import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { GetAllTenders } from '../../../store/admin/tenders/actions'
import { ChangeUpcomingTendersVisible } from '../../../store/admin/tenders/upcomingTenders/actions'
import { Spinner } from '../../../svg/Spinner'
import { SwitchRadioBtn } from '../SwitchRadioBtn'

interface UpcomingTendersSwitchProps {
  id: number,
  status: string,
  setDispatchTime: (value: (((prevState: number) => number) | number)) => void,
}

export const UpcomingTendersSwitch: React.FC<UpcomingTendersSwitchProps> = ({
  id,
  status,
  setDispatchTime,

}) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  return (
    <div className='flex items-center'>
      {loading
      ? (
        <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-10 bg-opacity-10 bg-gray-900">
          <Spinner style={{ color: 'black', width: '10px', height: '10px' }} />
        </div>
      ) : null}
      <div className="mr-2">Hide:</div>
      <SwitchRadioBtn
        className='is-hidden transform scale-90	'
        checked={status === 'hidden'}
        onChange={() => {
          setLoading(true)
          dispatch(ChangeUpcomingTendersVisible.request({
            tenderId: id,
            hide: status !== 'hidden',
            callBack(success) {
              if (success) {
                setDispatchTime(prev => prev + 1)
                dispatch(GetAllTenders.request())
              }
              setLoading(false)
            }
          }))
        }}
      />
    </div>
  )
}
