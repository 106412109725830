/* eslint-disable no-console,func-names */
/* global $ */
import axios from 'axios'
import React from 'react'
import zoomImg from '../components/svg/zoom_dropShadows.png'
import playImg from '../components/svg/play_dropShadows.png'
import pauseImg from '../components/svg/pause.svg'
import { isMobileDevice } from '../utils/helpers'

export const useVision360 = (useCustomVideo, diamondName) => {
  const [height, setHeight] = React.useState(275) // min height of container minus paddings
  const [swipeTop, setSwipeTop] = React.useState(0)
  const [swipeDown, setSwipeDown] = React.useState(0)
  const [displayVisible, setDisplayVisible] = React.useState(false)
  const [videoIsBroken, setVideoIsBroken] = React.useState(false)
  const [lastJsonIsLoaded, setLastJsonIsLoaded] = React.useState(false)

  if (useCustomVideo) return {
    height,
    setSwipeTop,
    setSwipeDown,
    displayVisible,
    videoIsBroken,
    lastJsonIsLoaded,
  }

  function captureNetworkRequest() {
    let valid = false
    const capture_resource = performance.getEntriesByType('resource')
    for (let i = 0; i < capture_resource.length; i += 1) {
      if (capture_resource[i].name.indexOf('7.json') > -1) valid = capture_resource[i].initiatorType === 'xmlhttprequest'
      if (capture_resource[i].name.indexOf('getSURLDataWithVersion') > -1) valid = false
    }
    return valid
  }

  const getSurl = () => {
    if (window.location.hostname === 'localhost' || window.location.hostname.split('.')[0] === 'circa-tender-v2') {
      // dev
      return 'https://dsub0pdn84mrt.cloudfront.net/'
    }
    if (window.location.hostname.split('.')[0] === 'stage') {
      // stage
      return 'https://d3b5i5zgfphyy7.cloudfront.net/'
    }
    // prod
    return 'https://d3abwgtk4eidxh.cloudfront.net/'

  }

  React.useEffect(() => {
    if (!diamondName) return null

    const getRatio = () => {
      const btnPreloadPlay = document.getElementsByClassName('btnPreloadPlay')[0]
      const vision360Wrapper = document.getElementById('vision360Wrapper')
      if (!btnPreloadPlay || !vision360Wrapper) return null

      const wText = btnPreloadPlay.style.width
      const hText = btnPreloadPlay.style.height
      const canvasW = vision360Wrapper?.clientWidth

      if (!canvasW || !hText || !wText) return null

      const h = +hText.replace('px', '')
      const w = +wText.replace('px', '')

      if (!h || !w) return null
      return h * canvasW / w
    }

    let timerId = null

    const resize = () => {
      clearTimeout(timerId)
      timerId = setTimeout(() => {
        const ratio = getRatio()
        if (!ratio) return null

        return setHeight(ratio)
      }, 100)
    }

    $(document).ready(function() {

      // eslint-disable-next-line consistent-return
      const fn = async () => {
        const url = 'https://v360.in/getSURL.aspx/getSURLDataWithVersion'
        let resp = null
        try {
          resp = await axios({
            method: 'POST',
            url,
            headers: {
              'Content-Type': 'application/json',
            },
            data: JSON.stringify({
              companyID: 1446,
              stoneID: diamondName,
            }),
          })
        } catch (e) {
          setVideoIsBroken(true)
          console.error('ERROR occurred when try again to load video')
        }


        const { d } = resp.data
        const respD = d.split(',')
        // console.log('🚀 ~ file: useVision360.js ~ line 24 ~ fn ~ respD', respD)
        const sgn = ' '
        if (respD[1] === '3.0' || respD === '3') {
          $('.Vision360').Vision360({
            diamondName,
            sideView: '0',
            zoom: 1,
            surl: getSurl(),
            scaleRatio: '-20',
            watermarkLink: 'https://v360.studio/',
            isBorderRadius: 0,
            autoPlay: 0,
            quality: 4, // Movie quality. If you pass 0 then it take from 0.js
            iconZoom: zoomImg,
            iconPause: pauseImg,
            iconPlay: playImg,
            iconLoader: sgn,
            iconNext: sgn,
            iconPrev: sgn,
            iconReverse: sgn,
            iconInfo: sgn,
            iconGrey: sgn,
            icon360: sgn,
            iconFV: sgn,
            iconFace: sgn,
            iconRV: sgn,
            iconBV: sgn,
            iconLV: sgn,
            iconAutoPlay: sgn,
            iconStop: sgn,
            iconPlayLoader: sgn,
            iconMinus: sgn,
            iconPlus: sgn,
            iconEye: sgn,
            iconDiamond: sgn,
            iconPoint: sgn,
          })
        } else {
          $('.Vision360').Vision360({
            diamondName,
            sideView: '',
            zoom: 1,
            surl: getSurl(),
            scaleRatio: '-20',
            watermarkLink: 'https://v360.studio/',
            quality: 4, // Movie quality. If you pass 0 then it take from 0.js
            isBorderRadius: 0,
            isFullLoad: 1,
            autoPlay: 0,
            iconZoom: zoomImg,
            iconPause: pauseImg,
            iconPlay: playImg,
            iconLoader: sgn,
            iconNext: sgn,
            iconPrev: sgn,
            iconReverse: sgn,
            iconInfo: sgn,
            iconGrey: sgn,
            icon360: sgn,
            iconFV: sgn,
            iconFace: sgn,
            iconRV: sgn,
            iconBV: sgn,
            iconLV: sgn,
            iconAutoPlay: sgn,
            iconStop: sgn,
            iconPlayLoader: sgn,
            iconMinus: sgn,
            iconPlus: sgn,
            iconEye: sgn,
            iconDiamond: sgn,
            iconPoint: sgn,
          })
        }

        // check if video is broken
        try {
          const respjson = await axios({
            method: 'GET',
            url: `${getSurl()}imaged/${diamondName}/0.json?version=1`,
            headers: {
              'Content-Type': 'application/xml',
            },
          })
          setVideoIsBroken(typeof respjson.data === 'string')
        } catch (e) {
          setVideoIsBroken(true)
          console.error('ERROR occurred when try again to load video')
          return null
        }

        $('.btnpos.hidemodel').each(function() {
          const id = $(this).children('img').attr('id')
          $(this).attr('id', `${id}_div`)
        })

        $('#ty div').not('#pause_div').remove()

        $('#trfv div').not('#zoomstart_div').remove()

        if (isMobileDevice()) {
          $('#v360Nav').append($('#pause_div'))
        } else {
          $('#v360Nav').append($('#pause_div')).append($('#zoomstart_div'))
        }

        $('.frameContainer a').click((e) => {
          e.preventDefault()
        })
        $('.big').prepend($('#v360Nav'))

        let intervalCount = 0

        const intervalId = setInterval(() => {
          intervalCount += 1
          if (intervalCount > 600) clearInterval(intervalId)

          const ratio = getRatio()
          if (!ratio) return null

          setHeight(ratio)
          setDisplayVisible(true)
          intervalCount = 0

          return clearInterval(intervalId)
        }, 100)

        let intervalCount2 = 0

        const intervalId2 = setInterval(() => {
          intervalCount2 += 1
          if (intervalCount2 > 600) clearInterval(intervalId2)
          if (captureNetworkRequest()) {
            setLastJsonIsLoaded(true)
            setTimeout(() => {
              // console.log('lastJson is loaded')
              const pause = document.getElementById('pause')
              const zoom = document.getElementById('zoomstart')
              if (pause) pause.style.pointerEvents = 'auto'
              if (zoom) zoom.style.pointerEvents = 'auto'
            }, 500)
            clearInterval(intervalId2)
          }
        }, 300)
      }

      fn()
    })

    window.addEventListener('resize', resize, true)

    return () => window.removeEventListener('resize', resize, true)
  }, [diamondName])


  React.useEffect(() => {
    if (swipeTop) {
      $(document).ready(() => {
        $('#itemPopupComponent').stop().animate({ scrollTop: height })
      })
    }
  }, [swipeTop, height])

  React.useEffect(() => {
    if (swipeDown) {
      $(document).ready(() => {
        $('#itemPopupComponent').stop().animate({ scrollTop: 0 })
      })
    }
  }, [swipeDown])

  return {
    height,
    setSwipeTop,
    setSwipeDown,
    displayVisible,
    videoIsBroken,
    lastJsonIsLoaded,
  }
}
