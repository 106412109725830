import { tenderSocketType } from '../../hooks/Sockets/useTender'
import { TCurrentBids, TCurrentBidsState } from '../../store/gallery/tenderSummary/types'

// sold = 0,
// buyNow = 1,
// bid = 2,
// deleteBid = 3,
// undoBuyNow = 4,

export const parseCurrentBids = (state: TCurrentBidsState, payload: tenderSocketType) => {
  const { data } = state
  const { currentBids } = data

  if (!currentBids) return state

  const bidIndex = currentBids.findIndex((item) => item.item_id === payload.item_id)

  switch (payload.type_of_event) {
    case 0:
      if (bidIndex !== -1) {
        data.bidsAmount -= 1
        currentBids.splice(bidIndex, 1)
      }
      break
    case 1:
      if (bidIndex !== -1) {
        data.bidsAmount -= 1
        currentBids.splice(bidIndex, 1)
      }
      data.buyNowAmount += 1
      break
    case 2:
      if (bidIndex === -1) {
        // eslint-disable-next-line no-case-declarations
        const newBid: TCurrentBids = {
          bid: 0,
          created_by_user_id: 0,
          email_of_customer: '',
          img: '',
          is_buy_now: false,
          item_id: payload.item_id,
          list_price: 0,
          title: '',
          type_of_product: 0,
          weight: 0,
        }
        currentBids.push(newBid)
        data.bidsAmount += 1
      } else data.refreshCurrentBids += 1
      break
    case 3:
      if (bidIndex !== -1) {
        data.bidsAmount -= 1
        currentBids.splice(bidIndex, 1)
      }
      break
    case 4:
      data.buyNowAmount -= 1

      if (payload.price) {
        // eslint-disable-next-line no-case-declarations
        const newBid: TCurrentBids = {
          bid: payload.price,
          created_by_user_id: 0,
          email_of_customer: '',
          img: '',
          is_buy_now: false,
          item_id: payload.item_id,
          list_price: 0,
          title: '',
          type_of_product: 0,
          weight: 0,
        }
        currentBids.push(newBid)
        data.bidsAmount += 1
      }
      break
    default:
      return state
  }

  return state
}
