import React from 'react'

export const IconInfo = ({className = ''}) => {
  return (
    <svg 
      className={`svg-inline--fa fa-search ${className}`}
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="circle-info"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      data-fa-i2svg=""
      width="20"
      height="20"
    >
      <path fill="currentColor" d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM208 352c-8.8 0-16 7.2-16 16s7.2 16 16 16l96 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0 0-112c0-8.8-7.2-16-16-16l-40 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l24 0 0 96-32 0zm48-168a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" />
    </svg>
  )
}

