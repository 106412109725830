/* eslint-disable react/require-default-props,react/jsx-props-no-spreading */
import React, { CSSProperties } from 'react'

interface PopUpProps {
  children: any
  onSubmit: () => void
  onCancel?: () => void
  className?: string
  style?: CSSProperties
  yesText?: string
  disabled?: boolean

  [key: string]: any
}

export const PopUp: React.FC<PopUpProps> = ({
  children,
  onSubmit,
  onCancel,
  className,
  style,
  yesText,
  disabled,
  ...props
}) => {
  return (
    <div
      className={`fixed top-5 bg-white w-80 rounded shadow-lg ${className}`}
      style={{ maxWidth: 'calc(100vw - 10px)', zIndex: 1000, ...style }}
      {...props}
    >
      <div className='p-3'>{children}</div>
      <div className='w-full h-px bg-bluegray-400' />
      <div className='flex justify-between p-3 gap-x-3'>
        <button
          onClick={onSubmit}
          type='submit'
          style={{ maxWidth: '10em' }}
          disabled={disabled}
          className='py-2 w-full mx-auto text-white bg-lightblue-700 hover:bg-lightblue-800 text-base rounded-md product-buttons'
        >
          {yesText || 'Yes'}
        </button>
        {onCancel && (
          <button
            onClick={onCancel}
            type='button'
            className='py-2 w-full text-white bg-bluegray-700 hover:bg-bluegray-800 text-base rounded-md product-buttons'
          >
            Cancel
          </button>
        )}
      </div>
    </div>
  )
}
