/* eslint-disable react/require-default-props */
import React from 'react'
import { Checkbox } from './Checkbox'

interface FileLabelProps {
  name: string
  size: string
  type: string
  className: string
  active: boolean
  setActive?: (value: boolean) => void
  checkbox?: boolean
}

export const FileLabel: React.FC<FileLabelProps> = ({ name, size, type, className, active, setActive, checkbox = true }) => {
  return (
    <div
      className={checkbox ? `p-4 flex border gap-4 smallMobile:justify-between border-bluegray-400 rounded ${!active && 'opacity-40'}` : ''}
    >
      <div className='flex items-center bg-white rounded font-semibold'>
        <div className={`w-12 h-12 rounded flex justify-center items-center ${className}`}>{type}</div>
        <div className='px-2 text-bluegray-600' style={{ maxWidth: 150 }}>
          <div className='whitespace-nowrap text-ellipsis overflow-hidden'>{name}</div>
          <div className='opacity-40 text-sm'>{size}</div>
        </div>
      </div>
      {checkbox &&
      <Checkbox label='' isChecked={active} variant='green' setIsChecked={() => setActive && setActive(!active)} />}
    </div>
  )
}
