import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from '../../components/ui/adminComponents/Input'
import { searchProps, useSearch } from '../../hooks/useSearch'
import { paginationProps, usePagination } from '../../hooks/usePagination'
import { getAuditByTendersChildParsed, getAuditByTendersParsed } from '../../utils/tendersUtils/tenderDataHelper'
import { AppStore } from '../../store/applicationState'
import { GetAuditByTender, GetAuditByTendersChild } from '../../store/admin/tenders/audit/actions'
import { TableForAudit } from '../../components/ui/adminComponents/TableForAudit'
import { useAddItemPopup } from '../../hooks/useAddItemPopup'
import { TenderStatusType } from '../../utils/tendersUtils/tenderStatusHelper'
import { LoaderPoints } from '../../components/ui/LoaderPoints'

const auditThead = {
  stock_number: 'Stock #',
  title: 'Item',
  count_of_actions: 'Events #',
  action: 'Action',
}

const tableCellWidth = [155, 250, 105, 105]

interface AuditByTenderProps {
  currentTenderId: number
  tenderStatus: TenderStatusType | null
}

export const AuditByTender: React.FC<AuditByTenderProps> = ({ currentTenderId, tenderStatus }) => {
  const limitAmount = 400
  const initialParams = { limit: limitAmount, offset: 0, search: undefined, sort: '', sortBy: '' }

  const dispatch = useDispatch()
  const { audit, tenders } = useSelector((store: AppStore) => store)
  const { add } = useAddItemPopup()

  const [params, setParams] = useState<searchProps | paginationProps>(initialParams)
  const [dispatchTime, setDispatchTime] = useState(0)
  const [childIndex, setChildIndex] = useState<number[]>([])

  const didMount = useRef(false)

  usePagination(audit.data, setParams, limitAmount)
  useSearch(limitAmount, params, setParams, setDispatchTime)

  useEffect(() => {
    if (didMount.current) {
      setDispatchTime((prev) => prev + 1)
      setParams(initialParams)
    }
    didMount.current = true
  }, [currentTenderId])

  useEffect(() => {
    dispatch(
      GetAuditByTender.request({
        idTender: currentTenderId,
        offset: params.offset,
        limit: params.limit,
        search: params.search,
        sortBy: params.sortBy,
        sort: params.sort,
      })
    )
  }, [params.offset, params.limit, params.sortBy, params.sort, dispatchTime])

  useEffect(() => {
    if (tenders.updateTenderNow) {
      if (params.offset === 0) setDispatchTime((prev) => prev + 1)
      else setParams((prev) => ({ ...prev, offset: 0, limit: prev.limit + prev.offset }))
    }
  }, [tenders.updateTenderNow])

  /// /////////////////////////////////////////////////////////////////////////////////////////////
  const callBack = (sortBy: string) => {
    if (sortBy === 'action') return

    let value: { sortBy: string; sort: 'ASC' | 'DESC' }
    if (sortBy !== params.sortBy || params.sort === 'DESC') value = { sortBy, sort: 'ASC' }
    else value = { sortBy, sort: 'DESC' }

    setParams((prev) => ({ ...prev, offset: 0, limit: limitAmount, sortBy: value.sortBy, sort: value.sort }))
    setChildIndex([])
  }

  const getChildForBidsByBidden = (idItem: number, companyId?: number[]) => {
    if (idItem !== -1) {
      dispatch(GetAuditByTendersChild.request({ idItem, companyId, limit: 10000 }))
    }
  }

  const auditValues = useMemo(() => {
    return audit.data
      ? getAuditByTendersParsed(audit.data, childIndex, getChildForBidsByBidden, dispatch, add, tenderStatus)
      : null
  }, [audit.data, childIndex])

  const auditValuesChild = useMemo(() => {
    if (!audit.childData) return null
    const result = new Map<number, object[]>()
    audit.childData.forEach((value, key) => {
      const auditItemData = audit.data ? audit.data.find((item) => item.item_id === key) : undefined
      result.set(key, getAuditByTendersChildParsed(value, auditItemData))
    })
    return result
  }, [audit.childData?.entries()])

  const auditTable = useMemo(() => {
    return auditValues ? (
      <TableForAudit
        thead={auditThead}
        tbody={auditValues}
        tbodyChild={auditValuesChild}
        callBack={callBack}
        childIndex={childIndex}
        setChildIndex={setChildIndex}
        tableCellWidth={tableCellWidth}
      />
    ) : null
  }, [auditValues, auditValuesChild])

  return (
    <>
      <div className='flex justify-between desktop:items-center mb-4 flex-col desktop:flex-row gap-x-5'>
        <div className='w-full md:w-1/3 2xl:w-1/4 mb-6 desktop:mb-0'>
          <Input
            placeholder='Search...'
            value={params.search || ''}
            onChange={(e) => setParams((prev) => ({ ...prev, search: e.target.value }))}
          />
        </div>
        <div style={{ height: '2.4rem' }} className='flex gap-1 whitespace-nowrap overflow-auto'>
          <div className='bg-blue-200 flex items-center px-4 rounded border border-blue-100'>Active Bid</div>
          <div className='bg-emerald-200 flex items-center px-4 rounded border border-emerald-100'>Active Buy now</div>
          <div className='bg-rose-50 flex items-center px-4 rounded border border-rose-100'>Deleted bid</div>
          <div className='bg-blue-100 flex items-center px-4 rounded border border-blue-50'>Edited bid</div>
          <div className='bg-amber-100 flex items-center px-4 rounded border border-amber-200'>Canceled - Sold</div>
          <div className='bg-rose-200 flex items-center px-4 rounded border border-rose-100'>
            Undo &quot;Buy now&quot;
          </div>
          <div className='bg-neutral-200 flex items-center px-4 rounded border border-neutral-100'>
            Bid canceled-Sold to the same company
          </div>
        </div>
      </div>
      <div className='relative'>
        {auditTable}
        {!!auditTable && audit.loading && (
          <div className='absolute left-0 right-0 -bottom-6 flex justify-center'>
            <LoaderPoints className='m-auto' />
          </div>
        )}
      </div>
    </>
  )
}
