import React from 'react'
import { Bar } from 'react-chartjs-2'
import { TAcquisitionPerformance } from '../../../store/dashboard/types'
import { ROUTES } from '../../../utils/routes'

interface activeTendersProps {
  tenders: TAcquisitionPerformance[]
}

export const PerformanceChart: React.FC<activeTendersProps> = ({ tenders }) => {
  const data = {
    labels: tenders?.map((tender) => tender.sales_cycle).reverse(),
    datasets: [
      {
        label: 'Lost by 9%+',
        categoryPercentage: 0.5,
        barPercentage: 1.0,
        maxBarThickness: 120,
        data: tenders
          ?.filter((tender) => tender.count_of_lost_by_more_then_nine > 0)
          .map((tender) => {
            return {
              x: tender.sales_cycle,
              y: tender.count_of_lost_by_more_then_nine,
            }
          }),
        backgroundColor: '#bfbfbf',
      },
      {
        label: 'Lost by 6-9%',
        categoryPercentage: 0.5,
        barPercentage: 1.0,
        maxBarThickness: 120,
        data: tenders
          ?.filter((tender) => tender.count_of_lost_by_six_nine_percents > 0)
          .map((tender) => {
            return {
              x: tender.sales_cycle,
              y: tender.count_of_lost_by_six_nine_percents,
            }
          }),
        backgroundColor: '#d6d6d6',
      },
      {
        label: 'Lost by 3-6%',
        categoryPercentage: 0.5,
        barPercentage: 1.0,
        maxBarThickness: 120,
        data: tenders
          ?.filter((tender) => tender.count_of_lost_by_three_six_percents > 0)
          .map((tender) => {
            return {
              x: tender.sales_cycle,
              y: tender.count_of_lost_by_three_six_percents,
            }
          }),
        backgroundColor: '#cdcdcd',
      },
      {
        label: 'Lost by 0-3%',
        categoryPercentage: 0.5,
        barPercentage: 1.0,
        maxBarThickness: 120,
        data: tenders
          ?.filter((tender) => tender.count_of_lost_by_zero_three_percents > 0)
          .map((tender) => {
            return {
              x: tender.sales_cycle,
              y: tender.count_of_lost_by_zero_three_percents,
            }
          }),
        backgroundColor: '#eeeeee',
      },
      {
        label: 'Winning Bid',
        categoryPercentage: 0.5,
        barPercentage: 1.0,
        maxBarThickness: 120,
        data: tenders
          ?.filter((tender) => tender.count_of_winning_bids > 0)
          .map((tender) => {
            return {
              x: tender.sales_cycle,
              y: tender.count_of_winning_bids,
            }
          }),
        backgroundColor: '#0284C7',
      },
      {
        label: 'Purchased Buy Now',
        categoryPercentage: 0.5,
        barPercentage: 1.0,
        maxBarThickness: 120,
        data: tenders
          ?.filter((tender) => tender.count_of_buy_now > 0)
          .map((tender) => {
            return {
              x: tender.sales_cycle,
              y: tender.count_of_buy_now,
            }
          }),
        backgroundColor: '#10B981',
      },
    ],
  }

  const goToTenderResults = (e: any, element: any) => {
    if (element.length) {
      const tenderSalesCycle = e.chart.tooltip.title[0]
      const tenderId = tenders.find((tender) => tender.sales_cycle === tenderSalesCycle)?.id

      window.open(`${ROUTES.tenderResults}/${tenderId}/bid-results`, '_blank')
    }
  }

  return (
    <div className=''>
      <Bar
        data={data}
        options={{
          maintainAspectRatio: true,
          onClick: (e: any, element) => {
            if (e.native.detail % 2 === 0 && element.length) goToTenderResults(e, element)
          },
          aspectRatio: 3,
          plugins: {
            title: {
              align: 'center',
              fullSize: false,
              display: true,
              text: 'Your total Bids',
              position: 'left',
            },
            legend: {
              position: 'bottom',
              reverse: true,
            },
            tooltip: {
              mode: 'x',
            },
          },
          responsive: true,
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false,
              },
            },
            y: {
              stacked: true,
              ticks: {
                stepSize: 1,
              },
              grid: {
                color: 'rgba(229, 229, 229, 0.5)',
              },
            },
          },
        }}
      />
    </div>
  )
}
