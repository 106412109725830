import { Reducer } from 'redux'
import { ActionTypes, TUpcomingTendersState } from './types'

export const initialState: TUpcomingTendersState = {
  loading: false,
  data: null,
}

const reducer: Reducer<TUpcomingTendersState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_UPCOMING_TENDERS_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_UPCOMING_TENDERS_S:
      return { ...state, loading: false, errors: undefined, data: action.payload }
    case ActionTypes.GET_UPCOMING_TENDERS_E:
      return { ...state, loading: false, errors: action.payload }
    
    default:
      return state
  }
}

export { reducer as UpcomingTendersReducer }