import { Reducer } from 'redux'
import { ActionTypes, TCompaniesState } from './types'

export const initialState: TCompaniesState = {
  loading: false,
  data: null,
  companyForEditData: null,
}

const reducer: Reducer<TCompaniesState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_COMPANIES_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_COMPANIES_S:
      return { ...state, loading: false, errors: undefined, data: action.payload }
    case ActionTypes.GET_COMPANIES_E:
      return { ...state, loading: false, errors: action.payload }
    
    case ActionTypes.UPDATE_COMPANY_INFO_R:
      return { ...state, errors: undefined }
    case ActionTypes.UPDATE_COMPANY_INFO_S:
      return { ...state, loading: false }
    case ActionTypes.UPDATE_COMPANY_INFO_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.GET_COMPANY_BY_ID_R:
      return { ...state, errors: undefined }
    case ActionTypes.GET_COMPANY_BY_ID_S:
      return { ...state, loading: false, errors: undefined, companyForEditData: action.payload }
    case ActionTypes.GET_COMPANY_BY_ID_E:
        return { ...state, loading: false, errors: action.payload }

    case ActionTypes.CREATE_NEW_COMPANY_R:
      return { ...state, errors: undefined }
    case ActionTypes.CREATE_NEW_COMPANY_S:
      return { ...state }
    case ActionTypes.CREATE_NEW_COMPANY_E:
      return { ...state, errors: action.payload }

    case ActionTypes.CLEAR_COMPANY_BY_ID:
      return { ...state, loading: false, errors: undefined, companyForEditData: undefined}
    
    default:
      return state
  }
}

export { reducer as CompaniesReducer }