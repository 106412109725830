export interface TCompanies {
  company_title: string
  created_at: string
  id: number
  updated_at: string
  in_recommendation?: boolean
  is_approved: boolean
  with_bid?: boolean
  ns_customer: TNSCustomer
  ns_internal_id: string
}

export interface TNSCustomer {
  address_book_list: {
    address_book_list: TCustomerAddress[];
  };
  company_name: string
  created_at: string
  custom_form: string
  default_address: string
  email: string
  email_transactions: boolean
  entity_status: string
  foreign_or_domestic: string
  ns_internal_id: string
  phone: string
  print_transactions: boolean
  subsidiary: string
  sync: boolean
  sync_error: string
  terms: string
  updated_at: string
}
export interface TCustomerAddress {
  addr1: string
  addr2: string
  addr_phone: string
  addressee: string
  attention: string
  city: string
  country: string
  default_billing: boolean
  default_shipping: boolean
  state: string
  zip: string
}

export interface TCompany{
  company_title: string
  created_at: string
  id: 0
  is_approved: boolean
  in_recommendation: true
  ns_customer: TNSCustomer
  ns_internal_id: string
  updated_at: string
  with_bid: true
}
export interface TBody {
  companies: TCompanies[] | null
  total_without_recommended_and_bid: number
}

export interface TCompaniesMessage {
  message: string
}

export interface TCompaniesState {
  loading: boolean
  data: TBody | null
  companyForEditData: TCompany | null
  errors?: string | undefined
}

export enum ActionTypes {
  GET_COMPANIES_R = '@@COMPANIES/GET_COMPANIES_R',
  GET_COMPANIES_S = '@@COMPANIES/GET_COMPANIES_S',
  GET_COMPANIES_E = '@@COMPANIES/GET_COMPANIES_E',

  GET_COMPANY_BY_ID_R = '@@COMPANIES/GET_COMPANY_BY_ID_R',
  GET_COMPANY_BY_ID_S = '@@COMPANIES/GET_COMPANY_BY_ID_S',
  GET_COMPANY_BY_ID_E = '@@COMPANIES/GET_COMPANY_BY_ID_E',

  UPDATE_COMPANY_INFO_R = '@@COMPANIES/UPDATE_COMPANY_INFO_R',
  UPDATE_COMPANY_INFO_S = '@@COMPANIES/UPDATE_COMPANY_INFO_S',
  UPDATE_COMPANY_INFO_E = '@@COMPANIES/UPDATE_COMPANY_INFO_E',

  CREATE_NEW_COMPANY_R = '@@admin/CREATE_NEW_COMPANY_R',
  CREATE_NEW_COMPANY_S = '@@admin/CREATE_NEW_COMPANY_S',
  CREATE_NEW_COMPANY_E = '@@admin/CREATE_NEW_COMPANY_E',

  CLEAR_COMPANY_BY_ID = '@@COMPANIES/CLEAR_COMPANY_BY_ID',

}
