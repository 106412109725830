/* eslint-disable react/require-default-props */
import React from 'react'
import styled from 'styled-components'

const LdsEllipsis = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 30px;

  div {
    position: absolute;
    top: 35%;
    transform: translate(-50%, 0);
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: rgb(71, 85, 105);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

`

interface LoaderPointsProps {
  className?: string
}

export const LoaderPoints: React.FC<LoaderPointsProps> = ({ className }) => {
  return (
    <LdsEllipsis className={className}>
      <div />
      <div />
      <div />
      <div />
    </LdsEllipsis>
  )
}