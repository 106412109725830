export interface TSignIn {
  access_token: string
  refresh_token: string
}

export interface TSignMessage {
  message: string
}

export interface TSignState {
  loading: boolean
  data: TSignIn | TSignMessage | null
  errors?: string | undefined
  authorized: boolean
}

export enum ActionTypes {
  SIGN_IN_R = '@@auth/POST_SIGN_IN_R',
  SIGN_IN_S = '@@auth/POST_SIGN_IN_S',
  SIGN_IN_E = '@@auth/POST_SIGN_IN_E',

  SIGN_OUT_R = '@@auth/DELETE_SIGN_OUT_R',
  SIGN_OUT_S = '@@auth/DELETE_SIGN_OUT_S',
  SIGN_OUT_E = '@@auth/DELETE_SIGN_OUT_E',

  FORGOT_PASS_R = '@@auth/FORGOT_PASS_R',
  FORGOT_PASS_S = '@@auth/FORGOT_PASS_S',
  FORGOT_PASS_E = '@@auth/FORGOT_PASS_E',

  SET_NEW_PASS_R = '@@auth/SET_NEW_PASS_R',
  SET_NEW_PASS_S = '@@auth/SET_NEW_PASS_S',
  SET_NEW_PASS_E = '@@auth/SET_NEW_PASS_E',

  SET_AUTH = '@@auth/SET_AUTH',
  SET_LOGOUT = '@@auth/SET_LOGOUT',
}
