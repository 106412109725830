import { Actions } from "../../../utils/Action"
import { ActionTypes } from './types'
import { BidStatus, TDefRequest } from '../../../types/actions'

/// ///////////////////////////////////////////////////////////////////////
const SetItemBidActions = new Actions('SET_ITEM_BID', ActionTypes)

export interface TypeSetItemBid extends TDefRequest {
  idTender: number,
  idItem: number,
  actionType: string,
  body: {
    bid_amount: number
    type_of_bid: BidStatus
  }
}

export const SetItemBid = {
  request: (payload: TypeSetItemBid) => SetItemBidActions.request(payload),
  success: (payload: any) => SetItemBidActions.success(payload),
  error: (payload: string) => SetItemBidActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
const DeleteItemBidActions = new Actions('DELETE_ITEM_BID', ActionTypes)

export interface TypeDeleteItemBid extends TDefRequest {
  idTender: number,
  idItem: number,
}

export const DeleteItemBid = {
  request: (payload: TypeDeleteItemBid) => DeleteItemBidActions.request(payload),
  success: (payload: any) => DeleteItemBidActions.success(payload),
  error: (payload: string) => DeleteItemBidActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
const SetItemBuyActions = new Actions('SET_ITEM_BUY', ActionTypes)

export interface TypeSetItemBuy extends TDefRequest {
  idTender: number,
  idItem: number,
}

export const SetItemBuy = {
  request: (payload: TypeSetItemBuy) => SetItemBuyActions.request(payload),
  success: (payload: any) => SetItemBuyActions.success(payload),
  error: (payload: string) => SetItemBuyActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////