import React, { useEffect, useState } from 'react'

export const useCheckLoc = (elem: React.RefObject<HTMLElement>) => {
  const [top, setTop] = useState(true)

  const onScroll = () => {
    if (elem.current) setTop((elem.current.offsetTop * 3) / 4 > window.scrollY)
  }

  useEffect(() => {
    if (elem.current) setTop((elem.current.offsetTop * 3) / 4 > window.scrollY)
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [elem])

  return {
    top,
  }
}
