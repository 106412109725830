import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { AppStore } from '../../../store/applicationState'
import { TGalleryItemInfo } from '../../../store/gallery/types'
import { IconCheckCircle } from '../../../svg/IconCheckCircle'
import { IconClose } from '../../../svg/IconClose'
import { IconEdit } from '../../../svg/IconEdit'
import { IconThumbsUp } from '../../../svg/IconThumbsUp'
import { IconTimes } from '../../../svg/IconTimes'
import { API_ROUTES } from '../../../utils/apiRoutes'
import { convertToDollars } from '../../../utils/convertToDollars'

interface ItemPopupStatusProps {
  item: TGalleryItemInfo
}

export const ItemPopupStatus: React.FC<ItemPopupStatusProps> = ({ item }) => {
  if (item.is_sold && item.bid_status_id === 8) {
    return (
      <div className='flex space-x-6  text-base status green-status items-center p-2 font-circa'>
        <IconCheckCircle styled='text-green-500 mr-2' />

        {`Bought by your company for ${convertToDollars(item.product.asking_price)} total.`}
      </div>
    )
  }

  if (item.is_sold && item.bid_status_id === 0) {
    return (
      <div className='flex space-x-6 text-base status red-status items-center p-2 font-circa '>
        <IconTimes styled='text-red-500 mr-2' />

        {`Sold at our BUY NOW price of ${convertToDollars(item.product.asking_price)}`}
      </div>
    )
  }

  if (item.is_sold && item.bid_status_id === 7) {
    return (
      <div className='flex space-x-6 text-base status yellow-status items-center p-2 font-circa text-yellow-500'>
        <IconTimes styled='text-yellow-500 mr-2' />
        Bid canceled - Sold to another buyer
      </div>
    )
  }

  return <></>
}

interface ItemPopupFinalizedStatusProps {
  item: TGalleryItemInfo
}

export const ItemPopupFinalizedStatus: React.FC<ItemPopupFinalizedStatusProps> = ({ item }) => {
  if (item.bid_status_id === 0) {
    return (
      <div className='flex space-x-6 text-base status gray-status justify-center items-center p-2 font-circa'>
        {/* <IconTimes styled='text-bluegray-500 mr-2' /> */}
        Tender has ended.
      </div>
    )
  }
  if (item.bid_status_id === 2) {
    return (
      <div className='flex space-x-6  text-base status green-status items-center p-2 font-circa'>
        <IconCheckCircle styled='text-green-500 mr-2' />
        YOU WON!
      </div>
    )
  }

  if ([4, 5, 6, 9, 10, 11, 12].includes(item.bid_status_id)) {
    let bidText = ''

    if (item.bid_status_id === 6) {
      bidText = `OUTBID BY > 10%`
    } else if (item.bid_status_id === 5) {
      bidText = `OUTBID BY 5-10%`
    } else if (item.bid_status_id === 4) {
      bidText = `OUTBID BY < 5%`
    } else if (item.bid_status_id === 9) {
      bidText = `OUTBID BY < 3%`
    } else if (item.bid_status_id === 10) {
      bidText = `OUTBID BY 3-6%`
    } else if (item.bid_status_id === 11) {
      bidText = `OUTBID BY 6-9%`
    } else if (item.bid_status_id === 12) {
      bidText = `OUTBID BY > 9%`
    }
    return (
      <div className='flex space-x-6 text-base status red-status items-center p-2 font-circa '>
        <IconTimes styled='text-red-500 mr-2' />

        {bidText}
      </div>
    )
  }
  if (item.bid_status_id === 3) {
    return (
      <div className='flex space-x-6 text-base status gray-status items-center p-2 font-circa'>
        <IconTimes styled='text-bluegray-500 mr-2' />
        RESERVE NOT MET
      </div>
    )
  }
  if (item.bid_status_id === 7) {
    return (
      <div className='flex space-x-6 text-base status yellow-status items-center p-2 font-circa text-yellow-500'>
        <IconTimes styled='text-yellow-500 mr-2' />
        Bid canceled - Sold to another buyer
      </div>
    )
  }
  if (item.bid_status_id === 8) {
    return (
      <div className='flex space-x-6  text-base status green-status items-center p-2 font-circa'>
        <IconCheckCircle styled='text-green-500 mr-2' />
        {`Bought by your company for ${convertToDollars(item.product.asking_price)} total.`}
      </div>
    )
  }

  return <></>
}

interface ItemCardStatusProps {
  item: TGalleryItemInfo
  tenderId: number
  setPurchasePopupActive: (value: boolean) => void
  openItemPopup: () => void
}

export const ItemCardStatus: React.FC<ItemCardStatusProps> = ({
  item,
  tenderId,
  setPurchasePopupActive,
  openItemPopup,
}) => {
  if (!item.is_sold && item.bid_status_id === 0) {
    return (
      <div className='flex space-x-3 flex-row h-9'>
        {item.product.asking_price ? (
          <button
            type='button'
            onClick={() => setPurchasePopupActive(true)}
            className='rounded-md select-none focus:outline-none buy-button buy-now flex-grow whitespace-nowrap font-semibold text-base'
          >
            <span className='text'>
              {item.category_id === 1
                ? `BUY NOW ${convertToDollars(item.product.asking_price_per_carat)} /ct`
                : `BUY NOW ${convertToDollars(item.product.asking_price)}`}
            </span>
          </button>
        ) : (
          ''
        )}

        <Link
          to={`${API_ROUTES.tenders}/${tenderId}?item=${item.item_id}`}
          onClick={(e) => {
            if (e.nativeEvent.which === 1) {
              e.preventDefault()
              openItemPopup()
            }
          }}
          className='rounded-md select-none focus:outline-none bid-button flex-grow whitespace-nowrap font-semibold text-base'
        >
          <span className='text'>BID</span>
        </Link>
      </div>
    )
  }

  if (!item.is_sold && item.bid_status_id === 1) {
    const bidDiscont = item.product.rap_list_price
      ? `(${
          Math.round(
            ((Math.round(item.bid / item.product.product_details.weight / 100) / (item.product.rap_list_price / 100)) *
              100 -
              100) *
              100
          ) / 100
        }%)`
      : ''
    return (
      <Link
        to={`${API_ROUTES.tenders}/${tenderId}?item=${item.item_id}`}
        onClick={(e) => {
          if (e.nativeEvent.which === 1) {
            e.preventDefault()
            openItemPopup()
          }
        }}
        className='status blue-status w-full p-1 px-2 flex items-center space-x-2 xrounded  h-9 text-sm'
      >
        <div className='flex justify-between w-full items-center'>
          <div className='flex items-center space-x-2 mobile:space-x-1 lg:space-x-2'>
            <IconThumbsUp styled='text-lightblue-500' />
            <div className='status-text font-semibold uppercase'>Bid</div>

            <div className='status-price'>
              {item.category_id === 1
                ? `${convertToDollars(item.bid / item.product.product_details.weight)} /ct ${bidDiscont}`
                : convertToDollars(item.bid)}
            </div>
          </div>

          <div className='action uppercase text-xs text-lightblue-900'>Edit</div>
        </div>
      </Link>
    )
  }

  if (item.is_sold && item.bid_status_id === 8) {
    return (
      <div className='status green-status w-full p-1 px-2 flex items-center space-x-2 mobile:space-x-1 lg:space-x-2 h-9 xrounded text-sm '>
        <div className='flex items-center justify-between space-x-2 mobile:space-x-1 lg:space-x-2'>
          <IconCheckCircle styled='text-green-500' />
          <div className='status-text font-semibold uppercase'>Bought</div>
        </div>

        <div className='status-price'>
          {item.category_id === 1
            ? `${convertToDollars(item.product.asking_price_per_carat)} /ct ${
                item.product.rap_list_price_discount ? `(${item.product.rap_list_price_discount}%)` : ''
              } `
            : convertToDollars(item.product.asking_price)}
        </div>
      </div>
    )
  }

  if (item.is_sold && item.bid_status_id === 0) {
    return (
      <div className='status red-status w-full p-1 px-2 flex items-center space-x-2 mobile:space-x-1 lg:space-x-2 h-9 xrounded text-sm'>
        <div className='flex items-center justify-between space-x-2 mobile:space-x-1 lg:space-x-2'>
          <IconTimes styled='text-red-500' />
          <div className='status-text font-semibold uppercase'>Sold</div>
        </div>

        <div className='status-price'>
          {item.category_id === 1
            ? `${convertToDollars(item.product.asking_price_per_carat)} /ct ${
                item.product.rap_list_price_discount ? `(${item.product.rap_list_price_discount}%)` : ''
              } `
            : convertToDollars(item.product.asking_price)}
        </div>
      </div>
    )
  }

  if (item.is_sold && item.bid_status_id === 7) {
    return (
      <div className='status yellow-status w-full p-1 px-2 flex items-center space-x-2 h-9 xrounded text-sm'>
        {/* <IconTimes styled='text-yellow-500' /> */}

        <div className='status-text message'>Bid canceled - Sold at BUY NOW.</div>
      </div>
    )
  }

  return <div />
}

interface ItemTableStatusProps {
  item: TGalleryItemInfo
  tenderId: number
  setCurrentItem: (value: any) => void
  setPurchasePopupActive: (value: boolean) => void
  openItemPopup: (value: number) => void
}

const InputWrappers = styled.input`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
export const ItemTableStatus: React.FC<ItemTableStatusProps> = ({
  item,
  tenderId,
  setPurchasePopupActive,
  openItemPopup,
  setCurrentItem,
}) => {
  const inputStartPercent = () => {
    if (!item.product.rap_list_price_discount) return ''
    const percent = item.product.rap_list_price_discount - 0.5
    const getBid = Math.round((item.product.rap_list_price / 100) * ((100 - -percent) / 100))
    return Math.round(((getBid / (item.product.rap_list_price / 100)) * 100 - 100) * 100) / 100
  }

  const [tableBidPercent, setTableBidPercent] = useState(inputStartPercent())
  const [percentError, setPercentError] = useState(false)
  const [disableBidButton, seDisableBidButton] = useState(false)
  const { gallery } = useSelector((store: AppStore) => store)

  const getMinBid = Math.round((Math.round(item.product.asking_price / 100) - 1) / item.product.product_details.weight)
  const geMintBidPercent = Math.round(((getMinBid / (item.product.rap_list_price / 100)) * 100 - 100) * 100) / 100

  const rapPercent =
    Math.round(((item.product.asking_price_per_carat / 100 / (item.product.rap_list_price / 100)) * 100 - 100) * 100) /
    100
  const buyNowTotal = item.product.asking_price / 100

  useEffect(() => {
    let timer: any
    const getBid = Math.round((item.product.rap_list_price / 100) * ((100 - -+tableBidPercent) / 100))
    const getTotal = Math.round(getBid * item.product.product_details.weight)

    const roundetBid = Math.round(((getBid / (item.product.rap_list_price / 100)) * 100 - 100) * 100) / 100

    if (tableBidPercent === '' || tableBidPercent === '-' || tableBidPercent === '.') {
      seDisableBidButton(false)
    } else if (roundetBid >= rapPercent || getTotal === buyNowTotal) {
      timer = setTimeout(() => {
        setPercentError(true)
        setTableBidPercent(rapPercent)
        seDisableBidButton(false)
      }, 1000)
    } else {
      setPercentError(false)
      timer = setTimeout(() => {
        if (roundetBid <= -100) {
          const ceilBid = Math.round((100 - (1 / (item.product.rap_list_price / 100)) * 100) * 100) / 100
          setTableBidPercent(-ceilBid)
        } else {
          setTableBidPercent(roundetBid)
        }
        seDisableBidButton(false)
      }, 1000)
    }

    return () => clearTimeout(timer)
  }, [tableBidPercent])

  useEffect(() => {
    if (gallery.data.tenderInfo && gallery.data.showPrices && gallery.data.pricesAreLoaded) {
      setTableBidPercent(inputStartPercent())
    }
  }, [gallery.data.tenderInfo, gallery.data.showPrices, gallery.data.pricesAreLoaded])

  const arrowPress = (press: boolean, up?: boolean) => {
    let value = +tableBidPercent
    let keyTimer: any

    if (press && up) {
      keyTimer = setInterval(() => {
        value = Math.round((value + 0.01) * 100) / 100
        setTableBidPercent(value)
        seDisableBidButton(true)
      }, 150)
    }

    if (press && !up) {
      keyTimer = setInterval(() => {
        value = Math.round((value - 0.01) * 100) / 100
        setTableBidPercent(value >= -99.99 ? value : -99.99)
        seDisableBidButton(true)
      }, 150)
    }

    document.addEventListener('mouseup', () => clearInterval(keyTimer))
  }

  const changeInputValue = (event: any) => {
    if (event.code === 'ArrowUp') {
      setTableBidPercent((prev) => Math.round((+prev + 0.01) * 100) / 100)
    }
    if (event.code === 'ArrowDown') {
      setTableBidPercent((prev) => (+prev > -99.99 ? Math.round((+prev - 0.01) * 100) / 100 : -99.99))
    }
    seDisableBidButton(true)
  }

  if (!item.is_sold && item.bid_status_id === 0) {
    return (
      <div className='flex items-center text-center whitespace-nowrap space-x-2 min-w-full w-80'>
        {item.product.asking_price ? (
          <button
            type='button'
            className={`rounded-md select-none focus:outline-none buy-button buy-now ${
              !item.product.rap_list_price ? 'w-full' : 'w-40'
            }`}
            onClick={() => {
              setPurchasePopupActive(true)
              setCurrentItem(item)
            }}
          >
            <span className='text'>{`Buy for ${convertToDollars(item.product.asking_price_per_carat)} /ct`}</span>
          </button>
        ) : null}

        {item.product.rap_list_price ? (
          <span className='discount relative flex'>
            {percentError && (
              <div className='error input-info absolute z-20 bottom-full -left-8 mb-1 text-xxxs w-36 whitespace-normal bg-red-100 border border-red-300 text-red-700 font-semibold px-3 py-1'>
                Your bid must be below the buy now price.
              </div>
            )}
            <InputWrappers
              type='text'
              name=''
              id={`input-${item.item_id}`}
              value={tableBidPercent}
              className={`text-base pr-4 font-semibold border p-1 w-20 ring-bluegray-100 outline-none text-center ${
                percentError ? 'border-red-400' : 'border-bluegray-200'
              }`}
              onFocus={() => {
                const targetInput = document.getElementById(`input-${item.item_id}`)
                if (targetInput) targetInput.addEventListener('keydown', (event) => changeInputValue(event))
              }}
              max={item.product.rap_list_price_discount}
              onChange={(e) => {
                const { value } = e.target
                const regex = /^[0-9.,-]+$/

                if (
                  (!value.match(regex) && value !== '') ||
                  value.slice(1).includes('-') ||
                  (value.slice(0, -1).includes('.') && value.replace(',', '.')[value.length - 1] === '.')
                ) {
                  e.preventDefault()
                  seDisableBidButton(false)
                } else if (+value.replace(',', '.') < -99.99 && value !== '-') {
                  e.preventDefault()
                  seDisableBidButton(true)
                } else if (value === '') {
                  setTableBidPercent('')
                } else {
                  setTableBidPercent(value.replace(',', '.'))
                  seDisableBidButton(true)
                }
                setPercentError(false)
              }}
            />
            <div className='absolute z-8 right-1 top-0 flex flex-col h-full text-micro'>
              <div className='my-auto'>
                <button
                  type='button'
                  id='button-up'
                  className='flex px-1 py-px bg-bluegray-100 hover:bg-bluegray-200 select-none mb-px'
                  disabled={percentError}
                  onMouseDown={() => arrowPress(true, true)}
                  onMouseUp={() => arrowPress(false)}
                  onClick={() => {
                    setTableBidPercent(Math.round((+tableBidPercent + 0.01) * 100) / 100)
                    seDisableBidButton(true)
                  }}
                >
                  ▲
                </button>
                <button
                  id='button-down'
                  type='button'
                  className='flex px-1 py-px bg-bluegray-100 hover:bg-bluegray-200 select-none'
                  onMouseDown={() => arrowPress(true, false)}
                  onMouseUp={() => arrowPress(false)}
                  disabled={+tableBidPercent <= -99.99}
                  onClick={() => {
                    if (+tableBidPercent > rapPercent) {
                      setTableBidPercent(geMintBidPercent)
                    } else {
                      setTableBidPercent(Math.round((+tableBidPercent - 0.01) * 100) / 100)
                    }
                    seDisableBidButton(true)
                  }}
                >
                  ▼
                </button>
              </div>
            </div>
          </span>
        ) : (
          ''
        )}

        <Link
          to={`${API_ROUTES.tenders}/${tenderId}?item=${item.item_id}`}
          className={`rounded-md select-none focus:outline-none bid-button ${
            !item.product.asking_price || !item.product.rap_list_price ? 'w-full' : 'w-20'
          } ${percentError || disableBidButton ? 'pointer-events-none opacity-40' : ''}`}
          onClick={(e) => {
            if (e.nativeEvent.which === 1) {
              e.preventDefault()
              openItemPopup(+tableBidPercent)
              setCurrentItem(item)
            }
          }}
        >
          <span className='text'>Bid</span>
        </Link>
      </div>
    )
  }

  if (!item.is_sold && item.bid_status_id === 1) {
    const bid = Math.round(item.bid / item.product.product_details.weight / 100)
    const bidPercent = Math.round(((bid / (item.product.rap_list_price / 100)) * 100 - 100) * 100) / 100
    return (
      <div className='text-center whitespace-nowrap  space-x-4'>
        {item.product.asking_price && (
          <button
            type='button'
            className='rounded-md select-none focus:outline-none buy-button buy-now w-40'
            onClick={() => {
              setPurchasePopupActive(true)
              setCurrentItem(item)
            }}
          >
            <span className='text'>{`Buy for ${convertToDollars(item.product.asking_price_per_carat)} /ct`}</span>
          </button>
        )}

        <Link
          to={`${API_ROUTES.tenders}/${tenderId}?item=${item.item_id}`}
          className={`rounded-md select-none focus:outline-none bid-button space-x-2 ${
            !item.product.asking_price ? 'w-full' : 'w-40'
          }`}
          onClick={(e) => {
            if (e.nativeEvent.which === 1) {
              e.preventDefault()
              openItemPopup(+tableBidPercent)
              setCurrentItem(item)
            }
          }}
        >
          Your Bid:
          <span className='font-bold text ml-1'>
            {item.product.rap_list_price
              ? `${bidPercent}%`
              : `$${Math.round(item.bid / item.product.product_details.weight / 100)} / ct`}
          </span>
          <IconEdit />
        </Link>
      </div>
    )
  }

  if (item.is_sold && item.bid_status_id === 8) {
    return (
      <div className='text-green-800'>
        {`Purchased for ${convertToDollars(item.product.asking_price_per_carat)} / ct ${
          item.product.rap_list_price_discount ? `| ${item.product.rap_list_price_discount}%` : ''
        }`}
      </div>
    )
  }

  if (item.is_sold && item.bid_status_id === 0) {
    return (
      <div className='text-red-400 flex items-center justify-center '>
        <span className='mr-2 flex'>
          <IconClose />
        </span>
        Sold to another buyer
      </div>
    )
  }

  if (item.is_sold && item.bid_status_id === 7) {
    return (
      <div className='text-yellow-500 flex items-center justify-center '>
        <span className='mr-2 flex'>
          <IconClose />
        </span>
        Bid canceled - Sold to another buyer
      </div>
    )
  }

  return <div />
}
