import React, { useEffect, useState } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { AppStore } from '../store/applicationState'

const Diamond = styled.ul`
  display: block;
  width: 200px;
  height: 100px;
  position: relative;

  li {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    opacity: 0;
    z-index: 100;
    transition: 400ms ease;
  }

  li:nth-child(1) {
    border-width: 20px 20px 0 20px;
    border-color: #7ad997 transparent transparent transparent;
    left: 0;
    right: 0;
    top: 0;
    opacity: 1;
    margin: auto;
    animation: opacity 2s 0.4s ease infinite;
    animation-fill-mode: forwards;
  }

  li:nth-child(2) {
    border-width: 0 20px 20px 20px;
    border-color: transparent transparent #64c592;
    left: 59px;
    top: 1px;
    opacity: 1;
    animation: opacity 2s 0.4s ease infinite;
    animation-fill-mode: forwards;
  }

  li:nth-child(3) {
    border-width: 0 20px 20px 20px;
    border-color: transparent transparent #54b28d;
    right: 59px;
    top: 1px;
    opacity: 1;
    animation: opacity 2s 0.4s ease infinite;
    animation-fill-mode: forwards;
  }

  li:nth-child(4) {
    border-width: 20px 20px 0 20px;
    border-color: #7ad997 transparent transparent transparent;
    right: 59px;
    top: 22px;
    opacity: 1;
    animation: opacity 2s 0.6s ease infinite;
    animation-fill-mode: forwards;
  }

  li:nth-child(5) {
    border-width: 20px 20px 0 20px;
    border-color: #b4fea5 transparent transparent transparent;
    left: 59px;
    top: 22px;
    opacity: 1;
    animation: opacity 2s 0.6s ease infinite;
    animation-fill-mode: forwards;
  }

  li:nth-child(6) {
    border-width: 0 20px 20px 20px;
    border-color: transparent transparent #90ef9d;
    left: 0;
    right: 0;
    top: 23px;
    margin: auto;
    opacity: 1;
    animation: opacity 2s 0.6s ease infinite;
    animation-fill-mode: forwards;
  }

  li:nth-child(7) {
    border-width: 20px 20px 0 20px;
    border-color: #c9feac transparent transparent transparent;
    left: 0;
    right: 0;
    margin: auto;
    top: 44px;
    opacity: 1;
    animation: opacity 2s 0.8s ease infinite;
    animation-fill-mode: forwards;
  }

  //@keyframes traingle1 {
  //  0% {
  //    top: -50px;
  //    opacity: 0;
  //  }
  //  100% {
  //    top: 0;
  //    opacity: 1;
  //  }
  //}
  //@keyframes traingle2 {
  //  0% {
  //    top: -50px;
  //    opacity: 0;
  //  }
  //  100% {
  //    top: 1px;
  //    opacity: 1;
  //  }
  //}
  //@keyframes traingle3 {
  //  0% {
  //    top: -50px;
  //    opacity: 0;
  //  }
  //  100% {
  //    top: 1px;
  //    opacity: 1;
  //  }
  //}
  //@keyframes traingle4 {
  //  0% {
  //    right: 59px;
  //    top: -33px;
  //    opacity: 0;
  //  }
  //  100% {
  //    right: 59px;
  //    top: 22px;
  //    opacity: 1;
  //  }
  //}
  //@keyframes traingle5 {
  //  0% {
  //    left: 59px;
  //    top: -33px;
  //    opacity: 0;
  //  }
  //  100% {
  //    left: 59px;
  //    top: 22px;
  //    opacity: 1;
  //  }
  //}
  //@keyframes traingle6 {
  //  0% {
  //    top: -33px;
  //    opacity: 0;
  //  }
  //  100% {
  //    top: 23px;
  //    opacity: 1;
  //  }
  //}
  //@keyframes traingle7 {
  //  0% {
  //    top: -10px;
  //    opacity: 0;
  //  }
  //  100% {
  //    top: 44px;
  //    opacity: 1;
  //  }
  //}

  @keyframes opacity {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }
`

// https://codepen.io/nicolagrosu/pen/QvMKzx
export const GlobalLoader = () => {
  const { promiseInProgress } = usePromiseTracker()
  const { global } = useSelector((store: AppStore) => store)

  const location = useLocation()
  const [showLoader, setShowLoader] = useState(true)
  const [pathIsChanged, setPathIsChanged] = useState(true)
  const [loaderStep, setLoaderStep] = useState(0)

  useEffect(() => {
    let timer: any
    if (!promiseInProgress) {
      timer = setTimeout(() => {
        setShowLoader(false)
        setPathIsChanged(false)
      }, 200)
    } else {
      // timer = setTimeout(() => {
      setShowLoader(true)
      // }, 200)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [promiseInProgress, loaderStep])

  useEffect(() => {
    setPathIsChanged(true)
  }, [location.pathname])

  useEffect(() => {
    setPathIsChanged(true)
    setLoaderStep(global.loaderStep)
  }, [global.loaderStep])

  return pathIsChanged && showLoader ? (
    <div
      style={{ zIndex: 999999, background: 'rgba(0,0,0,0.2)' }}
      className='loader fixed inset-0 flex justify-center items-center'
    >
      <div>
        <Diamond>
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
        </Diamond>
      </div>
    </div>
  ) : null
}
