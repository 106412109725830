import { Reducer } from 'redux'
import { ActionTypes, TAllBidsByTenderState } from './types'
import { getDataForPagination } from '../../../../utils/reducerUtils'

export const initialState: TAllBidsByTenderState = {
  loading: false,
  data: null,
  onlyWinnersData: null,
  errors: ''
}

const reducer: Reducer<TAllBidsByTenderState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_BIDS_BY_TENDER_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_ALL_BIDS_BY_TENDER_S:
      return { ...state, loading: false, errors: undefined, data: getDataForPagination(state.data, action.payload) }
    case ActionTypes.GET_ALL_BIDS_BY_TENDER_WITH_0_OFFSET_S:
      return { ...state, loading: false, errors: undefined, data: action.payload }
    case ActionTypes.GET_ALL_BIDS_BY_TENDER_E:
      return { ...state, loading: false, errors: action.payload, data: [] }
    
    case ActionTypes.GET_ALL_BIDS_BY_TENDER_WINNERS_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_ALL_BIDS_BY_TENDER_WINNERS_S:
      return { ...state, loading: false, errors: undefined, onlyWinnersData: action.payload }
    case ActionTypes.GET_ALL_BIDS_BY_TENDER_WINNERS_E:
      return { ...state, loading: false, errors: action.payload, onlyWinnersData: [] }

    case ActionTypes.MAKE_SALES_ORDERS_AND_INVOICES_IN_NS_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.MAKE_SALES_ORDERS_AND_INVOICES_IN_NS_S:
      return { ...state, loading: false, errors: undefined }
    case ActionTypes.MAKE_SALES_ORDERS_AND_INVOICES_IN_NS_E:
      return { ...state, loading: false, errors: action.payload }  
    
    default:
      return state
  }
}

export { reducer as AllBidsByTenderReducer }