import { useEffect } from 'react'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { checkToken, ReturnType } from '../utils/checkToken'
import { ROUTES } from '../utils/routes'
import { listenLogin, setStore } from '../utils/functions'
import { ENVIRONMENT, RECOMMEND_MOD, TOKEN } from '../utils/consts'
import { AppStore } from '../store/applicationState'
import { useLoginFromWorthy } from './useLoginFromWorthy'

export const useLoadApp = () => {
  const history = useHistory()
  const location = useLocation()
  const { checkRedirectToWorthy } = useLoginFromWorthy()

  const dispatch = useDispatch()
  const { signIn } = useSelector((store: AppStore) => store)

  const { access_token, mode } = queryString.parse(location.search)

  useEffect(() => {
    if (signIn.authorized) {
      ;(async () => {
        const userToken: ReturnType = await checkToken()
        if (userToken.success) {
          checkRedirectToWorthy()
          setStore(userToken, dispatch)
          if (location.pathname.startsWith(ROUTES.successReset)) history.replace(ROUTES.dashboard)
        }
      })()
    }
    window.addEventListener('storage', listenLogin)
    return () => {
      window.removeEventListener('storage', listenLogin)
    }
  }, [signIn.authorized])

  useEffect(() => {
    const init = () => {
      let reload = false
      if (typeof access_token === 'string' && !sessionStorage.getItem(TOKEN.access)) {
        sessionStorage.setItem(TOKEN.access, access_token)
        reload = true
      }
      if (typeof mode === 'string' && !sessionStorage.getItem(RECOMMEND_MOD)) {
        sessionStorage.setItem(RECOMMEND_MOD, mode)
        reload = true
      }
      if (reload) window.location.reload()
    }

    init()

    if (ENVIRONMENT === 'local') document.title = `[LOC] | ${document.title}`
    else if (ENVIRONMENT === 'development') document.title = `[DEV] | ${document.title}`
    else if (ENVIRONMENT === 'test') document.title = `[TEST] | ${document.title}`
    else if (ENVIRONMENT === 'stage') document.title = `[STAGE] | ${document.title}`
  }, [])
}
