import React from 'react'

export const MobileMenuLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g fill="#767D98">
          <g>
            <g>
              <path
                d="M4 17v2h16v-2H4zm0-6v2h11.556v-2H4zm0-6v2h16V5H4z"
                transform="translate(-24 -84) translate(0 60) translate(24 24)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
