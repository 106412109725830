import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from './Input'
import { Sort } from '../../../svg/Sort'
import { useClickOutside } from '../../../hooks/useClickOutside'
import { getIconForFullAccessCategories } from '../../../utils/userUtils/IconHelpers'
import { getShortDate } from '../../../utils/helpers'
import { AppStore } from '../../../store/applicationState'
import { getStatus, getStatusName } from '../../../utils/tendersUtils/tenderStatusHelper'
import { GetAllTenders } from '../../../store/admin/tenders/actions'
import { TTenders } from '../../../store/admin/tenders/types'

interface DropDownImageManageProps {
  tenderId: number
  setTenderId: (value: TTenders) => void
}

export const DropDownImageManage: React.FC<DropDownImageManageProps> = ({ tenderId, setTenderId }) => {
  const dispatch = useDispatch()
  const { tenders } = useSelector((store: AppStore) => store)

  const [listState, setListState] = useState<TTenders[] | null>(null)
  const [visible, setVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [choosenItem, setChoosenItem] = useState<TTenders | null>(
    tenders.data?.find((tender) => tender.id === tenderId) || null
  )

  const dropDownRef = useRef(null)
  const { clickOutside } = useClickOutside(dropDownRef)

  useEffect(() => {
    if (clickOutside) setVisible(false)
  }, [clickOutside])

  useEffect(() => {
    if (visible) {
      dispatch(GetAllTenders.request())
    }
  }, [visible])

  useEffect(() => {
    if (tenders.data)
      setListState(
        tenders.data.filter(
          (item) =>
            ['active', 'catalog', 'upcoming'].includes(getStatusName(item)) &&
            (item.category_id === 2 || item.category_id === 3)
        )
      )
    return () => setListState((prev) => prev)
  }, [tenders.data])

  const onChange = (e: any) => {
    const { value } = e.target
    setInputValue(value)
    const searchList = listState?.filter((tender) => tender.sales_cycle.toLowerCase().match(value.toLowerCase()))
    setListState(searchList || [])
  }

  return (
    <div ref={dropDownRef} className='relative w-full md:w-[420px]'>
      <button
        type='button'
        className='dropdown flex items-center w-full bg-white border border-bluegray-200 p-3 xxrounded-md text-left focus:outline-none focus:ring-1 focus:ring-bluegray-100 focus:border-ool-gray-100 text-sm smallMobile:text-lg'
        onClick={() => setVisible(true)}
      >
        {choosenItem ? (
          <span className='flex items-center'>
            <div className='w-6 flex justify-center'>{getIconForFullAccessCategories(choosenItem.category_id)}</div>
            <span className='ml-3 block truncate font-semibold'>
              <div className='flex justify-between leading-none items-center space-x-3'>
                <div className=''>{choosenItem.sales_cycle}</div>
              </div>
              <div className='text-xs text-bluegray-500 font-normal flex  ssmallest:space-x-1 smallMobile:space-x-2 flex-col ssmallest:flex-row'>
                {getStatus(choosenItem)}
                <div>{getShortDate(choosenItem.finalized_at)}</div>
              </div>
            </span>
          </span>
        ) : (
          <span className='opacity-75'>Please select the tender</span>
        )}
        <span className='ml-auto flex items-center pointer-events-none'>
          <Sort />
        </span>
      </button>
      <div
        style={visible ? { display: 'flex' } : {}}
        className='dropdown-content p-2 absolute hidden flex-col z-20 bg-white left-0 right-0 border border-t-0 border-ool-gray-100'
      >
        <Input
          className='rounded focus:border-green-700'
          placeholder='Search tender...'
          value={inputValue}
          onChange={onChange}
        />
        <div className='flex flex-col my-2 h-60 overflow-auto whitespace-nowrap'>
          <table>
            <tbody>
              {listState?.map((item) => {
                return (
                  <tr
                    key={item.id}
                    className={`${
                      item?.id === choosenItem?.id ? 'opacity-20' : 'hover:opacity-70 cursor-pointer'
                    } select-none`}
                    onClick={() => {
                      if (item?.id !== choosenItem?.id) {
                        setChoosenItem(item)
                        setTenderId(item)
                        setVisible(false)
                      }
                    }}
                  >
                    <td className=''>
                      <div className='flex items-center justify-center'>
                        {getIconForFullAccessCategories(item.category_id)}
                      </div>
                    </td>
                    <td>{item.sales_cycle}</td>
                    <td>{getStatus(item)}</td>
                    <td>{getShortDate(item.finalized_at)}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
