export interface TAcquisitionHistory {
  bid_buy_now: number,
  bid_win: number,
  category_id: number,
  finalized_at: string,
  sales_cycle: string,
  tender_id: number,
  tender_status: string,
  total_spend: number,
  unsuccessful_bids: number
}

export interface TAcquisitionPerformance {
  count_of_buy_now: number,
  count_of_lost_by_more_then_nine: number,
  count_of_lost_by_six_nine_percents: number,
  count_of_lost_by_three_six_percents: number,
  count_of_lost_by_zero_three_percents: number,
  count_of_winning_bids: number,
  finalized_at: string,
  sales_cycle: string,
  id: number
}

export interface TIcs {
  file: any,
  file_name: string
}

export interface TTenderSchedule {
  category_id: number,
  count_of_products: number,
  finalized_at: string,
  preview_start_at: string,
  sales_cycle: string,
  start_at: string,
  tender_id: number
}

export interface TDashboardMessage {
  message: string
}

export interface TDashboardState {
  loading: boolean
  data: {
    acquisitionHistory: TAcquisitionHistory[] | null
    acquisitionPerformance: TAcquisitionPerformance[] | null
    tenderSchedule: TTenderSchedule[] | null
    ics: any | null
    allAcquisition: any | null
  }
  errors?: string | undefined
}

export enum ActionTypes {
  GET_ACQUISITION_HISTORY_R = '@@DASHBOARD/GET_ACQUISITION_HISTORY_R',
  GET_ACQUISITION_HISTORY_S = '@@DASHBOARD/GET_ACQUISITION_HISTORY_S',
  GET_ACQUISITION_HISTORY_WITH_0_OFFSET_S = '@@DASHBOARD/GET_ACQUISITION_HISTORY_WITH_0_OFFSET_S',
  GET_ACQUISITION_HISTORY_E = '@@DASHBOARD/GET_ACQUISITION_HISTORY_E',

  GET_ACQUISITION_PERFORMANCE_R = '@@DASHBOARD/GET_ACQUISITION_PERFORMANCE_R',
  GET_ACQUISITION_PERFORMANCE_S = '@@DASHBOARD/GET_ACQUISITION_PERFORMANCE_S',
  GET_ACQUISITION_PERFORMANCE_E = '@@DASHBOARD/GET_ACQUISITION_PERFORMANCE_E',

  GET_TENDER_SCHEDULE_R = '@@DASHBOARD/GET_TENDER_SCHEDULE_R',
  GET_TENDER_SCHEDULE_S = '@@DASHBOARD/GET_TENDER_SCHEDULE_S',
  GET_TENDER_SCHEDULE_E = '@@DASHBOARD/GET_TENDER_SCHEDULE_E',

  GET_ICS_R = '@@DASHBOARD/GET_ICS_R',
  GET_ICS_S = '@@DASHBOARD/GET_ICS_S',
  GET_ICS_E = '@@DASHBOARD/GET_ICS_E',

  GET_ALL_ACQUISITIONS_R = '@@DASHBOARD/GET_ALL_ACQUISITIONS_R',
  GET_ALL_ACQUISITIONS_S = '@@DASHBOARD/GET_ALL_ACQUISITIONS_S',
  GET_ALL_ACQUISITIONS_E = '@@DASHBOARD/GET_ALL_ACQUISITIONS_E',
}
