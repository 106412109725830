import React from 'react'
import { useSelector } from 'react-redux'
import { TTenders } from '../../../store/admin/tenders/types'
import { AppStore } from '../../../store/applicationState'
import { Close } from '../../../svg/Close'
import { IconCheckCircle } from '../../../svg/IconCheckCircle'
import { IconTimes } from '../../../svg/IconTimes'
import { DiamondLoader } from '../DiamondLoader'

interface CheckImagesExistModalProps {
  setOpenModal: (value: boolean) => void
  loading: boolean
  selectedTender: TTenders
}

export const CheckImagesExistModal: React.FC<CheckImagesExistModalProps> = ({
  setOpenModal,
  loading,
  selectedTender
}) => {
  const { mediaManagement } = useSelector((store: AppStore) => store)

  const getIcon = (imagesCount: number) => {
    return (
      imagesCount > 0 ? (
        <IconCheckCircle styled='text-green-500 ml-1' />
      ) : (
        <IconTimes styled='text-red-500 ml-1' />
      )
    )
  }

  return (
    <div className='check-images-popup w-full h-screen fixed bg-bluegray-900 top-0 left-0 z-110 bg-opacity-90 justify-center flex items-center pt-1/12 popup-body'>
      <div className='max-h-screen w-full lg:w-8/12 max-w-3xl relative overflow-y-auto'>
        <button
          type='button'
          className='close-popup absolute right-2 top-0 text-lg p-2 z-50'
          onClick={() => setOpenModal(false)}
        >
          <Close />
        </button>
        <div className='min-h-[192px] bg-white xrounded shadow-sm py-8 flex items-center justify-center relative w-full'>
          {loading ? (
            <div className='check-images-loading absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center flex-col z-10 bg-opacity-20 bg-gray-900'>
              <DiamondLoader />
              <div className='mt-2 text-lg text-black'>
                Please wait until the script finishes checking items for images.
              </div>
            </div>
          ) : (
            <div className='max-w-full px-2'>
              {mediaManagement.data.tenderImagesStatus ? (
                <div className='check-images-info w-full'>
                  <div className=' font-semibold mb-1'>
                    {`Please review the items images availability report for Tender ${selectedTender?.sales_cycle}:`}
                  </div>

                  <ul className='ml-2'>
                    {mediaManagement.data.tenderImagesStatus.map((tenderItem, index) => {
                      return (
                        <li key={tenderItem.sku} className='flex space-x-2'>
                          <span className='w-44 overflow-x-auto'>{`${index + 1}. SKU: ${tenderItem.sku}`}</span>
                          <span className='flex items-center w-44'>
                            Regular images:
                            <span className='w-6 ml-1'>{tenderItem.regular_images}</span>
                            {getIcon(tenderItem.regular_images)}
                          </span>
                          {selectedTender?.category_id === 2 ? (
                            <span className='flex items-center w-44'>
                              Video:
                              <span className='w-6 ml-1'>{tenderItem.video_mp4}</span>
                              {getIcon(tenderItem.video_mp4)}
                            </span>
                          ) : null}
                        </li>
                      )
                    })}
                  </ul>
                  <div className='mt-4'>The list was duplicated to the email tender@circajewels.com</div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
