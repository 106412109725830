import React, { useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// @ts-ignore
import Tridi from 'react-tridi'
import 'react-tridi/dist/index.css'
import { ItemPopupFinalizedStatus, ItemPopupStatus } from '../itemLabels'
import { IconNoChecked } from '../../../../svg/IconNoChecked'
import { IconChecked } from '../../../../svg/IconChecked'
import { TGalleryItemInfo } from '../../../../store/gallery/types'
import { useZoom } from '../../../../hooks/useZoom.js'
import { isMobileDevice } from '../../../../utils/helpers'
import { IconLike } from '../../../../svg/IconLike'
import { getUniqueId } from '../../../../utils/FileUtils'
import { changeImageUrl } from '../../../../utils/imgUrlParse/imgUrlParser'
import { BidWidgetSimple } from './BidWidgetSimple'
import { addToFavoritesAction, addToRecommendedAction } from '../../../../utils/galleryUtils/itemsActions'
import { AppStore } from '../../../../store/applicationState'
import { TenderTimer } from '../../headerUi/TenderTimer'
import { Necklace } from '../../../../svg/Necklace'
import { JewelryAdditionalDetails } from './JewelryAdditionalDetails'
import { IconPauseCircle } from '../../../../svg/IconPauseCircle'
import { IconPlayCircle } from '../../../../svg/IconPlayCircle'
import { IconArrowCircle } from '../../../../svg/IconArrowCircle'
import { IconGlassPlus } from '../../../../svg/IconGlassPlus'
import { IconGlassMinus } from '../../../../svg/IconGlassMinus'
import { IconFourArrows } from '../../../../svg/IconFourArrows'
import { IconCircleStop } from '../../../../svg/IconCircleStop'
import { Icon360 } from '../../../../svg/Icon360'
import { recMode } from '../../../../utils/consts'
import { IconStar } from '../../../../svg/IconStar'

interface JewelryPopupBodyProps {
  item: TGalleryItemInfo
  isCatalog: boolean
  finalized: boolean
  setPurchasePopupActive: (value: boolean) => void
}

export const JewelryPopupBody: React.FC<JewelryPopupBodyProps> = ({
  item,
  isCatalog,
  finalized,
  setPurchasePopupActive,
}) => {
  const dispatch = useDispatch()
  const { userData, gallery } = useSelector((store: AppStore) => store)

  const [prewImg, setprewImg] = useState(item.product_gallery.images[0])
  const [imgError, setImgError] = useState(false)
  const [videoTabActive, setVideoTabActive] = useState(item.product_gallery.part_of_media_file.length > 1)
  const [isAutoPlayRunning, setIsAutoPlayRunning] = useState(false)
  const [isMovingRunning, setIsMovingRunning] = useState(false)
  const [grabbing, setGrabbing] = useState(false)
  const [zoomValue, setzoomValue] = useState(1)
  const tridiRef = useRef(null)

  if (!isMobileDevice()) useZoom(!prewImg || videoTabActive)
  // useZoom(!prewImg)
  const idTender = Number(window.location.pathname.split('/').find((elem) => Number(elem))) || 0

  const statusBody = useMemo(
    () => (
      <div>
        {finalized ? (
          <ItemPopupFinalizedStatus item={item} />
        ) : (
          <div>
            {finalized ? (
              <ItemPopupFinalizedStatus item={item} />
            ) : (
              <div>
                {!item.is_sold ? (
                  <BidWidgetSimple
                    item={item}
                    setPurchasePopupActive={setPurchasePopupActive}
                    tenderId={Number(window.location.pathname.split('/').find((elem) => Number(elem))) || 0}
                  />
                ) : (
                  <ItemPopupStatus item={item} />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    ),
    [item, item.is_sold, item.bid_status_id, item.bid, finalized]
  )

  return (
    <div className=' bg-white shadow-2xl-dark'>
      {!finalized ? (
        <div className='tender-type text-base  tracking-widest flex  space-x-6 font-semibold items-center  justify-center border-b py-2'>
          {gallery.data.tenderInfo ? <TenderTimer tender={gallery.data.tenderInfo} /> : null}
        </div>
      ) : null}

      <div className='md:flex md:flex-row flex-col md:space-x-8 p-6'>
        <div className='img w-12/12 md:w-5/12'>
          {videoTabActive && item.product_gallery.part_of_media_file.length > 1 ? (
            <div className='video-block relative border border-bluegray-200 min-w-full h-auto'>
              <div
                aria-hidden
                className='video-container relative'
                // onClick={() => {
                //   // @ts-ignore
                //   tridiRef.current.toggleAutoplay(false)
                //   setIsAutoPlayRunning(false)
                // }}
                onMouseDown={() => {
                  // @ts-ignore
                  tridiRef.current.toggleAutoplay(false)
                  setIsAutoPlayRunning(false)
                  setGrabbing(true)
                }}
                onMouseUp={() => setGrabbing(false)}
                onMouseLeave={() => setGrabbing(false)}
              >
                <Tridi
                  ref={tridiRef}
                  className={`jewelry-video h-full z-20 active:cursor-grabbing ${
                    grabbing ? 'video-grabbing' : 'cursor-grab'
                  }`}
                  images={item.product_gallery.part_of_media_file}
                  format='jpg'
                  count={item.product_gallery.part_of_media_file.length}
                  autoplay={isAutoPlayRunning}
                  zoom={zoomValue}
                  mousewheel
                  mouseleaveDetect
                  autoplaySpeed={140}
                  // dragInterval={2}
                  // inverse
                  onLoadChange={(loadSuccess: any) => {
                    if (loadSuccess) setIsAutoPlayRunning(true)
                  }}
                />
              </div>

              <div className='video-control-buttons absolute bottom-0 left-0 flex justify-around w-full p-2 text-2xl text-slate-600 bg-white bg-opacity-20 z-40'>
                <button
                  type='button'
                  className='video-control-play mx-1 px-1'
                  onClick={() => {
                    // @ts-ignore
                    tridiRef.current.toggleAutoplay(!isAutoPlayRunning)
                    setIsAutoPlayRunning(!isAutoPlayRunning)
                  }}
                >
                  {isAutoPlayRunning ? <IconPauseCircle /> : <IconPlayCircle />}
                </button>
                <button
                  type='button'
                  className='video-control-prev mx-1 px-1'
                  onClick={() => {
                    // @ts-ignore
                    tridiRef.current.prev()
                  }}
                  disabled={zoomValue <= 1 || isAutoPlayRunning}
                >
                  <IconArrowCircle />
                </button>
                <button
                  type='button'
                  className='video-control-next mx-1 px-1'
                  onClick={() => {
                    // @ts-ignore
                    tridiRef.current.next()
                  }}
                  disabled={zoomValue <= 1 || isAutoPlayRunning}
                >
                  <IconArrowCircle className='rotate-180' />
                </button>
                <button
                  className='video-control-zoom-minus mx-1 px-1'
                  type='button'
                  onClick={() => {
                    if (zoomValue > 1) setzoomValue((prev) => prev - 0.75)
                  }}
                >
                  <IconGlassMinus />
                </button>
                <button
                  className='video-control-zoom-plus mx-1 px-1'
                  type='button'
                  onClick={() => {
                    if (zoomValue < 3) setzoomValue((prev) => prev + 0.75)
                  }}
                >
                  <IconGlassPlus />
                </button>
                <button
                  className='video-control-move mx-1 px-1'
                  type='button'
                  onClick={() => {
                    // @ts-ignore
                    tridiRef.current.toggleMoving(!isMovingRunning)
                    setIsMovingRunning(!isMovingRunning)
                    // @ts-ignore
                    tridiRef.current.toggleAutoplay(false)
                    setIsAutoPlayRunning(false)
                    if (isMovingRunning) setzoomValue(1)
                  }}
                >
                  {isMovingRunning ? <IconCircleStop /> : <IconFourArrows />}
                </button>
              </div>
            </div>
          ) : (
            <div className='main-image item-image relative'>
              {item.product_gallery.images.length && !imgError ? (
                <div>
                  <div className='image-holder'>
                    <img
                      id='slideImage'
                      className={`border border-bluegray-200 ${isMobileDevice() ? '' : 'xzoom'}`}
                      // @ts-ignore i
                      xoriginal={changeImageUrl(prewImg)}
                      src={changeImageUrl(prewImg)}
                      onError={() => setImgError(true)}
                      alt='Jewelry'
                    />
                    <div className='absolute z-100 right-0 top-0 bottom-0 w-4' />
                    <div className='absolute z-100 left-0 top-0 bottom-0 w-4' />
                    <div className='absolute z-100 left-0 top-0 right-0 h-4' />
                    <div className='absolute z-100 left-0 bottom-0 right-0 h-4' />
                  </div>
                </div>
              ) : (
                <div className='text-bluegray-400 flex items-center justify-center h-72 w-auto text-7xl border border-bluegray-200'>
                  <Necklace />
                </div>
              )}
            </div>
          )}

          {/* <div className='main-image item-image relative'>
            {item.product_gallery.images.length && !imgError ? (
              <div>
                <div className='image-holder'>
                  <img
                    id='slideImage'
                    className={`border border-bluegray-200 xzoom ${isMobileDevice() && 'zm'}`}
                    // @ts-ignore i
                    xoriginal={changeImageUrl(prewImg)}
                    src={changeImageUrl(prewImg)}
                    onError={() => setImgError(true)}
                    alt='Jewelry'
                  />
                  <div className='absolute z-100 right-0 top-0 bottom-0 w-4' />
                  <div className='absolute z-100 left-0 top-0 bottom-0 w-4' />
                  <div className='absolute z-100 left-0 top-0 right-0 h-4' />
                  <div className='absolute z-100 left-0 bottom-0 right-0 h-4' />
                </div>
              </div>
            ) : (
              <div className='text-bluegray-400 flex items-center justify-center h-72 w-auto text-7xl border border-bluegray-200'>
                <Necklace />
              </div>
            )}
          </div> */}

          <div className='grid grid-cols-4 gap-4 py-4 images-grid'>
            {item.product_gallery.part_of_media_file.length > 1 && (
              <div
                className='video border border-bluegray-200 hover:border-lightblue-600 cursor-pointer text-base relative'
                onClick={() => {
                  setVideoTabActive(true)
                  setIsAutoPlayRunning(true)
                }}
                aria-hidden='true'
              >
                <img src={item.product_gallery.part_of_media_file[0]} alt='Jewelry' className=' opacity-50' />
                <div className='absolute top-0 left-0 w-full h-full text-4xl text-gray-700 flex justify-center items-center'>
                  <Icon360 />
                </div>
              </div>
            )}

            {item.product_gallery.images.map((image) => (
              <div
                onClick={() => {
                  setprewImg(image)
                  setVideoTabActive(false)
                  setIsAutoPlayRunning(false)
                }}
                aria-hidden='true'
                className='common-image border  border-bluegray-200 hover:border-lightblue-600 cursor-pointer'
                key={getUniqueId()}
              >
                <img src={changeImageUrl(image)} alt='Jewelry' className='border border-bluegray-200' />
              </div>
            ))}
          </div>
        </div>

        <div className='md:w-7/12 w-12/12 '>
          <div className='h-full space-y-6 md:space-y-6'>
            <div className='flex justify-between items-center'>
              <div className='title text-2xl text-bluegray-900 leading-none font-circa font-medium'>
                {item.product.title}
              </div>

              {finalized ? (
                ''
              ) : (
                <div className='flex flex-row gap-2'>
                  {((gallery.data.atLeastOneRecommended && item.in_recommendations) || recMode) && (
                    <button
                      type='button'
                      disabled={!recMode}
                      onClick={() => {
                        addToRecommendedAction(item.in_recommendations, userData.companyId!, item.item_id, dispatch)
                      }}
                      className='recommendations text-yellow-500 text-lg disabled:opacity-100'
                    >
                      <IconStar className='!w-6 !h-6' isActive={item.in_recommendations} />
                    </button>
                  )}
                  <button
                    type='button'
                    onClick={() => addToFavoritesAction(item.in_favorites, idTender, item.item_id, dispatch)}
                    className='favorites text-2xl text-red-500 ml-2'
                  >
                    <IconLike isActive={item.in_favorites} />
                  </button>
                </div>
              )}
            </div>

            <div className='space-y-2'>
              <div className='flex text-base space-x-8 text-bluegray-600'>
                {item.product.product_details.weight_grams ? (
                  <div className='weight-grams'>
                    <b className='text-black'>Item Weight</b>
                    {`: ${item.product.product_details.weight_grams}`}
                  </div>
                ) : null}
                {item.product.product_details.year ? (
                  <div className='year'>
                    <b className='text-black'>Year</b>
                    {`: ${item.product.product_details.year}`}
                  </div>
                ) : null}
              </div>

              <div className='lg:flex text-base lg:space-x-8 text-bluegray-600 space-y-2 lg:space-y-0'>
                {item.product.product_details.condition ? (
                  <div className='condition'>
                    <b className='text-black'>Condition</b>
                    {`: ${item.product.product_details.condition}`}
                  </div>
                ) : null}

                <div className='flex space-x-6'>
                  <div className='has-box items-center flex'>
                    <div className='font-bold '>Box</div>
                    :
                    {item.product.product_details.box === 'Yes' ? (
                      <span className='text-green-300 ml-2'>
                        <IconChecked />
                      </span>
                    ) : (
                      <span className='text-red-400 ml-2'>
                        <IconNoChecked />
                      </span>
                    )}
                  </div>

                  <div className='has-papers font-bold items-center flex'>
                    <div className='font-bold '>Papers</div>
                    :
                    {item.product.product_details.papers === 'Yes' ? (
                      <span className='text-green-300 ml-2'>
                        <IconChecked />
                      </span>
                    ) : (
                      <span className='text-red-400 ml-2'>
                        <IconNoChecked />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className=''>{!isCatalog && statusBody}</div>

            {item.product.product_details.jewelry_comments ? (
              <div className='circa-comments'>
                <div className='font-semibold text-base mb-3'>CIRCA Comments</div>

                <div className='text-bluegray-600'>{item.product.product_details.jewelry_comments}</div>
              </div>
            ) : null}

            <JewelryAdditionalDetails item={item} />
          </div>
        </div>
      </div>
    </div>
  )
}
