import React from 'react'

export const File = ({ stroke = '' }) => {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.6425 15.4825L15.4825 19.6425C15.0136 20.1116 14.3774 20.3751 13.7142 20.375H4.125C2.74429 20.375 1.625 19.2557 1.625 17.875V4.125C1.625 2.74429 2.74429 1.625 4.125 1.625H17.875C19.2557 1.625 20.375 2.74429 20.375 4.125V13.7142C20.3751 14.3774 20.1116 15.0136 19.6425 15.4825Z'
        stroke={stroke || '#114B6D'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.125 20.3408V16.625C14.125 15.2443 15.2443 14.125 16.625 14.125H20.3408'
        stroke={stroke || '#114B6D'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}