import { useEffect } from 'react'

export interface paginationProps {
  limit: number
  offset: number

  [x: string]: any
}

export const usePagination = (
  data: any[] | null | undefined,
  setCallback: (value: ((prevState: paginationProps) => paginationProps) | paginationProps) => void,
  limitAmount: number
) => {
  useEffect(() => {
    let isLoaded = false
    const onChangeScroll = () => {
      if (
        !isLoaded &&
        document.documentElement.scrollHeight - window.scrollY - document.documentElement.clientHeight < 450
      ) {
        isLoaded = true
        setCallback((prev: paginationProps) => ({ ...prev, offset: prev.offset + prev.limit, limit: limitAmount }))
      }
    }
    window.addEventListener('scroll', onChangeScroll)
    return () => window.removeEventListener('scroll', onChangeScroll)
  }, [data])
}
