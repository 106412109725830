import React from 'react'

export const IconFilePdf = () => {
  return (
    <svg
      className="svg-inline--fa fa-file-pdf"
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="file-pdf"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
      data-fa-i2svg=""
    >
      <path fill="currentColor" d="M384 448C384 483.3 355.3 512 320 512H64C28.65 512 0 483.3 0 448H32C32 465.7 46.33 480 64 480H320C337.7 480 352 465.7 352 448H384zM240 192C213.5 192 192 170.5 192 144V32H64C46.33 32 32 46.33 32 64V224H0V64C0 28.65 28.65 0 64 0H220.1C232.8 0 245.1 5.057 254.1 14.06L369.9 129.9C378.9 138.9 384 151.2 384 163.9V224H352V192H240zM347.3 152.6L231.4 36.69C229.4 34.62 226.8 33.18 224 32.48V144C224 152.8 231.2 160 240 160H351.5C350.8 157.2 349.4 154.6 347.3 152.6zM88 256C118.9 256 144 281.1 144 312C144 342.9 118.9 368 88 368H80V400C80 408.8 72.84 416 64 416C55.16 416 48 408.8 48 400V272C48 263.2 55.16 256 64 256H88zM112 312C112 298.7 101.3 288 88 288H80V336H88C101.3 336 112 325.3 112 312zM160 272C160 263.2 167.2 256 176 256H200C226.5 256 248 277.5 248 304V368C248 394.5 226.5 416 200 416H176C167.2 416 160 408.8 160 400V272zM192 384H200C208.8 384 216 376.8 216 368V304C216 295.2 208.8 288 200 288H192V384zM336 256C344.8 256 352 263.2 352 272C352 280.8 344.8 288 336 288H304V320H336C344.8 320 352 327.2 352 336C352 344.8 344.8 352 336 352H304V400C304 408.8 296.8 416 288 416C279.2 416 272 408.8 272 400V272C272 263.2 279.2 256 288 256H336z" />
    </svg>
  )
}
