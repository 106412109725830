import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { AppStore } from '../../../store/applicationState'
import { TGalleryItemInfo } from '../../../store/gallery/types'
import { IconImg } from '../../../svg/IconImg'
import { IconLikeGallery } from '../../../svg/IconLikeGallery'
import { Sort } from '../../../svg/Sort'
import { addNewParams } from '../../../utils/helpers'
import { PurchasePopup } from './PurchasePopup'
import { TableItem } from './TableItem'
import { recMode } from '../../../utils/consts'

const options = [
  { title: <IconLikeGallery isActive={false} bgColor='text-bluegray-700' />, value: 'favorites', sort: '' },
  { title: '', value: 'reports', sort: '' },
  { title: 'ID', value: 'id', sort: 'sku' },
  { title: <IconImg />, value: 'img', sort: '' },
  { title: 'SHAPE ', value: 'shape', sort: 'shape' },
  { title: 'WEIGHT', value: 'weight', sort: 'weight' },
  { title: 'COLOR', value: 'color', sort: 'color' },
  { title: 'CLARITY', value: 'clarity', sort: 'clarity' },
  { title: 'RAP LIST', value: 'rap-rate', sort: 'list_price' },
  { title: 'PPC', value: 'ppc', sort: 'price_per_carat' },
  { title: 'DISC (%)', value: 'disc', sort: 'ask_discount' },
  // {title: 'TOTAL', value: 'total', sort: 'asking_price'},
  { title: 'CUT', value: 'cut', sort: 'cut' },
  { title: 'POL', value: 'pol', sort: 'polish' },
  { title: 'SYM', value: 'sym', sort: 'symmetry' },
  { title: 'COMMENTS', value: 'comments', sort: 'circa_comments' },
]

const adminOptions = [
  { title: '# of Bids', value: 'bids', sort: 'count_of_bids' },
  { title: 'Owner', value: 'owner', sort: '' },
]

interface GalleryListProps {
  sortParams: string
  sortByParams: string
  setSort: (value: string) => void
  setSortBy: (value: string) => void
  isCatalog: boolean
  favorites: boolean
  setFavorites: (value: boolean) => void
}

export const DiamondTable: React.FC<GalleryListProps> = ({
  sortParams,
  sortByParams,
  setSort,
  setSortBy,
  isCatalog,
  favorites,
  setFavorites,
}) => {
  const [purchasePopupActive, setPurchasePopupActive] = useState(false)

  const { gallery } = useSelector((store: AppStore) => store)

  const items = gallery.data.tenderItems || []
  const tenderId = gallery.data.tenderInfo?.tender_info.tender_id || 0
  const [currentItem, setCurrentItem] = useState(items[0])

  const getSort = (sortBy: string) => {
    if (sortBy !== sortByParams || sortParams === 'DESC') {
      setSortBy(sortBy)
      setSort('ASC')
    } else {
      setSortBy(sortBy)
      setSort('DESC')
    }
  }

  const tableTitle = (title: any, value: string, sort: string) => {
    switch (value) {
      case 'favorites':
        return (
          <button
            type='button'
            className='uppercase text-xs text-bluegray-700 font-semibold whitespace-nowrap'
            onClick={() => {
              setFavorites(!favorites)
              addNewParams('favorites', `${!favorites}`)
            }}
          >
            <IconLikeGallery isActive={favorites} bgColor='text-bluegray-700' />
          </button>
        )

      case 'reports':
        return title

      case 'img':
        return title

      default:
        return (
          <button
            type='button'
            className={`sort-by-${sort} uppercase text-xs text-bluegray-700 font-semibold whitespace-nowrap`}
            onClick={() => getSort(sort)}
          >
            {title}

            <Sort />
          </button>
        )
    }
  }

  return (
    <>
      <table className='divide-y divide-gray-200 proview mx-auto table-fixed'>
        <thead className='bg-gray-50'>
          <tr>
            {options.map((option) =>
              (option.title === 'DISC (%)' || option.title === 'TOTAL' || option.title === 'PPC') &&
              isCatalog ? null : (
                <th
                  className='uppercase px-5 py-3 text-xs text-bluegray-700 font-semibold border-l border-bluegray-100 whitespace-nowrap text-center'
                  key={option.value}
                >
                  {tableTitle(option.title, option.value, option.sort)}
                </th>
              )
            )}
            {recMode &&
              adminOptions.map((option) => (
                <th
                  className='uppercase px-5 py-3 text-xs bg-orange-50 text-bluegray-700 font-semibold border-l border-bluegray-100 whitespace-nowrap text-center'
                  key={option.value}
                >
                  {tableTitle(option.title, option.value, option.sort)}
                </th>
              ))}
          </tr>
        </thead>
        <tbody className='bg-white divide-y divide-gray-200 w-full'>
          {items.length > 0 ? (
            items.map((item: TGalleryItemInfo, i: number) => (
              <TableItem
                setPurchasePopupActive={setPurchasePopupActive}
                setCurrentItem={setCurrentItem}
                isCatalog={isCatalog}
                key={item.item_id}
                i={i}
                item={item}
                tenderId={gallery.data.tenderInfo?.tender_info.tender_id}
              />
            ))
          ) : (
            <tr>
              <td colSpan={10000} height='250' className='text-center w-screen'>
                No available items
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {purchasePopupActive && (
        <PurchasePopup
          purchasePopupActive={purchasePopupActive}
          setPurchasePopupActive={setPurchasePopupActive}
          item={currentItem}
          tenderId={tenderId}
        />
      )}
    </>
  )
}
