import React, { useRef, useState } from 'react'
import { useOnClickOutsideDropDown } from '../../../hooks/useOnClickOutsideDropDown'
import { Arrow } from '../../../svg/Arrow'
// import { filtersChange } from '../../../utils/dashboardUtils/EventHandlers'

interface DashboardSelectProps {
  options: { value: string, text: string }[];
  activeFilter: string;
  callback: (value: string) => void,
}

export const DashboardSelect: React.FC<DashboardSelectProps> = ({
  options,
  activeFilter,
  callback,
}) => {
  const [dropDownActive, setDropDownActive] = useState(false)
  const dropDownRef = useRef(null)

  useOnClickOutsideDropDown(dropDownRef, () => setDropDownActive(false))

  return (
    <div ref={dropDownRef} className='relative w-full'>
      <button
        type='button'
        onClick={() => setDropDownActive(!dropDownActive)}
        className={`sort-dropdown-button outline-none bg-bluegray-50 text-xs cursor-pointer w-full appearance-none p-2 hover:bg-bluegray-100 ${dropDownActive && 'bg-bluegray-100'}`}
      >
        {options.find(option => option.value === activeFilter)?.text}
        <Arrow style={{ marginLeft: '8px', marginRight: '0px' }} />
      </button>

      <div
        className={`sort-dropdown absolute z-10 mt-1 w-full lg:w-48 flex-col bg-bluegray-100 shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none ${dropDownActive ? 'flex' : 'hidden'}`}
      >
        {options.map(option => (
          <button
            type='button'
            key={option.text}
            className='option select-none text-xs text-left relative py-2 px-3 text-gray-900 hover:text-white hover:bg-lightblue-600'
            onClick={() => {
              callback(option.value)
              setDropDownActive(false)
            }}
            disabled={option.value === activeFilter}
          >
            {option.text}
          </button>
        ))}
      </div>
    </div>
  )
}
