import { Reducer } from 'redux'
import { ActionTypes, TTenders, TTendersState } from './types'


const changeStatusSalesOrdersAndInvoices = (data: TTenders[] | null , payload: any) => {
  const { id, sales_orders_and_invoices_processing_status } = payload

      const newData:TTenders[] | null = data?.map((tender: TTenders) => {
        if (tender.id === id) {
          return { ...tender, sales_orders_and_invoices_processing_status }
        }
        return tender
      }) || null

  return newData
}

export const initialState: TTendersState = {
  loading: false,
  data: null,
  socketActionAmount: 0,
  updateTenderNow: false,
}

const reducer: Reducer<TTendersState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_TENDERS_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_ALL_TENDERS_S:
      return { ...state, loading: false, errors: undefined, data: action.payload }
    case ActionTypes.GET_ALL_TENDERS_E:
      return { ...state, loading: false, errors: action.payload }
    
    case ActionTypes.SOCKET_ACTION_OCCURRED:
      return { ...state, socketActionAmount: state.socketActionAmount + 1 }
    case ActionTypes.SOCKET_ACTION_OCCURRED_RESET:
      return { ...state, socketActionAmount: 0, updateTenderNow: false }
    
    case ActionTypes.UPDATE_TENDER_NOW:
      return { ...state, updateTenderNow: action.payload }

    case ActionTypes.CHANGE_STATUS_SALES_ORDERS_AND_INVOICES:
      return { ...state, data: changeStatusSalesOrdersAndInvoices(state.data, action.payload ) }
  
    default:
      return state
  }
}

export { reducer as TendersReducer }