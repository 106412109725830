import React from 'react'

export const IconCirclePlus = () => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-prefix='fal'
      data-icon='plus-circle'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      className='icon-circle-plus svg-inline--fa fa-plus-circle fa-w-16 fa-3x'
    >
      <path
        fill='currentColor'
        d='M384 250v12c0 6.6-5.4 12-12 12h-98v98c0 6.6-5.4 12-12 12h-12c-6.6 0-12-5.4-12-12v-98h-98c-6.6 0-12-5.4-12-12v-12c0-6.6 5.4-12 12-12h98v-98c0-6.6 5.4-12 12-12h12c6.6 0 12 5.4 12 12v98h98c6.6 0 12 5.4 12 12zm120 6c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-32 0c0-119.9-97.3-216-216-216-119.9 0-216 97.3-216 216 0 119.9 97.3 216 216 216 119.9 0 216-97.3 216-216z'
        className=''
      />
    </svg>
  )
}
