import { Reducer } from 'redux'
import { ActionTypes, TDiamondVideoState } from './types'

export const initialState: TDiamondVideoState = {
  loading: false,
  data: null,
}

const reducer: Reducer<TDiamondVideoState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_DIAMOND_VIDEO_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_DIAMOND_VIDEO_S:
      return { ...state, loading: false, errors: undefined, data: action.payload }
    case ActionTypes.GET_DIAMOND_VIDEO_E:
      return { ...state, loading: false, errors: action.payload }
    
    default:
      return state
  }
}

export { reducer as DiamondVideoReducer }
