import { Actions } from '../../../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../../../types/actions'


/// ///////////////////////////////////////////////////////////////////////
const GetBuyNowPurchasesActions = new Actions('GET_BUY_NOW_PURCHASES', ActionTypes)
const GetBuyNowPurchasesWith0OffsetActions = new Actions('GET_BUY_NOW_PURCHASES_WITH0_OFFSET', ActionTypes)

export interface TypeGetBuyNowPurchasesR extends TDefRequest {
  idTender: number
  offset?: number
  limit?: number
  search?: string
  sortBy?: string
  sort?: string
}

export const GetBuyNowPurchases = {
  request: (payload: TypeGetBuyNowPurchasesR) => GetBuyNowPurchasesActions.request(payload),
  success: (payload: any) => GetBuyNowPurchasesActions.success(payload),
  error: (payload: string) => GetBuyNowPurchasesActions.error(payload),
}

export const GetBuyNowPurchasesWith0Offset = {
  success: (payload: any) => GetBuyNowPurchasesWith0OffsetActions.success(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const DeleteBuyNowActions = new Actions('DELETE_BUY_NOW', ActionTypes)

export interface TypeDeleteBuyNowR extends TDefRequest {
  idItem: number
}

export const DeleteBuyNow = {
  request: (payload: TypeDeleteBuyNowR) => DeleteBuyNowActions.request(payload),
  success: (payload: any) => DeleteBuyNowActions.success(payload),
  error: (payload: string) => DeleteBuyNowActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetBuyNowPurchasesCsvActions = new Actions('GET_BUY_NOW_PURCHASES_CSV', ActionTypes)

export interface TypeGetBuyNowPurchasesCsvR extends TDefRequest {
  idTender: number
}

export const GetBuyNowPurchasesCsv = {
  request: (payload: TypeGetBuyNowPurchasesCsvR) => GetBuyNowPurchasesCsvActions.request(payload),
  success: (payload: any) => GetBuyNowPurchasesCsvActions.success(payload),
  error: (payload: string) => GetBuyNowPurchasesCsvActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetTenderStatsForBuyNowPurchasesActions = new Actions('GET_TENDER_STATS_FOR_BUY_NOW_PURCHASES', ActionTypes)

export interface TypeGetTenderStatsForBuyNowPurchasesR extends TDefRequest {
  idTender: number
}

export const GetTenderStatsForBuyNowPurchases = {
  request: (payload: TypeGetTenderStatsForBuyNowPurchasesR) => GetTenderStatsForBuyNowPurchasesActions.request(payload),
  success: (payload: any) => GetTenderStatsForBuyNowPurchasesActions.success(payload),
  error: (payload: string) => GetTenderStatsForBuyNowPurchasesActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const MakeSalesOrdersAndInvoicesActions = new Actions('MAKE_SALES_ORDERS_AND_INVOICES', ActionTypes)

export interface TypeMakeSalesOrdersAndInvoicesR extends TDefRequest {
  idItems: number[]
}

export const MakeSalesOrdersAndInvoices = {
  request: (payload: TypeMakeSalesOrdersAndInvoicesR) => MakeSalesOrdersAndInvoicesActions.request(payload),
  success: (payload: any) => MakeSalesOrdersAndInvoicesActions.success(payload),
  error: (payload: string) => MakeSalesOrdersAndInvoicesActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////

export const changeStatusSalesOrdersAndInvoicesBuyNow = (payload: { ids: number[], ns_processing_status: string, error_msg?: string  }) => ({type: ActionTypes.CHANGE_STATUS_SALES_ORDERS_AND_INVOICES_BUY_NOW, payload})

