/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { File } from '../../../svg/File'
import { Sort } from '../../../svg/Sort'
import { Input } from '../adminComponents/Input'
import { AppStore } from '../../../store/applicationState'
import { GetAllCsmTemplates } from '../../../store/admin/settings/cms/actions'
import { TCmsTemplates } from '../../../store/admin/settings/cms/types'
import { Close } from '../../../svg/Close'
import { IconSearch } from '../../../svg/IconSearch'
import { Auction } from '../../../svg/Auction'
import { GetAllTenders } from '../../../store/admin/tenders/actions'
import { getIconForFullAccessCategories } from '../../../utils/userUtils/IconHelpers'
import { getStatus, getStatusName } from '../../../utils/tendersUtils/tenderStatusHelper'
import { getShortDate } from '../../../utils/helpers'
import { TTenders } from '../../../store/admin/tenders/types'
import { Variables } from '../../../containers/SettingContent/Cms/Messages/Variables'
import { TemplateHint } from './TemplateHint'

interface TemplateComponentProps {
  choosedTemplate: TCmsTemplates | null
  setChoosedTemplate: (value: TCmsTemplates | null) => void
  choosedTender: TTenders | null
  setChoosedTender: (value: TTenders | null) => void
}

export const TemplateComponent: React.FC<TemplateComponentProps> = ({
                                                                      choosedTemplate,
                                                                      setChoosedTemplate,
                                                                      choosedTender,
                                                                      setChoosedTender,
                                                                    }) => {
  
  const dispatch = useDispatch()
  const { cms, tenders } = useSelector((store: AppStore) => store)
  
  const container = useRef<HTMLDivElement>(null)

  const [cmsData, setCmsData] = useState<TCmsTemplates[]>(cms.data || [])
  const [tenderData, setTenderData] = useState<TTenders[]>(tenders.data?.filter(tender => ['active', 'toBeClosed', 'completed', 'catalog', 'upcoming'].includes(getStatusName(tender))) || [])
  
  const [chooseTemplate, setChooseTemplate] = useState(false)
  const [chooseTender, setChooseTender] = useState(false)
  const [showHint, setShowHint] = useState(true)
  
  const [templateSearch, setTemplateSearch] = useState<string>('')
  const [tenderSearch, setTenderSearch] = useState<string>('')
  
  const [tableConWidth, setTableConWidth] = useState<number | null>(null)
  
  useEffect(() => {
    if (!cms.data) dispatch(GetAllCsmTemplates.request())
    if (!tenders.data) dispatch(GetAllTenders.request())
    
    if (container?.current?.clientWidth) setTableConWidth(container.current.clientWidth - 1)
  }, [])
  
  useEffect(() => {
    if (cms.data && !cmsData.length) setCmsData(cms.data)
    if (tenders.data && !tenderData.length) setTenderData(tenders.data.filter(tender => ['active', 'toBeClosed', 'completed', 'catalog', 'upcoming'].includes(getStatusName(tender))))
  }, [cms.data, tenders.data])
  
  const onTemplateSearch = (e: any) => {
    setTemplateSearch(e.target.value)
    setCmsData(cms.data?.filter(item => item.title.toLowerCase().search(e.target.value.toLowerCase()) !== -1) || [])
  }
  
  const onTenderSearch = (e: any) => {
    setTenderSearch(e.target.value)
    setTenderData(
      tenders.data?.filter(item => ['active', 'toBeClosed', 'completed', 'catalog', 'upcoming'].includes(getStatusName(item)) &&
        item.sales_cycle.toLowerCase().search(e.target.value.toLowerCase()) !== -1) || [],
    )
  }
  
  const onCancel = () => {
    setChooseTemplate(false)
    setChooseTender(false)
    setChoosedTemplate(null)
    setChoosedTender(null)
  }
  
  const canChoose = (template: TCmsTemplates, tender: TTenders) => {
    if (template.title.toLowerCase().search('diamond') !== -1) return [1, 4].includes(tender.category_id)
    if (template.title.toLowerCase().search('watch') !== -1) return [3, 6].includes(tender.category_id)
    return [2, 5].includes(tender.category_id)
  }
  
  return (
    <div
      ref={container}
      className={`border-lightblue-900 text-lightblue-900 lg-minh-90 ${choosedTender && 'overflow-auto'}`}
    >
      <button
        type='button'
        style={choosedTemplate ? { background: '#60A5FA', color: '#fff' } : {}}
        className='border inline-flex items-center py-2 px-4 rounded border-blue-100 bg-blue-50 hover:bg-blue-100 focus:bg-blue-200 w-full'
        onClick={() => {
          setChooseTemplate(!chooseTemplate)
          setChooseTender(false)
        }}
      >
        <File stroke={choosedTemplate ? '#fff' : ''} />
        <span className='mx-4'>{choosedTemplate?.title ? choosedTemplate.title : 'Template'}</span>
        {choosedTemplate ? <Close styled='ml-auto' onClick={onCancel} /> : <Sort style={{ marginLeft: 'auto' }} />}
      </button>
      {chooseTemplate && (
        <div className='rounded mt-4 p-2 bg-white shadow-lg'>
          <Input
            className='rounded focus:border-green-700'
            placeholder='Search template...'
            value={templateSearch}
            onChange={onTemplateSearch}
          />
          <div className='flex flex-col my-2'>
            {cmsData.map(item => (
              <button
                key={item.messaging_template_id}
                type='button'
                className='flex py-1 items-center opacity-30 hover:opacity-100'
                onClick={() => {
                  setChoosedTemplate(item)
                  setChooseTemplate(false)
                  setChoosedTender(null)
                }}
              >
                <File />
                <span className='ml-2'>{item.title}</span>
              </button>
            ))}
          </div>
        </div>
      )}
      {choosedTemplate && !chooseTemplate && !choosedTender && (
        <>
          <div
            className='border inline-flex items-center py-2 px-4 rounded border-indigo-100 bg-indigo-50 hover:bg-indigo-100 w-full mt-4 cursor-pointer'
            onClick={() => setChooseTender(!chooseTender)}
          >
            <IconSearch />
            <span className='mx-4'>Tender</span>
            <Auction className='ml-auto' />
          </div>
          {chooseTender ? (
            <div
              style={tableConWidth ? { width: `${tableConWidth}px` } : {}}
              className='rounded mt-4 p-2 bg-white shadow-lg'
            >
              <Input
                className='rounded focus:border-green-700'
                placeholder='Search tender...'
                value={tenderSearch}
                onChange={onTenderSearch}
              />
              <div className='flex flex-col my-2 h-60 overflow-auto whitespace-nowrap'>
                <table>
                  <tbody>
                    {tenderData.map(item => {
                    const canChooseResult = canChoose(choosedTemplate, item)
                    return (
                      <tr
                        key={item.id}
                        className={`${canChooseResult && 'hover:opacity-70'} cursor-pointer ${!canChooseResult && 'opacity-20'}`}
                        onClick={() => canChooseResult && setChoosedTender(item)}
                      >
                        <td className=''>
                          <div className='flex items-center justify-center'>
                            {getIconForFullAccessCategories(item.category_id)}
                          </div>
                        </td>
                        <td>
                          {item.sales_cycle}
                        </td>
                        <td>
                          {getStatus(item)}
                        </td>
                        <td>
                          {getShortDate(item.finalized_at)}
                        </td>
                      </tr>
                    )
                  })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : showHint &&
            <TemplateHint onSkip={() => setShowHint(false)} onThanks={() => setShowHint(false)} className='mt-2' />}
        </>
      )}
      {choosedTender && !chooseTemplate && (
        <>
          <div className='overflow-auto'>
            <div
              className='border bg-white border-blue-200 gap-x-1 inline-flex overflow-auto px-4 w-full py-2 justify-between items-center mt-4 rounded'
            >
              {getIconForFullAccessCategories(choosedTender.category_id)}
              <div className='flex w-5/6 flex-wrap'>
                <div>
                  {choosedTender.sales_cycle}
                  {getStatus(choosedTender)}
                </div>
                <div className='flex self-end ml-auto'>{getShortDate(choosedTender.finalized_at)}</div>
              </div>
              <Close styled='cursor-pointer' onClick={() => setChoosedTender(null)} />
            </div>
          </div>
          <Variables height='323px' />
        </>
      )}
    </div>
  )
}
