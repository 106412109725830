import { TDefRequest } from '../../../types/actions'
import { Actions } from '../../../utils/Action'
import { ActionTypes } from './types'
import { tenderSocketType } from '../../../hooks/Sockets/useTender'

/// ///////////////////////////////////////////////////////////////////////
const GetCurrentBidsActions = new Actions('GET_CURRENT_BIDS', ActionTypes)

export interface TypeGetCurrentBidsR extends TDefRequest {
  idTender: number
}

export const GetCurrentBids = {
  request: (payload: TypeGetCurrentBidsR) => GetCurrentBidsActions.request(payload),
  success: (payload: any) => GetCurrentBidsActions.success(payload),
  error: (payload: string) => GetCurrentBidsActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
export const WsChangeCurrentBids = (payload: tenderSocketType) => ({ type: ActionTypes.WS_CURRENT_BIDS, payload })
