import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SwitchRadioBtn } from '../SwitchRadioBtn'
import { AppStore } from '../../../store/applicationState'
import { SocketActionOccurredReset, UpdateTenderNow } from '../../../store/admin/tenders/actions'
import { Button } from './Button'

export const RealTimeUpdateControl = () => {
  
  const dispatch = useDispatch()
  const { tenders } = useSelector((store: AppStore) => store)
  
  const [active, setActive] = useState(true)
  const [timerId, setTimerId] = useState<any>(null)
  
  const updateTenderNow = (update: boolean) => dispatch(UpdateTenderNow(update))
  
  useEffect(() => {
    if (active && tenders.socketActionAmount && !timerId) {
      const timer = setTimeout(() => {
        updateTenderNow(true)
        setTimerId(null)
      }, 5000)
      setTimerId(timer)
    }
  }, [active, tenders.socketActionAmount])
  
  useEffect(() => {
    if (tenders.updateTenderNow) dispatch(SocketActionOccurredReset())
  }, [tenders.updateTenderNow])
  
  
  return (
    <div className='w-full smallMobile:w-auto'>
      <div className='flex gap-4 items-center justify-between flex-col 2smallest:flex-row'>
        <div className='flex py-2 items-center'>
          <span
            className='font-semibold border-lightblue-900 text-lightblue-900 w-full smallMobile:w-auto text-sm'
          >
            Real-time update
          </span>
          <div className='gap-x-4 flex ml-3'>
            <SwitchRadioBtn className='transform scale-90	' checked={active} onChange={() => setActive(!active)} />
          </div>
        </div>
        {!active && (
          <div className='relative'>
            <Button text='Refresh data' variant='white' onClick={() => updateTenderNow(true)} />
            {!!tenders.socketActionAmount && (
              <div
                style={{ minWidth: '1.25rem' }}
                className='absolute -left-2 -top-2 h-5 bg-white text-sm rounded-lg flex justify-center items-center'
              >
                {tenders.socketActionAmount}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
