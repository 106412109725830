/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { ROUTES } from '../../../utils/routes'
import { UploadImage } from './UploadImage'
import { DeleteImage } from './DeleteImage'
import { P404 } from '../../../pages'

export const MediaManagement = () => {
  const history = useHistory()
  const location = useLocation()

  return (
    <>
      <div className='flex relative justify-start space-x-10 mb-6 text-base font-semibold overflow-x-auto'>
        <div
          className={`settingTabGlobal ${
            location.pathname.search(`${ROUTES.adminPageSettingsMediaManagement}/upload`) !== -1 &&
            'settingTabGlobalSelected'
          }`}
          onClick={() => history.push(`${ROUTES.adminPageSettingsMediaManagement}/upload`)}
        >
          Upload
        </div>
        <div
          className={`settingTabGlobal ${
            location.pathname.search(`${ROUTES.adminPageSettingsMediaManagement}/delete`) !== -1 &&
            'settingTabGlobalSelected'
          }`}
          onClick={() => history.push(`${ROUTES.adminPageSettingsMediaManagement}/delete`)}
        >
          Delete
        </div>
        <div className='absolute h-px margin-l-r-0 left-0 right-0 bottom-0 bg-bluegray-300' />
      </div>
      <Switch>
        <Route
          path={[
            `${ROUTES.adminPageSettingsMediaManagement}/upload`,
            `/worthy${ROUTES.adminPageSettingsMediaManagement}/upload`,
          ]}
          component={UploadImage}
        />
        <Route
          path={[
            `${ROUTES.adminPageSettingsMediaManagement}/delete`,
            `/worthy${ROUTES.adminPageSettingsMediaManagement}/delete`,
          ]}
          component={DeleteImage}
        />
        <Route path='*' component={P404} />
      </Switch>
    </>
  )
}
