import React from 'react'

export const IconPauseCircle = () => {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   className="svg-inline--fa fa-play fa-w-16 fa-3x"
    //   viewBox="0 0 512 512"
    // >
    //   <path
    //     fill="currentColor"
    //     d="M200 160C186.8 160 176 170.8 176 184v144C176 341.3 186.8 352 200 352S224 341.3 224 328v-144C224 170.8 213.3 160 200 160zM312 160C298.8 160 288 170.8 288 184v144c0 13.25 10.75 24 24 24s24-10.75 24-24v-144C336 170.8 325.3 160 312 160zM256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464z"
    //   />
    // </svg>
    <svg className="svg-inline--fa fa-circle-pause" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="circle-pause" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M208 160C199.2 160 192 167.2 192 176v160C192 344.8 199.2 352 208 352S224 344.8 224 336v-160C224 167.2 216.8 160 208 160zM304 160C295.2 160 288 167.2 288 176v160c0 8.844 7.156 16 16 16s16-7.156 16-16v-160C320 167.2 312.8 160 304 160zM256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 480c-123.5 0-224-100.5-224-224s100.5-224 224-224s224 100.5 224 224S379.5 480 256 480z" /></svg>
  )
}
