import { Actions } from '../../../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../../../types/actions'


/// ///////////////////////////////////////////////////////////////////////
const GetDiamondsShapeActions = new Actions('GET_DIAMONDS_SHAPE', ActionTypes)
const GetDiamondsShapeWith0OffsetActions = new Actions('GET_DIAMONDS_SHAPE_WITH_0_OFFSET', ActionTypes)

export interface TypeGetDiamondsShapeR extends TDefRequest {
  offset?: number
  limit?: number
}

export const GetDiamondsShape = {
  request: (payload?: TypeGetDiamondsShapeR) => GetDiamondsShapeActions.request(payload || {}),
  success: (payload: any) => GetDiamondsShapeActions.success(payload),
  error: (payload: string) => GetDiamondsShapeActions.error(payload),
}

export const GetDiamondsShapeWith0Offset = {
  success: (payload: any) => GetDiamondsShapeWith0OffsetActions.success(payload),
}


/// ///////////////////////////////////////////////////////////////////////
const GetDiamondsColorActions = new Actions('GET_DIAMONDS_COLOR', ActionTypes)
const GetDiamondsColorWith0OffsetActions = new Actions('GET_DIAMONDS_COLOR_WITH_0_OFFSET', ActionTypes)

export interface TypeGetDiamondsColorR extends TDefRequest {
  offset?: number
  limit?: number
}

export const GetDiamondsColor = {
  request: (payload?: TypeGetDiamondsColorR) => GetDiamondsColorActions.request(payload || {}),
  success: (payload: any) => GetDiamondsColorActions.success(payload),
  error: (payload: string) => GetDiamondsColorActions.error(payload),
}

export const GetDiamondsColorWith0Offset = {
  success: (payload: any) => GetDiamondsColorWith0OffsetActions.success(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetDiamondsClarityActions = new Actions('GET_DIAMONDS_CLARITY', ActionTypes)
const GetDiamondsClarityWith0OffsetActions = new Actions('GET_DIAMONDS_CLARITY_WITH_0_OFFSET', ActionTypes)

export interface TypeGetDiamondsClarityR extends TDefRequest {
  offset?: number
  limit?: number
}

export const GetDiamondsClarity = {
  request: (payload?: TypeGetDiamondsClarityR) => GetDiamondsClarityActions.request(payload || {}),
  success: (payload: any) => GetDiamondsClarityActions.success(payload),
  error: (payload: string) => GetDiamondsClarityActions.error(payload),
}

export const GetDiamondsClarityWith0Offset = {
  success: (payload: any) => GetDiamondsClarityWith0OffsetActions.success(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetDiamondsFluorescenceActions = new Actions('GET_DIAMONDS_FLUORESCENCE', ActionTypes)
const GetDiamondsFluorescenceWith0OffsetActions = new Actions('GET_DIAMONDS_FLUORESCENCE_WITH_0_OFFSET', ActionTypes)

export interface TypeGetDiamondsFluorescenceR extends TDefRequest {
  offset?: number
  limit?: number
}

export const GetDiamondsFluorescence = {
  request: (payload?: TypeGetDiamondsFluorescenceR) => GetDiamondsFluorescenceActions.request(payload || {}),
  success: (payload: any) => GetDiamondsFluorescenceActions.success(payload),
  error: (payload: string) => GetDiamondsFluorescenceActions.error(payload),
}

export const GetDiamondsFluorescenceWith0Offset = {
  success: (payload: any) => GetDiamondsFluorescenceWith0OffsetActions.success(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetDiamondsCutPolishSymmetryActions = new Actions('GET_DIAMONDS_CUT_POLISH_SYMMETRY', ActionTypes)
const GetDiamondsCutPolishSymmetryWith0OffsetActions = new Actions('GET_DIAMONDS_CUT_POLISH_SYMMETRY_WITH_0_OFFSET', ActionTypes)

export interface TypeGetDiamondsCutPolishSymmetryR extends TDefRequest {
  offset?: number
  limit?: number
}

export const GetDiamondsCutPolishSymmetry = {
  request: (payload?: TypeGetDiamondsCutPolishSymmetryR) => GetDiamondsCutPolishSymmetryActions.request(payload || {}),
  success: (payload: any) => GetDiamondsCutPolishSymmetryActions.success(payload),
  error: (payload: string) => GetDiamondsCutPolishSymmetryActions.error(payload),
}

export const GetDiamondsCutPolishSymmetryWith0Offset = {
  success: (payload: any) => GetDiamondsCutPolishSymmetryWith0OffsetActions.success(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetOptionsByTypeActions = new Actions('GET_OPTIONS_BY_TYPE', ActionTypes)

export interface TypeGetOptionsByTypeR extends TDefRequest {
  type_of_option_id: number
}

export const GetOptionsByType = {
  request: (payload: TypeGetOptionsByTypeR) => GetOptionsByTypeActions.request(payload),
  success: (payload: any) => GetOptionsByTypeActions.success(payload),
  error: (payload: string) => GetOptionsByTypeActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
const PutDiamondsDictionaryActions = new Actions('PUT_DIAMONDS_DICTIONARY', ActionTypes)

export interface TypePutDiamondsDictionaryR extends TDefRequest {
  options_group_id: number
  option_id: number
}

export const PutDiamondsDictionary = {
  request: (payload: TypePutDiamondsDictionaryR) => PutDiamondsDictionaryActions.request(payload),
  success: (payload: any) => PutDiamondsDictionaryActions.success(payload),
  error: (payload: string) => PutDiamondsDictionaryActions.error(payload),
}