import { Reducer } from 'redux'
import { ActionTypes, TMediaManagementState } from './types'
import { changeImageSizeForAdminGalleryArray } from '../../../../utils/imgUrlParse/imgUrlParser'

export const initialState: TMediaManagementState = {
  loading: false,
  data: {
    imagesMetaData: null,
    tenderImagesStatus: null,
    filestackApiKey: null,
    galleryList: null,
  },
}

const reducer: Reducer<TMediaManagementState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_TENDER_IMAGES_STATUS_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_TENDER_IMAGES_STATUS_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        data: { ...state.data, tenderImagesStatus: action.payload },
      }
    case ActionTypes.GET_TENDER_IMAGES_STATUS_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.SEND_IMAGES_METADATA_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.SEND_IMAGES_METADATA_S:
      return { ...state, loading: false, errors: undefined, data: { ...state.data, imagesMetaData: action.payload } }
    case ActionTypes.SEND_IMAGES_METADATA_E:
      return { ...state, loading: false, errors: action.payload }
    default:
      return state

    case ActionTypes.GET_FILESTACK_API_KEY_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_FILESTACK_API_KEY_S:
      return { ...state, loading: false, errors: undefined, data: { ...state.data, filestackApiKey: action.payload } }
    case ActionTypes.GET_FILESTACK_API_KEY_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.GET_TENDER_GALLERY_LIST_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_TENDER_GALLERY_LIST_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        data: { ...state.data, galleryList: changeImageSizeForAdminGalleryArray(action.payload) },
      }
    case ActionTypes.GET_TENDER_GALLERY_LIST_E:
      return { ...state, loading: false, errors: action.payload }
  }
}

export { reducer as MediaManagementReducer }
