/* eslint-disable jsx-a11y/label-has-associated-control */
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InputChecked } from '../svg/InputChecked'
import { InputNotChecked } from '../svg/InputNotChecked'
import { stylesMulti, validateButton } from '../utils/userUtils/FormStyles'
import {
  getDefaultValue,
  getValueForSelect,
  handleSelect,
  onEmailChange,
  onInputChange,
  onPasswordChange,
  onPasswordChangeOld,
  onPasswordRepeatChange,
} from '../utils/userUtils/EventHandlers'
import { FormPhoneInput } from '../components/ui/formUi/FormPhoneInput'
import { GetUser, SetUserData, SetUserPassword, TypeSetUserData, TypeSetUserPassword } from '../store/user/actions'
import { AppStore } from '../store/applicationState'
import { GetMessageMethods } from '../store/messageMethods/actions'
import { isMobileDevice } from '../utils/helpers'
import { PasswordCheckPopover } from '../components/ui/PasswordCheckPopover'

interface UserAccountProps {}

export const UserAccount: React.FC<UserAccountProps> = () => {
  const { userData, messageMethods } = useSelector((store: AppStore) => store)
  const dispatch = useDispatch()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [messageMethod, setMessageMethod] = useState<{ value: string; label: string } | null>(null)
  const [tell, setTell] = useState('')
  const [isMobile, setIsMobile] = useState(false)
  const [company, setCompany] = useState('')

  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmedNewPassword, setConfirmedNewPassword] = useState('')

  const [firstNameError, setFirstNameError] = useState(false)
  const [lastNameError, setLastNameError] = useState(false)
  const [emailError, setEmailError] = useState(false) // is not using now
  const [tellError, setTellError] = useState(false)
  const [messageMethodError, setMessageMethodError] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [companyError, setCompanyError] = useState(false) // is not using now
  const [submitError, setSubmitError] = useState(false)

  const [passwordError, setPasswordError] = useState(true)
  const [newPasswordError, setNewPasswordError] = useState(true)
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(true)
  const [submitPassError, setSubmitPassError] = useState(false)

  const messageMethodRef = useRef(null)

  useEffect(() => {
    dispatch(GetUser.request())
    if (!messageMethods.data) dispatch(GetMessageMethods.request())
  }, [])

  useEffect(() => {
    if (messageMethods.data && userData.data?.preferred_message_method) {
      setMessageMethodError(false)
      setMessageMethod(getDefaultValue(messageMethods.data, userData.data.preferred_message_method))
    } else {
      setMessageMethodError(true)
    }
  }, [messageMethods.data, userData.data])

  useEffect(() => {
    if (userData.data) {
      setFirstName(userData.data.first_name)
      setLastName(userData.data.last_name)
      setEmail(userData.data.email)
      setTell(userData.data.phone)
      setIsMobile(userData.data.is_mobile)
      setCompany(userData.data.company_name)
    }
  }, [userData.data])

  const styles = {
    // eslint-disable-next-line
    control: (provided: any, state: any) => ({
      ...provided,
      color: 'red',
      padding: '5px',
      border: `${companyError ? '1px solid rgba(248, 113, 113, 1)' : ''}`,
      boxShadow: 'none',
      '&:hover': {
        borderColor: `${companyError ? 'rgba(248, 113, 113, 1)' : ''}`,
      },
    }),
    // menuPlacement: (provided: any, state: any) => ({
    //   ...provided,
    //   bottom: 'default',
    //   top: 'auto'
    // }),
  }

  const updateUserAccount = (data: TypeSetUserData) => dispatch(SetUserData.request(data))
  const updateUserPassword = (data: TypeSetUserPassword) => dispatch(SetUserPassword.request(data))

  const focusItem = (id: string) => {
    const item = document.getElementById(id)
    if (item) item.focus()
  }

  const onSubmitForm = (event: any) => {
    event.preventDefault()
    if (firstNameError || lastNameError || tellError || messageMethodError) {
      if (firstNameError) focusItem('firstName')
      else if (lastNameError) focusItem('lastName')
      else if (tellError)
        if (isMobileDevice()) window.scrollTo(0, 500)
        else if (messageMethodError) {
          // @ts-ignore
          messageMethodRef.current?.focus()
        }
      setSubmitError(true)
      return null
    }
    setSubmitError(false)
    if (!messageMethod) return null
    return updateUserAccount({
      company_name: company,
      email,
      first_name: firstName,
      is_mobile: isMobile,
      last_name: lastName,
      phone: tell,
      preferred_message_method: +messageMethod.value,
    })
  }

  const onSubmitResetPasswordForm = (event: any) => {
    event.preventDefault()
    if (passwordError || newPasswordError || confirmNewPasswordError) {
      if (passwordError) focusItem('password')
      else if (newPasswordError) focusItem('newPassword')
      else if (confirmNewPasswordError) focusItem('confirmNewPassword')
      setSubmitPassError(true)
      return null
    }
    setSubmitPassError(false)

    return updateUserPassword({
      current_password: password,
      new_password: newPassword,
      confirm_password: confirmedNewPassword,
    })
  }

  return (
    <div className='page-content pb-16 text-sm lg:pt-9 smallMobile:pt-16 pt-20'>
      <div className='flex justify-center items-center h-full'>
        <div className='px-4 w-11/12 max-w-screen-xl flex flex-col lg:flex-row'>
          <form
            noValidate
            onSubmit={(event) => onSubmitForm(event)}
            action=''
            className='my-profile-section form-user-block lg:mr-2'
          >
            <div className='font-circa text-2xl font-medium p-3'>My profile</div>

            <div className='mb-1 p-3 bg-bluegray-50 flex flex-col md:flex-row justify-between'>
              <div className='md:w-1/2 md:pr-3 mb-3 md:mb-0'>
                <label htmlFor='firstName' className='form-label'>
                  First name:
                </label>

                <div>
                  <input
                    value={firstName}
                    onChange={(event) => onInputChange(event, setFirstName, setFirstNameError)}
                    type='text'
                    name='firstName'
                    id='firstName'
                    placeholder='Enter First name *'
                    className={`form-input ${validateButton(firstNameError, submitError)}`}
                  />

                  <div className={`form-error-text mt-1 ${firstNameError && submitError ? 'block' : 'hidden'}`}>
                    Please enter a valid first name.
                  </div>
                </div>
              </div>

              <div className='md:w-1/2 md:pl-3'>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor='lastName' className='form-label'>
                  Last name:
                </label>

                <div>
                  <input
                    value={lastName}
                    onChange={(event) => onInputChange(event, setLastName, setLastNameError)}
                    type='text'
                    name='lastName'
                    id='lastName'
                    placeholder='Enter Last name *'
                    className={`form-input ${validateButton(lastNameError, submitError)}`}
                  />

                  <div className={`form-error-text mt-1 ${lastNameError && submitError ? 'block' : 'hidden'}`}>
                    Please enter a valid last name.
                  </div>
                </div>
              </div>
            </div>

            <div className='mb-1 p-3 bg-bluegray-50 flex flex-col md:flex-row justify-between'>
              <div className='w-full mb-3 md:mb-0'>
                <label htmlFor='email' className='form-label'>
                  Email:
                </label>

                <div>
                  <input
                    value={email}
                    onChange={(event) => onEmailChange(event, setEmail, setEmailError)}
                    type='email'
                    name='email'
                    disabled
                    id='email'
                    placeholder='Enter Email *'
                    className={`form-input ${validateButton(emailError, submitError)}`}
                  />

                  <div className={`form-error-text mt-1 ${emailError && submitError ? 'block' : 'hidden'}`}>
                    Please enter valid email address.
                  </div>
                </div>
              </div>
            </div>

            <div className='mb-1 p-3 bg-bluegray-50'>
              <div className='flex flex-col md:flex-row justify-between'>
                <div className='md:w-2/4 md:pr-3 mb-3 md:mb-0'>
                  <label htmlFor='phoneNumber' className='form-label'>
                    Phone number:
                  </label>

                  <FormPhoneInput
                    userTell={tell}
                    setUserTell={setTell}
                    userTellError={tellError}
                    setTellError={setTellError}
                    submitError={submitError}
                  />
                </div>

                <div className='md:w-2/4 md:pl-3'>
                  <label htmlFor='isMobile' className='form-label'>
                    Preferred message method:
                  </label>

                  <div>
                    {messageMethods.data && (
                      <Select
                        ref={messageMethodRef}
                        name='messageMethods'
                        isSearchable
                        options={messageMethods.data.map((item) => getValueForSelect(item))}
                        value={messageMethod}
                        onChange={(event) => handleSelect(event, setMessageMethod, setMessageMethodError)}
                        styles={stylesMulti}
                        id='messageMethod'
                      />
                    )}
                    <div className={`form-error-text mt-1 ${messageMethodError && submitError ? 'block' : 'hidden'}`}>
                      Please select or enter your preferred message method.
                    </div>
                  </div>
                </div>
              </div>

              <div className='mt-2'>
                <label
                  htmlFor='checkbox'
                  className='is-mobile text-sm text-gray-600 w-max-content hover:text-button-reports flex items-center cursor-pointer'
                >
                  <input type='checkbox' id='checkbox' className='hidden' onClick={() => setIsMobile(!isMobile)} />

                  {isMobile ? <InputChecked /> : <InputNotChecked />}
                  <span className='ml-3 select-none'>This number is my mobile</span>
                </label>
              </div>
            </div>

            <div className='mb-1 p-3 bg-bluegray-50'>
              <label htmlFor='company' className='form-label'>
                Company:
              </label>

              <div>
                <CreatableSelect
                  // options={options}
                  isClearable={false}
                  isDisabled
                  menuPlacement='top'
                  placeholder='Select or enter company'
                  value={getValueForSelect(company)}
                  // onChange={(event) => handleSelect(event, setUserCompany, setUserCompanyError)}
                  styles={styles}
                  id='company'
                />

                <div className={`form-error-text mt-1 ${companyError && submitError ? 'block' : 'hidden'}`}>
                  Please select or enter your company.
                </div>
              </div>
            </div>

            <div className='p-3'>
              <button
                type='submit'
                // disabled={userData.loading}
                className='py-3 px-16 text-white bg-green-700 hover:bg-green-800 text-base rounded-md product-buttons'
              >
                Save
              </button>
            </div>
          </form>

          <form
            noValidate
            onSubmit={(event) => onSubmitResetPasswordForm(event)}
            action=''
            className='password-section form-user-block mt-6 lg:mt-0 lg:ml-2 lg:h-3/4'
          >
            <div className='font-circa text-2xl font-medium p-3'>Password</div>

            <div className='mb-1 p-3 bg-bluegray-50 flex flex-col md:flex-row justify-between'>
              <div className='w-full mb-3 md:mb-0'>
                <label htmlFor='password' className='form-label'>
                  Current password:
                </label>

                <div>
                  <input
                    onChange={(event) => onPasswordChangeOld(event, setPassword, setPasswordError)}
                    type='password'
                    name='password'
                    id='password'
                    value={password}
                    placeholder='Enter current password'
                    className={`form-input ${validateButton(passwordError, submitPassError)}`}
                  />

                  <div className={`form-error-text mt-1 ${passwordError && submitPassError ? 'block' : 'hidden'}`}>
                    Please enter your password.
                  </div>
                </div>
              </div>
            </div>

            <div className='mb-1 p-3 bg-bluegray-50 flex flex-col md:flex-row justify-between'>
              <div className='w-full mb-3 md:mb-0'>
                <label htmlFor='newPassword' className='form-label'>
                  New password:
                </label>

                <div>
                  <PasswordCheckPopover>
                    <input
                      onChange={(event) => onPasswordChange(event, setNewPassword, setNewPasswordError)}
                      type='password'
                      name='newPassword'
                      id='newPassword'
                      value={newPassword}
                      placeholder='Enter new password'
                      className={`form-input ${validateButton(newPasswordError, submitPassError)}`}
                    />

                    <div className={`form-error-text mt-1 ${newPasswordError && submitPassError ? 'block' : 'hidden'}`}>
                      Please enter valid password.
                    </div>
                  </PasswordCheckPopover>
                </div>
              </div>
            </div>

            <div className='mb-1 p-3 bg-bluegray-50 flex flex-col md:flex-row justify-between'>
              <div className='w-full mb-3 md:mb-0'>
                <label htmlFor='confirmNewPassword' className='form-label'>
                  Confirm new password:
                </label>

                <div>
                  <PasswordCheckPopover>
                    <input
                      onChange={(event) => {
                        onPasswordRepeatChange(event, newPassword, setConfirmedNewPassword, setConfirmNewPasswordError)
                      }}
                      value={confirmedNewPassword}
                      type='password'
                      name='confirmNewPassword'
                      id='confirmNewPassword'
                      placeholder='Confirm new password'
                      className={`form-input ${validateButton(confirmNewPasswordError, submitPassError)}`}
                    />

                    <div
                      className={`form-error-text mt-1 ${
                        confirmNewPasswordError && submitPassError ? 'block' : 'hidden'
                      }`}
                    >
                      Passwords should match.
                    </div>
                  </PasswordCheckPopover>
                </div>
              </div>
            </div>

            <div className='p-3'>
              <button
                type='submit'
                // disabled={userData.loading}
                className='py-3 px-16 text-white bg-green-700 hover:bg-green-800 text-base rounded-md product-buttons'
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
