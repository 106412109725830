import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import image from '../images/404Diamond.png'
import { Icon404 } from '../svg/Icon404'
import { Button } from '../components/ui/adminComponents/Button'
import { ROUTES } from '../utils/routes'
import { AppStore } from '../store/applicationState'
import { checkToken, ReturnType } from '../utils/checkToken'

export const P404 = () => {
  const history = useHistory()
  const { userData } = useSelector((store: AppStore) => store)

  useEffect(() => {
    ;(async () => {
      const userToken: ReturnType = await checkToken()
      if (window.location.pathname.search('/login') !== -1) {
        if (!userToken.idOfAdminRole) history.replace(ROUTES.dashboard)
        else history.replace(ROUTES.adminPageTender)
      }
    })()
  }, [])

  return (
    <div className='relative h-screen flex mx-auto overflow-hidden'>
      <div className='absolute h-full left-0 top-0 bottom-0 flex items-center'>
        <img src={image} alt='404Diamond' />
      </div>
      <div
        style={{ maxWidth: 720 }}
        className='ml-auto desktop-ml-50vw md:mr-20 z-10 flex px-2 md:px-0 -mt-10 justify-center items-center flex-col'
      >
        <Icon404 width='100%' />
        <h1 className='border-lightblue-900 text-lightblue-900 mobile:text-lg font-semibold uppercase text-center mt-14 mb-6'>
          The page you are looking for was not found
        </h1>
        <div className='w-full flex flex-wrap smallMobile:flex-nowrap px-4 gap-4 mobile:px-8 mobile:gap-8'>
          {(userData.isAssociateAdmin || userData.isExecutiveAdmin) && (
            <Button onClick={() => history.push(ROUTES.adminPageTender)} variant='goTo' text='Go to admin panel' />
          )}
          <Button
            onClick={() => {
              history.push(ROUTES.dashboard)
            }}
            variant='goTo'
            text='Go to dashboard'
          />
        </div>
      </div>
    </div>
  )
}
