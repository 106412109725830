import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as Actions from './actions'
import { callApi } from '../../utils/callApi'
import { API_ROUTES } from '../../utils/apiRoutes'
import { ActionTypes, TDiamondVideo } from './types'


function* getDiamondVideoWorker(action: ReturnType<typeof Actions.GetDiamondVideo.request>) {
  const { callBack, idItem } = action.payload as Actions.TypeGetDiamondVideoR
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.itemVideo}/${idItem}`,
    })) as TDiamondVideo
    
    yield put(Actions.GetDiamondVideo.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetDiamondVideo.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}


function* watchRequest() {
  yield takeEvery(ActionTypes.GET_DIAMOND_VIDEO_R, getDiamondVideoWorker)
}

export default function* diamondVideoSaga() {
  yield all([fork(watchRequest)])
}
