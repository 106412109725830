/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from 'react'
import { Input } from './Input'
import { Arrow } from '../../../svg/Arrow'
import { useClickOutside } from '../../../hooks/useClickOutside'
import { TListType } from '../../../store/admin/settings/mapping/types'

interface IOptions {
  options_group_id: number
  option_name: string
  other?: string
}

interface DropDownSimpleProps {
  list: IOptions[] | null
  optionTitle: string
  callBack?: (item: TListType) => void
  style?: React.CSSProperties
  className?: string
  withArrow?: boolean
  goUp?: boolean
  defaultIndex?: number
}

export const DropDownSimple: React.FC<DropDownSimpleProps> = ({
  list,
  optionTitle,
  style,
  className = '',
  withArrow,
  goUp,
  callBack,
  defaultIndex,
}) => {
  const [listState, setListState] = useState<IOptions[] | null>(list || null)
  const [visible, setVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [choosenItemIndex, setChoosenItemIndex] = useState<number>(defaultIndex !== undefined ? defaultIndex : -1)

  const inputRef = useRef<HTMLInputElement>(null)
  const dropDownRef = useRef(null)
  const { clickOutside } = useClickOutside(dropDownRef)

  useEffect(() => {
    if (clickOutside) setVisible(false)
  }, [clickOutside])

  useEffect(() => {
    // @ts-ignore
    if (visible) inputRef?.current?.focus()
  }, [visible])

  useEffect(() => {
    if (list)
      setListState(
        list.filter(
          (item) =>
            item.option_name.toLowerCase().includes(inputRef.current?.value.toLowerCase() || '') ||
            item.other?.toLowerCase().includes(inputRef.current?.value.toLowerCase() || '')
        )
      )
    return () => setListState(null)
  }, [list])

  const onInputChange = (e: any) => {
    const { value } = e.target
    setInputValue(value)
    const searchList =
      list?.filter(
        (item) =>
          item.option_name.toLowerCase().includes(value.toLowerCase()) ||
          item.other?.toLowerCase().includes(value.toLowerCase())
      ) || null

    const index =
      searchList?.findIndex((item) => item.options_group_id === listState?.[choosenItemIndex]?.options_group_id) || -1

    setChoosenItemIndex(index)
    setListState(searchList)
  }

  return (
    <div ref={dropDownRef} style={style} className={`w-full text-sm relative ${className}`}>
      <button
        type='button'
        style={visible ? { borderBottomRightRadius: 0, borderBottomLeftRadius: 0 } : {}}
        className='dropdown flex items-center w-full h-full bg-white border p-1 rounded text-left focus:outline-none focus:ring-1 focus:ring-bluegray-100 focus:border-cool-gray-100'
        onClick={() => setVisible(true)}
      >
        <span className='flex items-center'>
          <span className='mx-3 block truncate font-semibold'>
            <div className='text-bluegray-500 font-normal'>
              {listState?.[choosenItemIndex]?.option_name || optionTitle || <p>Select...</p>}
            </div>
          </span>
        </span>
        {withArrow && <Arrow className='ml-auto mr-2 text-gray-300' strokeWidth='20px' />}
      </button>
      <div
        style={visible ? { display: 'flex', maxHeight: '250px' } : {}}
        className={`dropdown-content p-2 absolute hidden flex-col z-10 left-0 right-0 bg-white overflow-y-scroll border border-t-0 ${
          goUp ? 'bottom-full' : ''
        }`}
      >
        <Input inputRef={inputRef} placeholder='Search' value={inputValue} onChange={onInputChange} />
        <div className='my-2'>
          {list &&
            listState?.map((item, i) => {
              return (
                <button
                  type='button'
                  className='flex items-center cursor-pointer w-full hover:bg-lightblue-600 hover:text-white'
                  key={`dropdown${item.option_name + i}`}
                  disabled={
                    item.options_group_id === listState[choosenItemIndex]?.options_group_id ||
                    (choosenItemIndex === -1 && item.option_name === optionTitle)
                  }
                  onClick={() => {
                    setChoosenItemIndex(i)
                    setVisible(false)
                    return callBack && callBack(listState[i])
                  }}
                >
                  <div className='flex flex-col mx-2 my-1'>{item.option_name}</div>
                </button>
              )
            })}
        </div>
      </div>
    </div>
  )
}
