export const getDataForPagination = (state: any[] | null, payload: any[] | null) => {
  if (payload && payload?.length !== 0) {
    if (state) return [...state, ...payload]
    return payload
  }
  return state
}

export const getMapData = (state: Map<number, any[]> | null, payload: { idItem: number, data: any[] | null }): Map<number, any[]> | null => {
  if (payload.data) {
    if (state) return state.set(payload.idItem, payload.data)
    return new Map<number, any[]>([[payload.idItem, payload.data]])
  }
  return state
}

export const getSingleMapData = (state: Map<number, any>, payload: { idItem: number, data: any }): Map<number, any> => {
  if (payload.data) {
    if (state) return state.set(payload.idItem, payload.data)
    return new Map<number, any>([payload.idItem, payload.data])
  }
  return state
}

export const getSingleMapDataForce = (state: Map<number, any>, payload: { idItem: number, data: any }): Map<number, any> => {
  if (payload.data) {
    const newState = new Map(state)
    newState.set(payload.idItem, payload.data)
    return newState
  }
  return state
}