import { Reducer } from 'redux'
import { ActionTypes, TBidsByBiddenState } from './types'
import { getDataForPagination, getMapData } from '../../../../utils/reducerUtils'

export const initialState: TBidsByBiddenState = {
  loading: false,
  data: null,
  childData: null,
}

const reducer: Reducer<TBidsByBiddenState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_BIDS_BY_BIDDEN_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_BIDS_BY_BIDDEN_S:
      return { ...state, loading: false, errors: undefined, data: getDataForPagination(state.data, action.payload) }
    case ActionTypes.GET_BIDS_BY_BIDDEN_WITH_O_OFFSET_S:
      return { ...state, loading: false, errors: undefined, data: action.payload }
    case ActionTypes.GET_BIDS_BY_BIDDEN_E:
      return { ...state, loading: false, errors: action.payload, data: [] }
    
    case ActionTypes.GET_BIDS_BY_BIDDEN_CHILD_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_BIDS_BY_BIDDEN_CHILD_S:
      return { ...state, loading: false, errors: undefined, childData: getMapData(state.childData, action.payload) }
    case ActionTypes.GET_BIDS_BY_BIDDEN_CHILD_E:
      return { ...state, loading: false, errors: action.payload }
    
    default:
      return state
  }
}

export { reducer as BidsByBiddenReducer }