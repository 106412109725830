import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { callApi } from '../../utils/callApi'
import { API_ROUTES } from '../../utils/apiRoutes'
import * as Actions from './actions'
import { ActionTypes, TCategories } from './types'

function* getCategoriesWorker(action: ReturnType<typeof Actions.GetCategories.request>) {
  const { callBack } = action.payload as Actions.TypeGetCategoriesR
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: API_ROUTES.categories,
    })) as TCategories[]
    
    yield put(Actions.GetCategories.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetCategories.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}


function* watchRequest() {
  yield takeEvery(ActionTypes.GET_CATEGORIES_R, getCategoriesWorker)
}

export default function* categoriesSaga() {
  yield all([fork(watchRequest)])
}