import { Actions } from '../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../types/actions'


/// ///////////////////////////////////////////////////////////////////////
const GetAcquisitionHistoryActions = new Actions('GET_ACQUISITION_HISTORY', ActionTypes)
const GetAcquisitionHistoryWith0OffsetActions = new Actions('GET_ACQUISITION_HISTORY_WITH_0_OFFSET', ActionTypes)

export interface TypeGetAcquisitionHistoryR extends TDefRequest {
  idCategory: 1 | 2 | 3
  offset: number
  limit: number
  sortBy?: string
  search?: string
  sort?: string
}

export const GetAcquisitionHistory = {
  request: (payload: TypeGetAcquisitionHistoryR) => GetAcquisitionHistoryActions.request(payload || {}),
  success: (payload: any) => GetAcquisitionHistoryActions.success(payload),
  error: (payload: string) => GetAcquisitionHistoryActions.error(payload),
}

export const GetAcquisitionHistoryWith0Offset = {
  success: (payload: any) => GetAcquisitionHistoryWith0OffsetActions.success(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetAcquisitionPerformanceActions = new Actions('GET_ACQUISITION_PERFORMANCE', ActionTypes)

export interface TypeGetAcquisitionPerformanceR extends TDefRequest {
  idCategory: 1 | 2 | 3
}

export const GetAcquisitionPerformance = {
  request: (payload: TypeGetAcquisitionPerformanceR) => GetAcquisitionPerformanceActions.request(payload),
  success: (payload: any) => GetAcquisitionPerformanceActions.success(payload),
  error: (payload: string) => GetAcquisitionPerformanceActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
const GetTenderScheduleActions = new Actions('GET_TENDER_SCHEDULE', ActionTypes)

export interface TypeGetTenderScheduleR extends TDefRequest {}

export const GetTenderSchedule = {
  request: (payload?: TypeGetTenderScheduleR) => GetTenderScheduleActions.request(payload || {}),
  success: (payload: any) => GetTenderScheduleActions.success(payload),
  error: (payload: string) => GetTenderScheduleActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
const GetIcsActions = new Actions('GET_ICS', ActionTypes)

export interface TypeGetIcsR extends TDefRequest {
  idTender: number
}

export const GetIcs = {
  request: (payload: TypeGetIcsR) => GetIcsActions.request(payload),
  success: (payload: any) => GetIcsActions.success(payload),
  error: (payload: string) => GetIcsActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
const GetAllAcquisitionActions = new Actions('GET_ALL_ACQUISITIONS', ActionTypes)

export interface TypeGetAllAcquisitionR extends TDefRequest {}

export const GetAllAcquisition = {
  request: (payload?: TypeGetAllAcquisitionR) => GetAllAcquisitionActions.request(payload),
  success: (payload: any) => GetAllAcquisitionActions.success(payload),
  error: (payload: string) => GetAllAcquisitionActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
