import { TCurrentBids } from '../../store/gallery/tenderSummary/types'
import { TGalleryItemInfo } from '../../store/gallery/types'
import { TTenderResults } from '../../store/tenderResults/types'
import { TTenderGalleryList } from '../../store/admin/settings/mediaManagement/types'

export const changeImageSizeForSummery = (payload: TCurrentBids[] | null) => {
  const size = 300
  const cropParams = payload?.every((item) => item.type_of_product !== 1) ? ',fit:crop' : ''

  if (payload)
    for (let i = 0; i < payload.length; i += 1) {
      if (!payload[i].img.includes('cdn.filestackcontent.com/A3r4cONw2QCeRFN4kcWd5z')) {
        // eslint-disable-next-line no-param-reassign
        payload[i].img = payload[i].img.replace(
          'cdn.filestackcontent.com',
          `cdn.filestackcontent.com/resize=height:${size},width:${size}${cropParams}`
        )
      }
    }
  return payload
}

export const changeImageSizeForTenderResults = (payload: TTenderResults[] | null) => {
  const size = 300
  const cropParams = payload?.every((item) => item.category_id !== 1) ? ',fit:crop' : ''

  if (payload)
    for (let i = 0; i < payload.length; i += 1) {
      if (!payload[i].img.includes('cdn.filestackcontent.com/A3r4cONw2QCeRFN4kcWd5z')) {
        // eslint-disable-next-line no-param-reassign
        payload[i].img = payload[i].img.replace(
          'cdn.filestackcontent.com',
          `cdn.filestackcontent.com/resize=height:${size},width:${size}${cropParams}`
        )
      }
    }
  return payload
}

export const changeImageSizeForGallery = (payload: TGalleryItemInfo | null) => {
  const size = 1600
  const reportImagesSize = 2048
  const videoImagesSize = 2400
  const cropParams = payload?.category_id !== 1 ? ',fit:crop' : ''

  if (payload && (payload.category_id === 3 || payload.category_id === 2)) {
    for (let i = 0; i < payload.product_gallery.images.length; i += 1) {
      // eslint-disable-next-line no-param-reassign
      payload.product_gallery.images[i] = payload.product_gallery.images[i].replace(
        'cdn.filestackcontent.com',
        `cdn.filestackcontent.com/resize=height:${size},width:${size}${cropParams}`
      )
    }
    for (let i = 0; i < payload.product_gallery.part_of_media_file.length; i += 1) {
      // eslint-disable-next-line no-param-reassign
      payload.product_gallery.part_of_media_file[i] = payload.product_gallery.part_of_media_file[i].replace(
        'cdn.filestackcontent.com',
        `cdn.filestackcontent.com/resize=height:${videoImagesSize},width:${videoImagesSize}${cropParams}`
      )
    }
  }
  if (payload && payload.category_id === 1) {
    for (let i = 0; i < payload.product_gallery.report_images.length; i += 1) {
      // eslint-disable-next-line no-param-reassign
      payload.product_gallery.report_images[i] = payload.product_gallery.report_images[i].replace(
        'cdn.filestackcontent.com',
        `cdn.filestackcontent.com/resize=height:${reportImagesSize},width:${reportImagesSize}`
      )
    }
  }
  return payload
}

export const changeImageSizeForGalleryArray = (payload: TGalleryItemInfo[] | null) => {
  const size = 600
  const cropParams = payload?.every((item) => item.category_id !== 1) ? ',fit:crop' : ''

  if (payload)
    for (let i = 0; i < payload.length; i += 1) {
      for (let j = 0; j < payload[i].product_gallery.images.length; j += 1) {
        if (!payload[i].product_gallery.images[j].includes('cdn.filestackcontent.com/A3r4cONw2QCeRFN4kcWd5z')) {
          // eslint-disable-next-line no-param-reassign
          payload[i].product_gallery.images[j] = payload[i].product_gallery.images[j].replace(
            'cdn.filestackcontent.com',
            `cdn.filestackcontent.com/resize=height:${size},width:${size}${cropParams}`
          )
        }
      }
      for (let y = 0; y < payload[i].product_gallery.part_of_media_file.length; y += 1) {
        // eslint-disable-next-line no-param-reassign
        payload[i].product_gallery.part_of_media_file[y] = payload[i].product_gallery.part_of_media_file[y].replace(
          'cdn.filestackcontent.com',
          `cdn.filestackcontent.com/resize=height:${size},width:${size}${cropParams}`
        )
      }
      // for (let j = 0; j < payload[i].product_gallery.report_images.length; j += 1) {
      //   // eslint-disable-next-line no-param-reassign
      //   payload[i].product_gallery.report_images[j] = payload[i].product_gallery.report_images[j].replace('resize=height:1600,width:1600,fit:crop/', '')
      // }
    }
  return payload
}

export const changeImageSizeForAdminGalleryArray = (payload: TTenderGalleryList[] | null) => {
  const size = 300

  if (payload)
    for (let i = 0; i < payload.length; i += 1) {
      const galleryResponse = payload[i].gallery_response
      if (galleryResponse)
        for (let j = 0; j < galleryResponse.length; j += 1) {
          if (galleryResponse[j].file_type_id === 1) {
            // eslint-disable-next-line no-param-reassign
            galleryResponse[j].url = galleryResponse[j].url.replace(
              'cdn.filestackcontent.com',
              `cdn.filestackcontent.com/resize=height:${size},width:${size}`
            )
          }
        }
    }
  return payload
}

export const changeImageUrl = (url: string) =>
  url.replace(`resize=height:${600},width:${600},`, 'resize=height:1600,width:1600,')
