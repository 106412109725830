import { Actions } from '../../../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../../../types/actions'


/// ///////////////////////////////////////////////////////////////////////
const GetAllEmailLogsActions = new Actions('GET_ALL_EMAIL_LOGS', ActionTypes)
const GetAllEmailLogsWith0OffsetActions = new Actions('GET_ALL_EMAIL_LOGS_WITH_0_OFFSET', ActionTypes)

export interface TypeGetAllEmailLogsR extends TDefRequest {
  offset?: number
  limit?: number
  search?: string
  isAccepted?: boolean
  isSent?: boolean
  isOpened?: boolean
  isFailed?: boolean
}

export const GetAllEmailLogs = {
  request: (payload?: TypeGetAllEmailLogsR) => GetAllEmailLogsActions.request(payload || {}),
  success: (payload: any) => GetAllEmailLogsActions.success(payload),
  error: (payload: string) => GetAllEmailLogsActions.error(payload),
}

export const GetAllEmailLogsWith0Offset = {
  success: (payload: any) => GetAllEmailLogsWith0OffsetActions.success(payload),
}

/// ///////////////////////////////////////////////////////////////////////
