import { Reducer } from 'redux'
import { ActionTypes, TTermsState } from './types'

export const initialState: TTermsState = {
  loading: false,
  data: null,
  confirmed: true,
}

const reducer: Reducer<TTermsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_TERMS_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_TERMS_S:
      return { ...state, loading: false, errors: undefined, data: action.payload }
    case ActionTypes.GET_TERMS_E:
      return { ...state, loading: false, errors: action.payload }
    
    case ActionTypes.POST_TERMS_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.POST_TERMS_S:
      return { ...state, loading: false, errors: undefined, confirmed: true, data: action.payload }
    case ActionTypes.POST_TERMS_E:
      return { ...state, loading: false, errors: action.payload }
    
    case ActionTypes.SET_TERMS_FALSE:
      return { ...state, confirmed: false }
    case ActionTypes.SET_TERMS_TRUE:
      return { ...state, confirmed: true }
    
    default:
      return state
  }
}

export { reducer as TermsReducer }