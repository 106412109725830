import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as Actions from './actions'
import { callApi } from '../../../../utils/callApi'
import { API_ROUTES } from '../../../../utils/apiRoutes'
import { ActionTypes, TAllBidsByTender, TCsv} from './types'
import { SnackBar } from '../../../../components/ui/SnackBar'

const { addSnackBar } = SnackBar()

function* getAllBidsByTenderWorker(action: ReturnType<typeof Actions.GetAllBidsByTender.request>) {
  const {
    callBack,
    idTender,
    offset,
    limit,
    search,
    sortBy,
    sort,
  } = action.payload as Actions.TypeGetAllBidsByTenderR
  
  let success = true
  let data = null
  
  let query = `?Offset=${offset || 0}&Limit=${limit || 20}`
  // query += search ? `&Search=${search}` : ''
  query += search ? `&Search=${encodeURIComponent(search)}` : ''
  query += sortBy ? `&SortBy=${sortBy}` : '&SortBy=created_at'
  query += sort ? `&Sort=${sort}` : '&Sort=DESC'
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminTenders}/${idTender}/bids${query}`,
    })) as TAllBidsByTender[]
    
    if (offset !== 0)
      yield put(Actions.GetAllBidsByTender.success(data))
    else
      yield put(Actions.GetAllBidsByTenderWith0Offset.success(data))
    
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetAllBidsByTender.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getAllBidsByTenderWinnersWorker(action: ReturnType<typeof Actions.GetAllBidsByTenderWinners.request>) {
  const {
    callBack,
    idTender,
    offset,
    limit,
    search,
    sortBy,
    sort,
  } = action.payload as Actions.TypeGetAllBidsByTenderWinnersR
  
  let success = true
  let data = null
  
  let query = `?Offset=${offset || 0}&Limit=${limit || 1000}`
  query += search ? `&Search=${search}` : ''
  // query += search ? `&Search=${encodeURIComponent(search)}` : ''
  query += sortBy ? `&SortBy=${sortBy}` : ''
  query += sort ? `&Sort=${sort}` : ''
  query += `&OnlyWinners=${true}`
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminTenders}/${idTender}/bids${query}`,
    })) as TAllBidsByTender[]
    
    yield put(Actions.GetAllBidsByTenderWinners.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetAllBidsByTenderWinners.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}


function* getAllBidsByTenderCsvWorker(action: ReturnType<typeof Actions.GetAllBidsByTenderCsv.request>) {
  const { callBack, idTender } = action.payload as Actions.TypeGetAllBidsByTenderCsvR
  
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminTenders}/${idTender}/bids/csv`,
    })) as TCsv
    
    yield put(Actions.GetAllBidsByTenderCsv.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetAllBidsByTenderCsv.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (!success) addSnackBar(data?.message || '', success)
  }
}

function* getBidsSummaryByTenderCsvWorker(action: ReturnType<typeof Actions.GetBidsSummaryByTenderCsv.request>) {
  const { callBack, idTender } = action.payload as Actions.TypeGetBidsSummaryByTenderCsvR
  
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminTenders}/${idTender}/bids/summary/csv`,
    })) as TCsv
    
    yield put(Actions.GetBidsSummaryByTenderCsv.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetBidsSummaryByTenderCsv.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (!success) addSnackBar(data?.message || '', success)
  }
}

function* getWinningBidsByTenderCsvWorker(action: ReturnType<typeof Actions.GetWinningBidsByTenderCsv.request>) {
  const { callBack, idTender } = action.payload as Actions.TypeGetWinningBidsByTenderCsvR
  
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminTenders}/${idTender}/bids/winning/csv`,
    })) as TCsv
    
    yield put(Actions.GetWinningBidsByTenderCsv.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetWinningBidsByTenderCsv.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (!success) addSnackBar(data?.message || '', success)
  }
}

function* makeSalesOrdersAndInvoicesWorker(action: ReturnType<typeof Actions.MakeSalesOrdersAndInvoices.request>) {
  const { callBack, idTender } = action.payload as Actions.TypeMakeSalesOrdersAndInvoicesR

  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'put',
      path: `${API_ROUTES.adminTenders}/${idTender}/ns/make-sales-orders-and-invoices`,
    })) as string

    yield put(Actions.MakeSalesOrdersAndInvoices.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.MakeSalesOrdersAndInvoices.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    const successMessage = 'Invoices have been sent.'
    const errorMessage = data?.message || ''
    const snackBarMessage = success ? successMessage : errorMessage
    addSnackBar(snackBarMessage, success)
  }
}


function* watchRequest() {
  yield takeEvery(ActionTypes.GET_ALL_BIDS_BY_TENDER_R, getAllBidsByTenderWorker)
  yield takeEvery(ActionTypes.GET_ALL_BIDS_BY_TENDER_WINNERS_R, getAllBidsByTenderWinnersWorker)
  yield takeEvery(ActionTypes.GET_ALL_BIDS_BY_TENDER_CSV_R, getAllBidsByTenderCsvWorker)
  yield takeEvery(ActionTypes.GET_BIDS_SUMMARY_BY_TENDER_CSV_R, getBidsSummaryByTenderCsvWorker)
  yield takeEvery(ActionTypes.GET_WINNING_BIDS_BY_TENDER_CSV_R, getWinningBidsByTenderCsvWorker)
  yield takeEvery(ActionTypes.MAKE_SALES_ORDERS_AND_INVOICES_IN_NS_R, makeSalesOrdersAndInvoicesWorker)
  
}

export default function* allBidsByTenderSaga() {
  yield all([fork(watchRequest)])
}
