import { Reducer } from 'redux'
import { ActionTypes, TAdminUsersState } from './types'
import { getDataForPagination } from '../../../utils/reducerUtils'

export const initialState: TAdminUsersState = {
  loading: false,
  data: null,
  userForEditData: null,
}

const reducer: Reducer<TAdminUsersState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_All_USERS_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_All_USERS_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        data: {
          ...state.data,
          ...action.payload,
          users: getDataForPagination(state.data?.users || [], action.payload?.users),
        },
      }
    case ActionTypes.GET_All_USERS_WITH_0_OFFSET_S:
      return { ...state, loading: false, errors: undefined, data: action.payload }
    case ActionTypes.GET_All_USERS_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.GET_USER_BY_ID_R:
      return { ...state, errors: undefined }
    case ActionTypes.GET_USER_BY_ID_S:
      return { ...state, loading: false, errors: undefined, userForEditData: action.payload }
    case ActionTypes.GET_USER_BY_ID_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.CREATE_NEW_USER_R:
      return { ...state, errors: undefined }
    case ActionTypes.CREATE_NEW_USER_S:
      return { ...state }
    case ActionTypes.CREATE_NEW_USER_E:
      return { ...state, errors: action.payload }

    case ActionTypes.UPDATE_USER_INFO_R:
      return { ...state, errors: undefined }
    case ActionTypes.UPDATE_USER_INFO_S:
      return { ...state, loading: false }
    case ActionTypes.UPDATE_USER_INFO_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.GET_USERS_CSV_TEMPLATE_R:
      return { ...state, errors: undefined }
    case ActionTypes.GET_USERS_CSV_TEMPLATE_S:
      return { ...state, loading: false }
    case ActionTypes.GET_USERS_CSV_TEMPLATE_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.GET_CATEGORY_CSV_R:
      return { ...state, errors: undefined }
    case ActionTypes.GET_CATEGORY_CSV_S:
      return { ...state, loading: false }
    case ActionTypes.GET_CATEGORY_CSV_E:
      return { ...state, loading: false, errors: action.payload }

    default:
      return state
  }
}

export { reducer as AdminUsersReducer }
