import { Actions } from '../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../types/actions'
import { tenderSocketType } from '../../hooks/Sockets/useTender'

/// ///////////////////////////////////////////////////////////////////////
const GetItemInfoActions = new Actions('GET_ITEM_INFO', ActionTypes)

export interface TypeGetItemInfoR extends TDefRequest {
  itemId: number
}

export const GetItemInfo = {
  request: (payload: TypeGetItemInfoR) => GetItemInfoActions.request(payload),
  success: (payload: any) => GetItemInfoActions.success(payload),
  error: (payload: string) => GetItemInfoActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetItemPricesActions = new Actions('GET_ITEM_PRICES', ActionTypes)

export interface TypeGetPricesInfoR extends TDefRequest {
  itemId: number
}

export const GetItemPrices = {
  request: (payload: TypeGetPricesInfoR) => GetItemPricesActions.request(payload),
  success: (payload: any) => GetItemPricesActions.success(payload),
  error: (payload: string) => GetItemPricesActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetTenderInfoActions = new Actions('GET_TENDER_INFO', ActionTypes)

export interface TypeGetTenderInfoR extends TDefRequest {
  idTender: number
}

export const GetTenderInfo = {
  request: (payload: TypeGetTenderInfoR) => GetTenderInfoActions.request(payload),
  success: (payload: any) => GetTenderInfoActions.success(payload),
  error: (payload: string) => GetTenderInfoActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetTenderItemsActions = new Actions('GET_TENDER_ITEMS', ActionTypes)

export interface TypeGetTenderItemsR extends TDefRequest {
  idTender: number
  offset: number
  limit: number
  search?: string
  sortBy?: string
  sort?: string
  showMyActivity?: boolean
  minWeight?: string
  maxWeight?: string
  favorites?: boolean
  recommendations?: boolean
  available?: boolean
  certified?: boolean
  lab?: string
  hasBox?: string
  hasPapers?: string
  brands?: string
  filters?: string
  adminInfo?: boolean
}

export const GetTenderItems = {
  request: (payload?: TypeGetTenderItemsR) => GetTenderItemsActions.request(payload || {}),
  success: (payload: any) => GetTenderItemsActions.success(payload),
  error: (payload: string) => GetTenderItemsActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetTenderItemsPricesActions = new Actions('GET_TENDER_ITEMS_PRICES', ActionTypes)

export interface TypeGetTenderItemsPricesR extends TDefRequest {
  idTender: number
  offset: number
  limit: number
  search?: string
  sortBy?: string
  sort?: string
  showMyActivity?: boolean
  minWeight?: string
  maxWeight?: string
  favorites?: boolean
  available?: boolean
  certified?: boolean
  lab?: string
  hasBox?: string
  hasPapers?: string
  brands?: string
  filters?: string
  adminInfo?: boolean
}

export const GetTenderItemsPrices = {
  request: (payload?: TypeGetTenderItemsPricesR) => GetTenderItemsPricesActions.request(payload || {}),
  success: (payload: any) => GetTenderItemsPricesActions.success(payload),
  error: (payload: string) => GetTenderItemsPricesActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
export interface ChangeItemByIdR extends TDefRequest {
  idItem: number
  body?: {
    bid?: number
    bid_status_id?: number
    category_id?: number
    in_favorites?: boolean
    is_sold?: boolean
  }
}

export const ChangeItemById = (payload: ChangeItemByIdR) => ({ type: ActionTypes.CHANGE_ITEMS_BY_ID, payload })

/// ///////////////////////////////////////////////////////////////////////
const SetItemWishlistActions = new Actions('SET_ITEM_WISHLIST', ActionTypes)

export interface TypeSetItemWishlist extends TDefRequest {
  idTender: number
  idItem: number
  status: boolean
}

export const SetItemWishlist = {
  request: (payload: TypeSetItemWishlist) => SetItemWishlistActions.request(payload),
  success: (payload: { idItem: number; status: boolean }) => SetItemWishlistActions.success(payload),
  error: (payload: string) => SetItemWishlistActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const SetItemRecommendationActions = new Actions('SET_ITEM_RECOMMENDATION', ActionTypes)

export interface TypeSetItemRecommendation extends TDefRequest {
  idItem: number
  companyId: number[]
  status: boolean
}

export const SetItemRecommendation = {
  request: (payload: TypeSetItemRecommendation) => SetItemRecommendationActions.request(payload),
  success: (payload: { idItem: number; status: boolean }) => SetItemRecommendationActions.success(payload),
  error: (payload: string) => SetItemRecommendationActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
export const WsChangeTenderItems = (payload: tenderSocketType) => ({
  type: ActionTypes.WS_CHANGE_TENDER_ITEMS,
  payload,
})

export const WsChangeTenderItemsForPersRecommend = (payload: tenderSocketType) => ({
  type: ActionTypes.WS_CHANGE_TENDER_ITEMS_FOR_PERS_RECOMMEND,
  payload,
})

export const LoadTenderPrices = () => ({ type: ActionTypes.LOAD_TENDER_PRICES })
export const ShowPrices = () => ({ type: ActionTypes.SHOW_PRICES })
