import React from 'react'
import styled from 'styled-components'

const Diamond = styled.ul`
  display: block;
  width: 200px;
  height: 70px;
  position: relative;

  li {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    opacity: 0;
    z-index: 100;
    transition: 400ms ease;
  }

  li:nth-child(1) {
    border-width: 20px 20px 0 20px;
    border-color: #7AD997 transparent transparent transparent;
    left: 0;
    right: 0;
    top: 0;
    opacity: 1;
    margin: auto;
    animation: opacity 2s 0.4s ease infinite;
    animation-fill-mode: forwards;
  }

  li:nth-child(2) {
    border-width: 0 20px 20px 20px;
    border-color: transparent transparent #64C592;
    left: 59px;
    top: 1px;
    opacity: 1;
    animation: opacity 2s 0.4s ease infinite;
    animation-fill-mode: forwards;
  }

  li:nth-child(3) {
    border-width: 0 20px 20px 20px;
    border-color: transparent transparent #54B28D;
    right: 59px;
    top: 1px;
    opacity: 1;
    animation: opacity 2s 0.4s ease infinite;
    animation-fill-mode: forwards;
  }

  li:nth-child(4) {
    border-width: 20px 20px 0 20px;
    border-color: #7AD997 transparent transparent transparent;
    right: 59px;
    top: 22px;
    opacity: 1;
    animation: opacity 2s 0.6s ease infinite;
    animation-fill-mode: forwards;
  }

  li:nth-child(5) {
    border-width: 20px 20px 0 20px;
    border-color: #B4FEA5 transparent transparent transparent;
    left: 59px;
    top: 22px;
    opacity: 1;
    animation: opacity 2s 0.6s ease infinite;
    animation-fill-mode: forwards;
  }

  li:nth-child(6) {
    border-width: 0 20px 20px 20px;
    border-color: transparent transparent #90EF9D;
    left: 0;
    right: 0;
    top: 23px;
    margin: auto;
    opacity: 1;
    animation: opacity 2s 0.6s ease infinite;
    animation-fill-mode: forwards;
  }

  li:nth-child(7) {
    border-width: 20px 20px 0 20px;
    border-color: #C9FEAC transparent transparent transparent;
    left: 0;
    right: 0;
    margin: auto;
    top: 44px;
    opacity: 1;
    animation: opacity 2s 0.8s ease infinite;
    animation-fill-mode: forwards;
  }

  @keyframes opacity {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 1;
    }
  }
`

export const DiamondLoader = () => {
  return (
    <div
      className='diamond-loader inset-0 flex justify-center items-center'
    >
      <div>
        <Diamond>
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
          <li />
        </Diamond>
      </div>
    </div>
  )
}
