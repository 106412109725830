import { Actions } from '../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../types/actions'


/// ///////////////////////////////////////////////////////////////////////
const GetDiamondVideoActions = new Actions('GET_DIAMOND_VIDEO', ActionTypes)

export interface TypeGetDiamondVideoR extends TDefRequest {
  idItem: number
}

export const GetDiamondVideo = {
  request: (payload?: TypeGetDiamondVideoR) => GetDiamondVideoActions.request(payload || {}),
  success: (payload: any) => GetDiamondVideoActions.success(payload),
  error: (payload: string) => GetDiamondVideoActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
