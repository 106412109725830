import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { callApi } from '../../../../utils/callApi'
import { API_ROUTES } from '../../../../utils/apiRoutes'
import * as Actions from './actions'
import { ActionTypes, TEmailLogs  } from './types'


function* getAllTemplatesWorker(action: ReturnType<typeof Actions.GetAllEmailLogs.request>) {
  const { callBack, search, offset, limit, isAccepted, isSent, isOpened, isFailed } = action.payload as Actions.TypeGetAllEmailLogsR
  let success = true
  let data = null
  
  let query = `?Offset=${offset || 0}&Limit=${limit || 20}`
  query += search ? `&Search=${search}` : ''
  query += isAccepted ? `&IsAccepted=${isAccepted}` : ''
  query += isSent ? `&IsSent=${isSent}` : ''
  query += isOpened ? `&IsOpened=${isOpened}` : ''
  query += isFailed ? `&IsFailed=${isFailed}` : ''
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminEmailLogs}${query}`,
    })) as TEmailLogs[]

    if (offset !== 0)
      yield put(Actions.GetAllEmailLogs.success(data))
    else
      yield put(Actions.GetAllEmailLogsWith0Offset.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetAllEmailLogs.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.GET_ALL_EMAIL_LOGS_R, getAllTemplatesWorker)
}

export default function* emailLogsSaga() {
  yield all([fork(watchRequest)])
}
