import React from 'react'

export const UsersCrown = () => {
  return (
    <svg
      className="svg-inline--fa fa-users-crown fa-w-20"
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="users-crown"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 512"
      data-fa-i2svg=""
    >
      <g className="fa-group">
        <path 
          className="fa-secondary" 
          fill="rgb(156, 163, 175)"
          d="M96 224a64 64 0 1 0-64-64 64 64 0 0 0 64 64zm448 0a64 64 0 1 0-64-64 64 64 0 0 0 64 64zm32 32h-64a63.78 63.78 0 0 0-45.07 18.59A146.54 146.54 0 0 1 542.06 384H608a32 32 0 0 0 32-32v-32a64 64 0 0 0-64-64zm-512 0a64 64 0 0 0-64 64v32a32 32 0 0 0 32 32h65.94a146.54 146.54 0 0 1 75.13-109.41A63.78 63.78 0 0 0 128 256zM320 32l-48 24-48-24v80h192V32l-48 24z"
        /> 
        <path
          className="fa-primary"
          fill="currentColor"
          d="M320 256a96 96 0 0 0 96-96v-16H224v16a96 96 0 0 0 96 96zm76.8 32h-8.31c-20.84 10-43.89 16-68.49 16s-47.64-6-68.49-16h-8.31A115.2 115.2 0 0 0 128 403.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-28.8A115.2 115.2 0 0 0 396.8 288z"
        />
      </g>
    </svg>
  )
}