import React from 'react'
import { Link } from 'react-router-dom'
import { IconLogo } from '../../svg/IconLogo'
import { ROUTES } from '../../utils/routes'

export const ErrorReset = () => {
  return (
    <div
      className='page-content max-w-screen-xl mx-auto flex justify-center items-center text-sm lg:pt-9 smallMobile:pt-16 pt-20'
    >
      <div className='container mx-auto'>
        <div className='max-w-md mx-auto my-10'>
          <div className='text-center'>
            <h1 className='my-3 text-xl font-circa font-semibold text-gray-700 dark:text-gray-200'>
              <IconLogo />
              <p>Forgot Password</p>
            </h1>
          </div>
          
          <div className='m-7'>
            <div className='mb-6'>
              <div
                className='w-full text-base px-3 py-3 text-red-800 border border-red-400 bg-red-100 rounded-md text-center'
              >
                Error, try again
              </div>
            </div>
            
            <div className='mb-6'>
              <button type='button' className='form-button'>
                <Link to={ROUTES.forgotPass}>go to forgot password</Link>
              </button>
            </div>
            
            {/* <div> */}
            {/*  <Link to="/new-password" className="text-base text-gray-400 focus:outline-none focus:text-button-reports hover:text-button-reports"> */}
            {/*    Go to start */}
            {/*  </Link> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
