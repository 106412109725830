import { addNewParams } from '../helpers'

export const gallerySortChange = (
  value: string,
  setSort: (value: string) => void,
  setSortBy: (value: string) => void,
  setActiveFilter?: (value: string) => void
) => {
  const filterValue = value
  switch (filterValue) {
    case 'carat_weight_high_to_low':
      addNewParams('sort', 'carat_weight_high_to_low')
      setSort('DESC')
      setSortBy('weight')
      break

    case 'carat_weight_low_to_high':
      addNewParams('sort', 'carat_weight_low_to_high')
      setSort('ASC')
      setSortBy('weight')
      break

    case 'count_of_bids_high_to_low':
      addNewParams('sort', 'count_of_bids_high_to_low')
      setSort('DESC')
      setSortBy('count_of_bids')
      break

    case 'count_of_bids_low_to_high':
      addNewParams('sort', 'count_of_bids_low_to_high')
      setSort('ASC')
      setSortBy('count_of_bids')
      break

    case 'color_high_to_low':
      addNewParams('sort', 'color_high_to_low')
      setSort('DESC')
      setSortBy('color_mapping')
      break

    case 'color_low_to_high':
      addNewParams('sort', 'color_low_to_high')
      setSort('ASC')
      setSortBy('color_mapping')
      break

    case 'clarity_high_to_low':
      addNewParams('sort', 'clarity_high_to_low')
      setSort('DESC')
      setSortBy('clarity_mapping')
      break

    case 'clarity_low_to_high':
      addNewParams('sort', 'clarity_low_to_high')
      setSort('ASC')
      setSortBy('clarity_mapping')
      break

    case 'price_high_to_low':
      addNewParams('sort', 'price_high_to_low')
      setSort('DESC')
      setSortBy('asking_price')
      break

    case 'price_low_to_high':
      addNewParams('sort', 'price_low_to_high')
      setSort('ASC')
      setSortBy('asking_price')
      break

    case 'brand_a_z':
      addNewParams('sort', 'brand_a_z')
      setSort('ASC')
      setSortBy('brand')
      break

    case 'brand_z_a':
      addNewParams('sort', 'brand_z_a')
      setSort('DESC')
      setSortBy('brand')
      break

    default:
      break
  }
  if (setActiveFilter) setActiveFilter(filterValue)
}

export const galleryUrlSortChange = (
  filterValue: string,
  setSort: (value: string) => void,
  setSortBy: (value: string) => void
) => {
  switch (filterValue) {
    case 'carat_weight_high_to_low':
      setSort('DESC')
      setSortBy('weight')
      break

    case 'carat_weight_low_to_high':
      setSort('ASC')
      setSortBy('weight')
      break

    case 'count_of_bids_high_to_low':
      addNewParams('sort', 'count_of_bids_high_to_low')
      setSort('DESC')
      setSortBy('count_of_bids')
      break

    case 'count_of_bids_low_to_high':
      addNewParams('sort', 'count_of_bids_low_to_high')
      setSort('ASC')
      setSortBy('count_of_bids')
      break

    case 'color_high_to_low':
      setSort('DESC')
      setSortBy('color')
      break

    case 'color_low_to_high':
      setSort('ASC')
      setSortBy('color')
      break

    case 'clarity_high_to_low':
      setSort('DESC')
      setSortBy('clarity')
      break

    case 'clarity_low_to_high':
      setSort('ASC')
      setSortBy('clarity')
      break

    case 'price_high_to_low':
      setSort('DESC')
      setSortBy('asking_price')
      break

    case 'price_low_to_high':
      setSort('ASC')
      setSortBy('asking_price')
      break

    case 'brand_a_z':
      setSort('ASC')
      setSortBy('brand')
      break

    case 'brand_z_a':
      setSort('DESC')
      setSortBy('brand')
      break

    default:
      break
  }
}
