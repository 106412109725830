import { SnackBar } from '../components/ui/SnackBar'

const { addSnackBar } = SnackBar()

export const downloadFile = (contentType: string, decodedData: any, fileName: string) => {
  const uriContent = URL.createObjectURL(new Blob([decodedData], { type: contentType }))
  const link = document.createElement('a')
  link.setAttribute('href', uriContent)
  link.setAttribute('download', fileName)
  const event = new MouseEvent('click')
  link.dispatchEvent(event)
}

const toBase64 = async (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const promiseBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    try {
      const result = toBase64(file)
      resolve(result)
    } catch (error) {
      reject(error)
    }
  })
}

export const uploadFileBase64 = (accept: string, onLoad: (file: any, currentFile: any) => void) => {
  const input = document.createElement('input')
  input.type = 'file'
  input.accept = accept
  input.style.display = 'none'
  input.onchange = async (e: any) => {
    const currentType = e.target.files[0].type.split('/')[1]
    const currentFile = e.target.files[0]
    const accepted = accept
      .split(',')
      .some((fileType) =>
        fileType[0] === '.' ? fileType.substr(1) === currentType : fileType.split('/')[1] === currentType
      )
    if (accepted) {
      const file = await promiseBase64(e.target.files[0])
      onLoad(file, currentFile)
    } else {
      addSnackBar('Type of file is not allowed', false)
    }
  }
  document.body.appendChild(input)
  input.click()
  document.body.removeChild(input)
}

export const uploadFileFormData = (accept: string, onLoad: (formData: FormData) => void) => {
  const input = document.createElement('input')
  input.type = 'file'
  input.accept = accept
  input.style.display = 'none'
  input.onchange = async (e: any) => {
    const currentType = e.target.files[0].type.split('/')[1]
    const accepted = accept
      .split(',')
      .some((fileType) =>
        fileType[0] === '.' ? fileType.substr(1) === currentType : fileType.split('/')[1] === currentType
      )

    if (accepted) {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      onLoad(formData)
    } else {
      addSnackBar('Type of file is not allowed', false)
    }
  }
  document.body.appendChild(input)
  input.click()
  document.body.removeChild(input)
}

export const getUniqueId = () => Date.now().toString(36) + Math.random().toString(36).substr(2)
