import { fork } from 'redux-saga/effects'
import signInSaga from './auth/sagas'
import userSaga from './user/sagas'
import messageMethodsSaga from './messageMethods/sagas'
import termsSaga from './terms/sagas'
import adminMappingSaga from './admin/settings/mapping/sagas'
import adminUsersSaga from './admin/users/sagas'
import categoriesSaga from './categories/sagas'
import rolesSaga from './admin/roles/sagas'
import companiesSaga from './admin/companies/sagas'
import tendersSaga from './admin/tenders/sagas'
import bidsByItemSaga from './admin/tenders/bidsByItem/sagas'
import bidsByBiddenSaga from './admin/tenders/bidsByBidden/sagas'
import allBidsByTenderSaga from './admin/tenders/allBidsByTender/sagas'
import tenderResultSaga from './tenderResults/sagas'
import buyNowPurchasesSaga from './admin/tenders/buyNowPurchases/sagas'
import tendersFinalizeSaga from './admin/tenders/finalizeTenders/sagas'
import dashboardSaga from './dashboard/sagas'
import cmsSaga from './admin/settings/cms/sagas'
import gallerySaga from './gallery/sagas'
import itemActionsSaga from './gallery/tendersActions/sagas'
import currentBidsSaga from './gallery/tenderSummary/sagas'
import auditByTenderSaga from './admin/tenders/audit/sagas'
import emailLogsSaga from './admin/settings/emailLogs/sagas'
import upcomingTendersSaga from './admin/tenders/upcomingTenders/sagas'
import currentTimeSaga from './currentTime/sagas'
import mediaManagementSaga from './admin/settings/mediaManagement/sagas'
import diamondVideoSaga from './diamondVideo/sagas'
import adminCompaniesNSSaga from './admin/companiesNS/sagas'

export default function* rootSaga() {
  yield fork(signInSaga)
  yield fork(userSaga)
  yield fork(messageMethodsSaga)
  yield fork(termsSaga)
  yield fork(adminMappingSaga)
  yield fork(adminUsersSaga)
  yield fork(categoriesSaga)
  yield fork(rolesSaga)
  yield fork(companiesSaga)
  yield fork(tendersSaga)
  yield fork(bidsByItemSaga)
  yield fork(bidsByBiddenSaga)
  yield fork(allBidsByTenderSaga)
  yield fork(tenderResultSaga)
  yield fork(buyNowPurchasesSaga)
  yield fork(tendersFinalizeSaga)
  yield fork(dashboardSaga)
  yield fork(cmsSaga)
  yield fork(gallerySaga)
  yield fork(itemActionsSaga)
  yield fork(currentBidsSaga)
  yield fork(auditByTenderSaga)
  yield fork(emailLogsSaga)
  yield fork(upcomingTendersSaga)
  yield fork(currentTimeSaga)
  yield fork(mediaManagementSaga)
  yield fork(diamondVideoSaga)
  yield fork(adminCompaniesNSSaga )
}
