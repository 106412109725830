import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ResultsTable } from '../../components/ui/tenderResults/ResultsTable'
import { AppStore } from '../../store/applicationState'
import { GetBidsResult } from '../../store/tenderResults/actions'

interface BidResultsProps {}

export const BidResults: React.FC<BidResultsProps> = () => {
  const [sortByParams, setSortByParams] = useState('')
  const [sortParams, setSortParams] = useState('')

  const { tenderResults } = useSelector((store: AppStore) => store)
  const dispatch = useDispatch()

  useEffect(() => {
    const idTender = Number(window.location.pathname.split('/').find((elem) => Number(elem))) || 0
    dispatch(
      GetBidsResult.request({
        idTender,
        sort: sortParams || '',
        sortBy: sortByParams || '',
      })
    )
  }, [sortParams, sortByParams])

  return (
    <div>
      <div className='bids-history-table mt-8 pb-12'>
        <div className='text-white bg-lightblue-700 text-xl py-2 px-4 font-semibold font-circa'>Bidding Outcomes</div>

        <div className='overflow-x-auto'>
          {tenderResults.data.bidsResults?.length ? (
            <ResultsTable
              itemsInfo={tenderResults.data.bidsResults}
              tableType='bidsResults'
              showStatus
              sortByParams={sortByParams}
              setSortByParams={setSortByParams}
              sortParams={sortParams}
              setSortParams={setSortParams}
            />
          ) : (
            <div className='table-text w-full py-3 pl-8'>No bids</div>
          )}
        </div>
      </div>
    </div>
  )
}
