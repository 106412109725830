import { Reducer } from 'redux'
import { ActionTypes, TCurrentBidsState } from './types'
import { parseCurrentBids } from '../../../utils/socketUtils/parseCurrentBids'
import { changeImageSizeForSummery } from '../../../utils/imgUrlParse/imgUrlParser'

export const initialState: TCurrentBidsState = {
  loading: false,
  data: {
    currentBids: null,
    bidsAmount: 0,
    buyNowAmount: 0,
    refreshCurrentBids: 0,
  },
}

const reducer: Reducer<TCurrentBidsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CURRENT_BIDS_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_CURRENT_BIDS_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        data: {
          ...state.data,
          currentBids: changeImageSizeForSummery(action.payload),
          bidsAmount: action.payload?.filter((item: any) => !item.is_buy_now).length,
          buyNowAmount: action.payload?.filter((item: any) => item.is_buy_now).length,
        },
      }
    case ActionTypes.GET_CURRENT_BIDS_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.WS_CURRENT_BIDS:
      return { ...parseCurrentBids(state, action.payload) }

    default:
      return state
  }
}

export { reducer as CurrentBidsReducer }
