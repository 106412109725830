import React from 'react'

export const Icon404 = ({ width = '' }) => {
  return (
    <svg width={width || '629'} height='155' viewBox='0 0 629 155' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.791504 91.4004L104.792 2.40039H167.191V85.2004H198.191V123.6H167.191V152H110.592V123.6H0.791504V91.4004ZM110.592 85.2004V40.8004L58.5915 85.2004H110.592Z'
        fill='#114B6D'
      />
      <path
        d='M423.526 74.4004V80.4004C423.526 86.6671 422.859 92.7337 421.526 98.6004C420.326 104.467 417.459 111 412.926 118.2C408.526 125.267 402.726 131.4 395.526 136.6C388.326 141.667 378.126 146 364.926 149.6C351.859 153.067 336.593 154.8 319.126 154.8H310.126C292.659 154.8 277.326 153.067 264.126 149.6C250.926 146 240.726 141.667 233.526 136.6C226.326 131.4 220.459 125.267 215.926 118.2C211.526 111 208.659 104.467 207.326 98.6004C206.126 92.7337 205.526 86.6671 205.526 80.4004V74.4004C205.526 70.0004 205.793 65.7337 206.326 61.6004C206.993 57.4671 208.259 52.8671 210.126 47.8004C212.126 42.7337 214.659 38.0671 217.726 33.8004C220.793 29.4004 224.993 25.1337 230.326 21.0004C235.793 16.7337 242.059 13.1337 249.126 10.2004C256.326 7.26705 265.126 4.93372 275.526 3.20039C285.926 1.33372 297.459 0.400391 310.126 0.400391H319.126C331.793 0.400391 343.326 1.33372 353.726 3.20039C364.126 4.93372 372.859 7.26705 379.926 10.2004C386.993 13.1337 393.193 16.7337 398.526 21.0004C403.993 25.1337 408.259 29.4004 411.326 33.8004C414.393 38.0671 416.859 42.7337 418.726 47.8004C420.726 52.8671 421.993 57.4671 422.526 61.6004C423.193 65.7337 423.526 70.0004 423.526 74.4004ZM364.526 78.2004V75.6004C364.526 71.3337 363.726 67.3337 362.126 63.6004C360.659 59.7337 358.126 56.0004 354.526 52.4004C351.059 48.6671 345.859 45.7337 338.926 43.6004C332.126 41.4671 323.993 40.4004 314.526 40.4004C304.793 40.4004 296.459 41.4671 289.526 43.6004C282.593 45.7337 277.393 48.6671 273.926 52.4004C270.459 56.0004 267.993 59.7337 266.526 63.6004C265.059 67.3337 264.326 71.3337 264.326 75.6004V78.0004C264.326 82.2671 265.059 86.4004 266.526 90.4004C268.126 94.2671 270.659 98.1337 274.126 102C277.726 105.734 282.993 108.734 289.926 111C296.859 113.267 305.059 114.4 314.526 114.4C323.993 114.4 332.126 113.267 338.926 111C345.859 108.734 351.059 105.734 354.526 102C358.126 98.2671 360.659 94.4671 362.126 90.6004C363.726 86.6004 364.526 82.4671 364.526 78.2004Z'
        fill='#114B6D'
      />
      <path
        d='M430.674 91.4004L534.674 2.40039H597.074V85.2004H628.074V123.6H597.074V152H540.474V123.6H430.674V91.4004ZM540.474 85.2004V40.8004L488.474 85.2004H540.474Z'
        fill='#114B6D'
      />
    </svg>
  )
}
