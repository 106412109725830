import { TGalleryItemInfo } from '../../store/gallery/types'

export const recommendationHelper = (data: TGalleryItemInfo[] | null, payload: { idItem: number; status: boolean }) => {
  if (data) {
    const tenderItem = data.find((item) => item.item_id === payload.idItem)
    if (tenderItem) tenderItem.in_recommendations = payload.status
  }

  return data
}

export const recommendationPopupHelper = (
  data: TGalleryItemInfo | null,
  payload: { idItem: number; status: boolean }
) => {
  if (data && data.item_id === payload.idItem) {
    // eslint-disable-next-line no-param-reassign
    data.in_recommendations = payload.status
  }

  return data
}
