import { Reducer } from 'redux'
import { ActionTypes, TAdminNSCompaniesState } from './types'

export const initialState: TAdminNSCompaniesState  = {
  loading: false,
  data: null,
  companyNSForData: null,
  countries: null,
  errors: '',
}

const reducer: Reducer<TAdminNSCompaniesState> = (state = initialState, action) => {
  switch (action.type) {

    case ActionTypes.GET_All_NS_COMPANIES_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_All_NS_COMPANIES_S:
      return { ...state, loading: false, errors: undefined, data: action.payload }
    case ActionTypes.GET_All_NS_COMPANIES_E:
      return { ...state, loading: false, errors: action.payload }
      
    case ActionTypes.GET_NS_COMPANY_BY_ID_R:
      return { ...state, errors: undefined }
    case ActionTypes.GET_NS_COMPANY_BY_ID_S:
      return { ...state, loading: false, errors: undefined, companyNSForData: action.payload }
    case ActionTypes.GET_NS_COMPANY_BY_ID_E:
        return { ...state, loading: false, errors: action.payload }

    case ActionTypes.GET_NS_COUNTRIES_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_NS_COUNTRIES_S:
      return { ...state, loading: false, countries: action.payload }
    case ActionTypes.GET_NS_COUNTRIES_E:
      return { ...state, loading: false, errors: action.payload } 

    case ActionTypes.CLEAR_NS_COMPANY_BY_ID:
      return { ...state, loading: false, errors: undefined, companyNSForData: undefined}

    case ActionTypes.CREATE_NEW_NS_COMPANY_R:
      return { ...state, errors: undefined }
    case ActionTypes.CREATE_NEW_NS_COMPANY_S:
      return { ...state }
    case ActionTypes.CREATE_NEW_NS_COMPANY_E:
      return { ...state, errors: action.payload }

    case ActionTypes.UPDATE_NS_COMPANY_INFO_R:
      return { ...state, errors: undefined }
    case ActionTypes.UPDATE_NS_COMPANY_INFO_S:
      return { ...state, loading: false }
    case ActionTypes.UPDATE_NS_COMPANY_INFO_E:
      return { ...state, loading: false, errors: action.payload }

    default:
      return state
  }
}

export { reducer as AdminCompaniesNSReducer }
