/* eslint-disable react/require-default-props */
import React, { useEffect, useMemo } from 'react'
import { MessageState } from '../EditCms'
import {
  CATALOG_PUBLISHED_DATE_LENGTH, RESULT_PUBLISHED_LENGTH,
  TENDER_CLOSING_TIME_LENGTH, TENDER_LINK_LENGTH,
  TENDER_START_TIME_LENGTH,
} from '../../../../utils/consts'

interface SmsProps {
  smsState: MessageState
  setSmsState: (value: (prev: MessageState) => MessageState) => void
  disabled?: boolean
}

export const Sms: React.FC<SmsProps> = ({ smsState, setSmsState, disabled }) => {
  
  const countedLength = useMemo(() => {
    return [
      { variable: '{{.CatalogPublishedDate}}', length: CATALOG_PUBLISHED_DATE_LENGTH },
      { variable: '{{.TenderStartTime}}', length: TENDER_START_TIME_LENGTH },
      { variable: '{{.TenderClosingTime}}', length: TENDER_CLOSING_TIME_LENGTH },
      { variable: '{{.TenderLink}}', length: TENDER_LINK_LENGTH },
      { variable: '{{.ResultsPublished}}', length: RESULT_PUBLISHED_LENGTH }]
      .reduce((count, { variable, length }) => {
        const countOccurrences = smsState.body.split(variable).length - 1
        return count + (variable.length - length) * countOccurrences
      }, 0)
  }, [smsState.body])
  
  useEffect(() => {
    if (smsState.body.length + countedLength * -1 > 160) setSmsState(prev => ({
      ...prev,
      body: prev.body.substring(0, prev.body.length - 1),
    }))
  }, [countedLength, smsState.body])
  
  return (
    <div className='border-lightblue-900 text-lightblue-900 flex flex-col gap-y-4 lg:w-3/4'>
      <div className='relative'>
        <textarea
          disabled={disabled}
          value={smsState.body}
          onChange={e => setSmsState(prev => ({ ...prev, body: e.target.value }))}
          className='lgh-90 w-full border border-bluegray-200 rounded p-2'
          style={{ minHeight: 200 }}
          maxLength={160 + countedLength}
        />
        <div className='absolute right-6 bottom-6 text-sm select-none'>
          {smsState.body.length + countedLength * -1}
          /160
        </div>
      </div>
    </div>
  )
}
