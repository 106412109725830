import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppStore } from '../../store/applicationState'
import { GetCurrentTime } from '../../store/currentTime/actions'
import { GetTenderSchedule } from '../../store/dashboard/actions'
import { TenderInfo } from './TenderInfo'

interface TenderScheduleProps {
  userCategories: string[]
}

export const TenderSchedule: React.FC<TenderScheduleProps> = ({ userCategories }) => {
  const [loading, setLoading] = useState(false)
  const { dashboard } = useSelector((store: AppStore) => store)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(GetCurrentTime.request())
  }, [])

  useEffect(() => {
    setLoading(true)
    dispatch(
      GetTenderSchedule.request({
        callBack: (success) => {
          if (success) {
            setLoading(false)
          }
        },
      })
    )
  }, [])

  const tenders = dashboard.data.tenderSchedule

  const tendersInfo = () => {
    if (tenders && tenders.length > 0) {
      const categories: number[] = userCategories.map((type) => {
        if (type === 'diamond') return 1
        if (type === 'jewelry') return 2
        if (type === 'watches') return 3

        return 0
      })
      return tenders
        .filter((tender) => categories.includes(tender.category_id) && !(new Date(tender.finalized_at) < new Date()))
        .map((tender) => <TenderInfo tender={tender} key={`${tender.tender_id}-${tender.tender_id}`} />)
    }

    return (
      <div className='message pb-5 space-y-6 whitespace-normal'>
        The details of upcoming tenders have not been released yet. Please contact your CIRCA representative for more
        information.
      </div>
    )
  }
  return (
    <div className='schedule bg-white shadow p-8 md:px-3 desktop:px-7 space-y-6'>
      <div className='font-circa text-black text-lg font-semibold border-b border-bluegray-200 pb-3'>
        Tender Schedule
      </div>

      {loading ? null : tendersInfo()}
    </div>
  )
}
