/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { IconBack } from '../svg/IconBack'
import { booleanOptions } from '../utils/userUtils/SelectOptions'
import { stylesMulti, validateButton } from '../utils/userUtils/FormStyles'
import { FormPhoneInput } from '../components/ui/formUi/FormPhoneInput'
import {
  getValueForSelect,
  handleMultiSelect,
  handleSelect,
  handleSelectBoolean,
  onEmailChange,
  onInputChange,
} from '../utils/userUtils/EventHandlers'
import { AppStore } from '../store/applicationState'
import { GetCategories } from '../store/categories/actions'
import { GetRoles } from '../store/admin/roles/actions'
import { GetCompanies } from '../store/admin/companies/actions'
import { CreateNewUser } from '../store/admin/users/actions'
import { ROUTES } from '../utils/routes'
import { InputChecked } from '../svg/InputChecked'
import { InputNotChecked } from '../svg/InputNotChecked'

interface AddUserFormProps {}

interface CompanyOption {
  value: string
  label: string
  is_approved?: boolean
}

const getCompanyOption = (company: any): CompanyOption => {
  const option = getValueForSelect(company)
  return { ...option, is_approved: company.is_approved }
}

export const AddUserForm: React.FC<AddUserFormProps> = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { categories, roles, companies, userData } = useSelector((store: AppStore) => store)

  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [tell, setTell] = useState<string>('')
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [isViewOnly, setIsViewOnly] = useState<boolean>(false)
  const [company, setCompany] = useState<{ value: string; label: string }>({ label: '', value: '' })
  const [disabled, setDisabled] = useState<boolean>(false)
  const [roleState, setRoleState] = useState<{ value: string; label: string } | null>(null)
  const [categoriesState, setCategoriesState] = useState<{ value: string; label: string }[]>([])
  const [isApproved, setIsApproved] = useState<boolean>(true)

  const [options, setOptions] = useState(categories.data?.map((item) => getValueForSelect(item)))

  const [firstNameError, setFirstNameError] = useState<boolean>(true)
  const [lastNameError, setLastNameError] = useState<boolean>(true)
  const [emailError, setEmailError] = useState<boolean>(true)
  const [tellError, setTellError] = useState<boolean>(true)
  const [companyError, setCompanyError] = useState<boolean>(true)
  const [categoriesError, setCategoriesError] = useState(true)
  const [submitError, setSubmitError] = useState<boolean>(false)

  useEffect(() => {
    if (!categories.data) dispatch(GetCategories.request())
    if (!roles.data) dispatch(GetRoles.request())
    dispatch(GetCompanies.request())
  }, [])

  useEffect(() => {
    if (categories.data) {
      setOptions(categories.data.map((item) => getValueForSelect(item)))
    }
  }, [categories.data])

  const styles = {
    control: (provided: any) => ({
      ...provided,
      color: 'red',
      padding: '5px',
      border: `${companyError && submitError ? '1px solid rgba(248, 113, 113, 1)' : ''}`,
      boxShadow: 'none',
      '&:hover': {
        borderColor: `${companyError && submitError ? 'rgba(248, 113, 113, 1)' : ''}`,
      },
    }),
  }

  const onSubmitUserForm = (event: any) => {
    event.preventDefault()
    if (emailError || firstNameError || lastNameError || tellError || companyError || categoriesError) {
      setSubmitError(true)
      return null
    }
    setSubmitError(false)
    return dispatch(
      CreateNewUser.request({
        body: {
          company_id: +company.value,
          disabled,
          email,
          first_name: firstName,
          full_access_categories: !isViewOnly ? categoriesState.map((item) => +item.value) : [],
          is_mobile: isMobile,
          last_name: lastName,
          phone: tell,
          role_id: roleState?.value ? +roleState.value : undefined,
          view_only_categories: isViewOnly ? categoriesState.map((item) => +item.value) : undefined,
        },
        callBack: (success) => {
          if (success) history.push(`${ROUTES.adminPageUsersCustomers}`)
        },
      })
    )
  }

  const onCompanySelect = (selectedOption: CompanyOption | null) => {
    if (selectedOption) {
      setCompany({ value: selectedOption.value, label: selectedOption.label })
      setCompanyError(false)
      const approved = selectedOption.is_approved || false
      setIsApproved(approved)
      setIsViewOnly(!approved)
    }
  }

  return (
    <div className='pb-3 bg-white'>
      <button
        type='button'
        className='py-1.5 smallMobile:py-3 w-max flex flex-row items-center h-16'
        onClick={() => {
          // @ts-ignore
          if (location.state?.fromUsers) history.goBack()
          else history.push(`${ROUTES.adminPageUsersCustomers}`)
        }}
      >
        <IconBack />

        <div className='ml-2 text-menuLink opacity-50 hover:opacity-80'>Back to table</div>
      </button>

      <div className='flex justify-center items-center'>
        <form
          noValidate
          onSubmit={(event) => onSubmitUserForm(event)}
          action=''
          className='2smallest:px-2 smallMobile:px-4 w-11/12 max-w-screen-xl'
        >
          <div className='flex flex-col lg:flex-row'>
            <div className='form-user-block lg:mr-2'>
              <div className='font-circa text-2xl font-normal'>Identity</div>

              <div className='mb-1 p-3 bg-bluegray-50 flex flex-col md:flex-row justify-between'>
                <div className='md:w-1/2 md:pr-1 mb-3 md:mb-0'>
                  <label htmlFor='firstName' className='form-label'>
                    First name *
                  </label>

                  <div>
                    <input
                      value={firstName}
                      onChange={(event) => onInputChange(event, setFirstName, setFirstNameError)}
                      type='text'
                      name='firstName'
                      id='firstName'
                      placeholder='Enter First name *'
                      className={`form-input ${validateButton(firstNameError, submitError)}`}
                    />

                    <div className={`form-error-text mt-1 ${firstNameError && submitError ? 'block' : 'hidden'}`}>
                      Please enter a valid first name.
                    </div>
                  </div>
                </div>

                <div className='md:w-1/2 md:pl-1'>
                  <label htmlFor='lastName' className='form-label'>
                    Last name *
                  </label>

                  <div>
                    <input
                      value={lastName}
                      onChange={(event) => onInputChange(event, setLastName, setLastNameError)}
                      type='text'
                      name='lastName'
                      id='lastName'
                      placeholder='Enter Last name *'
                      className={`form-input ${validateButton(lastNameError, submitError)}`}
                    />

                    <div className={`form-error-text mt-1 ${lastNameError && submitError ? 'block' : 'hidden'}`}>
                      Please enter a valid last name.
                    </div>
                  </div>
                </div>
              </div>

              <div className='mb-1 p-3 bg-bluegray-50'>
                <label htmlFor='email' className='form-label'>
                  Email *
                </label>

                <div>
                  <input
                    value={email}
                    onChange={(event) => onEmailChange(event, setEmail, setEmailError)}
                    type='email'
                    name='email'
                    id='email'
                    placeholder='Enter Email *'
                    className={`form-input ${validateButton(emailError, submitError)}`}
                  />

                  <div className={`form-error-text mt-1 ${emailError && submitError ? 'block' : 'hidden'}`}>
                    Please enter valid email address.
                  </div>
                </div>
              </div>

              <div className='mb-1 p-3 bg-bluegray-50 flex flex-col md:flex-row justify-between'>
                <div className='md:w-2/3 md:pr-1 mb-3 md:mb-0'>
                  <label htmlFor='phoneNumber' className='form-label'>
                    Phone number *
                  </label>

                  <FormPhoneInput
                    userTell={tell}
                    setUserTell={setTell}
                    userTellError={tellError}
                    setTellError={setTellError}
                    submitError={submitError}
                  />
                </div>

                <div className='md:w-1/3 md:pl-1'>
                  <label htmlFor='isMobile' className='form-label'>
                    Is mobile
                  </label>

                  <div>
                    <Select
                      name='isMobile'
                      options={booleanOptions}
                      defaultValue={getValueForSelect(`${isMobile}`)}
                      onChange={(event) => handleSelectBoolean(event, setIsMobile)}
                      isSearchable={false}
                      styles={stylesMulti}
                      id='isMobile'
                    />
                  </div>
                </div>
              </div>

              <div className='mb-1 p-3 bg-bluegray-50'>
                <label htmlFor='company' className='form-label'>
                  Company *
                </label>

                <div>
                  <Select
                    options={companies.data?.companies?.map((item) => getCompanyOption(item))}
                    isClearable={false}
                    placeholder='Select company'
                    menuPlacement='top'
                    onChange={(event) => onCompanySelect(event)}
                    styles={styles}
                    id='company'
                  />
                  <div className={`form-error-text mt-1 ${companyError && submitError ? 'block' : 'hidden'}`}>
                    Please select or enter your company.
                  </div>
                </div>
              </div>
            </div>

            <div className='form-user-block mt-6 lg:mt-0 lg:ml-2 lg:h-1/2 min-h-[425px]'>
              <div className='font-circa text-2xl font-normal'>Status</div>

              <div className='mb-1 p-3 bg-bluegray-50 flex flex-row justify-between'>
                <div className='w-full'>
                  <label htmlFor='Disabled' className='form-label'>
                    Disabled
                  </label>
                  <div>
                    <Select
                      options={booleanOptions}
                      defaultValue={getValueForSelect(`${disabled}`)}
                      onChange={(event) => handleSelectBoolean(event, setDisabled)}
                      isSearchable={false}
                      styles={stylesMulti}
                      name='Disabled'
                      id='Disabled'
                    />
                  </div>
                </div>
              </div>

              <div className='mb-1 p-3 bg-bluegray-50'>
                <label htmlFor='role' className='form-label'>
                  Role
                </label>
                <div>
                  <Select
                    options={roles.data
                      ?.filter((item) => (userData.isAssociateAdmin ? item.role_id !== 1 : item))
                      .map((item) => getValueForSelect(item))}
                    placeholder='Select user role'
                    onChange={(event) => handleSelect(event, setRoleState, () => {})}
                    styles={stylesMulti}
                    isClearable
                    id='role'
                  />
                </div>
              </div>

              <div className={`mb-1 p-3 bg-bluegray-50 ${!isApproved ? 'pb-0' : ''}`}>
                <label htmlFor='full_access_categories' className='form-label'>
                  Categories*
                </label>
                <div>
                  <Select
                    options={options}
                    closeMenuOnSelect={false}
                    placeholder='Select user categories'
                    onChange={(event) => handleMultiSelect(event, setCategoriesState, setCategoriesError)}
                    styles={stylesMulti}
                    isMulti
                    isClearable
                    id='full_access_categories'
                  />
                </div>
                <div className={`form-error-text mt-1 ${categoriesError && submitError ? 'block' : 'hidden'}`}>
                  Selecting at least one category is required.
                </div>
                <label
                  htmlFor='isViewOnly'
                  className={`w-max-content text-sm text-gray-600 flex items-center mt-4 cursor-pointer ${
                    !isApproved ? 'cursor-default opacity-50' : ''
                  }`}
                >
                  <input
                    type='checkbox'
                    id='isViewOnly'
                    className='hidden'
                    checked={isViewOnly}
                    disabled={!isApproved}
                    onChange={() => setIsViewOnly(!isViewOnly)}
                  />
                  {isViewOnly ? <InputChecked /> : <InputNotChecked />}
                  <span className='ml-3 select-none'>View only</span>
                </label>
                {!isApproved && (
                  <p className='text-red-500 mt-1 text-sm'>User can have full access only if the company is approved</p>
                )}
              </div>
            </div>
          </div>

          <div className='p-3 mt-6 flex items-center justify-center'>
            <button
              type='submit'
              className='py-3 px-16 text-white bg-green-700 hover:bg-green-800 text-base rounded-md product-buttons'
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
