import { Reducer } from 'redux'
import { ActionTypes, TAddressFormsState } from './types'

export const initialState: TAddressFormsState = {
  addressForms: []
}

const reducer: Reducer<TAddressFormsState> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.TOGGLE_ADDRESS_FORM: {
          const updatedForms = [...state.addressForms]
          updatedForms[action.payload.index] = action.payload.isExpanded
          return {
            ...state,
            addressForms: updatedForms 
          }
        }
        case ActionTypes.RESET_ADDRESS_FORMS_STATE:
            return {
                ...state,
                addressForms: [],
            }
        default:
          return state
      }
}

export { reducer as AddressFormsReducer }

