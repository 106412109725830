export interface TUpcomingTenders {
  category_id: number,
  created_at: string,
  finalized_at: string,
  id: number,
  preview_start_at: string,
  sales_cycle: string,
  start_at: string,
  status: string,
  updated_at: string,
  user?: {
    email: string,
    user_id: number
  }
}


export interface TUpcomingTendersMessage {
  message: string
}

export interface TUpcomingTendersState {
  loading: boolean
  data: TUpcomingTenders[] | null
  errors?: string | undefined
}

export enum ActionTypes {
  GET_UPCOMING_TENDERS_R = '@@TENDERS/GET_UPCOMING_TENDERS_R',
  GET_UPCOMING_TENDERS_S = '@@TENDERS/GET_UPCOMING_TENDERS_S',
  GET_UPCOMING_TENDERS_E = '@@TENDERS/GET_UPCOMING_TENDERS_E',
  
  CHANGE_UPCOMING_TENDER_VISIBLE_R = '@@TENDERS/CHANGE_UPCOMING_TENDER_VISIBLE_R',
  CHANGE_UPCOMING_TENDER_VISIBLE_S = '@@TENDERS/CHANGE_UPCOMING_TENDER_VISIBLE_S',
  CHANGE_UPCOMING_TENDER_VISIBLE_E = '@@TENDERS/CHANGE_UPCOMING_TENDER_VISIBLE_E',
}
