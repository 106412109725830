import React from 'react'

interface IconBackProps {}

export const IconBack: React.FC<IconBackProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g fill="#767D98">
          <g>
            <path
              d="M15.265 18c.184 0 .368-.07.515-.211.293-.282.293-.723 0-1.005L10.8 12.01l4.98-4.793c.293-.282.293-.723 0-1.005-.294-.281-.754-.281-1.048 0L9.22 11.498c-.147.14-.22.317-.22.493 0 .194.073.37.22.494l5.512 5.286c.154.147.323.218.494.228l.04.001z"
              transform="translate(-150 -228) translate(150 228)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
