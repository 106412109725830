import { Reducer } from 'redux'
import { getDataForPagination } from '../../utils/reducerUtils'
import { ActionTypes, TDashboardState } from './types'

export const initialState: TDashboardState = {
  loading: false,
  data: {
    acquisitionHistory: null,
    acquisitionPerformance: null,
    tenderSchedule: null,
    ics: null,
    allAcquisition: null
  }
}

const reducer: Reducer<TDashboardState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ACQUISITION_HISTORY_R:
      return { ...state, loading: true, errors: undefined }
      case ActionTypes.GET_ACQUISITION_HISTORY_S:
        return { ...state, loading: false, errors: undefined, data: { ...state.data, acquisitionHistory: getDataForPagination(state.data.acquisitionHistory, action.payload) }  }
      case ActionTypes.GET_ACQUISITION_HISTORY_WITH_0_OFFSET_S:
        return { ...state, loading: false, errors: undefined, data: { ...state.data, acquisitionHistory: action.payload } }
    // case ActionTypes.GET_ACQUISITION_HISTORY_S:
    //   return { ...state, loading: false, errors: undefined, data: { ...state.data, acquisitionHistory: action.payload } }
    case ActionTypes.GET_ACQUISITION_HISTORY_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.GET_ACQUISITION_PERFORMANCE_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_ACQUISITION_PERFORMANCE_S:
      return { ...state, loading: false, errors: undefined, data: { ...state.data, acquisitionPerformance: action.payload } }
    case ActionTypes.GET_ACQUISITION_PERFORMANCE_E:
      return { ...state, loading: false, errors: action.payload }
        
    case ActionTypes.GET_TENDER_SCHEDULE_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_TENDER_SCHEDULE_S:
      return { ...state, loading: false, errors: undefined, data: { ...state.data, tenderSchedule: action.payload } }
    case ActionTypes.GET_TENDER_SCHEDULE_E:
      return { ...state, loading: false, errors: action.payload }
        
      case ActionTypes.GET_ICS_R:
        return { ...state, loading: true, errors: undefined }
      case ActionTypes.GET_ICS_S:
        return { ...state, loading: false }
      case ActionTypes.GET_ICS_E:
        return { ...state, loading: false, errors: action.payload }
        
      case ActionTypes.GET_ALL_ACQUISITIONS_R:
        return { ...state, loading: true, errors: undefined }
      case ActionTypes.GET_ALL_ACQUISITIONS_S:
        return { ...state, loading: false }
      case ActionTypes.GET_ALL_ACQUISITIONS_E:
        return { ...state, loading: false, errors: action.payload }
    
    default:
      return state
  }
}

export { reducer as DashboardReducer }