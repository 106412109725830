export interface TTenderResults {
  bid: number,
  bid_by: string,
  bid_status_id: number,
  bracelet_length: string,
  category_id: number,
  condition: string,
  date: string,
  gia_link: string,
  img: string,
  item_id: number,
  list_price: number,
  necklace_length: string,
  ref: string,
  ring_size: string,
  title: string,
  video_link: string,
  weight: number,
  weight_grams: string,
  year: string
}

export interface TTenderInfo {
  category_id: number,
  finalized_at: string,
  previewStartAt: string,
  sales_cycle: string,
  start_at: string,
  status: string
}

export interface TTenderResultsMessage {
  message: string
}

export interface TTenderResultsState {
  loading: boolean
  data: {
    bidsResults: TTenderResults[] | null
    purchaseHistory: TTenderResults[] | null
    bidsWon: TTenderResults[] | null
    tenderInfo: TTenderInfo | null
  }
  errors?: string | undefined
}

export enum ActionTypes {
  GET_BIDS_RESULT_R = '@@TENDER_RESULTS/GET_BIDS_RESULT_R',
  GET_BIDS_RESULT_S = '@@TENDER_RESULTS/GET_BIDS_RESULT_S',
  GET_BIDS_RESULT_E = '@@TENDER_RESULTS/GET_BIDS_RESULT_E',

  GET_BIDS_WON_R = '@@TENDER_RESULTS/GET_BIDS_WON_R',
  GET_BIDS_WON_S = '@@TENDER_RESULTS/GET_BIDS_WON_S',
  GET_BIDS_WON_E = '@@TENDER_RESULTS/GET_BIDS_WON_E',

  GET_PURCHASE_HISTORY_R = '@@TENDER_RESULTS/GET_PURCHASE_HISTORY_R',
  GET_PURCHASE_HISTORY_S = '@@TENDER_RESULTS/GET_PURCHASE_HISTORY_S',
  GET_PURCHASE_HISTORY_E = '@@TENDER_RESULTS/GET_PURCHASE_HISTORY_E',

  GET_TENDER_INFO_R = '@@TENDER_RESULTS/GET_TENDER_INFO_R',
  GET_TENDER_INFO_S = '@@TENDER_RESULTS/GET_TENDER_INFO_S',
  GET_TENDER_INFO_E = '@@TENDER_RESULTS/GET_TENDER_INFO_E',
}
