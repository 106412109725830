// export const styles = {
//   control: (provided: any, state: any) => ({
//     ...provided,
//     color: "red",
//     padding: '5px',
//     border: `${userCompanyError ? '1px solid rgba(248, 113, 113, 1)' : ''}`,
//     boxShadow: 'none',
//     '&:hover': {
//       borderColor: `${userCompanyError ? 'rgba(248, 113, 113, 1)' : ''}`
//     }
//   })
// };

export const stylesMulti = {
  control: (provided: any) => ({
    ...provided,
    color: 'red',
    padding: '5px',
    boxShadow: 'none',
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: 'white',
    backgroundColor: '#5897fb',
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: '#A52A2A',
  }),
}

export const validateButton = (inputError: boolean, formError: boolean) => {
  if (inputError && formError) {
    return 'form-input-invalid border-red-400 focus:border-red-400 focus-within:border-red-400'
  }
  if (!inputError && formError) {
    return 'form-input-valid border-green-700 focus:border-green-700 focus-within::border-green-700'
  }
  return 'border-gray-300 focus:border-button-reports focus-within:border-button-reports'
}
