import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { WsChangeTenderItemsForPersRecommend } from '../../store/gallery/actions'
import { ROUTES } from '../../utils/routes'

export interface tenderSocketType {
  item_id: number
  percent_of_rap: number
  price: number
  price_per_carat: number
  type_of_event: number
}

export const useTenderForPersRecommend = (socket: WebSocket | null) => {
  const location = useLocation()
  const dispatch = useDispatch()

  const [currentTenderID, setCurrentTenderID] = useState<number>(0)

  const tenderListener = useCallback((data: tenderSocketType) => {
    dispatch(WsChangeTenderItemsForPersRecommend(data))
  }, [])

  useEffect(() => {
    const tenderId: number = Number(window.location.pathname.split('/').find((elem) => Number(elem))) || 0

    if (location.pathname.search(ROUTES.tenders) !== -1 && tenderId) {
      setCurrentTenderID((prev) => (prev !== tenderId ? tenderId : prev))
    } else {
      setCurrentTenderID((prev) => (prev !== 0 ? 0 : prev))
    }
  }, [location.pathname])

  useEffect(() => {
    if (socket) {
      socket.send(JSON.stringify({ tender_id: currentTenderID, listen_all_actions: true }))
    }
  }, [socket, currentTenderID])

  return {
    tenderListener,
  }
}
