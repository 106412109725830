import React from 'react'

export const FileCool = () => {
  return (
    <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.1464 9.50067V2.70844C16.1464 1.21511 14.9036 0 13.3764 0H3.36229C1.83501 0 0.592285 1.21511 0.592285 2.70844V15.2084C0.592285 16.702 1.83501 17.9169 3.36229 17.9169H10.6643C11.6586 19.1818 13.2202 20 14.9746 20C17.9707 20 20.4077 17.6167 20.4077 14.6876C20.4077 12.152 18.5807 10.0264 16.1464 9.50067ZM9.9341 16.6667H3.36229C2.54001 16.6667 1.87069 16.0127 1.87069 15.2082V2.70822C1.87069 1.90422 2.53979 1.24978 3.36229 1.24978H13.3766C14.1989 1.24978 14.8682 1.904 14.8682 2.70822V9.37733C11.9211 9.43356 9.54138 11.7929 9.54138 14.6876C9.54138 15.3869 9.6816 16.0542 9.9341 16.6667ZM10.82 14.6876C10.82 16.9276 12.6836 18.75 14.9746 18.75C17.2655 18.75 19.1293 16.9276 19.1293 14.6876C19.1293 12.4476 17.2655 10.6251 14.9746 10.6251C12.6836 10.6251 10.82 12.4476 10.82 14.6876Z'
        fill='currentColor'
      />
      <path
        d='M16.892 14.0626H15.6136V12.8126C15.6136 12.4671 15.3275 12.1875 14.9743 12.1875C14.6209 12.1875 14.335 12.4673 14.335 12.8126V14.0626H13.0568C12.7034 14.0626 12.4175 14.3422 12.4175 14.6877C12.4175 15.0328 12.7034 15.3128 13.0568 15.3128H14.3352V16.5628C14.3352 16.9079 14.6213 17.1879 14.9745 17.1879C15.3279 17.1879 15.6138 16.9077 15.6138 16.5628V15.3128H16.8923C17.2454 15.3128 17.5316 15.0326 17.5316 14.6877C17.5313 14.3422 17.2454 14.0626 16.892 14.0626Z'
        fill='currentColor'
      />
      <path
        d='M5.06681 5.41624H11.672C12.0252 5.41624 12.3114 5.13624 12.3114 4.79113C12.3114 4.44557 12.025 4.16602 11.672 4.16602H5.06681C4.71363 4.16602 4.42749 4.44557 4.42749 4.79113C4.42749 5.13624 4.71363 5.41624 5.06681 5.41624Z'
        fill='currentColor'
      />
      <path
        d='M12.3116 8.43761C12.3116 8.09206 12.0252 7.8125 11.6723 7.8125H5.06681C4.71363 7.8125 4.42749 8.09206 4.42749 8.43761C4.42749 8.78272 4.71363 9.06272 5.06681 9.06272H11.672C12.0252 9.06272 12.3116 8.78272 12.3116 8.43761Z'
        fill='currentColor'
      />
      <path
        d='M8.4759 11.459H5.06681C4.71363 11.459 4.42749 11.7385 4.42749 12.0841C4.42749 12.4294 4.71363 12.7092 5.06681 12.7092H8.4759C8.82908 12.7092 9.11522 12.4292 9.11522 12.0841C9.11499 11.7383 8.82885 11.459 8.4759 11.459Z'
        fill='currentColor'
      />
    </svg>
  )
}