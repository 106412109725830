/* eslint-disable react/require-default-props */
import React, { useEffect, useRef } from 'react'
import { getUniqueId } from '../../utils/FileUtils'

interface CheckboxProps {
  label: string
  isChecked: boolean | undefined
  setIsChecked: (value: boolean) => void
  classname?: string
  labelClassname?: string
  variant?: 'green' | 'blue'
  disabled?: boolean
  customId?: string
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  isChecked,
  setIsChecked,
  classname,
  labelClassname,
  variant,
  disabled,
  customId,
}) => {
  const id = customId || getUniqueId()
  let inputClass = ''
  let labelClass = ''

  switch (variant) {
    case 'green':
      inputClass = 'checked:bg-green-800 checked:border-green-800'
      labelClass = `${isChecked ? 'text-green-800' : 'text-lightblue-900'}`
      break
    case 'blue':
    inputClass = 'checked:bg-blue-800 checked:border-blue-800'
    labelClass = `${isChecked ? 'text-blue-800' : 'text-lightblue-900'}`
    break

    default:
      inputClass = 'checked:bg-lightblue-600 checked:border-lightblue-600'
      labelClass = 'text-lightblue-900'
  }

  const checkBoxRef = useRef(null)

  useEffect(() => {
    if (checkBoxRef.current) {
      // @ts-ignore
      checkBoxRef.current.checked = isChecked
    }
  }, [isChecked])

  return (
    <div className={`flex items-center ${labelClass} ${classname}`}>
      <input
        id={id}
        ref={checkBoxRef}
        type='checkbox'
        defaultChecked={isChecked}
        style={{ minWidth: 20, minHeight: 20 }}
        className={`disabled:opacity-30 disabled:cursor-default form-check-input appearance-none w-4 h-4 border border-gray-300 rounded-sm bg-white ${inputClass} focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain cursor-pointer`}
        onChange={(e) => setIsChecked(e.target.checked)}
        disabled={disabled}
      />
      <label htmlFor={id} className={`select-none px-2 overflow-auto cursor-pointer ${labelClassname}`}>
        {label}
      </label>
    </div>
  )
}
