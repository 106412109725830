/* eslint-disable react/require-default-props */
import React from 'react'
import { CustomDropdown } from '../adminUsersComponents/CustomDropdown'
import { TCategories } from '../../../store/categories/types'
import { TRoles } from '../../../store/admin/roles/types'

interface RolesCategoriesDropdownProps {
  soledIcon: boolean
  onClearRoleAndCategoriesFilters: () => void
  
  categoriesData?: TCategories[]
  selectedFullAccessCategories: number[]
  setSelectedFullAccessCategories: (categoryId: number) => void
  selectedViewOnlyCategories: number[]
  setSelectedViewOnlyCategories: (categoryId: number) => void
  
  rolesData?: TRoles[]
  selectedRoles: number[]
  setSelectedRoles: (roleId: number) => void
}

export const RolesCategoriesDropdown: React.FC<RolesCategoriesDropdownProps> = ({
                                                                                  soledIcon,
                                                                                  onClearRoleAndCategoriesFilters,
                                                                                  categoriesData,
                                                                                  selectedFullAccessCategories,
                                                                                  setSelectedFullAccessCategories,
                                                                                  selectedViewOnlyCategories,
                                                                                  setSelectedViewOnlyCategories,
                                                                                  rolesData,
                                                                                  selectedRoles,
                                                                                  setSelectedRoles,
                                                                                }) => {
  return (
    <CustomDropdown
      menuItemsWidth='w-54'
      title='Roles & Categories'
      solidIcon={soledIcon}
      onClear={onClearRoleAndCategoriesFilters}
    >
      {[
        {
          title: 'Full access categories',
          activeCheckboxId: selectedFullAccessCategories,
          checkboxes: categoriesData?.map(category => ({
            id: category.category_id,
            title: category.urlhash,
            callBack: () => setSelectedFullAccessCategories(category.category_id),
          })),
        },
        {
          title: 'View only categories',
          activeCheckboxId: selectedViewOnlyCategories,
          checkboxes: categoriesData?.map(category => ({
            id: category.category_id,
            title: category.urlhash,
            callBack: () => setSelectedViewOnlyCategories(category.category_id),
          })),
        },
        {
          title: 'Roles',
          activeCheckboxId: selectedRoles,
          checkboxes: rolesData?.map(role => ({
            id: role.role_id,
            title: role.title,
            callBack: () => setSelectedRoles(role.role_id),
          })),
        },
      ]}
    </CustomDropdown>
  )
}
