import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { callApi } from '../../utils/callApi'
import { API_ROUTES } from '../../utils/apiRoutes'
import * as Actions from './actions'
import { ActionTypes, TAcquisitionHistory, TAcquisitionPerformance, TIcs, TTenderSchedule } from './types'
import { SnackBar } from '../../components/ui/SnackBar'

const { addSnackBar } = SnackBar()

function* getAcquisitionHistoryWorker(action: ReturnType<typeof Actions.GetAcquisitionHistory.request>) {
  const { callBack,idCategory, offset, limit, sortBy, search, sort } = action.payload as Actions.TypeGetAcquisitionHistoryR
  let success = true
  let data = null

  let query = `?Offset=${offset || 0}&Limit=${limit || 20}`
  query += search ? `&Search=${search}` : ''
  query += sortBy ? `&SortBy=${sortBy}` : ''
  query += sort ? `&Sort=${sort}` : ''

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.tendersDashboard}/my-acquisition-history/categories/${idCategory}${query}`,
    })) as TAcquisitionHistory[]

    if (offset !== 0)
    yield put(Actions.GetAcquisitionHistory.success(data))
    else
      yield put(Actions.GetAcquisitionHistoryWith0Offset.success(data))
    
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetAcquisitionHistory.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getGetAcquisitionPerformanceWorker(action: ReturnType<typeof Actions.GetAcquisitionPerformance.request>) {
  const { callBack, idCategory } = action.payload as Actions.TypeGetAcquisitionPerformanceR
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.tendersDashboard}/my-acquisition-performance/categories/${idCategory}`,
    })) as TAcquisitionPerformance[]
    
    yield put(Actions.GetAcquisitionPerformance.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetAcquisitionPerformance.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getTenderScheduleWorker(action: ReturnType<typeof Actions.GetTenderSchedule.request>) {
  const { callBack } = action.payload as Actions.TypeGetTenderScheduleR
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.tendersDashboard}/tender-schedule`,
    })) as TTenderSchedule[]
    
    yield put(Actions.GetTenderSchedule.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetTenderSchedule.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getIcsWorker(action: ReturnType<typeof Actions.GetIcs.request>) {
  const { callBack, idTender } = action.payload as Actions.TypeGetIcsR
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.tenders}/${idTender}/ics`,
    })) as TIcs
    
    yield put(Actions.GetIcs.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetIcs.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (!success) addSnackBar(data?.message || '', success)
  }
}

function* getAllAcquisitionWorker(action: ReturnType<typeof Actions.GetAllAcquisition.request>) {
  const { callBack } = action.payload as Actions.TypeGetAllAcquisitionR
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.tendersDashboard}/download-all-acquisitions-xls`,
    })) as string
    
    yield put(Actions.GetAllAcquisition.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetAllAcquisition.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.GET_ACQUISITION_HISTORY_R, getAcquisitionHistoryWorker)
  yield takeEvery(ActionTypes.GET_ACQUISITION_PERFORMANCE_R, getGetAcquisitionPerformanceWorker)
  yield takeEvery(ActionTypes.GET_TENDER_SCHEDULE_R, getTenderScheduleWorker)
  yield takeEvery(ActionTypes.GET_ICS_R, getIcsWorker)
  yield takeEvery(ActionTypes.GET_ALL_ACQUISITIONS_R, getAllAcquisitionWorker)
}

export default function* dashboardSaga() {
  yield all([fork(watchRequest)])
}