import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from '../../components/ui/adminComponents/Input'
import { GetBidsByBidden, GetBidsByBiddenChild } from '../../store/admin/tenders/bidsByBidden/actions'
import { getBidsByBiddenChildParsed, getBidsByBiddenParsed } from '../../utils/tendersUtils/tenderDataHelper'
import { Table } from '../../components/ui/adminComponents/Table'
import { AppStore } from '../../store/applicationState'
import { paginationProps, usePagination } from '../../hooks/usePagination'
import { searchProps, useSearch } from '../../hooks/useSearch'
import { useAddItemPopup } from '../../hooks/useAddItemPopup'
import { TenderStatusType } from '../../utils/tendersUtils/tenderStatusHelper'
import { LoaderPoints } from '../../components/ui/LoaderPoints'

const bidsByBidderValuesThead = {
  email: 'Customer',
  company_title: 'Company',
  count_of_bids: '# Bids',
  last_bid: 'Last Bid',
  action: 'Action',
}

const bidsByBidderValuesTheadChild = {
  own: 'Own',
  stock: 'Stock #',
  bid_date: 'Bid Date ',
  item: 'Item',
  bid: 'Bid ',
  reserve: 'Reserve',
  buy_now: 'Buy Now',
}

const tableCellWidth = [270, 150, 90, 200, 140]

interface BidsByBidderProps {
  currentTenderId: number
  tenderStatus: TenderStatusType | null
}

export const BidsByBidder: React.FC<BidsByBidderProps> = ({ currentTenderId, tenderStatus }) => {
  const limitAmount = 400
  const initialParams = { limit: limitAmount, offset: 0, search: undefined, sort: '', sortBy: '' }

  const dispatch = useDispatch()
  const { bidsByBidden, userData, tenders } = useSelector((store: AppStore) => store)
  const { add } = useAddItemPopup()

  const [params, setParams] = useState<searchProps | paginationProps>(initialParams)
  const [dispatchTime, setDispatchTime] = useState(0)
  const [childIndex, setChildIndex] = useState<number[]>([])

  const didMount = useRef(false)

  usePagination(bidsByBidden.data, setParams, limitAmount)
  useSearch(limitAmount, params, setParams, setDispatchTime)

  useEffect(() => {
    if (didMount.current) {
      setDispatchTime((prev) => prev + 1)
      setParams(initialParams)
    }
    didMount.current = true
  }, [currentTenderId])

  useEffect(() => {
    dispatch(
      GetBidsByBidden.request({
        idTender: currentTenderId,
        offset: params.offset,
        limit: params.limit,
        search: params.search,
        sortBy: params.sortBy,
        sort: params.sort,
      })
    )
  }, [params.offset, params.limit, params.sortBy, params.sort, dispatchTime])

  useEffect(() => {
    if (tenders.updateTenderNow) {
      if (params.offset === 0) setDispatchTime((prev) => prev + 1)
      else setParams((prev) => ({ ...prev, offset: 0, limit: prev.limit + prev.offset }))
    }
  }, [tenders.updateTenderNow])

  /// /////////////////////////////////////////////////////////////////////////////////////////////
  const callBack = (sortBy: string) => {
    if (sortBy === 'action') return

    let value: { sortBy: string; sort: 'ASC' | 'DESC' }
    if (sortBy !== params.sortBy || params.sort === 'DESC') value = { sortBy, sort: 'ASC' }
    else value = { sortBy, sort: 'DESC' }

    setParams((prev) => ({ ...prev, offset: 0, limit: limitAmount, sortBy: value.sortBy, sort: value.sort }))
    setChildIndex([])
  }

  const getChildForBidsByBidden = (idUser: number) => {
    if (idUser !== -1) {
      dispatch(GetBidsByBiddenChild.request({ idTender: currentTenderId, idUser }))
    }
  }

  const bidsByBidderValues = useMemo(() => {
    return bidsByBidden.data
      ? getBidsByBiddenParsed(bidsByBidden.data, childIndex, getChildForBidsByBidden, currentTenderId)
      : null
  }, [bidsByBidden.data, childIndex])

  const bidsByBidderValuesChild = useMemo(() => {
    if (!bidsByBidden.childData) return null
    const result = new Map<number, object[]>()
    bidsByBidden.childData.forEach((value, key) => {
      result.set(key, getBidsByBiddenChildParsed(value, userData.isExecutiveAdmin, dispatch, add, tenderStatus))
    })
    return result
  }, [bidsByBidden.childData?.entries()])

  useEffect(() => {
    setChildIndex(
      childIndex.filter(
        (child) => bidsByBidden.data && bidsByBidden.data.some((item) => +`${currentTenderId}${item.user_id}` === child)
      )
    )
  }, [bidsByBidden.data])

  useEffect(() => {
    if (tenders.updateTenderNow) {
      if (childIndex.length) {
        childIndex.map((child) => {
          if (bidsByBidden.data) getChildForBidsByBidden(child - currentTenderId * currentTenderId.toString().length)

          return null
        })
      }
    }
  }, [tenders.updateTenderNow])

  const bidsByBidderTable = useMemo(() => {
    return bidsByBidderValues ? (
      <Table
        thead={bidsByBidderValuesThead}
        tbody={bidsByBidderValues}
        theadChild={bidsByBidderValuesTheadChild}
        tbodyChild={bidsByBidderValuesChild}
        callBack={callBack}
        childIndex={childIndex}
        setChildIndex={setChildIndex}
        tableCellWidth={tableCellWidth}
      />
    ) : null
  }, [bidsByBidderValues, bidsByBidderValuesChild])

  return (
    <>
      <div className='flex justify-between lg:items-center mb-4 flex-col lg:flex-row'>
        <div className='w-full md:w-1/3 lg:w-1/4 mb-6 lg:mb-0'>
          <Input
            placeholder='Search...'
            value={params.search || ''}
            onChange={(e) => setParams((prev) => ({ ...prev, search: e.target.value }))}
          />
        </div>
      </div>
      <div className='relative'>
        {bidsByBidderTable}
        {!!bidsByBidderTable && bidsByBidden.loading && (
          <div className='absolute left-0 right-0 -bottom-6 flex justify-center'>
            <LoaderPoints className='m-auto' />
          </div>
        )}
      </div>
    </>
  )
}
