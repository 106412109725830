export interface TTenderStatsForBuyNowPurchases {
  sold_count: number,
  total_buy_now: number,
  total_count: number
}

export interface TBuyNowPurchases {
  asking_discount?: number
  asking_price: number
  asking_price_per_carat?: number
  category_id: number
  company_id: number
  company_title: string
  consignor_name: string
  cost: string
  email: string
  ns_processing_status: string
  item_id: number
  shipping_owner: string
  stock_number: string
  title: string
  transaction_date: string
  user_id: number
  ns_err_msg: string
}

export interface TCsv {
  file: any,
  file_name: string
}

export interface TBuyNowPurchasesMessage {
  message: string
}

export interface TBuyNowPurchasesState {
  loading: boolean
  data: TBuyNowPurchases[] | null
  errors?: string | undefined
  tenderStats: TTenderStatsForBuyNowPurchases | null
}

export enum ActionTypes {
  GET_BUY_NOW_PURCHASES_R = '@@TENDERS/GET_BUY_NOW_PURCHASES_R',
  GET_BUY_NOW_PURCHASES_S = '@@TENDERS/GET_BUY_NOW_PURCHASES_S',
  GET_BUY_NOW_PURCHASES_WITH0_OFFSET_S = '@@TENDERS/GET_BUY_NOW_PURCHASES_WITH0_OFFSET_S',
  GET_BUY_NOW_PURCHASES_E = '@@TENDERS/GET_BUY_NOW_PURCHASES_E',
  
  DELETE_BUY_NOW_R = '@@TENDERS/DELETE_BUY_NOW_R',
  DELETE_BUY_NOW_S = '@@TENDERS/DELETE_BUY_NOW_S',
  DELETE_BUY_NOW_E = '@@TENDERS/DELETE_BUY_NOW_E',
  
  GET_BUY_NOW_PURCHASES_CSV_R = '@@TENDERS/GET_BUY_NOW_PURCHASES_CSV_R',
  GET_BUY_NOW_PURCHASES_CSV_S = '@@TENDERS/GET_BUY_NOW_PURCHASES_CSV_S',
  GET_BUY_NOW_PURCHASES_CSV_E = '@@TENDERS/GET_BUY_NOW_PURCHASES_CSV_E',
  
  GET_TENDER_STATS_FOR_BUY_NOW_PURCHASES_R = '@@TENDERS/GET_TENDER_STATS_FOR_BUY_NOW_PURCHASES_R',
  GET_TENDER_STATS_FOR_BUY_NOW_PURCHASES_S = '@@TENDERS/GET_TENDER_STATS_FOR_BUY_NOW_PURCHASES_S',
  GET_TENDER_STATS_FOR_BUY_NOW_PURCHASES_E = '@@TENDERS/GET_TENDER_STATS_FOR_BUY_NOW_PURCHASES_E',

  MAKE_SALES_ORDERS_AND_INVOICES_R = '@@TENDERS/MAKE_SALES_ORDERS_AND_INVOICES_R',
  MAKE_SALES_ORDERS_AND_INVOICES_S = '@@TENDERS/MAKE_SALES_ORDERS_AND_INVOICES_S',
  MAKE_SALES_ORDERS_AND_INVOICES_E = '@@TENDERS/MAKE_SALES_ORDERS_AND_INVOICES_E',

  CHANGE_STATUS_SALES_ORDERS_AND_INVOICES_BUY_NOW = '@@TENDERS/CHANGE_STATUS_SALES_ORDERS_AND_INVOICES_BUY_NOW'
}
