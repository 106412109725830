import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { callApi } from '../../../utils/callApi'
import { API_ROUTES } from '../../../utils/apiRoutes'
import { ActionTypes, TAdminNSCompany, TAdminNSCompanies, TAdminNSCountry} from './types'
import * as Actions from './actions'
import { SnackBar } from '../../../components/ui/SnackBar'

const { addSnackBar } = SnackBar()


function* getAllCompaniesNSWorker(action: ReturnType<typeof Actions.GetAllCompaniesNS.request>) {
  const { callBack, offset, limit, search, withoutCompanyRel, onlyNotSynced } = action.payload as Actions.TypeGetAllCompaniesNS
  let success = true
  let data = null

 
  let query = `?Offset=${offset || 0}&Limit=${limit || 1000}`
  query += search ? `&Search=${search}` : ''
  query += withoutCompanyRel ? `&WithoutCompanyRel=${withoutCompanyRel}` : ''
  query += onlyNotSynced ? `&OnlyNotSynced=${onlyNotSynced}` : ''

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminGetCompanyNS}${query}`,
    })) as TAdminNSCompanies[]

    yield put(Actions.GetAllCompaniesNS.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetAllCompaniesNS.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* GetNSCompanyByIdWorker(action: ReturnType<typeof Actions.GetNSCompanyById.request>) {
  const { companyNSId, callBack } = action.payload as Actions.TypeGetNSCompanyById
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminGetNSCompanyById}/${companyNSId}`,
    })) as TAdminNSCompany

    yield put(Actions.GetNSCompanyById.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetNSCompanyById.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getNSCountriesWorker(action: ReturnType<typeof Actions.GetNSCountries.request>) {
  const { offset, limit, search, callBack } = action.payload as Actions.TypeGetNSCountries
  let success = true
  let data = null

  let query = `?Offset=${offset || 0}&Limit=${limit || 500}`
  query += search ? `&Search=${search}` : ''

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminGetNSCountries}${query}`,
    })) as TAdminNSCountry[]

    yield put(Actions.GetNSCountries.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetNSCountries.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* CreateNewNSCompanyWorker(action: ReturnType<typeof Actions.CreateNewNSCompany.request>) {
  const { body, callBack } = action.payload as Actions.TypeCreateNewNSCompany
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'post',
      path: API_ROUTES.adminCreateNSCompany,
      data: body,
    })) as TAdminNSCompany

    yield put(Actions.CreateNewNSCompany.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.CreateNewNSCompany.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    const successMessage = 'Company NS has been created'
    const errorMessage = data?.message || ''
    const snackBarMessage = success ? successMessage : errorMessage
    addSnackBar(snackBarMessage, success)
  }
}


function* UpdateNSCompanyInfoWorker(action: ReturnType<typeof Actions.UpdateNSCompanyInfo.request>) {
  const { ns_internal_id, body, callBack } = action.payload as Actions.TypeUpdateNSCompanyInfo
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'put',
      path: `${API_ROUTES.adminUpdateNSCompany}/${ns_internal_id}`,
      data: body,
    })) as TAdminNSCompany

    yield put(Actions.UpdateNSCompanyInfo.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.UpdateNSCompanyInfo.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    const successMessage = 'Company NS has been updated'
    const errorMessage = data?.message || ''
    const snackBarMessage = success ? successMessage : errorMessage
    addSnackBar(snackBarMessage, success)
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.GET_All_NS_COMPANIES_R, getAllCompaniesNSWorker)
  yield takeEvery(ActionTypes.GET_NS_COMPANY_BY_ID_R, GetNSCompanyByIdWorker)
  yield takeEvery(ActionTypes.CREATE_NEW_NS_COMPANY_R, CreateNewNSCompanyWorker)
  yield takeEvery(ActionTypes.UPDATE_NS_COMPANY_INFO_R, UpdateNSCompanyInfoWorker)
  yield takeEvery(ActionTypes.GET_NS_COUNTRIES_R, getNSCountriesWorker)
}

export default function* adminCompaniesNSSaga() {
  yield all([fork(watchRequest)])
}