import React, { useEffect, useState } from 'react'
import * as filestack from 'filestack-js'
import { useDispatch, useSelector } from 'react-redux'
import { DropDownImageManage } from '../../../components/ui/adminComponents/DropDownImageManage'
import { filestackBacket, fileStackSource } from '../../../utils/consts'
import {
  GetFilestackApiKey,
  GetTenderImagesStatus,
  SendImagesMetadata,
} from '../../../store/admin/settings/mediaManagement/actions'
import { TTenders } from '../../../store/admin/tenders/types'
import { AppStore } from '../../../store/applicationState'
import { CheckImagesExistModal } from '../../../components/ui/adminComponents/CheckImagesExistModal'

interface uploaderLogsTypes {
  text: string
  status: 'success' | 'error' | 'normal'
  value?: string
}

export const UploadImage = () => {
  const [selectedTender, setSelectedTender] = useState<TTenders | null>(null)
  const [uploaderLogs, setUploaderLogs] = useState<uploaderLogsTypes[]>([])
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const { mediaManagement } = useSelector((store: AppStore) => store)

  const filestackTenderId = selectedTender?.id || 0

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(GetFilestackApiKey.request())
  }, [])

  const callback = (file: any) => {
    const files = file.filesUploaded.map((image: { filename: string; handle: string }) => {
      return {
        file_name: image.filename,
        handle: image.handle,
        tender_id: filestackTenderId,
      }
    })
    if (file.filesUploaded.length) {
      dispatch(
        SendImagesMetadata.request({
          imagesData: files,
          callBack: (success) => {
            setLoading(false)
            if (success) {
              setUploaderLogs((prev) => [
                ...prev,
                {
                  text: `${file.filesUploaded.length} files successfuly uploaded`,
                  status: 'success',
                },
              ])
            }
          },
        })
      )
    }
    if (file.filesFailed.length) {
      setUploaderLogs((prev) => [
        ...prev,
        {
          text: `Error loading ${file.filesFailed.length} files`,
          status: 'error',
        },
      ])
    }
  }

  const checkImageName = (imageName: string) => {
    const splitedName = imageName.replace('-', '_').split('_')
    const regex = /^-?\d+\.?\d*$/
    const imageIndex = splitedName[splitedName.length - 1].split('.')[0]

    if (splitedName.length < 2 || !regex.test(imageIndex) || imageIndex.length > 2) {
      setUploaderLogs((prev) => [
        ...prev,
        {
          text: 'The image name format is incorrect, please rename and upload again: ',
          status: 'error',
          value: imageName,
        },
      ])
      return false
    }

    return true
  }
  const checkVideoName = (videoName: string) => {
    const regex = /^[^\W_]+$/
    const imageIndex = videoName.split('.')[0].replace('-', '_').split('_')[0]

    if (!regex.test(imageIndex)) {
      setUploaderLogs((prev) => [
        ...prev,
        {
          text: 'The video name format is incorrect, please rename and upload again: ',
          status: 'error',
          value: videoName,
        },
      ])
      return false
    }

    return true
  }

  const pickerOptions = {
    accept: selectedTender?.category_id === 2 ? ['image/*', 'video/*'] : ['image/*'],
    uploadInBackground: false,
    storeTo: {
      location: 's3',
      container: filestackBacket(),
      region: 'us-east-1',
      access: 'private',
      path: `/jewelry-tender/${filestackTenderId}/`,
    },
    displayMode: filestack.PickerDisplayMode.inline,
    container: '#container-inline',
    fromSources: fileStackSource,
    maxFiles: 100000,
    onFileSelected: (file: any) => {
      if (file.mimetype.includes('image')) {
        if (!checkImageName(file.filename)) throw new Error(`The image name format is incorrect: ${file.filename}`)
      } else if (!checkVideoName(file.filename)) throw new Error(`The video name format is incorrect: ${file.filename}`)
    },
    onUploadDone: (file: any) => {
      if (file.filesUploaded) callback(file)
    },
  }

  useEffect(() => {
    if (mediaManagement.data.filestackApiKey) {
      const client = filestack.init(mediaManagement.data.filestackApiKey)
      document.getElementById('__filestack-picker')?.remove()
      client.picker(pickerOptions).open()
      setUploaderLogs([])
    }
  }, [selectedTender?.id, selectedTender?.category_id, mediaManagement.data.filestackApiKey])

  const getLogStatusLabel = (uploaderLog: uploaderLogsTypes) => {
    switch (uploaderLog.status) {
      case 'error':
        return (
          <div className='text-red-700 bg-red-200 w-full p-1'>
            {uploaderLog.text}
            <b>{uploaderLog.value}</b>
          </div>
        )

      case 'success':
        return <div className='text-green-700 bg-green-200 w-full p-1'>{uploaderLog.text}</div>

      case 'normal':
        return <div className='w-full p-1'>{uploaderLog.text}</div>

      default:
        return null
    }
  }

  return (
    <div>
      <div className='flex justify-between flex-col md:flex-row'>
        <DropDownImageManage tenderId={selectedTender?.id || 0} setTenderId={setSelectedTender} />
        <button
          type='button'
          className='сheck-for-images text-white px-6 py-2 bg-lightblue-600 rounded-full hover:bg-lightblue-700 mt-4 md:mt-0 md:ml-4'
          disabled={!selectedTender?.id || !selectedTender.has_items}
          onClick={() => {
            if (selectedTender?.id) {
              setLoading(true)
              setOpenModal(true)
              dispatch(
                GetTenderImagesStatus.request({
                  idTender: selectedTender?.id,
                  callBack: (success) => {
                    setLoading(false)
                    if (!success) setOpenModal(false)
                  },
                })
              )
            }
          }}
        >
          Check for images
        </button>
      </div>

      <div className='desktop:flex mt-8 z-10 h-auto desktop:h-124 space-y-4 desktop:space-y-0 desktop:space-x-4'>
        <div
          className={`filestack-container w-full desktop:w-2/3 h-124 desktop:h-full ${
            selectedTender?.id ? '' : 'pointer-events-none opacity-40'
          }`}
          id='container-inline'
        />

        <div className='uploader-logs-container w-full desktop:w-1/3 h-124 desktop:h-full'>
          <div className='text-white bg-green-700 font-semibold p-3 h-[10%] flex justify-between'>
            <div>Uploader logs</div>
            <button
              type='button'
              className='clear-logs-button bg-white text-green-700 rounded-md p-2 flex items-center font-semibold hover:bg-slate-100'
              onClick={() => setUploaderLogs([])}
            >
              Clear logs
            </button>
          </div>
          <div className='uploader-logs p-3 border border-slate-200 overflow-y-auto h-[90%] bg-slate-50 space-y-1'>
            {uploaderLogs.length ? (
              uploaderLogs.map((uploaderLog) => getLogStatusLabel(uploaderLog))
            ) : (
              <div className='opacity-75'>Upload logs will be displayed here</div>
            )}
          </div>
        </div>
      </div>

      {openModal && selectedTender ? (
        <CheckImagesExistModal loading={loading} setOpenModal={setOpenModal} selectedTender={selectedTender} />
      ) : null}
    </div>
  )
}
