import { Actions } from '../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../types/actions'


/// ///////////////////////////////////////////////////////////////////////
const GetBidsResultActions = new Actions('GET_BIDS_RESULT', ActionTypes)

export interface TypeGetBidsResultR extends TDefRequest {
  idTender: number
  sortBy?: string
  sort?: string
}

export const GetBidsResult = {
  request: (payload: TypeGetBidsResultR) => GetBidsResultActions.request(payload),
  success: (payload: any) => GetBidsResultActions.success(payload),
  error: (payload: string) => GetBidsResultActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
const GetBidsWonActions = new Actions('GET_BIDS_WON', ActionTypes)

export interface TypeGetBidsWonR extends TDefRequest {
  idTender: number
  sortBy?: string
  sort?: string
}

export const GetBidsWon = {
  request: (payload: TypeGetBidsWonR) => GetBidsWonActions.request(payload),
  success: (payload: any) => GetBidsWonActions.success(payload),
  error: (payload: string) => GetBidsWonActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
const GetPurchaseHistoryActions = new Actions('GET_PURCHASE_HISTORY', ActionTypes)

export interface TypeGetPurchaseHistoryR extends TDefRequest {
  idTender: number
  sortBy?: string
  sort?: string
}

export const GetPurchaseHistory = {
  request: (payload: TypeGetPurchaseHistoryR) => GetPurchaseHistoryActions.request(payload),
  success: (payload: any) => GetPurchaseHistoryActions.success(payload),
  error: (payload: string) => GetPurchaseHistoryActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
const GetTenderInfoActions = new Actions('GET_TENDER_INFO', ActionTypes)

export interface TypeGetTenderInfoR extends TDefRequest {
  idTender: number
}

export const GetTenderInfo = {
  request: (payload?: TypeGetPurchaseHistoryR) => GetTenderInfoActions.request(payload || {}),
  success: (payload: any) => GetTenderInfoActions.success(payload),
  error: (payload: string) => GetTenderInfoActions.error(payload),
}


// /// ///////////////////////////////////////////////////////////////////////
