export interface TCurrentTimeMessage {
  message: string
}

export interface TCurrentTimeState {
  loading: boolean
  data: string | null
  errors?: string | undefined
}

export enum ActionTypes {
  GET_CURRENT_TIME_R = '@@currentTime/GET_CURRENT_TIME_R',
  GET_CURRENT_TIME_S = '@@currentTime/GET_CURRENT_TIME_S',
  GET_CURRENT_TIME_E = '@@currentTime/GET_CURRENT_TIME_E',
}
