/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-curly-newline */
import React from 'react'
import { useErrorPopup } from '../../../hooks/useErrorPopup'
import { Close } from '../../../svg/Close'

export interface ErrorPopupProps {
  text?: string
}

export const ErrorPopup: React.FC<ErrorPopupProps> = ({ text }) => {
  const { remove } = useErrorPopup()
  return (
    <div
      className='buy-now-popup w-full h-screen fixed bg-bluegray-900 top-0 left-0 z-110 bg-opacity-50 justify-center pt-1/12 popup-body'
      id='popup-body'
    >
      <div className=' lg:w-8/12 mx-auto max-w-3xl h-full flex justify-center items-center'>
        <div className='h-auto bg-white xrounded shadow-sm p-5 flex flex-row-reverse'>
          <div className='font-circa text-xl font-semibold flex items-start'>
            <button type='button' className='flex items-center' onClick={remove}>
              <Close styled='text-bluegray-500 h-8 w-5 hover:text-lightblue-600' />
            </button>
          </div>
          <div className='w-full text-lg p-4'>
            {text === 'access to bid' ? (
              <>
                You do not have access to bid on CIRCA tenders. Please contact{' '}
                <a href='mailto:tender@circajewels.com' className=' underline text-blue-400 hover:text-blue-700'>
                  tender@circajewels.com
                </a>{' '}
                to become a verified user.
              </>
            ) : (
              text
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
