import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { FullDateInput } from '../components/ui/adminComponents/FullDateInput'
import { Input } from '../components/ui/adminComponents/Input'
import { paginationProps } from '../hooks/usePagination'
import { searchProps, useSearch } from '../hooks/useSearch'
import { GetAllTenders } from '../store/admin/tenders/actions'
import { AppStore } from '../store/applicationState'
import { IconBack } from '../svg/IconBack'
// import { Sort } from '../svg/Sort'
import { ROUTES } from '../utils/routes'
import { getStatus } from '../utils/tendersUtils/tenderStatusHelper'

const tableCellWidth = [100, 120, 150, 280, 280, 280]

const tHead = {
  id: 'Tender ID',
  status: 'Tender Status',
  sales_cycle: 'Tender SalesCycle',
  catalog_satrt: 'Catalog Start Date',
  tender_start: 'Tender Start Date',
  tender_end: 'Tender End Date',
}

export const TendersSchedule = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [dispatchTime, setDispatchTime] = useState(0)
  const [childIndex, setChildIndex] = useState<string[]>([])
  const { search } = queryString.parse(location.search)
  const emptyField = '-----'
  const limitAmount = 1000
  const initialParams = {
    limit: limitAmount,
    offset: 0,
    search: (typeof search === 'string' && search) || undefined,
  }
  const [params, setParams] = useState<searchProps | paginationProps>(initialParams)

  const { tenders } = useSelector((store: AppStore) => store)

  useEffect(() => {
    dispatch(GetAllTenders.request({ search: params.search }))
  }, [dispatchTime])
  useSearch(limitAmount, params, setParams, setDispatchTime)

  return (
    <div>
      <button
        type='button'
        className='py-1.5 smallMobile:py-3 w-max flex flex-row items-center h-16'
        onClick={() => {
          // @ts-ignore
          if (location.state?.fromTenders) history.goBack()
          else history.push(`${ROUTES.adminPageTender}`)
        }}
      >
        <IconBack />

        <div className='ml-2 text-menuLink opacity-50 hover:opacity-80'>Back to tenders</div>
      </button>

      <div className='w-full md:w-1/3 lg:w-1/4 mb-6'>
        <Input
          placeholder='Search...'
          value={params.search || ''}
          onChange={(e) => setParams((prev) => ({ ...prev, search: e.target.value }))}
        />
      </div>
      <div className='overflow-x-auto relative pb-16'>
        <table className={`upcoming-tenders w-full ${tableCellWidth && 'table-fixed'} border whitespace-nowrap mb-2`}>
          <thead className='bg-gray-50 text-left'>
            <tr className='divide-x border-b border-bluegray-200'>
              {Object.entries(tHead).map(([key, value], index) => {
                return (
                  <th
                    key={`th${key}`}
                    scope='col'
                    style={{ width: tableCellWidth[index] }}
                    className='whitespace-nowrap'
                  >
                    {value}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody className=''>
            {tenders.data &&
              tenders.data.map((tender) => {
                return (
                  <tr className='tender divide-x border-b border-bluegray-200' key={`company-${tender.id}`}>
                    <td className='id overflow-auto'>{tender.id || emptyField}</td>
                    <td className='status overflow-auto'>{getStatus(tender) || emptyField}</td>
                    <td className='sales-cycle overflow-auto'>{tender.sales_cycle || emptyField}</td>
                    {/* <td className="sales-cycle overflow-auto">{tender.status || emptyField}</td> */}
                    <td className='catalog-start'>
                      <FullDateInput
                        tenderDate={tender.preview_start_at}
                        timeType='catalog'
                        tender={tender}
                        id={`${tender.id}_catalog_start`}
                        childIndex={childIndex}
                        setChildIndex={setChildIndex}
                        setDispatchTime={setDispatchTime}
                      />
                    </td>
                    <td className='tender-start'>
                      <FullDateInput
                        tenderDate={tender.start_at}
                        timeType='start'
                        tender={tender}
                        id={`${tender.id}_start`}
                        childIndex={childIndex}
                        setChildIndex={setChildIndex}
                        setDispatchTime={setDispatchTime}
                      />
                    </td>
                    <td className='tender-end'>
                      <FullDateInput
                        tenderDate={tender.finalized_at}
                        timeType='end'
                        tender={tender}
                        id={`${tender.id}_end`}
                        childIndex={childIndex}
                        setChildIndex={setChildIndex}
                        setDispatchTime={setDispatchTime}
                      />
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
