/* eslint-disable react/require-default-props */
import React from 'react'

interface InputProps {
  placeholder?: string;
  onBlur?: () => void;
  autoFocus?: boolean;
  inputRef?: any;
  value: string;
  onChange: (e: any) => void;
  className?: string
  disabled?: boolean
}

export const Input: React.FC<InputProps> = ({
                                              placeholder,
                                              onBlur,
                                              autoFocus,
                                              inputRef,
                                              value,
                                              onChange,
                                              className,
                                              disabled,
                                            }) => {
  return (
    <input
      type='text'
      ref={inputRef}
      disabled={disabled}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      onBlur={onBlur}
      className={`p-2 w-full text-sm  border border-bluegray-200 ring-0 focus:ring-0 focus:outline-none px-2 xxrounded-md ${className}`}
    />
  )
}
