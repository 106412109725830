import { Reducer } from 'redux'
import { getDataForPagination } from '../../../../utils/reducerUtils'
import { ActionTypes, TEmailLogsState } from './types'

export const initialState: TEmailLogsState = {
  loading: false,
  data: null,
}

const reducer: Reducer<TEmailLogsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_EMAIL_LOGS_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_ALL_EMAIL_LOGS_S:
      return { ...state, loading: false, errors: undefined, data: getDataForPagination(state.data, action.payload) }
    case ActionTypes.GET_ALL_EMAIL_LOGS_WITH_0_OFFSET_S:
      return { ...state, loading: false, errors: undefined, data: action.payload }
    case ActionTypes.GET_ALL_EMAIL_LOGS_E:
      return { ...state, loading: false, errors: action.payload }
    
    default:
      return state
  }
}

export { reducer as EmailLogsReducer }