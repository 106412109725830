/* eslint-disable react/require-default-props */
import React from 'react'

interface RadioBtnProps {
  checked: boolean;
  onChange: (e: any) => void;
  className?: string
}

export const SwitchRadioBtn: React.FC<RadioBtnProps> = ({ checked, onChange, className = '' }) => {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={`switchRadioBtn ${className}`}>
      <input type='checkbox' checked={checked} onChange={onChange} />
      <span className='sliderSwitchRadioBtn roundSwitchRadioBtn' />
    </label>
  )
}