import React, { useEffect, useRef, useState } from 'react'
import { useOnClickOutsideDropDown } from '../../../hooks/useOnClickOutsideDropDown'
import { Arrow } from '../../../svg/Arrow'
import { ByCountOfBids, DiamondOptions, JewelryOptions, WatchesOptions } from '../../../utils/galleryUtils/consts'
import { gallerySortChange } from '../../../utils/galleryUtils/EventHandlers'
import { recMode } from '../../../utils/consts'

interface DashboardSelectProps {
  tenderCategory: number
  activeFilter: string
  isCatalog: boolean
  setSort: (value: string) => void
  setSortBy: (value: string) => void
  setActiveFilter: (value: string) => void
  sortBy: string
  sort: string
}

export const GallerySelect: React.FC<DashboardSelectProps> = ({
  tenderCategory,
  activeFilter,
  isCatalog,
  setSort,
  setSortBy,
  setActiveFilter,
  sortBy,
  sort,
}) => {
  const [dropDownActive, setDropDownActive] = useState(false)
  const [dropDownTitle, setDropDownTitle] = useState('')
  const dropDownRef = useRef(null)

  useOnClickOutsideDropDown(dropDownRef, () => setDropDownActive(false))

  useEffect(() => {
    const allOptions = DiamondOptions.concat(WatchesOptions).concat(ByCountOfBids)

    if (!activeFilter) {
      const title =
        tenderCategory !== 2
          ? allOptions.find((option) => option.sortBy === sortBy && option.sort === sort)?.title || 'Select filter'
          : JewelryOptions.concat(ByCountOfBids).find((option) => option.sortBy === sortBy && option.sort === sort)
              ?.title || 'Select filter'
      setDropDownTitle(title)
    }

    if (activeFilter) {
      const selectedTitle =
        tenderCategory !== 2
          ? allOptions.find((option) => option.value === activeFilter)?.title || 'Select filter'
          : JewelryOptions.concat(ByCountOfBids).find((option) => option.value === activeFilter)?.title ||
            'Select filter'
      setDropDownTitle(selectedTitle)
    }

    return () => {}
  }, [activeFilter])

  const getSelectOptions = () => {
    switch (tenderCategory) {
      case 1:
        return [...DiamondOptions, ...(recMode ? ByCountOfBids : [])].map((option) => (
          <button
            type='button'
            key={option.title}
            className='select-none text-xs text-left relative py-2 px-3 text-gray-900 hover:text-white hover:bg-lightblue-600'
            onClick={() => {
              const { value } = option
              gallerySortChange(value, setSort, setSortBy, setActiveFilter)
              setDropDownActive(false)
            }}
            disabled={
              option.value === activeFilter ||
              dropDownTitle === option.title ||
              (isCatalog && option.value === 'price_low_to_high') ||
              (isCatalog && option.value === 'price_high_to_low')
            }
          >
            {option.title}
          </button>
        ))

      case 2:
        return [...JewelryOptions, ...(recMode ? ByCountOfBids : [])].map((option) => (
          <button
            type='button'
            key={option.title}
            className='select-none text-xs text-left relative py-2 px-3 text-gray-900 hover:text-white hover:bg-lightblue-600'
            onClick={() => {
              const { value } = option
              gallerySortChange(value, setSort, setSortBy, setActiveFilter)
              setDropDownActive(false)
            }}
            disabled={
              option.value === activeFilter ||
              dropDownTitle === option.title ||
              (isCatalog && option.value === 'price_low_to_high') ||
              (isCatalog && option.value === 'price_high_to_low')
            }
          >
            {option.title}
          </button>
        ))

      case 3:
        return [...WatchesOptions, ...(recMode ? ByCountOfBids : [])].map((option) => (
          <button
            type='button'
            key={option.title}
            className='select-none text-xs text-left relative py-2 px-3 text-gray-900 hover:text-white hover:bg-lightblue-600'
            onClick={() => {
              const { value } = option
              gallerySortChange(value, setSort, setSortBy, setActiveFilter)
              setDropDownActive(false)
            }}
            disabled={
              option.value === activeFilter ||
              dropDownTitle === option.title ||
              (isCatalog && option.value === 'price_low_to_high') ||
              (isCatalog && option.value === 'price_high_to_low')
            }
          >
            {option.title}
          </button>
        ))

      default:
        return null
    }
  }

  return (
    <div
      ref={dropDownRef}
      className={`sort-button rounded-md select-none border w-full md:w-auto border-bluegray-200 hover:bg-bluegray-100 focus:outline-none items-center ${
        dropDownActive && 'bg-bluegray-100'
      }`}
    >
      <div className='flex items-center w-full'>
        <div className='relative w-full items-center'>
          <button
            type='button'
            onClick={() => setDropDownActive(!dropDownActive)}
            className='sorting-dropdown-button text-xs md:text-sm whitespace-nowrap w-full lg:w-auto flex items-center justify-center px-4 py-[6px] lg:py-2'
          >
            <span className='text-xs text-bluegray-500 uppercase hidden lg:block mr-2'>SORT</span>
            {dropDownTitle}
            <Arrow style={{ marginLeft: '8px', marginRight: '0px' }} />
          </button>

          <div
            className={`sorting-dropdown absolute z-10 mt-2 w-full flex-col bg-bluegray-100 shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none ${
              dropDownActive ? 'flex' : 'hidden'
            }`}
          >
            {getSelectOptions()}
          </div>
        </div>
      </div>
    </div>
  )
}
