import { BidStatus } from '../../../../types/actions'

export interface TTenderStatsForBidsByItem {
  items_with_bids: number
  more_or_equal_reserve: number
  total_count: number
}

export interface TBidsByItemChild {
  bid: number
  bid_id: number
  bid_status_id: number
  category_id: number
  company_id: number
  company_title: string
  created_at: string
  email: string
  list_price: number
  user_id: number
  weight: number
  type_of_bid: BidStatus
}

export interface TBidsByItem {
  asking_discount?: number
  asking_price: number
  asking_price_per_carat?: number
  category_id: number
  company_id: number
  company_title: string
  consignor_name: string
  cost: string
  count_of_bids: number
  email: string
  high_bid: number
  item_id: number
  list_price: number
  resolved: boolean
  sales_target: number
  sales_target_per_carat?: number
  shipping_owner: string
  stock_number: string
  title: string
  user_id: number
  weight?: number
  winning_bid: number
  consignor_payout_amount: number
}

export interface TBidsByItemMessage {
  message: string
}

export interface TBidsByItemState {
  loading: boolean
  data: TBidsByItem[] | null
  dataOnlyWinners: TBidsByItem[] | null
  childData: Map<number, TBidsByItemChild[]> | null
  onlyWinnersChildData: Map<number, TBidsByItemChild[]> | null
  tenderStats: TTenderStatsForBidsByItem | null
  errors?: string | undefined
}

export enum ActionTypes {
  GET_BIDS_BY_ITEM_R = '@@TENDERS/GET_BIDS_BY_ITEM_R',
  GET_BIDS_BY_ITEM_S = '@@TENDERS/GET_BIDS_BY_ITEM_S',
  GET_BIDS_BY_ITEM_WITH_0_OFFSET_S = '@@TENDERS/GET_BIDS_BY_ITEM_WITH_0_OFFSET_S',
  GET_BIDS_BY_ITEM_E = '@@TENDERS/GET_BIDS_BY_ITEM_E',

  GET_BIDS_BY_ITEM_CHILD_R = '@@TENDERS/GET_BIDS_BY_ITEM_CHILD_R',
  GET_BIDS_BY_ITEM_CHILD_S = '@@TENDERS/GET_BIDS_BY_ITEM_CHILD_S',
  GET_BIDS_BY_ITEM_CHILD_E = '@@TENDERS/GET_BIDS_BY_ITEM_CHILD_E',

  GET_BIDS_BY_ITEM_FOR_TENDER_CLOSE_R = '@@TENDERS/GET_BIDS_BY_ITEM_FOR_TENDER_CLOSE_R',
  GET_BIDS_BY_ITEM_FOR_TENDER_CLOSE_S = '@@TENDERS/GET_BIDS_BY_ITEM_FOR_TENDER_CLOSE_S',
  GET_BIDS_BY_ITEM_FOR_TENDER_CLOSE_E = '@@TENDERS/GET_BIDS_BY_ITEM_FOR_TENDER_CLOSE_E',

  GET_BIDS_BY_ITEM_FOR_TENDER_CLOSE_CHILD_R = '@@TENDERS/GET_BIDS_BY_ITEM_FOR_TENDER_CLOSE_CHILD_R',
  GET_BIDS_BY_ITEM_FOR_TENDER_CLOSE_CHILD_S = '@@TENDERS/GET_BIDS_BY_ITEM_FOR_TENDER_CLOSE_CHILD_S',
  GET_BIDS_BY_ITEM_FOR_TENDER_CLOSE_CHILD_E = '@@TENDERS/GET_BIDS_BY_ITEM_FOR_TENDER_CLOSE_CHILD_E',

  GET_TENDER_STATS_FOR_BIDS_BY_ITEM_R = '@@TENDERS/GET_TENDER_STATS_FOR_BIDS_BY_ITEM_R',
  GET_TENDER_STATS_FOR_BIDS_BY_ITEM_S = '@@TENDERS/GET_TENDER_STATS_FOR_BIDS_BY_ITEM_S',
  GET_TENDER_STATS_FOR_BIDS_BY_ITEM_E = '@@TENDERS/GET_TENDER_STATS_FOR_BIDS_BY_ITEM_E',
}
