import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as Actions from './actions'
import { callApi } from '../../../utils/callApi'
import { API_ROUTES } from '../../../utils/apiRoutes'
import { ActionTypes, TTenders, TTendersMessage } from './types'
import { SnackBar } from '../../../components/ui/SnackBar'

const { addSnackBar } = SnackBar()

function* getAllTendersWorker(action: ReturnType<typeof Actions.GetAllTenders.request>) {
  const { callBack, search } = action.payload as Actions.TypeGetAllTendersR
  let success = true
  let data = null

  const query = search ? `?Search=${search}` : ''
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminTenders}${query}`,
    })) as TTenders[]
    
    yield put(Actions.GetAllTenders.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetAllTenders.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* changeTendersScheduleWorker(action: ReturnType<typeof Actions.ChangeTendersSchedule.request>) {
  const { callBack, body, tender_id } = action.payload as Actions.TypeChangeTendersScheduleR
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'put',
      path: `${API_ROUTES.adminTenders}/${tender_id}/change-schedule`,
      data: body,
    })) as TTendersMessage
    
    yield put(Actions.ChangeTendersSchedule.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.ChangeTendersSchedule.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    const successMessage = 'Tenders schedule has been updated'
    const errorMessage = data?.message || ''
    const snackBarMessage = success ? successMessage : errorMessage
    addSnackBar(snackBarMessage, success)
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.GET_ALL_TENDERS_R, getAllTendersWorker)
  yield takeEvery(ActionTypes.CHANGE_TENDER_SCHEDULE_R, changeTendersScheduleWorker)
}


export default function* tendersSaga() {
  yield all([fork(watchRequest)])
}