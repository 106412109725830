import React from 'react'

// interface IconCheckCircleProps {
//   // eslint-disable-next-line react/require-default-props
//   styled?: string
// }

export const IconCheckCircle = ({ styled = '', className = '' }) => {
  return (
    // <svg
    //   className={`svg-inline--fa fa-check-circle ${styled}`}
    //   aria-hidden="true"
    //   focusable="false"
    //   data-prefix="fas"
    //   data-icon="check-circle"
    //   role="img"
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 512 512"
    //   data-fa-i2svg=""
    // >
    //   <path fill="currentColor" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM371.8 211.8l-128 128C238.3 345.3 231.2 348 224 348s-14.34-2.719-19.81-8.188l-64-64c-10.91-10.94-10.91-28.69 0-39.63c10.94-10.94 28.69-10.94 39.63 0L224 280.4l108.2-108.2c10.94-10.94 28.69-10.94 39.63 0C382.7 183.1 382.7 200.9 371.8 211.8z" />
    // </svg>
    <svg
      className={`svg-inline--fa fa-check-circle fa-2x ${styled}`}
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="check-circle"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      data-fa-i2svg=""
    >
      <g className="fa-duotone-group">
        <path
          className={`fa-secondary ${className}`}
          fill="currentColor"
          d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM371.8 211.8l-128 128C238.3 345.3 231.2 348 224 348s-14.34-2.719-19.81-8.188l-64-64c-10.91-10.94-10.91-28.69 0-39.63c10.94-10.94 28.69-10.94 39.63 0L224 280.4l108.2-108.2c10.94-10.94 28.69-10.94 39.63 0C382.7 183.1 382.7 200.9 371.8 211.8z"
        />
        <path
          className="fa-primary text-white"
          fill="currentColor"
          d="M371.8 211.8l-128 128C238.3 345.3 231.2 348 224 348s-14.34-2.719-19.81-8.188l-64-64c-10.91-10.94-10.91-28.69 0-39.63c10.94-10.94 28.69-10.94 39.63 0L224 280.4l108.2-108.2c10.94-10.94 28.69-10.94 39.63 0C382.7 183.1 382.7 200.9 371.8 211.8z"
        />
      </g>
    </svg>
  )
}
