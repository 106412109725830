/* eslint-disable react/require-default-props */
import React, { useRef, useState } from 'react'
import { Sort } from '../../../svg/Sort'
import { getUniqueId } from '../../../utils/FileUtils'

interface TableTenderCloseProps {
  thead: object
  tbody: { id?: number; [x: string]: any }[] | null
  theadChild: object
  tbodyChild: Map<number, object[]> | null
  childIndex: number[]

  callBack?: (sortBy: string) => void
  loading?: boolean
  setRefsForItems: (value: ((prevState: Map<number, any>) => Map<number, any>) | Map<number, any>) => void
}

const getBidTypeIcon = (data: string) => {
  if (typeof data === 'string') {
    if (data.includes('%%%'))
      return (
        <div className=' text-blue-800 border border-blue-800 rounded-full ml-1 w-5 h-5 flex items-center justify-center my-auto'>
          %
        </div>
      )
    if (data.includes('$$$'))
      return (
        <div className=' text-green-800 border border-green-800 rounded-full ml-1 w-5 h-5 flex items-center justify-center my-auto'>
          $
        </div>
      )
  }

  return null
}

const getBidText = (data: string) => {
  if (typeof data === 'string') {
    return data.replace('%%%', '').replace('$$$', '')
  }

  return data
}

const ChildrenTable = (thead: object, tbody: object[] | undefined, id: number) => {
  if (Object.keys(thead).length === 0) return <></>

  return (
    <tr>
      <td className='p-0 m-0' colSpan={10000}>
        <div className='p-4 bg-bluegray-50 shadow-inner w-full'>
          <div className='overflow-x-auto'>
            <table id={`select-winner-${id}`} className='w-full border whitespace-nowrap'>
              <thead className='text-left bg-white'>
                <tr className='divide-x border-b border-bluegray-200'>
                  {Object.entries(thead).map(([key, value]) => (
                    <th key={key} scope='col' className='leading-none whitespace-nowrap'>
                      {value}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* eslint-disable-next-line react/destructuring-assignment */}
                {tbody?.length ? tbody.map((obj) => {
                      // @ts-ignore
                      const { className, ...restObj } = obj
                      return (
                        <tr
                          key={`table-${getUniqueId()}`}
                          className={`divide-x border-b border-bluegray-200 ${className}`}
                        >
                          {Object.entries(restObj).map(([key, value]) => {
                            const data: any = typeof value === 'string' ? value.split('|||') : value
                            return (
                              <td colSpan={key === 'reserve_price' ? 4 : 1} key={`td${key}`}>
                                <div className='flex'>
                                  {getBidText(data[0] || data)}
                                  {getBidTypeIcon(data[0] || data)}
                                </div>
                                <div className='text-sm text-bluegray-400'>{data[1]}</div>
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })
                  : tbody && (
                  <tr>
                    <td colSpan={10000} className='text-center'>
                      No available items
                    </td>
                  </tr>
                    )}
              </tbody>
            </table>
          </div>
        </div>
      </td>
    </tr>
  )
}

export const TableTenderClose: React.FC<TableTenderCloseProps> = ({
  thead,
  tbody,
  theadChild,
  tbodyChild,
  callBack,
  loading,
  childIndex,
  setRefsForItems,
}) => {
  const [itemWithKeyWasPressed, setItemWithKeyWasPressed] = useState<string>('')

  const selectedKey = useRef<{ sort: 'down' | 'up'; sortBy: string }>({ sort: 'up', sortBy: 'high_bid' })

  return (
    <div className='overflow-x-auto' id='TableTenderClose'>
      <table className='w-full border whitespace-nowrap mb-2'>
        <thead className={`bg-gray-50' text-left`}>
          <tr className='divide-x border-b border-bluegray-200'>
            {Object.entries(thead).map(([key, value]) => (
              <th key={`th${key}`} scope='col' className='whitespace-nowrap'>
                <button
                  type='button'
                  className={`disabled:opacity-100 ${selectedKey.current?.sortBy === key ? '!font-bold' : ''}`}
                  style={{ font: 'inherit' }}
                  onClick={() => {
                    setItemWithKeyWasPressed(key)
                    if (callBack) {
                      callBack(key)
                      selectedKey.current = {
                        sort:
                          selectedKey.current?.sortBy === key && selectedKey.current?.sort === 'down' ? 'up' : 'down',
                        sortBy: key,
                      }
                    }
                  }}
                  disabled={itemWithKeyWasPressed === key && loading}
                >
                  {value}
                  {key !== 'action' && key !== 'roles_categories' && (
                    <Sort sort={selectedKey.current?.sortBy === key ? selectedKey.current?.sort : undefined} />
                  )}
                </button>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tbody?.length !== 0 ? (
            tbody?.map(({ id, ...objProps }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={`React.Fragment${index}`}>
                <tr
                  id={`item-${id}`}
                  ref={(elem) => elem && setRefsForItems((prev) => prev.set(id || index, elem))}
                  className={`${index % 2 === 1 ? 'bg-white' : ''} divide-x border-b border-bluegray-200`}
                >
                  {Object.entries(objProps).map(([key, value]) => {
                    const data = typeof value === 'string' ? value.split('|||') : value
                    return (
                      <td key={`td${key}`}>
                        {data[0] || data}
                        <div className='text-sm text-bluegray-400'>{data[1]}</div>
                      </td>
                    )
                  })}
                </tr>
                {theadChild &&
                  tbodyChild &&
                  id &&
                  childIndex.some((ind) => ind === id) &&
                  ChildrenTable(theadChild, tbodyChild.get(id),id)}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan={10000} height={250} className='text-center'>
                No available items
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
