import React from 'react'
import { Gem } from "../../svg/Gem"
import { Necklace } from "../../svg/Necklace"
import { Watch } from "../../svg/Watch"
import { TenderTypes } from "../../types/actions"

export const getCategoryId = (type: TenderTypes) => {
  switch (type) {
    case 'diamond':
      return 1

    case 'jewelry':
      return 2

    case 'watches':
      return 3
  
    default:
      return 1
  }
}

export const getCategoryIcon = (id: number) => {
  switch (id) {
    case 1:
      return <Gem />

    case 2:
      return <Necklace />

    case 3:
      return <Watch />
  
    default:
      return ''
  }
}

export const getCategoryTenderName = (id: number) => {
  switch (id) {
    case 1:
      return 'Diamond Tender'

    case 2:
      return 'Jewelry Tender'

    case 3:
      return 'Watch Tender'
  
    default:
      return ''
  }
}