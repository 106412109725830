import React, { useEffect, useMemo, useState } from 'react'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppStore } from '../../store/applicationState'
import { searchProps, useSearch } from '../../hooks/useSearch'
import { Input } from '../../components/ui/adminComponents/Input'
import { Table } from '../../components/ui/adminComponents/Table'
import { GetAllEmailLogs } from '../../store/admin/settings/emailLogs/actions'
import { getDate } from '../../utils/helpers'
import { EmailLogsDropdown } from '../../components/ui/emailLogsUi/EmailLogsDropdown'
import { usePagination } from '../../hooks/usePagination'
import { Arrow } from '../../svg/Arrow'
import { TEmailLogs } from '../../store/admin/settings/emailLogs/types'
import { LoaderPoints } from '../../components/ui/LoaderPoints'

const eventsData = [
  { id: 1, event_title: 'Opened' },
  { id: 2, event_title: 'Accepted' },
  { id: 3, event_title: 'Sent' },
  { id: 4, event_title: 'Failed' },
]

const emailLogsTheadChild = {
  description: 'Description',
}

const tableCellWidth = [250, 400, 100, 150, 150]

export const EmailLogs = () => {
  const limitAmount = 400

  const emptyField = '-----'

  const location = useLocation()
  const dispatch = useDispatch()
  const { emailLogs } = useSelector((store: AppStore) => store)

  const { search } = queryString.parse(location.search)

  const initialParams = {
    limit: limitAmount, // useless
    offset: 0, // useless
    search: (typeof search === 'string' && search) || undefined,
    isAccepted: false,
    isSent: false,
    isOpened: false,
    isFailed: false,
  }

  const [params, setParams] = useState<searchProps>(initialParams)
  const [dispatchTime, setDispatchTime] = useState(0)
  const [childIndex, setChildIndex] = useState<number[]>([])
  const [selectedEvents, setSelectedEvents] = useState<number[]>([])

  useSearch(limitAmount, params, setParams, setDispatchTime)
  usePagination(emailLogs.data, setParams, limitAmount)

  useEffect(() => {
    const timer = setTimeout(() => {
      setParams((prev) => ({
        ...prev,
        limit: limitAmount,
        offset: 0,
        isAccepted: !!selectedEvents.find((value) => value === 2),
        isSent: !!selectedEvents.find((value) => value === 3),
        isOpened: !!selectedEvents.find((value) => value === 1),
        isFailed: !!selectedEvents.find((value) => value === 4),
      }))
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  }, [selectedEvents])

  useEffect(() => {
    dispatch(
      GetAllEmailLogs.request({
        limit: params.limit,
        offset: params.offset,
        search: params.search || '',
        isAccepted: params.isAccepted,
        isSent: params.isSent,
        isOpened: params.isOpened,
        isFailed: params.isFailed,
      })
    )
  }, [params.offset, params.limit, params.isAccepted, params.isSent, params.isOpened, params.isFailed, dispatchTime])

  const getEmailStatus = (item: TEmailLogs, id: number) => {
    const { failed_code, is_opened, is_accepted, is_sent, failed_description } = item
    if (is_opened) return eventsData[0].event_title
    if (is_sent) return eventsData[2].event_title
    if (failed_code !== 0) {
      const isOpened = childIndex.some((index) => index === id)
      const action = (
        <button
          type='button'
          onClick={() => {
            if (!isOpened) setChildIndex((prev) => prev.filter((index) => index !== id))
          }}
        >
          {eventsData[3].event_title}
          <div className={`inline-block transform transition-transform ${isOpened && 'rotate-180'}`}>
            <Arrow />
          </div>
        </button>
      )

      return failed_description ? action : eventsData[3].event_title
    }
    if (is_accepted) return eventsData[1].event_title

    return emptyField
  }

  const customEventsDropdown = useMemo(
    () => (
      <EmailLogsDropdown
        solidIcon={selectedEvents.length > 0}
        onClear={() => setSelectedEvents([])}
        eventsData={eventsData}
        selectedEvents={selectedEvents}
        setSelectedEvents={(eventId) =>
          setSelectedEvents((prev) =>
            prev.some((item) => item === eventId) ? prev.filter((value) => value !== eventId) : [...prev, eventId]
          )
        }
      />
    ),
    [selectedEvents, params]
  )

  const theadMemorized = useMemo(() => {
    return {
      email_recipient: 'Recipient',
      email_subject: 'Subject',
      email_event: customEventsDropdown,
      email_tender_id: 'Tender',
      email_timestamp: 'Timestamp',
    }
  }, [customEventsDropdown])

  const tbodyMemorized = useMemo(() => {
    return (
      (emailLogs.data &&
        emailLogs.data?.map((item, index) => {
          if (!emailLogs.data) return {}

          const itemSended = item.is_sent && item.sent_at !== '0001-01-01T00:00:00Z'

          return {
            id: item.email_log_recipient_id,
            email_recipient: item.user.email,
            email_subject: item.email_log.subject || emptyField,
            email_event: getEmailStatus(item, index),
            email_tender_id: item.email_log.tender?.sales_cycle || emptyField,
            email_timestamp: getDate(item.failed_code !== 0 && !itemSended ? item.failed_at : item.sent_at),
          }
        })) ||
      null
    )
  }, [emailLogs.data, childIndex])

  const getBidsByItemChildParsed = (value: TEmailLogs) => {
    return [
      {
        description: value.failed_description,
      },
    ]
  }

  const tbodyChildMemorized = useMemo(() => {
    if (!emailLogs.data) return null
    const result = new Map<number, object[]>()
    emailLogs.data.forEach((value) => {
      if (emailLogs.data) result.set(value.email_log_recipient_id, getBidsByItemChildParsed(value))
    })
    return result
  }, [emailLogs.data])

  const emailLogsTable = useMemo(() => {
    return tbodyMemorized ? (
      <Table
        thead={theadMemorized}
        tbody={tbodyMemorized}
        tableCellWidth={tableCellWidth}
        theadChild={emailLogsTheadChild}
        tbodyChild={tbodyChildMemorized}
        // callBack={callBack}
        childIndex={childIndex}
        setChildIndex={setChildIndex}
      />
    ) : null
  }, [tbodyMemorized, tbodyChildMemorized])

  return (
    <>
      <div className='w-full md:w-1/3 lg:w-1/4 mb-6'>
        <Input
          placeholder='Search...'
          value={params.search || ''}
          onChange={(e) => setParams((prev) => ({ ...prev, search: e.target.value }))}
        />
      </div>
      <div className='relative'>
        {emailLogsTable}
        {!!emailLogsTable && emailLogs.loading && (
          <div className='absolute left-0 right-0 -bottom-8 mt-20 flex justify-center'>
            <LoaderPoints className='m-auto' />
          </div>
        )}
      </div>
    </>
  )
}
