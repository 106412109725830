export interface TUser {
  company_name: string
  email: string
  first_name: string
  is_mobile: true
  last_name: string
  phone: string
  preferred_message_method: number
  id: number
}

export interface TUserMessage {
  message: string
}

export interface TUserState {
  id?: number
  companyId?: number
  loading: boolean
  data: TUser | null
  isExecutiveAdmin: boolean
  isAssociateAdmin: boolean
  fullAccessCategories: number[]
  viewOnlyCategories: number[]
  errors?: string | undefined
}

export enum ActionTypes {
  GET_USER_R = '@@user/GET_USER_R',
  GET_USER_S = '@@user/GET_USER_S',
  GET_USER_E = '@@user/GET_USER_E',

  SET_USER_DATA_R = '@@user/SET_USER_DATA_R',
  SET_USER_DATA_S = '@@user/SET_USER_DATA_S',
  SET_USER_DATA_E = '@@user/SET_USER_DATA_E',

  SET_USER_PASSWORD_R = '@@user/SET_USER_PASSWORD_R',
  SET_USER_PASSWORD_S = '@@user/SET_USER_PASSWORD_S',
  SET_USER_PASSWORD_E = '@@user/SET_USER_PASSWORD_E',

  SET_EXTRA_DATA = '@@user/SET_EXTRA_DATA',

  SET_USER_IS_EXECUTIVE_ADMIN = '@@user/SET_USER_IS_EXECUTIVE_ADMIN',
  SET_USER_IS_ASSOCIATE_ADMIN = '@@user/SET_USER_IS_ASSOCIATE_ADMIN',
  SET_FULL_ACCESS_CATEGORIES = '@@user/SET_FULL_ACCESS_CATEGORIES',
  SET_VIEW_ONLY_CATEGORIES = '@@user/SET_VIEW_ONLY_CATEGORIES',
}
