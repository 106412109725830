import React from 'react'
import { Link } from 'react-router-dom'
import { TAcquisitionHistory } from '../../store/dashboard/types'
import { convertToDollars } from '../../utils/convertToDollars'
import { getCategoryIcon } from '../../utils/dashboardUtils/CategoriesHelpers'
import { getAcquisitionHistoryDate } from '../../utils/dashboardUtils/dataFormat'
import { ROUTES } from '../../utils/routes'

interface TenderHistoryProps {
  tender: TAcquisitionHistory;
}

export const TenderHistory: React.FC<TenderHistoryProps> = ({ tender }) => {
  return (
    <>
      {tender?.tender_status === 'draft'
        ? (
          <div className={`tender-${tender.tender_id} tender-history-item border-b border-bluegray-100 mb-6 pb-6`}>
            <div className='flex justify-between lg:flex-row flex-col lg:items-center text-bluegray-700'>
              <div className='lg:flex items-center lg:space-x-12 lg:mb-0 mb-3'>
                <div className='flex space-x-4 items-center'>
                  <div className='icon w-10 text-center text-4xl text-bluegray-200'>
                    {getCategoryIcon(tender.category_id)}
                  </div>
                  
                  <div className='mr-2 w-44'>
                    <div className='title text-badse font-circa font-semibold lg:mr-0 mr-3'>
                      {`Tender ${tender.sales_cycle}`}
                    </div>
                    
                    <div className='date text-xs font-normal'>
                      {getAcquisitionHistoryDate(tender.finalized_at)}
                    </div>
                  </div>
                </div>
                
                <div className='message text-sm font-circa font-semibold mt-4 lg:mt-0'>
                  <i>Tender results pending.</i>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Link to={`${ROUTES.tenderResults}/${tender.tender_id}/my-acquisition`}>
            <div className={`tender-${tender.tender_id} tender-history-item border-b border-bluegray-100 cursor-pointer mb-6 pb-6`}>
              <div className='lg:flex justify-between items-center space-y-4  text-bluegray-700'>
                <div className='flex items-center space-x-4 w-56'>
                  <div className='flex space-x-4 items-center'>
                    <div className='icon w-10 text-center text-4xl text-bluegray-200'>
                      {getCategoryIcon(tender.category_id)}
                    </div>
                    
                    <div className=''>
                      <div className='title text-badse font-circa font-semibold'>
                        {`Tender ${tender.sales_cycle}`}
                      </div>
                      
                      <div className='date text-xs font-normal'>
                        {getAcquisitionHistoryDate(tender.finalized_at)}
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className='stats text-xs font-semibold lg:flex lg:space-x-6 space-y-4 lg:space-y-0 lg:ml-2'>
                  <div
                    className='buy-now flex space-x-2 border border-emerald-200 bg-emerald-100 p-1 rounded-full pr-3 text-emerald-900 lg:mb-0 mr-2 whitespace-nowrap mb-2'
                  >
                    <div className='count rounded-full bg-white px-3 text-bluegray-600 font-semibold'>
                      {tender?.bid_buy_now}
                    </div>
                    
                    <div>
                      Buy Now
                    </div>
                  </div>
                  
                  <div
                    className='winning-bids flex space-x-2 border border-lightblue-200 bg-lightblue-50 p-1 rounded-full pr-3 text-lightblue-900 lg:mb-0 mr-2 whitespace-nowrap mb-2'
                  >
                    <div className='count rounded-full bg-white px-3 text-bluegray-600 font-semibold'>
                      {tender?.bid_win}
                    </div>
                    
                    <div>
                      Winning Bids
                    </div>
                  </div>
                  
                  <div
                    className='unsuccessful-bids flex space-x-2 border border-bluegray-200 bg-bluegray-100 p-1 rounded-full pr-3 text-bluegray-900 lg:mb-0 mr-2 whitespace-nowrap mb-2'
                  >
                    <div className='count rounded-full bg-white px-3 text-bluegray-600 font-semibold'>
                      {tender?.unsuccessful_bids}
                    </div>
                    
                    <div>
                      Unsuccessful Bids
                    </div>
                  </div>
                </div>
                
                <div className='total-spend text-left whitespace-normal mb-2 w-32 lg:ml-2'>
                  <div className='text-black font-semibold'>
                    {`Total Spend: ${convertToDollars(tender?.total_spend)}`}
                  </div>
                </div>
              </div>
            </div>
          </Link>
        )}
    </>
  )
}
