import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as Actions from './actions'
import { callApi } from '../../../../utils/callApi'
import { API_ROUTES } from '../../../../utils/apiRoutes'
import { ActionTypes, TFinalizeTendersMessage, TTenderStatistic } from './types'
import { SnackBar } from '../../../../components/ui/SnackBar'

const { addSnackBar } = SnackBar()

function* setTenderFinalizedWorker(action: ReturnType<typeof Actions.SetTenderFinalized.request>) {
  const { callBack, idTender } = action.payload as Actions.TypeSetTenderFinalizedR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'put',
      path: `${API_ROUTES.adminTenders}/${idTender}/finalize`,
    })) as TFinalizeTendersMessage

    yield put(Actions.SetTenderFinalized.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.SetTenderFinalized.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    const successMessage = 'The tender has been completed. Emails have been sent to the customers.'
    const errorMessage = data?.message || ''
    const snackBarMessage = success ? successMessage : errorMessage
    addSnackBar(snackBarMessage, success)
  }
}

function* updateWinnerByItemIdWorker(action: ReturnType<typeof Actions.UpdateWinnerByItemId.request>) {
  const { callBack, idBid, idItem } = action.payload as Actions.TypeUpdateWinnerByItemIdR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'put',
      path: `${API_ROUTES.adminTenders}/items/${idItem}/bids/${idBid}/set-winner`,
    })) as TFinalizeTendersMessage

    yield put(Actions.UpdateWinnerByItemId.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.UpdateWinnerByItemId.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    const successMessage = 'The selection has been saved.'
    const errorMessage = data?.message || ''
    const snackBarMessage = success ? successMessage : errorMessage
    addSnackBar(snackBarMessage, success)
  }
}

function* getTenderStatisticWorker(action: ReturnType<typeof Actions.GetTenderStatistic.request>) {
  const { callBack, idTender } = action.payload as Actions.GetTenderStatisticR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminTenders}/${idTender}/finalize-stats`,
    })) as TTenderStatistic

    yield put(Actions.GetTenderStatistic.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetTenderStatistic.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (!success) addSnackBar(`${data?.message || data}`, success)
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.SET_TENDER_FINALIZED_R, setTenderFinalizedWorker)
  yield takeEvery(ActionTypes.UPDATE_WINNER_BY_ITEM_ID_R, updateWinnerByItemIdWorker)
  yield takeEvery(ActionTypes.GET_TENDER_STATISTIC_R, getTenderStatisticWorker)
}

export default function* tendersFinalizeSaga() {
  yield all([fork(watchRequest)])
}
