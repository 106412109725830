/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAddItemPopup } from '../../../../hooks/useAddItemPopup'
import { TGalleryItemInfo } from '../../../../store/gallery/types'
import { IconDollar } from '../../../../svg/IconDollar'
import { convertToDollars } from '../../../../utils/convertToDollars'
import { ERROR_TEXT } from '../../../../utils/galleryUtils/consts'
import { addBidAction, deleteBidAction } from '../../../../utils/galleryUtils/itemsActions'

interface BidWidgetSimpleProps {
  item: TGalleryItemInfo
  tenderId: number
  setPurchasePopupActive: (value: boolean) => void
}

export const BidWidgetSimple: React.FC<BidWidgetSimpleProps> = ({ item, setPurchasePopupActive, tenderId }) => {
  const dispatch = useDispatch()
  const { remove } = useAddItemPopup()

  const [bid, setbid] = useState(Math.round(item.bid / 100))
  const [editBidWidget, seEditBidWidget] = useState(!item.bid)
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (!editBidWidget) {
      setbid(Math.round(item.bid / 100))
    }
  }, [item.bid, editBidWidget])

  return (
    <div className='bg-slate-50 lg:w-11/12 p-3 smallMobile:px-6'>
      {!item.bid ? null : (
        <div className='bid-status'>
          <div className='uppercase font-semibold flex mb-2'>Your company bid for this item:</div>
          <div className='text-slate-600 text-lg smallMobile:text-xl font-semibold flex divide-x '>
            {convertToDollars(item.bid)}
          </div>
        </div>
      )}
      {item.bid === 0 || editBidWidget ? (
        <div className='mt-2'>
          <div className='uppercase font-semibold flex mb-2'>
            <div className=''>Enter bid</div>
          </div>
          <div className='flex-col mb-3 items-center '>
            <div className='carat mb-6'>
              <div className='flex justify-between space-x-3 p-3 rounded border-bluegray-200 border items-center bg-white'>
                <div className='leading-none text-bluegray-500 text-2xl'>
                  <IconDollar />
                </div>

                <input
                  type='text'
                  className='asking-price w-full text-base input-plain outline-none'
                  placeholder='Enter Price'
                  value={bid > 0 ? bid : ''}
                  onChange={(e) => {
                    const value = Number(e.target.value.replace(/[^0-9]/g, ''))

                    if (value >= 1) {
                      setbid(value)
                    } else {
                      setbid(0)
                    }
                  }}
                />
              </div>

              <div className='bid-error mt-1'>
                {item.product.asking_price && bid >= item.product.asking_price / 100 ? (
                  <div className='text-red-500 text-sm w-full'>{ERROR_TEXT.bidHigher}</div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className='flex justify-between items-center mt-4'>
        {item.bid && editBidWidget ? (
          <div className='space-x-3 smallMobile:space-x-6 md:space-x-4 lg:space-x-6 desktop:space-x-10'>
            <button
              type='button'
              className='text-slate-500 font-semibold border-b border-slate-300 border-dotted border-opacity-80 pb-1 hover:text-slate-700'
              onClick={() => {
                seEditBidWidget(false)
                setShowError(false)
              }}
            >
              Cancel
            </button>
            <button
              type='button'
              className='delete-bid text-red-500 font-semibold border-b border-slate-300 border-dotted  border-opacity-80  pb-1 hover:text-red-900'
              onClick={() =>
                deleteBidAction(
                  () => remove(),
                  () => setShowError(true),
                  tenderId,
                  item.item_id,
                  dispatch
                )
              }
            >
              Delete Bid
            </button>
          </div>
        ) : (
          <div className='flex-col'>
            {item.product.asking_price ? (
              <button
                type='button'
                className='buy-now text-green-600 font-semibold border-b pb-1 hover:text-green-700 mr-2'
                onClick={() => setPurchasePopupActive(true)}
              >
                {`BUY NOW - ${convertToDollars(item.product.asking_price)}`}
              </button>
            ) : null}
          </div>
        )}

        {item.bid && !editBidWidget ? (
          <button
            type='button'
            className='edit-bid text-sky-700 font-semibold border-b border-slate-300 border-dotted  border-opacity-80  pb-1 hover:text-sky-800 uppercase'
            onClick={() => seEditBidWidget(true)}
          >
            Edit Bid
          </button>
        ) : (
          <button
            type='button'
            className={`${
              !item.bid ? 'place-bid' : 'save-bid'
            } text-xl text-center py-1 px-3 text-white rounded-md w-6/12 cursor-pointer bg-sky-700 hover:bg-sky-600`}
            onClick={() =>
              addBidAction(
                () => remove(),
                () => setShowError(true),
                tenderId,
                item.item_id,
                bid,
                item.bid,
                bid * 100,
                dispatch
              )
            }
            disabled={bid < 1 || bid === item.bid / 100 || bid >= item.product.asking_price / 100}
          >
            {!item.bid ? 'PLACE BID' : 'SAVE BID'}
          </button>
        )}
      </div>
      {showError ? (
        <div className='text-red-500 w-full mt-3 text-lg'>
          You do not have access to bid on CIRCA tenders. Please contact{' '}
          <a href='mailto:tender@circajewels.com' className=' underline hover:text-red-700'>
            tender@circajewels.com
          </a>{' '}
          to become a verified user.
        </div>
      ) : null}
    </div>
  )
}
