import { ActionTypes } from "./types"

export const ToggleAddressForm = (index:number, isExpanded: boolean) => ({
  type: ActionTypes.TOGGLE_ADDRESS_FORM,
  payload: { index, isExpanded }
})

/// ///////////////////////////////////////////////////////////

export const ResetAddressFormsState = () => ({
  type: ActionTypes.RESET_ADDRESS_FORMS_STATE,
})