export interface TTenders {
  category_id: number
  finalized_at: string
  has_items: boolean
  id: number
  preview_start_at: string
  sales_cycle: string
  sales_orders_and_invoices_processing_status: string
  start_at: string
  status: string
}

export interface TTendersMessage {
  message: string
}

export interface TTendersState {
  loading: boolean
  data: TTenders[] | null
  socketActionAmount: number
  updateTenderNow: boolean
  errors?: string | undefined
}

export enum ActionTypes {
  GET_ALL_TENDERS_R = '@@TENDERS/GET_ALL_TENDERS_R',
  GET_ALL_TENDERS_S = '@@TENDERS/GET_ALL_TENDERS_S',
  GET_ALL_TENDERS_E = '@@TENDERS/GET_ALL_TENDERS_E',
  
  SOCKET_ACTION_OCCURRED = '@@TENDERS/SOCKET_ACTION_OCCURRED',
  SOCKET_ACTION_OCCURRED_RESET = '@@TENDERS/SOCKET_ACTION_OCCURRED_RESET',
  UPDATE_TENDER_NOW = '@@TENDERS/UPDATE_TENDER_NOW',

  CHANGE_TENDER_SCHEDULE_R = '@@TENDERS/CHANGE_TENDER_SCHEDULE_R',
  CHANGE_TENDER_SCHEDULE_S = '@@TENDERS/CHANGE_TENDER_SCHEDULE_S',
  CHANGE_TENDER_SCHEDULE_E = '@@TENDERS/CHANGE_TENDER_SCHEDULE_E',

  CHANGE_STATUS_SALES_ORDERS_AND_INVOICES = '@@TENDERS/CHANGE_STATUS_SALES_ORDERS_AND_INVOICES',
}
