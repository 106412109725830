import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppStore } from '../store/applicationState'
import { GetDiamondVideo } from '../store/diamondVideo/actions'
import { DiamondVideoContent } from '../containers/DiamondVideoContent'
import { DiamondLoader } from '../components/ui/DiamondLoader'

export const DiamondVideo = () => {
  const { id } = useParams<{ id?: string }>()
  const [loading, setLoading] = useState(false)
  const { diamondVideo } = useSelector((store: AppStore) => store)
  const dispatch = useDispatch()

  React.useEffect(() => {
    setLoading(true)
    dispatch(
      GetDiamondVideo.request({
        idItem: +(id || 0),
        callBack: () => {
          setLoading(false)
        },
      })
    )
  }, [id])

  if (loading)
    return (
      <div className=' w-screen h-screen flex justify-center items-center'>
        <DiamondLoader />
      </div>
    )

  return !diamondVideo.data || diamondVideo.errors ? (
    <div className='flex p-4 text-base'>{diamondVideo.errors || 'Item not found'}</div>
  ) : (
    <div className='w-full flex justify-center'>
      <DiamondVideoContent item={diamondVideo.data} />
    </div>
  )
}
