/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { P404 } from '../pages'
import { ROUTES } from '../utils/routes'
import { AddUserForm } from './AddUserForm'
import { CreateMessage } from './CreateMessage'
import { EditUserForm } from './EditUserForm'
import { EditCompanyForm } from './EditCompanyForm'
import { Companies } from './UsersContent/Companies'
import { Customers } from './UsersContent/Customers'
import { PersonalRecommendations } from './UsersContent/PersonalRecommendations'
import { AddCompanyForm } from './AddCompanyForm'

interface UsersContentProps {}

export const UsersContent: React.FC<UsersContentProps> = () => {
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  
  return (
    <div className='bg-white pb-10'>
      <div className='flex relative justify-start space-x-10 mb-6 text-base font-semibold overflow-x-auto'>
        <div
          className={`pb-2 cursor-pointer z-10 hover:text-lightblue-700 ${
            location.pathname.search(ROUTES.adminPageUsersCustomers) !== -1 &&
            'border-lightblue-600 border-b-2 text-lightblue-600'
          }`}
          onClick={() => history.push(ROUTES.adminPageUsersCustomers)}
        >
          Customers
        </div>
        <div
          className={`pb-2 cursor-pointer z-10 hover:text-lightblue-700 ${
            location.pathname.search(ROUTES.adminPageUsersCompanies) !== -1 &&
            'border-lightblue-600 border-b-2 text-lightblue-600'
          }`}
          onClick={() => history.push(ROUTES.adminPageUsersCompanies)}
        >
          Companies
        </div>
        <div
          className={`pb-2 cursor-pointer z-10 hover:text-lightblue-700 ${
            location.pathname.search(ROUTES.adminPageUsersPersonalRecommendations) !== -1 &&
            'border-lightblue-600 border-b-2 text-lightblue-600'
          }`}
          onClick={() => history.push(ROUTES.adminPageUsersPersonalRecommendations)}
        >
          Personal Recommendations
        </div>
        <div className='absolute h-px margin-l-r-0 left-0 right-0 bottom-0 bg-bluegray-300' />
      </div>
      <Switch>
        <Route
          exact
          path={[ROUTES.adminPageUsersCustomers, `/worthy${ROUTES.adminPageUsersCustomers}`]}
          component={Customers}
        />
        <Route
          exact
          path={[ROUTES.adminPageUsersCompanies, `/worthy${ROUTES.adminPageUsersCompanies}`]}
          component={Companies}
        />
        <Route
          path={[
            `${ROUTES.adminPageUsersCustomers}/create-user`,
            `/worthy${ROUTES.adminPageUsersCustomers}/create-user`,
          ]}
          component={AddUserForm}
        />
        <Route
          path={[
            `${ROUTES.adminPageUsersCustomers}/edit-user/:id`,
            `/worthy${ROUTES.adminPageUsersCustomers}/edit-user/:id`,
          ]}
          component={EditUserForm}
        />
        <Route
          path={[
            `${ROUTES.adminPageUsersCustomers}/create-message`,
            `/worthy${ROUTES.adminPageUsersCustomers}/create-message`,
          ]}
          component={CreateMessage}
        />
        <Route
          path={[
            `${ROUTES.adminPageUsersCompanies}/edit-company/:id`,
            `/worthy${ROUTES.adminPageUsersCompanies}/edit-company/:id`,
          ]}
          component={EditCompanyForm}
        />
        <Route
          path={[
            `${ROUTES.adminPageUsersCompanies}/create-company`,
            `/worthy${ROUTES.adminPageUsersCompanies}/create-company`,
          ]}
          component={AddCompanyForm}
        />
        <Route
          path={[
            `${ROUTES.adminPageUsersPersonalRecommendations}`,
            `/worthy${ROUTES.adminPageUsersPersonalRecommendations}`,
          ]}
          component={PersonalRecommendations}
        />
        <Route path='*' component={P404} />
      </Switch>
    </div>
  )
}
