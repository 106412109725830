const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export const dataShortFormated = (data: string) => {
  const tenderData = new Date(data)
  return tenderData.toLocaleString('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
    timeZone: 'America/New_York',
  })
}

export const dataFormated = (data: string) => {
  const tenderData = new Date(data)
  return `${tenderData.toLocaleString('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
    timeZone: 'America/New_York',
  })} @ ${tenderData.toLocaleString('en-US', { hour: 'numeric', hour12: true, timeZone: 'America/New_York' }).toLowerCase()}`
}

export const getAcquisitionHistoryDate = ( date: string ) => {
  const nth = (d: number) => {
    if (d > 3 && d < 21) return 'th'
      switch (d % 10) {
          case 1:  return "st"
          case 2:  return "nd"
          case 3:  return "rd"
          default: return "th"
      }
  }
  const dateNew = new Date(date)
  const year = dateNew.getFullYear()
  const month = dateNew.getMonth()
  const day = dateNew.getDate()
  return `${months[month]} ${day}${nth(day)}, ${year}`
}