import React from 'react'
import { CircleSolid } from '../../svg/CircleSolid'
import { Close } from '../../svg/Close'
import { Gem } from '../../svg/Gem'
import { FiltersItem } from './FiltersItem'

interface DiamondFiltersProps {
  showFilters: boolean;
  setShowFilters: (value: boolean) => void;
}

export const DiamondFilters: React.FC<DiamondFiltersProps> = ({ showFilters, setShowFilters }) => {
  return (
    <div className='md:w-64 p-3 bg-white flex-shrink-0 md:sticky top-24 lg:top-16 left-auto h-full z-75 border border-bluegray-100 xl:block mr-10 fixed w-full shadow-sm'>
      <div className='filters-heading flex justify-between items-center pb-1 mb-4 border-b border-bluegray-200'>
        <div className='text-base text-bluegray-800 uppercase font-circa'>
          <button
            type='button'
            className='rounded-md select-none focus:outline-none toggle-filter text-bluegray-400 items-center'
            onClick={() => setShowFilters(!showFilters)}
          >
            <Close styled='w-3' />
          </button>

          <span className='ml-1'>
            filter
          </span>
        </div>
        
        <div className='text-red-400 text-xs uppercase'>
          clear
        </div>
      </div>

      <div className='filter-shape w-full filter mb-6'>
        <div className='filter-type text-xs uppercase text-bluegray-600 mb-3 font-semibold'>
          Shape
        </div>

        <div className='flex flex-col justify-between w-full space-y-3'>
          <div className='flex justify-between w-full'>
            <Gem />
            <Gem />
            <Gem />
            <Gem />
            <Gem />
          </div>

          <div className='flex justify-between w-full'>
            <Gem />
            <Gem />
            <Gem />
            <Gem />
            <Gem />
          </div>
        </div>
      </div>

      <div className='filter-weight w-full filter mb-6'>
        <div className='filter-type text-xs uppercase text-bluegray-600 mb-3 font-semibold'>
          Weight (CT)
        </div>

        <div className='flex justify-between items-center text-bluegray-400'>
          <div className='mr-3'>0</div>
          <CircleSolid />
          <div className='border-t border-4 border-bluegray-300 w-full' />
          <CircleSolid />
          <div className='ml-3'>10</div>
        </div>
      </div>

      <FiltersItem filterName='clarity' filterSubtypes={[ 'FL', 'IF-VVS', 'VS', 'SI', 'I']} />
      <FiltersItem filterName='cut' filterSubtypes={[ 'EX', 'VG', 'G', 'F', 'P']} />
      <FiltersItem filterName='polish' filterSubtypes={[ 'EX', 'VG', 'G', 'F', 'P']} />
      <FiltersItem filterName='symmetry' filterSubtypes={[ 'EX', 'VG', 'G', 'F', 'P']} />
      <FiltersItem filterName='fluorescence' filterSubtypes={[ 'Non', 'FNT', 'Med', 'STG', 'VST']} />
      <FiltersItem filterName='lab' filterSubtypes={[ 'GIA', 'Other']} />
    </div>
  )
}
