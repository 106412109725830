import { TGalleryItemInfo } from '../../store/gallery/types'

export const tenderDataPricesHelper = (data: TGalleryItemInfo[] | null, payload: TGalleryItemInfo[]) => {
  if (data) {
    for (let i = 0; i < data.length; i += 1) {
      const prices = payload.find(x => x.item_id === data[i].item_id)
      if (prices) {
        // eslint-disable-next-line no-param-reassign
        if (prices.product.asking_price) data[i].product.asking_price = prices.product.asking_price
        // eslint-disable-next-line no-param-reassign
        if (prices.product.rap_list_price_discount) data[i].product.rap_list_price_discount = prices.product.rap_list_price_discount
        // eslint-disable-next-line no-param-reassign
        if (prices.product.asking_price_per_carat) data[i].product.asking_price_per_carat = prices.product.asking_price_per_carat
      }
    }
  }

  return data
}
