import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from '../../components/ui/adminComponents/Input'
import { Button } from '../../components/ui/adminComponents/Button'
import { Table } from '../../components/ui/adminComponents/Table'
import { getAllBidsParsed } from '../../utils/tendersUtils/tenderDataHelper'
import { AppStore } from '../../store/applicationState'
import {
  GetAllBidsByTender,
  GetAllBidsByTenderCsv,
  GetBidsSummaryByTenderCsv,
  GetWinningBidsByTenderCsv,
  MakeSalesOrdersAndInvoices,
} from '../../store/admin/tenders/allBidsByTender/actions'
import { downloadFile } from '../../utils/FileUtils'
import { paginationProps, usePagination } from '../../hooks/usePagination'
import { searchProps, useSearch } from '../../hooks/useSearch'
import { TCsv } from '../../store/admin/tenders/allBidsByTender/types'
import { useAddItemPopup } from '../../hooks/useAddItemPopup'
import { TenderStatusType } from '../../utils/tendersUtils/tenderStatusHelper'
import { LoaderPoints } from '../../components/ui/LoaderPoints'
import { changeStatusSalesOrdersAndInvoices } from '../../store/admin/tenders/actions'
import { IconInfo } from '../../svg/IconInfo'
import { GetBuyNowPurchases } from '../../store/admin/tenders/buyNowPurchases/actions'

const allBidsThead = {
  shipping_owner: 'Own',
  stock_number: 'Stock #',
  title: 'Item',
  created_at: 'Bid Date',
  email: 'Customer',
  company_title: 'Company',
  bid: 'Bid',
  sales_target: 'Reserve',
  asking_price: 'BUY NOW',
}

const tableCellWidth = [155, 155, 250, 200, 270, 150, 195, 195, 195]

interface AllBidsProps {
  currentTenderId: number
  tenderStatus: TenderStatusType | null
}

export const AllBids: React.FC<AllBidsProps> = ({ currentTenderId, tenderStatus }) => {
  const limitAmount = 400
  const initialParams = {
    limit: limitAmount,
    offset: 0,
    search: undefined,
    sort: '',
    sortBy: '',
  }

  const dispatch = useDispatch()
  const { allBidsByTender, userData, tenders, buyNowPurchases } = useSelector((store: AppStore) => store)
  const { add } = useAddItemPopup()

  const [childIndex, setChildIndex] = useState<number[]>([])
  const [params, setParams] = useState<searchProps | paginationProps>(initialParams)
  const [dispatchTime, setDispatchTime] = useState(0)
  const [showDropdown, setShowDropdown] = useState(false)

  const didMount = useRef(false)

  usePagination(allBidsByTender.data, setParams, limitAmount)
  useSearch(limitAmount, params, setParams, setDispatchTime)

  useEffect(() => {
    if (didMount.current) {
      setDispatchTime((prev) => prev + 1)
      setParams(initialParams)
    }
    didMount.current = true
  }, [currentTenderId])

  useEffect(() => {
    dispatch(
      GetAllBidsByTender.request({
        idTender: currentTenderId,
        offset: params.offset,
        limit: params.limit,
        search: params.search,
        sortBy: params.sortBy,
        sort: params.sort,
      })
    )
  }, [params.offset, params.limit, params.sortBy, params.sort, dispatchTime])

  useEffect(() => {
    dispatch(
      GetBuyNowPurchases.request({
        idTender: currentTenderId,
        offset: 0,
        limit: 400
      })
    )
  }, [currentTenderId, dispatchTime])


  useEffect(() => {
    if (tenders.updateTenderNow) {
      if (params.offset === 0) setDispatchTime((prev) => prev + 1)
      else setParams((prev) => ({ ...prev, offset: 0, limit: prev.limit + prev.offset }))
    }
  }, [tenders.updateTenderNow])

  const callBack = (sortBy: string) => {
    let value: { sortBy: string; sort: 'ASC' | 'DESC' }
    if (sortBy !== params.sortBy || params.sort === 'DESC') value = { sortBy, sort: 'ASC' }
    else value = { sortBy, sort: 'DESC' }

    setParams((prev) => ({ ...prev, offset: 0, limit: limitAmount, sortBy: value.sortBy, sort: value.sort }))
  }

  const allBidsTable = useMemo(() => {
    return allBidsByTender.data ? (
      <Table
        thead={allBidsThead}
        tbody={getAllBidsParsed(allBidsByTender.data, userData.isExecutiveAdmin, dispatch, add, tenderStatus)}
        callBack={callBack}
        childIndex={childIndex}
        setChildIndex={setChildIndex}
        tableCellWidth={tableCellWidth}
      />
    ) : null
  }, [allBidsByTender.data])

  const downloadALLBidsCsv = () => {
    dispatch(
      GetAllBidsByTenderCsv.request({
        idTender: currentTenderId,
        callBack: (success, data: TCsv) => {
          if (success) {
            downloadFile('text/csv', atob(data.file), data.file_name)
          }
        },
      })
    )
  }
  const downloadBidsSummaryCsv = () => {
    dispatch(
      GetBidsSummaryByTenderCsv.request({
        idTender: currentTenderId,
        callBack: (success, data: TCsv) => {
          if (success) {
            downloadFile('text/csv', atob(data.file), data.file_name)
          }
        },
      })
    )
  }
  const downloadWinningBidsCsv = () => {
    dispatch(
      GetWinningBidsByTenderCsv.request({
        idTender: currentTenderId,
        callBack: (success, data: TCsv) => {
          if (success) {
            downloadFile('text/csv', atob(data.file), data.file_name)
          }
        },
      })
    )
  }

  const generateInvoices = (idTender: number) => {
    dispatch(
      MakeSalesOrdersAndInvoices.request({
        idTender,
        callBack: (success) => {
          if (success) {
            dispatch(
              changeStatusSalesOrdersAndInvoices({
                id: idTender,
                sales_orders_and_invoices_processing_status: 'in_progress',
              })
            )
          }
        },
      })
    )
  }

  const currentTender = tenders.data?.find((tender) => tender.id === currentTenderId)
  const statusInvoices = currentTender?.sales_orders_and_invoices_processing_status || ''

  const allNsErrMsgs = [
    ...(allBidsByTender.data?.map(bid => ({ stock_number: bid.stock_number, ns_err_msg: bid.ns_err_msg, ns_processing_status: bid.ns_processing_status })) || []),
    ...(buyNowPurchases.data?.map(item => ({ stock_number: item.stock_number, ns_err_msg: item.ns_err_msg, ns_processing_status: item.ns_processing_status })) || [])
  ]

  const hasErrorMessages = allNsErrMsgs.some(msg => msg.ns_err_msg !== undefined && msg.ns_processing_status === 'failed' )
  const allMessagesSame = allNsErrMsgs.every(msg => msg.ns_err_msg === allNsErrMsgs[0].ns_err_msg && msg.ns_processing_status === 'failed')
  const filterErrorMessages = allNsErrMsgs.filter(item => item.ns_err_msg && item.ns_processing_status === 'failed')


  return (
    <>
      <div className='flex justify-between lg:items-center mb-4 flex-col lg:flex-row'>
        <div className='w-full md:w-1/3 lg:w-1/4 mb-6 lg:mb-0'>
          <Input
            placeholder='Search...'
            value={params.search || ''}
            onChange={(e) => setParams((prev) => ({ ...prev, search: e.target.value }))}
          />
        </div>
        <div className='space-y-3 smallMobile:space-y-0 smallMobile:space-x-3 flex flex-col smallMobile:flex-row'>
          {tenderStatus === 'completed' && (
            <>
              {statusInvoices === '' && (
                <Button
                  text='Generate Invoices'
                  variant='generateInvoices'
                  onClick={() => generateInvoices(currentTenderId)}
                  disabled={false}
                />
              )}
              {statusInvoices === 'in_progress' && (
                <Button text='Processing' variant='generateInvoices' onClick={() => {}} disabled />
              )}
              {statusInvoices === 'failed' && (
              <div className='flex items-center'>
                <span className='mr-2 text-red-600'>Failed</span>
                {hasErrorMessages && (
                  <div 
                    className='relative mr-5'
                    onMouseEnter={() => setShowDropdown(true)} 
                    onMouseLeave={() => setShowDropdown(false)}
                  >
                    <IconInfo className='w-[17px] !h-[17px] mt-1' />
                    {showDropdown && (
                      <div className='custom-tooltip'>
                        {allMessagesSame ? (
                          <div>{allNsErrMsgs[0].ns_err_msg}</div>
                        ) : (
                          <>
                            {filterErrorMessages.map((item, index) => (
                              <div key={`item.stock_number${index * 2}`}>{item.stock_number}: {item.ns_err_msg}</div>
                            ))}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                )}
                <Button
                  text='Generate Invoices'
                  variant='generateInvoices'
                  onClick={() => generateInvoices(currentTenderId)}
                  disabled={false}
                />
              </div>
              )}
              {statusInvoices === 'completed' && null}
            </>
          )}
          <Button text='All Bids CSV' variant='bidsCSV' onClick={downloadALLBidsCsv} />
          <Button text='Bid Summary CSV' variant='bidsCSV' onClick={downloadBidsSummaryCsv} />
          <Button text='Winning Bids CSV' variant='bidsCSV' onClick={downloadWinningBidsCsv} />
        </div>
      </div>
      <div className='relative'>
        {allBidsTable}
        {!!allBidsTable && allBidsByTender.loading && (
          <div className='absolute left-0 right-0 -bottom-6 mt-20 flex justify-center'>
            <LoaderPoints className='m-auto' />
          </div>
        )}
      </div>
    </>
  )
}
