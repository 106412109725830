export interface TCurrentBids {
  bid: number,
  created_by_user_id: number,
  email_of_customer: string,
  img: string,
  is_buy_now: boolean,
  item_id: number,
  list_price: number,
  title: string,
  type_of_product: number,
  weight: number
}

export interface TCurrentBidsMessage {
  message: string
}

export interface TCurrentBidsState {
  loading: boolean
  data: {
    currentBids: TCurrentBids[] | null
    bidsAmount: number
    buyNowAmount: number
    refreshCurrentBids: number
  }
  errors?: string | undefined
}

export enum ActionTypes {
  GET_CURRENT_BIDS_R = '@@GALLERY/GET_CURRENT_BIDS_R',
  GET_CURRENT_BIDS_S = '@@GALLERY/GET_CURRENT_BIDS_S',
  GET_CURRENT_BIDS_E = '@@GALLERY/GET_CURRENT_BIDS_E',
  
  WS_CURRENT_BIDS = '@@GALLERY/WS_CURRENT_BIDS'
}
