export interface TAllBidsByTender {
  asking_price: number
  asking_price_discount?: number
  asking_price_per_carat?: number
  bid: number
  bid_id: number
  bid_status_id: number
  category_id: number
  company_id: number
  company_title: string
  consignor_name: string
  cost: string
  created_at: string
  email: string
  item_id: number
  list_price: number
  ns_err_msg: string,
  ns_processing_status: string,
  sales_target: number
  sales_target_per_carat?: number
  shipping_owner: string
  stock_number: string
  title: string
  user_id: number
  weight?: number
}

export interface TCsv {
  file: any,
  file_name: string
}

export interface TAllBidsByTenderMessage {
  message: string
}

export interface TAllBidsByTenderState {
  loading: boolean
  data: TAllBidsByTender[] | null
  onlyWinnersData: TAllBidsByTender[] | null
  errors?: string | undefined
}

export interface TMakeSalesOrdersAndInvoices {
  message: string
}

export enum ActionTypes {
  GET_ALL_BIDS_BY_TENDER_R = '@@TENDERS/GET_ALL_BIDS_BY_TENDER_R',
  GET_ALL_BIDS_BY_TENDER_S = '@@TENDERS/GET_ALL_BIDS_BY_TENDER_S',
  GET_ALL_BIDS_BY_TENDER_WITH_0_OFFSET_S = '@@TENDERS/GET_ALL_BIDS_BY_TENDER_WITH_0_OFFSET_S',
  GET_ALL_BIDS_BY_TENDER_E = '@@TENDERS/GET_ALL_BIDS_BY_TENDER_E',
  
  GET_ALL_BIDS_BY_TENDER_WINNERS_R = '@@TENDERS/GET_ALL_BIDS_BY_TENDER_WINNERS_R',
  GET_ALL_BIDS_BY_TENDER_WINNERS_S = '@@TENDERS/GET_ALL_BIDS_BY_TENDER_WINNERS_S',
  GET_ALL_BIDS_BY_TENDER_WINNERS_E = '@@TENDERS/GET_ALL_BIDS_BY_TENDER_WINNERS_E',
  
  GET_ALL_BIDS_BY_TENDER_CSV_R = '@@TENDERS/GET_ALL_BIDS_BY_TENDER_CSV_R',
  GET_ALL_BIDS_BY_TENDER_CSV_S = '@@TENDERS/GET_ALL_BIDS_BY_TENDER_CSV_S',
  GET_ALL_BIDS_BY_TENDER_CSV_E = '@@TENDERS/GET_ALL_BIDS_BY_TENDER_CSV_E',
  
  GET_BIDS_SUMMARY_BY_TENDER_CSV_R = '@@TENDERS/GET_BIDS_SUMMARY_BY_TENDER_CSV_R',
  GET_BIDS_SUMMARY_BY_TENDER_CSV_S = '@@TENDERS/GET_BIDS_SUMMARY_BY_TENDER_CSV_S',
  GET_BIDS_SUMMARY_BY_TENDER_CSV_E = '@@TENDERS/GET_BIDS_SUMMARY_BY_TENDER_CSV_E',
  
  GET_WINNING_BIDS_BY_TENDER_CSV_R = '@@TENDERS/GET_WINNING_BIDS_BY_TENDER_CSV_R',
  GET_WINNING_BIDS_BY_TENDER_CSV_S = '@@TENDERS/GET_WINNING_BIDS_BY_TENDER_CSV_S',
  GET_WINNING_BIDS_BY_TENDER_CSV_E = '@@TENDERS/GET_WINNING_BIDS_BY_TENDER_CSV_E',

  MAKE_SALES_ORDERS_AND_INVOICES_IN_NS_R = '@@TENDERS/MAKE_SALES_ORDERS_AND_INVOICES_IN_NS_R',
  MAKE_SALES_ORDERS_AND_INVOICES_IN_NS_S = '@@TENDERS/MAKE_SALES_ORDERS_AND_INVOICES_IN_NS_S',
  MAKE_SALES_ORDERS_AND_INVOICES_IN_NS_E = '@@TENDERS/MAKE_SALES_ORDERS_AND_INVOICES_IN_NS_E',
}
