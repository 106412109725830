import { Actions } from '../../../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../../../types/actions'


/// ///////////////////////////////////////////////////////////////////////
const GetAllBidsByTenderActions = new Actions('GET_ALL_BIDS_BY_TENDER', ActionTypes)
const GetAllBidsByTenderWith0OffsetActions = new Actions('GET_ALL_BIDS_BY_TENDER_WITH_0_OFFSET', ActionTypes)

export interface TypeGetAllBidsByTenderR extends TDefRequest {
  idTender: number
  offset?: number
  limit?: number
  search?: string
  sortBy?: string
  sort?: string
}

export const GetAllBidsByTender = {
  request: (payload: TypeGetAllBidsByTenderR) => GetAllBidsByTenderActions.request(payload),
  success: (payload: any) => GetAllBidsByTenderActions.success(payload),
  error: (payload: string) => GetAllBidsByTenderActions.error(payload),
}

export const GetAllBidsByTenderWith0Offset = {
  success: (payload: any) => GetAllBidsByTenderWith0OffsetActions.success(payload),
}


/// ///////////////////////////////////////////////////////////////////////
const GetAllBidsByTenderWinnersActions = new Actions('GET_ALL_BIDS_BY_TENDER_WINNERS', ActionTypes)

export interface TypeGetAllBidsByTenderWinnersR extends TDefRequest {
  idTender: number
  offset?: number
  limit?: number
  search?: string
  sortBy?: string
  sort?: string
}

export const GetAllBidsByTenderWinners = {
  request: (payload: TypeGetAllBidsByTenderWinnersR) => GetAllBidsByTenderWinnersActions.request(payload),
  success: (payload: any) => GetAllBidsByTenderWinnersActions.success(payload),
  error: (payload: string) => GetAllBidsByTenderWinnersActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
const GetAllBidsByTenderCsvActions = new Actions('GET_ALL_BIDS_BY_TENDER_CSV', ActionTypes)

export interface TypeGetAllBidsByTenderCsvR extends TDefRequest {
  idTender: number
}

export const GetAllBidsByTenderCsv = {
  request: (payload: TypeGetAllBidsByTenderCsvR) => GetAllBidsByTenderCsvActions.request(payload),
  success: (payload: any) => GetAllBidsByTenderCsvActions.success(payload),
  error: (payload: string) => GetAllBidsByTenderCsvActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
const GetBidsSummaryByTenderCsvActions = new Actions('GET_BIDS_SUMMARY_BY_TENDER_CSV', ActionTypes)

export interface TypeGetBidsSummaryByTenderCsvR extends TDefRequest {
  idTender: number
}

export const GetBidsSummaryByTenderCsv = {
  request: (payload: TypeGetBidsSummaryByTenderCsvR) => GetBidsSummaryByTenderCsvActions.request(payload),
  success: (payload: any) => GetBidsSummaryByTenderCsvActions.success(payload),
  error: (payload: string) => GetBidsSummaryByTenderCsvActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
const GetWinningBidsByTenderCsvActions = new Actions('GET_WINNING_BIDS_BY_TENDER_CSV', ActionTypes)

export interface TypeGetWinningBidsByTenderCsvR extends TDefRequest {
  idTender: number
}

export const GetWinningBidsByTenderCsv = {
  request: (payload: TypeGetWinningBidsByTenderCsvR) => GetWinningBidsByTenderCsvActions.request(payload),
  success: (payload: any) => GetWinningBidsByTenderCsvActions.success(payload),
  error: (payload: string) => GetWinningBidsByTenderCsvActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const MakeSalesOrdersAndInvoicesInNSActions = new Actions('MAKE_SALES_ORDERS_AND_INVOICES_IN_NS', ActionTypes)

export interface TypeMakeSalesOrdersAndInvoicesR extends TDefRequest {
  idTender: number
}

export const MakeSalesOrdersAndInvoices = {
  request: (payload: TypeMakeSalesOrdersAndInvoicesR) => MakeSalesOrdersAndInvoicesInNSActions.request(payload),
  success: (payload: any) => MakeSalesOrdersAndInvoicesInNSActions.success(payload),
  error: (payload: string) => MakeSalesOrdersAndInvoicesInNSActions.error(payload),
}