/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { GetTerms, PostTerms } from '../store/terms/actions'
import { AppStore } from '../store/applicationState'
import { InputChecked } from '../svg/InputChecked'
import { InputNotChecked } from '../svg/InputNotChecked'
import { Spinner } from '../svg/Spinner'
import { ROUTES } from '../utils/routes'
import { ViewAsCustomer } from '../components/ViewAsCustomer'
// @ts-ignore
import termsPath from '../utils/terms/terms.md'
import { useLoginFromWorthy } from '../hooks/useLoginFromWorthy'

const TermsContainer = styled.div`
  padding: 0 10px 20px 10px;
  text-align: justify;
  & > h1 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  & > h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  & > h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  & > p,
  ul,
  ol {
    margin-bottom: 10px;

    & > a {
      text-decoration: underline;
      color: rgba(2, 132, 199, 1);
    }
  }
`

interface TermsProps {}

export const Terms: React.FC<TermsProps> = () => {
  const [userTerms, setUserTerms] = useState('')
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const { signIn, terms } = useSelector((store: AppStore) => store)
  const { checkRedirectToWorthy } = useLoginFromWorthy()

  const [isSubmitted, setIsSubmitted] = useState(false)

  useEffect(() => {
    if (!terms.confirmed) {
      if (signIn.authorized && !terms.data?.terms) {
        fetch(termsPath)
          .then((response) => response.text())
          .then((text) => {
            setUserTerms(text)
          })
      }
      if (location.pathname !== ROUTES.terms) {
        history.push(ROUTES.terms)
      }
    }
  }, [signIn.authorized, terms.data?.terms, terms.confirmed])

  const callBack = (success: boolean) => {
    if (success) {
      history.push(ROUTES.home)
      checkRedirectToWorthy()
    }
  }

  const onSubmit = () => dispatch(PostTerms.request({ callBack }))

  return (
    <>
      <div className='fixed left-0 right-0 top-0'>
        <ViewAsCustomer />
      </div>
      <div className='terms-container mx-auto my-10 max-w-screen-xl'>
        {userTerms && (
          <TermsContainer>
            <ReactMarkdown>{userTerms}</ReactMarkdown>
            <div className='my-4'>
              <label
                htmlFor='checkbox'
                className='text-sm text-gray-600 w-max-content hover:text-button-reports flex items-center cursor-pointer'
              >
                <input type='checkbox' id='checkbox' className='hidden' onClick={() => setIsSubmitted(!isSubmitted)} />
                {isSubmitted ? <InputChecked /> : <InputNotChecked />}
                <span className='ml-3 select-none'>Agree to terms and conditions</span>
              </label>
            </div>
            <div className='my-6 relative'>
              <div>
                <button
                  type='submit'
                  className='form-button'
                  style={terms.loading ? { color: 'transparent' } : {}}
                  disabled={!isSubmitted}
                  onClick={onSubmit}
                >
                  Apply terms
                </button>
                {terms.loading && (
                  <div className='absolute' style={{ top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                    <Spinner />
                  </div>
                )}
              </div>
            </div>
          </TermsContainer>
        )}
      </div>
    </>
  )
}
