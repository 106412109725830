export interface TTendersAuditChild {
  active: boolean
  bid: number
  bid_id: number
  bid_status_id: number
  company_id: number
  company_title: string
  created_at: string
  email: string
  is_deleted: boolean
  item_id: number
  update_by_email: string
  update_by_id: number
  updated_at: string
  user_id: number
}

export interface TTendersAudit {
  asking_discount?: number
  asking_price: number
  asking_price_per_carat?: number
  category_id: number
  companies_ids?: number[]
  count_of_actions: number
  item_id: number
  list_price?: number
  resolved: boolean
  sales_target: number
  sales_target_per_carat?: number
  stock_number: string
  title: string
  weight?: number
}

export interface TTendersAuditMessage {
  message: string
}

export interface TTendersAuditState {
  loading: boolean
  data: TTendersAudit[] | null
  childData: Map<number, TTendersAuditChild[]> | null
  errors?: string | undefined
}

export enum ActionTypes {
  GET_AUDIT_BY_TENDER_R = '@@TENDERS/GET_AUDIT_BY_TENDER_R',
  GET_AUDIT_BY_TENDER_S = '@@TENDERS/GET_AUDIT_BY_TENDER_S',
  GET_AUDIT_BY_TENDER_WITH_0_OFFSET_S = '@@TENDERS/GET_AUDIT_BY_TENDER_WITH_0_OFFSET_S',
  GET_AUDIT_BY_TENDER_E = '@@TENDERS/GET_AUDIT_BY_TENDER_E',
  
  GET_AUDIT_BY_TENDER_CHILD_R = '@@TENDERS/GET_AUDIT_BY_TENDER_CHILD_R',
  GET_AUDIT_BY_TENDER_CHILD_S = '@@TENDERS/GET_AUDIT_BY_TENDER_CHILD_S',
  GET_AUDIT_BY_TENDER_CHILD_WITH_0_OFFSET_S = '@@TENDERS/GET_AUDIT_BY_TENDER_CHILD_WITH_0_OFFSET_S',
  GET_AUDIT_BY_TENDER_CHILD_E = '@@TENDERS/GET_AUDIT_BY_TENDER_CHILD_E',
}
