import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { API_ROUTES } from '../../utils/apiRoutes'
import { callApi } from '../../utils/callApi'
import * as Actions from './actions'
import { ActionTypes, TSignIn, TSignMessage } from './types'
import { TOKEN } from '../../utils/consts'
import { SnackBar } from '../../components/ui/SnackBar'
import { deleteAuthCookie, setCookie } from '../../utils/cookiesUtils'

const { addSnackBar } = SnackBar()

function* postSignInWorker(action: ReturnType<typeof Actions.PostSignIn.request>) {
  const { callBack } = action.payload as Actions.TypePostSignInR
  let success: boolean = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'post',
      path: API_ROUTES.signIn,
      data: action.payload,
    })) as TSignIn | TSignMessage

    // @ts-ignore
    if (data?.access_token && data?.refresh_token) {
      // @ts-ignore
      setCookie(TOKEN.access, data.access_token)
      // @ts-ignore
      setCookie(TOKEN.refresh, data.refresh_token)
    }
    yield put(Actions.PostSignIn.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.PostSignIn.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (!success) addSnackBar(data?.message || '', success)
  }
}

function* postSignOutWorker(action: ReturnType<typeof Actions.PostSignOut.request>) {
  // @ts-ignore
  const callBack = action.payload?.callBack
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'delete',
      path: API_ROUTES.signOut,
    })) as TSignMessage

    localStorage.clear()
    deleteAuthCookie()
    yield put(Actions.PostSignOut.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.PostSignOut.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (!success) addSnackBar(data?.message || '', success)
  }
}

function* postForgotPassWorker(action: ReturnType<typeof Actions.PostForgotPass.request>) {
  const { callBack } = action.payload as Actions.TypePostForgotPassR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'post',
      path: API_ROUTES.forgotPass,
      data: action.payload,
    })) as TSignMessage

    yield put(Actions.PostForgotPass.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.PostForgotPass.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (!success) addSnackBar(data?.message || '', success)
  }
}

function* postSetNewPassWorker(action: ReturnType<typeof Actions.PostSetNewPass.request>) {
  const { callBack, token } = action.payload as Actions.TypePostSetNewPassR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'post',
      path: API_ROUTES.setNewPass,
      data: action.payload,
      token,
    })) as TSignMessage

    yield put(Actions.PostSetNewPass.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.PostSetNewPass.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    const successMessage = 'Your password has been updated.'
    const errorMessage = data?.message || ''
    const snackBarMessage = success ? successMessage : errorMessage
    addSnackBar(snackBarMessage, success)
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.SIGN_IN_R, postSignInWorker)
  yield takeEvery(ActionTypes.SIGN_OUT_R, postSignOutWorker)
  yield takeEvery(ActionTypes.FORGOT_PASS_R, postForgotPassWorker)
  yield takeEvery(ActionTypes.SET_NEW_PASS_R, postSetNewPassWorker)
}

export default function* signInSaga() {
  yield all([fork(watchRequest)])
}
