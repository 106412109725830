import { Actions } from '../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../types/actions'


/// ///////////////////////////////////////////////////////////////////////
const GetUserActions = new Actions('GET_USER', ActionTypes)

export interface TypeGetUser extends TDefRequest {}

export const GetUser = {
  request: (payload?: TypeGetUser) => GetUserActions.request(payload || {}),
  success: (payload: any) => GetUserActions.success(payload),
  error: (payload: string) => GetUserActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
const SetUserDataActions = new Actions('SET_USER_DATA', ActionTypes)

export interface TypeSetUserData extends TDefRequest {
  company_name: string,
  email: string,
  first_name: string,
  is_mobile: boolean,
  last_name: string,
  phone: string,
  preferred_message_method: number
}

export const SetUserData = {
  request: (payload: TypeSetUserData) => SetUserDataActions.request(payload),
  success: (payload: any) => SetUserDataActions.success(payload),
  error: (payload: string) => SetUserDataActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
const SetUserPasswordActions = new Actions('SET_USER_PASSWORD', ActionTypes)

export interface TypeSetUserPassword extends TDefRequest {
  confirm_password: string,
  current_password: string,
  new_password: string,
}

export const SetUserPassword = {
  request: (payload: TypeSetUserPassword) => SetUserPasswordActions.request(payload),
  success: (payload: any) => SetUserPasswordActions.success(payload),
  error: (payload: string) => SetUserPasswordActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
export const SetIsExtraData = (payload: any) => ({ type: ActionTypes.SET_EXTRA_DATA, payload })

export const SetIsExecutiveAdmin = (payload: boolean) => ({ type: ActionTypes.SET_USER_IS_EXECUTIVE_ADMIN, payload })
export const SetIsAssociateAdmin = (payload: boolean) => ({ type: ActionTypes.SET_USER_IS_ASSOCIATE_ADMIN, payload })

export const SetFullAccessCategories = (payload: number[]) => ({ type: ActionTypes.SET_FULL_ACCESS_CATEGORIES, payload })
export const SetViewOnlyCategories = (payload: number[]) => ({ type: ActionTypes.SET_VIEW_ONLY_CATEGORIES, payload })
