import { Reducer } from 'redux'
import { ActionTypes, TBidsByItemState } from './types'
import { getDataForPagination, getMapData } from '../../../../utils/reducerUtils'

export const initialState: TBidsByItemState = {
  loading: false,
  data: null,
  dataOnlyWinners: null,
  childData: null,
  onlyWinnersChildData: null,
  tenderStats: null,
}

const reducer: Reducer<TBidsByItemState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_BIDS_BY_ITEM_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_BIDS_BY_ITEM_S:
      return { ...state, loading: false, errors: undefined, data: getDataForPagination(state.data, action.payload) }
    case ActionTypes.GET_BIDS_BY_ITEM_WITH_0_OFFSET_S:
      return { ...state, loading: false, errors: undefined, data: action.payload }
    case ActionTypes.GET_BIDS_BY_ITEM_E:
      return { ...state, loading: false, errors: action.payload, data: [] }
    
    case ActionTypes.GET_BIDS_BY_ITEM_CHILD_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_BIDS_BY_ITEM_CHILD_S:
      return { ...state, loading: false, errors: undefined, childData: getMapData(state.childData, action.payload) }
    case ActionTypes.GET_BIDS_BY_ITEM_CHILD_E:
      return { ...state, loading: false, errors: action.payload }
    
    case ActionTypes.GET_BIDS_BY_ITEM_FOR_TENDER_CLOSE_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_BIDS_BY_ITEM_FOR_TENDER_CLOSE_S:
      return { ...state, loading: false, errors: undefined, dataOnlyWinners: action.payload }
    case ActionTypes.GET_BIDS_BY_ITEM_FOR_TENDER_CLOSE_E:
      return { ...state, loading: false, errors: action.payload, dataOnlyWinners: [] }
    
    case ActionTypes.GET_BIDS_BY_ITEM_FOR_TENDER_CLOSE_CHILD_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_BIDS_BY_ITEM_FOR_TENDER_CLOSE_CHILD_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        onlyWinnersChildData: getMapData(state.onlyWinnersChildData, action.payload),
      }
    case ActionTypes.GET_BIDS_BY_ITEM_FOR_TENDER_CLOSE_CHILD_E:
      return { ...state, loading: false, errors: action.payload }
    
    case ActionTypes.GET_TENDER_STATS_FOR_BIDS_BY_ITEM_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_TENDER_STATS_FOR_BIDS_BY_ITEM_S:
      return { ...state, loading: false, errors: undefined, tenderStats: action.payload }
    case ActionTypes.GET_TENDER_STATS_FOR_BIDS_BY_ITEM_E:
      return { ...state, loading: false, errors: action.payload, tenderStats: null }
    default:
      return state
  }
}

export { reducer as BidsByItemReducer }