import React from 'react'
import { TTenders } from '../../store/admin/tenders/types'
import { ENVIRONMENT } from '../consts'

export type TenderStatusType = 'active' | 'toBeClosed' | 'completed' | 'catalog' | 'upcoming' | 'unknown'

export const getStatusName = (obj: TTenders): TenderStatusType => {
  const isStarted = new Date(obj.start_at).getTime() < Date.now()
  const isFinalized = new Date(obj.finalized_at).getTime() < Date.now()
  const isPreview = new Date(obj.preview_start_at).getTime() < Date.now()
  if (obj.status === 'draft') {
    if (isStarted) return !isFinalized ? 'active' : 'toBeClosed'
    // eslint-disable-next-line no-else-return
    else return isPreview && obj.has_items ? 'catalog' : 'upcoming'
  }
  return isStarted && isFinalized ? 'completed' : 'unknown'
}

export const getStatus = (obj: TTenders) => {
  const status = getStatusName(obj)
  if (status === 'active') return (<div className='status text-green-500 text-xs font-semibold uppercase'>Active</div>)
  if (status === 'toBeClosed') return (<div className='status text-red-600 text-xs font-semibold uppercase'>To be Closed</div>)
  if (status === 'completed') return (<div className='status text-orange-900 text-xs font-semibold uppercase'>Completed</div>)
  if (status === 'catalog') return (<div className='status text-gray-700 text-xs font-semibold uppercase'>Catalog</div>)
  if (status === 'upcoming') return (<div className='status text-gray-700 text-xs font-semibold uppercase'>Upcoming</div>)
  return (<div className='status text-red-900 text-xs font-semibold uppercase'>Unknown status</div>)
}

export const showAuditTable = (obj: TTenders) => {
  const dateShowAudit = ENVIRONMENT === 'production' ? '2022-07-15T00:00:00' : '2022-02-07T00:00:00'
  return new Date(obj.start_at).getTime() > new Date(dateShowAudit).getTime()
}
