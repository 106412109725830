import React, { useEffect, useState } from 'react'
import { Link, useHistory, Route, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { IconLogo } from './svg/IconLogo'
import { Basket } from '../svg/Basket'
import { UserImage } from '../svg/UserImage'
import { MobileMenuLogo } from '../svg/MobileMenuLogo'
import { TenderTimer } from './ui/headerUi/TenderTimer'
import { MobileMenu } from './ui/headerUi/MobileMenu'
import { Summary } from './ui/headerUi/Summary'
import { PostSignOut } from '../store/auth/actions'
import { ROUTES } from '../utils/routes'
import { AppStore } from '../store/applicationState'
import { ViewAsCustomer } from './ViewAsCustomer'
import { WORTHY_REDIRECT, recMode } from '../utils/consts'
import { getCookie } from '../utils/cookiesUtils'

export const Header = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { userData } = useSelector((store: AppStore) => store)
  const { gallery } = useSelector((store: AppStore) => store)
  const { currentBids } = useSelector((store: AppStore) => store)

  const [mobileActive, setMobileActive] = useState(false)
  const [summaryActive, setSummaryActive] = useState(false)

  const onLogOut = () => dispatch(PostSignOut.request())
  const location = useLocation()

  useEffect(() => {
    const isWorthy = location.pathname.includes('/worthy')
    return () => history.push(isWorthy ? `/worthy${ROUTES.login}` : ROUTES.login)
  }, [])

  return (
    <header
      // also change z-index in GalleryCards
      className='bg-white w-full fixed border-b border-bluegray-200 z-120'
    >
      <div className='mx-auto w-12/12 flex justify-between h-10 items-center px-5 space-x-6d'>
        <div className='logo w-3/12 flex items-center px-3 space-x-2'>
          <Link
            style={recMode ? { pointerEvents: 'none' } : {}}
            to={ROUTES.dashboard}
            className='logo-button uppercase font-circa text-xl font-medium tracking-wider flex items-center'
          >
            <IconLogo />
          </Link>
          {!window.location.pathname.startsWith('/dashboard') ? (
            <Link
              style={recMode ? { pointerEvents: 'none' } : {}}
              to={ROUTES.dashboard}
              className='dashboard-button text-xs uppercase hidden lg:flex text-bluegray-400 hover:text-bluegray-700'
            >
              Dashboard
            </Link>
          ) : null}
        </div>

        <Route path={['/worthy/tenders', '/tenders']}>
          {gallery.data.tenderInfo ? <TenderTimer tender={gallery.data.tenderInfo} /> : null}
        </Route>

        <div className='flex justify-end space-x-5 w-3/12'>
          <Route path={['/worthy/tenders', '/tenders']}>
            <button
              type='button'
              onClick={() => setSummaryActive(true)}
              className='text-sm toggle-summary p-1 md:p-0 md:px-2 border border-bluegray-200 rounded-md flex space-x-2 items-center text-bluegray-600 bg-bluegray-50 hover:bg-bluegray-100 cursor-pointer'
            >
              <Basket />

              <div className='hidden lg:flex space-x-3'>
                <div className='bids'>
                  <span className='bids-count text-lightblue-600 font-semibold mr-1'>
                    {currentBids.data.bidsAmount}
                  </span>
                  Bids
                </div>

                <div className='bids'>
                  <span className='purchases-count text-green-600 font-semibold mr-1'>
                    {currentBids.data.buyNowAmount}
                  </span>
                  Purchases
                </div>
              </div>
            </button>
          </Route>

          <div className='profile-logo text-bluegray-400 group hidden lg:inline-block relative'>
            <UserImage className={recMode ? '!cursor-default' : ''} />
            <ul
              className={`absolute hidden text-bluegray-700 pt-1 bg-white right-0 top-full min-w-max rounded-lg shadow-lg ${
                recMode ? '' : 'group-hover:block'
              }`}
            >
              {(userData.isAssociateAdmin || userData.isExecutiveAdmin) && (
                <span>
                  <li className=''>
                    <span className='whitespace-nowrap text-xs text-bluegray-400 py-2 px-5 pt-5'>Admin Account</span>
                  </li>

                  <li className=''>
                    <Link
                      style={recMode ? { pointerEvents: 'none' } : {}}
                      to={ROUTES.adminPageTender}
                      className='hover:text-lightblue-700 text-base text-menuLink py-1 px-5 block whitespace-nowrap'
                    >
                      Admin Panel
                    </Link>
                  </li>
                </span>
              )}

              <li className=''>
                <Link
                  style={recMode ? { pointerEvents: 'none' } : {}}
                  to={ROUTES.userAccount}
                  className='my-profile-button hover:text-lightblue-700 text-base text-menuLink py-1 px-5 pb-3 block whitespace-nowrap'
                >
                  My profile
                </Link>
              </li>

              {getCookie(WORTHY_REDIRECT) !== 'true' && !window.location.href.includes('worthy_auth_token') && (
                <li className='border-t border-bluegray-200 hover:bg-red-50 rounded-b-lg'>
                  <button
                    disabled={recMode}
                    type='button'
                    className='text-base text-menuLink py-2 px-5 block whitespace-nowrap'
                    onClick={onLogOut}
                  >
                    Logout
                  </button>
                </li>
              )}
            </ul>
          </div>
          <button
            disabled={recMode}
            className='flex cursor-pointer lg:hidden'
            onClick={() => setMobileActive(true)}
            type='button'
          >
            <MobileMenuLogo />
          </button>
        </div>
      </div>
      <ViewAsCustomer />
      <MobileMenu mobileActive={mobileActive} setMobileActive={setMobileActive} onLogOut={onLogOut} />
      <Summary summaryActive={summaryActive} setSummaryActive={setSummaryActive} />
    </header>
  )
}
