/* eslint-disable react/require-default-props */
import React, { CSSProperties } from 'react'
import {
  CATALOG_PUBLISHED_DATE_LENGTH, RESULT_PUBLISHED_LENGTH, TENDER_CLOSING_TIME_LENGTH,
  TENDER_LINK_LENGTH, TENDER_START_TIME_LENGTH,
} from '../../../../utils/consts'

interface VariablesProps {
  styles?: CSSProperties
  height?: string
}

export const Variables: React.FC<VariablesProps> = ({ styles, height }) => {
  return (
    <div className='flex flex-col gap-y-4' style={{ ...styles }}>
      <h1 className='mt-3.5 font-semibold'>Available variable:</h1>
      <div
        style={{ height }}
        className='border overflow-auto border-bluegray-200 flex lgh-90 flex-col gap-y-3 rounded bg-white p-2 text-black text-sm'
      >
        <div
          style={{ background: '#F8FAFC' }}
          className='p-2'
        >
          {`{{.CatalogPublishedDate}} = “Monday, January 2” (up to ${CATALOG_PUBLISHED_DATE_LENGTH} characters)`}
        </div>
        <div
          style={{ background: '#F8FAFC' }}
          className='p-2'
        >
          {`{{.TenderStartTime}} = “Monday, January 2 at 03:04pm MST” (up to ${TENDER_START_TIME_LENGTH} characters)`}
        </div>
        <div
          style={{ background: '#F8FAFC' }}
          className='p-2'
        >
          {`{{.TenderClosingTime}} = “Monday, January 2 at 03:04pm MST” (up to ${TENDER_CLOSING_TIME_LENGTH} characters)`}
        </div>
        <div
          style={{ background: '#F8FAFC' }}
          className='p-2'
        >
          {`{{.TenderLink}} (up to ${TENDER_LINK_LENGTH} characters)`}
        </div>
        <div
          style={{ background: '#F8FAFC' }}
          className='p-2'
        >
          {`{{.ResultsPublished}} = ”Monday, January 2” (up to ${RESULT_PUBLISHED_LENGTH} characters)`}
        </div>
      </div>
    </div>
  )
}