export interface TAdminNSCompanies {
    address_book_list: {
        address_book_list: TNSCompaniesAddress[];
    };
    company_name: string
    created_at: string
    custom_form: string
    default_address: string
    email: string
    email_transactions: boolean
    entity_status: string
    foreign_or_domestic: string
    ns_internal_id: string
    phone: string
    print_transactions: boolean
    subsidiary: string
    sync: boolean
    sync_error: string
    terms: string
    updated_at: string
}

export interface TNSCompaniesAddress {
    addr1: string
    addr2: string
    addr_phone: string
    addressee: string
    attention: string
    city: string
    country: string
    default_billing: boolean
    default_shipping: boolean
    state: string
    zip: string
}

export interface TAdminNSCompany{
    address_book_list: {
        address_book_list: TNSCompaniesAddress[];
    };
    company_name: string
    created_at: string
    custom_form: string
    default_address: string
    email: string
    email_transactions: boolean
    entity_status: string
    foreign_or_domestic: string
    ns_internal_id: string
    phone: string
    print_transactions: boolean
    subsidiary: string
    sync: boolean
    sync_error: string
    terms: string
    updated_at: string
} 

// /////////////////////////////// COUNTRY  ///////////////////
export interface TAdminNSCountry {
    code: string
    countryID: number
    createdAt: string
    name: string
    schemaEnumeration: string
    states: TAdminNSState[]
  }
  export interface TAdminNSState {
    code: string
    countryID: number
    createdAt: string
    name: string
    stateID: number
  }
  // /////////////////////////////// State ///////////////////
export interface TAdminNSCompaniesState {
    loading: boolean
    data: TAdminNSCompanies[] | null
    companyNSForData: TAdminNSCompany | null
    countries: TAdminNSCountry[] | null
    errors: string | undefined
}

  
export enum ActionTypes {
    GET_All_NS_COMPANIES_R = '@@admin/GET_All_NS_COMPANIES_R',
    GET_All_NS_COMPANIES_S = '@@admin/GET_All_NS_COMPANIES_S',
    GET_All_NS_COMPANIES_E = '@@admin/GET_All_NS_COMPANIES_E',

    GET_NS_COMPANY_BY_ID_R = '@@admin/GET_NS_COMPANY_BY_ID_R',
    GET_NS_COMPANY_BY_ID_S = '@@admin/GET_NS_COMPANY_BY_ID_S',
    GET_NS_COMPANY_BY_ID_E = '@@admin/GET_NS_COMPANY_BY_ID_E',

    CLEAR_NS_COMPANY_BY_ID = '@@COMPANIES/CLEAR_NS_COMPANY_BY_ID',

    CREATE_NEW_NS_COMPANY_R = '@@admin/CREATE_NEW_NS_COMPANY_R',
    CREATE_NEW_NS_COMPANY_S = '@@admin/CREATE_NEW_NS_COMPANY_S',
    CREATE_NEW_NS_COMPANY_E = '@@admin/CREATE_NEW_NS_COMPANY_E',

    UPDATE_NS_COMPANY_INFO_R = '@@admin/UPDATE_NS_COMPANY_INFO_R',
    UPDATE_NS_COMPANY_INFO_S = '@@admin/UPDATE_NS_COMPANY_INFO_S',
    UPDATE_NS_COMPANY_INFO_E = '@@admin/UPDATE_NS_COMPANY_INFO_E',

    GET_NS_COUNTRIES_R = '@@admin/GET_NS_COUNTRIES_R',
    GET_NS_COUNTRIES_S = '@@admin/GET_NS_COUNTRIES_S',
    GET_NS_COUNTRIES_E = '@@admin/GET_NS_COUNTRIES_E',

}
  