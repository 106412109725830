import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { SnackBar } from '../components/ui/SnackBar'
import { BidResults, MyAcquisition } from '../containers/TenderResults'
import { AppStore } from '../store/applicationState'
import { GetBidsResult, GetBidsWon, GetPurchaseHistory, GetTenderInfo } from '../store/tenderResults/actions'
import { DownloadDark } from '../svg/DownloadDark'
import { Spinner } from '../svg/Spinner'
import { API_ROUTES } from '../utils/apiRoutes'
import { callApi } from '../utils/callApi'
import { getCategoryTenderName } from '../utils/dashboardUtils/CategoriesHelpers'
import { ROUTES } from '../utils/routes'

export const TenderResults = () => {
  const history = useHistory()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [loadingXls, setLoadingXls] = useState(false)

  const { tenderResults, userData } = useSelector((store: AppStore) => store)
  const { addSnackBar } = SnackBar()
  const dispatch = useDispatch()

  const idTender = Number(location.pathname.split('/').find((elem) => Number(elem)))
  useEffect(() => {
    if (location.pathname === `${ROUTES.tenderResults}/${idTender}`) {
      history.replace(`${ROUTES.tenderResults}/${idTender}/my-acquisition`)
    }
    setLoading(true)
    dispatch(GetTenderInfo.request({ idTender, callBack: () => setLoading(false) }))

    return () => {
      dispatch(GetBidsResult.success([]))
      dispatch(GetBidsWon.success([]))
      dispatch(GetPurchaseHistory.success([]))
      dispatch(GetTenderInfo.success([]))
    }
  }, [])

  const getFinalizedDate = () => {
    if (tenderResults.data.tenderInfo?.finalized_at) {
      return new Date(tenderResults.data.tenderInfo?.finalized_at).toLocaleString('en-US', {
        hour12: true,
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        timeZone: 'America/New_York',
      })
    }

    return ''
  }

  const tenderTitle = () => {
    if (loading) {
      return 'Tender'
    }

    if (tenderResults.data.tenderInfo?.category_id) {
      return `${getCategoryTenderName(
        tenderResults.data.tenderInfo?.category_id
      )} ${tenderResults.data.tenderInfo?.sales_cycle.toUpperCase()}`
    }

    return 'Tender not found'
  }

  const downloadAllAcquisition = async () => {
    setLoadingXls(true)
    let success = true
    let data = null
    try {
      data = await callApi({
        method: 'get',
        path: `${API_ROUTES.tendersDashboard}/download-all-acquisitions-xls?idTender=${idTender}`,
        isBlob: true,
      })
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      const fileName = `Circa_Tender_${userData.data?.company_name}_${tenderResults.data.tenderInfo?.sales_cycle}_Purchases.xlsx`
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    } catch (e: any) {
      success = false
      data = JSON.parse(await e?.data.text()) || e
    } finally {
      if (!success) addSnackBar(data?.message || '', success)
      setLoadingXls(false)
    }
  }

  return (
    <div className='tender-results px-3 smallMobile:px-6 pb-16 text-sm lg:pt-9 smallMobile:pt-16 pt-20'>
      <div className='md:flex justify-between'>
        <h1 className='tender-title font-circa text-xl py-5'>
          {tenderTitle()}
          <span className='ml-3 text-slate-500'>{getFinalizedDate()}</span>
        </h1>

        {tenderResults.data.tenderInfo?.category_id === 1 ? (
          <div className='p-2 flex justify-center text-xs'>
            <div className='bg-gray-50 py-2 px-4 flex w-full md:w-auto'>
              <button
                type='button'
                className=' box-border bg-white p-1 rounded-md my-auto border border-gray-200 relative active:border-gray-400 focus:outline-none w-full'
                onClick={downloadAllAcquisition}
                disabled={loading || loadingXls || !userData.data?.company_name}
              >
                {loadingXls ? (
                  <div className='absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-10 bg-opacity-10 bg-gray-900'>
                    <Spinner style={{ color: 'black', width: '10px', height: '10px' }} />
                  </div>
                ) : null}

                <span className='mr-2'>
                  <DownloadDark />
                </span>
                <span>{`Download Tender ${tenderResults.data.tenderInfo?.sales_cycle} Acquisitions (XLS)`}</span>
              </button>
            </div>
          </div>
        ) : null}
      </div>

      <div className='bg-white shadow-xl'>
        <div className='font-semibold text-sm 2smallest:text-base smallMobile:text-lg text-bluegray-400 flex justify-start space-x-2 smallest:space-x-8 smallMobile:space-x-12 shadow-md'>
          <button
            type='button'
            className={`my-acquisition px-8 py-6 cursor-pointer ${
              location.pathname.search(`/my-acquisition`) !== -1
                ? 'border-b-2 border-lightblue-600 text-lightblue-600'
                : ''
            }`}
            onClick={() => {
              history.push(`${ROUTES.tenderResults}/${idTender}/my-acquisition`)
            }}
          >
            My Acquisitions
          </button>

          <button
            type='button'
            className={`bid-results px-8 py-6 cursor-pointer ${
              location.pathname.search(`/bid-results`) !== -1
                ? 'border-b-2 border-lightblue-600 text-lightblue-600'
                : ''
            }`}
            onClick={() => {
              history.push(`${ROUTES.tenderResults}/${idTender}/bid-results`)
            }}
          >
            Bid Results
          </button>
        </div>

        <div>
          <Switch>
            <Route
              path={[
                `${ROUTES.tenderResults}/${idTender}/my-acquisition`,
                `/worthy${ROUTES.tenderResults}/${idTender}/my-acquisition`,
              ]}
              component={MyAcquisition}
            />
            <Route
              path={[
                `${ROUTES.tenderResults}/${idTender}/bid-results`,
                `/worthy${ROUTES.tenderResults}/${idTender}/bid-results`,
              ]}
              component={BidResults}
            />
          </Switch>
        </div>
      </div>
    </div>
  )
}
