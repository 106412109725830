/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo } from 'react'
import { Link, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../components/ui/adminComponents/Button'
import { DropDownBtn } from '../components/ui/adminComponents/DropDownBtn'
import { TendersContent } from '../containers/TendersContent'
import { UsersContent } from '../containers/UsersContent'
import { SettingContent } from '../containers/SettingContent'
import { ROUTES } from '../utils/routes'
// import { AddUserForm } from '../containers/AddUserForm'
import { AppStore } from '../store/applicationState'
import { GetUser } from '../store/user/actions'
// import { EditUserForm } from '../containers/EditUserForm'
// import { CreateMessage } from '../containers/CreateMessage'
import { GetAllTenders } from '../store/admin/tenders/actions'
import { getSolidForCategories } from '../utils/userUtils/IconHelpers'
import { RealTimeUpdateControl } from '../components/ui/adminComponents/RealTimeUpdateControl'
import { P404 } from './P404'
import { getStatusName } from '../utils/tendersUtils/tenderStatusHelper'
import { UpcomingTenders } from '../containers/UpcomingTenders'
import { ENVIRONMENT } from '../utils/consts'
import { TendersSchedule } from '../containers/TendersSchedule'

export const AdminPage = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { userData, tenders } = useSelector((store: AppStore) => store)

  const tendersForLine = useMemo(
    () => tenders.data?.filter((tender) => ['catalog', 'active'].includes(getStatusName(tender))),
    [tenders.data]
  )

  useEffect(() => {
    if (location.pathname === '/admin' || location.pathname === '/admin/') history.replace('/admin/tender')
    dispatch(GetUser.request())
    if (!location.pathname.includes('tenders-schedule')) dispatch(GetAllTenders.request())
  }, [])

  useEffect(() => {
    let timer: any
    if (tenders.data) {
      const time = tenders.data.reduce((previousValue, currentValue) => {
        const status = getStatusName(currentValue)
        const startedTime = new Date(currentValue.start_at).getTime() - Date.now()
        const finalizedTime = new Date(currentValue.finalized_at).getTime() - Date.now()
        const previewTime = new Date(currentValue.preview_start_at).getTime() - Date.now()

        if (status === 'catalog' && startedTime > 0 && startedTime < previousValue) return startedTime
        if (status === 'upcoming' && previewTime > 0 && previewTime < previousValue) return previewTime
        if (status === 'active' && finalizedTime > 0 && finalizedTime < previousValue) return finalizedTime

        return previousValue
      }, 2 ** 31 - 1)

      timer = setTimeout(() => {
        dispatch(GetAllTenders.request())
      }, time)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [tenders.data])

  return (
    <div
      style={{ minHeight: '140vh' }}
      className='max-w-screen-4xl mx-auto px-4 mobile:px-8 py-12 text-base text-bluegray-700'
    >
      <div className='flex flex-col lg:flex-row gap-y-3 justify-between lg:items-center my-4'>
        <div className='text-xl font-circa flex items-center space-x-2 leading-none'>
          <div className='leading-none font-semibold'>
            Welcome,
            {` ${userData.data?.first_name || ''}`}
          </div>
          <div className='text-sm text-bluegray-400 leading-none whitespace-nowrap'>
            {userData.isExecutiveAdmin ? '(Executive Admin)' : '(Associated Admin)'}
          </div>
        </div>
        <div
          style={{ maxWidth: 1200 }}
          className='flex lg-w-65 lg:ml-2 gap-x-6 gap-y-2 text-xs justify-end lg:items-center flex-col lg:flex-row'
        >
          {!!tendersForLine?.length && (
            <>
              <div className='uppercase text-black font-semibold whitespace-nowrap'>current tenders</div>
              <div className='current-tenders flex gap-x-3 overflow-auto'>
                {tendersForLine.map((tender) =>
                  getStatusName(tender) === 'catalog' ? (
                    <Link to={`${ROUTES.tenders}/${tender.id}`} key={tender.id}>
                      <Button
                        variant='tenderCatalog'
                        image={getSolidForCategories(tender.category_id)}
                        text={`${tender.sales_cycle} (Catalog)`}
                      />
                    </Link>
                  ) : (
                    <Link to={`${ROUTES.tenders}/${tender.id}`} key={tender.id}>
                      <Button
                        variant='tenderActive'
                        image={getSolidForCategories(tender.category_id)}
                        text={`${tender.sales_cycle} (Active)`}
                      />
                    </Link>
                  )
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className='bg-white'>
        <div className='flex justify-start space-x-12 mb-6 text-base bg-bluegray-200 tab-group overflow-x-auto pt-2 pb-0 px-4'>
          <div
            className={`tenders tab ${location.pathname.search(`${ROUTES.adminPageTender}`) !== -1 && 'active'}`}
            onClick={() => {
              dispatch(GetAllTenders.request())
              history.push(`${ROUTES.adminPageTender}`)
            }}
          >
            Tenders
          </div>
          <div
            className={`users tab ${location.pathname.search(`${ROUTES.adminPageUsers}`) !== -1 && 'active'}`}
            onClick={() => {
              dispatch(GetAllTenders.request())
              history.push(`${ROUTES.adminPageUsersCustomers}`)
            }}
          >
            Users
          </div>
          <div
            className={`settings tab ${location.pathname.search(`${ROUTES.adminPageSettings}`) !== -1 && 'active'}`}
            onClick={() => {
              dispatch(GetAllTenders.request())
              history.push(`${ROUTES.adminPageSettingsMappingDiamonds}/shape`)
            }}
          >
            Settings
          </div>
        </div>
        <div className='tenders-section px-2 smallMobile:px-5'>
          <Switch>
            <Route
              path={[`${ROUTES.adminPageUpcomingTenders}`, `/worthy${ROUTES.adminPageUpcomingTenders}`]}
              component={UpcomingTenders}
            />
            <Route
              path={[`${ROUTES.adminPageTendersSchedule}`, `/worthy${ROUTES.adminPageTendersSchedule}`]}
              component={TendersSchedule}
            />
            <Route path={[`${ROUTES.adminPageTender}/:id?`, `/worthy${ROUTES.adminPageTender}/:id?`]}>
              <div className='mb-8 flex gap-5 justify-between flex-wrap'>
                <DropDownBtn />
                {userData.isExecutiveAdmin && (
                  <button
                    type='button'
                    onClick={() => {
                      history.push({ pathname: `${ROUTES.adminPageUpcomingTenders}`, state: { fromTenders: true } })
                    }}
                    className='upcoming-tenders text-white px-6 py-2 bg-lightblue-600 rounded-full h-max hover:bg-lightblue-700'
                  >
                    Upcoming Tenders
                  </button>
                )}
                {ENVIRONMENT !== 'production' ? (
                  <button
                    type='button'
                    onClick={() => {
                      history.push({ pathname: `${ROUTES.adminPageTendersSchedule}`, state: { fromTenders: true } })
                    }}
                    className='upcoming-tenders text-white px-6 py-2 bg-lightblue-600 rounded-full h-max hover:bg-lightblue-700'
                  >
                    Tenders schedule
                  </button>
                ) : null}
                <RealTimeUpdateControl />
              </div>
              <TendersContent />
            </Route>
            <Route path={[`${ROUTES.adminPageUsers}`, `/worthy${ROUTES.adminPageUsers}`]} component={UsersContent} />
            <Route
              path={[`${ROUTES.adminPageSettings}`, `/worthy${ROUTES.adminPageSettings}`]}
              component={SettingContent}
            />
            <Route path='*' component={P404} />
          </Switch>
        </div>
      </div>
    </div>
  )
}
