import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { DiamondTable } from '../../components/ui/galleryUi/DiamondTable'
import { AppStore } from '../../store/applicationState'
import { IconClose } from '../../svg/IconClose'
import { IconFilter } from '../../svg/IconFilter'
import { IconLikeGallery } from '../../svg/IconLikeGallery'
import { IconListSolid } from '../../svg/IconListSolid'
import { IconThLarge } from '../../svg/IconThLarge'
import { addNewParams } from '../../utils/helpers'
import { recMode } from '../../utils/consts'
import { IconStar } from '../../svg/IconStar'

interface GalleryListProps {
  setGalleryView: (value: string) => void
  isCatalog: boolean
  sort: string
  sortBy: string
  showMyActivity: boolean
  showAvailable: boolean
  setsearch: (value: string) => void
  setShowMyActivity: (value: boolean) => void
  setShowAvailable: (value: boolean) => void
  setSort: (value: string) => void
  setSortBy: (value: string) => void
  favorites: boolean
  setFavorites: (value: boolean) => void
  subtitleCount: any
  recommendations: boolean
  setRecommendations: (value: boolean) => void
}

export const GalleryList: React.FC<GalleryListProps> = ({
  isCatalog,
  setsearch,
  sort,
  sortBy,
  showMyActivity,
  showAvailable,
  setShowAvailable,
  setShowMyActivity,
  setSort,
  setSortBy,
  favorites,
  setFavorites,
  subtitleCount,
  setGalleryView,
  recommendations,
  setRecommendations,
}) => {
  const recommendParam = new URLSearchParams(window.location.search).get('recommendations')

  const location = useLocation()
  const { gallery } = useSelector((store: AppStore) => store)

  const [searchQuery, setSearchQuery] = useState('')

  const items = gallery.data.tenderItems || []

  useEffect(() => {
    if (new URLSearchParams(window.location.search).get('search')) {
      setSearchQuery(new URLSearchParams(window.location.search).get('search') || '')
    } else {
      setSearchQuery('')
    }
  }, [location])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setsearch(searchQuery)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchQuery])

  return (
    <div className='flex-grow'>
      <div className=' flex-col-reverse z-100 flex lg:flex-row lg:space-y-0 justify-between w-12/12 px-6 items-center py-2 bg-bluegray-50 border-b border-bluegray-200 border-opacity-75 sticky top-10'>
        <div className='flex items-center justify-between space-x-10'>
          <div className='available-items-counter text-sm lg:text-sm text-bluegray-500 font-circa uppercase font-semibold text-center lg:text-right whitespace-nowrap '>
            {subtitleCount()}
          </div>

          <button
            type='button'
            onClick={() => {
              setFavorites(!favorites)
              addNewParams('favorites', `${!favorites}`)
            }}
            className={`favorites-filter rounded-md select-none focus:outline-none px-4 py-2 border border-bluegray-100  text-sm p-0 hover:bg-bluegray-100 ${
              favorites && 'bg-bluegray-100'
            }`}
          >
            <span className='text-red-400'>
              <IconLikeGallery isActive={false} bgColor='text-red-400' />
            </span>
          </button>
          {(gallery.data.atLeastOneRecommended || recommendParam || recMode) && (
            <button
              type='button'
              onClick={() => {
                setRecommendations(!recommendations)
                addNewParams('recommendations', `${!recommendations}`)
              }}
              className={`recommendations-filter rounded-md select-none focus:outline-none px-4 py-2 border border-bluegray-100 text-sm p-0 hover:bg-bluegray-100 ${
                recommendations && 'bg-bluegray-100'
              }`}
            >
              <span className='text-yellow-400'>
                <IconStar isActive />
              </span>
            </button>
          )}
          <button
            type='button'
            onClick={() => {
              setGalleryView('gallery')
              addNewParams('view', 'gallery')
              setShowMyActivity(false)
              setSort('DESC')
              setSortBy('weight')
              setShowAvailable(false)
            }}
            className='view-switcher rounded-md select-none focus:outline-none px-4 py-2 border border-bluegray-100  hidden md:flex hover:bg-bluegray-100'
          >
            <span className='mr-2 text-sky-600'>
              <IconThLarge />
            </span>
            <span className='text-sky-600'>
              <IconListSolid />
            </span>
          </button>
        </div>

        <div className='flex space-x-6 py-2 lg:py-0 w-full justify-center  lg:w-6/12'>
          <button
            type='button'
            disabled={isCatalog || (!items.some((item) => [1, 7, 8].includes(item.bid_status_id)) && !showMyActivity)}
            onClick={() => setShowMyActivity(!showMyActivity)}
            className={`show-my-activity rounded-md flex justify-center select-none focus:outline-none text-xs px-2 py-1 hover:bg-opacity-80 bg-opacity-50 text-bluegray-700 whitespace-nowrap w-full lg:w-auto ${
              !showMyActivity ? 'bg-bluegray-200' : 'bg-cyan-200'
            }`}
          >
            <span className='text flex'>
              <span className='mr-2'>
                <IconFilter styled='text-bluegray-700 opacity-50' />
              </span>
              Show My Activity
            </span>
          </button>

          <button
            type='button'
            disabled={isCatalog || (!items.some((item) => item.is_sold && item.bid_status_id !== 8) && !showAvailable)}
            onClick={() => setShowAvailable(!showAvailable)}
            className={`hide-unavailable rounded-md flex justify-center select-none focus:outline-none text-xs px-2 py-1 hover:bg-opacity-80 bg-opacity-50 text-bluegray-700 whitespace-nowrap w-full lg:w-auto ${
              !showAvailable ? 'bg-bluegray-200' : 'bg-orange-200'
            }`}
          >
            <span className='text flex'>
              <span className='mr-2 text-bluegray-700'>
                <IconClose />
              </span>
              Hide Unavailable
            </span>
          </button>
        </div>

        <div className='flex items-center justify-end lg:w-3/12 w-full py-2 lg:py-0'>
          <div className='flex items-center justify-end space-x-2 w-full'>
            <div className='uppercase text-bluegray-500 w-auto block'>Filter</div>
            <input
              type='text'
              value={searchQuery}
              onChange={(event) => {
                setSearchQuery(event.target.value)
                addNewParams('search', event.target.value)
              }}
              placeholder='Input shape, weight, color, or clarity'
              className='search-input border border-bluegray-200 rounded p-1 leading-6 text-xs pr-8 ring-0 outline-none focus:ring-0 focus:outline-none ring-transparent focus:border-bluegray-300 w-full lg:w-56'
            />
          </div>
        </div>
      </div>

      <div className='w-screen max-w-full overflow-x-auto flex'>
        <DiamondTable
          sortParams={sort}
          sortByParams={sortBy}
          setSort={setSort}
          setSortBy={setSortBy}
          isCatalog={isCatalog}
          favorites={favorites}
          setFavorites={setFavorites}
        />
      </div>
    </div>
  )
}
