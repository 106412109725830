import React from 'react'

interface FiltersItemProps {
  filterName: string;
  filterSubtypes: string[];
}

export const FiltersItem: React.FC<FiltersItemProps> = ({ filterName, filterSubtypes }) => {
  return (
    <div className='filter-item mb-6'>
      <div className='flex justify-between items-center mb-1'>
        <div className='filter-type text-xs uppercase text-bluegray-600 font-semibold'>
          {filterName}
        </div>


        <div className='text-xs text-bluegray-400'>
          Toggle All
        </div>
      </div>

      <div className='flex justify-around divide-bluegray-300 divide-x border border-bluegray-300 xrounded-md cursor-pointer '>
        {filterSubtypes.map((x, i) => {
          return (
            <div key={x} className={`feature text-center flex-grow ${i === 3 ? 'bg-lightblue-100' : ''}`}>
              {x}
            </div>
          )
        })}
      </div>
    </div>
  )
}
