import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { API_ROUTES } from '../../../utils/apiRoutes'
import { callApi } from '../../../utils/callApi'
import * as Actions from './actions'
import { ActionTypes, TCurrentBids } from './types'


function* getGetCurrentBidsWorker(action: ReturnType<typeof Actions.GetCurrentBids.request>) {
  const { callBack, idTender } = action.payload as Actions.TypeGetCurrentBidsR
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.tenders}/${idTender}/current-bids`,
    })) as TCurrentBids
    
    yield put(Actions.GetCurrentBids.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetCurrentBids.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.GET_CURRENT_BIDS_R, getGetCurrentBidsWorker)
}

export default function* currentBidsSaga() {
  yield all([fork(watchRequest)])
}