import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { GetIcs } from '../../store/dashboard/actions'
import { TTenderSchedule } from '../../store/dashboard/types'
import { CalenderDay } from '../../svg/CalenderDay'
import { Sparkles } from '../../svg/Sparkles'
import { dataFormated, dataShortFormated } from '../../utils/dashboardUtils/dataFormat'
import { GetTime } from '../../utils/getTime'
import { downloadFile } from '../../utils/FileUtils'
import { ROUTES } from '../../utils/routes'
import { setTimeout_1 } from '../../utils/helpers'
import { IconFileXLSX } from '../../svg/IconFileXLSX'
import { callApi } from '../../utils/callApi'
import { API_ROUTES } from '../../utils/apiRoutes'
import { SnackBar } from '../../components/ui/SnackBar'
import { Spinner } from '../../svg/Spinner'
import { AppStore } from '../../store/applicationState'
import { getCategoryIcon, getCategoryTenderName } from '../../utils/dashboardUtils/CategoriesHelpers'

interface TenderInfoProps {
  tender: TTenderSchedule
}

export const TenderInfo: React.FC<TenderInfoProps> = ({ tender }) => {
  const [timer, setTimer] = useState(0)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { addSnackBar } = SnackBar()
  const { currentTime, worthyState } = useSelector((store: AppStore) => store)

  useEffect(() => {
    const serverTime = Math.round(Number(currentTime.data) / 1000000)
    const timeDiff = serverTime ? new Date().getTime() - serverTime : 0
    const finalizedAt = new Date(tender.finalized_at)
    setTimer(finalizedAt.getTime() - (new Date().getTime() - timeDiff))

    const intervalId = setInterval(() => setTimer(finalizedAt.getTime() - (new Date().getTime() - timeDiff)), 1000)

    return () => clearTimeout(intervalId)
  }, [currentTime.data])

  useEffect(() => {
    let timer1: any
    let timer2: any
    let timer3: any

    const serverTime = Math.round(Number(currentTime.data) / 1000000)
    const timeDiff = serverTime ? new Date().getTime() - serverTime : 0

    const timeToFinalize = new Date(tender.finalized_at).getTime() - (new Date().getTime() - timeDiff)
    const timeToStart = new Date(tender.start_at).getTime() - (new Date().getTime() - timeDiff)
    const timeToStartPreview = new Date(tender.preview_start_at).getTime() - (new Date().getTime() - timeDiff)

    if (timeToFinalize < 0) window.location.reload()

    if (timeToFinalize > 0)
      timer1 = setTimeout_1(() => {
        window.location.reload()
      }, timeToFinalize)

    if (timeToStart > 0)
      timer2 = setTimeout_1(() => {
        window.location.reload()
      }, timeToStart)

    if (timeToStartPreview > 0)
      timer3 = setTimeout_1(() => {
        window.location.reload()
      }, timeToStartPreview)

    return () => {
      clearTimeout(timer1)
      clearTimeout(timer2)
      clearTimeout(timer3)
    }
  }, [currentTime.data])

  useEffect(() => {
    if (timer < 0) window.location.reload()
  }, [timer])

  const downloadIcs = (idTender: number) => {
    const tenderType = getCategoryTenderName(tender.category_id)
    dispatch(
      GetIcs.request({
        idTender,
        callBack: (success, data) => {
          if (success) downloadFile('text/ics', data, `CIRCA - ${tenderType} - ${tender.sales_cycle}.ics`)
        },
      })
    )
  }

  const tenderStatus = useMemo((): 'upcoming' | 'viewCatalog' | 'active' | '' => {
    if (new Date(tender.preview_start_at) > new Date()) return 'upcoming'

    if (new Date(tender.start_at) > new Date() && new Date() >= new Date(tender.preview_start_at)) return 'viewCatalog'

    if (new Date(tender.start_at) <= new Date()) return 'active'

    return ''
  }, [])

  const tenderStatusLabel = () => {
    if (tenderStatus === 'active') {
      return (
        <div className='status text-purple-600 font-semibold text-xxs p-1 px-2 border-purple-200 border rounded-full bg-purple-50'>
          <span className='mr-1'>
            <Sparkles />
          </span>
          OPEN
        </div>
      )
    }
    if (tenderStatus === 'viewCatalog' || tenderStatus === 'upcoming') {
      return (
        <button
          type='button'
          onClick={() => downloadIcs(tender.tender_id)}
          className='status text-bluegray-800 font-semibold text-xxs rounded-full p-1 border px-2 border-bluegray-200 bg-bluegray-50'
        >
          <span className='mr-1'>
            <CalenderDay />
          </span>
          UPCOMING
        </button>
      )
    }

    return ''
  }

  const tenderStatusInfo = () => {
    if (tenderStatus === 'active') {
      return (
        <div className='timer-section flex items-end space-x-2 leading-none justify-center text-purple-600 mb-6'>
          <div className='value text-base flex-none text-teal-500 font-semibold'>{GetTime(timer)}</div>

          <div className='mode text-bluegray-500 font-normal uppercase text-xs flex-none hidden lg:block'>
            Remaining
          </div>
        </div>
      )
    }
    if (tenderStatus === 'viewCatalog' || tenderStatus === 'upcoming') {
      return (
        <div className='my-6'>
          <div className='catalog-schedule flex-none desktop:flex w-full justify-between items-center'>
            <div className='uppercase'>
              catalog
              <button type='button' className='ml-2 mr-1' onClick={() => downloadIcs(tender.tender_id)}>
                <CalenderDay />
              </button>
            </div>
            <div className='border-dotted border-b border-bluegray-200 mx-1 flex-grow' />
            <div className='text-bluegray-400'>{dataShortFormated(tender.preview_start_at)}</div>
          </div>

          <div className='start-schedule flex-none desktop:flex w-full justify-between items-center'>
            <div className='uppercase'>
              open
              <button type='button' className='ml-2 mr-1' onClick={() => downloadIcs(tender.tender_id)}>
                <CalenderDay />
              </button>
            </div>
            <div className='border-dotted border-b border-bluegray-200 mx-1 flex-grow' />
            <div className='text-bluegray-400'>
              <div>{`${dataFormated(tender.start_at)} – ${dataFormated(tender.finalized_at)}`}</div>
            </div>
          </div>
        </div>
      )
    }
    return ''
  }

  const downloadCatalogExcel = async () => {
    setLoading(true)
    let success = true
    let data = null
    try {
      data = await callApi({
        method: 'get',
        path: `${API_ROUTES.tenders}/${tender.tender_id}/catalog-xls`,
        isBlob: true,
      })
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `CIRCA Tender Catalog_${tender.sales_cycle}.xlsx`)
      document.body.appendChild(link)
      link.click()
    } catch (e: any) {
      success = false
      data = JSON.parse(await e?.data.text()) || e
    } finally {
      if (!success) addSnackBar(data?.message || '', success)
      setLoading(false)
    }
  }

  return (
    <div className={`tender-${tender.tender_id} border-b border-bluegray-200 pb-5 space-y-6`}>
      <div className='flex items-center leading-none mb-2 text-base'>
        <div className={`icon mr-2 ${tenderStatus === 'active' ? 'text-purple-400' : 'text-bluegray-400'}`}>
          {getCategoryIcon(tender.category_id)}
        </div>
        <div className='text-bluegray-800 flex space-x-2 items-center leading-none'>
          <div className={`tender-type ${tenderStatus === 'active' && 'font-semibold'}`}>
            {getCategoryTenderName(tender.category_id)}
          </div>

          {tenderStatusLabel()}
        </div>
      </div>

      {tenderStatusInfo()}
      {!(tenderStatus === 'upcoming') && tender.count_of_products > 0 && (
        <div className='view'>
          <Link
            to={`${worthyState.isWorthy ? '/worthy' : ''}${ROUTES.tenders}/${tender.tender_id}`}
            className='tender-link items-center p-2 font-semibold bg-bluegray-200 text-bluegray-800 border border-bluegray-300 w-full hover:bg-bluegray-300 xrounded-md block text-center rounded-md'
          >
            {tenderStatus === 'active'
              ? `Go to Tender - ${tender.sales_cycle}`
              : `Visit Catalog - ${tender.sales_cycle}`}
          </Link>

          {tender.category_id === 1 ? (
            <div className='mt-3 text-center relative'>
              <button
                type='button'
                disabled={loading}
                onClick={downloadCatalogExcel}
                className='catalog-xlsx text-green-600 uppercase text-xs hover:text-green-800 select-none'
              >
                {loading ? (
                  <div className='absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-10 bg-opacity-10'>
                    <Spinner style={{ color: 'black', width: '10px', height: '10px' }} />
                  </div>
                ) : null}
                <IconFileXLSX className='mr-2' />
                Download Catalog XLS
              </button>
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}
