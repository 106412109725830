import { Reducer } from 'redux'
import { ActionTypes, TSignState } from './types'

export const initialState: TSignState = {
  loading: false,
  data: null,
  authorized: false,
}

const reducer: Reducer<TSignState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SIGN_IN_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.SIGN_IN_S:
      return {
        ...state,
        loading: false,
        authorized: !!action.payload?.access_token,
        errors: undefined,
        data: action.payload,
      }
    case ActionTypes.SIGN_IN_E:
      return { ...state, loading: false, authorized: false, errors: action.payload }
    
    case ActionTypes.SIGN_OUT_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.SIGN_OUT_S: /// //////////////////// this is useless
      return { ...state, loading: false, authorized: false, errors: undefined }
    /// /////////////////////////////////////////////// after sign out store reset
    case ActionTypes.SIGN_OUT_E:
      return { ...state, loading: false, errors: action.payload }
    
    case ActionTypes.FORGOT_PASS_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.FORGOT_PASS_S:
      return { ...state, loading: false, errors: undefined }
    case ActionTypes.FORGOT_PASS_E:
      return { ...state, loading: false, errors: action.payload }
    
    case ActionTypes.SET_NEW_PASS_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.SET_NEW_PASS_S:
      return { ...state, loading: false, errors: undefined }
    case ActionTypes.SET_NEW_PASS_E:
      return { ...state, loading: false, errors: action.payload }
    
    case ActionTypes.SET_AUTH:
      return { ...state, authorized: true }
    case ActionTypes.SET_LOGOUT:
      return { ...state, authorized: false }
    default:
      return state
  }
}

export { reducer as SignReducer }
