import { TGalleryItemInfo } from '../../store/gallery/types'

export const itemDataPricesHelper = (data: TGalleryItemInfo | null, payload: TGalleryItemInfo) => {
  if (data) {
    // eslint-disable-next-line no-param-reassign
    if (payload.product.asking_price) data.product.asking_price = payload.product.asking_price
    // eslint-disable-next-line no-param-reassign
    if (payload.product.rap_list_price_discount) data.product.rap_list_price_discount = payload.product.rap_list_price_discount
    // eslint-disable-next-line no-param-reassign
    if (payload.product.asking_price_per_carat) data.product.asking_price_per_carat = payload.product.asking_price_per_carat
  }

  return data
}
