/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import styled from 'styled-components'
// @ts-ignore
import Tridi from 'react-tridi'
import 'react-tridi/dist/index.css'

import { useSwipeable } from 'react-swipeable'
import { ItemPopupFinalizedStatus, ItemPopupStatus } from '../itemLabels'
import { TGalleryItemInfo } from '../../../../store/gallery/types'
import { convertToDollars } from '../../../../utils/convertToDollars'
import { Gem } from '../../../../svg/Gem'
import { useVision360 } from '../../../../hooks/useVision360.js'
import { isMobileDevice, isScrolledIntoView } from '../../../../utils/helpers'
import { IconLike } from '../../../../svg/IconLike'
import { getUniqueId } from '../../../../utils/FileUtils'
import { IconFilePdf } from '../../../../svg/IconFilePdf'
import { BidWidgetDiamond } from './BidWidgetDiamond'
import { addToFavoritesAction, addToRecommendedAction } from '../../../../utils/galleryUtils/itemsActions'
import { AppStore } from '../../../../store/applicationState'
import { TenderTimer } from '../../headerUi/TenderTimer'
import { recMode } from '../../../../utils/consts'
import { IconStar } from '../../../../svg/IconStar'
import { IconPauseCircle } from '../../../../svg/IconPauseCircle'
import { IconPlayCircle } from '../../../../svg/IconPlayCircle'
import { IconArrowCircle } from '../../../../svg/IconArrowCircle'
import { IconGlassMinus } from '../../../../svg/IconGlassMinus'
import { IconGlassPlus } from '../../../../svg/IconGlassPlus'
import { IconCircleStop } from '../../../../svg/IconCircleStop'
import { IconFourArrows } from '../../../../svg/IconFourArrows'

const ImageWrapperS = styled.div<{ canvasHeight: number }>`
  @media screen and (min-width: 450px) {
    min-height: 300px;
  }

  .Vision360 {
    position: relative;
  }

  .main {
    display: block;
  }

  .bigframe,
  .V360-canvas,
  .frameContainer {
    margin: 0 auto !important;
    max-height: ${({ canvasHeight }) => canvasHeight}px !important;
    min-height: ${({ canvasHeight }) => canvasHeight}px !important;
    width: 100% !important;
    cursor: grab;

    img {
      max-height: ${({ canvasHeight }) => canvasHeight}px !important;
      min-height: ${({ canvasHeight }) => canvasHeight}px !important;
      width: 100% !important;
      margin: 0 auto !important;
      opacity: 1 !important;
    }

    #customeLoader {
      img {
        text-indent: -32768px;
        z-index: 999 !important;
      }
    }

    .firstimag {
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 1 !important;
    }
  }

  #v360Nav {
    position: absolute;
    color: white;
    bottom: 20px;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 3%;
    padding-right: 3%;
    cursor: grab;
    height: 40px;
    overflow: visible;
  }

  #v360Nav div {
    text-align: center;
  }

  #zoomimg {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
  }

  #pause_div {
    filter: brightness(2);
    overflow: visible;

    img {
      pointer-events: none;
      cursor: pointer;
    }
  }

  #zoomstart_div {
    overflow: visible;

    img {
      pointer-events: none;
      transform: scale(1.3);
      cursor: pointer;
    }
  }
`

interface DiamondPopupBodyProps {
  item: TGalleryItemInfo
  isCatalog: boolean
  finalized: boolean
  bidFromTable?: number
  setPurchasePopupActive: (value: boolean) => void
}

export const DiamondPopupBody: React.FC<DiamondPopupBodyProps> = ({
  item,
  isCatalog,
  finalized,
  bidFromTable,
  setPurchasePopupActive,
}) => {
  const dispatch = useDispatch()
  const { userData, gallery } = useSelector((store: AppStore) => store)

  const [countJPEG, setCountJPEG] = useState(0)
  const [imgError, setImgError] = useState(false)
  const [reportError, setReportError] = useState(true)
  const [showReportImages, setShowReportImages] = useState(false)
  const [isAutoPlayRunning, setIsAutoPlayRunning] = useState(false)
  const [isMovingRunning, setIsMovingRunning] = useState(false)
  const [grabbing, setGrabbing] = useState(false)
  const [zoomValue, setzoomValue] = useState(1)
  const tridiRef = useRef(null)

  const { height, setSwipeTop, displayVisible, setSwipeDown, videoIsBroken, lastJsonIsLoaded } = useVision360(
    item.product_gallery.part_of_media_file.length > 5,
    item.product_gallery.videos[0]?.split('?d=')[1]
  )

  useEffect(() => {
    const itemPopup = document.getElementById('itemPopup')
    const vision360Wrapper = document.getElementById('vision360Wrapper')

    const onScroll = () => {
      const pause = document.getElementById('pause') as HTMLImageElement
      if (vision360Wrapper && pause) {
        if (!isScrolledIntoView(vision360Wrapper)) {
          if (pause.currentSrc.search('pause') !== -1) {
            pause.click()
          }
        } else if (pause.currentSrc.search('pause') === -1) {
          pause.click()
        }
      }
    }

    const onFocus = () => {
      const pause = document.getElementById('pause') as HTMLImageElement

      if (vision360Wrapper && pause && pause.currentSrc.search('pause') === -1) pause.click()
    }

    const onBlur = () => {
      const pause = document.getElementById('pause') as HTMLImageElement

      if (vision360Wrapper && pause && pause.currentSrc.search('pause') !== -1) pause.click()
    }

    if (itemPopup?.children[0]) itemPopup?.children[0].addEventListener('scroll', onScroll)
    window.addEventListener('blur', onBlur)
    window.addEventListener('focus', onFocus)

    return () => {
      if (itemPopup?.children[0]) itemPopup?.children[0].addEventListener('scroll', onScroll)
      window.removeEventListener('blur', onBlur)
      window.removeEventListener('focus', onFocus)
    }
  }, [])

  const swipeHandler = useSwipeable({
    onSwipedUp: () => {
      if (!isMobileDevice()) return null
      return setSwipeTop((i) => i + 1)
    },
    onSwipedDown: () => {
      if (!isMobileDevice()) return null
      return setSwipeDown((i) => i + 1)
    },
  })

  const navClickHandle = () => {
    if (!lastJsonIsLoaded) return

    const getZoomTracker = () => {
      const tracker: HTMLElement | null = document.querySelector('.zoomtracker')
      const canvas = document.querySelector('#zoomimg')
      if (!canvas) return null
      // const heightDiv = canvas.clientHeight
      const widthDiv = canvas.clientWidth

      if (!tracker) return null
      // tracker.style.height = `${heightDiv - 60}px`
      tracker.style.width = `${widthDiv - 50}px`
      return null
    }

    try {
      // @ts-ignore
      document.getElementsByClassName('firstimag')[0].style.display = 'none'
      setTimeout(getZoomTracker, 1000)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Error when click on zoom')
    }
  }
  const idTender = Number(window.location.pathname.split('/').find((elem) => Number(elem))) || 0

  useEffect(() => {
    async function getPDFinfo() {
      const report = item.product_gallery.report_pdfs[0]
      const adress = 'https://cdn.filestackcontent.com/A3r4cONw2QCeRFN4kcWd5z'
      const pdfs = `${adress}/output=format:jpg,quality:100,density:500,docinfo:true/${report}`
      let success = true
      try {
        const res = await axios.request({
          method: 'get',
          responseType: 'json',
          url: pdfs,
        })
        setCountJPEG(res.data.numpages)

        return res.data
      } catch (e) {
        setReportError(true)
        setShowReportImages(true)
        success = false
        // eslint-disable-next-line no-console
        console.warn(e)
        return null
      } finally {
        if (success) setReportError(false)
      }
    }

    if (!item.product_gallery.report_pdfs.length) setShowReportImages(true)
    if (
      !item.product_gallery.report_pdf_2_images.length &&
      item.product_gallery.report_pdfs.length &&
      !item.product_gallery.report_pdfs[0].includes('https://www.gia.edu')
    )
      getPDFinfo()
  }, [])

  const getImages = (count: number) => {
    const size = 2048
    if (count > 0) {
      const arr = Array.from(Array(count).keys())

      return arr.map((ar) => (
        <img
          key={`report-${getUniqueId()}`}
          src={`https://cdn.filestackcontent.com/A3r4cONw2QCeRFN4kcWd5z/output=format:jpg,quality:100,density:500,page:${
            ar + 1
          }/resize=height:${size},width:${size}/${item.product_gallery.report_pdfs[0]}`}
          alt='GiaReport'
          className='converted-report-image w-full'
        />
      ))
    }
    return ''
  }

  const statusBody = useMemo(
    () => (
      <div>
        {finalized ? (
          <ItemPopupFinalizedStatus item={item} />
        ) : (
          <div>
            {!item.is_sold ? (
              <BidWidgetDiamond
                item={item}
                bidFromTable={bidFromTable}
                setPurchasePopupActive={setPurchasePopupActive}
                tenderId={Number(window.location.pathname.split('/').find((elem) => Number(elem))) || 0}
              />
            ) : (
              <ItemPopupStatus item={item} />
            )}
          </div>
        )}
      </div>
    ),
    [item, item.is_sold, item.bid_status_id, item.bid, finalized, bidFromTable]
  )

  const diamondPrewiew = () => {
    if (item.product_gallery.part_of_media_file.length > 5) {
      return (
        <div className='video-block relative border border-bluegray-200 min-w-full h-auto'>
          <div
            aria-hidden
            className='video-container relative'
            onMouseDown={() => {
              // @ts-ignore
              tridiRef.current.toggleAutoplay(false)
              setIsAutoPlayRunning(false)
              setGrabbing(true)
            }}
            onMouseUp={() => setGrabbing(false)}
            onMouseLeave={() => setGrabbing(false)}
          >
            <Tridi
              ref={tridiRef}
              className={`diamond-video h-full z-20 active:cursor-grabbing ${
                grabbing ? 'video-grabbing' : 'cursor-grab'
              }`}
              images={item.product_gallery.part_of_media_file}
              format='jpg'
              count={item.product_gallery.part_of_media_file.length}
              autoplay={isAutoPlayRunning}
              zoom={zoomValue}
              mousewheel
              mouseleaveDetect
              autoplaySpeed={45}
              // dragInterval={2}
              onLoadChange={(loadSuccess: any) => {
                if (loadSuccess) setIsAutoPlayRunning(true)
              }}
            />
          </div>

          <div className='video-control-buttons flex justify-around w-full p-2 text-2xl text-slate-600 bg-white bg-opacity-20 z-40'>
            <button
              type='button'
              className='video-control-play mx-1 px-1'
              onClick={() => {
                // @ts-ignore
                tridiRef.current.toggleAutoplay(!isAutoPlayRunning)
                setIsAutoPlayRunning(!isAutoPlayRunning)
              }}
            >
              {isAutoPlayRunning ? <IconPauseCircle /> : <IconPlayCircle />}
            </button>
            <button
              type='button'
              className='video-control-prev mx-1 px-1'
              onClick={() => {
                // @ts-ignore
                tridiRef.current.prev()
              }}
              disabled={zoomValue <= 1 || isAutoPlayRunning}
            >
              <IconArrowCircle />
            </button>
            <button
              type='button'
              className='video-control-next mx-1 px-1'
              onClick={() => {
                // @ts-ignore
                tridiRef.current.next()
              }}
              disabled={zoomValue <= 1 || isAutoPlayRunning}
            >
              <IconArrowCircle className='rotate-180' />
            </button>
            <button
              className='video-control-zoom-minus mx-1 px-1'
              type='button'
              onClick={() => {
                if (zoomValue > 1) setzoomValue((prev) => prev - 0.25)
              }}
            >
              <IconGlassMinus />
            </button>
            <button
              className='video-control-zoom-plus mx-1 px-1'
              type='button'
              onClick={() => {
                if (zoomValue < 2) setzoomValue((prev) => prev + 0.25)
              }}
            >
              <IconGlassPlus />
            </button>
            <button
              className='video-control-move mx-1 px-1'
              type='button'
              onClick={() => {
                // @ts-ignore
                tridiRef.current.toggleMoving(!isMovingRunning)
                setIsMovingRunning(!isMovingRunning)
                // @ts-ignore
                tridiRef.current.toggleAutoplay(false)
                setIsAutoPlayRunning(false)
                if (isMovingRunning) setzoomValue(1)
              }}
            >
              {isMovingRunning ? <IconCircleStop /> : <IconFourArrows />}
            </button>
          </div>
        </div>
      )
    }
    if (!!item.product_gallery.videos[0] && !videoIsBroken) {
      return (
        <ImageWrapperS
          {...swipeHandler}
          style={!displayVisible ? { background: 'transparent', border: '1px solid transparent' } : {}}
          className='img'
          canvasHeight={height}
          id='vision360Wrapper'
        >
          <div className='Vision360' style={displayVisible ? { visibility: 'visible' } : { visibility: 'hidden' }} />
          <div id='v360Nav' onClick={navClickHandle} />
        </ImageWrapperS>
      )
    }

    if (item.product_gallery.images.length && !imgError) {
      return (
        <div className='main-image img'>
          <img src={item.product_gallery.images[0]} alt='diamond' onError={() => setImgError(true)} />
        </div>
      )
    }

    return (
      <div className='main-image img'>
        <div
          style={{ minHeight: '400px' }}
          className='text-white bg-bluegray-300 flex items-center justify-center h-full w-auto text-7xl border border-bluegray-200'
        >
          <Gem />
        </div>
      </div>
    )
  }

  return (
    <div className='mx-auto flex-col shadow-2xl-dark bg-white pt-1 md:pt-0'>
      {!finalized ? (
        <div className='tender-type text-base  tracking-widest flex  space-x-6 font-semibold items-center  justify-center border-b py-2'>
          {gallery.data.tenderInfo ? <TenderTimer tender={gallery.data.tenderInfo} /> : null}
        </div>
      ) : null}

      <div className='md:flex md:flex-row flex-col'>
        <div className='image-block w-12/12 md:w-6/12 p-6'>{diamondPrewiew()}</div>

        <div className='md:w-6/12 w-12/12 p-6 flex-grow'>
          <div className='space-y-8'>
            <div className='flex justify-between items-center leading-none'>
              <div className='title text-2xl text-bluegray-900 leading-none font-circa font-medium'>
                {item.product.title}
              </div>
              {!finalized && (
                <div className='flex flex-row gap-2'>
                  {((gallery.data.atLeastOneRecommended && item.in_recommendations) || recMode) && (
                    <button
                      type='button'
                      disabled={!recMode}
                      onClick={() => {
                        addToRecommendedAction(item.in_recommendations, userData.companyId!, item.item_id, dispatch)
                      }}
                      className='recommendations text-yellow-500 text-lg disabled:opacity-100'
                    >
                      <IconStar className='!w-6 !h-6' isActive={item.in_recommendations} />
                    </button>
                  )}
                  <button
                    type='button'
                    onClick={() => addToFavoritesAction(item.in_favorites, idTender, item.item_id, dispatch)}
                    className='favorites text-2xl text-red-500 ml-2'
                  >
                    <IconLike isActive={item.in_favorites} />
                  </button>
                </div>
              )}
            </div>

            <div className='h-1/4 flex items-center'>
              <div className='circa-comments bg-yellow-50 text-base'>
                {`CIRCA Comments: ${item.product.product_details.circa_comments}`}
              </div>
            </div>

            <div className='flex justify-between items-start h-1/4 mb-3 w-11/12'>
              {item.product.rap_list_price ? (
                <div className='flex space-x-4 items-start flex-initial text-bluegray-500 mr-2'>
                  <div className='rap-list-price text-xl font-semibold leading-none text-center'>
                    <span className=''>{convertToDollars(item.product.rap_list_price)}</span>

                    <div className='text-sm uppercase text-bluegray-500 whitespace-nowrap mt-3 font-normal'>
                      RAP List Price
                    </div>
                  </div>
                </div>
              ) : null}

              <div className='flex  items-start flex-initial divide-x-2'>
                {item.product.asking_price_per_carat && !isCatalog ? (
                  <div className='price-per-carat text-xl font-semibold leading-none text-center pr-3'>
                    {convertToDollars(item.product.asking_price_per_carat)}
                    <div className='text-sm uppercase text-bluegray-500 whitespace-nowrap mt-3 font-normal'>
                      Price Per Carat
                    </div>
                  </div>
                ) : null}
                {item.product.rap_list_price_discount && !isCatalog ? (
                  <div className='rap-list-price-discount text-xl font-semibold leading-none text-center pl-3'>
                    {item.product.rap_list_price_discount ? `${item.product.rap_list_price_discount}%` : ''}
                    <div className='text-sm uppercase text-bluegray-500 mt-3 font-normal'>% RAP</div>
                  </div>
                ) : null}
              </div>
            </div>

            {!isCatalog && statusBody}
          </div>
        </div>
      </div>

      {(item.product_gallery.report_pdfs.length && !reportError) ||
      item.product_gallery.report_images.length ||
      item.product_gallery.report_pdf_2_images.length ? (
        <>
          <div className='bg-bluegray-100 h-6 mt-6' />

          <div>
            <div className='report-header p-4  flex justify-between items-center border-b border-bluegray-200 border-dashed'>
              <div className='text-2xl  leading-none font-circa'>Report</div>

              <div className='view-pdf-button'>
                {item.product_gallery.report_pdfs[0] ? (
                  <a
                    href={item.product_gallery.report_pdfs[0]}
                    target='_blank'
                    rel='noreferrer'
                    className='border border-bluegray-100 bg-bluegray-50 text-bluegray-700 rounded-md px-4 py-2 select-none hover:bg-bluegray-100 focus:outline-none'
                  >
                    <span className='mr-2'>
                      <IconFilePdf />
                    </span>
                    View PDF
                  </a>
                ) : null}
              </div>
            </div>

            {!item.product_gallery.report_pdf_2_images.length && item.product_gallery.report_pdfs.length && !reportError
              ? getImages(countJPEG)
              : null}

            {item.product_gallery.report_pdf_2_images.length
              ? item.product_gallery.report_pdf_2_images.map((image) => {
                  return (
                    <img
                      key={`report-pdf-${getUniqueId()}`}
                      src={image}
                      alt='GiaReport'
                      className='converted-report-image w-full'
                    />
                  )
                })
              : null}
          </div>
        </>
      ) : null}

      {showReportImages && item.product_gallery.report_images.length ? (
        <div className=''>
          {item.product_gallery.report_images.map((img) => (
            <img key={`report-${getUniqueId()}`} src={img} alt='GiaReport' className='report-image w-full' />
          ))}
        </div>
      ) : null}
    </div>
  )
}
