/* eslint-disable react/require-default-props */
import React from 'react'
import { CustomDropdown } from '../adminUsersComponents/CustomDropdown'

interface TEvents {
  id: number,
  event_title: string
}

interface EmailLogsDropdownProps {
  solidIcon: boolean
  onClear: () => void
  
  eventsData?: TEvents[]
  selectedEvents: number[]
  setSelectedEvents: (eventId: number) => void
}

export const EmailLogsDropdown: React.FC<EmailLogsDropdownProps> = ({
  solidIcon,
  onClear,
  eventsData,
  selectedEvents,
  setSelectedEvents,
}) => {
  return (
    <CustomDropdown
      menuItemsWidth='w-64'
      title='Event'
      solidIcon={solidIcon}
      onClear={onClear}
    >
      {[{
        title: 'Events',
        activeCheckboxId: selectedEvents,
        checkboxes: eventsData?.map(event => ({
          id: event.id,
          title: event.event_title,
          callBack: () => setSelectedEvents(event.id),
        })),
      }]}
    </CustomDropdown>
  )
}
