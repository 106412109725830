import { Actions } from "../../utils/Action"
import { ActionTypes } from "./types"
import { TDefRequest } from "../../types/actions"


/// ///////////////////////////////////////////////////////////////////////
const GetMessageMethodsActions = new Actions('GET_MESSAGE_METHODS', ActionTypes)

export interface TypeGetMessageMethodsR extends TDefRequest {}

export const GetMessageMethods = {
  request: (payload?: TypeGetMessageMethodsR) => GetMessageMethodsActions.request(payload || {}),
  success: (payload: any) => GetMessageMethodsActions.success(payload),
  error: (payload: string) => GetMessageMethodsActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
