import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as Actions from './actions'
import { callApi } from '../../../../utils/callApi'
import { API_ROUTES } from '../../../../utils/apiRoutes'
import { ActionTypes, TBuyNowPurchases, TBuyNowPurchasesMessage, TCsv, TTenderStatsForBuyNowPurchases } from './types'
import { SnackBar } from '../../../../components/ui/SnackBar'

const { addSnackBar } = SnackBar()

function* getBuyNowPurchasesWorker(action: ReturnType<typeof Actions.GetBuyNowPurchases.request>) {
  const { callBack, idTender, offset, limit, search, sortBy, sort } = action.payload as Actions.TypeGetBuyNowPurchasesR
  let success = true
  let data = null
  
  let query = `?Offset=${offset || 0}&Limit=${limit || 20}`
  query += search ? `&Search=${search}` : ''
  query += sortBy ? `&SortBy=${sortBy}` : ''
  query += sort ? `&Sort=${sort}` : ''
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminTenders}/${idTender}/items/buy-now${query}`,
    })) as TBuyNowPurchases[]
    if (offset !== 0)
      yield put(Actions.GetBuyNowPurchases.success(data))
    else
      yield put(Actions.GetBuyNowPurchasesWith0Offset.success(data))
    
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetBuyNowPurchases.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}


function* deleteBuyNowWorker(action: ReturnType<typeof Actions.DeleteBuyNow.request>) {
  const { callBack, idItem } = action.payload as Actions.TypeDeleteBuyNowR
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'delete',
      path: `${API_ROUTES.adminTenders}/items/${idItem}/buy`,
    })) as TBuyNowPurchasesMessage
    
    yield put(Actions.DeleteBuyNow.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.DeleteBuyNow.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    const successMessage = '"Buy now" has been deleted'
    const errorMessage = data?.message || ''
    const snackBarMessage = success ? successMessage : errorMessage
    addSnackBar(snackBarMessage, success)
  }
}


function* getBuyNowPurchasesCsvWorker(action: ReturnType<typeof Actions.GetBuyNowPurchasesCsv.request>) {
  const { callBack, idTender } = action.payload as Actions.TypeGetBuyNowPurchasesCsvR
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminTenders}/${idTender}/items/buy-now/csv`,
    })) as TCsv
    
    yield put(Actions.GetBuyNowPurchasesCsv.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetBuyNowPurchasesCsv.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (!success) addSnackBar(data?.message || '', success)
  }
}

function* getTenderStatsForBuyNowPurchasesWorker(action: ReturnType<typeof Actions.GetTenderStatsForBuyNowPurchases.request>) {
  const { callBack, idTender } = action.payload as Actions.TypeGetTenderStatsForBuyNowPurchasesR
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminTenders}/${idTender}/buy-now/tender-stats-sold`,
    })) as TTenderStatsForBuyNowPurchases
    
    yield put(Actions.GetTenderStatsForBuyNowPurchases.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetTenderStatsForBuyNowPurchases.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (!success) addSnackBar(data?.message || '', success)
  }
}

function* makeSalesOrdersAndInvoicesWorker(action: ReturnType<typeof Actions.MakeSalesOrdersAndInvoices.request>) {
  const { callBack, idItems } = action.payload as Actions.TypeMakeSalesOrdersAndInvoicesR
  let success = true
  let data = null
  
  try {
    const queryString = idItems.map(id => `ItemId=${id}`).join('&')
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminTenders}/items/make-sales-orders-and-invoices?${queryString}`,
    })) as TBuyNowPurchasesMessage
    
    yield put(Actions.MakeSalesOrdersAndInvoices.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.MakeSalesOrdersAndInvoices.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    const successMessage = 'In process'
    const errorMessage = data?.message || ''
    const snackBarMessage = success ? successMessage : errorMessage
    addSnackBar(snackBarMessage, success)
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.GET_BUY_NOW_PURCHASES_R, getBuyNowPurchasesWorker)
  yield takeEvery(ActionTypes.DELETE_BUY_NOW_R, deleteBuyNowWorker)
  yield takeEvery(ActionTypes.GET_BUY_NOW_PURCHASES_CSV_R, getBuyNowPurchasesCsvWorker)
  yield takeEvery(ActionTypes.GET_TENDER_STATS_FOR_BUY_NOW_PURCHASES_R, getTenderStatsForBuyNowPurchasesWorker)
  yield takeEvery(ActionTypes.MAKE_SALES_ORDERS_AND_INVOICES_R, makeSalesOrdersAndInvoicesWorker)
}


export default function* buyNowPurchasesSaga() {
  yield all([fork(watchRequest)])
}