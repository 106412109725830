import { TMessageForTemplates } from '../../store/admin/settings/cms/types'
import { TMessageMethods } from '../../store/messageMethods/types'

export const getSchedule = (value: number): string => {
  if (value < 0) return `${value * -1 / 60} mins before event`
  return `${value / 60} mins after event`
}

export const getMessages = (messages: TMessageForTemplates[], messageMethods: TMessageMethods[]): string[] => {
  const result = messages.map(message => messageMethods?.find((method) => message.active && method.message_method_id === message.message_method_id))
  return (
    result.map(item => {
      if (item) return `${item.method_name}`
      return ''
    })
  )
}