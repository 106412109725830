import React from 'react'

export const IconVideo = () => {
  return (
    <svg
      className="svg-inline--fa fa-video"
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="video"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      data-fa-i2svg=""
    >
      <g className="fa-duotone-group">
        <path className="fa-secondary opacity-40" fill="currentColor" d="M384 112v288c0 26.51-21.49 48-48 48h-288c-26.51 0-48-21.49-48-48v-288c0-26.51 21.49-48 48-48h288C362.5 64 384 85.49 384 112z" />
        <path className="fa-primary" fill="currentColor" d="M576 127.5v256.9c0 25.5-29.17 40.39-50.39 25.79L416 334.7V177.3l109.6-75.56C546.9 87.13 576 102.1 576 127.5z" />
      </g>
    </svg>
  )
}
