import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from '../../components/ui/adminComponents/Input'
import {
  GetBidsByItem,
  GetBidsByItemChild,
  GetTenderStatsForBidsByItem,
} from '../../store/admin/tenders/bidsByItem/actions'
import { getBidsByItemChildParsed, getBidsByItemParsed } from '../../utils/tendersUtils/tenderDataHelper'
import { Table } from '../../components/ui/adminComponents/Table'
import { AppStore } from '../../store/applicationState'
import { searchProps, useSearch } from '../../hooks/useSearch'
import { paginationProps, usePagination } from '../../hooks/usePagination'
import { useAddItemPopup } from '../../hooks/useAddItemPopup'
import { TenderStatusType } from '../../utils/tendersUtils/tenderStatusHelper'
import { LoaderPoints } from '../../components/ui/LoaderPoints'
import { getObjWithOutKeys } from '../../utils/helpers'
import { UserRecommendByItemPopup } from '../../components/ui/adminComponents/UserRecommendByItemPopup'
import { Button } from '../../components/ui/adminComponents/Button'
import { SnackBar } from '../../components/ui/SnackBar'
import { IconCopy } from '../../svg/IconCopy'
import { TBidsByItem } from '../../store/admin/tenders/bidsByItem/types'

const bidsByItemValuesTheadChild = {
  customer: 'Customer',
  company: 'Company',
  bid: 'Bid',
  bid_date: 'Bid Date ',
}

const tableCellWidthActive = [155, 155, 250, 90, 195, 195, 195, 145, 105, 140, 120]
const tableCellWidth = [155, 155, 250, 90, 195, 195, 195, 190, 150, 140, 120]

interface BidsByItemProps {
  currentTenderId: number
  tenderStatus: TenderStatusType | null
}

export const BidsByItem: React.FC<BidsByItemProps> = ({ currentTenderId, tenderStatus }) => {
  const limitAmount = 400
  const initialParams = {
    limit: limitAmount,
    offset: 0,
    search: undefined,
    sort: '',
    sortBy: '',
  }

  const dispatch = useDispatch()
  const { bidsByItem, userData, tenders } = useSelector((store: AppStore) => store)
  const { add } = useAddItemPopup()

  const [params, setParams] = useState<searchProps | paginationProps>(initialParams)
  const [dispatchTime, setDispatchTime] = useState(0)
  const [childIndex, setChildIndex] = useState<number[]>([])
  const [showRecommendationItem, setShowRecommendationItem] = useState<TBidsByItem | null>(null)

  const didMount = useRef(false)

  usePagination(bidsByItem.data, setParams, limitAmount)
  useSearch(limitAmount, params, setParams, setDispatchTime)
  const { addSnackBar } = SnackBar()

  useEffect(() => {
    dispatch(GetTenderStatsForBidsByItem.request({ idTender: currentTenderId }))
  }, [currentTenderId])

  useEffect(() => {
    if (tenders.updateTenderNow) dispatch(GetTenderStatsForBidsByItem.request({ idTender: currentTenderId }))
  }, [tenders.updateTenderNow])

  useEffect(() => {
    if (didMount.current) {
      setDispatchTime((prev) => prev + 1)
      setParams(initialParams)
    }
    didMount.current = true
  }, [currentTenderId])

  useEffect(() => {
    dispatch(
      GetBidsByItem.request({
        tenderId: currentTenderId,
        offset: params.offset,
        limit: params.limit,
        search: params.search,
        sortBy: params.sortBy,
        sort: params.sort,
        // callBack: (success, data: TBidsByItem[]) => {
        //   if (success) setChildIndex(childIndex.filter(child => data.some(item  => item.item_id === child)))
        // },
      })
    )
  }, [params.offset, params.limit, params.sortBy, params.sort, dispatchTime])

  useEffect(() => {
    if (tenders.updateTenderNow) {
      if (params.offset === 0) setDispatchTime((prev) => prev + 1)
      else setParams((prev) => ({ ...prev, offset: 0, limit: prev.limit + prev.offset }))
    }
  }, [tenders.updateTenderNow])

  /// //////////////////////////////////////////////////////////////////////////////////
  const callBack = (sortBy: string) => {
    if (sortBy === 'action') return

    let value: { sortBy: string; sort: 'ASC' | 'DESC' }
    if (sortBy !== params.sortBy || params.sort === 'DESC') value = { sortBy, sort: 'ASC' }
    else value = { sortBy, sort: 'DESC' }

    setParams((prev) => ({ ...prev, offset: 0, limit: limitAmount, sortBy: value.sortBy, sort: value.sort }))
    setChildIndex([])
  }

  const getChildForBidsByItem = (idItem: number) => {
    if (idItem !== -1) {
      dispatch(GetBidsByItemChild.request({ idItem }))
    }
  }

  useEffect(() => {
    setChildIndex(
      childIndex.filter((child) => bidsByItem.data && bidsByItem.data.some((item) => item.item_id === child))
    )
  }, [bidsByItem.data])

  useEffect(() => {
    if (tenders.updateTenderNow) {
      if (childIndex.length) {
        childIndex.map((child) => {
          if (bidsByItem.data) getChildForBidsByItem(child)

          return null
        })
      }
    }
  }, [tenders.updateTenderNow])

  const onCopyLink = () => {
    navigator.clipboard
      .writeText(`${window.location.origin}/c/${currentTenderId}`)
      .then(() => addSnackBar('Copied!', true, 'text-center !min-w-[140px]'))
  }

  const bidsByItemValuesThead = {
    shipping_owner: 'Own',
    stock_number: 'Stock #',
    title: 'Item',
    count_of_bids: '# Bids',
    high_bid: 'High Bid',
    sales_target: 'Reserve',
    asking_price: 'BUY NOW',
    winning_bid: 'Winning Price',
    company_title: 'Winner',
    action: 'Action',
    action2: (
      <div className=''>
        <Button
          className='flex items-center !px-1.5 !text-xss'
          variant='bidsCSV'
          text='Copy link'
          image={<IconCopy style={{ width: 22, height: 20 }} />}
          onClick={onCopyLink}
        />
      </div>
    ),
  }

  const bidsByItemValues = useMemo(() => {
    return bidsByItem.data
      ? getBidsByItemParsed(
          bidsByItem.data,
          childIndex,
          getChildForBidsByItem,
          userData.isExecutiveAdmin,
          dispatch,
          add,
          tenderStatus,
          setShowRecommendationItem
        )
      : null
  }, [bidsByItem.data, childIndex])

  const bidsByItemValuesChild = useMemo(() => {
    if (!bidsByItem.childData) return null
    const result = new Map<number, object[]>()
    bidsByItem.childData.forEach((value, key) => {
      result.set(key, getBidsByItemChildParsed(value, userData.isExecutiveAdmin, tenderStatus === 'completed'))
    })
    return result
  }, [bidsByItem.childData?.entries()])

  const bidsByItemTable = useMemo(() => {
    return bidsByItemValues ? (
      <Table
        thead={
          tenderStatus === 'active' ? bidsByItemValuesThead : getObjWithOutKeys(bidsByItemValuesThead, ['action2'])
        }
        tbody={bidsByItemValues}
        theadChild={bidsByItemValuesTheadChild}
        tbodyChild={bidsByItemValuesChild}
        callBack={callBack}
        childIndex={childIndex}
        setChildIndex={setChildIndex}
        tableCellWidth={tenderStatus === 'active' ? tableCellWidthActive : tableCellWidth}
        styledReserveNotMet
        defaultSort={{ sort: 'up', sortBy: 'title' }}
      />
    ) : null
  }, [bidsByItemValues, bidsByItemValuesChild])

  return (
    <>
      <div className='flex justify-between mb-4 flex-col lg:flex-row'>
        <div className='w-full md:w-1/3 lg:w-1/4 mb-6 lg:mb-0'>
          <Input
            placeholder='Search...'
            value={params.search || ''}
            onChange={(e) => setParams((prev) => ({ ...prev, search: e.target.value }))}
          />
        </div>
        <div className='border text-xs w-full lg:w-2/3 overflow-x-auto'>
          <div
            className='font-semibold text-bluegray-900 border-b border-bluegray-200 pl-3 flex'
            style={{ minWidth: '700px' }}
          >
            Summary
          </div>
          <div className='flex justify-between items-center divide-x w-full' style={{ minWidth: '700px' }}>
            <div className='px-3'>
              Total items:
              {` ${bidsByItem.tenderStats?.total_count}`}
            </div>
            <div className='px-3'>
              # items with at least 1 bid:
              {` ${bidsByItem.tenderStats?.items_with_bids}`}
            </div>
            <div className='px-3'>
              % of items &gt;= 1 bid:
              {bidsByItem.tenderStats &&
                ` ${(
                  (bidsByItem.tenderStats.items_with_bids / (bidsByItem.tenderStats.total_count || 100)) *
                  100
                ).toFixed(1)}%`}
            </div>
            <div className='px-3'>
              # items with bid &gt;= reserve:
              {` ${bidsByItem.tenderStats?.more_or_equal_reserve}`}
            </div>
            <div className='px-3'>
              % of items with bid &gt;= reserve:
              {bidsByItem.tenderStats &&
                ` ${(
                  (bidsByItem.tenderStats.more_or_equal_reserve / (bidsByItem.tenderStats.total_count || 100)) *
                  100
                ).toFixed(1)}`}
            </div>
          </div>
        </div>
      </div>
      <div className='relative'>
        {bidsByItemTable}
        {!!bidsByItemTable && bidsByItem.loading && (
          <div className='absolute left-0 right-0 -bottom-6 flex justify-center'>
            <LoaderPoints className='m-auto' />
          </div>
        )}
      </div>
      {!!showRecommendationItem && (
        <UserRecommendByItemPopup setVisible={setShowRecommendationItem} itemm={showRecommendationItem} />
      )}
    </>
  )
}
