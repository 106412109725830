/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { PurchasePopup } from '../../components/ui/galleryUi/PurchasePopup'
import { ItemCardStatus } from '../../components/ui/galleryUi/itemLabels'
import { TGalleryItemInfo } from '../../store/gallery/types'
import { convertToDollars } from '../../utils/convertToDollars'
import { Gem } from '../../svg/Gem'
import { useAddItemPopup } from '../../hooks/useAddItemPopup'
import { IconLike } from '../../svg/IconLike'
import { addNewParams } from '../../utils/helpers'
import { addToFavoritesAction, addToRecommendedAction } from '../../utils/galleryUtils/itemsActions'
import { GetItemInfo } from '../../store/gallery/actions'
import { API_ROUTES } from '../../utils/apiRoutes'
import { IconStar } from '../../svg/IconStar'
import { AppStore } from '../../store/applicationState'
import { recMode } from '../../utils/consts'

interface diamondCardProps {
  isCatalog: boolean
  tenderId: number
  item: TGalleryItemInfo
}

const ImageWrapperS = styled.img`
  width: 100%;
  object-fit: cover;

  @media screen and (min-width: 650px) {
    height: 220px;
  }
`
const ImageAltWrapperS = styled.div`
  width: 100%;
  height: 220px;
  object-fit: cover;
`

export const DiamondCard: React.FC<diamondCardProps> = ({ item, tenderId, isCatalog }) => {
  const dispatch = useDispatch()
  const { userData, gallery } = useSelector((store: AppStore) => store)

  const [purchasePopupActive, setPurchasePopupActive] = useState(false)
  const [imgError, setImgError] = useState(false)

  const itemStatus = item.bid_status_id === 0 && !item.is_sold ? 'open' : ''

  const { add } = useAddItemPopup()
  const openItemPopup = () => {
    dispatch(
      GetItemInfo.request({
        itemId: item.item_id,
        callBack: (success, data) => {
          if (success) {
            const finalized = false
            add({ item: data, isCatalog, finalized })
            addNewParams('item', `${item.item_id}`)
          }
        },
      })
    )
  }

  const havePreviewImage = (item.product_gallery.images.length || item.product_gallery.part_of_media_file.length) > 0

  return (
    <div className={`mx-1.5 py-2 ${havePreviewImage ? '' : 'mobile:w-auto w-full'}`}>
      <div className='item-card p-4 border border-bluegray-100 bg-white lg:w-68 mobile:w-64 w-12/12 mobile:mx-0 mx-5 mb-8 shadow-sm'>
        <div className='item-image relative'>
          <div className='image-holder relative flex'>
            <Link
              to={`${API_ROUTES.tenders}/${tenderId}?item=${item.item_id}`}
              onClick={(e) => {
                if (e.nativeEvent.which === 1) {
                  e.preventDefault()
                  openItemPopup()
                }
              }}
              className='w-full'
            >
              {havePreviewImage && !imgError ? (
                <ImageWrapperS
                  src={item.product_gallery.images[0] || item.product_gallery.part_of_media_file[0]}
                  alt=''
                  aria-hidden='true'
                  onError={() => setImgError(true)}
                  className={`transform rxotate-180 z-10 ${itemStatus === 'open' ? '' : 'opacity-25'}`}
                />
              ) : (
                <ImageAltWrapperS className='text-white bg-bluegray-300 flex items-center justify-center h-36 w-auto text-7xl border border-bluegray-200'>
                  <Gem />
                </ImageAltWrapperS>
              )}
            </Link>
            {recMode && (
              <div className='absolute left-1 right-1 top-1 bg-gray-300 opacity-70 p-1'>
                Owner: {item.product.shipping_owner}
                <br />
                Bid/s: {item.product.count_of_bids || 0}
              </div>
            )}
          </div>
        </div>

        <div className='item-info flex flex-col justify-between mt-4'>
          <div className='flex justify-between flex-col space-y-3 flex-grow'>
            <div className={`item-text ${itemStatus === 'open' ? '' : 'opacity-25'}`}>
              <div className='font-circad text-lg text-bluegray-900 font-medium mb-2 h-14'>
                <Link
                  to={`${API_ROUTES.tenders}/${tenderId}?item=${item.item_id}`}
                  onClick={(e) => {
                    if (e.nativeEvent.which === 1) {
                      e.preventDefault()
                      openItemPopup()
                    }
                  }}
                  className='title flex text-left'
                >
                  {item.product.title}
                </Link>
              </div>
              <div className='price text-lg font-semibold flex items-end justify-between h-12 text-bluegray-600 '>
                <div>
                  <div className='rap-list-price rap text-sm text-bluegray-400'>
                    {item.product.rap_list_price ? `${convertToDollars(item.product.rap_list_price)} RAP` : null}
                  </div>
                  {item.product.asking_price_per_carat && !isCatalog && (
                    <div className='price-per-carat'>
                      {`${convertToDollars(item.product.asking_price_per_carat)}/ct`}

                      {item.product.rap_list_price_discount && (
                        <span className='rap-list-price-discount'>
                          <span className='mx-1'>|</span>
                          {`${item.product.rap_list_price_discount}%`}
                        </span>
                      )}
                    </div>
                  )}
                </div>
                <div className='flex flex-row gap-2'>
                  {((gallery.data.atLeastOneRecommended && item.in_recommendations) || recMode) && (
                    <button
                      type='button'
                      disabled={!recMode}
                      onClick={() => {
                        addToRecommendedAction(item.in_recommendations, userData.companyId!, item.item_id, dispatch)
                      }}
                      className='recommendations text-yellow-500 text-lg disabled:opacity-100'
                    >
                      <IconStar isActive={item.in_recommendations} />
                    </button>
                  )}
                  <button
                    type='button'
                    onClick={() => addToFavoritesAction(item.in_favorites, tenderId, item.item_id, dispatch)}
                    className='favorites text-red-500 text-lg'
                  >
                    <IconLike isActive={item.in_favorites} />
                  </button>
                </div>
              </div>
            </div>
            {!isCatalog && (
              <ItemCardStatus
                item={item}
                tenderId={tenderId}
                setPurchasePopupActive={setPurchasePopupActive}
                openItemPopup={() => openItemPopup()}
              />
            )}
          </div>
        </div>
      </div>
      {purchasePopupActive && (
        <PurchasePopup
          setPurchasePopupActive={setPurchasePopupActive}
          purchasePopupActive={purchasePopupActive}
          item={item}
          tenderId={tenderId}
        />
      )}
    </div>
  )
}
