/* eslint-disable */
/* global $ */
// import Hammer from 'hammerjs';
import React from 'react'
import 'xzoom/dist/xzoom.min.css'
import 'xzoom/dist/xzoom.min.js'

export const useZoom = (skip = false) => {
  const [hideControls] = React.useState(false)
  React.useEffect(() => {
    if (skip) return null
    $(document).ready(function() {
      const itms = $('.zm')

      const rowEls = []

      itms.each((i) => {
        $(`.xzoom-${i + 1}, .xzoom-gallery`).xzoom({
          zoomWidth: 400,
          title: true,
          tint: '#333',
          Xoffset: 15,
        })
        rowEls.push(`.xzoom-${i + 1}`)
      })

      const newClasses = rowEls.join(', ')

      $('.xzoom, .xzoom-gallery').xzoom({
        zoomWidth: 400,
        title: true,
        tint: '#333',
        Xoffset: 15,
      })
      $('.xzoom115, .xzoom-gallery').xzoom({
        zoomWidth: 400,
        title: true,
        tint: '#333',
        Xoffset: 15,
      })
      $('.xzoom1, .xzoom-gallery').xzoom({
        zoomWidth: 400,
        title: true,
        tint: '#333',
        Xoffset: 15,
      })
      $('.xzoom2, .xzoom-gallery2').xzoom({ position: '#xzoom2-id', tint: '#ffa200' })

      $('.xzoom3, .xzoom-gallery3').xzoom({
        position: 'lens',
        lensShape: 'circle',
        sourceClass: 'xzoom-hidden',
      })

      $('.xzoom4, .xzoom-gallery4').xzoom({ tint: '#006699', Xoffset: 15 })

      $('.xzoom5, .xzoom-gallery5').xzoom({ tint: '#006699', Xoffset: 15 })

      // Integration with hammer.js
      const isTouchSupported = 'ontouchstart' in window

      if (isTouchSupported) {
        // If touch device
        $(`.xzoom, .xzoom2, .xzoom3, .xzoom4, .xzoom5, .xzoom115, ${newClasses}`).each(function() {
          const xzoom = $(this).data('xzoom')

          xzoom.eventunbind()
        })

        $(`.xzoom, .xzoom115, .xzoom2, .xzoom3, ${newClasses}`).each(function() {
          const xzoom = $(this).data('xzoom')
          $(this)
            .hammer()
            .on('tap', function(event) {
              event.pageX = event.gesture.center.pageX
              event.pageY = event.gesture.center.pageY
              // const s = 1,
              //   ls;

              xzoom.eventmove = function(element) {
                element.hammer().on('drag', function(event) {
                  event.pageX = event.gesture.center.pageX
                  event.pageY = event.gesture.center.pageY
                  xzoom.movezoom(event)
                  event.gesture.preventDefault()
                })
              }

              xzoom.eventleave = function(element) {
                element.hammer().on('tap', function(event) {
                  xzoom.closezoom()
                })
              }
              xzoom.openzoom(event)
            })
        })

        $('.xzoom4').each(function() {
          const xzoom = $(this).data('xzoom')
          $(this)
            .hammer()
            .on('tap', function(event) {
              event.pageX = event.gesture.center.pageX
              event.pageY = event.gesture.center.pageY

              xzoom.eventmove = function(element) {
                element.hammer().on('drag', function(event) {
                  event.pageX = event.gesture.center.pageX
                  event.pageY = event.gesture.center.pageY
                  xzoom.movezoom(event)
                  event.gesture.preventDefault()
                })
              }

              let counter = 0
              xzoom.eventclick = function(element) {
                element.hammer().on('tap', function() {
                  counter += 1
                  if (counter === 1) setTimeout(openfancy, 300)
                  event.gesture.preventDefault()
                })
              }

              function openfancy() {
                if (counter === 2) {
                  xzoom.closezoom()
                  $.fancybox.open(xzoom.gallery().cgallery)
                } else {
                  xzoom.closezoom()
                }
                counter = 0
              }

              xzoom.openzoom(event)
            })
        })

        $('.xzoom5').each(function() {
          const xzoom = $(this).data('xzoom')
          $(this)
            .hammer()
            .on('tap', function(event) {
              event.pageX = event.gesture.center.pageX
              event.pageY = event.gesture.center.pageY

              xzoom.eventmove = function(element) {
                element.hammer().on('drag', function(event) {
                  event.pageX = event.gesture.center.pageX
                  event.pageY = event.gesture.center.pageY
                  xzoom.movezoom(event)
                  event.gesture.preventDefault()
                })
              }

              let counter = 0
              xzoom.eventclick = function(element) {
                element.hammer().on('tap', function() {
                  counter += 1
                  if (counter === 1) setTimeout(openmagnific, 300)
                  event.gesture.preventDefault()
                })
              }

              function openmagnific() {
                if (counter === 2) {
                  xzoom.closezoom()
                  const gallery = xzoom.gallery().cgallery
                  let i
                  const images = []
                  for (i in gallery) {
                    images[i] = { src: gallery[i] }
                  }
                  $.magnificPopup.open({
                    items: images,
                    type: 'image',
                    gallery: { enabled: true },
                  })
                } else {
                  xzoom.closezoom()
                }
                counter = 0
              }

              xzoom.openzoom(event)
            })
        })
      } else {
        // If not touch device

        // Integration with fancybox plugin
        $('#xzoom-fancy').bind('click', function(event) {
          const xzoom = $(this).data('xzoom')
          xzoom.closezoom()
          $.fancybox.open(xzoom.gallery().cgallery, {
            padding: 0,
            helpers: { overlay: { locked: false } },
          })
          event.preventDefault()
        })

        // Integration with magnific popup plugin
        $('#xzoom-magnific').bind('click', function(event) {
          const xzoom = $(this).data('xzoom')
          xzoom.closezoom()
          const gallery = xzoom.gallery().cgallery
          let i
          const images = []
          for (i in gallery) {
            images[i] = { src: gallery[i] }
          }
          $.magnificPopup.open({ items: images, type: 'image', gallery: { enabled: true } })
          event.preventDefault()
        })
      }
    })
  }, [skip])
  return {
    hideControls,
  }
}
