export interface TRoles {
  role_id: number,
  title: string
}

export interface TRolesMessage {
  message: string
}

export interface TRolesState {
  loading: boolean
  data: TRoles[] | null
  errors?: string | undefined
}

export enum ActionTypes {
  GET_ROLES_R = '@@ROLES/GET_ROLES_R',
  GET_ROLES_S = '@@ROLES/GET_ROLES_S',
  GET_ROLES_E = '@@ROLES/GET_ROLES_E',
  
}