import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ROUTES } from '../utils/routes'

export const C = () => {
  const { tenderId } = useParams<any>()

  useEffect(() => {
    window.location.href = `${ROUTES.tenders}/${tenderId}?recommendations=true`
  }, [tenderId])

  return null
}
