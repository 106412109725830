/* eslint-disable react/jsx-indent,react/jsx-curly-newline,react/jsx-one-expression-per-line */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ViewAsCustomer } from '../../ViewAsCustomer'
import { Close } from '../../../svg/Close'
import { Sort } from '../../../svg/Sort'
import { getTbody2, getThead2 } from '../../../utils/userUtils/userDataHelper'
import { AppStore } from '../../../store/applicationState'
import { addNewParams } from '../../../utils/helpers'
import { searchProps, useSearch } from '../../../hooks/useSearch'
import { SetLoader } from '../../../store/globalState/action'
import { GetCompanies } from '../../../store/admin/companies/actions'
import { getUniqueId } from '../../../utils/FileUtils'
import { LoaderPoints } from '../LoaderPoints'
import { Input } from './Input'
import { SetItemRecommendation } from '../../../store/gallery/actions'
import { TBidsByItem } from '../../../store/admin/tenders/bidsByItem/types'
import { TBody } from '../../../store/admin/companies/types'

interface Props {
  setVisible: (value: null) => void
  itemm: TBidsByItem
}

const tableCellWidth = [55, 140, 140, 300, 150]
const limitAmount = 400

export const UserRecommendByItemPopup: React.FC<Props> = ({ setVisible, itemm }) => {
  const { companies, userData } = useSelector((store: AppStore) => store)
  const dispatch = useDispatch()

  const selectedKey = useRef<{ sort: 'down' | 'up'; sortBy: string } | null>(null)
  const isRendered = useRef<any>(null)

  const initialParams = {
    limit: limitAmount,
    offset: 0,
    search: undefined,
    sort: '',
    sortBy: '',
  }

  const [params, setParams] = useState<searchProps>(initialParams)
  const [dispatchTime, setDispatchTime] = useState(0)
  const [headIsChecked, setHeadIsChecked] = useState<boolean | undefined>()
  const [bodyAreChecked, setBodyAreChecked] = useState<Set<number>>(new Set())
  useSearch(limitAmount, params, setParams, setDispatchTime, isRendered)

  const onCheckHead = (checked: boolean) => {
    setHeadIsChecked(checked)
    dispatch(
      SetItemRecommendation.request({
        companyId: companies.data!.companies!.filter((item) => !item.with_bid).map(({ id }) => id),
        idItem: itemm.item_id,
        status: checked,
      })
    )
    dispatch(SetLoader())

    if (checked) {
      setBodyAreChecked(new Set(companies.data?.companies?.filter((item) => !item.with_bid).map(({ id }) => id)))
    } else setBodyAreChecked(new Set())
  }

  useEffect(() => {
    dispatch(
      GetCompanies.request({
        offset: 0,
        limit: 1000,
        search: params.search,
        sortBy: params.sortBy,
        sort: params.sort,
        recommendation_item_id: itemm.item_id,
        category: itemm.category_id,
        without_bid: false,
        callBack: (success, data: TBody) => {
          isRendered.current = true
          if (success && data?.companies) {
            setBodyAreChecked(new Set(data.companies.filter((item) => item.in_recommendation).map((item) => item.id)))
            setHeadIsChecked(data.companies.filter((item) => !item.with_bid).every((item) => item.in_recommendation))
          } else setBodyAreChecked(new Set())
        },
      })
    )
  }, [params.sortBy, params.sort, dispatchTime])

  /// ///////////////////////////////////////////////////////////////////////////
  const callBack = (sortByValue: string) => {
    if (sortByValue === 'action' || sortByValue === 'roles_categories' || sortByValue === 'message_title') return

    let value: { sortBy: string; sort: 'ASC' | 'DESC' }
    if (sortByValue !== params.sortBy || params.sort === 'DESC') value = { sortBy: sortByValue, sort: 'ASC' }
    else value = { sortBy: sortByValue, sort: 'DESC' }

    addNewParams('sort', value.sort)
    addNewParams('sortBy', value.sortBy)

    setParams((prev) => ({ ...prev, sortBy: value.sortBy, sort: value.sort }))
  }

  const onBodyAreChecked = (companyId: number) => {
    if (bodyAreChecked.delete(companyId)) {
      setHeadIsChecked(false)
      setBodyAreChecked(bodyAreChecked)
      dispatch(SetItemRecommendation.request({ companyId: [companyId], idItem: itemm.item_id, status: false }))
    } else {
      setBodyAreChecked(bodyAreChecked.add(companyId))
      dispatch(SetItemRecommendation.request({ companyId: [companyId], idItem: itemm.item_id, status: true }))
    }
    dispatch(SetLoader())
  }

  const memorizedThead = useMemo(() => getThead2(!!headIsChecked, onCheckHead, companies), [headIsChecked, companies])

  const memorizedUsers = useMemo(() => {
    const userTableData = companies.data?.companies
      ? getTbody2(companies.data.companies, bodyAreChecked, onBodyAreChecked)
      : null

    return userTableData?.length !== 0 ? (
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      userTableData?.map(({ id, class_name, ...objProps }) => (
        <tr key={`React.Fragment${getUniqueId()}`} className={`divide-x border-b border-bluegray-200 ${class_name}`}>
          {Object.entries(objProps).map(([key, value]) => {
            const data: any = typeof value === 'string' ? value.split('|||') : value
            return (
              <td key={`td${key}`} className='relative'>
                <div style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                  {data[0] || data}
                  <div className='text-sm text-bluegray-400'>{data[1]}</div>
                </div>
              </td>
            )
          })}
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={tableCellWidth?.length || 10000} height={250} className='text-center'>
          No available items
        </td>
      </tr>
    )
  }, [userData.isAssociateAdmin, bodyAreChecked])

  return ReactDOM.createPortal(
    <div className='item-popup fixed inset-0 bg-bluegray-600 w-screen h-full overflow-y-auto overscroll-none py-4 px-8 md:px-4 z-[180]'>
      <ViewAsCustomer className='sticky -top-4 -mx-8 md:-mx-4 z-[10000]' />
      <button
        type='button'
        onClick={() => setVisible(null)}
        className='float-right sticky top-1 text-bluegray-300 cursor-pointer text-2xl p-2 -mr-8 md:-mr-4 lg:-mr-0'
      >
        <Close />
      </button>
      <div className='md:w-11/12 w-12/12 2xl:w-12/12 mx-auto pt-2 space-y-4 md:space-y-10 md:max-w-5xl'>
        <div className='relative overflow-auto mx-auto flex-col shadow-2xl-dark bg-white px-2 py-1 min-h-[calc(100vh-100px)]'>
          <div className='my-8 flex w-full flex-col mobile:flex-row relative items-center gap-4 md:full lg:w-1/3 desktop:w-1/4 lg:mr-3'>
            <div className='w-full'>
              <Input
                placeholder='Search by Company'
                value={params.search || ''}
                onChange={(e) => setParams((prev) => ({ ...prev, search: e.target.value }))}
              />
            </div>
            {!!bodyAreChecked.size && (
              <div
                style={{ height: 'auto', left: '104%' }}
                className='text-green-800 lg:absolute border whitespace-nowrap w-min border-green-200 bg-green-50 rounded-3xl py-1 px-4'
              >
                {bodyAreChecked.size} Selected
              </div>
            )}
          </div>
          <table className='w-full table-fixed border whitespace-nowrap mb-2'>
            <thead className='bg-gray-50 text-left'>
              <tr className='divide-x border-b border-bluegray-200'>
                {Object.entries(memorizedThead).map(([key, value], index) => (
                  <th
                    key={`th${key}`}
                    scope='col'
                    style={{ width: tableCellWidth[index] }}
                    className='whitespace-nowrap'
                  >
                    {typeof value === 'string' ? (
                      <button
                        type='button'
                        className={`disabled:opacity-100 ${selectedKey.current?.sortBy === key ? '!font-bold' : ''}`}
                        style={{ font: 'inherit' }}
                        onClick={() => {
                          callBack(key)
                          selectedKey.current = {
                            sort:
                              selectedKey.current?.sortBy === key && selectedKey.current?.sort === 'down'
                                ? 'up'
                                : 'down',
                            sortBy: key,
                          }
                        }}
                      >
                        {value}
                        <Sort sort={selectedKey.current?.sortBy === key ? selectedKey.current?.sort : undefined} />
                      </button>
                    ) : (
                      value
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{memorizedUsers}</tbody>
          </table>
          {companies.loading && (
            <div className='absolute left-0 right-0 bottom-6 mt-20 flex justify-center'>
              <LoaderPoints className='m-auto' />
            </div>
          )}
        </div>
      </div>
    </div>,
    document.body
  )
}
