export interface TEmailLogs {
  accepted_at: string,
  email_log: {
    created_at: string,
    email_log_id: number,
    subject: string,
    tender: {
      sales_cycle: string,
      tender_id: number
    }
  },
  email_log_recipient_id: number,
  failed_at: string,
  failed_code: number,
  failed_description: string,
  is_accepted: boolean,
  is_opened: boolean,
  is_sent: boolean,
  opened_at: string,
  sent_at: string,
  user: {
    email: string,
    user_id: number
  }
}

export interface TEmailLogsMessage {
  message: string
}

export interface TEmailLogsState {
  loading: boolean
  data: TEmailLogs[] | null
  errors?: string | undefined
}

export enum ActionTypes {
  GET_ALL_EMAIL_LOGS_R = '@@CMS/GET_ALL_EMAIL_LOGS_R',
  GET_ALL_EMAIL_LOGS_S = '@@CMS/GET_ALL_EMAIL_LOGS_S',
  GET_ALL_EMAIL_LOGS_WITH_0_OFFSET_S = '@@admin/GET_ALL_EMAIL_LOGS_WITH_0_OFFSET_S',
  GET_ALL_EMAIL_LOGS_E = '@@CMS/GET_ALL_EMAIL_LOGS_E',
}
