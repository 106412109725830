import { Actions } from '../../../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../../../types/actions'

/// ///////////////////////////////////////////////////////////////////////
const GetBidsByItemActions = new Actions('GET_BIDS_BY_ITEM', ActionTypes)
const GetBidsByItemWith0offsetActions = new Actions('GET_BIDS_BY_ITEM_WITH_0_OFFSET', ActionTypes)

export interface TypeGetBidsByItemR extends TDefRequest {
  tenderId: number
  offset?: number
  limit?: number
  search?: string
  sortBy?: string
  sort?: string
  idCompanyRecommendations?: number
}

export const GetBidsByItem = {
  request: (payload: TypeGetBidsByItemR) => GetBidsByItemActions.request(payload),
  success: (payload: any) => GetBidsByItemActions.success(payload),
  error: (payload: string) => GetBidsByItemActions.error(payload),
}

export const GetBidsByItemWith0Offset = {
  success: (payload: any) => GetBidsByItemWith0offsetActions.success(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetBidsByItemChildActions = new Actions('GET_BIDS_BY_ITEM_CHILD', ActionTypes)

export interface TypeGetBidsByItemChildR extends TDefRequest {
  idItem: number
}

export const GetBidsByItemChild = {
  request: (payload: TypeGetBidsByItemChildR) => GetBidsByItemChildActions.request(payload),
  success: (payload: any) => GetBidsByItemChildActions.success(payload),
  error: (payload: string) => GetBidsByItemChildActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetBidsByItemForTenderCloseActions = new Actions('GET_BIDS_BY_ITEM_FOR_TENDER_CLOSE', ActionTypes)

export interface TypeGetBidsByItemForTenderCloseR extends TDefRequest {
  tenderId: number
  offset?: number
  limit?: number
  search?: string
  sortBy?: string
  sort?: string
}

export const GetBidsByItemForTenderClose = {
  request: (payload: TypeGetBidsByItemForTenderCloseR) => GetBidsByItemForTenderCloseActions.request(payload),
  success: (payload: any) => GetBidsByItemForTenderCloseActions.success(payload),
  error: (payload: string) => GetBidsByItemForTenderCloseActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetBidsByItemForTenderCloseChildActions = new Actions('GET_BIDS_BY_ITEM_FOR_TENDER_CLOSE_CHILD', ActionTypes)

export interface TypeGetBidsByItemForTenderCloseChildR extends TDefRequest {
  idItem: number
}

export const GetBidsByItemForTenderCloseChild = {
  request: (payload: TypeGetBidsByItemForTenderCloseChildR) => GetBidsByItemForTenderCloseChildActions.request(payload),
  success: (payload: any) => GetBidsByItemForTenderCloseChildActions.success(payload),
  error: (payload: string) => GetBidsByItemForTenderCloseChildActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetTenderStatsForBidsByItemActions = new Actions('GET_TENDER_STATS_FOR_BIDS_BY_ITEM', ActionTypes)

export interface TypeGetTenderStatsForBidsByItemR extends TDefRequest {
  idTender: number
}

export const GetTenderStatsForBidsByItem = {
  request: (payload: TypeGetTenderStatsForBidsByItemR) => GetTenderStatsForBidsByItemActions.request(payload),
  success: (payload: any) => GetTenderStatsForBidsByItemActions.success(payload),
  error: (payload: string) => GetTenderStatsForBidsByItemActions.error(payload),
}
