/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { TCustomerAddress } from '../../../../store/admin/companies/types'
import { ItemAddress } from './ItemAddress'
import { AppStore } from '../../../../store/applicationState'
import { GetNSCountries } from '../../../../store/admin/companiesNS/actions'
import { ToggleAddressForm } from '../../../../store/addressFormsState/actions'

interface AddressBlockProps {
  addressList: TCustomerAddress[]
  setAddressList: (arr: TCustomerAddress[]) => void
  addNewItem: () => void
  companyNSName: string
}

interface CountryOption {
  value: string
  label: string
  states?: { value: string; label: string }[]
}

export const AddressBlock: React.FC<AddressBlockProps> = ({ 
  addressList,
  setAddressList,
  addNewItem,
  companyNSName,
  }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(GetNSCountries.request({}))
  }, [])

  const { adminCompaniesNS, addressFormsState } = useSelector((store: AppStore) => store)
  const [countryOptions, setCountryOptions] = useState<CountryOption[]>([])

  useEffect(() => {
    if (adminCompaniesNS.countries) {
      const options = adminCompaniesNS?.countries.map((country: any) => ({
        value: country.SchemaEnumeration,
        label: country.Name,
        states: country.states
          ? country.states.map((state: any) => ({
              value: state.Name,
              label: state.Name,
            }))
          : [],
      }))

      setCountryOptions(options)
    }
  }, [adminCompaniesNS.countries])

  const onInputValueChange = (updatedAddress: TCustomerAddress, index: number) => {
    const newArr = addressList.map((item, i) => (i === index ? updatedAddress : item))
    setAddressList(newArr)
  }

  const handleCountryInputChange = (inputValue: string) => {
    dispatch(GetNSCountries.request({ search: inputValue }))
  }

  const handleDeleteAddress = (index: number) => {
    const newAddressList = addressList.filter((_, i) => i !== index)
    setAddressList(newAddressList)
  }

  const isAnyFormExpanded = addressFormsState?.addressForms?.some((form) => form) || false

  const toggleForm = (index: number, isExpanded: boolean) => {
    dispatch(ToggleAddressForm(index, isExpanded))
  }

  return (
    <div className='form-company-block-second mt-6 lg:mt-0 lg:ml-2'>
      <div className='font-circa text-2xl font-normal pb-4'>Address *</div>
      <div className='address-list-container'>
        {addressList?.map((addressItem, index) => (
          <ItemAddress
            key={`addressItem${index * 2}`}
            addressItem={addressItem}
            country={countryOptions}
            index={index}
            onInputValueChange={onInputValueChange}
            onCountryInputChange={handleCountryInputChange}
            onDeleteAddress={() => handleDeleteAddress(index)}
            toggleForm={toggleForm}
            isExpandedForm={addressFormsState?.addressForms?.[index] || false}
            companyNSName={companyNSName}
          />
        ))}
      </div>
      <div className='flex justify-end pr-2.5'>
        {(addressList.length === 0 || !isAnyFormExpanded) && (
        <div className='flex justify-end pr-2.5'>
          <button onClick={addNewItem} className=' text-white bg-green-700 hover:bg-green-800 px-5 py-2 rounded mb-2.5 mt-auto items-end' type='button'>
            Add new
          </button>
        </div>
        )}
      </div>
    </div>
  )
}
