import React from 'react'
import { TGalleryItemInfo } from '../../../../store/gallery/types'

interface WatchesAdditionalDetailsProps {
  item: TGalleryItemInfo;
}

export const WatchesAdditionalDetails: React.FC<WatchesAdditionalDetailsProps> = ({ item }) => {
  const fields = [
    { value: item.product.sku, title: 'SKU' },
    { value: item.product.product_details.case_size, title: 'Case Size (mm)' },
    { value: item.product.product_details.case_material, title: 'Case Material' },
    { value: item.product.product_details.bracelet_strap, title: 'Bracelet Strap' },
    { value: item.product.product_details.bezel, title: 'Bezel' },
    { value: item.product.product_details.dial_color, title: 'Dial Color' },
    { value: item.product.product_details.be_crystal, title: 'Crystal' },
    { value: item.product.product_details.crystal_condition, title: 'Crystal Condition' },
    { value: item.product.product_details.link_count, title: 'Link Count' },
    { value: item.product.product_details.screw_count, title: 'Screw Count' },
    { value: item.product.product_details.case_back, title: 'Case Back' },
    { value: item.product.product_details.clasp_type, title: 'Clasp Type' },
    { value: item.product.product_details.papers_type, title: 'Warranty Type' },
    { value: item.product.product_details.movement_type, title: 'Movement Type' },
  ]
  return (
    <div className="">
      <div className="font-semibold text-base mb-3">
        Additional Details
      </div>

      {fields.map(field => {
        if (field.value) {
          return (
            <div className="additional-details flex space-x-2 items-center border-b border-bluegray-100 w-8/12 py-0.5" key={field.title}>
              <div className="font-semibold">
                {`${field.title}:`}
              </div>
      
              <div className="text-bluegray-600">
                {field.value}
              </div>
            </div>
          )
        }

        return null
      })}
    </div>
  )
}
