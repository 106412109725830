import React from 'react'

export const Users = () => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-prefix='fad'
      data-icon='users'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 640 512'
      className='svg-inline--fa fa-users fa-w-20 fa-3x'
    >
      <g className='fa-group'>
        <path
          fill="rgb(156, 163, 175)"
          d='M96 224a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64zm480 32h-64a63.81 63.81 0 0 0-45.1 18.6A146.27 146.27 0 0 1 542 384h66a32 32 0 0 0 32-32v-32a64.06 64.06 0 0 0-64-64zm-512 0a64.06 64.06 0 0 0-64 64v32a32 32 0 0 0 32 32h65.9a146.64 146.64 0 0 1 75.2-109.4A63.81 63.81 0 0 0 128 256zm480-32a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64z'
          className='fa-secondary'
        />
        <path
          fill='currentColor'
          d='M396.8 288h-8.3a157.53 157.53 0 0 1-68.5 16c-24.6 0-47.6-6-68.5-16h-8.3A115.23 115.23 0 0 0 128 403.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-28.8A115.23 115.23 0 0 0 396.8 288zM320 256a112 112 0 1 0-112-112 111.94 111.94 0 0 0 112 112z'
          className='fa-primary'
        />
      </g>
    </svg>
  )
}