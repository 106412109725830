import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppStore } from '../../../store/applicationState'
import { DropDownImageManage } from '../../../components/ui/adminComponents/DropDownImageManage'
import { TTenders } from '../../../store/admin/tenders/types'
import { Table } from '../../../components/ui/adminComponents/Table'
import {
  getTenderItemsForDeleteChildParsed,
  getTenderItemsForDeleteParsed,
} from '../../../utils/tendersUtils/tenderDataHelper'
import {
  DeleteAllImages,
  DeleteOneImage,
  GetTenderGalleryList,
} from '../../../store/admin/settings/mediaManagement/actions'
import { SetLoader } from '../../../store/globalState/action'

const deleteImageThead = {
  image: 'Main media',
  sku: 'SKU',
  brand: 'Item',
  media: '# Media',
  action: 'Actions',
}

const deleteImageTheadChild = {
  image_preview: 'Media preview',
  image_name: 'Media name',
  handle: 'Handle',
  action: 'Actions',
}

const tableCellWidth = [155, 140, 260, 60, 100]

export const DeleteImage = () => {
  const dispatch = useDispatch()
  const { mediaManagement } = useSelector((store: AppStore) => store)

  const [selectedTender, setSelectedTender] = useState<TTenders | null>(null)
  const [childIndex, setChildIndex] = useState<number[]>([])

  useEffect(() => {
    if (selectedTender) dispatch(GetTenderGalleryList.request({ idTender: selectedTender.id }))
  }, [selectedTender])

  /// //////////////////////////////////////////////////////////////////////////////////
  const onDeleteImg = (id: number) => {
    if (selectedTender) {
      dispatch(SetLoader())
      dispatch(
        DeleteOneImage.request({
          idTender: selectedTender?.id,
          idImage: id,
          callBack: (success) => {
            if (success) dispatch(GetTenderGalleryList.request({ idTender: selectedTender.id }))
          },
        })
      )
    }
  }

  const onDeleteAll = (id: number) => {
    if (selectedTender) {
      dispatch(SetLoader())
      dispatch(
        DeleteAllImages.request({
          idTender: selectedTender?.id,
          idItem: id,
          callBack: (success) => {
            if (success) dispatch(GetTenderGalleryList.request({ idTender: selectedTender.id }))
          },
        })
      )
    }
  }

  const tenderItemsForDelete = useMemo(() => {
    return mediaManagement.data.galleryList
      ? getTenderItemsForDeleteParsed(mediaManagement.data.galleryList, childIndex)
      : null
  }, [mediaManagement.data.galleryList, childIndex])

  const tenderItemsForDeleteChild = useMemo(() => {
    if (!mediaManagement.data.galleryList) return null
    const result = new Map<number, object[]>()
    mediaManagement.data.galleryList.forEach((value) => {
      if (value.gallery_response)
        result.set(value.item_id, getTenderItemsForDeleteChildParsed(value.gallery_response, onDeleteImg))
      else result.set(value.item_id, [])
    })
    return result
  }, [mediaManagement.data.galleryList])

  return (
    <>
      <DropDownImageManage tenderId={selectedTender?.id || 0} setTenderId={setSelectedTender} />
      <div className='mt-8' />
      {selectedTender && (
        <Table
          thead={deleteImageThead}
          tbody={tenderItemsForDelete}
          theadChild={deleteImageTheadChild}
          tbodyChild={tenderItemsForDeleteChild}
          childIndex={childIndex}
          setChildIndex={setChildIndex}
          tableCellWidth={tableCellWidth}
          styledReserveNotMet
          onDeleteAll={onDeleteAll}
        />
      )}
    </>
  )
}
