export interface TMessageForTemplates {
  active: boolean
  body: string
  created_at: string
  footer: string
  have_catalog: boolean
  have_ical: boolean
  message_id: number
  message_method_id: number
  messaging_template_id: number
  subject: string
  updated_at: string
}

export interface TCmsTemplates {
  active: boolean
  created_at: string
  have_schedule: boolean
  messages: TMessageForTemplates[]
  messaging_template_id: number
  preferred_delivery_method: boolean
  schedule: number
  title: string
  updated_at: string
}

export interface TCmsMessage {
  message: string
}

export interface TCmsState {
  loading: boolean
  data: TCmsTemplates[] | null
  currentTemplateMessages: {
    id: number | null
    data: TMessageForTemplates[] | null
  }
  currentUsersId: number[] | null
  errors?: string | undefined
}

export enum ActionTypes {
  GET_ALL_CSM_TEMPLATES_R = '@@CMS/GET_ALL_CSM_TEMPLATES_R',
  GET_ALL_CSM_TEMPLATES_S = '@@CMS/GET_ALL_CSM_TEMPLATES_S',
  GET_ALL_CSM_TEMPLATES_E = '@@CMS/GET_ALL_CSM_TEMPLATES_E',

  GET_ALL_CSM_MESSAGES_BY_ID_R = '@@CMS/GET_ALL_CSM_MESSAGES_BY_ID_R',
  GET_ALL_CSM_MESSAGES_BY_ID_S = '@@CMS/GET_ALL_CSM_MESSAGES_BY_ID_S',
  GET_ALL_CSM_MESSAGES_BY_ID_E = '@@CMS/GET_ALL_CSM_MESSAGES_BY_ID_E',

  EDIT_TEMPLATE_R = '@@CMS/EDIT_TEMPLATE_R',
  EDIT_TEMPLATE_S = '@@CMS/EDIT_TEMPLATE_S',
  EDIT_TEMPLATE_E = '@@CMS/EDIT_TEMPLATE_E',

  EDIT_MESSAGE_R = '@@CMS/EDIT_MESSAGE_R',
  EDIT_MESSAGE_S = '@@CMS/EDIT_MESSAGE_S',
  EDIT_MESSAGE_E = '@@CMS/EDIT_MESSAGE_E',

  SEND_MESSAGE_TO_USERS_R = '@@admin/SEND_MESSAGE_TO_USERS_R',
  SEND_MESSAGE_TO_USERS_S = '@@admin/SEND_MESSAGE_TO_USERS_S',
  SEND_MESSAGE_TO_USERS_E = '@@admin/SEND_MESSAGE_TO_USERS_E',

  // UPLOAD_ATTACHMENT_R = '@@admin/UPLOAD_ATTACHMENT_R',
  // UPLOAD_ATTACHMENT_S = '@@admin/UPLOAD_ATTACHMENT_S',
  // UPLOAD_ATTACHMENT_E = '@@admin/UPLOAD_ATTACHMENT_E',

  SET_CURRENT_USERS_ADMIN = '@@admin/SET_CURRENT_USERS_ADMIN',
}
