import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ResultsTable } from '../../components/ui/tenderResults/ResultsTable'
import { AppStore } from '../../store/applicationState'
import { GetBidsWon, GetPurchaseHistory } from '../../store/tenderResults/actions'

interface MyAcquisitionProps {}

export const MyAcquisition: React.FC<MyAcquisitionProps> = () => {
  const [sortByParamsBid, setSortByParamsBid] = useState('')
  const [sortParamsBid, setSortParamsBid] = useState('')

  const [sortByParamsPurchase, setSortByParamsPurchase] = useState('')
  const [sortParamsPurchase, setSortParamsPurchase] = useState('')

  const { tenderResults } = useSelector((store: AppStore) => store)
  const dispatch = useDispatch()

  useEffect(() => {
    const idTender = Number(window.location.pathname.split('/').find((elem) => Number(elem))) || 0
    dispatch(GetBidsWon.request({ idTender, sort: sortParamsBid || '', sortBy: sortByParamsBid || '' }))
  }, [sortByParamsBid, sortParamsBid])

  useEffect(() => {
    const idTender = Number(window.location.pathname.split('/').find((elem) => Number(elem))) || 0
    dispatch(
      GetPurchaseHistory.request({ idTender, sort: sortParamsPurchase || '', sortBy: sortByParamsPurchase || '' })
    )
  }, [sortByParamsPurchase, sortParamsPurchase])

  return (
    <div>
      <div className='buy-now-table mt-8'>
        <div className='text-white bg-lightblue-700 text-xl py-2 px-4 font-semibold font-circa'>Buy now</div>

        {tenderResults.data.purchaseHistory?.length ? (
          <ResultsTable
            itemsInfo={tenderResults.data.purchaseHistory}
            tableType='purchaseHistory'
            showStatus={false}
            sortByParams={sortByParamsPurchase}
            setSortByParams={setSortByParamsPurchase}
            sortParams={sortParamsPurchase}
            setSortParams={setSortParamsPurchase}
          />
        ) : (
          <div className='table-text w-full py-3 pl-8'>No items purchased</div>
        )}
      </div>

      <div className='bids-won-table mt-8 pb-12'>
        <div className='text-white bg-lightblue-700 text-xl py-2 px-4 font-semibold font-circa'>Bids won</div>

        {tenderResults.data.bidsWon?.length ? (
          <ResultsTable
            itemsInfo={tenderResults.data.bidsWon}
            tableType='bidsWon'
            showStatus={false}
            sortByParams={sortByParamsBid}
            setSortByParams={setSortByParamsBid}
            sortParams={sortParamsBid}
            setSortParams={setSortParamsBid}
          />
        ) : (
          <div className='table-text w-full py-3 pl-8'>No items won</div>
        )}
      </div>
    </div>
  )
}
