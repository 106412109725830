import { Actions } from '../../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../../types/actions'


/// ///////////////////////////////////////////////////////////////////////
const GetAllTendersActions = new Actions('GET_ALL_TENDERS', ActionTypes)

export interface TypeGetAllTendersR extends TDefRequest {
  search?: string
}

export const GetAllTenders = {
  request: (payload?: TypeGetAllTendersR) => GetAllTendersActions.request(payload || {}),
  success: (payload: any) => GetAllTendersActions.success(payload),
  error: (payload: string) => GetAllTendersActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
const ChangeTendersScheduleActions = new Actions('CHANGE_TENDER_SCHEDULE', ActionTypes)

export interface TypeChangeTendersScheduleR extends TDefRequest {
  tender_id: number,
  body: {
    finalized_epoch: number,
    preview_start_epoch: number,
    start_epoch: number,
  }
}

export const ChangeTendersSchedule = {
  request: (payload?: TypeChangeTendersScheduleR) => ChangeTendersScheduleActions.request(payload || {}),
  success: (payload: any) => ChangeTendersScheduleActions.success(payload),
  error: (payload: string) => ChangeTendersScheduleActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
export const SocketActionOccurred = () => ({ type: ActionTypes.SOCKET_ACTION_OCCURRED })
export const SocketActionOccurredReset = () => ({ type: ActionTypes.SOCKET_ACTION_OCCURRED_RESET })

export const UpdateTenderNow = (payload: boolean) => ({ type: ActionTypes.UPDATE_TENDER_NOW, payload })

/// ///////////////////////////////////////////////////////////////////////


export const changeStatusSalesOrdersAndInvoices = (payload: {id: number, sales_orders_and_invoices_processing_status: string}) => ({ type: ActionTypes.CHANGE_STATUS_SALES_ORDERS_AND_INVOICES, payload })


/// ///////////////////////////////////////////////////////////////////////
