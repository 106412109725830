import { Reducer } from 'redux'
import { ActionTypes, TBuyNowPurchases, TBuyNowPurchasesState } from './types'
import { getDataForPagination } from '../../../../utils/reducerUtils'


const changeStatusSalesOrdersAndInvoicesBuyNow = (data: TBuyNowPurchases[] | null, payload: any) => {
  const { ids, ns_processing_status, error_msg } = payload

  const newData: TBuyNowPurchases[] | null = data?.map((purchase: TBuyNowPurchases) => {
    if (ids.includes(purchase.item_id)) {
      return { ...purchase, ns_processing_status, ns_err_msg: error_msg }
    }
    return purchase
  }) || null

  return newData
}

export const initialState: TBuyNowPurchasesState = {
  loading: false,
  data: null,
  tenderStats: null,
  errors: ''
}

const reducer: Reducer<TBuyNowPurchasesState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_BUY_NOW_PURCHASES_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_BUY_NOW_PURCHASES_S:
      return { ...state, loading: false, errors: undefined, data: getDataForPagination(state.data, action.payload) }
    case ActionTypes.GET_BUY_NOW_PURCHASES_WITH0_OFFSET_S:
      return { ...state, loading: false, errors: undefined, data: action.payload }
    case ActionTypes.GET_BUY_NOW_PURCHASES_E:
      return { ...state, loading: false, errors: action.payload, data: [] }
    
    case ActionTypes.GET_TENDER_STATS_FOR_BUY_NOW_PURCHASES_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_TENDER_STATS_FOR_BUY_NOW_PURCHASES_S:
      return { ...state, loading: false, errors: undefined, tenderStats: action.payload }
    case ActionTypes.GET_TENDER_STATS_FOR_BUY_NOW_PURCHASES_E:
      return { ...state, loading: false, errors: action.payload, tenderStats: null }

    case ActionTypes.MAKE_SALES_ORDERS_AND_INVOICES_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.MAKE_SALES_ORDERS_AND_INVOICES_S:
      return { ...state, loading: false, errors: undefined }
    case ActionTypes.MAKE_SALES_ORDERS_AND_INVOICES_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.CHANGE_STATUS_SALES_ORDERS_AND_INVOICES_BUY_NOW:
      return { ...state, data: changeStatusSalesOrdersAndInvoicesBuyNow(state.data, action.payload) }  
    
    default:
      return state
  }
}

export { reducer as BuyNowPurchasesReducer }