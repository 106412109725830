/* eslint-disable */
import jwt_decode from 'jwt-decode'
import * as Sentry from '@sentry/react'
import { API_ROUTES } from './apiRoutes'
import { callApi } from './callApi'
import { TOKEN } from './consts'
import { deleteAuthCookie, deleteCookie, getCookie, setCookie } from './cookiesUtils'

export interface a0ccessTokenType {
  expires_at?: number
  created_at?: number
  user_data?: {
    company_id?: number
    is_terms_confirmed?: boolean
    user_id?: number
    user_uuid?: string
    view_like_a_user_id?: number
    permissions?: {
      id_of_admin_role?: number
      ids_of_full_access_categories?: number[]
      ids_of_view_only_categories?: number[]
    }
  }
}

export interface newAccessTokenType {
  created_at?: number
  exp?: number
  session_exp_at?: number
  at_id?: string
  authorized?: boolean
  company_id?: number
  is_terms_confirmed?: true
  permissions?: {
    id_of_admin_role?: number
    ids_of_full_access_categories?: number[]
    ids_of_view_only_categories?: number[]
  }
  session_uuid?: string
  user_id?: number
  user_uuid?: string
  view_like_a_user_id?: number
}

export interface ReturnType {
  usrId: string
  success: boolean
  token: string
  is_terms_confirmed?: boolean
  idOfAdminRole?: number
  fullAccessCategories?: number[]
  viewOnlyCategories?: number[]
  company_id?: number
}

const cash: { [key: string]: newAccessTokenType } = {}
let refreshStarted = false
let refreshViewAsCustomerStarted = false

const returnFail = (): ReturnType => {
  localStorage.clear()
  deleteAuthCookie()
  sessionStorage.clear()
  return {
    usrId: '',
    success: false,
    token: '',
  }
}

export const getParsedAccessToken = (accessToken: string): newAccessTokenType => {
  if (cash[accessToken]) return cash[accessToken]
  const decodeToken: {
    created_at?: number
    exp?: number
    session_exp_at?: number
    at_id?: string
    authorized?: boolean
    company_id?: number
    is_terms_confirmed?: true
    permissions?: {
      id_of_admin_role?: number
      ids_of_full_access_categories?: number[]
      ids_of_view_only_categories?: number[]
    }
    session_uuid?: string
    user_id?: number
    user_uuid?: string
    view_like_a_user_id?: number
  } = jwt_decode(accessToken)

  return { ...decodeToken }
}

const refreshTokenFetch = async (refreshToken: string, decodeToken: any): Promise<ReturnType> => {
  refreshStarted = true

  try {
    const data = await callApi({
      path: API_ROUTES.refreshToken,
      method: 'post',
      refreshToken,
    })

    if (!data.access_token || !data.refresh_token) return returnFail()

    const parsedNewTokenData = getParsedAccessToken(data.access_token)

    if (!parsedNewTokenData.permissions || !parsedNewTokenData.exp || !parsedNewTokenData.user_id) {
      return returnFail()
    }

    cash[data.access_token] = parsedNewTokenData
    setCookie(TOKEN.access, data.access_token)
    setCookie(TOKEN.refresh, data.refresh_token)

    return {
      success: true,
      token: data.access_token,
      usrId: `${parsedNewTokenData.user_id}`,
      is_terms_confirmed: parsedNewTokenData.is_terms_confirmed,
      idOfAdminRole: parsedNewTokenData.permissions?.id_of_admin_role || 0,
      fullAccessCategories: parsedNewTokenData.permissions?.ids_of_full_access_categories,
      viewOnlyCategories: parsedNewTokenData.permissions?.ids_of_view_only_categories,
      company_id: parsedNewTokenData.company_id,
    }
  } catch (e: any) {
    if (e?.message === 'Network Error') return { usrId: '', success: false, token: '' }

    Sentry.captureMessage('Logout', {
      level: 'info' as Sentry.Severity,
      contexts: {
        info: {
          event: 'on call refresh',
          refresh_token: refreshToken,
          refresh_token_expire_at: new Date(decodeToken.exp * 1000),
          response_status: e?.response?.status || e?.status,
          response_data_message: e?.response?.data?.message || e?.data?.message || e?.message,
          error: JSON.stringify(e),
        },
      },
    })
    return returnFail()
  } finally {
    refreshStarted = false
  }
}

export const refresh = async (): Promise<ReturnType> => {
  deleteCookie(TOKEN.access)
  const refreshToken = getCookie(TOKEN.refresh)

  if (!refreshToken) return returnFail()

  const decodeToken: { exp?: number; created_at?: number } = jwt_decode(refreshToken)

  if (!decodeToken || !decodeToken.exp) return returnFail()

  const isExpired = Date.now() >= decodeToken.exp * 1000

  if (isExpired) return returnFail()

  if (refreshStarted) {
    return new Promise((resp) => {
      const interval = setInterval(() => {
        if (!refreshStarted) {
          const token = getCookie(TOKEN.access)
          const parsedNewTokenData = token ? getParsedAccessToken(token) : {}

          if (!parsedNewTokenData?.user_id || !parsedNewTokenData?.exp) {
            return returnFail()
          }
          resp(
            token
              ? {
                  token,
                  success: true,
                  usrId: `${parsedNewTokenData.user_id}`,
                  is_terms_confirmed: parsedNewTokenData.is_terms_confirmed,
                  idOfAdminRole: parsedNewTokenData.permissions?.id_of_admin_role || 0,
                  fullAccessCategories: parsedNewTokenData.permissions?.ids_of_full_access_categories,
                  viewOnlyCategories: parsedNewTokenData.permissions?.ids_of_view_only_categories,
                  company_id: parsedNewTokenData.company_id,
                }
              : returnFail()
          )
          clearInterval(interval)
        }
        return null
      }, 200)
    })
  }

  // if (document.hidden) {
  //   return new Promise((resp) => {
  //     let counter = 0
  //     const interval = setInterval(() => {
  //       const token = localStorage.getItem(TOKEN.access)
  //       counter += 1
  //       if (counter > 20 && !token) {
  //         clearInterval(interval)
  //         return refreshTokenFetch(refreshToken, decodeToken)
  //       }
  //
  //       if (token) {
  //         const parsedNewTokenData = getParsedAccessToken(token)
  //
  //         if (!parsedNewTokenData?.user_data || !parsedNewTokenData?.expires_at) return returnFail()
  //
  //         resp(
  //           token
  //             ? {
  //                 token,
  //                 success: true,
  //                 usrId: `${parsedNewTokenData.user_data.user_id}`,
  //                 is_terms_confirmed: parsedNewTokenData.user_data.is_terms_confirmed,
  //                 idOfAdminRole: parsedNewTokenData.user_data.permissions?.id_of_admin_role || 0,
  //                 fullAccessCategories: parsedNewTokenData.user_data.permissions?.ids_of_full_access_categories,
  //                 viewOnlyCategories: parsedNewTokenData.user_data.permissions?.ids_of_view_only_categories,
  //               }
  //             : returnFail()
  //         )
  //         clearInterval(interval)
  //       }
  //       return null
  //     }, 200)
  //   })
  // }

  return refreshTokenFetch(refreshToken, decodeToken)
}

export const refreshViewAsCustomerToken = async (parsedToken: newAccessTokenType): Promise<ReturnType> => {
  const userToken = await refresh()
  if (!userToken.success) return returnFail()

  if (refreshViewAsCustomerStarted) {
    return new Promise((resp) => {
      const interval = setInterval(() => {
        if (!refreshViewAsCustomerStarted) {
          const token = sessionStorage.getItem(TOKEN.access)
          const parsedNewTokenData = token ? getParsedAccessToken(token) : {}

          if (!parsedNewTokenData?.user_id || !parsedNewTokenData?.exp) return returnFail()

          resp(
            token
              ? {
                  token,
                  success: true,
                  usrId: `${parsedNewTokenData.user_id}`,
                  is_terms_confirmed: parsedNewTokenData.is_terms_confirmed,
                  idOfAdminRole: parsedNewTokenData.permissions?.id_of_admin_role || 0,
                  fullAccessCategories: parsedNewTokenData.permissions?.ids_of_full_access_categories,
                  viewOnlyCategories: parsedNewTokenData.permissions?.ids_of_view_only_categories,
                  company_id: parsedNewTokenData.company_id,
                }
              : returnFail()
          )
          clearInterval(interval)
        }
        return null
      }, 200)
    })
  }

  refreshViewAsCustomerStarted = true
  sessionStorage.removeItem(TOKEN.access)

  try {
    const data = await callApi({
      path: `${API_ROUTES.adminGetTokensForViewLikeAUser}/${parsedToken?.user_id}/view-like-a-user`,
      method: 'get',
    })

    if (!data.access_token) return returnFail()

    const parsedNewTokenData = getParsedAccessToken(data.access_token)

    if (!parsedNewTokenData.exp || !parsedNewTokenData.user_id || !parsedNewTokenData.permissions) return returnFail()

    cash[data.access_token] = parsedNewTokenData
    sessionStorage.setItem(TOKEN.access, data.access_token)

    return {
      success: true,
      token: data.access_token,
      usrId: `${parsedNewTokenData.user_id}`,
      is_terms_confirmed: parsedNewTokenData.is_terms_confirmed,
      idOfAdminRole: parsedNewTokenData.permissions?.id_of_admin_role || 0,
      fullAccessCategories: parsedNewTokenData.permissions?.ids_of_full_access_categories,
      viewOnlyCategories: parsedNewTokenData.permissions?.ids_of_view_only_categories,
      company_id: parsedNewTokenData.company_id,
    }
  } catch (e) {
    return returnFail()
  } finally {
    refreshViewAsCustomerStarted = false
  }
}

export async function checkToken(fromLocalStorage?: boolean): Promise<ReturnType> {
  const accessTokenForViewAsCustomer = sessionStorage.getItem(TOKEN.access)
  const accessToken = fromLocalStorage
    ? getCookie(TOKEN.access)
    : accessTokenForViewAsCustomer || getCookie(TOKEN.access)

  if (!accessToken) return refresh()

  const parsedToken = getParsedAccessToken(accessToken)

  if (!parsedToken.user_id || !parsedToken.exp || !parsedToken.permissions) {
    return returnFail()
  }

  const isExpired: boolean = accessTokenForViewAsCustomer
    ? Date.now() >= parsedToken.exp * 1000 - 30 * 1000
    : Date.now() >= parsedToken.exp * 1000

  if (isExpired) {
    delete cash[accessToken]
    return accessTokenForViewAsCustomer ? refreshViewAsCustomerToken(parsedToken) : refresh()
  }

  if (!cash[accessToken]) cash[accessToken] = parsedToken

  return {
    success: true,
    token: accessToken,
    usrId: `${parsedToken.user_id}`,
    company_id: parsedToken?.company_id,
    is_terms_confirmed: parsedToken.is_terms_confirmed,
    idOfAdminRole: parsedToken.permissions?.id_of_admin_role || 0,
    fullAccessCategories: parsedToken.permissions?.ids_of_full_access_categories,
    viewOnlyCategories: parsedToken.permissions?.ids_of_view_only_categories,
  }
}
