import { Actions } from '../../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../../types/actions'

/// ///////////////////////////////////////////////////////////////////////
const GetCompaniesActions = new Actions('GET_COMPANIES', ActionTypes)

export interface TypeGetCompaniesR extends TDefRequest {
  offset?: number
  limit?: number
  search?: string
  sortBy?: string
  sort?: string
  category?: number
  recommendation_item_id?: number
  without_bid?: boolean
  with_rec?: boolean
}

export const GetCompanies = {
  request: (payload?: TypeGetCompaniesR) => GetCompaniesActions.request(payload || {}),
  success: (payload: any) => GetCompaniesActions.success(payload),
  error: (payload: string) => GetCompaniesActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const UpdateCompanyInfoActions = new Actions('UPDATE_COMPANY_INFO', ActionTypes)

export interface TypeUpdateCompanyInfoR extends TDefRequest {
  body: {
    company_title: string
    id: number
    is_approved: boolean,
    ns_internal_id?: string
  }
}

export const UpdateCompanyInfo = {
  request: (payload?: TypeUpdateCompanyInfoR) => UpdateCompanyInfoActions.request(payload || {}),
  success: (payload: any) => UpdateCompanyInfoActions.success(payload),
  error: (payload: string) => UpdateCompanyInfoActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const CreateNewCompanyActions = new Actions('CREATE_NEW_COMPANY', ActionTypes)

export interface TypeCreateNewCompany extends TDefRequest {
  body: {
    company_title: string
    is_approved: boolean
    ns_internal_id?: string
  }
}

export const CreateNewCompany = {
  request: (payload: TypeCreateNewCompany) => CreateNewCompanyActions.request(payload),
  success: (payload: any) => CreateNewCompanyActions.success(payload),
  error: (payload: string) => CreateNewCompanyActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////

const GetCompanyByIdActions = new Actions('GET_COMPANY_BY_ID', ActionTypes)

export interface TypeGetCompanyById extends TDefRequest {
  companyId: number
}

export const GetCompanyById = {
  request: (payload: TypeGetCompanyById) => GetCompanyByIdActions.request(payload),
  success: (payload: any) => GetCompanyByIdActions.success(payload),
  error: (payload: string) => GetCompanyByIdActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////

export const ClearCompanyByIdActions = {type: ActionTypes.CLEAR_COMPANY_BY_ID, payload: {} }


