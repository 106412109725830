/* eslint-disable react/require-default-props,react/no-unused-prop-types,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/tabindex-no-positive */
import React, { useEffect, useRef, useState } from 'react'
import JoditEditor from 'jodit-react'
import { Input } from '../../../../components/ui/adminComponents/Input'
import { FileLabel } from '../../../../components/ui/FileLabel'
import { MessageState } from '../EditCms'
import { Button } from '../../../../components/ui/adminComponents/Button'
import { uploadFileBase64 } from '../../../../utils/FileUtils'
import { Close } from '../../../../svg/Close'
import { createMessageFilesType } from '../../../CreateMessage'

interface EmailProps {
  emailState: MessageState
  setEmailState: (value: (prev: MessageState) => MessageState) => void
  isCatalog: boolean
  catalog?: boolean
  setCatalog?: (value: boolean) => void
  isIcal: boolean
  ical?: boolean
  setIcal?: (value: boolean) => void
  disabled?: boolean
  files?: createMessageFilesType
  setFiles?: (value: (prev: createMessageFilesType) => createMessageFilesType) => void
}

export const Email: React.FC<EmailProps> = ({
  emailState,
  setEmailState,
  isCatalog,
  catalog,
  setCatalog,
  isIcal,
  ical,
  setIcal,
  disabled,
  files,
  setFiles,
}) => {
  const textColors = [
    'rgb(38, 38, 38)',
    'rgb(154, 52, 18)',
    'rgb(146, 64, 14)',
    'rgb(157, 23, 77)',
    'rgb(153, 27, 27)',
    'rgb(55, 48, 163)',
  ]
  const bgColors = [
    'rgb(229, 229, 229)',
    'rgb(254, 215, 170)',
    'rgb(253, 230, 138)',
    'rgb(251, 207, 232)',
    'rgb(254, 202, 202)',
    'rgb(199, 210, 254)',
  ]

  const editor = useRef(null)

  const [config, setConfig] = useState({
    readonly: false,
    zIndex: 99999,
    height: '23.5em',
    editorCssClass: 'editorStyle',
    statusbar: false,
    removeButtons: ['preview'],
    placeholder: '',
  })

  useEffect(() => {
    setConfig((prev) => ({ ...prev, readonly: !!disabled }))
  }, [disabled])

  const onAddFile = (
    file: createMessageFilesType,
    setFile: (value: (prev: createMessageFilesType) => createMessageFilesType) => void
  ) => {
    const onLoad = (loadedFile: any, currentFile: any) => {
      setFile((prev) => ({
        data: [...prev.data, { file_base_64: loadedFile, file_name: currentFile.name, size: currentFile.size }],
        size: prev.size + 1,
      }))
    }
    uploadFileBase64(
      '.pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain,.csv,.jpg,.jpeg,.png,.ics',
      onLoad
    )
  }

  const removeFile = (
    key: string,
    file: createMessageFilesType,
    setFile: (value: (prev: createMessageFilesType) => createMessageFilesType) => void
  ) => {
    setFile((prev) => ({ data: prev.data.filter((item) => item.file_name !== key), size: prev.size - 1 }))
  }

  return (
    <div className='border-lightblue-900 flex flex-col gap-y-4 lg:w-3/4'>
      <Input
        disabled={disabled}
        placeholder='Header'
        value={emailState.subject}
        onChange={(e) => setEmailState((prev) => ({ ...prev, subject: e.target.value }))}
        className='rounded'
      />
      <JoditEditor
        ref={editor}
        value={emailState.body}
        // @ts-ignore
        // eslint-disable-next-line
        config={config}
        onBlur={(newContent) =>
          setEmailState((prev) => ({
            ...prev,
            body: newContent,
          }))
        }
        // onChange={newContent => {}}
      />
      <div className='flex flex-col gap-4 overflow-auto'>
        <div className='flex gap-4 flex-col w-full smallMobile:w-auto md:flex-row'>
          {isCatalog && (
            <FileLabel
              active={!!catalog}
              setActive={() => setCatalog && setCatalog(!catalog)}
              name='Catalog Diamond'
              size='922kb'
              type='XLSX'
              className='bg-green-200 text-green-800'
              checkbox={!!setCatalog}
            />
          )}
          {isIcal && (
            <FileLabel
              active={!!ical}
              setActive={() => setIcal && setIcal(!ical)}
              name='Circa Tender'
              size='851kb'
              type='ICS'
              className='bg-red-200 text-red-800'
              checkbox={!!setIcal}
            />
          )}
        </div>
        {files && setFiles && (
          <div className='flex w-full flex-wrap gap-4'>
            {files.size < 10 && (
              <Button variant='addFile' text='Add a file' onClick={() => onAddFile(files, setFiles)} />
            )}
            {files.data.map((file, i) => {
              return (
                <div className='flex text-sm w-min items-center bg-white rounded font-semibold' key={file.file_name}>
                  <div
                    style={{ background: bgColors[i % bgColors.length], color: textColors[i % textColors.length] }}
                    className='w-11 h-11 whitespace-nowrap text-ellipsis overflow-hidden rounded flex justify-center items-center'
                  >
                    {file.file_name.split('.')[file.file_name.split('.').length - 1]}
                  </div>
                  <div className='px-2 text-bluegray-600 w-20'>
                    <div className='whitespace-nowrap text-ellipsis overflow-hidden'>{file.file_name}</div>
                    <div className='opacity-40'>{file.size}</div>
                  </div>
                  <button type='button' onClick={() => removeFile(file.file_name, files, setFiles)}>
                    <Close styled='mr-4' />
                  </button>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
