import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAddItemPopup } from '../../../hooks/useAddItemPopup'
import { GetItemInfo } from '../../../store/gallery/actions'
import { TTenderResults } from '../../../store/tenderResults/types'
import { convertToDollars } from '../../../utils/convertToDollars'
import { getCategoryIcon } from '../../../utils/dashboardUtils/CategoriesHelpers'

interface ResultsTableItemProps {
  itemInfo: TTenderResults
  showStatus: boolean
  index: number
}

export const ResultsTableItem: React.FC<ResultsTableItemProps> = ({ itemInfo, showStatus, index }) => {
  const { add } = useAddItemPopup()
  const dispatch = useDispatch()
  const [imgError, setImgError] = useState(false)

  const openItemPopup = (tenderItem: TTenderResults) => {
    dispatch(
      GetItemInfo.request({
        itemId: tenderItem.item_id,
        callBack: (success, data) => {
          if (success) {
            const isCatalog = false
            const item = data
            const finalized = true

            if (item) add({ item, isCatalog, finalized })
          }
        },
      })
    )
  }

  const statusLabel = (bidStatus: number) => {
    if (bidStatus === 2) {
      return <div className='bid-status text-emerald-700 font-bold text-base'>YOU WON!</div>
    }
    if ([4, 5, 6, 9, 10, 11, 12].includes(bidStatus)) {
      let bidText = ''

      if (bidStatus === 6) {
        bidText = `OUTBID BY > 10%`
      } else if (bidStatus === 5) {
        bidText = `OUTBID BY 5-10%`
      } else if (bidStatus === 4) {
        bidText = `OUTBID BY < 5%`
      } else if (bidStatus === 9) {
        bidText = `OUTBID BY < 3%`
      } else if (bidStatus === 10) {
        bidText = `OUTBID BY 3-6%`
      } else if (bidStatus === 11) {
        bidText = `OUTBID BY 6-9%`
      } else if (bidStatus === 12) {
        bidText = `OUTBID BY > 9%`
      }
      return (
        <div className='bid-status text-red-700 font-bold text-base'>
          <span>{bidText}</span>
        </div>
      )
    }
    if (bidStatus === 3) {
      return (
        <div className='bid-status text-bluegray-700 font-bold text-base'>
          <span>RESERVE NOT MET</span>
        </div>
      )
    }
    if (bidStatus === 7) {
      return (
        <div className='bid-status text-yellow-500 font-bold text-base'>
          <span>SOLD TO ANOTHER BUYER</span>
        </div>
      )
    }

    return ''
  }

  const bidDiscount = (list_price: number, bid: number, weight: number) => {
    if (list_price > 0 && bid > 0 && weight > 0) {
      const percent = bid / weight / (list_price / 100) - 100
      return `${Math.round(percent * 100) / 100}%`
    }

    return ''
  }

  const itemAdditionalInfo = () => {
    switch (itemInfo.category_id) {
      case 1:
        return (
          <div className='space-x-4'>
            {itemInfo.gia_link ? (
              <a href={itemInfo.gia_link} target='_blank' rel='noreferrer' className='gia-report tender-results-link'>
                GIA Report
              </a>
            ) : null}
            {itemInfo.video_link ? (
              <a href={itemInfo.video_link} target='_blank' rel='noreferrer' className='v360-video tender-results-link'>
                360° Video
              </a>
            ) : null}
          </div>
        )

      case 2:
        return (
          <div className='divide-x divide-solid divide-bluegray-500'>
            {itemInfo.ring_size ? (
              <span className='ring-size pr-[6px]'>{`Ring Size: ${itemInfo.ring_size}`}</span>
            ) : null}
            {itemInfo.bracelet_length ? (
              <span className='bracelet-length pr-[6px]'>{`Bracelet Length: ${itemInfo.bracelet_length}`}</span>
            ) : null}
            {itemInfo.necklace_length ? (
              <span className='necklace-length pr-[6px]'>{`Necklace Length: ${itemInfo.necklace_length}`}</span>
            ) : null}
            {itemInfo.weight_grams ? (
              <span className='weight-grams px-[6px]'>{`Item Weight grams: ${itemInfo.weight_grams}g`}</span>
            ) : null}
            {itemInfo.year ? <span className='year px-[6px]'>{`Year: ${itemInfo.year}`}</span> : null}
            {itemInfo.condition ? (
              <span className='condition pl-[6px]'>{`Condition: ${itemInfo.condition}`}</span>
            ) : null}
          </div>
        )

      case 3:
        return (
          <div className='divide-x divide-solid divide-bluegray-500'>
            {itemInfo.ref ? <span className='ref pr-[6px]'>{`Ref #: ${itemInfo.ref}`}</span> : null}
            {itemInfo.year ? <span className='year px-[6px]'>{`Year: ${itemInfo.year}`}</span> : null}
            {itemInfo.condition ? (
              <span className='condition pl-[6px]'>{`Condition: ${itemInfo.condition}`}</span>
            ) : null}
          </div>
        )

      default:
        return null
    }
  }

  return (
    <tr className={`item border-b bg-opacity-75 ${index % 2 === 0 ? 'bg-white' : 'bg-slate-50'}`}>
      <td className='py-3 pl-8 pr-5 flex items-center text-center'>
        <button type='button' onClick={() => openItemPopup(itemInfo)} className='img w-24 mr-3'>
          {itemInfo.img && !imgError ? (
            <img src={itemInfo.img} alt='ItemImage' className='xrounded-lg w-24' onError={() => setImgError(true)} />
          ) : (
            <div className='text-bluegray-400 flex items-center justify-center h-16 w-24 text-3xl'>
              {getCategoryIcon(itemInfo.category_id)}
            </div>
          )}
        </button>

        <div className='flex flex-col items-start'>
          {showStatus ? statusLabel(itemInfo.bid_status_id) : null}

          <button
            type='button'
            onClick={() => openItemPopup(itemInfo)}
            className='title text-lg font-semibold mr-4 hover:text-lightblue-600 tracking-wider'
          >
            {itemInfo.title}
          </button>

          {itemAdditionalInfo()}
        </div>
      </td>

      <td className='py-3 pl-1 pr-10 min-w-[300px] max-w-[300px]'>
        <div className='bid-date text-base font-semibold mb-1'>
          {new Date(itemInfo.date).toLocaleDateString('en-US')}
        </div>

        <div className='user-email font-semibold text-sm overflow-auto'>{itemInfo.bid_by}</div>
      </td>

      <td
        className={`bid-amount py-3 pl-1 pr-8 ${
          itemInfo.category_id === 1 ? 'min-w-[250px] max-w-[250px]' : 'min-w-[180px] max-w-[180px]'
        }`}
      >
        {itemInfo.category_id === 1 ? (
          <div className='flex items-start flex-initial divide-x-2'>
            <div className='price-per-carat text-xl font-semibold leading-none text-right pr-3 w-[125px]'>
              {convertToDollars(itemInfo.bid)}
              <div className='text-sm uppercase text-bluegray-500 whitespace-nowrap mt-3 font-normal'>Total</div>
            </div>

            {itemInfo.list_price ? (
              <div className='rap-list-price-discount text-xl font-semibold leading-none text-left pl-3'>
                {bidDiscount(itemInfo.list_price, itemInfo.bid, itemInfo.weight)}
                <div className='text-sm uppercase text-bluegray-500 mt-3 font-normal'>% RAP</div>
              </div>
            ) : null}
          </div>
        ) : (
          <div className='w-full font-semibold text-center text-xl'>{convertToDollars(itemInfo.bid)}</div>
        )}
      </td>
    </tr>
  )
}
