import { Link } from 'react-router-dom'
import React from 'react'
import { useSelector } from 'react-redux'
import { Close } from '../../../svg/Close'
import { IconLogo } from '../../../svg/IconLogo'
import { ROUTES } from '../../../utils/routes'
import { AppStore } from '../../../store/applicationState'
import { WORTHY_REDIRECT, recMode } from '../../../utils/consts'
import { getCookie } from '../../../utils/cookiesUtils'

interface MobileMenuProps {
  mobileActive: boolean
  setMobileActive: (value: boolean) => void
  onLogOut: () => void
}

export const MobileMenu: React.FC<MobileMenuProps> = ({ mobileActive, setMobileActive, onLogOut }) => {
  const { userData } = useSelector((store: AppStore) => store)

  return (
    <div className={mobileActive ? 'mobile-menu activeMenu' : 'mobile-menu'}>
      <div className='flex-col fixed w-full h-full bg-white z-50'>
        <div className='flex justify-between h-15 items-center px-5 py-3 border-b border-bluegray-300'>
          <Link
            style={recMode ? { pointerEvents: 'none' } : {}}
            to={ROUTES.dashboard}
            onClick={() => {
              setMobileActive(false)
            }}
          >
            <IconLogo />
          </Link>

          <button
            disabled={recMode}
            type='button'
            className='cursor-pointer flex items-center'
            onClick={() => setMobileActive(false)}
          >
            <Close styled='text-bluegray-500 h-8 w-5 hover:text-lightblue-600' />
          </button>
        </div>

        <ul className='mt-2'>
          <li className='px-5 py-3 border-b border-bluegray-200'>
            <Link
              style={recMode ? { pointerEvents: 'none' } : {}}
              to={ROUTES.dashboard}
              className='hover:text-lightblue-700 text-sm text-menuLink'
              onClick={() => {
                setMobileActive(false)
              }}
            >
              Dashboard
            </Link>
          </li>

          {(userData.isAssociateAdmin || userData.isExecutiveAdmin) && (
            <li className='px-5 py-3 border-b border-bluegray-200'>
              <Link
                style={recMode ? { pointerEvents: 'none' } : {}}
                to={ROUTES.adminPageTender}
                className='hover:text-lightblue-700 text-sm text-menuLink'
                onClick={() => setMobileActive(false)}
              >
                Admin Panel
              </Link>
            </li>
          )}

          <li className='px-5 py-3 border-b border-bluegray-200'>
            <Link
              style={recMode ? { pointerEvents: 'none' } : {}}
              to={ROUTES.userAccount}
              className='hover:text-lightblue-700 text-sm text-menuLink'
              onClick={() => setMobileActive(false)}
            >
              My Profile
            </Link>
          </li>

          {getCookie(WORTHY_REDIRECT) !== 'true' && !window.location.href.includes('worthy_auth_token') && (
            <li className='px-5 py-3'>
              <button
                disabled={recMode}
                type='button'
                className='hover:text-lightblue-700 text-sm text-menuLink'
                onClick={onLogOut}
              >
                Logout
              </button>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}
