import { TDefRequest } from '../../../../types/actions'
import { Actions } from '../../../../utils/Action'
import { ActionTypes } from './types'

/// ///////////////////////////////////////////////////////////////////////
const GetUpcomingTendersActions = new Actions('GET_UPCOMING_TENDERS', ActionTypes)

export interface TypeGetUpcomingTendersR extends TDefRequest {}

export const GetUpcomingTenders = {
  request: (payload?: TypeGetUpcomingTendersR) => GetUpcomingTendersActions.request(payload || {}),
  success: (payload: any) => GetUpcomingTendersActions.success(payload),
  error: (payload: string) => GetUpcomingTendersActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
const ChangeUpcomingTendersVisibleActions = new Actions('CHANGE_UPCOMING_TENDER_VISIBLE', ActionTypes)

export interface TypeChangeUpcomingTendersVisibleR extends TDefRequest {
  tenderId: number
  hide: boolean
}

export const ChangeUpcomingTendersVisible = {
  request: (payload?: TypeChangeUpcomingTendersVisibleR) => ChangeUpcomingTendersVisibleActions.request(payload || {}),
  success: (payload: any) => ChangeUpcomingTendersVisibleActions.success(payload),
  error: (payload: string) => ChangeUpcomingTendersVisibleActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
