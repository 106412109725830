/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useState } from 'react'
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppStore } from '../../../store/applicationState'
import { GetAllCsmMessagesById, GetAllCsmTemplates } from '../../../store/admin/settings/cms/actions'
import { ROUTES } from '../../../utils/routes'
import { IconBack } from '../../../svg/IconBack'
import { Checkbox } from '../../../components/ui/Checkbox'
import { RadioBtn } from '../../../components/ui/RadioBtn'
import { Email } from './Messages/Email'
import { SwitchRadioBtn } from '../../../components/ui/SwitchRadioBtn'
import { Sms } from './Messages/Sms'
import { Variables } from './Messages/Variables'
import { saveMessages, saveTemplate } from '../../../utils/EditCmsUtils/helpers'
import { EmailPreview } from '../../../components/ui/EmailPreview'
import { P404 } from '../../../pages'
import { callApi } from '../../../utils/callApi'
import { API_ROUTES } from '../../../utils/apiRoutes'
import { SetLoader } from '../../../store/globalState/action'
import { SnackBar } from '../../../components/ui/SnackBar'
import { TemplatesSnackBar } from '../../../components/ui/adminComponents/TemplatesSnackBar'

export interface MessageState {
  body: string
  subject: string
  footer: string
}

interface EditCmsProps {}

const templateSaveStatus = {
  clickSave: false,
  templateMessage: '',
  tempalteError: false,
  emailMessage: '',
  emailError: false,
  smsMessage: '',
  smsError: false,
}

export const EditCms: React.FC<EditCmsProps> = () => {
  const { addSnackBar } = SnackBar()
  const { id, idTemplate = +id } = useParams() as any
  const history = useHistory()
  const location = useLocation()

  const dispatch = useDispatch()
  const { cms } = useSelector((store: AppStore) => store)
  const { addTemplateSnackBar } = TemplatesSnackBar()

  const [preferred, setPreferred] = useState(false)
  const [email, setEmail] = useState(false)
  // const [whatsApp, setWhatsApp] = useState(false)
  const [sms, setSms] = useState(false)

  const [eventHappenedRadioValue, setEventHappenedRadioValue] = useState('')
  const [eventHappenedTime, setEventHappenedTime] = useState('0')
  const [active, setActive] = useState(false)

  const [emailState, setEmailState] = useState<MessageState>({ body: '', footer: '', subject: '' })
  // const [whatsAppState, setWhatsAppState] = useState<MessageState>({ body: '', footer: '', subject: '' })
  const [smsState, setSmsState] = useState<MessageState>({ body: '', footer: '', subject: '' })

  const [editStatus, setEditStatus] = useState(templateSaveStatus)

  const [emailPreviewState, setEmailPreviewState] = useState<null | { body: string; subject: string }>(null)

  const currentTemplate = useMemo(() => {
    return (cms.data && cms.data.find((item) => item.messaging_template_id === idTemplate)) || null
  }, [cms.data, idTemplate])

  const current = useMemo(() => {
    return (
      cms.currentTemplateMessages.data && {
        email: cms.currentTemplateMessages.data.find((item) => item.message_method_id === 1) || null,
        sms: cms.currentTemplateMessages.data.find((item) => item.message_method_id === 2) || null,
        // whatsApp: cms.currentTemplateMessages.data.find(item => item.message_method_id === 3) || null,
      }
    )
  }, [cms.currentTemplateMessages.data])

  useEffect(() => {
    if (idTemplate !== cms.currentTemplateMessages.id) dispatch(GetAllCsmMessagesById.request({ idTemplate }))
  }, [cms.currentTemplateMessages.id, idTemplate])

  /// //////////////////////////////////////////////////////////// init state
  useEffect(() => {
    if (current?.email /* && current?.whatsApp */ && current?.sms) {
      setEmail(current.email.active)
      // setWhatsApp(current.whatsApp.active)
      setSms(current.sms.active)

      setEmailState({ body: current.email.body, footer: current.email.footer, subject: current.email.subject })
      // setWhatsAppState({ body: current.whatsApp.body, footer: current.whatsApp.footer, subject: current.whatsApp.subject })
      setSmsState({ body: current.sms.body, footer: current.sms.footer, subject: current.sms.subject })
    }
  }, [current])

  useEffect(() => {
    if (currentTemplate) {
      setActive(currentTemplate.active)
      if (currentTemplate.schedule) setEventHappenedRadioValue('eventTime')
      else setEventHappenedRadioValue('eventHappened')
      setEventHappenedTime(`${currentTemplate.schedule / 60}`)
      setPreferred(currentTemplate.preferred_delivery_method)
    }
  }, [currentTemplate])

  useEffect(() => {
    const timer = setTimeout(() => {
      setEventHappenedTime(`${Math.round(+eventHappenedTime)}`)
    }, 2000)

    return () => clearTimeout(timer)
  }, [eventHappenedTime])
  /// ////////////////////////////////////////////////////////////

  const onSaveAll = () => {
    const schedule = +eventHappenedTime * 60
    if (current?.email /* && current?.whatsApp */ && current?.sms && !Number.isNaN(schedule)) {
      saveTemplate(schedule, idTemplate, active, preferred, dispatch, setEditStatus)
      saveMessages(current, email, emailState, /* whatsApp, whatsAppState, */ sms, smsState, dispatch, setEditStatus)
      setEditStatus((prev) => {
        return {
          ...prev,
          clickSave: true,
        }
      })
    }
  }

  useEffect(() => {
    if (editStatus.clickSave && editStatus.emailMessage && editStatus.smsMessage && editStatus.templateMessage) {
      addTemplateSnackBar([
        { message: editStatus.templateMessage, success: !editStatus.tempalteError },
        { message: editStatus.emailMessage, success: !editStatus.emailError },
        { message: editStatus.smsMessage, success: !editStatus.smsError },
      ])
      if (!editStatus.emailError && !editStatus.smsError && !editStatus.tempalteError) {
        dispatch(GetAllCsmTemplates.request())
        history.push(ROUTES.adminPageSettingsCsm)
      }
      setEditStatus(templateSaveStatus)
    }
  }, [editStatus.clickSave, editStatus.emailMessage, editStatus.smsMessage, editStatus.templateMessage])

  const arrowPress = (press: boolean, up?: boolean) => {
    let value = +eventHappenedTime
    let keyTimer: any

    if (press && up) {
      keyTimer = setInterval(() => {
        value = Math.round(value + 1)
        setEventHappenedTime(`${value}`)
      }, 200)
    }

    if (press && !up) {
      keyTimer = setInterval(() => {
        value = Math.round(value - 1)
        setEventHappenedTime(`${value}`)
      }, 200)
    }

    document.addEventListener('mouseup', () => clearInterval(keyTimer))
  }

  const onShowPreview = () => {
    dispatch(SetLoader())
    callApi({
      method: 'put',
      path: `${API_ROUTES.adminCms}/messages/preview`,
      data: {
        body: emailState.body,
        footer: emailState.footer,
        message_method_id: current?.email?.message_method_id || 1,
        subject: emailState.subject,
        is_tender_generate: true,
      },
    })
      .then((res) => setEmailPreviewState({ body: res.body, subject: res.subject }))
      .catch(({ data }) => addSnackBar(data.message, false))
  }

  return (
    <>
      <button
        type='button'
        className='py-3 w-max flex flex-row items-center h-16'
        onClick={() => history.push(ROUTES.adminPageSettingsCsm)}
      >
        <IconBack />
        <div className='ml-2 text-menuLink opacity-50 hover:opacity-80'>Back to table</div>
      </button>
      <h1 className='text-xl font-semibold border-lightblue-900 text-lightblue-900'>
        Edit template: ID
        {idTemplate}
      </h1>
      <div className='my-8 flex flex-wrap gap-y-4 gap-x-2 justify-between'>
        <div
          style={{ background: '#F8FAFC' }}
          className='p-4 rounded flex flex-col md:flex-row gap-y-4 w-full lg:w-auto justify-center items-center'
        >
          <span className='font-semibold border-lightblue-900 text-lightblue-900'>Channel:</span>
          <div className='gap-x-4 flex flex-wrap justify-between gap-y-4 md:ml-5'>
            <Checkbox
              label='Preferred'
              isChecked={preferred}
              variant='green'
              setIsChecked={() => setPreferred(!preferred)}
            />
            <Checkbox label='Email' isChecked={email} variant='green' setIsChecked={() => setEmail(!email)} />
            {/* <Checkbox label='WhatsApp' isChecked={whatsApp} variant='green' setIsChecked={() => setWhatsApp(!whatsApp)} /> */}
            <Checkbox label='Sms' isChecked={sms} variant='green' setIsChecked={() => setSms(!sms)} />
          </div>
        </div>
        {currentTemplate?.have_schedule && (
          <div
            style={{ background: '#F8FAFC' }}
            className='p-4 gap-y-4 rounded flex flex-col mobile:flex-row w-full md:w-auto justify-center items-center'
          >
            <span className='font-semibold border-lightblue-900 text-lightblue-900'>Schedule:</span>
            <div className='gap-x-4 flex flex-wrap gap-y-4 justify-between mobile:ml-5'>
              <RadioBtn
                text='Event happened'
                value='eventHappened'
                color='text-green-800'
                checked={eventHappenedRadioValue === 'eventHappened'}
                onChange={(e) => {
                  setEventHappenedRadioValue(e.target.value)
                  setEventHappenedTime('0')
                }}
                name='event'
              />
              <div className='flex gap-x-4 relative pr-4' onClick={() => setEventHappenedRadioValue('eventTime')}>
                <RadioBtn
                  text=''
                  value='eventTime'
                  color='text-green-800'
                  checked={eventHappenedRadioValue === 'eventTime'}
                  onChange={(e) => setEventHappenedRadioValue(e.target.value)}
                  name='event'
                />
                <div className='relative text-sm flex justify-center disableArrows'>
                  <input
                    type='number'
                    value={eventHappenedTime}
                    onChange={(e) => setEventHappenedTime(`${e.target.value}`)}
                    className='w-20 pl-1 pr-12 text-center border-lightblue-900 text-lightblue-900'
                  />
                  <span
                    style={{ top: '50%', transform: 'translate(0, -50%)' }}
                    className='absolute right-[17px] border-lightblue-900 text-lightblue-900'
                  >
                    mins
                  </span>

                  <div className='absolute z-10 right-0 top-0 h-full flex flex-col text-micro'>
                    <div className='my-auto'>
                      <button
                        type='button'
                        className='flex px-1 bg-white hover:bg-bluegray-200 select-none'
                        onMouseDown={() => arrowPress(true, true)}
                        onMouseUp={() => arrowPress(false)}
                        onClick={() => setEventHappenedTime(`${Math.round(+eventHappenedTime + 1)}`)}
                      >
                        ▲
                      </button>
                      <button
                        id='button-down'
                        type='button'
                        className='flex px-1 bg-white hover:bg-bluegray-200 select-none'
                        onMouseDown={() => arrowPress(true, false)}
                        onMouseUp={() => arrowPress(false)}
                        onClick={() => setEventHappenedTime(`${Math.round(+eventHappenedTime - 1)}`)}
                      >
                        ▼
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div style={{ background: '#F8FAFC' }} className='p-4 rounded flex justify-center items-center'>
          <span className='font-semibold border-lightblue-900 text-lightblue-900'>Active:</span>
          <div className='gap-x-4 flex ml-5'>
            <SwitchRadioBtn checked={active} onChange={() => setActive(!active)} />
          </div>
        </div>
      </div>
      <div className='bg-white pb-10'>
        <div className='flex relative justify-start gap-x-14 mb-6 text-base overflow-x-auto border-lightblue-900 text-lightblue-900'>
          <div
            className={`py-2 cursor-pointer z-10 hover:text-lightblue-700 ${
              location.pathname.search(`${ROUTES.adminPageSettingsCsm}/edit/${idTemplate}/email`) !== -1 &&
              'border-lightblue-900 text-lightblue-900 font-semibold border-b-2'
            }`}
            onClick={() => history.push(`${ROUTES.adminPageSettingsCsm}/edit/${idTemplate}/email`)}
          >
            Email
          </div>
          {/* <div */}
          {/*  className={`py-2 cursor-pointer z-10 hover:text-lightblue-700 ${location.pathname.search(`${ROUTES.adminPageSettingsCsm}/edit/${idTemplate}/whats-app`) !== -1 && 'border-lightblue-900 text-lightblue-900 border-b-2 font-semibold'}`} */}
          {/*  onClick={() => history.push(`${ROUTES.adminPageSettingsCsm}/edit/${idTemplate}/whats-app`)} */}
          {/* > */}
          {/*  WhatsApp */}
          {/* </div> */}
          <div
            className={`py-2 cursor-pointer z-10 hover:text-lightblue-700 ${
              location.pathname.search(`${ROUTES.adminPageSettingsCsm}/edit/${idTemplate}/sms`) !== -1 &&
              'border-lightblue-900 text-lightblue-900 border-b-2 font-semibold'
            }`}
            onClick={() => history.push(`${ROUTES.adminPageSettingsCsm}/edit/${idTemplate}/sms`)}
          >
            SMS
          </div>
          {location.pathname.search(`${ROUTES.adminPageSettingsCsm}/edit/${idTemplate}/email`) !== -1 && (
            <button
              type='button'
              className='ml-auto border hover:bg-white active:text-white active:bg-lightblue-800 whitespace-nowrap border-bluegray-300 rounded-t p-2'
              onClick={onShowPreview}
            >
              Preview Message
            </button>
          )}
          {emailPreviewState && (
            <EmailPreview
              subject={emailPreviewState.subject}
              html={emailPreviewState.body}
              onClose={() => setEmailPreviewState(null)}
            />
          )}
          <div className='absolute h-px margin-l-r-0 left-0 right-0 bottom-0 bg-bluegray-300' />
        </div>
        <div style={{ background: '#F8FAFC' }} className='flex flex-col gap-y-2 lg:flex-row p-3'>
          <Switch>
            <Route
              path={[
                `${ROUTES.adminPageSettingsCsm}/edit/:id/email`,
                `/worthy${ROUTES.adminPageSettingsCsm}/edit/:id/email`,
              ]}
            >
              <Email
                emailState={emailState}
                setEmailState={setEmailState}
                isCatalog={!!current?.email?.have_catalog}
                isIcal={!!current?.email?.have_ical}
              />
            </Route>
            {/* <Route path={`${ROUTES.adminPageSettingsCsm}/edit/:id/whats-app`}> */}
            {/*  <WhatsApp whatsAppState={whatsAppState} setWhatsAppState={setWhatsAppState} /> */}
            {/* </Route> */}
            <Route
              path={[
                `${ROUTES.adminPageSettingsCsm}/edit/:id/sms`,
                `/worthy${ROUTES.adminPageSettingsCsm}/edit/:id/sms`,
              ]}
            >
              <Sms smsState={smsState} setSmsState={setSmsState} />
            </Route>
            <Route path='*' component={P404} />
          </Switch>
          <div className='flex flex-col space-y-6 justify-between lg:w-1/3 lg:pl-2.5'>
            <Variables />
            <button
              type='submit'
              className='py-3 px-16 text-white bg-green-700 hover:bg-green-800 text-base rounded-md product-buttons'
              onClick={() => onSaveAll()}
            >
              Save template
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
