import { Actions } from '../../../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../../../types/actions'


/// ///////////////////////////////////////////////////////////////////////
const GetAuditByTenderActions = new Actions('GET_AUDIT_BY_TENDER', ActionTypes)
const GetAuditByTenderWith0OffsetActions = new Actions('GET_AUDIT_BY_TENDER_WITH_0_OFFSET', ActionTypes)

export interface TypeGetAuditByTenderR extends TDefRequest {
  idTender: number
  offset?: number
  limit?: number
  search?: string
  sortBy?: string
  sort?: string
}

export const GetAuditByTender = {
  request: (payload: TypeGetAuditByTenderR) => GetAuditByTenderActions.request(payload),
  success: (payload: any) => GetAuditByTenderActions.success(payload),
  error: (payload: string) => GetAuditByTenderActions.error(payload),
}

export const GetAuditByTenderWith0Offset = {
  success: (payload: any) => GetAuditByTenderWith0OffsetActions.success(payload),
}


/// ///////////////////////////////////////////////////////////////////////
const GetAuditByTendersChildActions = new Actions('GET_AUDIT_BY_TENDER_CHILD', ActionTypes)
const GetAuditByTendersChildWith0OffsetActions = new Actions('GET_AUDIT_BY_TENDER_CHILD_WITH_0_OFFSET', ActionTypes)

export interface TypeGetAuditByTendersChildR extends TDefRequest {
  idItem: number
  offset?: number
  limit?: number
  sort?: string
  companyId?: number[]
}

export const GetAuditByTendersChild = {
  request: (payload: TypeGetAuditByTendersChildR) => GetAuditByTendersChildActions.request(payload),
  success: (payload: any) => GetAuditByTendersChildActions.success(payload),
  error: (payload: string) => GetAuditByTendersChildActions.error(payload),
}

export const GetAuditByTendersChildWith0Offset = {
  success: (payload: any) => GetAuditByTendersChildWith0OffsetActions.success(payload),
}


/// ///////////////////////////////////////////////////////////////////////
