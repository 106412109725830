import { Actions } from '../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../types/actions'


/// ///////////////////////////////////////////////////////////////////////
const GetTermsActions = new Actions('GET_TERMS', ActionTypes)

export interface TypeGetTerms extends TDefRequest {}

export const GetTerms = {
  request: (payload?: TypeGetTerms) => GetTermsActions.request(payload || {}),
  success: (payload: any) => GetTermsActions.success(payload),
  error: (payload: string) => GetTermsActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
const PostTermsActions = new Actions('POST_TERMS', ActionTypes)

export interface TypePostTerms extends TDefRequest {}

export const PostTerms = {
  request: (payload?: TypePostTerms) => PostTermsActions.request(payload || {}),
  success: (payload: any) => PostTermsActions.success(payload),
  error: (payload: string) => PostTermsActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////

export const SetTermsFalse = () => ({ type: ActionTypes.SET_TERMS_FALSE })

/// ///////////////////////////////////////////////////////////////////////

export const SetTermsTrue = () => ({ type: ActionTypes.SET_TERMS_TRUE })