import { Actions } from '../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../types/actions'


/// ///////////////////////////////////////////////////////////////////////
const GetCurrentTimeActions = new Actions('GET_CURRENT_TIME', ActionTypes)

export interface TypeGetCurrentTimeR extends TDefRequest {}

export const GetCurrentTime = {
  request: (payload?: TypeGetCurrentTimeR) => GetCurrentTimeActions.request(payload || {}),
  success: (payload: any) => GetCurrentTimeActions.success(payload),
  error: (payload: string) => GetCurrentTimeActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
