/* eslint-disable react/require-default-props */
import React from 'react'

interface Props {
  className?: string
  style?: any
  sort?: 'up' | 'down'
}

export const Sort: React.FC<Props> = ({ className = '', style = {}, sort = undefined }) => {
  return (
    <svg
      className={`svg-inline--fa fa-sort fa-w-10 ${className}`}
      style={style}
      viewBox='0 0 9 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      {sort !== 'down' && (
        <path
          d='M4.12835 0.412942C4.32697 0.192251 4.67303 0.192251 4.87165 0.412941L8.24897 4.16552C8.53855 4.48728 8.3102 5 7.87732 5H1.12268C0.689795 5 0.461449 4.48728 0.751034 4.16552L4.12835 0.412942Z'
          fill='#95A4B7'
        />
      )}
      {sort !== 'up' && (
        <path
          d='M4.12835 10.5871C4.32697 10.8077 4.67303 10.8077 4.87165 10.5871L8.24897 6.83448C8.53855 6.51272 8.3102 6 7.87732 6H1.12268C0.689795 6 0.461449 6.51272 0.751034 6.83448L4.12835 10.5871Z'
          fill='#95A4B7'
        />
      )}
    </svg>
  )
}
