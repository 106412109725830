export interface TBidsByBiddenChild {
  asking_discount?: number
  asking_price?: number
  asking_price_per_carat?: number
  bid: number
  bid_id: number
  bid_status_id: number
  category_id: number
  consignor_name: string
  cost: string
  created_at: string
  item_id: number
  list_price: number
  sales_target: number
  sales_target_per_carat: number
  shipping_owner: string
  stock_number: string
  title: string
  weight?: number
}

export interface TBidsByBidden {
  company_id: number,
  company_title: string,
  count_bids: number,
  email: string,
  last_bid: string,
  user_id: number
}

export interface TBidsByBiddenMessage {
  message: string
}

export interface TBidsByBiddenState {
  loading: boolean
  data: TBidsByBidden[] | null
  childData: Map<number, TBidsByBiddenChild[]> | null
  errors?: string | undefined
}

export enum ActionTypes {
  GET_BIDS_BY_BIDDEN_R = '@@TENDERS/GET_BIDS_BY_BIDDEN_R',
  GET_BIDS_BY_BIDDEN_S = '@@TENDERS/GET_BIDS_BY_BIDDEN_S',
  GET_BIDS_BY_BIDDEN_WITH_O_OFFSET_S = '@@TENDERS/GET_BIDS_BY_BIDDEN_WITH_O_OFFSET_S',
  GET_BIDS_BY_BIDDEN_E = '@@TENDERS/GET_BIDS_BY_BIDDEN_E',
  
  GET_BIDS_BY_BIDDEN_CHILD_R = '@@TENDERS/GET_BIDS_BY_BIDDEN_CHILD_R',
  GET_BIDS_BY_BIDDEN_CHILD_S = '@@TENDERS/GET_BIDS_BY_BIDDEN_CHILD_S',
  GET_BIDS_BY_BIDDEN_CHILD_E = '@@TENDERS/GET_BIDS_BY_BIDDEN_CHILD_E',
}
