import { Reducer } from 'redux'
import { ActionTypes, TTenderResultsState } from './types'
import { changeImageSizeForTenderResults } from '../../utils/imgUrlParse/imgUrlParser'

export const initialState: TTenderResultsState = {
  loading: false,
  data: {
    bidsResults: null,
    purchaseHistory: null,
    bidsWon: null,
    tenderInfo: null,
  },
}

const reducer: Reducer<TTenderResultsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_BIDS_RESULT_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_BIDS_RESULT_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        data: { ...state.data, bidsResults: changeImageSizeForTenderResults(action.payload) },
      }
    case ActionTypes.GET_BIDS_RESULT_E:
      return { ...state, loading: false, errors: action.payload }
    
    case ActionTypes.GET_PURCHASE_HISTORY_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_PURCHASE_HISTORY_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        data: { ...state.data, purchaseHistory: changeImageSizeForTenderResults(action.payload) },
      }
    case ActionTypes.GET_PURCHASE_HISTORY_E:
      return { ...state, loading: false, errors: action.payload }
    
    case ActionTypes.GET_BIDS_WON_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_BIDS_WON_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        data: { ...state.data, bidsWon: changeImageSizeForTenderResults(action.payload) },
      }
    case ActionTypes.GET_BIDS_WON_E:
      return { ...state, loading: false, errors: action.payload }
    
    case ActionTypes.GET_TENDER_INFO_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_TENDER_INFO_S:
      return { ...state, loading: false, errors: undefined, data: { ...state.data, tenderInfo: action.payload } }
    case ActionTypes.GET_TENDER_INFO_E:
      return { ...state, loading: false, errors: action.payload }
    
    default:
      return state
  }
}

export { reducer as TenderResultsReducer }