import { Actions } from '../../../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../../../types/actions'


/// ///////////////////////////////////////////////////////////////////////
const SetTenderFinalizedActions = new Actions('SET_TENDER_FINALIZED', ActionTypes)

export interface TypeSetTenderFinalizedR extends TDefRequest {
  idTender: number
}

export const SetTenderFinalized = {
  request: (payload: TypeSetTenderFinalizedR) => SetTenderFinalizedActions.request(payload),
  success: (payload: any) => SetTenderFinalizedActions.success(payload),
  error: (payload: string) => SetTenderFinalizedActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
const UpdateWinnerByItemIdActions = new Actions('UPDATE_WINNER_BY_ITEM_ID', ActionTypes)

export interface TypeUpdateWinnerByItemIdR extends TDefRequest {
  idItem: number
  idBid: number
}

export const UpdateWinnerByItemId = {
  request: (payload: TypeUpdateWinnerByItemIdR) => UpdateWinnerByItemIdActions.request(payload),
  success: (payload: any) => UpdateWinnerByItemIdActions.success(payload),
  error: (payload: string) => UpdateWinnerByItemIdActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
const GetTenderStatisticActions = new Actions('GET_TENDER_STATISTIC', ActionTypes)

export interface GetTenderStatisticR extends TDefRequest {
  idTender: number
}

export const GetTenderStatistic = {
  request: (payload: GetTenderStatisticR) => GetTenderStatisticActions.request(payload),
  success: (payload: any) => GetTenderStatisticActions.success(payload),
  error: (payload: string) => GetTenderStatisticActions.error(payload),
}
