/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory} from 'react-router-dom'
import { AppStore } from '../store/applicationState'
import {
  GetNSCompanyById,
  GetAllCompaniesNS,
  ClearCompanyNSByIdActions,
  UpdateNSCompanyInfo,
  CreateNewNSCompany,
} from '../store/admin/companiesNS/actions'
import {ClearCompanyByIdActions, CreateNewCompany } from '../store/admin/companies/actions'
import { IconBack } from '../svg/IconBack'
import { getValueForSelect, onEmailChange, onEmptyInputChange } from '../utils/userUtils/EventHandlers'
import { stylesMulti, validateButton } from '../utils/userUtils/FormStyles'
import { FormPhoneInput } from '../components/ui/formUi/FormPhoneInput'
import { TNSCompaniesAddress } from '../store/admin/companiesNS/types'
import { AddressBlock } from '../components/ui/admin/companies/AddressBlock'
import { ROUTES } from '../utils/routes'
import { ResetAddressFormsState, ToggleAddressForm } from '../store/addressFormsState/actions'
import { InputChecked } from '../svg/InputChecked'
import { InputNotChecked } from '../svg/InputNotChecked'

const options = [
  { value: '1', label: 'Foreign' },
  { value: '2', label: 'Domestic' },
]

interface AddCompanyFormProps {}

export const AddCompanyForm: React.FC<AddCompanyFormProps> = () => {
  const history = useHistory()
  const dispatch = useDispatch()


  useEffect(() => {
    dispatch(GetAllCompaniesNS.request({ withoutCompanyRel: 'true' }))
    return () => {
      dispatch(ClearCompanyByIdActions)
      dispatch(ClearCompanyNSByIdActions)
    }
  }, [])

  const { adminCompaniesNS, addressFormsState } = useSelector((store: AppStore) => store)
  // const nsId = companies.companyForEditData?.ns_internal_id || adminCompaniesNS.companyNSForData?.ns_internal_id || ''

  const [companyTitle, setCompanyTitle] = useState<string>('')
  const [customerNSName, setCustomerNSName] = useState<string>('')
  const [companyNSName, setCompanyNSName] = useState<string>('')
  const [foreignOrDomestic, setForeignOrDomestic] = useState<string>('')
  const [tell, setTell] = useState<string>('')
  const [email, setEmail] = useState<string>('')

  const [isApproved, setIsApproved] = useState<boolean>(false)
  const [addressList, setAddressList] = useState<TNSCompaniesAddress[]>([])

  const [companyTitleError, setCompanyTitleError] = useState<boolean>(false)
  const [companyNSNameError, setCompanyNSNameError] = useState<boolean>(false)
  const [emailError, setEmailError] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tellError, setTellError] = useState<boolean>(false)
  const [defaultError, setDefaultError] = useState(false)
  const [submitError, setSubmitError] = useState<boolean>(false)
  const [addressListError, setAddressListError] = useState<boolean>(false)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [companyNSCustomerId, setCompanyNSCustomerId] = useState<number | string | null>(null)

  useEffect(() => {
    setCompanyNSNameError(!companyNSName)
  }, [companyNSName])

  useEffect(() => {
    setEmailError(!email)
  }, [email])

  const styles = {
    control: (provided: any) => ({
      ...provided,
      color: 'red',
      padding: '5px',
      boxShadow: 'none',
    }),
  }

  const generatingEmptyValues = () => {
    return {
      addr1: '',
      addr2: '',
      addr_phone: '',
      addressee: '',
      attention: '',
      city: '',
      country: '',
      default_billing: false,
      default_shipping: false,
      state: '',
      zip: '',
    }
  }

  const addNewAddress = () => {
    const emptyAdd = generatingEmptyValues()
    setAddressList([...addressList, emptyAdd])
    dispatch(ToggleAddressForm(addressList.length, true))
  }

  useEffect(() => {
    if (adminCompaniesNS.companyNSForData) {
      const addrList = adminCompaniesNS.companyNSForData?.address_book_list?.address_book_list

      if (addrList) {
        setAddressList(addrList)
      }
      setCustomerNSName(adminCompaniesNS.companyNSForData?.company_name)
      setCompanyNSName(adminCompaniesNS.companyNSForData?.company_name)
      setCompanyNSCustomerId(adminCompaniesNS.companyNSForData?.ns_internal_id)
      setTell(adminCompaniesNS.companyNSForData?.phone || '')
      setEmail(adminCompaniesNS.companyNSForData?.email)
      setForeignOrDomestic(adminCompaniesNS.companyNSForData?.foreign_or_domestic)
      // setPrintTransactions(adminCompaniesNS.companyNSForData?.print_transactions)
      // setEmailTransactions(adminCompaniesNS.companyNSForData?.email_transactions)
    }
  }, [adminCompaniesNS.companyNSForData])


  useEffect(() => {
    if (adminCompaniesNS.errors) {
      setIsSubmitting(false)
    }
  }, [adminCompaniesNS.errors])

  const isAnyFormExpanded = useMemo(() => {
    return addressFormsState.addressForms.includes(true)
  }, [addressFormsState.addressForms])

  const onSubmitCompanyForm = (event: any) => {
    event.preventDefault()

    if(!isApproved) {
      dispatch(
        CreateNewCompany.request({
          body: {
            company_title: companyTitle,
            is_approved: isApproved,
          },
          callBack: (createSuccess) => {
            setIsSubmitting(false)
            if (createSuccess) {
              history.push(`${ROUTES.adminPageUsersCompanies}`)
            }
          },
        })
      )
      return null
    }

    if (addressList.length > 0) {
      const hasDefaultShipping = addressList.some((address) => address.default_shipping)
      const hasDefaultBilling = addressList.some((address) => address.default_billing)

      if (!hasDefaultShipping || !hasDefaultBilling) {
        setDefaultError(true)
        return null
      }
      setDefaultError(false)
    }

    if (companyTitleError || companyNSNameError || emailError) {
      setSubmitError(true)
      return null
    }

    if (isApproved && addressList.length === 0) {
      setAddressListError(true)
      return null
    }

    setAddressListError(false)
    setSubmitError(false)
    setIsSubmitting(true)

    if (adminCompaniesNS.companyNSForData || companyNSCustomerId) {
      dispatch(
        UpdateNSCompanyInfo.request({
          ns_internal_id: companyNSCustomerId ? `${companyNSCustomerId}` : '',
          body: {
            address_book_list: addressList,
            company_name: companyNSName,
            custom_form: adminCompaniesNS.companyNSForData?.custom_form || '',
            default_address: adminCompaniesNS.companyNSForData?.default_address || '',
            email,
            entity_status:adminCompaniesNS?.companyNSForData?.entity_status || '',
            foreign_or_domestic: foreignOrDomestic,
            phone: tell,
            subsidiary: adminCompaniesNS.companyNSForData?.subsidiary || '',
            terms:adminCompaniesNS.companyNSForData?.terms || ''
          }, 
          callBack: (success) => {
            if (success) {
              dispatch(
                CreateNewCompany.request({
                  body: {
                    company_title: companyTitle,
                    is_approved: isApproved,
                    ns_internal_id: adminCompaniesNS.companyNSForData?.ns_internal_id
                  },
                  callBack: (createSuccess) => {
                    setIsSubmitting(false)
                    if (createSuccess) {
                      history.push(`${ROUTES.adminPageUsersCompanies}`)
                    }
                  },
                })
              )
            }
          },
        })
      )
    } else {
      dispatch(
        CreateNewNSCompany.request({
          body: {
            address_book_list: addressList,
            company_name: companyNSName,
            email,
            foreign_or_domestic: foreignOrDomestic,
            phone: tell,
          },
          callBack: (success, newCompanyData) => {
            if (success && newCompanyData) {
              const newNSInternalId = newCompanyData.ns_internal_id
              dispatch(
                CreateNewCompany.request({
                  body: {
                    company_title: companyTitle,
                    is_approved: isApproved,
                    ns_internal_id: newNSInternalId
                  },
                  callBack: (createSuccess) => {
                    setIsSubmitting(false)
                    if (createSuccess) {
                      history.push(`${ROUTES.adminPageUsersCompanies}`)
                    }
                  },
                })
              )
            }
          },
        })
      )
    }
    return null
  }

  const onCompaniesNSInputChange = (value: any) => {
    dispatch(GetAllCompaniesNS.request({ search: value, withoutCompanyRel: 'true' }))
  }

  const handleSelectCustomer = (selectedOption: any) => {
    if (selectedOption) {
      const companyNSId = +selectedOption.ns_id
      setCompanyNSCustomerId(companyNSId)
      dispatch(GetNSCompanyById.request({ companyNSId }))
    } else {
      dispatch(ClearCompanyNSByIdActions)
      setCompanyNSCustomerId(null)
      setCustomerNSName('')
      setCompanyNSName('')
      setTell('')
      setEmail('')
      setForeignOrDomestic('')
      setAddressList([])
    }
    dispatch(ResetAddressFormsState())
  }

  const defaultCustomerNSName = useMemo(() => {
    if (customerNSName) return getValueForSelect(customerNSName)

    return ''
  }, [customerNSName])

  const nsCustomersList = useMemo(() => {
    return adminCompaniesNS.data?.map((item) => {
      return { value: item.company_name, label: item.company_name, ns_id: item.ns_internal_id }
    })
  }, [adminCompaniesNS.data])

  const selectValue = useMemo(() => {
    return foreignOrDomestic
      ? { value: foreignOrDomestic, label: foreignOrDomestic === '1' ? 'Foreign' : 'Domestic' }
      : null
  }, [foreignOrDomestic])

  const handleBackToTable = () => {
    dispatch(ResetAddressFormsState())
    history.goBack()
  }

  return (
    <div className='pb-3 bg-white'>
      <button
        type='button'
        className='py-1.5 smallMobile:py-3 w-max flex flex-row items-center h-16'
        onClick={handleBackToTable}
      >
        <IconBack />
        <div className='ml-2 text-menuLink opacity-50 hover:opacity-80'>Back to table</div>
      </button>

      <div className='flex justify-center items-center'>
        <form
          noValidate
          onSubmit={(event) => onSubmitCompanyForm(event)}
          action=''
          className='2smallest:px-2 smallMobile:px-4 w-11/12 max-w-screen-xl'
        >
          <div className='flex flex-col lg:flex-row'>
            <div className='form—company-block lg:mr-2'>
              <div className='font-circa text-2xl font-normal'>Primary Information</div>

              <div className='mb-1 p-3 bg-bluegray-50'>
                <label htmlFor='companyName' className='form-label'>
                  Company name *
                </label>
                <div>
                  <input
                    value={companyTitle || ''}
                    onChange={(event) => onEmptyInputChange(event, setCompanyTitle, setCompanyTitleError)}
                    type='text'
                    name='companyTitle'
                    id='companyTitle'
                    placeholder='Enter company name *'
                    className={`form-input ${validateButton(companyTitleError, submitError)}`}
                  />
                  <div className={`form-error-text mt-1 ${companyTitleError && submitError ? 'block' : 'hidden'}`}>
                    Please select a country.
                  </div>
                </div>
              </div>

              <div className='mb-1 p-3 self-end'>
                <label
                  htmlFor='isApproved'
                  className={`w-max-content text-sm text-gray-600 flex items-center cursor-pointer`}
                >
                  <input
                    type='checkbox'
                    id='isApproved'
                    className='hidden'
                    checked={isApproved}
                    onChange={() => setIsApproved(!isApproved)}
                  />
                  {isApproved ? <InputChecked /> : <InputNotChecked />}
                  <span className='ml-3 select-none'>Approved</span>
                </label>
              </div>

              {isApproved && (
              <>
                <div className='mb-1 p-3 bg-bluegray-50'>
                  <label htmlFor='company' className='form-label'>
                    NS customer
                  </label>
                  <div>
                    <Select
                      options={nsCustomersList}
                      isClearable
                      placeholder='Select or enter company'
                      menuPlacement='bottom'
                      value={defaultCustomerNSName}
                      onChange={(event) => handleSelectCustomer(event)}
                      onInputChange={(value) => onCompaniesNSInputChange(value)}
                      styles={styles}
                      id='company'
                    />
                  </div>
                </div>

                <div className='mb-1 p-3 bg-bluegray-50'>
                  <label htmlFor='companyName' className='form-label'>
                    NS company name *
                  </label>
                  <div>
                    <input
                      value={companyNSName || ''}
                      onChange={(event) => onEmptyInputChange(event, setCompanyNSName, setCompanyNSNameError)}
                      type='text'
                      name='NScompanyName'
                      id='NScompanyName'
                      placeholder='Enter company name *'
                      className={`form-input ${validateButton(companyNSNameError, submitError)}`}
                    />
                    <div className={`form-error-text mt-1 ${companyNSNameError && submitError ? 'block' : 'hidden'}`}>
                      Please enter a valid NS company name.
                    </div>
                  </div>
                </div>

                <div className='mb-1 p-3 bg-bluegray-50'>
                  <label htmlFor='phoneNumber' className='form-label'>
                    Phone number
                  </label>
                  <FormPhoneInput
                    userTell={tell}
                    setUserTell={setTell}
                    userTellError={false}
                    setTellError={setTellError}
                    submitError={false}
                  />
                </div>

                <div className='mb-1 p-3 bg-bluegray-50'>
                  <label htmlFor='email' className='form-label'>
                    Email *
                  </label>
                  <div>
                    <input
                      value={email || ''}
                      onChange={(event) => onEmailChange(event, setEmail, setEmailError)}
                      type='email'
                      name='email'
                      id='email'
                      placeholder='Enter Email'
                      className={`form-input ${validateButton(emailError, submitError)}`}
                    />
                    <div className={`form-error-text mt-1 ${emailError && submitError ? 'block' : 'hidden'}`}>
                      Please enter valid email address.
                    </div>
                  </div>
                </div>

                <div className='mb-1 p-3 bg-bluegray-50'>
                  <label className='form-label'>Foreign or domestic:</label>
                  <Select
                    name='foreignOrDomestic'
                    id='foreignOrDomestic'
                    options={options}
                    value={selectValue}
                    onChange={(selectedOption) => selectedOption && setForeignOrDomestic(selectedOption.value)}
                    isSearchable={false}
                    styles={stylesMulti}
                  />
                </div>
              </>
              )}
            </div>
            <div className='lg:w-[65%]'>
              {isApproved && (
              <AddressBlock 
                addressList={addressList}
                setAddressList={setAddressList}
                addNewItem={addNewAddress}
                companyNSName={companyNSName}
              />
            )}
            </div>
          </div>
          <div className='h-14'>
            {defaultError && (
              <div className='text-red-500 text-center pt-5'>
                Please set the default billing address/shipping address.
              </div>
            )}
            {addressListError && (
              <div className='text-red-500 text-center pt-5'>
                Please add the address.
              </div>
            )}
          </div>
          <div className='p-3 flex items-center justify-center'>
            <button
              type='submit'
              disabled={isAnyFormExpanded || isSubmitting}
              className='py-3 px-16 text-white bg-green-700 hover:bg-green-800 text-base rounded-md product-buttons'
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
