import React from 'react'

interface IconThLargeProps {}

export const IconThLarge: React.FC<IconThLargeProps> = () => {
  return (
    <svg
      className="svg-inline--fa fa-th-large"
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="th-large"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      data-fa-i2svg=""
    >
      <path fill="currentColor" d="M0 96C0 60.65 28.65 32 64 32H448C483.3 32 512 60.65 512 96V416C512 451.3 483.3 480 448 480H64C28.65 480 0 451.3 0 416V96zM272 240H480V96C480 78.33 465.7 64 448 64H272V240zM240 64H64C46.33 64 32 78.33 32 96V240H240V64zM32 272V416C32 433.7 46.33 448 64 448H240V272H32zM272 448H448C465.7 448 480 433.7 480 416V272H272V448z" />
    </svg>
  )
}
