import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as Actions from './actions'
import { callApi } from '../../../utils/callApi'
import { API_ROUTES } from '../../../utils/apiRoutes'
import { ActionTypes, TCompanies, TCompaniesMessage , TCompany } from './types'
import { SnackBar } from '../../../components/ui/SnackBar'

const { addSnackBar } = SnackBar()

function* getCompaniesWorker(action: ReturnType<typeof Actions.GetCompanies.request>) {
  const { callBack, offset, limit, search, sortBy, sort, category, recommendation_item_id, without_bid, with_rec } =
    action.payload as Actions.TypeGetCompaniesR
  let success = true
  let data = null

  let query = `?Offset=${offset || 0}&Limit=${limit || 1000}`
  query += search ? `&Search=${search}` : ''
  query += sortBy ? `&SortBy=${sortBy}` : ''
  query += sort ? `&Sort=${sort}` : ''
  query += category ? `&Category=${category}` : ''
  query += recommendation_item_id ? `&RecommendationItemId=${recommendation_item_id}` : ''
  query += without_bid ? `&WithoutBid=${without_bid}` : ''
  query += with_rec ? `&WithRec=${with_rec}` : ''

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.companies}${query}`,
    })) as TCompanies[]

    yield put(Actions.GetCompanies.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetCompanies.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* createNewCompanyWorker(action: ReturnType<typeof Actions.CreateNewCompany.request>) {
  const { callBack, body } = action.payload as Actions.TypeCreateNewCompany
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'post',
      path: API_ROUTES.adminCreateCompany,
      data: body,
    })) as TCompaniesMessage

    yield put(Actions.CreateNewCompany.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.CreateNewCompany.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    const successMessage = 'Company has been created'
    const errorMessage = data?.message || ''
    const snackBarMessage = success ? successMessage : errorMessage
    addSnackBar(snackBarMessage, success)
  }
}

function* updateCompanyInfoWorker(action: ReturnType<typeof Actions.UpdateCompanyInfo.request>) {
  const { callBack, body } = action.payload as Actions.TypeUpdateCompanyInfoR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'put',
      path: `${API_ROUTES.companies}`,
      data: body,
    })) as TCompaniesMessage

    yield put(Actions.UpdateCompanyInfo.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.UpdateCompanyInfo.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    const successMessage = 'Company name has been updated'
    const errorMessage = data?.message || ''
    const snackBarMessage = success ? successMessage : errorMessage
    addSnackBar(snackBarMessage, success)
  }
}

function* getCompanyByIdWorker(action: ReturnType<typeof Actions.GetCompanyById.request>) {
  const { companyId, callBack } = action.payload as Actions.TypeGetCompanyById
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.getCompanyById}/${companyId}`,
    })) as TCompany

    yield put(Actions.GetCompanyById.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetCompanyById.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.GET_COMPANIES_R, getCompaniesWorker)
  yield takeEvery(ActionTypes.UPDATE_COMPANY_INFO_R, updateCompanyInfoWorker)
  yield takeEvery(ActionTypes.GET_COMPANY_BY_ID_R, getCompanyByIdWorker)
  yield takeEvery(ActionTypes.CREATE_NEW_COMPANY_R, createNewCompanyWorker)
}

export default function* companiesSaga() {
  yield all([fork(watchRequest)])
}
