import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { callApi } from '../utils/callApi'
import { API_ROUTES } from '../utils/apiRoutes'
import { ROUTES } from '../utils/routes'
import { SECOND_DOMAIN_URL, TOKEN, WORTHY_DOMAIN_URL, WORTHY_REDIRECT } from '../utils/consts'
import { getCookie, setCookie } from '../utils/cookiesUtils'

export const useLoginFromWorthy = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [getWorthyTokenLoading, setGetWorthyTokenLoading] = React.useState(false)
  const history = useHistory()
  const location = useLocation()

  // eslint-disable-next-line consistent-return
  const getWorthyToken = async () => {
    setGetWorthyTokenLoading(true)
    try {
      const resp = await callApi({ method: 'get', path: API_ROUTES.worthyToken })
      if (resp) {
        return resp
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    } finally {
      setGetWorthyTokenLoading(false)
    }
  }

  const checkRedirectToWorthy = async () => {
    const div = document.createElement('div')

    div.id = 'errorPopup'
    document.body.style.overflow = 'hidden'
    div.className = 'flex bg-white absolute inset-0 z-[2000]'
    document.body.appendChild(div)
    const authData = await getWorthyToken()
    if (authData && authData.access_token) {
      const redirect =
        !authData.default_redirect_to_worthy || location.pathname.includes(ROUTES.diamondVideo)
          ? false
          : getCookie(WORTHY_REDIRECT) === 'true' || authData.access_token
      if (redirect) {
        let circa_path = location.pathname.startsWith('/worthy')
          ? `${SECOND_DOMAIN_URL}${location.pathname}${location.search}`
          : `${SECOND_DOMAIN_URL}/worthy${location.pathname}${location.search}`
        // @ts-ignore
        const prevLocation = location?.state?.prevLocation
        if (prevLocation && prevLocation !== '/' && prevLocation.search('/login') === -1) {
          circa_path = prevLocation.includes('/worthy')
            ? `${SECOND_DOMAIN_URL}${prevLocation}`
            : `${SECOND_DOMAIN_URL}/worthy${prevLocation}`
        }
        if (circa_path.includes('/terms')) circa_path = circa_path.replace('/terms', ROUTES.home)
        setCookie(WORTHY_REDIRECT, 'true')
        const redirected = window.location.href.includes('worthy_auth_token') || location.pathname.startsWith('/worthy')
        const newUrl = `${WORTHY_DOMAIN_URL}/tenders?circa_auth_token=${encodeURI(
          authData.access_token
        )}&circa_url=${encodeURI(circa_path)}`
        if (!redirected) {
          window.location.href = newUrl
          setTimeout(() => {
            document.body.removeChild(div)
            document.body.style.overflow = 'auto'
          }, 2000)
        } else {
          setTimeout(() => {
            document.body.removeChild(div)
            document.body.style.overflow = 'auto'
          }, 100)
        }

        // eslint-disable-next-line no-console
        console.log(newUrl, 'worthy redirect')
      } else {
        setCookie(WORTHY_REDIRECT, 'false')
        setTimeout(() => {
          document.body.removeChild(div)
          document.body.style.overflow = 'auto'
        }, 100)
      }
    } else {
      setTimeout(() => {
        document.body.removeChild(div)
        document.body.style.overflow = 'auto'
      }, 100)
    }
  }

  const loginFromWorthy = async (worthy_token: string) => {
    try {
      setIsLoading(true)

      const resp = await callApi({ method: 'post', path: API_ROUTES.signInViaToken, data: { token: worthy_token } })
      if (resp) {
        // @ts-ignore
        setCookie(TOKEN.access, resp.access_token)
        // @ts-ignore
        setCookie(TOKEN.refresh, resp.refresh_token)
        setCookie(WORTHY_REDIRECT, `${resp.default_redirect_to_worthy}`)
        if (location.pathname.includes('/login')) {
          history.replace({
            pathname: `/worthy${ROUTES.login}`,
            state: { prevLocation: `/worthy${location.pathname}${location.search}` },
          })
        } else {
          history.replace({
            pathname: `/worthy${location.pathname}`,
            search: '',
            state: { prevLocation: `/worthy${location.pathname}` },
          })
        }
        window.location.reload()
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)

      history.replace({
        pathname: `/worthy${ROUTES.login}`,
        state: { prevLocation: `/worthy${location.pathname}${location.search}` },
      })
    } finally {
      setIsLoading(false)
    }
  }
  return {
    isLoading,
    getWorthyTokenLoading,
    loginFromWorthy,
    checkRedirectToWorthy,
  }
}
