import { Reducer } from 'redux'
import { ActionTypes, TSettingMappingState } from './types'
import { getDataForPagination } from '../../../../utils/reducerUtils'

export const initialState: TSettingMappingState = {
  loadingDiamonds: false,
  loadingOptions: false,
  data: {
    options: [],
    diamonds: {
      shapes: null,
      colors: null,
      clarity: null,
      fluorescence: null,
      cut_polish_symmetry: null,
    },
  },
}

const reducer: Reducer<TSettingMappingState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_DIAMONDS_SHAPE_R:
      return { ...state, loadingDiamonds: true, errors: undefined }
    case ActionTypes.GET_DIAMONDS_SHAPE_S:
      return {
        ...state,
        loadingDiamonds: false,
        data: {
          ...state.data,
          diamonds: { ...state.data.diamonds, shapes: getDataForPagination(state.data.diamonds.shapes, action.payload) },
        },
      }
    case ActionTypes.GET_DIAMONDS_SHAPE_WITH_0_OFFSET_S:
      return {
        ...state,
        loadingDiamonds: false,
        data: { ...state.data, diamonds: { ...state.data.diamonds, shapes: action.payload } },
      }
    case ActionTypes.GET_DIAMONDS_SHAPE_E:
      return { ...state, loadingDiamonds: false, errors: action.payload }
    /// //////////////////////////////////////////////////////////////////////////
    case ActionTypes.GET_DIAMONDS_COLOR_R:
      return { ...state, loadingDiamonds: true, errors: undefined }
    case ActionTypes.GET_DIAMONDS_COLOR_S:
      return {
        ...state,
        loadingDiamonds: false,
        data: {
          ...state.data,
          diamonds: { ...state.data.diamonds, colors: getDataForPagination(state.data.diamonds.colors, action.payload) },
        },
      }
    case ActionTypes.GET_DIAMONDS_COLOR_WITH_0_OFFSET_S:
      return {
        ...state,
        loadingDiamonds: false,
        data: { ...state.data, diamonds: { ...state.data.diamonds, colors: action.payload } },
      }
    case ActionTypes.GET_DIAMONDS_COLOR_E:
      return { ...state, loadingDiamonds: false, errors: action.payload }
    /// //////////////////////////////////////////////////////////////////////////
    case ActionTypes.GET_DIAMONDS_CLARITY_R:
      return { ...state, loadingDiamonds: true, errors: undefined }
    case ActionTypes.GET_DIAMONDS_CLARITY_S:
      return {
        ...state,
        loadingDiamonds: false,
        data: {
          ...state.data,
          diamonds: { ...state.data.diamonds, clarity: getDataForPagination(state.data.diamonds.clarity, action.payload) },
        },
      }
    case ActionTypes.GET_DIAMONDS_CLARITY_WITH_0_OFFSET_S:
      return {
        ...state,
        loadingDiamonds: false,
        data: { ...state.data, diamonds: { ...state.data.diamonds, clarity: action.payload } },
      }
    case ActionTypes.GET_DIAMONDS_CLARITY_E:
      return { ...state, loadingDiamonds: false, errors: action.payload }
    /// //////////////////////////////////////////////////////////////////////////
    case ActionTypes.GET_DIAMONDS_FLUORESCENCE_R:
      return { ...state, loadingDiamonds: true, errors: undefined }
    case ActionTypes.GET_DIAMONDS_FLUORESCENCE_S:
      return {
        ...state,
        loadingDiamonds: false,
        data: {
          ...state.data,
          diamonds: {
            ...state.data.diamonds,
            fluorescence: getDataForPagination(state.data.diamonds.fluorescence, action.payload),
          },
        },
      }
    case ActionTypes.GET_DIAMONDS_FLUORESCENCE_WITH_0_OFFSET_S:
      return {
        ...state,
        loadingDiamonds: false,
        data: { ...state.data, diamonds: { ...state.data.diamonds, fluorescence: action.payload } },
      }
    case ActionTypes.GET_DIAMONDS_FLUORESCENCE_E:
      return { ...state, loadingDiamonds: false, errors: action.payload }
    /// //////////////////////////////////////////////////////////////////////////
    case ActionTypes.GET_DIAMONDS_CUT_POLISH_SYMMETRY_R:
      return { ...state, loadingDiamonds: true, errors: undefined }
    case ActionTypes.GET_DIAMONDS_CUT_POLISH_SYMMETRY_S:
      return {
        ...state,
        loadingDiamonds: false,
        data: { ...state.data,
          diamonds: {
            ...state.data.diamonds,
            cut_polish_symmetry: getDataForPagination(state.data.diamonds.cut_polish_symmetry, action.payload),
          },
        },
      }
    case ActionTypes.GET_DIAMONDS_CUT_POLISH_SYMMETRY_WITH_0_OFFSET_S:
      return {
        ...state,
        loadingDiamonds: false,
        data: { ...state.data, diamonds: { ...state.data.diamonds, cut_polish_symmetry: action.payload } },
      }
    case ActionTypes.GET_DIAMONDS_CUT_POLISH_SYMMETRY_E:
      return { ...state, loadingDiamonds: false, errors: action.payload }
    /// //////////////////////////////////////////////////////////////////////////
    case ActionTypes.GET_OPTIONS_BY_TYPE_R:
      return { ...state, loadingOptions: true, errors: undefined }
    case ActionTypes.GET_OPTIONS_BY_TYPE_S:
      return {
        ...state,
        loadingOptions: false,
        data: { ...state.data, options: [...state.data.options, ...action.payload] },
      }
    case ActionTypes.GET_OPTIONS_BY_TYPE_E:
      return { ...state, loadingOptions: false, errors: action.payload }
    /// //////////////////////////////////////////////////////////////////////////
    case ActionTypes.PUT_DIAMONDS_DICTIONARY_R:
      return { ...state, loadingDiamonds: true, errors: undefined }
    case ActionTypes.PUT_DIAMONDS_DICTIONARY_S:
      return { ...state, loadingDiamonds: false }
    case ActionTypes.PUT_DIAMONDS_DICTIONARY_E:
      return { ...state, loadingDiamonds: false, errors: action.payload }
    
    default:
      return state
  }
}

export { reducer as AdminMapping }
