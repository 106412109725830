import { Reducer } from 'redux'
import { ActionTypes, TCategoriesState } from './types'

export const initialState: TCategoriesState = {
  loading: false,
  data: null,
}

const reducer: Reducer<TCategoriesState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CATEGORIES_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_CATEGORIES_S:
      return { ...state, loading: false, errors: undefined, data: action.payload }
    case ActionTypes.GET_CATEGORIES_E:
      return { ...state, loading: false, errors: action.payload }
    
    default:
      return state
  }
}

export { reducer as CategoriesReducer }