import React from 'react'
import { Checkbox } from '../../components/ui/Checkbox'
import { EditUser } from '../../svg/EditUser'
import { onViewLikeAUser } from './EventHandlers'
import { ViewLikeAUser } from '../../svg/ViewLikeAUser'
import { Message } from '../../svg/Message'
import { getDate } from '../helpers'
import { getIconsForUsers } from './IconHelpers'
import { TAdminUsers } from '../../store/admin/users/types'
import { TCompanies, TCompaniesState } from '../../store/admin/companies/types'

export const getThead = (
  headIsChecked: boolean,
  setHeadIsChecked: (checked: boolean) => void,
  customCompanyDropdown: any,
  customRolesCategoriesDropdown: any
) => ({
  checkbox: <Checkbox label='' isChecked={headIsChecked} setIsChecked={setHeadIsChecked} customId='select-all-users' />,
  user_id: 'User ID',
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
  company_title: customCompanyDropdown,
  last_signed_in: 'Last Active',
  roles_categories: customRolesCategoriesDropdown,
  message_title: 'Message',
  action: 'Actions',
})

export const getThead2 = (
  headIsChecked: boolean,
  onCheckHead: (checked: boolean) => void,
  companies: TCompaniesState
) => ({
  checkbox: (
    <Checkbox
      label=''
      disabled={!companies.data?.companies?.length}
      isChecked={headIsChecked}
      setIsChecked={onCheckHead}
      customId='personal-recommendations-select-all-users'
    />
  ),
  company_title: 'Company',
})

export const getTbody = (
  data: TAdminUsers[],
  isAssociateAdmin: boolean,
  onEditUser: (userId: number) => void,
  dispatch: any,
  onMessage: (userId: number) => void,
  bodyAreChecked: Set<number>,
  onBodyAreChecked: (userId: number) => void
) => {
  return data.map((user) => {
    const hideOptions = isAssociateAdmin && user.role_id === 1
    const action = (
      <div className='flex gap-x-1'>
        <button
          type='button'
          className={`w-6 h-4 flex justify-center ${hideOptions && 'invisible'}`}
          onClick={() => onEditUser(user.user_id)}
        >
          <div className='absolute -mt-0.5'>
            <EditUser />
          </div>
        </button>
        <button
          type='button'
          className={`w-6 h-4 flex justify-center ${hideOptions && 'invisible'}`}
          onClick={() => onViewLikeAUser(user.user_id, dispatch)}
        >
          <div className='absolute'>
            <ViewLikeAUser />
          </div>
        </button>
        <button type='button' className='w-6 h-4 flex justify-center' onClick={() => onMessage(user.user_id)}>
          <div className='absolute'>
            <Message />
          </div>
        </button>
      </div>
    )

    return {
      checkbox: (
        <Checkbox
          classname='mx-auto'
          label=''
          isChecked={bodyAreChecked.has(user.user_id)}
          setIsChecked={() => onBodyAreChecked(user.user_id)}
        />
      ),
      user_id: user.user_id,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      company_title: user.company_title,
      last_signed_in: getDate(user.last_signed_in),
      roles_categories: getIconsForUsers(user.role_id, user.full_access_categories, user.view_only_categories),
      message_title: user.message_title,
      action,
    }
  })
}

export const getTbody2 = (
  data: TCompanies[],
  bodyAreChecked: Set<number>,
  onBodyAreChecked: (companyId: number) => void
) => {
  return data.map((company) => {
    return {
      checkbox: (
        <Checkbox
          classname='mx-auto'
          label=''
          disabled={company.with_bid}
          isChecked={bodyAreChecked.has(company.id)}
          setIsChecked={() => onBodyAreChecked(company.id)}
        />
      ),
      company_title: company.company_title,
      class_name: company.with_bid ? 'bg-green-50' : '',
    }
  })
}
