import { Actions } from '../../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../../types/actions'


const GetAllCompaniesNSActions = new Actions('GET_All_NS_COMPANIES', ActionTypes)


export interface TypeGetAllCompaniesNS extends TDefRequest {
  offset?: number
  limit?: number
  search?: string
  withoutCompanyRel?: string
  onlyNotSynced?: boolean
}

export const GetAllCompaniesNS = {
  request: (payload?: TypeGetAllCompaniesNS) => GetAllCompaniesNSActions.request(payload || {}),
  success: (payload: any) => GetAllCompaniesNSActions.success(payload),
  error: (payload: string) => GetAllCompaniesNSActions.error(payload),
}

/// ////////////////////////////////////////////////////////////////

const GetNSCompanyByIdActions = new Actions('GET_NS_COMPANY_BY_ID', ActionTypes)

export interface TypeGetNSCompanyById extends TDefRequest {
  companyNSId: number
}

export const GetNSCompanyById = {
  request: (payload: TypeGetNSCompanyById) => GetNSCompanyByIdActions.request(payload),
  success: (payload: any) => GetNSCompanyByIdActions.success(payload),
  error: (payload: string) => GetNSCompanyByIdActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////

const GetNSCountriesActions = new Actions('GET_NS_COUNTRIES', ActionTypes)

export interface TypeGetNSCountries extends TDefRequest {
  offset?: number
  limit?: number
  search?: string
}

export const GetNSCountries = {
  request: (payload: TypeGetNSCountries) => GetNSCountriesActions.request(payload),
  success: (payload: any) => GetNSCountriesActions.success(payload),
  error: (payload: string) => GetNSCountriesActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////////////////////////////

export const ClearCompanyNSByIdActions = {type: ActionTypes.CLEAR_NS_COMPANY_BY_ID, payload: {} }

/// ///////////////////////////////////////////////////////////////////////

const CreateNewNSCompanyActions = new Actions('CREATE_NEW_NS_COMPANY', ActionTypes)
export interface TypeCreateNewNSCompany extends TDefRequest {
  body: {
    address_book_list: TNSCompaniesAddress[]
    company_name: string
    email: string
    foreign_or_domestic: string
    phone: string
  }
}

export const CreateNewNSCompany = {
  request: (payload: TypeCreateNewNSCompany) => CreateNewNSCompanyActions.request(payload),
  success: (payload: any) => CreateNewNSCompanyActions.success(payload),
  error: (payload: string) => CreateNewNSCompanyActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const UpdateNSCompanyInfoActions = new Actions('UPDATE_NS_COMPANY_INFO', ActionTypes)

export interface TypeUpdateNSCompanyInfo extends TDefRequest {
  ns_internal_id: string
  body: {
    address_book_list: TNSCompaniesAddress[]
    company_name: string
    custom_form: string
    default_address: string
    email: string
    entity_status: string
    foreign_or_domestic: string
    phone: string
    subsidiary: string
    terms: string
  }
}

export const UpdateNSCompanyInfo = {
  request: (payload: TypeUpdateNSCompanyInfo) => UpdateNSCompanyInfoActions.request(payload),
  success: (payload: any) => UpdateNSCompanyInfoActions.success(payload),
  error: (payload: string) => UpdateNSCompanyInfoActions.error(payload),
}

export interface TNSCompaniesAddress {
  addr1: string
  addr2: string
  addr_phone: string
  addressee: string
  attention: string
  city: string
  country: string
  default_billing: boolean
  default_shipping: boolean
  state: string
  zip: string
}