/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo, useState } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ROUTES } from '../utils/routes'
import { IconBack } from '../svg/IconBack'
import { Checkbox } from '../components/ui/Checkbox'
import { Email } from './SettingContent/Cms/Messages/Email'
import { Sms } from './SettingContent/Cms/Messages/Sms'
import { MessageState } from './SettingContent/Cms/EditCms'
import { TemplateComponent } from '../components/ui/createMessageComponent/TemplateComponent'
import { TCmsTemplates } from '../store/admin/settings/cms/types'
import { TTenders } from '../store/admin/tenders/types'
import { AppStore } from '../store/applicationState'
import { SendMessageToUsers } from '../store/admin/settings/cms/actions'
import { EmailPreview } from '../components/ui/EmailPreview'
import { P404 } from '../pages'
import { SetLoader } from '../store/globalState/action'
import { callApi } from '../utils/callApi'
import { API_ROUTES } from '../utils/apiRoutes'
import { SnackBar } from '../components/ui/SnackBar'

export interface createMessageFilesType {
  data: { file_base_64: string; file_name: string; size: number }[]
  size: number
}

export const CreateMessage = () => {
  const { addSnackBar } = SnackBar()

  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { cms } = useSelector((store: AppStore) => store)

  // @ts-ignore
  const [searchParams] = useState(location?.state?.search || '')

  const [preferred, setPreferred] = useState(true)
  const [email, setEmail] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [whatsApp, setWhatsApp] = useState(false)
  const [sms, setSms] = useState(false)
  const [ical, setIcal] = useState(false)
  const [catalog, setCatalog] = useState(false)

  const [emailState, setEmailState] = useState<MessageState>({ body: '', footer: '', subject: '' })
  // const [whatsAppState, setWhatsAppState] = useState<MessageState>({ body: '', footer: '', subject: '' })
  const [smsState, setSmsState] = useState<MessageState>({ body: '', footer: '', subject: '' })

  const [choosedTemplate, setChoosedTemplate] = useState<TCmsTemplates | null>(null)
  const [choosedTender, setChoosedTender] = useState<TTenders | null>(null)

  const [emailPreviewState, setEmailPreviewState] = useState<null | { body: string; subject: string }>(null)

  const [files, setFiles] = useState<createMessageFilesType>({ data: [], size: 0 })

  const current = useMemo(() => {
    return (
      choosedTemplate?.messages && {
        email: choosedTemplate?.messages.find((item) => item.message_method_id === 1) || null,
        sms: choosedTemplate?.messages.find((item) => item.message_method_id === 2) || null,
        // whatsApp: choosedTemplate?.messages.find(item => item.message_method_id === 3) || null,
      }
    )
  }, [choosedTemplate?.messages])

  useEffect(() => {
    setEmail(!!current?.email?.active)
    // setWhatsApp(!!current?.whatsApp?.active)
    setSms(!!current?.sms?.active)

    setEmailState({
      body: current?.email?.body || '',
      footer: current?.email?.footer || '',
      subject: current?.email?.subject || '',
    })
    // setWhatsAppState({
    //   body: current?.whatsApp?.body || '',
    //   footer: current?.whatsApp?.footer || '',
    //   subject: current?.whatsApp?.subject || '',
    // })
    setSmsState({
      body: current?.sms?.body || '',
      footer: current?.sms?.footer || '',
      subject: current?.sms?.subject || '',
    })

    setIcal(!!current?.email?.have_ical)
    setCatalog(!!current?.email?.have_catalog)
  }, [current])

  useEffect(() => {
    if (choosedTemplate) setPreferred(choosedTemplate.preferred_delivery_method)
  }, [choosedTemplate])

  useEffect(() => {
    if (!cms.currentUsersId || !cms.currentUsersId.length) history.push(ROUTES.adminPageUsersCustomers)
  }, [])

  const onSendMessage = () => {
    if (!cms.currentUsersId) return null

    dispatch(
      SendMessageToUsers.request({
        body: {
          attachments: files.data,
          have_catalog: catalog,
          have_ical: ical,
          preferred,
          template_id: choosedTemplate?.messaging_template_id,
          tender_id: choosedTender?.id,
          users_ids: cms.currentUsersId!, // should exist
          email: {
            active: email,
            body: emailState.body,
            footer: emailState.footer,
            subject: emailState.subject,
          },
          // whats_app: {
          //   active: whatsApp,
          //   body: '-',
          // body: whatsAppState.body,
          // footer: whatsAppState.footer,
          // subject: whatsAppState.subject,
          // },
          sms: {
            active: sms,
            body: smsState.body,
            footer: smsState.footer,
            subject: smsState.subject,
          },
        },
        callBack: (success) => success && history.goBack(),
      })
    )
    return true
  }

  const onShowPreview = () => {
    dispatch(SetLoader())
    callApi({
      method: 'put',
      path: `${API_ROUTES.adminCms}/messages/preview`,
      data: {
        body: emailState.body,
        footer: emailState.footer,
        message_method_id: current?.email?.message_method_id || 1,
        subject: emailState.subject,
        tender_id: choosedTender?.id,
      },
    })
      .then((res) => setEmailPreviewState({ body: res.body, subject: res.subject }))
      .catch(({ data }) => addSnackBar(data.message, false))
  }

  return (
    <>
      <button
        type='button'
        className='py-3 w-max flex flex-row items-center h-16'
        onClick={() => history.push(`${ROUTES.adminPageUsersCustomers}${searchParams}`)}
      >
        <IconBack />
        <div className='ml-2 text-menuLink opacity-50 hover:opacity-80'>Back to table</div>
      </button>
      <h1 className='text-xl font-semibold border-lightblue-900 text-lightblue-900'>Create Message</h1>
      <div className='my-8 flex flex-wrap gap-y-4 gap-x-2 justify-between'>
        <div
          style={{ background: '#F8FAFC' }}
          className='p-4 rounded flex flex-col md:flex-row gap-y-4 w-full lg:w-auto'
        >
          <span className='font-semibold border-lightblue-900 text-lightblue-900'>Channel:</span>
          <div className='gap-x-4 flex flex-wrap justify-between gap-y-4 md:ml-5'>
            <Checkbox
              label='Preferred'
              isChecked={preferred}
              variant='green'
              setIsChecked={() => setPreferred(!preferred)}
            />
            <Checkbox label='Email' isChecked={email} variant='green' setIsChecked={() => setEmail(!email)} />
            {/* <Checkbox label='WhatsApp' isChecked={whatsApp} variant='green' setIsChecked={() => setWhatsApp(!whatsApp)} /> */}
            <Checkbox label='Sms' isChecked={sms} variant='green' setIsChecked={() => setSms(!sms)} />
          </div>
        </div>
        <div
          style={{ height: 'auto' }}
          className='text-green-800 border ml-auto border-green-200 bg-green-50 rounded-3xl py-1 px-5 self-center'
        >
          {cms.currentUsersId?.length || 0}
          Selected
        </div>
      </div>
      <div className='bg-white pb-10'>
        <div className='flex relative justify-start gap-x-14 mb-6 text-base overflow-x-auto border-lightblue-900 text-lightblue-900'>
          <div
            className={`py-2 cursor-pointer z-10 hover:text-lightblue-700 ${
              location.pathname.search(`${ROUTES.adminPageUsersCustomers}/create-message/email`) !== -1 &&
              'border-lightblue-900 text-lightblue-900 font-semibold border-b-2'
            }`}
            onClick={() => history.push(`${ROUTES.adminPageUsersCustomers}/create-message/email`)}
          >
            Email
          </div>
          {/* <div */}
          {/*  className={`py-2 cursor-pointer z-10 hover:text-lightblue-700 ${location.pathname.search(`${ROUTES.adminPageUsersCustomers}/create-message/whats-app`) !== -1 && 'border-lightblue-900 text-lightblue-900 border-b-2 font-semibold'}`} */}
          {/*  onClick={() => history.push(`${ROUTES.adminPageUsersCustomers}/create-message/whats-app`)} */}
          {/* > */}
          {/*  WhatsApp */}
          {/* </div> */}
          <div
            className={`py-2 cursor-pointer z-10 hover:text-lightblue-700 ${
              location.pathname.search(`${ROUTES.adminPageUsersCustomers}/create-message/sms`) !== -1 &&
              'border-lightblue-900 text-lightblue-900 border-b-2 font-semibold'
            }`}
            onClick={() => history.push(`${ROUTES.adminPageUsersCustomers}/create-message/sms`)}
          >
            SMS
          </div>
          {location.pathname.search(`${ROUTES.adminPageUsersCustomers}/create-message/email`) !== -1 && (
            <button
              type='button'
              className='ml-auto border hover:bg-white active:text-white active:bg-lightblue-800 whitespace-nowrap border-bluegray-300 rounded-t p-2'
              onClick={onShowPreview}
            >
              Preview Message
            </button>
          )}
          {emailPreviewState && (
            <EmailPreview
              subject={emailPreviewState.subject}
              html={emailPreviewState.body}
              onClose={() => setEmailPreviewState(null)}
            />
          )}
          <div className='absolute h-px margin-l-r-0 left-0 right-0 bottom-0 bg-bluegray-300' />
        </div>
        <div style={{ background: '#F8FAFC' }} className='flex flex-col gap-y-4 lg:flex-row p-3'>
          <Switch>
            <Route
              path={[
                `${ROUTES.adminPageUsersCustomers}/create-message/email`,
                `/worthy${ROUTES.adminPageUsersCustomers}/create-message/email`,
              ]}
            >
              <Email
                emailState={emailState}
                setEmailState={setEmailState}
                isCatalog={!!current?.email?.have_catalog}
                catalog={catalog}
                setCatalog={setCatalog}
                isIcal={!!current?.email?.have_ical}
                ical={ical}
                setIcal={setIcal}
                disabled={(!!choosedTemplate && !choosedTender) || !(email || preferred)}
                files={files}
                setFiles={setFiles}
              />
            </Route>
            {/* <Route path={`${ROUTES.adminPageUsersCustomers}/create-message/whats-app`}> */}
            {/*  <WhatsApp */}
            {/*    whatsAppState={whatsAppState} */}
            {/*    setWhatsAppState={setWhatsAppState} */}
            {/*    disabled={(!!choosedTemplate && !choosedTender) || !(whatsApp || preferred)} */}
            {/*  /> */}
            {/* </Route> */}
            <Route
              path={[
                `${ROUTES.adminPageUsersCustomers}/create-message/sms`,
                `/worthy${ROUTES.adminPageUsersCustomers}/create-message/sms`,
              ]}
            >
              <Sms
                smsState={smsState}
                setSmsState={setSmsState}
                disabled={(!!choosedTemplate && !choosedTender) || !(sms || preferred)}
              />
            </Route>
            <Route path='*' component={P404} />
          </Switch>
          <div className='flex flex-col gap-y-6 justify-between lg:w-1/2 desktop:w-1/3 lg:pl-2.5'>
            <TemplateComponent
              choosedTemplate={choosedTemplate}
              setChoosedTemplate={setChoosedTemplate}
              choosedTender={choosedTender}
              setChoosedTender={setChoosedTender}
            />
            <button
              type='submit'
              className='py-3 px-16 text-white bg-green-700 hover:bg-green-800 text-base rounded-md product-buttons'
              onClick={onSendMessage}
            >
              Send message
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
