export interface TCategories {
  category_id: number,
  urlhash: string
}

export interface TCategoriesMessage {
  message: string
}

export interface TCategoriesState {
  loading: boolean
  data: TCategories[] | null
  errors?: string | undefined
}

export enum ActionTypes {
  GET_CATEGORIES_R = '@@CATEGORIES/GET_CATEGORIES_R',
  GET_CATEGORIES_S = '@@CATEGORIES/GET_CATEGORIES_S',
  GET_CATEGORIES_E = '@@CATEGORIES/GET_CATEGORIES_E',
  
}