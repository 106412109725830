import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import jwt_decode from 'jwt-decode'
import { Container } from '../../wrappers/Container'
import { validateButton } from '../../utils/userUtils/FormStyles'
import { onEmailChange, onPasswordChangeOld } from '../../utils/userUtils/EventHandlers'
import { PostSignIn, TypePostSignInR } from '../../store/auth/actions'
import { IconLogo } from '../../svg/IconLogo'
import { ROUTES } from '../../utils/routes'
import { Spinner } from '../../svg/Spinner'
import { AppStore } from '../../store/applicationState'
import { PopUp } from '../../components/ui/PopUp'
import { checkToken, ReturnType } from '../../utils/checkToken'

interface LoginProps {}

export const Login: React.FC<LoginProps> = () => {
  const [userEmail, setUserEmail] = useState('')
  const [userPassword, setUserPassword] = useState('')
  const [emailError, setEmailError] = useState(true)
  const [passwordError, setPasswordError] = useState(true)
  const [submitError, setSubmitError] = useState(false)
  const [popUpChildren, setPopUpChildren] = useState('')
  const [logOutPopChild, setLogOutPopChild] = useState('')

  const { signIn } = useSelector((store: AppStore) => store)

  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (location.hash === '#endofsession') setLogOutPopChild('You have been logged in on another device.')
    else if (location.hash === '#sessionisexpired')
      setLogOutPopChild('Your session has expired please log in to continue.')
  }, [location.hash])

  const callBack = (success: boolean, data: any) => {
    if (success && data?.access_token) {
      const decodeToken = jwt_decode(data?.access_token) as any
      // const userData = JSON.parse(decodeToken?.user_data || {})

      // @ts-ignore
      const prevLocation = location?.state?.prevLocation
      if (prevLocation && prevLocation !== '/' && prevLocation.search('/login') === -1)
        return history.replace(prevLocation)

      if (decodeToken?.permissions?.id_of_admin_role) history.replace(ROUTES.adminPageTender)
      else history.replace(ROUTES.dashboard)
    } else if (success) {
      setPopUpChildren(data?.message)
    }
    return null
  }

  const SignInFunc = async (data: TypePostSignInR) => {
    const userToken: ReturnType = await checkToken()
    if (userToken.success) window.location.reload()
    else dispatch(PostSignIn.request({ ...data, callBack }))
  }

  const onSubmit = () => SignInFunc({ email: userEmail, password: userPassword, end_previous_session: true })
  const onCancel = () => setPopUpChildren('')

  const onSubmitForm = (event: any) => {
    event.preventDefault()

    if (emailError || passwordError) {
      setSubmitError(true)
      return null
    }

    setSubmitError(false)
    return SignInFunc({ email: userEmail, password: userPassword, end_previous_session: false })
  }

  return (
    <Container>
      <div className='page-content relative max-w-screen-xl mx-auto flex justify-center items-center text-sm lg:pt-9 smallMobile:pt-16 pt-20'>
        <div className='container mx-auto'>
          <div className='max-w-md mx-auto my-10'>
            <div className='text-center' role='button'>
              <h1 className='my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200'>
                <IconLogo />
              </h1>
            </div>

            <div className='m-7'>
              <form noValidate onSubmit={(event) => onSubmitForm(event)} action='' className='caret-button-reports'>
                <div className='mb-6'>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor='email' className='block mb-2 text-base text-gray-600 dark:text-gray-400'>
                    Email address:
                  </label>
                  <div>
                    <input
                      value={userEmail}
                      onChange={(event) => onEmailChange(event, setUserEmail, setEmailError)}
                      type='email'
                      name='email'
                      id='email'
                      placeholder='E-mail'
                      className={`form-input ${validateButton(emailError, submitError)}`}
                    />
                    <div className={`form-error-text mt-1 ${emailError && submitError ? 'block' : 'hidden'}`}>
                      Please enter valid email address.
                    </div>
                  </div>
                </div>

                <div className='mb-8'>
                  <div className='flex justify-between mb-2'>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor='loginPassword' className='text-base text-gray-600 dark:text-gray-400'>
                      Password:
                    </label>
                  </div>
                  <div>
                    <input
                      value={userPassword}
                      onChange={(event) => onPasswordChangeOld(event, setUserPassword, setPasswordError)}
                      type='password'
                      name='password'
                      id='loginPassword'
                      placeholder='Your Password'
                      className={`form-input ${validateButton(passwordError, submitError)}`}
                    />

                    <div className={`form-error-text mt-1 ${passwordError && submitError ? 'block' : 'hidden'}`}>
                      Please enter a password.
                    </div>
                  </div>
                </div>
                <div className='mb-6 relative'>
                  <button
                    type='submit'
                    className='p-3 font-semibold text-base bg-sky-500 rounded-md text-white w-full uppercase hover:bg-sky-600 '
                    style={signIn.loading ? { color: 'transparent' } : {}}
                  >
                    Sign in
                  </button>
                  {signIn.loading && (
                    <div className='absolute' style={{ top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                      <Spinner />
                    </div>
                  )}
                </div>

                <div>
                  <Link
                    to={ROUTES.forgotPass}
                    className='text-base text-gray-400 focus:outline-none focus:text-button-reports hover:text-button-reports'
                  >
                    Forgot password?
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {popUpChildren && (
          <PopUp onSubmit={onSubmit} onCancel={onCancel}>
            {popUpChildren}
          </PopUp>
        )}
        {logOutPopChild && (
          <PopUp yesText='Ok' onSubmit={() => setLogOutPopChild('')}>
            {logOutPopChild}
          </PopUp>
        )}
      </div>
    </Container>
  )
}
