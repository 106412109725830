import React from 'react'

export const DownloadDark = () => {
  return (
    <svg
      className="svg-inline--fa fa-file-download fa-w-12 w-3 inline-block align-top text-bluegray-500"
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="file-download"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
      data-fa-i2svg=""
    >
      <g className="fa-group">
        <path
          className="fa-secondary"
          fill="currentColor"
          d="M384 128H272a16 16 0 0 1-16-16V0H24A23.94 23.94 0 0 0 0 23.88V488a23.94 23.94 0 0 0 23.88 24H360a23.94 23.94 0 0 0 24-23.88V128zm-83.55 219.36L204 443.06a17.06 17.06 0 0 1-24 0l-96.42-95.7C73.42 337.29 80.54 320 94.82 320H160v-80a16 16 0 0 1 16-16h32a16 16 0 0 1 16 16v80h65.18c14.28 0 21.4 17.29 11.27 27.36z"
        />
        <path
          className="fa-primary"
          fill="white"
          d="M377 105L279.1 7a24 24 0 0 0-17-7H256v112a16 16 0 0 0 16 16h112v-6.1a23.9 23.9 0 0 0-7-16.9zm-87.82 215H224v-80a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v80H94.82c-14.28 0-21.4 17.29-11.24 27.36l96.42 95.7a17.06 17.06 0 0 0 24 0l96.45-95.7c10.13-10.07 3.01-27.36-11.27-27.36z"
        />
      </g>
    </svg>
  )
}
