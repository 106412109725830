import { Actions } from '../../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../../types/actions'


/// ///////////////////////////////////////////////////////////////////////
const GetRolesActions = new Actions('GET_ROLES', ActionTypes)

export interface TypeGetRolesR extends TDefRequest {}

export const GetRoles = {
  request: (payload?: TypeGetRolesR) => GetRolesActions.request(payload || {}),
  success: (payload: any) => GetRolesActions.success(payload),
  error: (payload: string) => GetRolesActions.error(payload),
}


/// ///////////////////////////////////////////////////////////////////////
