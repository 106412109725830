import { Reducer } from 'redux'
import { ActionTypes, TUserState } from './types'

export const initialState: TUserState = {
  loading: false,
  data: null,
  isExecutiveAdmin: false,
  isAssociateAdmin: false,
  fullAccessCategories: [],
  viewOnlyCategories: [],
}

const reducer: Reducer<TUserState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_USER_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_USER_S:
      return { ...state, loading: false, errors: undefined, data: action.payload }
    case ActionTypes.GET_USER_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.SET_USER_DATA_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.SET_USER_DATA_S:
      return { ...state, loading: false, errors: undefined }
    case ActionTypes.SET_USER_DATA_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.SET_USER_PASSWORD_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.SET_USER_PASSWORD_S:
      return { ...state, loading: false, errors: undefined, data: action.payload }
    case ActionTypes.SET_USER_PASSWORD_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.SET_EXTRA_DATA:
      return { ...state, ...action.payload }

    case ActionTypes.SET_USER_IS_EXECUTIVE_ADMIN:
      return { ...state, isExecutiveAdmin: action.payload }
    case ActionTypes.SET_USER_IS_ASSOCIATE_ADMIN:
      return { ...state, isAssociateAdmin: action.payload }

    case ActionTypes.SET_FULL_ACCESS_CATEGORIES:
      return { ...state, fullAccessCategories: action.payload }
    case ActionTypes.SET_VIEW_ONLY_CATEGORIES:
      return { ...state, viewOnlyCategories: action.payload }

    default:
      return state
  }
}

export { reducer as UserReducer }
