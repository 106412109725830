/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { AppStore } from '../../store/applicationState'
import { searchProps, useSearch } from '../../hooks/useSearch'
import { paginationProps, usePagination } from '../../hooks/usePagination'
import { PopUpForCsv, PopUpForCsvDataType } from '../../components/ui/PopUpForCsv'
import { useFilterForUsers } from '../../hooks/useFilterForUsers'
import { GetAllUsers, PostUsersCsvTemplate } from '../../store/admin/users/actions'
import { SetLoader } from '../../store/globalState/action'
import { addNewParams } from '../../utils/helpers'
import { CompanyDropdown } from '../../components/ui/usersUI/CompanyDropdown'
import { getUniqueId, uploadFileFormData } from '../../utils/FileUtils'
import { RolesCategoriesDropdown } from '../../components/ui/usersUI/RolesCategoriesDropdown'
import { getTbody, getThead } from '../../utils/userUtils/userDataHelper'
import { onAddUser, onCreateMessage, onEditUser } from '../../utils/userUtils/EventHandlers'
import { Input } from '../../components/ui/adminComponents/Input'
import { Button } from '../../components/ui/adminComponents/Button'
import { CsvDropdown } from '../../components/ui/usersUI/CsvDropdown'
import { LoaderPoints } from '../../components/ui/LoaderPoints'
import { Sort } from '../../svg/Sort'
import { GetCompanies } from '../../store/admin/companies/actions'

const tableCellWidth = [55, 85, 140, 140, 300, 150, 200, 185, 110, 90]

export const Customers = () => {
  const limitAmount = 400
  const selectedKey = useRef<{ sort: 'down' | 'up'; sortBy: string } | null>(null)

  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()

  const { adminUsers, userData, roles, categories, companies } = useSelector((store: AppStore) => store)
  const { search, sort, sortBy, rolesId, fullAccessCategoriesId, viewOnlyCategoriesId, companiesId } =
    queryString.parse(location.search)

  const rolesIdParsed = typeof rolesId === 'string' && rolesId && rolesId.split('-').map((id) => +id)
  const fullAccessCategoriesIdParsed =
    typeof fullAccessCategoriesId === 'string' &&
    fullAccessCategoriesId &&
    fullAccessCategoriesId.split('-').map((id) => +id)
  const viewOnlyCategoriesIdParsed =
    typeof viewOnlyCategoriesId === 'string' && viewOnlyCategoriesId && viewOnlyCategoriesId.split('-').map((id) => +id)
  const companiesIdParsed =
    typeof companiesId === 'string' && companiesId ? companiesId.split('-').map((id) => +id) : []

  const initialParams = {
    limit: limitAmount,
    offset: 0,
    search: (typeof search === 'string' && search) || undefined,
    sort: (typeof sort === 'string' && sort) || '',
    sortBy: (typeof sortBy === 'string' && sortBy) || '',
  }

  const [params, setParams] = useState<searchProps | paginationProps>(initialParams)
  const [dispatchTime, setDispatchTime] = useState(0)
  const [selectedRoles, setSelectedRoles] = useState<number[]>(rolesIdParsed || [])
  const [selectedFullAccessCategories, setSelectedFullAccessCategories] = useState<number[]>(
    fullAccessCategoriesIdParsed || []
  )
  const [selectedViewOnlyCategories, setSelectedViewOnlyCategories] = useState<number[]>(
    viewOnlyCategoriesIdParsed || []
  )
  const [selectedCompanies, setSelectedCompanies] = useState<number[]>(companiesIdParsed || [])
  const [dataCsvPopUp, setDataCsvPopUp] = useState<PopUpForCsvDataType[] | null>(null)
  const [headIsChecked, setHeadIsChecked] = useState<boolean>(false)
  const [bodyAreChecked, setBodyAreChecked] = useState<Set<number>>(new Set())
  const [choseSize, setChoseSize] = useState(0)

  
  const isRendered = useRef<any>(null)

  usePagination(adminUsers.data?.users, setParams, limitAmount)
  useSearch(limitAmount, params, setParams, setDispatchTime, isRendered)
  useFilterForUsers({
    limitAmount,
    params,
    setCallback: setParams,
    selectedRoles,
    setSelectedRoles,
    selectedFullAccessCategories,
    setSelectedFullAccessCategories,
    selectedViewOnlyCategories,
    setSelectedViewOnlyCategories,
    selectedCompanies,
    setSelectedCompanies,
    setDispatchTime,
    isRendered,
  })

  useEffect(() => {
    isRendered.current = true
    dispatch(
      GetAllUsers.request({
        offset: params.offset,
        limit: params.limit,
        search: params.search,
        sortBy: params.sortBy,
        sort: params.sort,
        companyId: selectedCompanies.length !== companies.data?.companies?.length ? selectedCompanies : undefined,
        roleId: selectedRoles,
        categoryId: selectedFullAccessCategories,
        readOnlyCategoryId: selectedViewOnlyCategories,
      })
    )
  }, [params.offset, params.limit, params.sortBy, params.sort, dispatchTime])

  useEffect(() => {
    dispatch(GetCompanies.request())
  }, [])

  useEffect(() => {
    if (adminUsers.data && headIsChecked) {
      if (params.limit !== 10000) {
        dispatch(SetLoader())
        setParams((prev) => ({ ...prev, offset: adminUsers.data!.users.length, limit: 10000 }))
      }
      setBodyAreChecked(new Set(adminUsers.data.users.map((item) => item.user_id)))
    }
  }, [headIsChecked, adminUsers.data])

  useEffect(() => {
    if (!headIsChecked) setBodyAreChecked(new Set())
  }, [headIsChecked])

  useEffect(() => setChoseSize(bodyAreChecked.size), [bodyAreChecked.entries()])

  /// ///////////////////////////////////////////////////////////////////////////
  const onClearRoleAndCategoriesFilters = () => {
    setSelectedRoles([])
    setSelectedFullAccessCategories([])
    setSelectedViewOnlyCategories([])
  }

  const callBack = (sortByValue: string) => {
    if (sortByValue === 'action' || sortByValue === 'roles_categories' || sortByValue === 'message_title') return

    let value: { sortBy: string; sort: 'ASC' | 'DESC' }
    if (sortByValue !== params.sortBy || params.sort === 'DESC') value = { sortBy: sortByValue, sort: 'ASC' }
    else value = { sortBy: sortByValue, sort: 'DESC' }

    addNewParams('sort', value.sort)
    addNewParams('sortBy', value.sortBy)

    setParams((prev) => ({ ...prev, offset: 0, limit: limitAmount, sortBy: value.sortBy, sort: value.sort }))
  }

  const onBodyAreChecked = (userId: number) => {
    if (bodyAreChecked.delete(userId)) setBodyAreChecked(bodyAreChecked)
    else setBodyAreChecked(bodyAreChecked.add(userId))
    setChoseSize(bodyAreChecked.size)
  }

  const onSelectAllCompanies = (value: boolean) => {
    if (!value) setSelectedCompanies([])
    else setSelectedCompanies(companies.data?.companies?.map((item) => item.id) || [])
  }


  const onUploadCsvTemplate = () => {
    const onLoad = (file: any) => {
      dispatch(
        PostUsersCsvTemplate.request({
          file,
          callBack: (success, data) => {
            if (params.offset !== 0 || params.limit !== limitAmount)
              setParams((prev) => ({
                ...prev,
                offset: 0,
                limit: limitAmount,
              }))
            else setDispatchTime((prev) => prev + 1)

            if (!success && data?.data) {
              const result: PopUpForCsvDataType[] = Object.keys(data.data).map((item) => ({
                column: item,
                ...data.data[item],
              }))
              setDataCsvPopUp(result)
            }
          },
        })
      )
    }
    uploadFileFormData('.csv', onLoad)
  }

  const customCompanyDropdown = useMemo(
    () => (
      <CompanyDropdown
        solidIcon={!!companies.data && selectedCompanies.length !== companies.data.companies?.length}
        callBack={(value) => {
          callBack(value)
          selectedKey.current = {
            sort:
              selectedKey.current?.sortBy === 'company_title' && selectedKey.current?.sort === 'down' ? 'up' : 'down',
            sortBy: value,
          }
        }}
        selectedKey={selectedKey.current}
        onSetAllChecked={onSelectAllCompanies}
        companiesData={companies.data?.companies || undefined}
        selectedCompanies={selectedCompanies}
        setSelectedCompanies={(companyId) =>
          setSelectedCompanies((prev) =>
            prev.some((item) => item === companyId) ? prev.filter((value) => value !== companyId) : [...prev, companyId]
          )
        }
      />
    ),
    [companies.data, selectedCompanies, params]
  )

  const customRolesCategoriesDropdown = useMemo(
    () => (
      <RolesCategoriesDropdown
        soledIcon={
          !!selectedFullAccessCategories.length || !!selectedViewOnlyCategories.length || !!selectedRoles.length
        }
        onClearRoleAndCategoriesFilters={onClearRoleAndCategoriesFilters}
        categoriesData={categories.data || undefined}
        selectedFullAccessCategories={selectedFullAccessCategories}
        setSelectedFullAccessCategories={(categoryId) =>
          setSelectedFullAccessCategories((prev) =>
            prev.some((item) => item === categoryId)
              ? prev.filter((value) => value !== categoryId)
              : [...prev, categoryId]
          )
        }
        selectedViewOnlyCategories={selectedViewOnlyCategories}
        setSelectedViewOnlyCategories={(categoryId) =>
          setSelectedViewOnlyCategories((prev) =>
            prev.some((item) => item === categoryId)
              ? prev.filter((value) => value !== categoryId)
              : [...prev, categoryId]
          )
        }
        rolesData={roles.data || undefined}
        selectedRoles={selectedRoles}
        setSelectedRoles={(roleId) =>
          setSelectedRoles((prev) =>
            prev.some((item) => item === roleId) ? prev.filter((value) => value !== roleId) : [...prev, roleId]
          )
        }
      />
    ),
    [selectedFullAccessCategories, selectedViewOnlyCategories, selectedRoles, categories.data, roles.data]
  )

  const memorizedThead = useMemo(
    () =>
      getThead(
        headIsChecked,
        (checked: boolean) => setHeadIsChecked(checked),
        customCompanyDropdown,
        customRolesCategoriesDropdown
      ),
    [customCompanyDropdown, customRolesCategoriesDropdown, headIsChecked]
  )

  const memorizedTBody = useMemo(() => {
    const memorizedUsers = adminUsers.data
      ? getTbody(
          adminUsers.data.users,
          userData.isAssociateAdmin,
          (userId) => onEditUser(userId, history),
          dispatch,
          (userId) => onCreateMessage([userId], dispatch, history),
          bodyAreChecked,
          onBodyAreChecked
        )
      : null

    return (
      <tbody>
        {memorizedUsers?.length !== 0 ? (
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          memorizedUsers?.map(({ id, ...objProps }) => (
            <tr key={`React.Fragment${getUniqueId()}`} className='divide-x border-b border-bluegray-200'>
              {Object.entries(objProps).map(([key, value]) => {
                const data: any = typeof value === 'string' ? value.split('|||') : value
                return (
                  <td key={`td${key}`} className='relative'>
                    <div style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                      {data[0] || data}
                      <div className='text-sm text-bluegray-400'>{data[1]}</div>
                    </div>
                  </td>
                )
              })}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={tableCellWidth?.length || 10000} height={250} className='text-center'>
              No available items
            </td>
          </tr>
        )}
      </tbody>
    )
  }, [adminUsers.data, userData.isAssociateAdmin, bodyAreChecked])

  return (
    <>
      <div className='flex flex-col lg:flex-row justify-between lg:items-center mb-4 space-y-5'>
        <div className='flex w-full flex-col mobile:flex-row relative items-center gap-4 md:full lg:w-1/3 desktop:w-1/4 lg:mr-3'>
          <div className='w-full'>
            <Input
              placeholder='Search by Name, Company or Email'
              value={params.search || ''}
              onChange={(e) => setParams((prev) => ({ ...prev, search: e.target.value }))}
            />
          </div>
          {!!choseSize && (
            <div
              style={{ height: 'auto', left: '104%' }}
              className='text-green-800 lg:absolute border whitespace-nowrap w-min border-green-200 bg-green-50 rounded-3xl py-1 px-4'
            >
              {choseSize} Selected
            </div>
          )}
        </div>
        <div className='flex flex-col gap-y-2 mobile:flex-row'>
          <div className='mb-3 gap-x-3 gap-y-2 flex flex-col 2smallest:flex-row lg:flex-col desktop:flex-row mobile:mb-0 smallMobile:ml-auto mobile:mr-3 justify-between'>
            <Button
              variant='message'
              text='Create message'
              onClick={() => onCreateMessage(Array.from(bodyAreChecked), dispatch, history)}
              disabled={!choseSize}
            />
            <CsvDropdown />
          </div>
          <div className='gap-3 overflow-x-auto flex flex-col 2smallest:flex-row lg:flex-col desktop:flex-row'>
            <Button text='Upload CSV' variant='white' onClick={onUploadCsvTemplate} />
            <Button text='Add User' variant='white' onClick={() => onAddUser(history)} />
          </div>
        </div>
      </div>
      <div className='overflow-x-auto relative'>
        <table className='w-full table-fixed border whitespace-nowrap mb-2'>
          <thead className='bg-gray-50 text-left'>
            <tr className='divide-x border-b border-bluegray-200'>
              {Object.entries(memorizedThead).map(([key, value], index) => (
                <th key={`th${key}`} scope='col' style={{ width: tableCellWidth[index] }} className='whitespace-nowrap'>
                  {typeof value === 'string' ? (
                    <button
                      type='button'
                      className={`disabled:opacity-100 ${selectedKey.current?.sortBy === key ? '!font-bold' : ''}`}
                      style={{ font: 'inherit' }}
                      onClick={() => {
                        if (callBack) {
                          callBack(key)
                          selectedKey.current = {
                            sort:
                              selectedKey.current?.sortBy === key && selectedKey.current?.sort === 'down'
                                ? 'up'
                                : 'down',
                            sortBy: key,
                          }
                        }
                      }}
                    >
                      {value}
                      <Sort sort={selectedKey.current?.sortBy === key ? selectedKey.current?.sort : undefined} />
                    </button>
                  ) : (
                    value
                  )}
                </th>
              ))}
            </tr>
          </thead>
          {memorizedTBody}
        </table>
        {adminUsers.loading && (
          <div className='absolute left-0 right-0 -bottom-8 mt-20 flex justify-center'>
            <LoaderPoints className='m-auto' />
          </div>
        )}
      </div>
      {dataCsvPopUp && <PopUpForCsv data={dataCsvPopUp} onClose={() => setDataCsvPopUp(null)} />}
    </>
  )
}
