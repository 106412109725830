// import jwt_decode from 'jwt-decode'
import { TOKEN } from './consts'
import { ReturnType } from './checkToken'
import { SetTermsFalse, SetTermsTrue } from '../store/terms/actions'
import {
  SetFullAccessCategories,
  SetIsAssociateAdmin,
  SetIsExecutiveAdmin,
  SetIsExtraData,
  SetViewOnlyCategories,
} from '../store/user/actions'
// import { setTimeout_1 } from './helpers'

// let timer: any

// export const logoutWhenTokenWillExpire = () => {
//   const refresh_token = localStorage.getItem(TOKEN.refresh)
//   if (refresh_token) {
//     const decodedRefresh = jwt_decode(refresh_token) as any
//     if (decodedRefresh?.expires_at) {
//       if (timer) clearTimeout(timer)
//       timer = setTimeout_1(() => {
//         localStorage.clear()
//         sessionStorage.clear()
//         window.location.href = '/login#sessionisexpired'
//       }, new Date(decodedRefresh.expires_at * 1000).getTime() - Date.now())
//     }
//   }
// }

export const listenLogin = (event: StorageEvent) => {
  if (event.key === TOKEN.refresh && !event.oldValue) window.location.reload()
}

export const setStore = (userToken: ReturnType, dispatch: any) => {
  if (userToken.is_terms_confirmed) dispatch(SetTermsTrue())
  else dispatch(SetTermsFalse())
  dispatch(SetIsExtraData({ id: +userToken.usrId, companyId: userToken.company_id }))

  dispatch(SetIsExecutiveAdmin(userToken.idOfAdminRole === 1))
  dispatch(SetIsAssociateAdmin(userToken.idOfAdminRole === 2))
  dispatch(SetFullAccessCategories(userToken.fullAccessCategories || []))
  dispatch(SetViewOnlyCategories(userToken.viewOnlyCategories || []))

  // logoutWhenTokenWillExpire()
}
