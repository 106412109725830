import { Actions } from '../../../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../../../types/actions'

/// ///////////////////////////////////////////////////////////////////////
const GetTenderImagesStatusActions = new Actions('GET_TENDER_IMAGES_STATUS', ActionTypes)

export interface TypeGetTenderImagesStatusR extends TDefRequest {
  idTender: number
}

export const GetTenderImagesStatus = {
  request: (payload?: TypeGetTenderImagesStatusR) => GetTenderImagesStatusActions.request(payload || {}),
  success: (payload: any) => GetTenderImagesStatusActions.success(payload),
  error: (payload: string) => GetTenderImagesStatusActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const SendImagesMetadataActions = new Actions('SEND_IMAGES_METADATA', ActionTypes)

export interface TypeSendImagesMetadataR extends TDefRequest {
  imagesData: {
    file_name: string
    tender_id: number
    handle: string
  }[]
}

export const SendImagesMetadata = {
  request: (payload?: TypeSendImagesMetadataR) => SendImagesMetadataActions.request(payload || {}),
  success: (payload: any) => SendImagesMetadataActions.success(payload),
  error: (payload: string) => SendImagesMetadataActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetFilestackApiKeyActions = new Actions('GET_FILESTACK_API_KEY', ActionTypes)

export interface TypeGetFilestackApiKeyR extends TDefRequest {}

export const GetFilestackApiKey = {
  request: (payload?: TypeGetFilestackApiKeyR) => GetFilestackApiKeyActions.request(payload || {}),
  success: (payload: any) => GetFilestackApiKeyActions.success(payload),
  error: (payload: string) => GetFilestackApiKeyActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const DeleteAllImagesActions = new Actions('DELETE_ALL_IMAGES', ActionTypes)

export interface TypeDeleteAllImagesR extends TDefRequest {
  idTender: number
  idItem: number
}

export const DeleteAllImages = {
  request: (payload: TypeDeleteAllImagesR) => DeleteAllImagesActions.request(payload),
  success: (payload: any) => DeleteAllImagesActions.success(payload),
  error: (payload: string) => DeleteAllImagesActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const DeleteOneImageActions = new Actions('DELETE_ONE_IMAGE', ActionTypes)

export interface TypeDeleteOneImageR extends TDefRequest {
  idTender: number
  idImage: number
}

export const DeleteOneImage = {
  request: (payload: TypeDeleteOneImageR) => DeleteOneImageActions.request(payload),
  success: (payload: any) => DeleteOneImageActions.success(payload),
  error: (payload: string) => DeleteOneImageActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetTenderGalleryListActions = new Actions('GET_TENDER_GALLERY_LIST', ActionTypes)

export interface TypeGetTenderGalleryListR extends TDefRequest {
  idTender: number
}

export const GetTenderGalleryList = {
  request: (payload: TypeGetTenderGalleryListR) => GetTenderGalleryListActions.request(payload),
  success: (payload: any) => GetTenderGalleryListActions.success(payload),
  error: (payload: string) => GetTenderGalleryListActions.error(payload),
}
