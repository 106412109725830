import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { GalleryCards, GalleryList } from '../containers/gallery'
import { useAddItemPopup } from '../hooks/useAddItemPopup'
import { AppStore } from '../store/applicationState'
import { GetItemInfo, GetTenderInfo, GetTenderItems, GetTenderItemsPrices } from '../store/gallery/actions'
import { GetCurrentBids } from '../store/gallery/tenderSummary/actions'
import { galleryUrlSortChange } from '../utils/galleryUtils/EventHandlers'
import { ROUTES } from '../utils/routes'
import { recMode } from '../utils/consts'

export const ProductGallery = () => {
  const recommendParam = new URLSearchParams(window.location.search).get('recommendations')

  const [galleryView, setGalleryView] = useState('gallery')
  const [favorites, setFavorites] = useState(false)
  const [recommendations, setRecommendations] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingItems, setLoadingItems] = useState(true)
  const [loadingSearchItems, setLoadingSearchItems] = useState(false)

  const [search, setSearch] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [sort, setSort] = useState('DESC')
  const [showMyActivity, setShowMyActivity] = useState(false)
  const [showAvailable, setShowAvailable] = useState(false)
  const [urlChecked, setUrlChecked] = useState(false)
  const [isCatalog, setIsCatalog] = useState(true)
  const [errorOnFetchTender, setErrorOnFetchTender] = useState<string | null>(null)

  const { gallery, currentTime } = useSelector((store: AppStore) => store)
  const { add, remove } = useAddItemPopup()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    let timer: any
    if (errorOnFetchTender)
      timer = setTimeout(() => {
        history.replace(ROUTES.dashboard)
      }, 2000)
    return () => clearTimeout(timer)
  }, [errorOnFetchTender])

  const parseUrlSort = () => {
    if (new URLSearchParams(window.location.search).get('sort')) {
      galleryUrlSortChange(new URLSearchParams(window.location.search).get('sort') || '', setSort, setSortBy)
    }
  }

  useEffect(() => {
    setLoading(true)
    const tenderId = Number(window.location.pathname.split('/').find((elem) => Number(elem))) || 0
    dispatch(
      GetTenderInfo.request({
        idTender: tenderId,
        callBack: (success, data) => {
          if (success && data) {
            const tenderCategory = data.tender_info.category_id === 1 ? 'weight' : 'asking_price'
            const tenderCategoryCatalog = data.tender_info.category_id === 1 ? 'weight' : 'brand'

            if (new URLSearchParams(window.location.search).get('favorites') === 'true') {
              setFavorites(true)
            }

            if (
              new URLSearchParams(window.location.search).get('favorites') === 'false' ||
              new URLSearchParams(window.location.search).get('favorites') === null
            ) {
              setFavorites(false)
            }

            if (recommendParam === 'true') setRecommendations(true)
            else setRecommendations(false)

            const searchQuery = new URLSearchParams(window.location.search).get('search')
            if (searchQuery) setSearch(searchQuery)

            if (new URLSearchParams(window.location.search).get('sort')) {
              parseUrlSort()
            } else {
              const isCatalog2 = data.tender_info ? new Date(data.tender_info.start_at) > new Date() : false
              if (isCatalog2) {
                setSortBy(tenderCategoryCatalog)
                setSort(data.tender_info.category_id === 1 ? 'DESC' : 'ASC')
              } else {
                setSortBy(tenderCategory)
                setSort('DESC')
              }
            }

            setUrlChecked(true)
            setLoading(false)
          } else setErrorOnFetchTender('No such tender')
        },
      })
    )
  }, [])

  useEffect(() => {
    const tenderId = Number(window.location.pathname.split('/').find((elem) => Number(elem))) || 0
    dispatch(GetCurrentBids.request({ idTender: tenderId }))
  }, [])

  useEffect(() => {
    if (gallery.data.tenderInfo && !loadingItems) {
      let timeDiff = 0
      if (gallery.data.showPrices === 0) {
        const serverTime = Math.round(Number(currentTime.data) / 1000000)
        timeDiff = serverTime ? new Date().getTime() - serverTime : 0
      }
      const startAt = new Date(gallery.data.tenderInfo.tender_info.start_at)
      if (currentTime) {
        setIsCatalog(startAt.getTime() > new Date().getTime() - timeDiff)
      }
    }
  }, [gallery.data.tenderInfo, currentTime, gallery.data.showPrices, loadingItems])

  const tenderType = gallery.data.tenderInfo?.tender_info.category_id === 1 ? 'diamond' : 'watches'

  useEffect(() => {
    if (urlChecked || errorOnFetchTender) {
      const itemPopup = new URLSearchParams(document.location.search).get('item')
      const popUp = document.getElementById('itemPopup')
      if (itemPopup && !popUp && currentTime && gallery.data.tenderInfo) {
        const serverTime = Math.round(Number(currentTime.data) / 1000000)
        const timeDiff = serverTime ? new Date().getTime() - serverTime : 0
        const startAt = new Date(gallery.data.tenderInfo.tender_info.start_at)
        const checkCatalog = startAt.getTime() > new Date().getTime() - timeDiff
        dispatch(
          GetItemInfo.request({
            itemId: +itemPopup,
            callBack: (success, data) => {
              if (success) {
                const item = data
                const finalized = !gallery.data.tenderInfo

                if (item) add({ item, isCatalog: checkCatalog, finalized })
              }
            },
          })
        )
      }
    }
  }, [urlChecked, errorOnFetchTender, gallery.data.tenderInfo])

  useEffect(() => {
    const viewParams = new URLSearchParams(document.location.search).get('view')
    if (viewParams && tenderType === 'diamond') {
      setGalleryView(viewParams)
    }

    if (viewParams === null && tenderType === 'diamond') {
      setGalleryView('gallery')
    }
  }, [tenderType])

  useEffect(() => {
    if (urlChecked && isCatalog && gallery.data.loadTenderPrices) {
      const idTender = Number(window.location.pathname.split('/').find((elem) => Number(elem))) || 0
      dispatch(
        GetTenderItemsPrices.request({
          idTender,
          offset: 0,
          limit: 1000,
          favorites: favorites ? true : undefined,
          search,
          sortBy,
          sort,
          showMyActivity: showMyActivity ? true : undefined,
          available: showAvailable ? true : undefined,
        })
      )
    }
  }, [gallery.data.loadTenderPrices, isCatalog])

  useEffect(() => {
    if (urlChecked) {
      if (!loadingItems) setLoadingSearchItems(true)
      const idTender = Number(window.location.pathname.split('/').find((elem) => Number(elem))) || 0
      dispatch(
        GetTenderItems.request({
          idTender,
          offset: 0,
          limit: 1000,
          favorites: favorites ? true : undefined,
          recommendations: recommendations ? true : undefined,
          search,
          sortBy,
          sort,
          showMyActivity: showMyActivity ? true : undefined,
          available: showAvailable ? true : undefined,
          adminInfo: recMode,
          callBack: (success, data) => {
            if (success && data) {
              if (loadingItems) setLoadingItems(false)
              else setLoadingSearchItems(false)
            }
          },
        })
      )
    }
  }, [recommendations, favorites, search, sortBy, sort, showMyActivity, urlChecked, showAvailable])

  const subtitleCount = () => {
    const allItems = gallery.data.tenderInfo?.tender_info.total || 0
    const soldItems = gallery.data.tenderInfo?.tender_info.sold || 0
    const availableItems = allItems - soldItems

    switch (availableItems) {
      case 1:
        return `${availableItems} / ${allItems} ITEM REMAIN AVAILABLE`

      case 0:
        return `NO ITEMS REMAIN AVAILABLE`

      default:
        return `${availableItems} / ${allItems} ITEMS REMAIN AVAILABLE`
    }
  }

  useEffect(() => {
    const windowNav = () => {
      const itemPopup = new URLSearchParams(document.location.search).get('item')
      const popUp = document.getElementById('itemPopup')
      const tenderType2 = gallery.data.tenderInfo?.tender_info.category_id === 1 ? 'diamond' : 'watches'

      if (itemPopup && !popUp) {
        dispatch(
          GetItemInfo.request({
            itemId: +itemPopup,
            callBack: (success, data) => {
              if (success) {
                const item = data
                const finalized = false

                if (item) add({ item, isCatalog, finalized })
              }
            },
          })
        )
      }

      if (itemPopup === null && popUp) {
        remove()
      }

      const viewParams = new URLSearchParams(document.location.search).get('view')
      if (viewParams && tenderType2 === 'diamond') {
        setGalleryView(viewParams)
      }
      if (!viewParams) {
        setGalleryView('gallery')
      }

      const favoritesParams = new URLSearchParams(document.location.search).get('view')
      if (favoritesParams === 'true') {
        setFavorites(true)
      }

      if (favoritesParams === 'false' || !favoritesParams) {
        setFavorites(false)
      }

      if (recommendParam === 'true') setRecommendations(true)
      else setRecommendations(false)

      if (new URLSearchParams(window.location.search).get('sort')) {
        parseUrlSort()
      }

      if (!new URLSearchParams(window.location.search).get('sort')) {
        const isCatalog2 = gallery.data.tenderInfo?.tender_info
          ? new Date(gallery.data.tenderInfo.tender_info.start_at) > new Date()
          : false
        const tenderCategory = tenderType2 === 'diamond' ? 'weight' : 'asking_price'
        const tenderCategoryCatalog = tenderType2 === 'diamond' ? 'weight' : 'brand'
        if (isCatalog2) {
          setSortBy(tenderCategoryCatalog)
          setSort(tenderType2 === 'diamond' ? 'DESC' : 'ASC')
        } else {
          setSortBy(tenderCategory)
          setSort('DESC')
        }
      }
    }

    if (gallery.data.tenderInfo) window.addEventListener('popstate', windowNav)

    return () => window.removeEventListener('popstate', windowNav)
  }, [gallery.data.tenderInfo])

  if (errorOnFetchTender) return <p className='tender-error-message m-4'>{errorOnFetchTender}</p>

  return galleryView === 'gallery' ? (
    <GalleryCards
      loading={loading}
      loadingItems={loadingItems}
      loadingSearchItems={loadingSearchItems}
      setGalleryView={setGalleryView}
      isCatalog={isCatalog}
      setsearch={setSearch}
      sort={sort}
      setSort={setSort}
      sortBy={sortBy}
      setSortBy={setSortBy}
      favorites={favorites}
      setFavorites={setFavorites}
      recommendations={recommendations}
      setRecommendations={setRecommendations}
      subtitleCount={subtitleCount}
    />
  ) : (
    <GalleryList
      isCatalog={isCatalog}
      favorites={favorites}
      sort={sort}
      sortBy={sortBy}
      showMyActivity={showMyActivity}
      showAvailable={showAvailable}
      setsearch={setSearch}
      setShowMyActivity={setShowMyActivity}
      setSort={setSort}
      setSortBy={setSortBy}
      setFavorites={setFavorites}
      setGalleryView={setGalleryView}
      subtitleCount={subtitleCount}
      setShowAvailable={setShowAvailable}
      recommendations={recommendations}
      setRecommendations={setRecommendations}
    />
  )
}
