import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { SnackBar } from '../../../../components/ui/SnackBar'
import { API_ROUTES } from '../../../../utils/apiRoutes'
import { callApi } from '../../../../utils/callApi'
import * as Actions from './actions'
import { ActionTypes, TUpcomingTenders, TUpcomingTendersMessage } from './types'

const { addSnackBar } = SnackBar()

function* getUpcomingTendersWorker(action: ReturnType<typeof Actions.GetUpcomingTenders.request>) {
  const { callBack } = action.payload as Actions.TypeGetUpcomingTendersR
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: API_ROUTES.adminUpcomingTenders,
    })) as TUpcomingTenders[]
    
    yield put(Actions.GetUpcomingTenders.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetUpcomingTenders.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* changeUpcomingTendersVisibleWorker(action: ReturnType<typeof Actions.ChangeUpcomingTendersVisible.request>) {
  const { callBack, tenderId, hide } = action.payload as Actions.TypeChangeUpcomingTendersVisibleR
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'put',
      path: `${API_ROUTES.adminTenders}/${tenderId}/hide`,
    })) as TUpcomingTendersMessage
    
    yield put(Actions.ChangeUpcomingTendersVisible.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.ChangeUpcomingTendersVisible.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    const successMessage = hide ? 'Tender has been hidden' : 'Tender is shown'
    const errorMessage = data?.message || ''
    const snackBarMessage = success ? successMessage : errorMessage
    addSnackBar(snackBarMessage, success)
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.GET_UPCOMING_TENDERS_R, getUpcomingTendersWorker)
  yield takeEvery(ActionTypes.CHANGE_UPCOMING_TENDER_VISIBLE_R, changeUpcomingTendersVisibleWorker)
}


export default function* upcomingTendersSaga() {
  yield all([fork(watchRequest)])
}