import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChangeTendersSchedule } from '../../../store/admin/tenders/actions'
import { TTenders } from '../../../store/admin/tenders/types'
import { AppStore } from '../../../store/applicationState'
import { Edit } from '../../../svg/Edit'
import { IconCloseEdit } from '../../../svg/IconCloseEdit'
import { IconEditCheck } from '../../../svg/IconEditCheck'
import { Spinner } from '../../../svg/Spinner'
import { getDate } from '../../../utils/helpers'
import { TimeInput } from './TimeInput'

interface FullDateInputProps {
  tenderDate: string,
  timeType: 'start' | 'end' | 'catalog'
  id: string,
  tender: TTenders,
  childIndex: string[],
  setChildIndex: (value: ((prev: string[]) => any) | string[]) => void,
  setDispatchTime: (value: (((prevState: number) => number) | number)) => void,
}

const convertTime12to24 = (time12h: string) => {
  const [time, modifier] = time12h.split(' ')

  const minutes = time.split(':')[1]
  let hours = time.split(':')[0]

  if (hours === '12') {
    hours = '00'
  }

  if (modifier === 'PM') {
    hours = `${parseInt(hours, 10) + 12}`
  }

  return `${hours}:${minutes}:00`
}

export const FullDateInput: React.FC<FullDateInputProps> = ({
  tenderDate,
  tender,
  id,
  timeType,
  childIndex,
  setChildIndex,
  setDispatchTime,
}) => {
  const [dateError, setDateError] = useState<boolean>(false)
  const [timeError, setTimeError] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [time, setTime] = useState('')
  const [date, setDate] = useState('')
  const dispatch = useDispatch()
  const { tenders } = useSelector((store: AppStore) => store)

  useEffect(() => {
    const d = new Date(tenderDate)
    .toLocaleString('zh-Hans-CN', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      timeZone: 'America/New_York',
    }).replaceAll('/', '-')

    const t = new Date(tenderDate)
    .toLocaleString('en-US', {
      hour12: true,
      hour: '2-digit',
      minute: 'numeric',
      second: 'numeric',
      timeZone: 'America/New_York',
    })

    setTime(t)
    setDate(d)
  }, [childIndex.includes(id), tenders])

  const changeTenderTime = (newTime: string) => {
    setLoading(true)
    const finalized = timeType === 'end' ? Math.round(+new Date(newTime)/1000) : Math.round(+new Date(tender.finalized_at)/1000)
    const start = timeType === 'start' ? Math.round(+new Date(newTime)/1000) : Math.round(+new Date(tender.start_at)/1000)
    const catalogStart = timeType === 'catalog' ? Math.round(+new Date(newTime)/1000) : Math.round(+new Date(tender.preview_start_at)/1000)
    dispatch(ChangeTendersSchedule.request({
      tender_id: tender.id,
      body: {
        finalized_epoch: finalized,
        preview_start_epoch: catalogStart,
        start_epoch: start,
      },
      callBack(success) {
        if (success) {
          setDispatchTime(prev => prev + 1)
          setChildIndex([])
        }
        setLoading(false)
      }
    }))
  }
  
  return (
    <div className='flex justify-between relative'>
      {(timeError || dateError) && childIndex.includes(id) && (
        <div className='input-info absolute z-20 bottom-full right-[40%] mb-1 text-xxxs w-36 whitespace-normal bg-red-100 border border-red-300 text-red-700 font-semibold px-3 py-1 text-center'>
          {dateError && "Enter date"}
          {timeError && "Enter tiem"}
        </div>
      )}
      {childIndex.includes(id) ? (
        <div className="flex space-x-2">
          <input
            type="date"
            value={date}
            onChange={(e) => {
              const { value } = e.target
              setDate(value)
              if (!value) setDateError(true)
              else setDateError(false)
            }}
          />

          <TimeInput
            time={time}
            setTime={setTime}
          />
        </div>
      ) : (
        getDate(tenderDate)
      )}

      <div className='ml-2'>
        {childIndex.includes(id)
        ? (
          <div className={`flex space-x-1 relative ${loading ? 'pointer-events-none' : ''}`}>
            {loading && (
              <div className="absolute top-0 left-0 ml-1 right-0 bottom-0 flex justify-center items-center z-10 bg-opacity-10 bg-gray-900">
                <Spinner style={{ color: 'black', width: '10px', height: '10px' }} />
              </div>
            )}
            <button
              type='button'
              className='flex'
              onClick={() => {
                setChildIndex([])
                setDateError(false)
                setTimeError(false)
                setTime('')
                setDate('')
              }}
            >
              <IconCloseEdit />
            </button>
            <button
              type='button'
              className='flex'
              onClick={() => {
                const dateSplited = tenderDate.split('-')
                const timezone = dateSplited[dateSplited.length - 1]
                const newDate = `${date}T${convertTime12to24(time)}-${timezone}`
                
                setChildIndex([])
                changeTenderTime(newDate)
              }}
            >
              <IconEditCheck />
            </button>
          </div>
        ) : (
          <button
            type='button'
            className='flex'
            onClick={() => {
              setChildIndex([ id ])
              setDateError(false)
              setTimeError(false)
            }}
          >
            <Edit />
          </button>
        )}
      </div>
    </div>
  )
}
