import React from 'react'

interface IconLikeGalleryProps {
  isActive: boolean,
  bgColor: string
}

export const IconLikeGallery: React.FC<IconLikeGalleryProps> = ({ isActive, bgColor }) => {
  return (
    <svg
      className={`svg-inline--fa fa-heart ${isActive ? 'text-red-500' : `${bgColor}`}`}
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="heart"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      data-fa-i2svg=""
    >
      <path fill="currentColor" d="M472.1 270.5l-193.1 199.7c-12.64 13.07-33.27 13.08-45.91 .0107l-193.2-199.7C-16.21 212.5-13.1 116.7 49.04 62.86C103.3 15.88 186.4 24.42 236.3 75.98l19.7 20.27l19.7-20.27c49.95-51.56 132.1-60.1 187.3-13.12C525.1 116.6 528.2 212.5 472.1 270.5z" />
    </svg>
  )
}
