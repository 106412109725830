import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import { IconLogo } from '../../svg/IconLogo'
import { onPasswordChange, onPasswordRepeatChange } from '../../utils/userUtils/EventHandlers'
import { validateButton } from '../../utils/userUtils/FormStyles'
import { PostSetNewPass, TypePostSetNewPassR } from '../../store/auth/actions'
import { ROUTES } from '../../utils/routes'
import { AppStore } from '../../store/applicationState'
import { Spinner } from '../../svg/Spinner'
import { PasswordCheckPopover } from '../../components/ui/PasswordCheckPopover'

export const NewPassword = () => {
  const [userNewPassword, setUserNewPassword] = useState('')
  const [userRepeatPassword, setUserRepeatPassword] = useState('')
  const [newPasswordError, setNewPasswordError] = useState(true)
  const [repeatPasswordError, setRepeatPasswordError] = useState(true)
  const [submitError, setSubmitError] = useState(false)

  const dispatch = useDispatch()
  const location = useLocation()
  const { signIn } = useSelector((store: AppStore) => store)
  const { token } = queryString.parse(location.search)
  const history = useHistory()

  const callBack = (success: boolean) => {
    if (success) {
      const isWorthy = location.pathname.includes('/worthy')
      history.push(isWorthy ? `/worthy${ROUTES.login}` : ROUTES.login)
    }
  }

  const setNewPasswordFunc = (data: TypePostSetNewPassR) =>
    dispatch(
      PostSetNewPass.request({
        ...data,
        callBack,
      })
    )

  const onSubmitForm = (event: any) => {
    event.preventDefault()

    if (newPasswordError || repeatPasswordError) {
      setSubmitError(true)
      return null
    }

    setSubmitError(false)

    if (token && typeof token === 'string') return setNewPasswordFunc({ password: userNewPassword, token })
    return null
  }

  return (
    <div className='page-content max-w-screen-xl mx-auto flex justify-center items-center text-sm lg:pt-9 smallMobile:pt-16 pt-20'>
      <div className='container mx-auto'>
        <div className='max-w-md mx-auto my-10'>
          <div className='text-center'>
            <h1 className='my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200'>
              <IconLogo />
            </h1>
          </div>

          <div className='m-7'>
            <form noValidate onSubmit={(event) => onSubmitForm(event)} action='' className='caret-button-reports'>
              <div className='mb-8'>
                <div className='flex justify-between mb-2'>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor='firstPassword' className='text-base text-gray-600 dark:text-gray-400'>
                    New password:
                  </label>
                </div>

                <div>
                  <PasswordCheckPopover>
                    <input
                      value={userNewPassword}
                      onChange={(event) => onPasswordChange(event, setUserNewPassword, setNewPasswordError)}
                      type='password'
                      name='password'
                      id='firstPassword'
                      placeholder='Password'
                      className={`form-input ${validateButton(newPasswordError, submitError)}`}
                    />
                    <div className={`form-error-text mt-1 ${newPasswordError && submitError ? 'block' : 'hidden'}`}>
                      {userNewPassword === ''
                        ? 'Please enter a password (7 characters minimum).'
                        : 'Please enter a valid password'}
                    </div>
                  </PasswordCheckPopover>
                </div>
              </div>

              <div className='mb-8'>
                <div className='flex justify-between mb-2'>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor='secondPassword' className='text-base text-gray-600 dark:text-gray-400'>
                    Repeat new password:
                  </label>
                </div>
                <div>
                  <PasswordCheckPopover>
                    <input
                      value={userRepeatPassword}
                      onChange={(event) => {
                        onPasswordRepeatChange(event, userNewPassword, setUserRepeatPassword, setRepeatPasswordError)
                      }}
                      type='password'
                      name='password'
                      id='secondPassword'
                      placeholder='Password'
                      className={`form-input ${validateButton(repeatPasswordError, submitError)}`}
                    />
                    <div className={`form-error-text mt-1 ${repeatPasswordError && submitError ? 'block' : 'hidden'}`}>
                      Please enter a password, passwords must match.
                    </div>
                  </PasswordCheckPopover>
                </div>
              </div>

              <div className='mb-6 relative'>
                <button type='submit' className='form-button' style={signIn.loading ? { color: 'transparent' } : {}}>
                  submit
                </button>
                {signIn.loading && (
                  <div className='absolute' style={{ top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                    <Spinner />
                  </div>
                )}
              </div>
              {/* <div> */}
              {/*  <Link */}
              {/*    to='/login' */}
              {/*    className='text-base text-gray-400 focus:outline-none focus:text-button-reports hover:text-button-reports' */}
              {/*  > */}
              {/*    Go to start */}
              {/*  </Link> */}
              {/* </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
