/* eslint-disable react/require-default-props */
import React from 'react'
import { CustomDropdown } from '../adminUsersComponents/CustomDropdown'
import { TCompanies } from '../../../store/admin/companies/types'
import { Checkbox } from '../Checkbox'

interface CompanyDropdownProps {
  solidIcon: boolean
  callBack: (value: string) => void

  companiesData?: TCompanies[]
  selectedCompanies: number[]
  setSelectedCompanies: (companyId: number) => void

  onSetAllChecked: (value: boolean) => void
  selectedKey: { sort: 'down' | 'up'; sortBy: string } | null
}

export const CompanyDropdown: React.FC<CompanyDropdownProps> = ({
  solidIcon,
  callBack,
  companiesData,
  selectedCompanies,
  setSelectedCompanies,
  onSetAllChecked,
  selectedKey,
}) => {
  return (
    <CustomDropdown
      menuItemsWidth='w-64'
      title='Company'
      solidIcon={solidIcon}
      callBack={() => callBack('company_title')}
      selected={selectedKey?.sortBy==='company_title'}
      updown={selectedKey?.sort}
    >
      {[
        {
          title: 'Companies',
          activeCheckboxId: selectedCompanies,
          checkboxComponent: (
            <Checkbox
              label='Select all'
              classname='flex-row-reverse'
              isChecked={selectedCompanies.length === companiesData?.length}
              setIsChecked={onSetAllChecked}
            />
          ),
          checkboxes: companiesData?.map((company) => ({
            id: company.id,
            title: company.company_title,
            callBack: () => setSelectedCompanies(company.id),
          })),
        },
      ]}
    </CustomDropdown>
  )
}
