import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Input } from './Input'
import { Sort } from '../../../svg/Sort'
import { useClickOutside } from '../../../hooks/useClickOutside'
import { getIconForFullAccessCategories } from '../../../utils/userUtils/IconHelpers'
import { getShortDate } from '../../../utils/helpers'
import { AppStore } from '../../../store/applicationState'
import { ROUTES } from '../../../utils/routes'
import { EmptySpan } from '../EmptySpan'
import { getStatus, getStatusName } from '../../../utils/tendersUtils/tenderStatusHelper'
import { GetAllTenders } from '../../../store/admin/tenders/actions'

interface DropDownProps {}

export const DropDownBtn: React.FC<DropDownProps> = () => {
  const { id, currentTenderId = +id } = useParams() as any
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { tenders } = useSelector((store: AppStore) => store)
  
  const [listState, setListState] = useState(tenders.data)
  const [visible, setVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [choosenItemIndex, setChoosenItemIndex] = useState<number>(0)
  
  const [itemRef, setItemRef] = useState<HTMLButtonElement | null>(null)
  const dropDownRef = useRef(null)
  const { clickOutside } = useClickOutside(dropDownRef)
  
  useEffect(() => {
    if (tenders.data && currentTenderId) setChoosenItemIndex(tenders.data.findIndex(tender => tender.id === currentTenderId))
  }, [currentTenderId, tenders.data])
  
  useEffect(() => {
    if (clickOutside) setVisible(false)
  }, [clickOutside])
  
  useEffect(() => {
    if (visible) {
      itemRef?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
      dispatch(GetAllTenders.request())
    }
  }, [visible])
  
  useEffect(() => {
    if (tenders.data) setListState(tenders.data)
    return () => setListState(prev => prev)
  }, [tenders.data])
  
  const onChange = (e: any) => {
    const { value } = e.target
    setInputValue(value)
    const searchList = tenders.data?.filter(tender => tender.sales_cycle.toLowerCase().match(value.toLowerCase()))
    setListState(searchList || [])
  }
  
  return (
    <div ref={dropDownRef} className='tenders-dropdown relative w-full smallMobile:w-80'>
      <button
        type='button'
        className='dropdown flex items-center w-full bg-white border border-bluegray-200 p-3 xxrounded-md text-left focus:outline-none focus:ring-1 focus:ring-bluegray-100 focus:border-ool-gray-100 text-sm smallMobile:text-lg'
        onClick={() => setVisible(true)}
      >
        <span className='flex items-center'>
          <div className='w-6 flex justify-center'>
            {tenders.data && tenders.data[choosenItemIndex] && getIconForFullAccessCategories(tenders.data[choosenItemIndex]?.category_id) ||
              <EmptySpan />}
          </div>
          <span className='ml-3 block truncate font-semibold'>
            <div className='flex justify-between leading-none items-center space-x-3'>
              <div className='sales-cycle'>{tenders.data && tenders.data[choosenItemIndex]?.sales_cycle || <EmptySpan />}</div>
            </div>
            <div
              className='text-xs text-bluegray-500 font-normal flex  ssmallest:space-x-1 smallMobile:space-x-2 flex-col ssmallest:flex-row'
            >
              {tenders.data && tenders.data[choosenItemIndex] && getStatus(tenders.data[choosenItemIndex]) || <EmptySpan />}
              <div className='date'>
                {tenders.data && tenders.data[choosenItemIndex] && getStatusName(tenders.data[choosenItemIndex]) !== 'active' && getShortDate(tenders.data[choosenItemIndex]?.finalized_at) ||
                  <EmptySpan />}
              </div>
            </div>
          </span>
        </span>
        <span className='ml-auto flex items-center pointer-events-none'>
          <Sort />
        </span>
      </button>
      <div
        style={visible ? { display: 'flex' } : {}}
        className='dropdown-content p-2 absolute hidden flex-col z-10 bg-white left-0 right-0 overflow-y-scroll border border-t-0 border-ool-gray-100'
      >
        <Input placeholder='Search' value={inputValue} onChange={onChange} />
        <div className='my-2'>
          {listState?.map((obj, index) => {
            if (['active', 'toBeClosed', 'completed'].includes(getStatusName(obj)))
              return (
                <button
                  type='button'
                  ref={ref => {if (choosenItemIndex === index) setItemRef(ref)}}
                  className='tender flex items-center w-full cursor-pointer hover:bg-bluegray-100'
                  key={`dropdown${obj.sales_cycle + index}`}
                  onClick={() => {
                    history.push(`${ROUTES.adminPageTender}/${obj.id}${location.pathname.substr(location.pathname.search(`${currentTenderId}`) + String(currentTenderId).length)}`)
                    setVisible(false)
                  }}
                  disabled={!!tenders.data && tenders.data[choosenItemIndex]?.id === listState[index].id}
                >
                  <div className='w-6 flex justify-center'>
                    {getIconForFullAccessCategories(obj.category_id)}
                  </div>
                  <div className='flex flex-col mx-2'>
                    <div className='sales-cycle text-left'>{obj.sales_cycle}</div>
                    <div className='flex items-center'>
                      {getStatus(obj)}
                      <div className='date text-sm ml-2'>{getStatusName(obj) !== 'active' && getShortDate(obj.finalized_at)}</div>
                    </div>
                  </div>
                </button>
              )
            return null
          })}
        </div>
      </div>
    </div>
  )
}
