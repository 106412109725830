import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as Actions from './actions'
import { ActionTypes, TItemMessage } from './types'
import { SnackBar } from '../../../components/ui/SnackBar'
import { API_ROUTES } from '../../../utils/apiRoutes'
import { callApi } from '../../../utils/callApi'
// import { GallerySnackBar } from '../../../components/ui/galleryUi/GallerySnackBar'
import { ChangeItemById } from '../actions'
import { NewGallerySnackBar } from '../../../components/ui/galleryUi/NewGallerySnackBar'

const { addSnackBar } = SnackBar()
// const { addNewGallerySnackBar } = GallerySnackBar()
const { addNewGallerySnackBar } = NewGallerySnackBar()

function* setItemBidWorker(action: ReturnType<typeof Actions.SetItemBid.request>) {
  const { callBack, idTender, idItem, body, actionType } = action.payload as Actions.TypeSetItemBid
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'post',
      path: `${API_ROUTES.tenders}/${idTender}/items/${idItem}/bid`,
      data: body,
    })) as TItemMessage
    
    yield put(Actions.SetItemBid.success(data))
    yield put(ChangeItemById({ idItem, body: { bid: body.bid_amount } }))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.SetItemBid.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (!success) {
      if (data?.message !== 'do not have access') addSnackBar(data?.message || '', success)
    }
    else addNewGallerySnackBar(data?.message || '', success, actionType)
    
  }
}

function* deleteItemBidWorker(action: ReturnType<typeof Actions.DeleteItemBid.request>) {
  const { callBack, idTender, idItem } = action.payload as Actions.TypeDeleteItemBid
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'delete',
      path: `${API_ROUTES.tenders}/${idTender}/items/${idItem}/bid`,
    })) as TItemMessage
    
    yield put(Actions.DeleteItemBid.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.DeleteItemBid.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (!success) {
      if (data?.message !== 'do not have access') addSnackBar(data?.message || '', success)
    }
    else addNewGallerySnackBar(data?.message || '', success, 'deleteBid')
  }
}

function* setItemBuyWorker(action: ReturnType<typeof Actions.SetItemBid.request>) {
  const { callBack, idTender, idItem } = action.payload as Actions.TypeSetItemBuy
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'post',
      path: `${API_ROUTES.tenders}/${idTender}/items/${idItem}/buy`,
    })) as TItemMessage
    
    yield put(Actions.SetItemBid.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.SetItemBid.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (!success) {
      if (data?.message !== 'do not have access') addSnackBar(data?.message || '', success)
    }
    else addNewGallerySnackBar(data?.message || '', success, 'purchased')
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.SET_ITEM_BID_R, setItemBidWorker)
  yield takeEvery(ActionTypes.DELETE_ITEM_BID_R, deleteItemBidWorker)
  yield takeEvery(ActionTypes.SET_ITEM_BUY_R, setItemBuyWorker)
}

export default function* itemActionsSaga() {
  yield all([fork(watchRequest)])
}