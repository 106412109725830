/* eslint-disable react/require-default-props */
import React from 'react'

interface RadioBtnProps {
  text: string;
  value: string;
  checked: boolean;
  color?: string;
  onChange: (e: any) => void;
  name: string
}

export const RadioBtn: React.FC<RadioBtnProps> = ({
                                                    text,
                                                    value, // should bu unique
                                                    checked,
                                                    color, // text-orange-400 for example
                                                    onChange,
                                                    name,
                                                  }) => {
  return (
    <div className='flex items-center space-x-3 cursor-pointer'>
      <input
        type='radio'
        className={`${color || 'text-green-400'} h-5 w-5 border form-radio cursor-pointer`}
        value={value}
        checked={checked}
        onChange={onChange}
        name={name}
        id={`radio${value}`}
      />
      {text && (
        <label
          htmlFor={`radio${value}`}
          className={`cursor-pointer ${checked && `font-semibold ${color || 'text-green-500'}`}`}
          style={{ minWidth: '150px' }}
        >
          {text}
        </label>
      )}
    </div>
  )
}
