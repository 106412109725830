import { TDefRequest } from '../../types/actions'
import { Actions } from '../../utils/Action'
import { ActionTypes } from './types'

/// ///////////////////////////////////////////////////////////////////////
const SignInActions = new Actions('SIGN_IN', ActionTypes)

export interface TypePostSignInR extends TDefRequest {
  email: string
  end_previous_session: boolean
  password: string
}

export const PostSignIn = {
  request: (payload: TypePostSignInR) => SignInActions.request(payload),
  success: (payload: any) => SignInActions.success(payload),
  error: (payload: string) => SignInActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const SignOutActions = new Actions('SIGN_OUT', ActionTypes)

export interface TypePostSignOutR extends TDefRequest {}

export const PostSignOut = {
  request: (payload?: TypePostSignOutR) => SignOutActions.request(payload || {}),
  success: (payload: any) => SignOutActions.success(payload),
  error: (payload: string) => SignOutActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const ForgotPassActions = new Actions('FORGOT_PASS', ActionTypes)

export interface TypePostForgotPassR extends TDefRequest {
  email: string
}

export const PostForgotPass = {
  request: (payload: TypePostForgotPassR) => ForgotPassActions.request(payload),
  success: (payload: any) => ForgotPassActions.success(payload),
  error: (payload: string) => ForgotPassActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const SetNewPassActions = new Actions('SET_NEW_PASS', ActionTypes)

export interface TypePostSetNewPassR extends TDefRequest {
  password: string
  token: string
}

export const PostSetNewPass = {
  request: (payload: TypePostSetNewPassR) => SetNewPassActions.request(payload),
  success: (payload: any) => SetNewPassActions.success(payload),
  error: (payload: string) => SetNewPassActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////

export const SetIsAuth = () => ({ type: ActionTypes.SET_AUTH })
export const SetSignout = () => ({ type: ActionTypes.SET_LOGOUT })
