import { Reducer } from 'redux'
import { ActionTypes, TMessageMethods, TMessageMethodsState } from './types'

export const initialState: TMessageMethodsState = {
  loading: false,
  data: null,
}

const reducer: Reducer<TMessageMethodsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_MESSAGE_METHODS_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_MESSAGE_METHODS_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        data: action.payload.filter((item: TMessageMethods) => item.method_name !== 'WhatsApp'),
      }
    case ActionTypes.GET_MESSAGE_METHODS_E:
      return { ...state, loading: false, errors: action.payload }
    
    default:
      return state
  }
}

export { reducer as MessageMethodsReducer }