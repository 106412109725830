import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ItemPopupFinalizedStatus, ItemPopupStatus } from '../itemLabels'
import { IconNoChecked } from '../../../../svg/IconNoChecked'
import { IconChecked } from '../../../../svg/IconChecked'
import { TGalleryItemInfo } from '../../../../store/gallery/types'
import { Watch } from '../../../../svg/Watch'
import { WatchesAdditionalDetails } from './WatchesAdditionalDetails'
import { useZoom } from '../../../../hooks/useZoom.js'
import { isMobileDevice } from '../../../../utils/helpers'
import { IconLike } from '../../../../svg/IconLike'
import { getUniqueId } from '../../../../utils/FileUtils'
import { changeImageUrl } from '../../../../utils/imgUrlParse/imgUrlParser'
import { BidWidgetSimple } from './BidWidgetSimple'
import { addToFavoritesAction, addToRecommendedAction } from '../../../../utils/galleryUtils/itemsActions'
import { AppStore } from '../../../../store/applicationState'
import { TenderTimer } from '../../headerUi/TenderTimer'
import { recMode } from '../../../../utils/consts'
import { IconStar } from '../../../../svg/IconStar'

interface WatchesPopupBodyProps {
  item: TGalleryItemInfo
  isCatalog: boolean
  finalized: boolean
  setPurchasePopupActive: (value: boolean) => void
}

export const WatchesPopupBody: React.FC<WatchesPopupBodyProps> = ({
  item,
  isCatalog,
  finalized,
  setPurchasePopupActive,
}) => {
  const dispatch = useDispatch()
  const { userData } = useSelector((store: AppStore) => store)
  const [prewImg, setprewImg] = useState(item.product_gallery.images[0])
  const [imgError, setImgError] = useState(false)

  if (!isMobileDevice()) useZoom(!prewImg)

  const idTender = Number(window.location.pathname.split('/').find((elem) => Number(elem))) || 0

  const { gallery } = useSelector((store: AppStore) => store)

  const statusBody = useMemo(
    () => (
      <div>
        {finalized ? (
          <ItemPopupFinalizedStatus item={item} />
        ) : (
          <div>
            {finalized ? (
              <ItemPopupFinalizedStatus item={item} />
            ) : (
              <div>
                {!item.is_sold ? (
                  <BidWidgetSimple
                    item={item}
                    setPurchasePopupActive={setPurchasePopupActive}
                    tenderId={Number(window.location.pathname.split('/').find((elem) => Number(elem))) || 0}
                  />
                ) : (
                  <ItemPopupStatus item={item} />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    ),
    [item, item.is_sold, item.bid_status_id, item.bid, finalized]
  )

  return (
    <div className=' bg-white shadow-2xl-dark'>
      {!finalized ? (
        <div className='tender-type text-base  tracking-widest flex  space-x-6 font-semibold items-center  justify-center border-b py-2'>
          {gallery.data.tenderInfo ? <TenderTimer tender={gallery.data.tenderInfo} /> : null}
        </div>
      ) : null}

      <div className='md:flex md:flex-row flex-col md:space-x-8 p-6'>
        <div className='img w-12/12 md:w-5/12'>
          <div className='main-image item-image relative'>
            {item.product_gallery.images.length && !imgError ? (
              <div>
                <div className='image-holder'>
                  <img
                    id='slideImage'
                    className={`border border-bluegray-200 ${isMobileDevice() ? '' : 'xzoom'}`}
                    // @ts-ignore i
                    xoriginal={changeImageUrl(prewImg)}
                    src={changeImageUrl(prewImg)}
                    onError={() => setImgError(true)}
                    alt='Watches'
                  />
                  <div className='absolute z-100 right-0 top-0 bottom-0 w-4' />
                  <div className='absolute z-100 left-0 top-0 bottom-0 w-4' />
                  <div className='absolute z-100 left-0 top-0 right-0 h-4' />
                  <div className='absolute z-100 left-0 bottom-0 right-0 h-4' />
                </div>
              </div>
            ) : (
              <div className='text-bluegray-400 flex items-center justify-center h-72 w-auto text-7xl border border-bluegray-200'>
                <Watch />
              </div>
            )}
          </div>

          <div className='grid grid-cols-4 gap-4 py-4 images-grid'>
            {item.product_gallery.images.map((image) => (
              <div
                onClick={() => setprewImg(image)}
                aria-hidden='true'
                className='border  border-bluegray-200 hover:border-lightblue-600 cursor-pointer'
                key={getUniqueId()}
              >
                <img src={changeImageUrl(image)} alt='Watches' className='border border-bluegray-200' />
              </div>
            ))}
          </div>
        </div>

        <div className='md:w-7/12 w-12/12 '>
          <div className='h-full space-y-6 md:space-y-6'>
            <div className='flex justify-between items-center'>
              <div className='title text-2xl text-bluegray-900 leading-none font-circa font-medium'>
                {item.product.title}
              </div>

              {finalized ? (
                ''
              ) : (
                <div className='flex flex-row gap-2'>
                  {((gallery.data.atLeastOneRecommended && item.in_recommendations) || recMode) && (
                    <button
                      type='button'
                      disabled={!recMode}
                      onClick={() => {
                        addToRecommendedAction(item.in_recommendations, userData.companyId!, item.item_id, dispatch)
                      }}
                      className='recommendations text-yellow-500 text-lg disabled:opacity-100'
                    >
                      <IconStar isActive={item.in_recommendations} />
                    </button>
                  )}
                  <button
                    type='button'
                    onClick={() => addToFavoritesAction(item.in_favorites, idTender, item.item_id, dispatch)}
                    className='favorites text-2xl text-red-500 ml-2'
                  >
                    <IconLike isActive={item.in_favorites} />
                  </button>
                </div>
              )}
            </div>

            <div className='space-y-2'>
              <div className='flex text-base space-x-8 text-bluegray-600'>
                <div className='ref'>
                  <b className='text-black'>Ref. #</b>
                  {`: ${item.product.product_details.ref}`}
                </div>
                <div className='year'>
                  <b className='text-black'>Year</b>
                  {`: ${item.product.product_details.year}`}
                </div>
              </div>

              <div className='lg:flex text-base lg:space-x-8 text-bluegray-600 space-y-2 lg:space-y-0'>
                <div className='condition'>
                  <b className='text-black'>Condition</b>

                  {`: ${item.product.product_details.condition}`}
                </div>

                <div className='flex space-x-6'>
                  <div className='has-box items-center flex'>
                    <div className='font-bold '>Box</div>:
                    {item.product.product_details.box === 'Yes' ? (
                      <span className='text-green-300 ml-2'>
                        <IconChecked />
                      </span>
                    ) : (
                      <span className='text-red-400 ml-2'>
                        <IconNoChecked />
                      </span>
                    )}
                  </div>

                  <div className='has-papers font-bold items-center flex'>
                    <div className='font-bold '>Papers</div>:
                    {item.product.product_details.papers === 'Yes' ? (
                      <span className='text-green-300 ml-2'>
                        <IconChecked />
                      </span>
                    ) : (
                      <span className='text-red-400 ml-2'>
                        <IconNoChecked />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className=''>{!isCatalog && statusBody}</div>

            <div className='circa-comments'>
              <div className='font-semibold text-base mb-3'>CIRCA Comments</div>

              <div className='text-bluegray-600'>{item.product.product_details.watch_comments}</div>
            </div>

            <WatchesAdditionalDetails item={item} />
          </div>
        </div>
      </div>
    </div>
  )
}
