/* eslint-disable func-names,no-console */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { checkToken, refresh, ReturnType } from '../utils/checkToken'
import { useTender } from './Sockets/useTender'
import { useSignout } from './Sockets/useSignout'
import { changeStatusSalesOrdersAndInvoices } from '../store/admin/tenders/actions'
import { GetBuyNowPurchases,} from '../store/admin/tenders/buyNowPurchases/actions'
import { SnackBar } from '../components/ui/SnackBar'
import { GetAllBidsByTender } from '../store/admin/tenders/allBidsByTender/actions'

export const useSocket = () => {
  const [wsState, setWsState] = useState<WebSocket | null>(null)

  const { addSnackBar } = SnackBar()

  const { tenderListener } = useTender(wsState)
  const { signoutListener } = useSignout()

  const dispatch = useDispatch()


  const connect = async () => {
    const { token }: ReturnType = await checkToken()
    if (!token) return

    const ws: WebSocket = new WebSocket(`${process.env.REACT_APP_WEB_SOCKET_PATH}/api/v1/ws`)

    ws.onopen = function () {
      setWsState(ws)
      console.log('connect')
      ws.send(JSON.stringify({ token }))
    }

    ws.onmessage = function (evt) {
      const data = JSON.parse(evt.data) as { type_event: string; event_data: any }

      if (data.type_event === 'item_event') tenderListener(data.event_data)
      else if (data.type_event === 'sign_out_event') signoutListener()
    }

    ws.onerror = function (evt) {
      console.log(evt, 'onerror')
    }

    ws.onclose = async function (evt) {
      console.log(evt, 'onclose')
      setWsState(null)
      if (!document.hidden)
        if (evt.reason === 'valid access token') {
          await refresh()
          connect().catch(console.warn)
        } else {
          setTimeout(() => {
            connect()
          }, 1000)
        }
    }
  }

  useEffect(() => {
    connect().catch(console.log)
  }, [])


  useEffect(() => {
    if (wsState) {
      wsState.onmessage = function (evt) {
        const data = JSON.parse(evt.data) as { type_event: string; event_data: any }
  
        if (data.type_event === 'item_event') {
          tenderListener(data.event_data)
        } else if (data.type_event === 'sign_out_event') {
          signoutListener()
        } else if (data.type_event === 'sales_orders_event') {

          dispatch(changeStatusSalesOrdersAndInvoices({ 
            id: data.event_data.tender_id, 
            sales_orders_and_invoices_processing_status: data.event_data.sales_orders_and_invoices_processing_status
          }))

          dispatch(GetBuyNowPurchases.request({
              idTender: data.event_data.tender_id,
              offset: 0,
              limit: 400
            })
          )

          dispatch(GetAllBidsByTender.request({
              idTender: data.event_data.tender_id,
              offset: 0,
              limit: 400
            })
          )

          if (data.event_data.sales_orders_and_invoices_processing_status === 'failed' && data.event_data.error_msg) {
            addSnackBar(data.event_data.error_msg, false)
          }
         
        }
      }
    }
  }, [tenderListener, signoutListener, wsState])
  

  useEffect(() => {
    const onVisibilityChange = () => {
      if (!document.hidden && !wsState) connect().catch(console.log)
    }

    document.addEventListener('visibilitychange', onVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
      wsState?.close()
    }
  }, [wsState])
}
