export interface TGalleryTenderInfo {
  options: [
    {
      group_name: string
      id: string
      options: [
        {
          id: string
          name: string
          value: string
        }
      ]
      type_of_group: string
    }
  ]
  sort: [
    {
      active: boolean
      title: string
      value: string
    }
  ]
  tender_info: {
    category_id: number
    finalized_at: string
    sales_cycle: string
    sold: number
    start_at: string
    tender_id: number
    total: number
  }
}

export interface TGalleryItemInfo {
  bid: number
  bid_status_id: number
  category_id: number
  in_favorites: boolean
  in_recommendations: boolean
  is_sold: boolean
  item_id: number
  product: {
    asking_price: number
    asking_price_per_carat: number
    rap_list_price: number
    rap_list_price_discount: number
    sales_portal_show_reserve: boolean
    sales_target: number
    sales_target_per_carat: number
    count_of_bids?: number
    shipping_owner?: string
    sku: string
    title: string
    product_details: {
      approximate_diamond_clarity: string
      approximate_diamond_color: string
      approximate_total_colored_stone_carat_weight: string // approximate_total_colored_stone_carat_weight
      approximate_total_diamond_carat_weight: string // approximate_total_diamond_carat_weight
      be_crystal: string
      bezel: string
      box: string
      bracelet_length: string
      bracelet_strap: string
      case_back: string
      case_material: string
      case_size: string
      circa_comments: string
      clarity: string
      clasp_type: string
      color: string
      colored_stone_condition: string
      colored_stone_notes: string
      condition: string
      crystal_condition: string
      cut: string
      dial_color: string
      diamond_count: string
      jewelry_comments: string
      link_count: string
      metal_type: string
      movement_type: string
      necklace_length: string
      papers: string
      papers_type: string
      polish: string
      precious_color: string
      precious_color_2: string
      ref: string
      ring_size: string
      screw_count: string
      shape: string
      symmetry: string
      type: string
      watch_comments: string
      weight: number
      weight_grams: number
      year: string
    }
  }
  product_gallery: {
    images: string[]
    report_images: string[]
    report_pdfs: string[]
    report_pdf_2_images: string[]
    videos: string[]
    part_of_media_file: string[]
  }
}

export interface TGalleryMessage {
  message: string
}

export interface TGalleryState {
  loading: boolean
  data: {
    itemInfo: TGalleryItemInfo | null
    tenderInfo: TGalleryTenderInfo | null
    tenderItems: TGalleryItemInfo[] | null
    atLeastOneRecommended: boolean
    loadTenderPrices: number
    showPrices: number
    pricesAreLoaded: boolean
  }
  errors?: string | undefined
}

export enum ActionTypes {
  GET_ITEM_INFO_R = '@@GALLERY/GET_ITEM_INFO_R',
  GET_ITEM_INFO_S = '@@GALLERY/GET_ITEM_INFO_S',
  GET_ITEM_INFO_E = '@@GALLERY/GET_ITEM_INFO_E',

  GET_ITEM_PRICES_R = '@@GALLERY/GET_ITEM_PRICES_R',
  GET_ITEM_PRICES_S = '@@GALLERY/GET_ITEM_PRICES_S',
  GET_ITEM_PRICES_E = '@@GALLERY/GET_ITEM_PRICES_E',

  GET_TENDER_INFO_R = '@@GALLERY/GET_TENDER_INFO_R',
  GET_TENDER_INFO_S = '@@GALLERY/GET_TENDER_INFO_S',
  GET_TENDER_INFO_E = '@@GALLERY/GET_TENDER_INFO_E',

  GET_TENDER_ITEMS_PRICES_R = '@@GALLERY/GET_TENDER_ITEMS_PRICES_R',
  GET_TENDER_ITEMS_PRICES_S = '@@GALLERY/GET_TENDER_ITEMS_PRICES_S',
  GET_TENDER_ITEMS_PRICES_E = '@@GALLERY/GET_TENDER_ITEMS_PRICES_E',

  GET_TENDER_ITEMS_R = '@@GALLERY/GET_TENDER_ITEMS_R',
  GET_TENDER_ITEMS_S = '@@GALLERY/GET_TENDER_ITEMS_S',
  GET_TENDER_ITEMS_E = '@@GALLERY/GET_TENDER_ITEMS_E',

  SET_ITEM_WISHLIST_R = '@@itemActions/SET_ITEM_WISHLIST_R',
  SET_ITEM_WISHLIST_S = '@@itemActions/SET_ITEM_WISHLIST_S',
  SET_ITEM_WISHLIST_E = '@@itemActions/SET_ITEM_WISHLIST_E',

  SET_ITEM_RECOMMENDATION_R = '@@itemActions/SET_ITEM_RECOMMENDATION_R',
  SET_ITEM_RECOMMENDATION_S = '@@itemActions/SET_ITEM_RECOMMENDATION_S',
  SET_ITEM_RECOMMENDATION_E = '@@itemActions/SET_ITEM_RECOMMENDATION_E',

  CHANGE_ITEMS_BY_ID = '@@GALLERY/CHANGE_ITEMS_BY_ID',
  WS_CHANGE_TENDER_ITEMS = '@@GALLERY/WS_CHANGE_TENDER_ITEMS',
  WS_CHANGE_TENDER_ITEMS_FOR_PERS_RECOMMEND = '@@GALLERY/WS_CHANGE_TENDER_ITEMS_FOR_PERS_RECOMMEND',
  LOAD_TENDER_PRICES = '@@GALLERY/LOAD_TENDER_PRICES',
  SHOW_PRICES = '@@GALLERY/SHOW_PRICES',
}
