import React, { useRef, useState } from 'react'
// @ts-ignore
import Tridi from 'react-tridi'
import 'react-tridi/dist/index.css'
import { IconPauseCircle } from '../svg/IconPauseCircle'
import { IconPlayCircle } from '../svg/IconPlayCircle'
import { IconArrowCircle } from '../svg/IconArrowCircle'
import { IconGlassMinus } from '../svg/IconGlassMinus'
import { IconGlassPlus } from '../svg/IconGlassPlus'
import { IconCircleStop } from '../svg/IconCircleStop'
import { IconFourArrows } from '../svg/IconFourArrows'
import { Gem } from '../svg/Gem'
import { TDiamondVideo } from '../store/diamondVideo/types'

interface DiamondVideoContentProps {
  item: TDiamondVideo
}

export const DiamondVideoContent: React.FC<DiamondVideoContentProps> = ({ item }) => {
  const [imgError, setImgError] = useState(false)
  const [isAutoPlayRunning, setIsAutoPlayRunning] = useState(false)
  const [isMovingRunning, setIsMovingRunning] = useState(false)
  const [grabbing, setGrabbing] = useState(false)
  const [zoomValue, setZoomValue] = useState(1)
  const tridiRef = useRef(null)

  const imagePreview = () => {
    if (item.images && item.images.length && !imgError) {
      return <img src={item.images[0]} alt='diamond' onError={() => setImgError(true)} />
    }

    if (item.part_of_media_file && item.part_of_media_file.length && !imgError) {
      return <img src={item.part_of_media_file[0]} alt='diamond' onError={() => setImgError(true)} />
    }

    return (
      <div
        style={{ minHeight: '400px' }}
        className='text-white bg-bluegray-300 flex items-center justify-center h-full w-auto text-7xl border border-bluegray-200'
      >
        <Gem />
      </div>
    )
  }
  return (
    <div className='flex max-w-[600px] w-full'>
      {item.part_of_media_file.length > 5 ? (
        <div className='video-block relative border border-bluegray-200 min-w-full h-auto'>
          <div
            aria-hidden
            className='video-container relative'
            onMouseDown={() => {
              // @ts-ignore
              tridiRef.current.toggleAutoplay(false)
              setIsAutoPlayRunning(false)
              setGrabbing(true)
            }}
            onMouseUp={() => setGrabbing(false)}
            onMouseLeave={() => setGrabbing(false)}
          >
            <Tridi
              ref={tridiRef}
              className={`diamond-video h-full z-20 active:cursor-grabbing ${
                grabbing ? 'video-grabbing' : 'cursor-grab'
              }`}
              images={item.part_of_media_file}
              format='jpg'
              count={item.part_of_media_file.length}
              autoplay={isAutoPlayRunning}
              zoom={zoomValue}
              mousewheel
              mouseleaveDetect
              autoplaySpeed={45}
              // dragInterval={2}
              onLoadChange={(loadSuccess: any) => {
                if (loadSuccess) setIsAutoPlayRunning(true)
              }}
            />
          </div>

          <div className='video-control-buttons flex justify-around w-full p-2 text-2xl text-slate-600 bg-white bg-opacity-20 z-40'>
            <button
              type='button'
              className='video-control-play mx-1 px-1'
              onClick={() => {
                // @ts-ignore
                tridiRef.current.toggleAutoplay(!isAutoPlayRunning)
                setIsAutoPlayRunning(!isAutoPlayRunning)
              }}
            >
              {isAutoPlayRunning ? <IconPauseCircle /> : <IconPlayCircle />}
            </button>
            <button
              type='button'
              className='video-control-prev mx-1 px-1'
              onClick={() => {
                // @ts-ignore
                tridiRef.current.prev()
              }}
              disabled={zoomValue <= 1 || isAutoPlayRunning}
            >
              <IconArrowCircle />
            </button>
            <button
              type='button'
              className='video-control-next mx-1 px-1'
              onClick={() => {
                // @ts-ignore
                tridiRef.current.next()
              }}
              disabled={zoomValue <= 1 || isAutoPlayRunning}
            >
              <IconArrowCircle className='rotate-180' />
            </button>
            <button
              className='video-control-zoom-minus mx-1 px-1'
              type='button'
              onClick={() => {
                if (zoomValue > 1) setZoomValue((prev) => prev - 0.25)
              }}
            >
              <IconGlassMinus />
            </button>
            <button
              className='video-control-zoom-plus mx-1 px-1'
              type='button'
              onClick={() => {
                if (zoomValue < 2) setZoomValue((prev) => prev + 0.25)
              }}
            >
              <IconGlassPlus />
            </button>
            <button
              className='video-control-move mx-1 px-1'
              type='button'
              onClick={() => {
                // @ts-ignore
                tridiRef.current.toggleMoving(!isMovingRunning)
                setIsMovingRunning(!isMovingRunning)
                // @ts-ignore
                tridiRef.current.toggleAutoplay(false)
                setIsAutoPlayRunning(false)
                if (isMovingRunning) setZoomValue(1)
              }}
            >
              {isMovingRunning ? <IconCircleStop /> : <IconFourArrows />}
            </button>
          </div>
        </div>
      ) : (
        <div className='main-image img w-full'>{imagePreview()}</div>
      )}
    </div>
  )
}
