import { useEffect } from 'react'
import { addNewParams } from '../utils/helpers'

export interface searchProps {
  limit: number,
  offset: number,
  search?: string,
  
  [x: string]: any
}

export const useSearch = (limitAmount: number, params: searchProps, setCallback: (value: (((prevState: searchProps) => searchProps) | searchProps)) => void, setDispatchTime: (value: (((prevState: number) => number) | number)) => void, isRendered?: any) => {
  
  useEffect(() => {
    if (isRendered !== undefined && isRendered.current !== true) return () => {}
    if (params.search === undefined || params.search === null) return () => {}
    
    const timerId = setTimeout(() => {
      addNewParams('search', params.search || '')
      
      if (params.limit === limitAmount && params.offset === 0)
        setDispatchTime(prev => prev + 1)
      else
        setCallback((prev: searchProps) => ({ ...prev, offset: 0, limit: limitAmount }))
    }, 500)
    
    return () => clearTimeout(timerId)
  }, [params.search])
  
}