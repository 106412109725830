import React from 'react'
import ReactDOM from 'react-dom'
import { Close } from '../../../svg/Close'
import { IconCheckCircle } from '../../../svg/IconCheckCircle'
import { IconThumbsUp } from '../../../svg/IconThumbsUp'

export const NewGallerySnackBar = () => {
  let timerId: any = null
  const containers: HTMLDivElement[] = []
  
  const ErrorSnackBar = (text: string) => (
    <div
      className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded'
      role='alert'
    >
      <span className='block sm:inline'>{text}</span>
    </div>
  )
  const SuccessSnackBar = (actionType: string) => (
    <div className="alerts">
      {actionType === 'addBid'
      ? (
        <div className="success-snack-bar bg-lightblue-600 p-6 w-full opacity-90 flex items-center">
          <div className="flex items-center space-x-6 w-full justify-center">
            <span className='text-white text-3xl'>
              <IconThumbsUp styled='opacity-40' />
            </span>
            <div className="message text-white text-xl font-circa font-semibold">
              Your bid has been placed.
            </div>
          </div>

          <button
            type='button'
            className='text-white'
            id='snack-close-button'
          >
            <Close />
          </button>
        </div>
      ) : null}

      {actionType === 'editBid'
      ? (
        <div className="success-snack-bar bg-lightblue-600 p-6 w-full opacity-90 flex items-center">
          <div className="flex items-center space-x-6 w-full justify-center">
            <span className='text-white text-3xl'>
              <IconThumbsUp styled='opacity-40' />
            </span>
            <div className="message text-white text-xl font-circa font-semibold">
              Your bid has been edited.
            </div>
          </div>

          <button
            type='button'
            className='text-white'
            id='snack-close-button'
          >
            <Close />
          </button>
        </div>
      ) : null}

      {actionType === 'deleteBid'
      ? (
        <div className="success-snack-bar bg-lightblue-600 p-6 w-full opacity-90 flex items-center">
          <div className="flex items-center space-x-6 w-full justify-center">
            <span className='text-white text-3xl'>
              <IconThumbsUp styled='opacity-40' />
            </span>
            <div className="message text-white text-xl font-circa font-semibold">
              Your bid has been deleted.
            </div>
          </div>

          <button
            type='button'
            className='text-white'
            id='snack-close-button'
          >
            <Close />
          </button>
        </div>
      ) : null}

      {actionType === 'purchased'
      ? (
        <div className="success-snack-bar bg-green-600 p-6 w-full opacity-90 flex items-center">
          <div className="flex items-center space-x-6 w-full justify-center">
            <span className='text-white text-3xl'>
              <IconCheckCircle styled='' className='opacity-40' />
            </span>
            <div className="message text-white text-xl font-circa font-semibold">
              Your purchase has been confirmed.
            </div>
          </div>

          <button
            type='button'
            className='text-white'
            id='snack-close-button'
          >
            <Close />
          </button>
        </div>
      ) : null}
    </div>
  )
  
  const addNewGallerySnackBar = (message: string, success: boolean, actionType: string) => {
    clearTimeout(timerId)
    containers.map(container => container.remove())
    const container = document.createElement('div')
    
    container.style.position = 'fixed'
    container.style.zIndex = '10000'
    container.style.top = '0'
    container.style.right = '0'
    container.style.minWidth = '100%'
    container.style.transitionDuration = '1000ms'
    container.style.opacity = '1'
    container.className = 'transition-all'
    
    ReactDOM.render(
      success ? SuccessSnackBar(actionType) : ErrorSnackBar(message),
      document.body.appendChild(container),
    )
    setTimeout(() => {
      container.style.opacity = '1'
    }, 0)
    timerId = setTimeout(() => {
      container.style.top = '-500px'
      setTimeout(() => {
        container.remove()
      }, 500)
    }, 5000)
    document.getElementById('snack-close-button')?.addEventListener('click', () => container.remove())

    containers.push(container)
  }
  
  return {
    addNewGallerySnackBar,
  }
}
