import React from 'react'
import ReactDOM from 'react-dom'

export const SnackBar = () => {
  let timerId: any = null
  const containers: HTMLDivElement[] = []

  const ErrorSnackBar = (text: string) => (
    <div className='error-alert bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded' role='alert'>
      {/* <strong className='font-bold'>Error: </strong> */}
      <span className='block sm:inline'>{text}</span>
    </div>
  )
  const SuccessSnackBar = (text: string = '') => (
    <div className='success-alert bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded' role='alert'>
      {/* <strong className='font-bold'>Success: </strong> */}
      <span className='block sm:inline'>{text}</span>
    </div>
  )

  const addSnackBar = (message: string, success: boolean, className?: string) => {
    clearTimeout(timerId)
    containers.map((container) => container.remove())
    const container = document.createElement('div')

    container.style.position = 'fixed'
    container.style.zIndex = '10000'
    container.style.top = '10vh'
    container.style.right = '50px'
    container.style.minWidth = '260px'
    container.style.transitionDuration = '1000ms'
    container.style.opacity = '0'
    container.className = `transition-all ${className}`
    if (window.innerWidth < 450) {
      container.style.right = '5px'
      container.style.left = '5px'
    }

    ReactDOM.render(success ? SuccessSnackBar(message) : ErrorSnackBar(message), document.body.appendChild(container))
    setTimeout(() => {
      container.style.opacity = '1'
    }, 0)
    timerId = setTimeout(() => {
      container.style.right = '-500px'
      if (window.innerWidth < 450) {
        container.style.left = '1000px'
      }
      setTimeout(() => {
        container.remove()
      }, 500)
    }, 5000)
    containers.push(container)
  }

  return {
    addSnackBar,
  }
}
