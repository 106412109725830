import { Actions } from '../../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../../types/actions'

/// ///////////////////////////////////////////////////////////////////////
const GetAllUsersActions = new Actions('GET_All_USERS', ActionTypes)
const GetAllUsersWith0OffsetActions = new Actions('GET_All_USERS_WITH_0_OFFSET', ActionTypes)

export interface TypeGetAllUsers extends TDefRequest {
  offset: number
  limit: number
  search?: string
  sortBy?: string
  sort?: string
  companyId?: number[]
  roleId?: number[]
  categoryId?: number[]
  readOnlyCategoryId?: number[]
  recommendationItemId?: number
  withoutBid?: boolean
}

export const GetAllUsers = {
  request: (payload?: TypeGetAllUsers) => GetAllUsersActions.request(payload || {}),
  success: (payload: any) => GetAllUsersActions.success(payload),
  error: (payload: string) => GetAllUsersActions.error(payload),
}

export const GetAllUsersWith0Offset = {
  success: (payload: any) => GetAllUsersWith0OffsetActions.success(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetUserByIdActions = new Actions('GET_USER_BY_ID', ActionTypes)

export interface TypeGetUserById extends TDefRequest {
  userId: number
}

export const GetUserById = {
  request: (payload: TypeGetUserById) => GetUserByIdActions.request(payload),
  success: (payload: any) => GetUserByIdActions.success(payload),
  error: (payload: string) => GetUserByIdActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const CreateNewUserActions = new Actions('CREATE_NEW_USER', ActionTypes)

export interface TypeCreateNewUser extends TDefRequest {
  body: {
    company_id: number
    disabled: boolean
    email: string
    first_name: string
    full_access_categories: number[]
    is_mobile: boolean
    last_name: string
    phone: string
    role_id?: number
    view_only_categories?: number[]
  }
}

export const CreateNewUser = {
  request: (payload: TypeCreateNewUser) => CreateNewUserActions.request(payload),
  success: (payload: any) => CreateNewUserActions.success(payload),
  error: (payload: string) => CreateNewUserActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const UpdateUserInfoActions = new Actions('UPDATE_USER_INFO', ActionTypes)

export interface TypeUpdateUserInfo extends TDefRequest {
  user_id: number
  body: {
    company_id: number
    disabled: boolean
    email: string
    first_name: string
    full_access_categories: number[]
    is_mobile: boolean
    last_name: string
    message_method_id?: number
    phone: string
    role_id?: number
    terms_confirmed: boolean
    view_only_categories?: number[]
  }
}

export const UpdateUserInfo = {
  request: (payload: TypeUpdateUserInfo) => UpdateUserInfoActions.request(payload),
  success: (payload: any) => UpdateUserInfoActions.success(payload),
  error: (payload: string) => UpdateUserInfoActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetUsersCsvTemplateActions = new Actions('GET_USERS_CSV_TEMPLATE', ActionTypes)

export interface TypeGetUsersCsvTemplate extends TDefRequest {}

export const GetUsersCsvTemplate = {
  request: (payload?: TypeGetUsersCsvTemplate) => GetUsersCsvTemplateActions.request(payload || {}),
  success: (payload: any) => GetUsersCsvTemplateActions.success(payload),
  error: (payload: string) => GetUsersCsvTemplateActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetCategoryCsvActions = new Actions('GET_CATEGORY_CSV', ActionTypes)

export interface TypeGetCategoryCsv extends TDefRequest {
  categoryId: number
}

export const GetCategoryCsv = {
  request: (payload?: TypeGetCategoryCsv) => GetCategoryCsvActions.request(payload || {}),
  success: (payload: any) => GetCategoryCsvActions.success(payload),
  error: (payload: string) => GetCategoryCsvActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const PostUsersCsvTemplateActions = new Actions('POST_USERS_CSV_TEMPLATE', ActionTypes)

export interface TypePostUsersCsvTemplate extends TDefRequest {
  file: any
}

export const PostUsersCsvTemplate = {
  request: (payload: TypePostUsersCsvTemplate) => PostUsersCsvTemplateActions.request(payload),
  success: (payload: any) => PostUsersCsvTemplateActions.success(payload),
  error: (payload: string) => PostUsersCsvTemplateActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetTokensForViewLikeAUserActions = new Actions('GET_TOKENS_FOR_VIEW_LIKE_A_USER', ActionTypes)

export interface TypeGetTokensForViewLikeAUser extends TDefRequest {
  idUser: number
  with_terms_confirmed: boolean
}

export const GetTokensForViewLikeAUser = {
  request: (payload: TypeGetTokensForViewLikeAUser) => GetTokensForViewLikeAUserActions.request(payload),
  success: (payload: any) => GetTokensForViewLikeAUserActions.success(payload),
  error: (payload: string) => GetTokensForViewLikeAUserActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const ResendInviteActions = new Actions('RESEND_INVITE', ActionTypes)

export interface TypeResendInvite extends TDefRequest {
  idUser: number
}

export const ResendInvite = {
  request: (payload: TypeResendInvite) => ResendInviteActions.request(payload),
  success: (payload: any) => ResendInviteActions.success(payload),
  error: (payload: string) => ResendInviteActions.error(payload),
}
