/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { Mapping } from './SettingContent/Mapping'
import { Cms } from './SettingContent/Cms/Cms'
import { ROUTES } from '../utils/routes'
import { P404 } from '../pages'
import { EmailLogs } from './SettingContent/EmailLogs'
import { MediaManagement } from './SettingContent/MediaManagement'

export const SettingContent = () => {
  const history = useHistory()
  const location = useLocation()

  return (
    <div className='bg-white pb-10'>
      <div className='flex relative justify-start space-x-10 mb-6 text-base font-semibold overflow-x-auto'>
        <div
          className={`settingTabGlobal ${
            location.pathname.search(ROUTES.adminPageSettingsMappingDiamonds) !== -1 && 'settingTabGlobalSelected'
          }`}
          onClick={() => history.push(`${ROUTES.adminPageSettingsMappingDiamonds}/shape`)}
        >
          Mappings
        </div>
        <div
          className={`settingTabGlobal ${
            location.pathname.search(ROUTES.adminPageSettingsCsm) !== -1 && 'settingTabGlobalSelected'
          }`}
          onClick={() => history.push(ROUTES.adminPageSettingsCsm)}
        >
          CMS
        </div>
        <div
          className={`settingTabGlobal ${
            location.pathname.search(ROUTES.adminPageSettingsEmailLogs) !== -1 && 'settingTabGlobalSelected'
          }`}
          onClick={() => history.push(ROUTES.adminPageSettingsEmailLogs)}
        >
          Email Logs
        </div>
        <div
          className={`settingTabGlobal ${
            location.pathname.search(ROUTES.adminPageSettingsMediaManagement) !== -1 && 'settingTabGlobalSelected'
          }`}
          onClick={() => history.push(`${ROUTES.adminPageSettingsMediaManagement}/upload`)}
        >
          Media Management
        </div>
        <div className='absolute h-px margin-l-r-0 left-0 right-0 bottom-0 bg-bluegray-300' />
      </div>
      <Switch>
        <Route
          path={[ROUTES.adminPageSettingsMappingDiamonds, `/worthy${ROUTES.adminPageSettingsMappingDiamonds}`]}
          component={Mapping}
        />
        <Route path={[ROUTES.adminPageSettingsCsm, `/worthy${ROUTES.adminPageSettingsCsm}`]} component={Cms} />
        <Route
          path={[ROUTES.adminPageSettingsEmailLogs, `/worthy${ROUTES.adminPageSettingsEmailLogs}`]}
          component={EmailLogs}
        />
        <Route
          path={[ROUTES.adminPageSettingsMediaManagement, `/worthy${ROUTES.adminPageSettingsMediaManagement}`]}
          component={MediaManagement}
        />
        <Route path='*' component={P404} />
      </Switch>
    </div>
  )
}
