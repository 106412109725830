import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppStore } from '../store/applicationState'
import image from '../images/403Hammer.png'
import { Icon403 } from '../svg/Icon403'
import { Button } from '../components/ui/adminComponents/Button'
import { ROUTES } from '../utils/routes'

export const P403 = () => {
  const history = useHistory()

  const { userData } = useSelector((store: AppStore) => store)

  return (
    <div className='relative h-screen flex mx-auto overflow-hidden'>
      <div className='absolute h-full left-0 top-0 bottom-0 flex items-center'>
        <img src={image} alt='403Hammer' />
      </div>
      <div
        style={{ maxWidth: 720 }}
        className='ml-auto desktop-ml-50vw md:mr-20 z-10 flex px-2 md:px-0 -mt-10 justify-center items-center flex-col'
      >
        <Icon403 width='100%' />
        <h1 className='border-lightblue-900 text-lightblue-900 uppercase font-semibold text-3xl mobile:text-6xl mt-4'>
          Forbidden
        </h1>
        <h1 className='border-lightblue-900 text-lightblue-900 mobile:text-lg font-semibold uppercase text-center mt-10 mb-6'>
          We’re sorry, but you don’t have access to this page
        </h1>
        <div className='w-full flex flex-wrap smallMobile:flex-nowrap px-4 gap-4 mobile:px-8 mobile:gap-8'>
          {(userData.isAssociateAdmin || userData.isExecutiveAdmin) && (
            <Button onClick={() => history.push(ROUTES.adminPageTender)} variant='goTo' text='Go to admin panel' />
          )}
          <Button
            onClick={() => {
              history.push(ROUTES.dashboard)
            }}
            variant='goTo'
            text='Go to dashboard'
          />
        </div>
      </div>
    </div>
  )
}
