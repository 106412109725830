/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { DropDownSimple } from '../../components/ui/adminComponents/DropDownSimple'
import { ROUTES } from '../../utils/routes'
import { SettingTable } from '../../components/ui/adminComponents/SettingTable'
import { AppStore } from '../../store/applicationState'
import { paginationProps, usePagination } from '../../hooks/usePagination'
import {
  TListType,
  TSettingMappingDiamondsObject,
  TSettingMappingOptions,
} from '../../store/admin/settings/mapping/types'
import {
  GetDiamondsClarity,
  GetDiamondsColor,
  GetDiamondsCutPolishSymmetry,
  GetDiamondsFluorescence,
  GetDiamondsShape,
  GetOptionsByType,
  PutDiamondsDictionary,
} from '../../store/admin/settings/mapping/actions'
import { LoaderPoints } from '../../components/ui/LoaderPoints'

const menuList: TSettingMappingOptions['child_options'] = [{ option_name: 'Diamonds', options_group_id: 1 }]

export const Mapping = () => {
  const limitAmount = 400
  const initialParams = { limit: limitAmount, offset: 0 }

  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { settingMapping } = useSelector((store: AppStore) => store)

  const [activeTab, setActiveTab] = useState<
    'shape' | 'color' | 'clarity' | 'fluorescence' | 'cut_polish_symmetry' | null
  >(null)
  const [params, setParams] = useState<paginationProps>(initialParams)

  useEffect(() => {
    if (location.pathname.search(`${ROUTES.adminPageSettingsMappingDiamonds}/color`) !== -1) setActiveTab('color')
    else if (location.pathname.search(`${ROUTES.adminPageSettingsMappingDiamonds}/clarity`) !== -1)
      setActiveTab('clarity')
    else if (location.pathname.search(`${ROUTES.adminPageSettingsMappingDiamonds}/fluorescence`) !== -1)
      setActiveTab('fluorescence')
    else if (location.pathname.search(`${ROUTES.adminPageSettingsMappingDiamonds}/cut-polish-symmetry`) !== -1)
      setActiveTab('cut_polish_symmetry')
    else setActiveTab('shape')
  }, [location.pathname])

  const memorizedData: { diamonds: TSettingMappingDiamondsObject[] | null; option: TSettingMappingOptions | null } =
    useMemo(() => {
      let diamonds: TSettingMappingDiamondsObject[] | null = null
      let parentId: number | null = null
      if (activeTab === 'shape' && settingMapping.data.diamonds.shapes) {
        diamonds = settingMapping.data.diamonds.shapes
        parentId = settingMapping.data.diamonds.shapes[0].type_of_option_id
      }
      if (activeTab === 'color' && settingMapping.data.diamonds.colors) {
        diamonds = settingMapping.data.diamonds.colors
        parentId = settingMapping.data.diamonds.colors[0].type_of_option_id
      }
      if (activeTab === 'clarity' && settingMapping.data.diamonds.clarity) {
        diamonds = settingMapping.data.diamonds.clarity
        parentId = settingMapping.data.diamonds.clarity[0].type_of_option_id
      }
      if (activeTab === 'fluorescence' && settingMapping.data.diamonds.fluorescence) {
        diamonds = settingMapping.data.diamonds.fluorescence
        parentId = settingMapping.data.diamonds.fluorescence[0].type_of_option_id
      }
      if (activeTab === 'cut_polish_symmetry' && settingMapping.data.diamonds.cut_polish_symmetry) {
        diamonds = settingMapping.data.diamonds.cut_polish_symmetry
        parentId = settingMapping.data.diamonds.cut_polish_symmetry[0].type_of_option_id
      }
      return { diamonds, option: settingMapping.data.options.find((item) => item.parent_id === parentId) || null }
    }, [settingMapping.data.diamonds, settingMapping.data.options.length])

  usePagination(memorizedData.diamonds, setParams, limitAmount)

  useEffect(() => {
    setParams(initialParams)
  }, [activeTab])

  useEffect(() => {
    if (activeTab === 'shape') {
      dispatch(GetDiamondsShape.request({ offset: params.offset, limit: params.limit }))
    } else if (activeTab === 'color') {
      dispatch(GetDiamondsColor.request({ offset: params.offset, limit: params.limit }))
    } else if (activeTab === 'clarity') {
      dispatch(GetDiamondsClarity.request({ offset: params.offset, limit: params.limit }))
    } else if (activeTab === 'fluorescence') {
      dispatch(GetDiamondsFluorescence.request({ offset: params.offset, limit: params.limit }))
    } else if (activeTab === 'cut_polish_symmetry') {
      dispatch(GetDiamondsCutPolishSymmetry.request({ offset: params.offset, limit: params.limit }))
    }
  }, [params])

  useEffect(() => {
    if (activeTab === 'shape' && settingMapping.data.diamonds.shapes && !memorizedData.option) {
      dispatch(
        GetOptionsByType.request({ type_of_option_id: settingMapping.data.diamonds.shapes[0].type_of_option_id })
      )
    } else if (activeTab === 'color' && settingMapping.data.diamonds.colors && !memorizedData.option) {
      dispatch(
        GetOptionsByType.request({ type_of_option_id: settingMapping.data.diamonds.colors[0].type_of_option_id })
      )
    } else if (activeTab === 'clarity' && settingMapping.data.diamonds.clarity && !memorizedData.option) {
      dispatch(
        GetOptionsByType.request({ type_of_option_id: settingMapping.data.diamonds.clarity[0].type_of_option_id })
      )
    } else if (activeTab === 'fluorescence' && settingMapping.data.diamonds.fluorescence && !memorizedData.option) {
      dispatch(
        GetOptionsByType.request({ type_of_option_id: settingMapping.data.diamonds.fluorescence[0].type_of_option_id })
      )
    } else if (
      activeTab === 'cut_polish_symmetry' &&
      settingMapping.data.diamonds.cut_polish_symmetry &&
      !memorizedData.option
    ) {
      dispatch(
        GetOptionsByType.request({
          type_of_option_id: settingMapping.data.diamonds.cut_polish_symmetry[0].type_of_option_id,
        })
      )
    }
  }, [settingMapping.data.diamonds])

  const setDiamondsDictionary = (option: TListType, item: TSettingMappingDiamondsObject) => {
    dispatch(
      PutDiamondsDictionary.request({
        options_group_id: option.options_group_id,
        option_id: item.option_id,
        callBack: (success) => {
          if (success) {
            if (activeTab === 'shape') {
              dispatch(GetDiamondsShape.request({ offset: 0, limit: params.offset + params.limit }))
            } else if (activeTab === 'color') {
              dispatch(GetDiamondsColor.request({ offset: 0, limit: params.offset + params.limit }))
            } else if (activeTab === 'clarity') {
              dispatch(GetDiamondsClarity.request({ offset: 0, limit: params.offset + params.limit }))
            } else if (activeTab === 'fluorescence') {
              dispatch(GetDiamondsFluorescence.request({ offset: 0, limit: params.offset + params.limit }))
            } else if (activeTab === 'cut_polish_symmetry') {
              dispatch(GetDiamondsCutPolishSymmetry.request({ offset: 0, limit: params.offset + params.limit }))
            }
          }
        },
      })
    )
  }

  return (
    <>
      <div className='flex flex-col smallMobile:flex-row'>
        <div style={{ minWidth: '200px' }} className='flex h-16 smallMobile:h-auto smallMobile:w-1/4'>
          <DropDownSimple
            list={menuList}
            // optionId={1}
            optionTitle='Diamonds'
            className='mb-6 smallMobile:mr-4 mobile:mr-6 text-base'
            withArrow
          />
        </div>
        <div className='flex justify-start space-x-1 mobile:space-x-6 mb-6 text-base text-bluegray-500 tab-group overflow-x-auto'>
          <div
            className={`settingTab ${activeTab === 'shape' && 'activeSettingTab'}`}
            onClick={() => {
              setActiveTab('shape')
              history.push(`${ROUTES.adminPageSettingsMappingDiamonds}/shape`)
            }}
          >
            Shape
          </div>
          <div
            className={`settingTab ${activeTab === 'color' && 'activeSettingTab'}`}
            onClick={() => {
              setActiveTab('color')
              history.push(`${ROUTES.adminPageSettingsMappingDiamonds}/color`)
            }}
          >
            Color
          </div>
          <div
            className={`settingTab ${activeTab === 'clarity' && 'activeSettingTab'}`}
            onClick={() => {
              setActiveTab('clarity')
              history.push(`${ROUTES.adminPageSettingsMappingDiamonds}/clarity`)
            }}
          >
            Clarity
          </div>
          <div
            className={`settingTab ${activeTab === 'fluorescence' && 'activeSettingTab'}`}
            onClick={() => {
              setActiveTab('fluorescence')
              history.push(`${ROUTES.adminPageSettingsMappingDiamonds}/fluorescence`)
            }}
          >
            Fluorescence
          </div>
          <div
            className={`settingTab ${activeTab === 'cut_polish_symmetry' && 'activeSettingTab'} whitespace-nowrap`}
            onClick={() => {
              setActiveTab('cut_polish_symmetry')
              history.push(`${ROUTES.adminPageSettingsMappingDiamonds}/cut-polish-symmetry`)
            }}
          >
            Cut, Polish, Symmetry
          </div>
        </div>
      </div>
      <div className='relative'>
        <SettingTable
          tbody={memorizedData.diamonds}
          list={memorizedData.option}
          callBack={setDiamondsDictionary}
          loading={settingMapping.loadingDiamonds}
        />
        {settingMapping.loadingDiamonds && (
          <div className='absolute left-0 right-0 -bottom-8 mt-20 flex justify-center'>
            <LoaderPoints className='m-auto' />
          </div>
        )}
      </div>
    </>
  )
}
