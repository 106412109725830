/* eslint-disable func-names,no-console */
import { useEffect, useState } from 'react'
import { checkToken, refresh, ReturnType } from '../utils/checkToken'
import { useTenderForPersRecommend } from './Sockets/useTenderForPersRecommend'
import { recMode } from '../utils/consts'

export const useSocketForPersRecommend = () => {
  const [wsState, setWsState] = useState<WebSocket | null>(null)

  const { tenderListener } = useTenderForPersRecommend(wsState)

  const connect = async () => {
    const { token }: ReturnType = await checkToken(true)
    if (!token) return

    const ws: WebSocket = new WebSocket(`${process.env.REACT_APP_WEB_SOCKET_PATH}/api/v1/ws`)

    ws.onopen = function () {
      setWsState(ws)
      console.log('connect for personal recommend mode')
      ws.send(JSON.stringify({ token }))
    }

    ws.onmessage = function (evt) {
      // console.log(evt, 'onmessage3')
      const data = JSON.parse(evt.data) as { type_event: string; event_data: any }

      if (data.type_event === 'item_event') tenderListener(data.event_data)
    }

    ws.onerror = function (evt) {
      console.log(evt, 'onerror')
    }

    ws.onclose = async function (evt) {
      console.log(evt, 'onclose')
      setWsState(null)
      if (!document.hidden)
        if (evt.reason === 'valid access token') {
          await refresh()
          connect().catch(console.warn)
        } else {
          setTimeout(() => {
            connect()
          }, 1000)
        }
    }
  }

  useEffect(() => {
    if (recMode) connect().catch(console.log)
  }, [])

  useEffect(() => {
    if (wsState) {
      wsState.onmessage = function (evt) {
        // console.log(evt, 'onmessage4')
        const data = JSON.parse(evt.data) as { type_event: string; event_data: any }

        if (data.type_event === 'item_event') tenderListener(data.event_data)
      }
    }
  }, [tenderListener, wsState])

  useEffect(() => {
    const onVisibilityChange = () => {
      if (!document.hidden && !wsState && recMode) connect().catch(console.log)
    }

    document.addEventListener('visibilitychange', onVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
      wsState?.close()
    }
  }, [wsState])
}
