export interface TDiamondVideoMessage {
  message: string
}

export interface TDiamondVideo {
  images: string[],
  part_of_media_file: string[],
  report_images: string[],
  report_pdf_2_images: string[],
  report_pdfs: string[],
  video_mp4: string[],
  videos: string[]
}

export interface TDiamondVideoState {
  loading: boolean
  data: TDiamondVideo | null
  errors?: string | undefined
}

export enum ActionTypes {
  GET_DIAMOND_VIDEO_R = '@@DiamondVideo/GET_DIAMOND_VIDEO_R',
  GET_DIAMOND_VIDEO_S = '@@DiamondVideo/GET_DIAMOND_VIDEO_S',
  GET_DIAMOND_VIDEO_E = '@@DiamondVideo/GET_DIAMOND_VIDEO_E',
}
