import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../../components/ui/adminComponents/Button'
import { Table } from '../../../components/ui/adminComponents/Table'
import { getOnlyWinnersParsed } from '../../../utils/tendersUtils/tenderDataHelper'
import { GetTenderStatistic, SetTenderFinalized } from '../../../store/admin/tenders/finalizeTenders/actions'
import { AppStore } from '../../../store/applicationState'
import { GetAllTenders } from '../../../store/admin/tenders/actions'
import { PopUp } from '../../../components/ui/PopUp'
import { useAddItemPopup } from '../../../hooks/useAddItemPopup'
import { TenderStatusType } from '../../../utils/tendersUtils/tenderStatusHelper'

const onlyWinnersThead = {
  item: 'Item',
  customer: 'Customer',
  bid: 'Bid',
  action: '',
}

interface AllBidsByTenderOnlyWinnersProps {
  currentTenderId: number
  getChildForBidsByItem: (
    idItem: number,
    salesTarget: number,
    perCarat: number | undefined,
    listPrice: number,
    resolved: boolean,
    open?: boolean,
    scroll?: boolean
  ) => void
  tenderStatus: TenderStatusType | null
}

export const AllBidsByTenderOnlyWinners: React.FC<AllBidsByTenderOnlyWinnersProps> = ({
  currentTenderId,
  getChildForBidsByItem,
  tenderStatus,
}) => {
  const dispatch = useDispatch()
  const { bidsByItem, finalizeTenders } = useSelector((store: AppStore) => store)
  const { add } = useAddItemPopup()

  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [childIndex, setChildIndex] = useState<number[]>([])

  useEffect(() => {
    dispatch(GetTenderStatistic.request({ idTender: currentTenderId }))
  }, [currentTenderId])

  useEffect(() => {
    window.scrollTo({
      top: document.body.scrollHeight - 150,
      behavior: 'smooth',
    })
  }, [])

  const onFinalizeTender = () => {
    setLoading(true)
    dispatch(
      SetTenderFinalized.request({
        idTender: currentTenderId,
        callBack: (success) => {
          if (success) dispatch(GetAllTenders.request())
          setShowPopup(false)
          setLoading(false)
        },
      })
    )
  }

  const editChild = (
    idItem: number,
    salesTarget: number,
    perCarat: number | undefined,
    listPrice: number,
    resolved: boolean
  ) => {
    getChildForBidsByItem(idItem, salesTarget, perCarat, listPrice, resolved, true, true)
  }

  const onlyWinners = useMemo(() => {
    return bidsByItem.dataOnlyWinners ? (
      <Table
        thead={onlyWinnersThead}
        tbody={getOnlyWinnersParsed(bidsByItem.dataOnlyWinners, editChild, dispatch, add, tenderStatus)}
        no_borders_style
        childIndex={childIndex}
        setChildIndex={setChildIndex}
      />
    ) : null
  }, [bidsByItem.dataOnlyWinners])

  return (
    <>
      <div className='mt-12 mx-auto flex items-center justify-between space-x-8'>
        <div className='border mb-12 border-dotted border-bluegray-300 mx-auto w-full lg:w-11/12 max-w-5xl p-2.5 smallMobile:p-5 bg-bluegray-50'>
          <div className='text-xl font-circa font-semibold text-center mb-8'>
            Finalize Bidding &amp; Announce Results
          </div>
          <div className='flex lg:space-x-10 flex-col lg:flex-row space-y-6 lg:space-y-0'>
            <div className='h-64 overflow-y-scroll lg:w-8/12'>
              <div className='font-semibold uppercase mb-3 text-base'>Confirmed Winning Bids</div>
              {onlyWinners}
            </div>
            <div className='lg:w-4/12'>
              <div className='text-sm mb-4'>
                <div className='font-semibold uppercase mb-3 text-base'>Action Required</div>
                By clicking the button below, results will be finalized, and winning customers will be notified via
                their preferred communication method.
              </div>
              <Button id='finalize-btn' text='Finalize Tender' variant='finalize' onClick={() => setShowPopup(true)} />
              <div className='mt-3 text-sm'>
                <p id='circa-sold'>CIRCA SOLD: {finalizeTenders.data?.count_of_sold_items_with_circa_owner}</p>
                <p id='circa-reserve-not-met'>
                  CIRCA Reserve Not Met: {finalizeTenders.data?.count_of_reserve_not_met_items_with_owner_circa}
                </p>
                <p id='consign-sold'>CONSIGN SOLD: {finalizeTenders.data?.count_of_sold_consign_items}</p>
                <p id='consign-reserve-not-met'>
                  CONSIGN Reserve Not Met: {finalizeTenders.data?.count_of_reserve_not_met_consign_items}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className='fixed inset-0 bg-bluegray-900 bg-opacity-10 z-110'>
          <PopUp
            id='finalize-popup'
            onSubmit={onFinalizeTender}
            onCancel={() => setShowPopup(false)}
            disabled={loading}
            className='left-1/2 !top-1/2 shadow-2xl transform -translate-x-1/2 -translate-y-2/3 w-96'
          >
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <div>Confirm that you'd like to finalize this Tender.</div>
            This action cannot be undone.
          </PopUp>
        </div>
      )}
    </>
  )
}
