import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { AppStore } from '../../../store/applicationState'
import { GetAllCsmMessagesById, GetAllCsmTemplates } from '../../../store/admin/settings/cms/actions'
import { Table } from '../../../components/ui/adminComponents/Table'
import { EditCms } from './EditCms'
import { getMessages, getSchedule } from '../../../utils/settingUtils/ValueParsers'
import { GetMessageMethods } from '../../../store/messageMethods/actions'
import { Edit } from '../../../svg/Edit'
import { ROUTES } from '../../../utils/routes'
import { Input } from '../../../components/ui/adminComponents/Input'
import { searchProps, useSearch } from '../../../hooks/useSearch'
import { addNewParams } from '../../../utils/helpers'
import { P404 } from '../../../pages'

const thead = {
  messaging_template_id: 'Id',
  title: 'Template',
  schedule: 'Schedule',
  preferred_delivery_method: 'Channel',
  active: 'Automated Sending is Active',
  action: 'Action',
}

export const Cms = () => {
  const limitAmount = 400

  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { cms, messageMethods } = useSelector((store: AppStore) => store)

  const { search, sort, sortBy } = queryString.parse(location.search)

  const initialParams = {
    limit: limitAmount, // useless
    offset: 0, // useless
    search: (typeof search === 'string' && search) || undefined,
    sort: (typeof sort === 'string' && sort) || '',
    sortBy: (typeof sortBy === 'string' && sortBy) || '',
  }

  const [params, setParams] = useState<searchProps>(initialParams)
  const [dispatchTime, setDispatchTime] = useState(0)
  const [childIndex, setChildIndex] = useState<number[]>([])

  useSearch(limitAmount, params, setParams, setDispatchTime)

  useEffect(() => {
    dispatch(
      GetAllCsmTemplates.request({
        search: params.search,
        sort: params.sort,
        sortBy: params.sortBy,
      })
    )
    if (!messageMethods.data) dispatch(GetMessageMethods.request())
  }, [params.sort, params.sortBy, dispatchTime])

  const callBack = (sortByValue: string) => {
    if (sortByValue === 'action' || sortByValue === 'preferred_delivery_method') return

    let value: { sortBy: string; sort: 'ASC' | 'DESC' }
    if (sortByValue !== params.sortBy || params.sort === 'DESC') value = { sortBy: sortByValue, sort: 'ASC' }
    else value = { sortBy: sortByValue, sort: 'DESC' }

    addNewParams('sort', value.sort)
    addNewParams('sortBy', value.sortBy)

    setParams((prev) => ({ ...prev, sortBy: value.sortBy, sort: value.sort }))
  }

  const tbodyMemorized = useMemo(() => {
    return (
      (messageMethods.data &&
        cms.data?.map((item) => {
          if (!messageMethods.data) return {}
          const action = (
            <button
              type='button'
              className='w-6 h-4 flex justify-center'
              onClick={() => {
                dispatch(GetAllCsmMessagesById.success({ id: item.messaging_template_id, data: item.messages }))
                history.push(`${ROUTES.adminPageSettingsCsm}/edit/${item.messaging_template_id}/email`)
              }}
            >
              <div className='absolute'>
                <Edit />
              </div>
            </button>
          )
          const messages = item.preferred_delivery_method ? ['Preferred'] : []
          messages.push(...getMessages(item.messages, messageMethods.data))

          return {
            messaging_template_id: item.messaging_template_id,
            title: item.title,
            schedule: item.have_schedule ? getSchedule(item.schedule) : '-',
            preferred_delivery_method: messages.filter((string) => string).join(', '),
            active: `${item.active}`,
            action,
          }
        })) ||
      null
    )
  }, [cms.data, messageMethods.data])

  return (
    <Switch>
      <Route exact path={[ROUTES.adminPageSettingsCsm, `/worthy${ROUTES.adminPageSettingsCsm}`]}>
        <div className='w-full md:w-1/3 lg:w-1/4 mb-6'>
          <Input
            placeholder='Search...'
            value={params.search || ''}
            onChange={(e) => setParams((prev) => ({ ...prev, search: e.target.value }))}
          />
        </div>
        <Table
          thead={thead}
          tbody={tbodyMemorized}
          callBack={callBack}
          childIndex={childIndex}
          setChildIndex={setChildIndex}
        />
      </Route>
      <Route
        path={[`${ROUTES.adminPageSettingsCsm}/edit/:id`, `/worthy${ROUTES.adminPageSettingsCsm}/edit/:id`]}
        component={EditCms}
      />
      <Route path='*' component={P404} />
    </Switch>
  )
}
