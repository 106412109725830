import { TOKEN, WORTHY_REDIRECT } from './consts'

export const setCookie = (name: string, value: string, options1 = {}) => {
  const options = {
    path: '/',
    secure: true,
    'max-age': 3600 * 24 * 7,
    samesite: 'None',
    ...options1,
  }

  // if (options.expires instanceof Date) {
  //   options.expires = options.expires.toUTCString()
  // }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`

  Object.entries(options).forEach(([key, objValue]) => {
    updatedCookie += `; ${key}`
    if (objValue !== true) {
      updatedCookie += `=${objValue}`
    }
  })

  document.cookie = updatedCookie
}

export const getCookie = (name: string) => {
  const matches = document.cookie.match(
    new RegExp(
      // eslint-disable-next-line no-useless-escape
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

export const deleteCookie = (name: string) => {
  setCookie(name, '', {
    'max-age': -1,
  })
}

export const deleteAuthCookie = () => {
  const names = [TOKEN.access, TOKEN.refresh, WORTHY_REDIRECT]
  names.forEach((name) => {
    setCookie(name, '', {
      'max-age': -1,
    })
  })
}
