export const isMobileDevice = (): boolean => 'ontouchstart' in window

export const addNewParams = (name: string, params: string) => {
  const { history, location } = window

  const urlParams = new URLSearchParams(location.search)
  urlParams.set(name, params)
  return history.pushState('', '', `${location.pathname}?${urlParams.toString()}`)
}

export const deleteParams = (name: string) => {
  const { history, location } = window

  const urlParams = new URLSearchParams(location.search)
  urlParams.delete(name)
  return history.pushState('', '', `${location.pathname}`)
}

export const deleteSearchParams = (name: string) => {
  const { history, location } = window
  const paramValue = new URLSearchParams(window.location.search).get(name)

  let urlParams = location.search
  if (urlParams.includes(`?${name}`)) urlParams = urlParams.replace(`?${name}=${paramValue}`, '')
  if (urlParams.includes(`&${name}`)) urlParams = urlParams.replace(`&${name}=${paramValue}`, '')

  return history.pushState('', '', `${location.pathname}${urlParams}`)
}

const getNth = (day: number) => {
  if (day > 3 && day < 21) return 'th'
  switch (day % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

export const getDate = (date: string): string => {
  const dateType = new Date(date)
  if (Number.isNaN(dateType.getDate())) return 'Invalid date'
  if (date === '0001-01-01T00:00:00Z') return '-----'
  const formatedDate = dateType
    .toLocaleString('en-US', {
      hour12: true,
      month: 'numeric',
      day: 'numeric',
      year: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: 'America/New_York',
    })
    .replaceAll(',', '')
  const miliSec = `:${dateType.getMilliseconds()}`
  return formatedDate.slice(0, -3) + miliSec + formatedDate.slice(-3)
}

export const getShortDate = (date: string): string => {
  const dateType = new Date(date)
  if (Number.isNaN(dateType.getDate())) return 'Invalid date'
  if (date === '0001-01-01T00:00:00Z') return '-----'
  return dateType
    .toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      timeZone: 'America/New_York',
    })
    .replaceAll(',', '')
    .split(' ')
    .map((item, i) => (i === 1 ? item + getNth(+item) : item))
    .join(' ')
}

export const getCurrentDate = (): string => {
  const today = new Date()
  const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
  const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
  return `${date} ${time}`
}

export function setTimeout_1(fn: any, delay: number) {
  const maxDelay = 2 ** 31 - 1

  if (delay > maxDelay) {
    // eslint-disable-next-line prefer-rest-params
    const args = arguments
    args[1] -= maxDelay

    return setTimeout(() => {
      // @ts-ignore
      // eslint-disable-next-line prefer-spread
      setTimeout_1.apply(undefined, args)
    }, maxDelay)
  }
  // @ts-ignore
  // eslint-disable-next-line prefer-spread,prefer-rest-params
  return setTimeout.apply(undefined, arguments)
}

export const isScrolledIntoView = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect()
  return rect.bottom > 0 && rect.right > 0
}

export const isHover = (e: HTMLElement) => e.parentElement?.querySelector(':hover') === e

// export function prepare(func: any, args: any, thisArg: any): any {
//   return function () {
//     func.apply(thisArg, args)
//   }
// }

export const getObjWithOutKeys = (object: object, keys: string[]) => {
  return Object.keys(object).reduce((newObj, key) => {
    if (keys.indexOf(key) === -1) {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      newObj[key] = object[key]
    }
    return newObj
  }, {})
}

// eslint-disable-next-line no-useless-escape
export const checkSymbols = (pass: string): boolean => /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(pass)
export const checkUpperCase = (pass: string): boolean => /[A-Z]/.test(pass)
export const checkLowerCase = (pass: string): boolean => /[a-z]/.test(pass)
export const checkNumbers = (pass: string): boolean => /\d/.test(pass)
export const checkLength = (pass: string, requiredLength: number = 8): boolean => pass.length >= requiredLength
