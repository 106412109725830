import { SetItemRecommendation, SetItemWishlist } from '../../store/gallery/actions'
import { DeleteItemBid, SetItemBid, SetItemBuy } from '../../store/gallery/tendersActions/actions'
import { BidStatus, TDefRequest } from '../../types/actions'

export const addToRecommendedAction = (
  isRecommended: boolean,
  companyId: number,
  idItem: number,
  dispatch: any,
  callback?: TDefRequest['callBack']
) => {
  dispatch(
    SetItemRecommendation.request({
      companyId: [companyId],
      idItem,
      status: !isRecommended,
      callBack: callback,
    })
  )
}

export const addToFavoritesAction = (isFavorites: boolean, tenderId: number, itemId: number, dispatch: any) => {
  dispatch(
    SetItemWishlist.request({
      idTender: tenderId,
      idItem: itemId,
      status: !isFavorites,
    })
  )
}

export const buyItemAction = (buyItemCallback: () => void, showError: () => void, tenderId: number, itemId: number, dispatch: any) => {
  dispatch(
    SetItemBuy.request({
      idTender: tenderId,
      idItem: itemId,
      callBack: (success, data) => {
        if (success) buyItemCallback()
        else if (data && data.message && data.message === 'do not have access') showError()
      },
    })
  )
}

export const deleteBidAction = (deleteBidCallback: () => void, showError: () => void, tenderId: number, itemId: number, dispatch: any) => {
  dispatch(
    DeleteItemBid.request({
      idTender: tenderId,
      idItem: itemId,
      callBack: (success, data) => {
        if (success) deleteBidCallback()
        else if (data && data.message && data.message === 'do not have access') showError()
      },
    })
  )
}

export const addBidAction = (
  addBidCallback: () => void,
  showError: () => void,
  tenderId: number,
  itemId: number,
  bid: number,
  itemBid: number,
  bidAmount: number,
  dispatch: any,
  status?: BidStatus
) => {
  if (bid >= 1) {
    const bidStatus: BidStatus = status || 'bid'
    dispatch(
      SetItemBid.request({
        idTender: tenderId,
        idItem: itemId,
        actionType: itemBid === 0 ? 'addBid' : 'editBid',
        body: {
          bid_amount: bidAmount,
          type_of_bid: bidStatus,
        },
        callBack: (success, data) => {
          if (success) addBidCallback()
          else if (data && data.message && data.message === 'do not have access') showError()
        },
      })
    )
  }
}
