/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom'
import { AllBids } from './TendersContent/AllBids'
import { BidsByItem } from './TendersContent/BidsByItem'
import { BidsByBidder } from './TendersContent/BidsByBidder'
import { BuyNowPurchases } from './TendersContent/BuyNowPurchases'
import { CloseTender } from './TendersContent/CloseTender'
import { AppStore } from '../store/applicationState'
import { TTenders } from '../store/admin/tenders/types'
import { ROUTES } from '../utils/routes'
import { P403, P404 } from '../pages'
import { AuditByTender } from './TendersContent/AuditByTender'
import { getStatusName, showAuditTable } from '../utils/tendersUtils/tenderStatusHelper'
import { clearDataListener } from '../utils/tendersUtils/tenderDataHelper'
import { ENVIRONMENT } from '../utils/consts'

interface TenderContentProps {}

export const TendersContent: React.FC<TenderContentProps> = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const { id, currentTenderId = +id } = useParams() as any
  const { userData, tenders } = useSelector((store: AppStore) => store)

  const tendersForChoose = useMemo(
    () => tenders.data?.filter((tender) => ['active', 'toBeClosed', 'completed'].includes(getStatusName(tender))),
    [tenders.data]
  )

  const currentTenderData: TTenders | null = useMemo(() => {
    if (tendersForChoose?.length && currentTenderId) {
      return tendersForChoose.find((item) => item.id === currentTenderId) || tendersForChoose[0]
    }
    return null
  }, [tendersForChoose, currentTenderId])

  const currentTenderStatus = useMemo(() => currentTenderData && getStatusName(currentTenderData), [currentTenderData])

  useEffect(() => {
    if (tendersForChoose?.length && !currentTenderId)
      history.replace(`${ROUTES.adminPageTender}/${tendersForChoose[0].id}/all-bids`)
  }, [tendersForChoose, currentTenderId])

  useEffect(() => {
    if (
      currentTenderStatus &&
      currentTenderStatus !== 'toBeClosed' &&
      location.pathname.search(`${ROUTES.adminPageTender}/${currentTenderId}/close-tender`) !== -1
    )
      history.replace(`${ROUTES.adminPageTender}/${currentTenderId}/all-bids`)
  }, [currentTenderStatus])

  useEffect(() => {
    if (currentTenderId) clearDataListener(window.location.pathname, dispatch)
  }, [currentTenderId])

  return (
    <div className='w-full mt-4 px-2 pb-6 smallestpx-2'>
      <div className='flex flex-col md:flex-row justify-between'>
        <div className='tabs text-sm md:text-base mb-4 space-x-4 md:space-x-12 tabs-secondary overflow-x-auto'>
          <div
            className={`tab min-w-max ${
              location.pathname.search(`${ROUTES.adminPageTender}/${currentTenderId}/all-bids`) !== -1 && 'active'
            }`}
            onClick={() => history.push(`${ROUTES.adminPageTender}/${currentTenderId}/all-bids`)}
          >
            All Bids
          </div>
          <div
            className={`tab min-w-max ${
              location.pathname.search(`${ROUTES.adminPageTender}/${currentTenderId}/bids-by-item`) !== -1 && 'active'
            }`}
            onClick={() => history.push(`${ROUTES.adminPageTender}/${currentTenderId}/bids-by-item`)}
          >
            Bids / Item
          </div>
          <div
            className={`tab min-w-max ${
              location.pathname.search(`${ROUTES.adminPageTender}/${currentTenderId}/bids-by-bidder`) !== -1 && 'active'
            }`}
            onClick={() => history.push(`${ROUTES.adminPageTender}/${currentTenderId}/bids-by-bidder`)}
          >
            Bids / Bidder
          </div>
          <div
            className={`tab min-w-max ${
              location.pathname.search(`${ROUTES.adminPageTender}/${currentTenderId}/buy-now-purchases`) !== -1 &&
              'active'
            }`}
            onClick={() => history.push(`${ROUTES.adminPageTender}/${currentTenderId}/buy-now-purchases`)}
          >
            BUY NOW Purchases
          </div>
          {currentTenderStatus === 'toBeClosed' && userData.isExecutiveAdmin && (
            <div
              className={`tab min-w-max ${
                location.pathname.search(`${ROUTES.adminPageTender}/${currentTenderId}/close-tender`) !== -1 && 'active'
              } text-orange-600`}
              onClick={() => history.push(`${ROUTES.adminPageTender}/${currentTenderId}/close-tender`)}
            >
              Close Tender
            </div>
          )}
          {userData.isExecutiveAdmin && (
            <div
              className={`tab min-w-max ${
                location.pathname.search(`${ROUTES.adminPageTender}/${currentTenderId}/audit`) !== -1 && 'active'
              }`}
              onClick={() => history.push(`${ROUTES.adminPageTender}/${currentTenderId}/audit`)}
            >
              Audit
            </div>
          )}
        </div>
      </div>
      {!!currentTenderId && currentTenderData && (
        <Switch>
          <Route path={[`${ROUTES.adminPageTender}/:id/all-bids`, `/worthy${ROUTES.adminPageTender}/:id/all-bids`]}>
            <AllBids currentTenderId={currentTenderId} tenderStatus={currentTenderStatus} />
          </Route>
          <Route
            path={[`${ROUTES.adminPageTender}/:id/bids-by-item`, `/worthy${ROUTES.adminPageTender}/:id/bids-by-item`]}
          >
            <BidsByItem currentTenderId={currentTenderId} tenderStatus={currentTenderStatus} />
          </Route>
          <Route
            path={[
              `${ROUTES.adminPageTender}/:id/bids-by-bidder`,
              `/worthy${ROUTES.adminPageTender}/:id/bids-by-bidder`,
            ]}
          >
            <BidsByBidder currentTenderId={currentTenderId} tenderStatus={currentTenderStatus} />
          </Route>
          <Route
            path={[
              `${ROUTES.adminPageTender}/:id/buy-now-purchases`,
              `/worthy${ROUTES.adminPageTender}/:id/buy-now-purchases`,
            ]}
          >
            <BuyNowPurchases currentTenderId={currentTenderId} tenderStatus={currentTenderStatus} />
          </Route>
          <Route
            path={[`${ROUTES.adminPageTender}/:id/close-tender`, `/worthy${ROUTES.adminPageTender}/:id/close-tender`]}
          >
            {currentTenderStatus === 'toBeClosed' && userData.isExecutiveAdmin ? (
              <CloseTender currentTenderId={currentTenderId} tenderStatus={currentTenderStatus} />
            ) : (
              <P403 />
            )}
          </Route>
          <Route path={[`${ROUTES.adminPageTender}/:id/audit`, `/worthy${ROUTES.adminPageTender}/:id/audit`]}>
            {userData.isExecutiveAdmin ? (
              (showAuditTable(currentTenderData) && (
                <AuditByTender currentTenderId={currentTenderId} tenderStatus={currentTenderStatus} />
              )) || (
                <div className='mt-8'>
                  {`Audit table is available only for tenders started after ${
                    ENVIRONMENT === 'production' ? '15 Jul' : '7 Feb'
                  }`}
                </div>
              )
            ) : (
              <P403 />
            )}
          </Route>
          <Route path='*' component={P404} />
        </Switch>
      )}
    </div>
  )
}
