import React from 'react'
import { useDispatch } from 'react-redux'
import { onDownloadCsvTemplate, onDownloadCategoryCsv } from '../../../utils/userUtils/EventHandlers'
import { Dropdown } from '../Dropdown'

export const CsvDropdown = () => {
  const dispatch = useDispatch()
  
  return(
    <Dropdown title='Download CSV'>
      {[
        {
          title: 'Template CSV',
          callBack: () => onDownloadCsvTemplate(dispatch),
        },
        {
          title: 'Diamonds CSV',
          callBack: () => onDownloadCategoryCsv(dispatch, 1),
        },
        {
          title: 'Jewelry CSV',
          callBack: () => onDownloadCategoryCsv(dispatch, 2),
        },
        {
          title: 'Watch CSV',
          callBack: () => onDownloadCategoryCsv(dispatch, 3),
        },
      ]}
    </Dropdown>
  )
}
