import React from 'react'

export const IconSearchFilled = () => {
  return (
    <svg
      className='svg-inline--fa-search fa-search'
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden='true'
      focusable='false'
      data-prefix='fas'
      data-icon='search'
      role='img'
      viewBox='0 0 24 24'
      data-fa-i2svg=''
    >
      <path
        d="M9.5 3C11.2239 3 12.8772 3.68482 14.0962 4.90381C15.3152 6.12279 16 7.77609 16 9.5C16 11.11 15.41 12.59 14.44 13.73L14.71 14H15.5L20.5 19L19 20.5L14 15.5V14.71L13.73 14.44C12.5504 15.4465 11.0506 15.9996 9.5 16C7.77609 16 6.12279 15.3152 4.90381 14.0962C3.68482 12.8772 3 11.2239 3 9.5C3 7.77609 3.68482 6.12279 4.90381 4.90381C6.12279 3.68482 7.77609 3 9.5 3ZM9.5 5C7 5 5 7 5 9.5C5 12 7 14 9.5 14C12 14 14 12 14 9.5C14 7 12 5 9.5 5Z" 
        fill="#4B5563"
      />
      <circle cx="13.5" cy="5.5" r="3" fill="#F87171" stroke="white" />
    </svg>
  )
}

