import React from 'react'
import { TTenderResults } from '../../../store/tenderResults/types'
import { Sort } from '../../../svg/Sort'
import { ResultsTableItem } from './ResultsTableItem'

interface ResultsTableProps {
  itemsInfo: TTenderResults[] | null
  tableType: 'purchaseHistory' | 'bidsWon' | 'bidsResults'
  showStatus: boolean
  sortByParams: string
  setSortByParams: (value: string) => void
  sortParams: string
  setSortParams: (value: string) => void
}

export const ResultsTable: React.FC<ResultsTableProps> = ({
  itemsInfo,
  showStatus,
  sortByParams,
  setSortByParams,
  sortParams,
  setSortParams,
  tableType,
}) => {
  const getSort = (sortBy: string) => {
    if (sortBy !== sortByParams || sortParams === 'DESC') {
      setSortByParams(sortBy)
      setSortParams('ASC')
    } else {
      setSortByParams(sortBy)
      setSortParams('DESC')
    }
  }

  return (
    <div className='overflow-x-auto table-shadows'>
      <table>
        <thead>
          <tr className='bg-white bg-opacity-75'>
            <th className='w-full py-3 pl-8'>
              <button className='sort-by-title font-semibold' type='button' onClick={() => getSort('title')}>
                Item Information
                <Sort />
              </button>
            </th>
            <th className='pl-1'>
              <button className='sort-by-date font-semibold' type='button' onClick={() => getSort('date')}>
                Transaction Date
                <Sort />
              </button>
            </th>
            <th className='pl-1 flex justify-center'>
              <button className='sort-by-bid font-semibold' type='button' onClick={() => getSort('bid')}>
                {tableType === 'purchaseHistory' ? 'Price Paid' : 'Bid'}
                <Sort />
              </button>
            </th>
          </tr>
        </thead>

        <tbody>
          {itemsInfo?.map((item, index) => (
            <ResultsTableItem index={index} itemInfo={item} showStatus={showStatus} key={item.item_id} />
          ))}
        </tbody>
      </table>
    </div>
  )
}
