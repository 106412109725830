import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DownloadDark } from '../../svg/DownloadDark'
import { TenderHistory } from './TenderHistory'
import { callApi } from '../../utils/callApi'
import { API_ROUTES } from '../../utils/apiRoutes'
import { SnackBar } from '../../components/ui/SnackBar'
import { Spinner } from '../../svg/Spinner'
import { AppStore } from '../../store/applicationState'
import { GetAcquisitionHistory } from '../../store/dashboard/actions'
import { DashboardSelect } from '../../components/ui/dashboardUi/DashboardSelect'
import { paginationProps, usePagination } from '../../hooks/usePagination'
import { filtersChange } from '../../utils/dashboardUtils/EventHandlers'
import { TenderTypes } from '../../types/actions'
import { getCategoryId } from '../../utils/dashboardUtils/CategoriesHelpers'

const selectOptions = [
  { value: '0', text: 'Tender Date Newest to Oldest' },
  { value: '1', text: 'Tender Date Oldest to Newest' },
  { value: '4', text: '# Buy Now High to Low' },
  { value: '5', text: '# Buy Now Low to High' },
  { value: '6', text: '# Winning Bids High to Low' },
  { value: '7', text: '# Winning Bids Low to High' },
  { value: '8', text: '# Unsuccessful Bids High to Low' },
  { value: '9', text: '# Unsuccessful Bids Low to High' },
  { value: '2', text: 'Total Spend High to Low' },
  { value: '3', text: 'Total Spend Low to High' },
]

interface activeTendersProps {
  userCategories: string[]
  userCompany: string | undefined
  activeTenders: TenderTypes
  setActiveTenders: (value: TenderTypes) => void
  activeSearch: boolean
  setActiveSearch: (value: boolean) => void
}

export const AcquisitionHistory: React.FC<activeTendersProps> = ({
  userCompany,
  userCategories,
  activeTenders,
  activeSearch,
  setActiveSearch,
  setActiveTenders,
}) => {
  const [activeFilter, setActiveFilter] = useState('0')
  const [searchQuery, setSearchQuery] = useState('')
  const { addSnackBar } = SnackBar()
  const [loading, setLoading] = useState(false)
  const [tendersLoaded, setTendersLoaded] = useState(false)

  const { dashboard } = useSelector((store: AppStore) => store)
  const dispatch = useDispatch()
  const limitAmount = 50

  const initialParams = {
    limit: limitAmount,
    offset: 0,
    search: '',
    tenderCategory: getCategoryId(activeTenders),
  }

  const [params, setParams] = useState<paginationProps>(initialParams)

  const [sortBy, setSortBy] = useState('')
  const [sort, setSort] = useState('')
  usePagination(dashboard.data.acquisitionHistory, setParams, limitAmount)

  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      offset: 0,
      limit: limitAmount,
      tenderCategory: getCategoryId(activeTenders),
      search: '',
    }))
    setSearchQuery('')
    setTendersLoaded(false)
  }, [activeTenders])

  useEffect(() => {
    dispatch(
      GetAcquisitionHistory.request({
        idCategory: params.tenderCategory,
        offset: params.offset,
        limit: params.limit,
        sort: sort || '',
        sortBy: sortBy || '',
        search: params.search || '',
        callBack: (success) => {
          if (success) {
            setTendersLoaded(true)
          }
        },
      })
    )
  }, [sortBy, sort, params.search, params.limit, params.offset, params.tenderCategory])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setParams((prev) => ({ ...prev, offset: 0, limit: limitAmount, search: searchQuery }))
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchQuery])

  const tenders = dashboard.data.acquisitionHistory

  const getTenders = useMemo(() => {
    return dashboard.data.acquisitionHistory?.length ? (
      dashboard.data.acquisitionHistory.map((tender) => (
        <TenderHistory tender={tender} key={`${tender.tender_id}-TenderHistory}`} />
      ))
    ) : (
      <div>There is no historical data for your company.</div>
    )
  }, [dashboard.data.acquisitionHistory])

  const downloadAllAcquisition = async () => {
    setLoading(true)
    let success = true
    let data = null
    try {
      data = await callApi({
        method: 'get',
        path: `${API_ROUTES.tendersDashboard}/download-all-acquisitions-xls`,
        isBlob: true,
      })
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Circa_Tender_${userCompany}_Purchases.xlsx`)
      document.body.appendChild(link)
      link.click()
    } catch (e: any) {
      success = false
      data = JSON.parse(await e?.data.text()) || e
    } finally {
      if (!success) addSnackBar(data?.message || '', success)
      setLoading(false)
    }
  }

  const tabsChange = (tabType: TenderTypes) => {
    setActiveTenders(tabType)
    setActiveSearch(false)
  }

  return (
    <div className='my-acquisition-history items'>
      <div className='flex flex-col mobile:flex-row justify-between items-center mb-6'>
        <div className='flex items-center space-x-3'>
          <div className='font-circa text-black text-lg font-semibold mb-5 mobile:mb-0'>My Acquisition History</div>
        </div>

        {userCategories.length > 1 ? (
          <div className='tabs flex justify-start space-x-12'>
            {userCategories.includes('diamond') ? (
              <button
                type='button'
                className={`diamond-tab ${activeTenders === 'diamond' ? 'tab active' : 'tab'}`}
                onClick={() => tabsChange('diamond')}
              >
                Diamond Tenders
              </button>
            ) : null}

            {userCategories.includes('watches') ? (
              <button
                type='button'
                className={`watch-tab ${activeTenders === 'watches' ? 'tab active' : 'tab'}`}
                onClick={() => tabsChange('watches')}
              >
                Watch Tenders
              </button>
            ) : null}

            {userCategories.includes('jewelry') ? (
              <button
                type='button'
                className={`jewelry-tab ${activeTenders === 'jewelry' ? 'tab active' : 'tab'}`}
                onClick={() => tabsChange('jewelry')}
              >
                Jewelry Tenders
              </button>
            ) : null}
          </div>
        ) : (
          ''
        )}
      </div>

      {(activeTenders === 'diamond' && tenders?.filter((tender) => tender.category_id === 1).length) ||
      (activeTenders === 'jewelry' && tenders?.filter((tender) => tender.category_id === 2).length) ||
      (activeTenders === 'watches' && tenders?.filter((tender) => tender.category_id === 3).length) ||
      activeSearch ? (
        <div className='lg:space-x-6 flex lg:items-center justify-between flex-col lg:flex-row bg-bluegray-50 p-4 mb-10'>
          <input
            type='text'
            placeholder='Search...'
            className='search p-2 py-1 text-sm border border-bluegray-200 ring-0 focus:ring-0 focus:outline-none px-2 xrounded-md lg:w-48'
            value={searchQuery}
            onChange={(event) => {
              setSearchQuery(event.target.value)
              setActiveSearch(true)
            }}
          />

          <div className='rounded-md select-none focus:outline-none flex items-center lg:m-0 my-5'>
            <div className='flex space-x-4 w-full'>
              <div className='text-xs text-bluegray-500 uppercase flex items-center'>sort</div>
              <DashboardSelect
                options={selectOptions}
                activeFilter={activeFilter}
                callback={(value) => {
                  filtersChange(value, setSort, setSortBy, setActiveFilter)
                  setParams((prev) => ({ ...prev, offset: 0, limit: limitAmount }))
                }}
              />
            </div>
          </div>
          {activeTenders === 'diamond' ? (
            <button
              type='button'
              className='download-xlsx flex relative flex-row rounded-md text-xs select-none box-border active:border-bluegray-300 active:border-1 focus:outline-none items-center p-1 border border-bluegray-100 bg-white text-bluegray-500'
              onClick={downloadAllAcquisition}
              disabled={
                loading ||
                tenders
                  ?.filter((tender) => tender.category_id === 1)
                  .every((tender) => tender?.tender_status === 'draft')
              }
            >
              {loading ? (
                <div className='absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-10 bg-opacity-10 bg-gray-900'>
                  <Spinner style={{ color: 'black', width: '10px', height: '10px' }} />
                </div>
              ) : null}

              <span className='mr-3'>
                <DownloadDark />
              </span>
              <span>Download all acquisitions (XLSX)</span>
            </button>
          ) : (
            ''
          )}
        </div>
      ) : null}

      {!tendersLoaded ? null : <span className='list'>{getTenders}</span>}
    </div>
  )
}
