export interface TAdminUser {
  company_id: number,
  company_title: string
  disabled: boolean
  email: string
  first_name: string
  full_access_categories: number[]
  is_mobile: boolean
  last_name: string
  last_signed_in: string
  message_method_id: number
  phone: string
  role_id: number
  terms_confirmed: boolean
  terms_confirmed_date: string
  user_id: number
  view_only_categories: number[]
}

export interface TAdminUsers {
  company_id: number
  company_title: string
  email: string
  first_name: string
  full_access_categories: number[]
  last_name: string
  last_signed_in: string
  message_method_id: number
  message_title: string
  role_id: number
  user_id: number
  view_only_categories: number[]
  with_bid?: boolean
  in_recommendation?: boolean
}

export interface TAdminUsersTokens {
  access_token: string
}

export interface TAdminUsersMessage {
  message: string
}

export interface TAdminUsersData {
  total_without_recommended_and_bid?: number
  users: TAdminUsers[]
}

export interface TAdminUsersState {
  loading: boolean
  data: TAdminUsersData | null
  userForEditData: TAdminUser | null
  errors?: string | undefined
}

export enum ActionTypes {
  GET_All_USERS_R = '@@admin/GET_All_USERS_R',
  GET_All_USERS_S = '@@admin/GET_All_USERS_S',
  GET_All_USERS_WITH_0_OFFSET_S = '@@admin/GET_All_USERS_WITH_0_OFFSET_S',
  GET_All_USERS_E = '@@admin/GET_All_USERS_E',

  GET_USER_BY_ID_R = '@@admin/GET_USER_BY_ID_R',
  GET_USER_BY_ID_S = '@@admin/GET_USER_BY_ID_S',
  GET_USER_BY_ID_E = '@@admin/GET_USER_BY_ID_E',

  CREATE_NEW_USER_R = '@@admin/CREATE_NEW_USER_R',
  CREATE_NEW_USER_S = '@@admin/CREATE_NEW_USER_S',
  CREATE_NEW_USER_E = '@@admin/CREATE_NEW_USER_E',

  UPDATE_USER_INFO_R = '@@admin/UPDATE_USER_INFO_R',
  UPDATE_USER_INFO_S = '@@admin/UPDATE_USER_INFO_S',
  UPDATE_USER_INFO_E = '@@admin/UPDATE_USER_INFO_E',

  GET_USERS_CSV_TEMPLATE_R = '@@admin/GET_USERS_CSV_TEMPLATE_R',
  GET_USERS_CSV_TEMPLATE_S = '@@admin/GET_USERS_CSV_TEMPLATE_S',
  GET_USERS_CSV_TEMPLATE_E = '@@admin/GET_USERS_CSV_TEMPLATE_E',

  GET_CATEGORY_CSV_R = '@@admin/GET_CATEGORY_CSV_R',
  GET_CATEGORY_CSV_S = '@@admin/GET_CATEGORY_CSV_S',
  GET_CATEGORY_CSV_E = '@@admin/GET_CATEGORY_CSV_E',

  POST_USERS_CSV_TEMPLATE_R = '@@admin/POST_USERS_CSV_TEMPLATE_R',
  POST_USERS_CSV_TEMPLATE_S = '@@admin/POST_USERS_CSV_TEMPLATE_S',
  POST_USERS_CSV_TEMPLATE_E = '@@admin/POST_USERS_CSV_TEMPLATE_E',

  GET_TOKENS_FOR_VIEW_LIKE_A_USER_R = '@@admin/GET_TOKENS_FOR_VIEW_LIKE_A_USER_R',
  GET_TOKENS_FOR_VIEW_LIKE_A_USER_S = '@@admin/GET_TOKENS_FOR_VIEW_LIKE_A_USER_S',
  GET_TOKENS_FOR_VIEW_LIKE_A_USER_E = '@@admin/GET_TOKENS_FOR_VIEW_LIKE_A_USER_E',

  RESEND_INVITE_R = '@@admin/RESEND_INVITE_R',
  RESEND_INVITE_S = '@@admin/RESEND_INVITE_S',
  RESEND_INVITE_E = '@@admin/RESEND_INVITE_E',
}
