import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { WsChangeTenderItems } from '../../store/gallery/actions'
import { WsChangeCurrentBids } from '../../store/gallery/tenderSummary/actions'
import { ROUTES } from '../../utils/routes'
import { SocketActionOccurred, SocketActionOccurredReset } from '../../store/admin/tenders/actions'
import { AppStore } from '../../store/applicationState'

export interface tenderSocketType {
  item_id: number
  percent_of_rap: number
  price: number
  price_per_carat: number
  type_of_event: number
}

export const useTender = (socket: WebSocket | null) => {
  const location = useLocation()
  const dispatch = useDispatch()

  const [currentTenderID, setCurrentTenderID] = useState<number>(0)
  const [listenAllActions, setListenAllActions] = useState<boolean>(false)

  const { tenders } = useSelector((store: AppStore) => store)

  const tenderListener = useCallback((data: tenderSocketType) => {
    dispatch(WsChangeTenderItems(data))
    dispatch(WsChangeCurrentBids(data))
    dispatch(SocketActionOccurred())
  }, [])

  useEffect(() => {
    const tenderId: number = Number(window.location.pathname.split('/').find((elem) => Number(elem))) || 0

    if (location.pathname.search(ROUTES.tenders) !== -1 && tenderId) {
      setCurrentTenderID((prev) => (prev !== tenderId ? tenderId : prev))
      setListenAllActions((prev) => (prev ? false : prev))
    } else if (location.pathname.search(ROUTES.adminPageTender) !== -1 && tenderId) {
      setCurrentTenderID((prev) => (prev !== tenderId ? tenderId : prev))
      setListenAllActions((prev) => (!prev ? true : prev))
    } else {
      setCurrentTenderID((prev) => (prev !== 0 ? 0 : prev))
      setListenAllActions((prev) => (prev ? false : prev))
    }
  }, [location.pathname])

  useEffect(() => {
    if (socket) {
      if (listenAllActions) socket.send(JSON.stringify({ tender_id: currentTenderID, listen_all_actions: true }))
      else socket.send(JSON.stringify({ tender_id: currentTenderID }))
    }
  }, [socket, currentTenderID, listenAllActions])

  useEffect(() => {
    if (tenders.socketActionAmount) dispatch(SocketActionOccurredReset())
  }, [location.pathname])

  return {
    tenderListener,
  }
}
