import React from 'react'

interface TimeInputProps {
  time: string
  setTime: (value: string) => void
}

export const TimeInput: React.FC<TimeInputProps> = ({ time, setTime}) => {
  const [hours, setHours] = React.useState('00')
  const [min, setMin] = React.useState('00')
  const [hour12, setHour12] = React.useState('AM')
  const regex = /^\d+$/

  React.useEffect(() => {
    if (time.includes('PM')) setHour12('PM')
    else setHour12('AM')

    const t = time.split(' ')[0].split(':')
    setHours(t[0])
    setMin(t[1])
  }, [])

  React.useEffect(() => {
    setTime(`${hours}:${min}:00 ${hour12}`)
  }, [hours, min, hour12])

  return (
    <div className="flex space-x-1">
      <label htmlFor="hours" className='flex'>
        <input
          type="text"
          name='hours'
          value={hours}
          onChange={(e) => {
            const { value } = e.target
            if (!value.match(regex)
            // || value.length < 2
            // || (+value.slice(0, 2) !== 10 && +value[value.length - 1] > 2)
            ) {
              e.preventDefault()
            }
            else if (value.length < 2) setHours(`0${value}`)
            // else if (+value[value.length - 2] > 1) setHours(`1${value.slice(value.length - 1)}`)
            else if (+value > 12 && !['0', '1'].includes(value[value.length - 1])) setHours('12')
            else if (+value > 12 && ['0', '1'].includes(value[value.length - 1])) setHours(`0${value[value.length - 1]}`)
            else if (value.length > 2) setHours(value.slice(1))
            else setHours(value)
          }}
          className='w-5 rounded-lg outline-none text-center'
        />
        <div className="">:</div>
        <input
          type="text"
          name='min'
          value={min}
          onChange={(e) => {const { value } = e.target
          if (!value.match(regex)
          // || value.length < 2
          // || (+value.slice(0, 2) !== 10 && +value[value.length - 1] > 2)
          ) {
            e.preventDefault()
          }
          else if (value.length < 2) setMin(`0${value}`)
          else if (+value[value.length - 2] > 5) setMin(`5${value.slice(value.length - 1)}`)
          else if (value.length > 2) setMin(value.slice(1))
          else setMin(value)
          }}
          className='w-5 rounded-lg outline-none text-center'
        />
      </label>

      <select
        name="hour12"
        id="hour12"
        className='hour12 outline-none'
        value={hour12}
        onChange={(e) => setHour12(e.target.value)}
      >
        <option value="AM">AM</option>
        <option value="PM">PM</option>
      </select>
    </div>
  )
}
