import React, { CSSProperties } from 'react'
import { Gem } from '../../svg/Gem'
import { Watch } from '../../svg/Watch'
import { UsersCrown } from '../../svg/UsersCrown'
import { ViewLikeAUser } from '../../svg/ViewLikeAUser'
import { Users } from '../../svg/Users'
import { DiamondSolid } from '../../svg/DiamondSolid'
import { WatchSolid } from '../../svg/WatchSolid'
import { Necklace } from '../../svg/Necklace'

export const getIconForFullAccessCategories = (id: number) => {
  const stylesForContainer: CSSProperties = {
    display: 'inline-flex',
    position: 'relative',
    justifyContent: 'center',
    margin: '0 1px',
    alignItems: 'center',
    color: 'rgb(71, 85, 105)',
  }
  const stylesForText: CSSProperties = {
    position: 'absolute',
    bottom: '0',
    left: '52%',
    fontSize: '10px',
    fontWeight: 'bolder',
    background: '#fff',
    lineHeight: '8px',
    transform: 'translate(-50%, 30%)',
    // color: 'rgb(156, 163, 175)',
  }
  switch (id) {
    case 1:
      return (
        <div style={stylesForContainer} key={`IconForFullAccessCategories${id}`}>
          <Gem />
        </div>
      )
    case 2:
      return (
        <div style={stylesForContainer} key={`IconForFullAccessCategories${id}`}>
          <Necklace />
        </div>
      )
    case 3:
      return (
        <div style={stylesForContainer} key={`IconForFullAccessCategories${id}`}>
          <Watch />
        </div>
      )
    
    // VIP
    case 4:
      return (
        <div style={{ ...stylesForContainer }} key={`IconForFullAccessCategories${id}`}>
          <Gem />
          <p style={stylesForText}>VIP</p>
        </div>
      )
    case 5:
      return (
        <div style={{ ...stylesForContainer }} key={`IconForFullAccessCategories${id}`}>
          <Necklace />
          <p style={stylesForText}>VIP</p>
        </div>
      )
    case 6:
      return (
        <div style={{ ...stylesForContainer }} key={`IconForFullAccessCategories${id}`}>
          <Watch />
          <p style={stylesForText}>VIP</p>
        </div>
      )
    default:
      return <></>
  }
}

export const getIconForAdmin = (id: number) => {
  const stylesForContainer: CSSProperties = {
    display: 'inline-flex',
    position: 'relative',
    justifyContent: 'center',
    margin: '0 1px',
    alignItems: 'center',
  }
  
  if (id === 1)
    return (
      <div style={stylesForContainer} key={`IconForAdmin${id}`}>
        <UsersCrown />
      </div>
    )
  if (id === 2)
    return (
      <div style={stylesForContainer} key={`IconForAdmin${id}`}>
        <Users />
      </div>
    )
  
  return null
}

export const getIconsForUsers = (userRole: number, fullAccess: number[], viewOnly: number[]) => {
  if (getIconForAdmin(userRole) && fullAccess.length) return getIconForAdmin(userRole)
  if (!fullAccess.length && viewOnly.length) return <ViewLikeAUser noHover className='-ml-1' />
  if (fullAccess.length) return <>{fullAccess.map(id => getIconForFullAccessCategories(id))}</>
  
  return <></>
}

export const getSolidForCategories = (id: number) => {
  switch (id) {
    case 1:
    case 4:
      return <DiamondSolid />
    case 2:
    case 5:
      return <Necklace />
    case 3:
    case 6:
      return <WatchSolid />
    default:
      return <></>
  }
}