/* eslint-disable no-console */
import axios from 'axios'
import { trackPromise } from 'react-promise-tracker'
import * as Sentry from '@sentry/react'
import jwt_decode from 'jwt-decode'
import { API_ROUTES } from './apiRoutes'
import { checkToken, getParsedAccessToken, refresh, refreshViewAsCustomerToken, ReturnType } from './checkToken'
import { API_PATH, TOKEN } from './consts'
import { deleteAuthCookie, getCookie } from './cookiesUtils'

export async function callApi(obj: {
  method: 'get' | 'post' | 'put' | 'delete'
  path: string
  token?: string
  isBlob?: boolean
  refreshToken?: string
  data?: Object | null
  isFormData?: boolean
  isGzip?: boolean
}): Promise<any> {
  const { method, path, data, refreshToken, token, isBlob, isGzip } = obj
  const subPath = '/api/v1'
  const mustCheckToken = !(
    path === API_ROUTES.signIn ||
    path === API_ROUTES.forgotPass ||
    path === API_ROUTES.setNewPass ||
    path.includes(API_ROUTES.itemVideo) ||
    path.includes(API_ROUTES.signInViaToken)
  )

  let userToken: ReturnType = {
    usrId: '',
    success: true,
    token: '',
  }
  if (mustCheckToken && !refreshToken) {
    userToken = await checkToken(path.includes('/admin/recommendations'))
  }

  if (!userToken.success) window.location.href = '/login#sessionisexpired'

  const request = async (access?: string): Promise<any> => {
    const tryRefreshToken = async () => {
      try {
        const accessTokenForViewAsCustomer = sessionStorage.getItem(TOKEN.access)
        let resp
        if (accessTokenForViewAsCustomer) {
          const parsedToken = getParsedAccessToken(accessTokenForViewAsCustomer)
          resp = await refreshViewAsCustomerToken(parsedToken)
        } else {
          resp = await refresh()
        }
        // if (resp.success) return request(resp.token)
        if (resp.success) return window.location.reload()
        return null
      } catch (e: any) {
        return e
      }
    }

    let responseType = 'json'
    if (isBlob) responseType = 'blob'
    if (isGzip) responseType = 'arraybuffer'

    try {
      const res = await axios.request({
        method,
        // @ts-ignore
        responseType,
        headers: {
          Authorization: `Bearer ${access || userToken.token || refreshToken}`,
          'Content-Type': 'application/json',
          token: `${token}`,
          decompress: false,
        },
        url: API_PATH + subPath + path,
        data: data || null,
      })
      return res.data
    } catch (e: any) {
      if (e?.response?.status === 401 || e?.response?.data?.message === 'invalid access token') {
        if (path.includes(API_ROUTES.signInViaToken) && e?.response?.status === 401) {
          localStorage.clear()
          sessionStorage.clear()
          deleteAuthCookie()
          const isWorthy = window.location.href.includes('/worthy')
          window.location.href = isWorthy ? "/worthy/login" : ''
        }
        if (!refreshToken && !access) return tryRefreshToken()
        const decodeToken: { exp?: number; created_at?: number } = jwt_decode(getCookie(TOKEN.refresh) || '')
        Sentry.captureMessage('Logout', {
          level: 'info' as Sentry.Severity,
          contexts: {
            info: {
              event: 'on call api',
              token: access || userToken.token || 'undefined',
              refresh_token: refreshToken || 'undefined',
              response_status: e?.response?.status || e?.status,
              response_data_message: e?.response?.data?.message || e?.data?.message || e?.message,
              refresh_token_expire_at: decodeToken?.exp ? new Date(decodeToken.exp * 1000) : 'no expires_at in token',
              error: JSON.stringify(e),
            },
          },
        })

        localStorage.clear()
        deleteAuthCookie()
        sessionStorage.clear()
        const isWorthy = window.location.href.includes('/worthy')
        window.location.href = isWorthy ? '/worthy/login#endofsession' : '/login#endofsession'
        return null
      }
      throw e?.response || e
    }
  }

  return trackPromise(request())
}
