import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
// import { PersistGate } from 'redux-persist/integration/react'
import { App } from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import store from './store'
import { ENVIRONMENT } from './utils/consts'

if (ENVIRONMENT && ENVIRONMENT !== 'local' && ENVIRONMENT !== 'test')
  Sentry.init({
    dsn: 'https://ec8bbe1f9ecf4c1c8b1484fba1a9ef98@o248338.ingest.sentry.io/6189997',
    integrations: [new BrowserTracing()],
    environment: ENVIRONMENT,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
