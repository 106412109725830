/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { checkLength, checkLowerCase, checkNumbers, checkSymbols, checkUpperCase } from '../../utils/helpers'
import { useCheckLoc } from '../../hooks/useCheckLoc'

interface Props {
  children: React.ReactNode
}

export const PasswordCheckPopover: React.FC<Props> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null)
  const { top } = useCheckLoc(ref)
  const mobileModal = window.innerWidth < 600

  const [focused, setFocused] = useState(false)

  const [symbols, setSymbols] = useState(false)
  const [upperCase, setUpperCase] = useState(false)
  const [lowerCase, setLowerCase] = useState(false)
  const [numbers, setNumbers] = useState(false)
  const [length, setLength] = useState(false)

  const [inputHeight, setInputHeight] = useState<number>(0)
  const [inputWidth, setInputWidth] = useState<number>(0)

  const onInputValueChange = (ev: any) => {
    setSymbols(checkSymbols(ev.target.value))
    setUpperCase(checkUpperCase(ev.target.value))
    setLowerCase(checkLowerCase(ev.target.value))
    setNumbers(checkNumbers(ev.target.value))
    setLength(checkLength(ev.target.value))
  }

  const onInputFocus = () => setFocused(true)
  const onInputBlur = () => setFocused(false)

  const getInput = (item: HTMLElement): HTMLInputElement | undefined => {
    let input: HTMLInputElement | undefined
    item.childNodes.forEach((elem: any) => {
      if (elem.tagName?.toLowerCase() === 'input') return (input = elem)
      input = getInput(elem) || input
    })
    return input
  }

  const isValidAtLeast3 = useMemo(() => {
    // eslint-disable-next-line no-plusplus, no-param-reassign
    return [lowerCase, upperCase, numbers, symbols].reduce((count, value) => (value ? ++count : count), 0) >= 3
  }, [lowerCase, upperCase, numbers, symbols])

  const child = useMemo(
    () => (
      <>
        <ul className='text-base'>
          <li className={`ml-3 ${length ? 'list-style-success' : 'list-style-error'}`}>
            At least 8 characters in length
          </li>
          <li className={`ml-3 ${isValidAtLeast3 ? 'list-style-success' : 'list-style-error'}`}>
            Contain at least 3 of the following 4 types of characters:
            <ul>
              <li className={`ml-3 ${lowerCase ? 'list-style-success' : 'list-style-error'}`}>
                Lower case letters (a-z)
              </li>
              <li className={`ml-3 ${upperCase ? 'list-style-success' : 'list-style-error'}`}>
                Upper case letters (A-Z)
              </li>
              <li className={`ml-3 ${numbers ? 'list-style-success' : 'list-style-error'}`}>Numbers (i.e. 0-9)</li>
              <li className={`ml-3 ${symbols ? 'list-style-success' : 'list-style-error'}`}>
                Special characters (e.g. !@#$%^&amp;*)
              </li>
            </ul>
          </li>
        </ul>
      </>
    ),
    [length, isValidAtLeast3, lowerCase, upperCase, numbers, symbols]
  )

  useEffect(() => {
    const init = () => {
      if (ref.current) {
        try {
          const input = getInput(ref.current)
          setInputHeight(input!.clientHeight)
          setInputWidth(input!.clientWidth)
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Password popover error->', e)
        }
      }
    }
    setTimeout(() => init(), 1000)

    window.addEventListener('resize', init)
    window.addEventListener('scroll', init)
    return () => {
      window.removeEventListener('resize', init)
      window.removeEventListener('scroll', init)
    }
  }, [])

  useEffect(() => {
    const input = getInput(ref.current!)
    input?.addEventListener('input', onInputValueChange)
    input?.addEventListener('focus', onInputFocus)
    input?.addEventListener('blur', onInputBlur)

    return () => {
      input?.removeEventListener('input', onInputValueChange)
      input?.removeEventListener('focus', onInputFocus)
      input?.removeEventListener('blur', onInputBlur)
    }
  }, [])

  return (
    <div ref={ref} className='relative'>
      {children}
      {!mobileModal && focused && inputHeight && inputWidth ? (
        <div
          className='absolute z-[99999] h-max bg-white rounded-md px-5 py-4 drop-shadow-xl min-h-[200px] flex items-center'
          style={{ top: top ? -205 : inputHeight + 5 }}
        >
          {child}
        </div>
      ) : null}
      {mobileModal && (
        <div
          style={{ maxHeight: focused ? 1000 : 0 }}
          className='transition-all delay-75 duration-500 overflow-hidden bg-white rounded-md drop-shadow-xl'
        >
          <div className='px-5 py-4'>{child}</div>
        </div>
      )}
    </div>
  )
}
