/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { IconChecked } from '../../svg/IconChecked'
import { IconNoChecked } from '../../svg/IconNoChecked'
import { PurchasePopup } from '../../components/ui/galleryUi/PurchasePopup'
import { ItemCardStatus } from '../../components/ui/galleryUi/itemLabels'
import { TGalleryItemInfo } from '../../store/gallery/types'
import { useAddItemPopup } from '../../hooks/useAddItemPopup'
import { IconLike } from '../../svg/IconLike'
import { addNewParams } from '../../utils/helpers'
import { getUniqueId } from '../../utils/FileUtils'
import { addToFavoritesAction, addToRecommendedAction } from '../../utils/galleryUtils/itemsActions'
import { GetItemInfo } from '../../store/gallery/actions'
import { Necklace } from '../../svg/Necklace'
import { API_ROUTES } from '../../utils/apiRoutes'
import { IconStar } from '../../svg/IconStar'
import { recMode } from '../../utils/consts'
import { AppStore } from '../../store/applicationState'

interface JewelryCardProps {
  isCatalog: boolean
  tenderId: number
  item: TGalleryItemInfo
}

const ImageAltWrapperS = styled.div`
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
`

export const JewelryCard: React.FC<JewelryCardProps> = ({ item, tenderId, isCatalog }) => {
  const dispatch = useDispatch()
  const { userData, gallery } = useSelector((store: AppStore) => store)

  const [purchasePopupActive, setPurchasePopupActive] = useState(false)
  const [imgError, setImgError] = useState(false)

  const [prewImg, setprewImg] = useState(item.product_gallery.images[0] || item.product_gallery.part_of_media_file[0])

  const itemStatus = item.bid_status_id === 0 && !item.is_sold ? 'open' : ''

  const { add } = useAddItemPopup()

  const openItemPopup = () => {
    dispatch(
      GetItemInfo.request({
        itemId: item.item_id,
        callBack: (success, data) => {
          if (success) {
            const finalized = false
            add({ item: data, isCatalog, finalized })
            addNewParams('item', `${item.item_id}`)
          }
        },
      })
    )
  }

  return (
    <div className={`px-1 ${item.product_gallery.images.length ? '' : 'mobile:w-auto w-full'}`}>
      <div className='item-card p-3 border border-bluegray-100 bg-white lg:w-64 mobile:w-60 w-12/12 mobile:mx-0 mx-5 mb-8 shadow-sm space-y-4'>
        <div className='item-image relative'>
          <div className='image-holder relative flex'>
            <Link
              to={`${API_ROUTES.tenders}/${tenderId}?item=${item.item_id}`}
              onClick={(e) => {
                if (e.nativeEvent.which === 1) {
                  e.preventDefault()
                  openItemPopup()
                }
              }}
              className='w-full'
            >
              {(item.product_gallery.images.length || item.product_gallery.part_of_media_file.length) && !imgError ? (
                <img
                  src={prewImg}
                  alt='Jewelry'
                  aria-hidden='true'
                  onError={() => setImgError(true)}
                  className={`border border-bluegray-200 ${itemStatus === 'open' ? '' : 'opacity-25'}`}
                />
              ) : (
                <ImageAltWrapperS className='text-bluegray-400 flex items-center justify-center w-full text-7xl border border-bluegray-200'>
                  <Necklace />
                </ImageAltWrapperS>
              )}
            </Link>
            {recMode && (
              <div className='absolute left-1 right-1 top-1 bg-gray-300 opacity-70 p-1'>
                Owner: {item.product.shipping_owner}
                <br />
                Bid/s: {item.product.count_of_bids || 0}
              </div>
            )}
          </div>
        </div>

        <div className='images-slider grid-cols-4 grid gap-3 mobile:h-12'>
          {item.product_gallery.images.length > 1 &&
            item.product_gallery.images.map((image, i) => {
              if (i <= 3) {
                return (
                  <button
                    className='col border border-bluegray-200 hover:border-lightblue-600'
                    type='button'
                    key={getUniqueId()}
                    onClick={() => setprewImg(image)}
                  >
                    <img src={image} alt='Jewelry' className={` ${itemStatus === 'open' ? '' : 'opacity-25'}`} />
                  </button>
                )
              }
              return ''
            })}
        </div>
        <div className='item-info flex flex-col justify-between mt-4'>
          <div className='flex justify-between flex-col space-y-3 flex-grow'>
            <div className={`item-text text-bluegray-800 ${itemStatus === 'open' ? '' : 'opacity-25'}`}>
              <div className='font-circa text-base text-black mb-2 overflow-hidden md:text-ellipsis h-12'>
                <Link
                  to={`${API_ROUTES.tenders}/${tenderId}?item=${item.item_id}`}
                  onClick={(e) => {
                    if (e.nativeEvent.which === 1) {
                      e.preventDefault()
                      openItemPopup()
                    }
                  }}
                  className='title flex text-left'
                >
                  {item.product.title}
                </Link>
              </div>
              <div className='price text-base flex items-center justify-between text-bluegray-500 mb-5 min-h-[16px] leading-none'>
                <div className='metal-type'>{item.product.product_details.metal_type}</div>
                {item.product.product_details.year ? (
                  <div className='flex'>
                    <div className='text-bluegray-400 mr-1'>Year:</div>
                    <div className='year'>{item.product.product_details.year}</div>
                  </div>
                ) : null}
              </div>
              <div className='text-bluegray-800 space-y-3 mb-2 leading-none'>
                <div className='flex justify-between items-center min-h-[14px]'>
                  {item.product.product_details.weight_grams ? (
                    <>
                      <div className='font-semibold mr-1'>Item Weight</div>
                      <div className=' border-dotted border-b border-bluegray-300 mx-1 flex-grow' />
                      <div className='weight-number'>{`${item.product.product_details.weight_grams}g`}</div>
                    </>
                  ) : null}
                </div>

                <div className='flex justify-between items-center min-h-[14px]'>
                  {item.product.product_details.condition ? (
                    <>
                      <div className='font-semibold mr-1'>Cond.</div>
                      <div className=' border-dotted border-b border-bluegray-300 mx-1 flex-grow' />
                      <div className='condition'>{item.product.product_details.condition}</div>
                    </>
                  ) : null}
                </div>

                <div className='flex space-x-3 items-center mt-2 justify-between'>
                  <div className='flex space-x-4'>
                    <div className='box font-semibold'>
                      {item.product.product_details.box === 'Yes' ? (
                        <span className='icon-checked text-green-300 mr-1 text-xxs'>
                          <IconChecked />
                        </span>
                      ) : (
                        <span className='icon-unchecked text-red-400 mr-1 text-xxs'>
                          <IconNoChecked />
                        </span>
                      )}
                      Box
                    </div>
                    <div className='font-semibold papers'>
                      {item.product.product_details.papers === 'Yes' ? (
                        <span className='icon-checked text-green-300 mr-1 text-xxs'>
                          <IconChecked />
                        </span>
                      ) : (
                        <span className='icon-unchecked text-red-400 mr-1 text-xxs'>
                          <IconNoChecked />
                        </span>
                      )}
                      Papers
                    </div>
                  </div>
                  <div className='flex flex-row gap-2'>
                    {((gallery.data.atLeastOneRecommended && item.in_recommendations) || recMode) && (
                      <button
                        type='button'
                        disabled={!recMode}
                        onClick={() => {
                          addToRecommendedAction(item.in_recommendations, userData.companyId!, item.item_id, dispatch)
                        }}
                        className='recommendations text-yellow-500 text-lg disabled:opacity-100'
                      >
                        <IconStar isActive={item.in_recommendations} />
                      </button>
                    )}
                    <button
                      type='button'
                      onClick={() => addToFavoritesAction(item.in_favorites, tenderId, item.item_id, dispatch)}
                      className='favorites text-red-500 text-lg'
                    >
                      <IconLike isActive={item.in_favorites} />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {!isCatalog && (
              <ItemCardStatus
                item={item}
                tenderId={tenderId}
                setPurchasePopupActive={setPurchasePopupActive}
                openItemPopup={() => openItemPopup()}
              />
            )}
          </div>
        </div>
      </div>
      {purchasePopupActive && (
        <PurchasePopup
          setPurchasePopupActive={setPurchasePopupActive}
          purchasePopupActive={purchasePopupActive}
          item={item}
          tenderId={tenderId}
        />
      )}
    </div>
  )
}
