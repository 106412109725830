import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAddItemPopup } from '../../../hooks/useAddItemPopup'
import { AppStore } from '../../../store/applicationState'
import { GetItemInfo } from '../../../store/gallery/actions'
import { TCurrentBids } from '../../../store/gallery/tenderSummary/types'
import { convertToDollars } from '../../../utils/convertToDollars'
import { getCategoryIcon } from '../../../utils/dashboardUtils/CategoriesHelpers'
import { addNewParams } from '../../../utils/helpers'

interface MobileMenuProps {
  itemSummary: TCurrentBids
}

export const ItemSummaryCard: React.FC<MobileMenuProps> = ({ itemSummary }) =>  {
  const [imgError, setImgError] = useState(false)
  const { add } = useAddItemPopup()

  const { gallery } = useSelector((store: AppStore) => store)
  const dispatch = useDispatch()

  const openItemPopup = () => {
    if (gallery.data.tenderItems) {
      const item = gallery.data.tenderItems.find(item2 => item2.item_id === itemSummary.item_id)
      const isCatalog = false
      if (item) {
        const finalized = false
        
        dispatch(GetItemInfo.request({
          itemId: itemSummary.item_id,
          callBack: (success, data) => {
            if (success) {
              add({ item: data, isCatalog, finalized })
              addNewParams('item', `${itemSummary.item_id}`)
            }
          },
        }))
      }
    }
  }

  const altName = () => {
    switch (itemSummary.type_of_product) {
      case 1:
        return 'Diamond'
    
      case 2:
        return 'Jewelry'

      case 3:
        return 'Watches'

      default:
        return 'Image'
    }
  }

  return (
    <div className='item summary- font-circad text-xsds pb-4 border-b'>
      <div className="flex space-x-4 h-24 ">
        <div className="img w-24 space-y-2">
          {itemSummary.img && !imgError
          ? (
            <img
              src={itemSummary.img}
              alt={altName()}
              onError={() => setImgError(true)}
              className='border'
            />
          ) : (
            <div
              className={`flex items-center py-1 justify-center w-full text-5xl border border-bluegray-200 ${itemSummary.type_of_product === 1 ? 'text-white bg-bluegray-300' : 'text-bluegray-400'}`}
            >
              {getCategoryIcon(itemSummary.type_of_product)}
            </div>
          )}
        </div>
        
        <div className="text-sm flex flex-col justify-between flex-grow w-3/4">
          <div className="title font text-lg text-bluegray-900 font-d">
            {itemSummary.title}
          </div>

          <div className="status-price flex items-center text-xs leading-none space-x-6">
            {itemSummary.is_buy_now
            ? (
              <div className="status text-green-900  bg-green-50 border-green-300 border p-1 h-6 leading-none">
                Purchased
              </div>
            ) : (
              <div className="status text-lightblue-900  bg-lightblue-50 border-lightblue-300 border p-1 h-6 leading-none">
                Bid Placed
              </div>
            )}

            {itemSummary.type_of_product === 1
            ? (
              <div className="price font-mono font-semibold text-bluegray-700 text-sm">
                {`${convertToDollars((itemSummary.bid / itemSummary.weight))} /ct`}
                <div className="text-xxs text-bluegray-400 font-light mt-1">
                  {`${convertToDollars(itemSummary.bid)} total`}
                </div>
              </div>
            ) : (
              <div className="price font-mono font-semibold text-bluegray-700 text-sm">
                {`${convertToDollars(itemSummary.bid)}`}
              </div>
            )}

            <button
              type='button'
              className='action text-xs'
              onClick={() => openItemPopup()}
            >
              {`View${!itemSummary.is_buy_now ? '/Edit' : ''}`}
            </button>
          </div>

          <div className="customer-email text-bluegray-500 ">
            {`${itemSummary.email_of_customer}`}
          </div>
        </div>
      </div>
    </div>
  )
}
