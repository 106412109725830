import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { callApi } from '../../utils/callApi'
import { API_ROUTES } from '../../utils/apiRoutes'
import * as Actions from './actions'
import { ActionTypes, TTenderResults } from './types'

function* getBidsResultsWorker(action: ReturnType<typeof Actions.GetBidsResult.request>) {
  const { callBack, idTender, sortBy, sort } = action.payload as Actions.TypeGetBidsResultR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.tenders}/${idTender}/tender-results/bid-results?SortBy=${sortBy || ''}&Sort=${sort || ''}`,
    })) as TTenderResults[]
    
    yield put(Actions.GetBidsResult.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetBidsResult.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getBidsWonWorker(action: ReturnType<typeof Actions.GetBidsWon.request>) {
  const { callBack, idTender, sortBy, sort } = action.payload as Actions.TypeGetBidsWonR
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.tenders}/${idTender}/tender-results/bids-won?SortBy=${sortBy || ''}&Sort=${sort || ''}`,
    })) as TTenderResults[]
    
    yield put(Actions.GetBidsWon.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetBidsWon.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getPurchaseHistoryWorker(action: ReturnType<typeof Actions.GetPurchaseHistory.request>) {
  const { callBack, idTender, sortBy, sort } = action.payload as Actions.TypeGetPurchaseHistoryR
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.tenders}/${idTender}/tender-results/purchase-history?SortBy=${sortBy || ''}&Sort=${sort || ''}`,
    })) as TTenderResults[]
    
    yield put(Actions.GetPurchaseHistory.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetPurchaseHistory.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getTenderInfoWorker(action: ReturnType<typeof Actions.GetTenderInfo.request>) {
  const { callBack, idTender } = action.payload as Actions.TypeGetTenderInfoR
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.tenders}/${idTender}/tender-results/tender-info`,
    })) as TTenderResults[]
    
    yield put(Actions.GetTenderInfo.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetTenderInfo.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}


function* watchRequest() {
  yield takeEvery(ActionTypes.GET_BIDS_RESULT_R, getBidsResultsWorker)
  yield takeEvery(ActionTypes.GET_BIDS_WON_R, getBidsWonWorker)
  yield takeEvery(ActionTypes.GET_PURCHASE_HISTORY_R, getPurchaseHistoryWorker)
  yield takeEvery(ActionTypes.GET_TENDER_INFO_R, getTenderInfoWorker)
}

export default function* tenderResultSaga() {
  yield all([fork(watchRequest)])
}