import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppStore } from '../../../store/applicationState'
import { Close } from '../../../svg/Close'
import { ItemSummaryCard } from './ItemSummaryCard'
import { GetCurrentBids } from '../../../store/gallery/tenderSummary/actions'
import { getUniqueId } from '../../../utils/FileUtils'

interface MobileMenuProps {
  summaryActive: boolean;
  setSummaryActive: (value: boolean) => void;
}

export const Summary: React.FC<MobileMenuProps> = ({ summaryActive, setSummaryActive }) => {
  const dispatch = useDispatch()
  
  const { currentBids } = useSelector((store: AppStore) => store)
  
  useEffect(() => {
    if (summaryActive) {
      const idTender = Number(window.location.pathname.split('/').find(elem => Number(elem))) || 0
      dispatch(GetCurrentBids.request({ idTender }))
    }
  }, [summaryActive, currentBids.data.bidsAmount, currentBids.data.buyNowAmount, currentBids.data.refreshCurrentBids])
  
  useEffect(() => {
    const url = document.location.pathname.split('/')[1]
    if (url !== 'tenders') setSummaryActive(false)
  }, [document.location.pathname])
  
  return (
    <div className={`summary ${summaryActive && 'activeSummary'}`}>
      <div className='flex-col font-sans fixed w-full h-full bg-white shadow-2xl z-50 p-6 pb-4 pt-0 space-y-4 overflow-y-auto'>
        <div className='font-circa text-xl font-semibold  py-4 border-b border-bluegray-200 flex justify-between sticky top-0 bg-white'>
          <div className='font-semibold text-lg font-circa'>
            Tender Summary
          </div>
          
          <button
            type='button'
            className='flex items-center'
            onClick={() => setSummaryActive(false)}
          >
            <Close styled='text-bluegray-500 h-8 w-5 hover:text-lightblue-600' />
          </button>
        </div>
        
        {currentBids.data.currentBids?.length
          ? (
            currentBids.data.currentBids.map(item => (
              <ItemSummaryCard
                itemSummary={item}
                key={`summary-${getUniqueId()}`}
              />
            ))
          ) : (
            <div className='message mt-2'>
              No items yet.
            </div>
          )}
      </div>
    </div>
  )
}
