/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react'

interface EmailPreviewProps {
  subject: string,
  html: string
  onClose: () => void
}

export const EmailPreview: React.FC<EmailPreviewProps> = ({ subject, html, onClose }) => {
  
  return (
    <div
      style={{ background: 'rgba(0,0,0,0.2)', color: 'initial' }}
      className='fixed inset-0 flex items-center z-100 justify-center bg-black'
      onClick={onClose}
    >
      <div
        style={{ maxHeight: '85vh', minHeight: '70vh' }}
        className='rounded border bg-white border-lightblue-900 p-4 w-4/5 overflow-auto'
        onClick={e => e.stopPropagation()}
      >
        <h1 className='text-xl'>{subject}</h1>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: html }} className='editorStyle' />
      </div>
    </div>
  )
}