export const filtersChange = (
  event: any,
  setSort: (value: string) => void,
  setSortBy: (value: string) => void,
  setActiveFilter: (value: string) => void,
  ) => {
  // const filterValue = event.target.value
  const filterValue = event
  switch (filterValue) {
    case '0':
      setSort('DESC')
      setSortBy('finalized_at')
      break

    case '1':
      setSort('ASC')
      setSortBy('finalized_at')
      break

      case '2':
        setSort('DESC')
        setSortBy('total_spend')
        break

      case '3':
        setSort('ASC')
        setSortBy('total_spend')
        break

      case '4':
        setSort('DESC')
        setSortBy('bid_buy_now')
        break

      case '5':
        setSort('ASC')
        setSortBy('bid_buy_now')
        break

      case '6':
        setSort('DESC')
        setSortBy('bid_win')
        break

      case '7':
        setSort('ASC')
        setSortBy('bid_win')
        break

      case '8':
        setSort('DESC')
        setSortBy('unsuccessful_bids')
        break

      case '9':
        setSort('ASC')
        setSortBy('unsuccessful_bids')
        break

    default:
      break
  }
  setActiveFilter(filterValue)
}

export const searchInput = (
  event: any,
  setSearchQuery: (value: string) => void,
  setSearch: (value: string) => void
  ) => {
  const search = event.target.value

  setSearchQuery(search)
  setSearch(search)
}