import React from 'react'

export const PlusSign = () => {
  return (
    <svg width='20' height='20' className='inline-flex' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.99998 16.6673V10.0006M9.99998 10.0006V3.33398M9.99998 10.0006H16.6666M9.99998 10.0006H3.33331'
        stroke='#114B6D'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  )
}