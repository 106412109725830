import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { GetCompanies } from '../../store/admin/companies/actions'
import { AppStore } from '../../store/applicationState'
import { addNewParams, getDate } from '../../utils/helpers'
import { searchProps, useSearch } from '../../hooks/useSearch'
import { paginationProps } from '../../hooks/usePagination'
import { Input } from '../../components/ui/adminComponents/Input'
import { Sort } from '../../svg/Sort'
import { Button } from '../../components/ui/adminComponents/Button'
import { onAddCompany, onEditCompany } from '../../utils/userUtils/EventHandlers'
import { ENVIRONMENT } from '../../utils/consts'

const tableCellWidth = [150, 500, 120, 200, 100]

const nsUrl =
  ENVIRONMENT === 'production'
    ? 'https://9535990.app.netsuite.com/app/common/entity/custjob.nl'
    : 'https://9535990-sb1.app.netsuite.com/app/common/entity/custjob.nl'

export const Companies = () => {
  const selectedKey = useRef<{ sort: 'down' | 'up'; sortBy: string } | null>(null)

  const limitAmount = 400
  const emptyField = '-----'

  const dispatch = useDispatch()
  const location = useLocation()
  const { companies, userData } = useSelector((store: AppStore) => store)
  const history = useHistory()

  const tHead = {
    company_id: 'Company ID',
    company_title: 'Company name',
    ns_customer: 'NS customer',
    updated_at: 'Last edited',
    ...(userData.isExecutiveAdmin && { action: 'Actions' }),
  }

  const { search, sort, sortBy } = queryString.parse(location.search)

  const initialParams = {
    limit: limitAmount,
    offset: 0,
    search: (typeof search === 'string' && search) || undefined,
    sort: (typeof sort === 'string' && sort) || '',
    sortBy: (typeof sortBy === 'string' && sortBy) || '',
  }

  const [params, setParams] = useState<searchProps | paginationProps>(initialParams)
  const [dispatchTime, setDispatchTime] = useState(0)

  useEffect(() => {
    dispatch(
      GetCompanies.request({
        offset: params.offset,
        limit: params.limit,
        search: params.search,
        sortBy: params.sortBy,
        sort: params.sort,
      })
    )
  }, [params.offset, params.limit, params.sortBy, params.sort, dispatchTime])

  useSearch(limitAmount, params, setParams, setDispatchTime)

  const callBack = (sortByValue: string) => {
    if (sortByValue === 'action' || sortByValue === 'roles_categories' || sortByValue === 'message_title') return

    let value: { sortBy: string; sort: 'ASC' | 'DESC' }
    if (sortByValue !== params.sortBy || params.sort === 'DESC') value = { sortBy: sortByValue, sort: 'ASC' }
    else value = { sortBy: sortByValue, sort: 'DESC' }

    addNewParams('sort', value.sort)
    addNewParams('sortBy', value.sortBy)

    setParams((prev) => ({ ...prev, offset: 0, limit: limitAmount, sortBy: value.sortBy, sort: value.sort }))
  }

  const handleNsLinkClick = (nsInternalId: string) => {
    const url = `${nsUrl}?id=${nsInternalId}&whence=`
    window.open(url, '_blank')
  }

  return (
    <>
      <div className='flex flex-col justify-between gap-y-2 mobile:flex-row mb-6'>
        <div className='w-full md:w-1/3 lg:w-1/4'>
          <Input
            placeholder='Search...'
            value={params.search || ''}
            onChange={(e) => setParams((prev) => ({ ...prev, search: e.target.value }))}
          />
        </div>
        {userData.isExecutiveAdmin && (
          <div>
            <Button text='Add Company' variant='white' onClick={() => onAddCompany(history)} />
          </div>
        )}
      </div>

      <div className='overflow-x-auto relative'>
        <table className={`w-full ${tableCellWidth && 'table-fixed'} border whitespace-nowrap mb-2`}>
          <thead className='bg-gray-50 text-left'>
            <tr className='divide-x border-b border-bluegray-200'>
              {Object.entries(tHead).map(([key, value], index) => (
                <th key={`th${key}`} scope='col' style={{ width: tableCellWidth[index] }} className='whitespace-nowrap'>
                  {key === 'action' || key === 'ns_customer' ? (
                    value
                  ) : (
                    <button
                      type='button'
                      className={`disabled:opacity-100 ${selectedKey.current?.sortBy === key ? '!font-bold' : ''}`}
                      style={{ font: 'inherit' }}
                      onClick={() => {
                        if (callBack) {
                          callBack(key)
                          selectedKey.current = {
                            sort:
                              selectedKey.current?.sortBy === key && selectedKey.current?.sort === 'down'
                                ? 'up'
                                : 'down',
                            sortBy: key,
                          }
                        }
                      }}
                    >
                      {value}
                      <Sort sort={selectedKey.current?.sortBy === key ? selectedKey.current?.sort : undefined} />
                    </button>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className=''>
            {companies.data?.companies?.map((company) => (
              <tr className='divide-x border-b border-bluegray-200' key={`company-${company.id}`}>
                <td className='overflow-auto'>{company.id}</td>
                <td className=''>{company.company_title}</td>
                <td className=''>
                  <div className='flex justify-center items-center'>
                    {company.ns_internal_id ? (
                      <button
                        type='button'
                        className={`w-[22px] h-[22px] relative flex justify-center items-center bg-black text-white font-bold `}
                        onClick={() => handleNsLinkClick(company.ns_internal_id)}
                      >
                        <span className='text-[16px]'>N</span>
                      </button>
                    ) : (
                      emptyField
                    )}
                  </div>
                </td>
                <td className='overflow-auto'>{getDate(company.updated_at)}</td>
                {userData.isExecutiveAdmin && (
                  <td className='py-0'>
                    <div className='flex flex-col'>
                      <Button
                        className=''
                        variant='edit'
                        text='Edit'
                        onClick={() => onEditCompany(company.id, history)}
                      />
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}
