/* eslint-disable */
import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../utils/routes'

export const Footer = () => {
  return (
    <footer className='bg-bluegray-600 w-full h-12 text-sm flex items-center justify-between px-8 text-bluegray-400 font-circa d bottom-0'>
      <div>{`© CIRCA ${new Date().getFullYear()}`}</div>
      <div className="flex space-x-6 text-white">
        <a href="http://crca.co/ctp" target='_blank' className="text-white hover:text-slate-300">
          User Guide
        </a>
        <Link to={ROUTES.dashboard} className='text-white hover:text-slate-300'>
          Dashboard
        </Link>
      </div>
    </footer>
  )
}
