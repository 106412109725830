import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as Actions from './actions'
import { ActionTypes, TBidsByItem, TBidsByItemChild, TTenderStatsForBidsByItem } from './types'
import { callApi } from '../../../../utils/callApi'
import { API_ROUTES } from '../../../../utils/apiRoutes'

function* getBidsByItemWorker(action: ReturnType<typeof Actions.GetBidsByItem.request>) {
  const { callBack, tenderId, offset, limit, search, sortBy, sort, idCompanyRecommendations } =
    action.payload as Actions.TypeGetBidsByItemR
  let success = true
  let data = null

  let query = `?Offset=${offset || 0}&Limit=${limit || 20}`
  query += search ? `&Search=${search}` : ''
  query += sortBy ? `&SortBy=${sortBy}` : ''
  query += sort ? `&Sort=${sort}` : ''
  query += idCompanyRecommendations ? `&idCompanyRecommendations=${idCompanyRecommendations}` : ''

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminTenders}/${tenderId}/items${query}`,
    })) as TBidsByItem[]
    if (offset !== 0) yield put(Actions.GetBidsByItem.success(data))
    else yield put(Actions.GetBidsByItemWith0Offset.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetBidsByItem.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getBidsByItemChildWorker(action: ReturnType<typeof Actions.GetBidsByItemChild.request>) {
  const { callBack, idItem } = action.payload as Actions.TypeGetBidsByItemChildR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminTenders}/items/${idItem}/bids`,
    })) as TBidsByItemChild[]

    yield put(Actions.GetBidsByItemChild.success({ data, idItem }))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetBidsByItemChild.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getBidsByItemForTenderCloseWorker(action: ReturnType<typeof Actions.GetBidsByItemForTenderClose.request>) {
  const { callBack, tenderId, offset, limit, search, sortBy, sort } =
    action.payload as Actions.TypeGetBidsByItemForTenderCloseR
  let success = true
  let data = null

  let query = `?Offset=${offset || 0}&Limit=${limit || 10000}`
  query += search ? `&Search=${search}` : ''
  query += sortBy ? `&SortBy=${sortBy}` : ''
  query += sort ? `&Sort=${sort}` : ''
  query += `&WhereExistBids=${true}`

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminTenders}/${tenderId}/items${query}`,
    })) as TBidsByItem[]

    yield put(Actions.GetBidsByItemForTenderClose.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetBidsByItemForTenderClose.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getBidsByItemForTenderCloseChildWorker(
  action: ReturnType<typeof Actions.GetBidsByItemForTenderCloseChild.request>
) {
  const { callBack, idItem } = action.payload as Actions.TypeGetBidsByItemForTenderCloseChildR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminTenders}/items/${idItem}/bids`,
    })) as TBidsByItemChild[]

    yield put(Actions.GetBidsByItemForTenderCloseChild.success({ data, idItem }))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetBidsByItemForTenderCloseChild.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getTenderStatsForBidsByItemWorker(action: ReturnType<typeof Actions.GetTenderStatsForBidsByItem.request>) {
  const { callBack, idTender } = action.payload as Actions.TypeGetTenderStatsForBidsByItemR
  let success = true
  let data = null
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminTenders}/${idTender}/bids/tender-stats`,
    })) as TTenderStatsForBidsByItem

    yield put(Actions.GetTenderStatsForBidsByItem.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetTenderStatsForBidsByItem.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.GET_BIDS_BY_ITEM_R, getBidsByItemWorker)
  yield takeEvery(ActionTypes.GET_BIDS_BY_ITEM_CHILD_R, getBidsByItemChildWorker)
  yield takeEvery(ActionTypes.GET_BIDS_BY_ITEM_FOR_TENDER_CLOSE_R, getBidsByItemForTenderCloseWorker)
  yield takeEvery(ActionTypes.GET_BIDS_BY_ITEM_FOR_TENDER_CLOSE_CHILD_R, getBidsByItemForTenderCloseChildWorker)
  yield takeEvery(ActionTypes.GET_TENDER_STATS_FOR_BIDS_BY_ITEM_R, getTenderStatsForBidsByItemWorker)
}

export default function* bidsByItemSaga() {
  yield all([fork(watchRequest)])
}
