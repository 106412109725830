import { Reducer } from 'redux'
import { ActionTypes, TTendersAuditState } from './types'
import { getDataForPagination, getMapData } from '../../../../utils/reducerUtils'

export const initialState: TTendersAuditState = {
  loading: false,
  data: null,
  childData: null,
}

const reducer: Reducer<TTendersAuditState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_AUDIT_BY_TENDER_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_AUDIT_BY_TENDER_S:
      return { ...state, loading: false, errors: undefined, data: getDataForPagination(state.data, action.payload) }
    case ActionTypes.GET_AUDIT_BY_TENDER_WITH_0_OFFSET_S:
      return { ...state, loading: false, errors: undefined, data: action.payload }
    case ActionTypes.GET_AUDIT_BY_TENDER_E:
      return { ...state, loading: false, errors: action.payload, data: [] }
    
    case ActionTypes.GET_AUDIT_BY_TENDER_CHILD_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_AUDIT_BY_TENDER_CHILD_S:
      return { ...state, loading: false, errors: undefined, childData: getMapData(state.childData, action.payload) }
    case ActionTypes.GET_AUDIT_BY_TENDER_CHILD_WITH_0_OFFSET_S:
      return { ...state, loading: false, errors: undefined, childData: getMapData(state.childData, action.payload) }
    case ActionTypes.GET_AUDIT_BY_TENDER_CHILD_E:
      return { ...state, loading: false, errors: action.payload }
    
    default:
      return state
  }
}

export { reducer as AuditByTenderReducer }