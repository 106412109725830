/* eslint-disable */
import React from 'react'

export const Information = () => {
  return (
    <div className="general-information bg-white shadow p-7 px-3 desktop:px-7 space-y-4 md:block hidden">
      <div className="font-circa text-black text-lg font-semibold">
        General Information
      </div>

      <div className="text-lightblue-600 space-y-3">
        <div>
          <a href="http://crca.co/ctp" target='_blank' className="user-guide-button hover:text-menuLink">
            User Guide
          </a>
        </div>

        <div>
          <a href="mailto:cms@circajewels.com?subject=Add New User to Tender" className="add-new-user-button hover:text-menuLink">
            Request to add new user
          </a>
        </div>
      </div>
    </div>
  )
}
