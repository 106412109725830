/* eslint-disable react/require-default-props */
import React from 'react'

interface TemplateHintProps {
  onSkip: () => void
  onThanks: () => void
  className?: string
}

export const TemplateHint: React.FC<TemplateHintProps> = ({ onSkip, onThanks, className }) => {
  return (
    <div className={` ${className}`}>
      <div
        className='w-0 h-0 ml-auto mr-3'
        style={{
          borderLeft: '20px solid transparent',
          borderRight: '20px solid transparent',
          borderBottom: '30px solid rgb(6, 95, 70)',
        }}
      />
      <div className='w-full bg-green-800 rounded py-3 px-5'>
        <div className='text-white font-semibold'>Tender</div>
        <div className='text-white text-sm'>Please choose the Tender ID to proceed</div>
        <div className='flex justify-end gap-4 text-white text-sm mt-1'>
          <button type='button' className='opacity-60' onClick={onSkip}>skip</button>
          <button
            type='button'
            className='border border-white hover:bg-white hover:text-green-800 px-3 rounded'
            onClick={onThanks}
          >
            thanks
          </button>
        </div>
      </div>
    </div>
  )
}
