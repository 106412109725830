/* eslint-disable react/require-default-props,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react'
import { Close } from '../../svg/Close'

export interface PopUpForCsvDataType {
  column?: string
  email?: string
  first_name?: string
  last_name?: string
  company?: string
  phone?: string
  role?: string
}

interface PopUpForCsvProps {
  onClose: () => void
  className?: string
  data: PopUpForCsvDataType[] | null
}

export const PopUpForCsv: React.FC<PopUpForCsvProps> = ({ onClose, className, data }) => {
  return (
    <div
      style={{ background: 'rgba(0, 0, 0, 0.5)' }}
      className='fixed inset-0 flex justify-center items-center z-100'
      onClick={onClose}
    >
      <div
        onClick={e => e.stopPropagation()}
        className={`bg-white w-11/12 md:w-4/5 lg:w-3/4 rounded ${className}`}
        style={{ maxWidth: 'calc(100vw - 10px)', zIndex: 1000 }}
      >
        <div className='p-4 pb-0 flex justify-between'>
          <span>Error loading csv</span>
          <button type='button' onClick={onClose}>
            <Close />
          </button>
        </div>
        <div className='h-0.5 my-4 w-full bg-bluegray-200' />
        <div className='overflow-x-auto px-2'>
          <table className='w-full'>
            <thead>
              <tr>
                <th>
                  Column
                </th>
                <th>
                  Email
                </th>
                <th>
                  First Name
                </th>
                <th>
                  Last Name
                </th>
                <th>
                  Company
                </th>
                <th>
                  Phone
                </th>
                <th>
                  Roles & Categories
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map(item => (
                <tr key={item.column} className='border-b border-t border-bluegray-200'>
                  <td>
                    {item.column}
                  </td>
                  <td>
                    {item.email}
                  </td>
                  <td>
                    {item.first_name}
                  </td>
                  <td>
                    {item.last_name}
                  </td>
                  <td>
                    {item.company}
                  </td>
                  <td>
                    {item.phone}
                  </td>
                  <td>
                    {item.role}
                  </td>
                </tr>
            ))}
            </tbody>
          </table>
        </div>
        <div className='h-0.5 my-4 w-full bg-bluegray-200' />
      </div>
    </div>
  )
}
