import { Reducer } from 'redux'
import { ActionTypes, TGlobalState } from './types'

export const initialState: TGlobalState = {
  loaderStep: 0,
}

const reducer: Reducer<TGlobalState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_LOADER:
      return { ...state, loaderStep: state.loaderStep + 1 }

    default:
      return state
  }
}

export { reducer as GlobalReducer }
