/* eslint-disable react/require-default-props,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Checkbox } from '../Checkbox'
import { Sort } from '../../../svg/Sort'
import { FilterRegular } from '../../../svg/FilterRegular'
import { FilterSolid } from '../../../svg/FilterSolid'
import { RadioBtn } from '../RadioBtn'

interface CustomDropdownProps {
  title: string
  onClear?: () => void
  callBack?: () => void
  solidIcon?: boolean
  children: {
    title: string
    checkboxComponent?: any
    activeCheckboxId?: number[]
    checkboxes?: {
      id: number
      title: string
      callBack: () => void
    }[]
    activeRadioBtnId?: number
    radioBtns?: {
      id: number
      title: string
      callBack: () => void
    }[]
  }[]
  menuItemsWidth: string
  selected?: boolean
  updown?: 'down' | 'up' | null
}

export const CustomDropdown: React.FC<CustomDropdownProps> = ({
  children,
  solidIcon = false,
  title,
  onClear,
  callBack,
  menuItemsWidth,
  selected,
  updown,
}) => {
  return (
    <Menu as='div' className='inline-block text-left w-full'>
      <Menu.Button
        style={{ font: 'inherit', userSelect: 'none' }}
        className='inline-flex items-center w-full cursor-pointer'
        as='div'
      >
        <div
          className='inline-flex items-center'
          onClick={(e) => {
            if (callBack) {
              e.stopPropagation()
              callBack()
            }
          }}
        >
          <div className={`${selected ? '!font-bold' : ''}`}>{title}</div>
          {callBack && <Sort sort={(selected && updown) || undefined} />}
        </div>
        <div className='ml-auto'>{solidIcon ? <FilterSolid /> : <FilterRegular />}</div>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items
          style={{ maxHeight: '100vh' }}
          className={`origin-top-right overflow-auto absolute -ml-2 mt-2 p-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50 ${menuItemsWidth}`}
        >
          {children.map((item) => (
            <div className='p-1' key={item.title}>
              <Menu.Item>
                <>
                  <div className='flex justify-between'>
                    <h1 className='mb-1'>{item.title}</h1>
                    {item.checkboxComponent}
                  </div>
                  <div className='flex flex-col gap-1'>
                    {item.checkboxes?.map((checkbox) => (
                      <Checkbox
                        key={checkbox.title + checkbox.id}
                        label={checkbox.title}
                        classname='hover:shadow-md'
                        isChecked={!!item.activeCheckboxId?.some((id) => id === checkbox.id)}
                        setIsChecked={() => checkbox.callBack()}
                      />
                    ))}
                    {item.radioBtns?.map((radioBtn) => (
                      <div key={radioBtn.title + radioBtn.id}>
                        <RadioBtn
                          color='text-bluegray-600'
                          text={radioBtn.title}
                          value={radioBtn.title + radioBtn.id}
                          checked={radioBtn.id === item.activeRadioBtnId}
                          onChange={radioBtn.callBack}
                          name={radioBtn.title}
                        />
                      </div>
                    ))}
                  </div>
                </>
              </Menu.Item>
            </div>
          ))}
          {onClear && (
            <button
              type='submit'
              onClick={onClear}
              className='block w-full text-left my-1 px-4 py-2 shadow-md border border-gray-100 bg-gray-50 text-gray-900'
            >
              Clear filters
            </button>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
