export interface TImagesMetaData {
  file_name: string
  file_type_id: number
  id: number
  sku: string
  sort: number
  tender_id: number
  url: string
}

export interface TTenderImagesStatus {
  video_mp4: number
  regular_images: number
  sku: string
}

export interface TTenderGalleryResponse {
  url: string
  image_id: number
  file_name_from_load: string
  file_type_id: number
}

export interface TTenderGalleryList {
  item_id: number
  sku: string
  title: string
  gallery_response: TTenderGalleryResponse[] | null
}

export interface TMediaManagementMessage {
  message: string
}

export interface TMediaManagementState {
  loading: boolean
  data: {
    imagesMetaData: TImagesMetaData[] | null
    tenderImagesStatus: TTenderImagesStatus[] | null
    filestackApiKey: string | null
    galleryList: TTenderGalleryList[] | null
  }
  errors?: string | undefined
}

export enum ActionTypes {
  GET_TENDER_IMAGES_STATUS_R = '@@mediaManagement/GET_TENDER_IMAGES_STATUS_R',
  GET_TENDER_IMAGES_STATUS_S = '@@mediaManagement/GET_TENDER_IMAGES_STATUS_S',
  GET_TENDER_IMAGES_STATUS_E = '@@mediaManagement/GET_TENDER_IMAGES_STATUS_E',

  SEND_IMAGES_METADATA_R = '@@mediaManagement/SEND_IMAGES_METADATA_R',
  SEND_IMAGES_METADATA_S = '@@mediaManagement/SEND_IMAGES_METADATA_S',
  SEND_IMAGES_METADATA_E = '@@mediaManagement/SEND_IMAGES_METADATA_E',

  GET_FILESTACK_API_KEY_R = '@@mediaManagement/GET_FILESTACK_API_KEY_R',
  GET_FILESTACK_API_KEY_S = '@@mediaManagement/GET_FILESTACK_API_KEY_S',
  GET_FILESTACK_API_KEY_E = '@@mediaManagement/GET_FILESTACK_API_KEY_E',

  DELETE_ALL_IMAGES_R = '@@mediaManagement/DELETE_ALL_IMAGES_R',
  DELETE_ALL_IMAGES_S = '@@mediaManagement/DELETE_ALL_IMAGES_S',
  DELETE_ALL_IMAGES_E = '@@mediaManagement/DELETE_ALL_IMAGES_E',

  DELETE_ONE_IMAGE_R = '@@mediaManagement/DELETE_ONE_IMAGE_R',
  DELETE_ONE_IMAGE_S = '@@mediaManagement/DELETE_ONE_IMAGE_S',
  DELETE_ONE_IMAGE_E = '@@mediaManagement/DELETE_ONE_IMAGE_E',

  GET_TENDER_GALLERY_LIST_R = '@@mediaManagement/GET_TENDER_GALLERY_LIST_R',
  GET_TENDER_GALLERY_LIST_S = '@@mediaManagement/GET_TENDER_GALLERY_LIST_S',
  GET_TENDER_GALLERY_LIST_E = '@@mediaManagement/GET_TENDER_GALLERY_LIST_E',
}
