import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { callApi } from '../../../utils/callApi'
import { API_ROUTES } from '../../../utils/apiRoutes'
import { ActionTypes, TAdminUser, TAdminUsers, TAdminUsersMessage, TAdminUsersTokens } from './types'
import * as Actions from './actions'
import { SnackBar } from '../../../components/ui/SnackBar'

const { addSnackBar } = SnackBar()

function* getAllUsersWorker(action: ReturnType<typeof Actions.GetAllUsers.request>) {
  const {
    offset,
    limit,
    search,
    sortBy,
    sort,
    companyId,
    roleId,
    categoryId,
    readOnlyCategoryId,
    recommendationItemId,
    withoutBid,
    callBack,
  } = action.payload as Actions.TypeGetAllUsers
  let success = true
  let data = null

  let query = `?Offset=${offset || 0}&Limit=${limit || 20}`
  query += search ? `&Search=${encodeURIComponent(search)}` : ''
  query += sortBy ? `&SortBy=${sortBy}` : ''
  query += sort ? `&Sort=${sort}` : ''
  query += companyId ? companyId.map((id) => `&CompanyId=${id}`).join('') : ''
  query += roleId ? roleId.map((id) => `&RoleId=${id}`).join('') : ''
  query += categoryId ? categoryId.map((id) => `&CategoryId=${id}`).join('') : ''
  query += readOnlyCategoryId ? readOnlyCategoryId.map((id) => `&ReadOnlyCategoryId=${id}`).join('') : ''
  query += recommendationItemId ? `&RecommendationItemId=${recommendationItemId}` : ''
  query += withoutBid ? `&WithoutBid=${withoutBid}` : ''

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminGetUsers}${query}`,
    })) as TAdminUsers[]
    if (offset !== 0) yield put(Actions.GetAllUsers.success(data))
    else yield put(Actions.GetAllUsersWith0Offset.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetAllUsers.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getUserByIdWorker(action: ReturnType<typeof Actions.GetUserById.request>) {
  const { userId, callBack } = action.payload as Actions.TypeGetUserById
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminGetUserById}/${userId}`,
    })) as TAdminUser

    yield put(Actions.GetUserById.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetUserById.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* createNewUserWorker(action: ReturnType<typeof Actions.CreateNewUser.request>) {
  const { callBack, body } = action.payload as Actions.TypeCreateNewUser
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'post',
      path: API_ROUTES.adminCreateUser,
      data: body,
    })) as TAdminUsersMessage

    yield put(Actions.CreateNewUser.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.CreateNewUser.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    const successMessage = 'User has been created'
    const errorMessage = data?.message || ''
    const snackBarMessage = success ? successMessage : errorMessage
    addSnackBar(snackBarMessage, success)
  }
}

function* updateUserInfoWorker(action: ReturnType<typeof Actions.UpdateUserInfo.request>) {
  const { callBack, body, user_id } = action.payload as Actions.TypeUpdateUserInfo
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'put',
      path: `${API_ROUTES.adminUpdateUser}/${user_id}`,
      data: body,
    })) as TAdminUsersMessage

    yield put(Actions.UpdateUserInfo.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.UpdateUserInfo.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    const successMessage = 'User profile has been updated'
    const errorMessage = data?.message || ''
    const snackBarMessage = success ? successMessage : errorMessage
    addSnackBar(snackBarMessage, success)
  }
}

function* getUsersCsvTemplateWorker(action: ReturnType<typeof Actions.GetUsersCsvTemplate.request>) {
  const { callBack } = action.payload as Actions.TypeGetUsersCsvTemplate
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: API_ROUTES.adminGetUsersCsvTemplate,
    })) as TAdminUsersMessage

    yield put(Actions.GetUsersCsvTemplate.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetUsersCsvTemplate.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (!success) addSnackBar(data?.message || '', success)
  }
}

function* getCategoryCsvWorker(action: ReturnType<typeof Actions.GetCategoryCsv.request>) {
  const { callBack, categoryId } = action.payload as Actions.TypeGetCategoryCsv
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminGetCategoryCsv}/${categoryId}`,
    })) as TAdminUsersMessage

    yield put(Actions.GetCategoryCsv.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetCategoryCsv.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (!success) addSnackBar(data?.message || '', success)
  }
}

function* postUsersCsvTemplateWorker(action: ReturnType<typeof Actions.PostUsersCsvTemplate.request>) {
  const { callBack, file } = action.payload as Actions.TypePostUsersCsvTemplate
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'post',
      path: API_ROUTES.adminPostUsersCsvTemplate,
      data: file,
    })) as TAdminUsersMessage

    yield put(Actions.PostUsersCsvTemplate.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.PostUsersCsvTemplate.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)

      if (!success && !data?.data) {
        const errorMessage = data?.message || ''
        addSnackBar(errorMessage, success)
      } else if (success) {
        const successMessage = 'New users have been added'
        addSnackBar(successMessage, success)
      }
  }
}

function* getTokensForViewLikeAUserWorker(action: ReturnType<typeof Actions.GetTokensForViewLikeAUser.request>) {
  const { callBack, idUser, with_terms_confirmed } = action.payload as Actions.TypeGetTokensForViewLikeAUser
  let success = true
  let data = null
  const query = `?WithTermsConfirmed=${!!with_terms_confirmed}`

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminGetTokensForViewLikeAUser}/${idUser}/view-like-a-user${query}`,
    })) as TAdminUsersTokens

    yield put(Actions.GetTokensForViewLikeAUser.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetTokensForViewLikeAUser.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (!success) addSnackBar(data?.message || '', success)
  }
}

function* resendInviteWorker(action: ReturnType<typeof Actions.ResendInvite.request>) {
  const { callBack, idUser } = action.payload as Actions.TypeResendInvite
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'post',
      path: `${API_ROUTES.adminResendInvite}/${idUser}/resend-invite`,
    })) as TAdminUsersMessage

    yield put(Actions.ResendInvite.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.ResendInvite.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    const successMessage = 'Re-invitation has been sent'
    const errorMessage = data?.message || ''
    const snackBarMessage = success ? successMessage : errorMessage
    addSnackBar(snackBarMessage, success)
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.GET_All_USERS_R, getAllUsersWorker)
  yield takeEvery(ActionTypes.GET_USER_BY_ID_R, getUserByIdWorker)
  yield takeEvery(ActionTypes.CREATE_NEW_USER_R, createNewUserWorker)
  yield takeEvery(ActionTypes.UPDATE_USER_INFO_R, updateUserInfoWorker)
  yield takeEvery(ActionTypes.GET_USERS_CSV_TEMPLATE_R, getUsersCsvTemplateWorker)
  yield takeEvery(ActionTypes.GET_CATEGORY_CSV_R, getCategoryCsvWorker)
  yield takeEvery(ActionTypes.POST_USERS_CSV_TEMPLATE_R, postUsersCsvTemplateWorker)
  yield takeEvery(ActionTypes.GET_TOKENS_FOR_VIEW_LIKE_A_USER_R, getTokensForViewLikeAUserWorker)
  yield takeEvery(ActionTypes.RESEND_INVITE_R, resendInviteWorker)
}

export default function* adminUsersSaga() {
  yield all([fork(watchRequest)])
}
