import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { IconLogo } from '../../svg/IconLogo'
import { ROUTES } from '../../utils/routes'

export const SuccessReset = () => {
  const history = useHistory()
  const location = useLocation()

  return (
    <div className='page-content max-w-screen-xl mx-auto flex justify-center items-center text-sm lg:pt-9 smallMobile:pt-16 pt-20'>
      <div className='container mx-auto'>
        <div className='max-w-md mx-auto my-10'>
          <div className='text-center'>
            <h1 className='my-3 text-xl font-circa font-semibold text-gray-700 dark:text-gray-200'>
              <IconLogo />
              <p>Forgot Password</p>
            </h1>
          </div>
          <div className='m-7'>
            <div className='mb-6'>
              <div className='success-reset w-full text-base px-3 py-3 text-green-700 border border-green-600 bg-emerald-100 rounded-md text-center'>
                Check your email for Reset Link
              </div>
            </div>
            <div className='mb-6'>
              <button
                type='button'
                className='return-to-login form-button'
                onClick={() => {
                  const isWorthy = location.pathname.includes('/worthy')
                  history.push(isWorthy ? `/worthy${ROUTES.login}` : ROUTES.login)
                }}
              >
                go to login form
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
