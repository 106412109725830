import { Actions } from '../../../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../../../types/actions'

/// ///////////////////////////////////////////////////////////////////////
const GetAllCsmTemplatesActions = new Actions('GET_ALL_CSM_TEMPLATES', ActionTypes)

export interface TypeGetAllCsmTemplatesR extends TDefRequest {
  search?: string
  sortBy?: string
  sort?: string
}

export const GetAllCsmTemplates = {
  request: (payload?: TypeGetAllCsmTemplatesR) => GetAllCsmTemplatesActions.request(payload || {}),
  success: (payload: any) => GetAllCsmTemplatesActions.success(payload),
  error: (payload: string) => GetAllCsmTemplatesActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const GetAllCsmMessagesByIdActions = new Actions('GET_ALL_CSM_MESSAGES_BY_ID', ActionTypes)

export interface TypeGetAllCsmMessagesByIdR extends TDefRequest {
  idTemplate: number
}

export const GetAllCsmMessagesById = {
  request: (payload: TypeGetAllCsmMessagesByIdR) => GetAllCsmMessagesByIdActions.request(payload),
  success: (payload: any) => GetAllCsmMessagesByIdActions.success(payload),
  error: (payload: string) => GetAllCsmMessagesByIdActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const EditTemplateActions = new Actions('EDIT_TEMPLATE', ActionTypes)

export interface TypeEditTemplateR extends TDefRequest {
  idTemplate: number
  body: {
    active: boolean
    preferred_delivery_method: boolean
    schedule: number
  }
}

export const EditTemplate = {
  request: (payload: TypeEditTemplateR) => EditTemplateActions.request(payload),
  success: (payload: any) => EditTemplateActions.success(payload),
  error: (payload: string) => EditTemplateActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const EditMessageActions = new Actions('EDIT_MESSAGE', ActionTypes)

export interface TypeEditMessageR extends TDefRequest {
  idMessage: number
  body: {
    active: boolean
    body: string
    footer: string
    subject: string
  }
}

export const EditMessage = {
  request: (payload: TypeEditMessageR) => EditMessageActions.request(payload),
  success: (payload: any) => EditMessageActions.success(payload),
  error: (payload: string) => EditMessageActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
const SendMessageToUsersActions = new Actions('SEND_MESSAGE_TO_USERS', ActionTypes)

export interface TypeSendMessageToUsersR extends TDefRequest {
  body: {
    attachments: { file_base_64: string; file_name: string }[]
    have_catalog?: boolean
    have_ical?: boolean
    preferred: boolean
    template_id?: number
    tender_id?: number
    users_ids: number[]
    email: {
      active: boolean
      body: string
      footer?: string
      subject?: string
    }
    // whats_app: {
    //   active: boolean,
    //   body: string,
    //   footer?: string,
    //   subject?: string
    // }
    sms: {
      active: boolean
      body: string
      footer?: string
      subject?: string
    }
  }
}

export const SendMessageToUsers = {
  request: (payload: TypeSendMessageToUsersR) => SendMessageToUsersActions.request(payload),
  success: (payload: any) => SendMessageToUsersActions.success(payload),
  error: (payload: string) => SendMessageToUsersActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
export const SetCurrentUsersId = (payload: number[]) => ({ type: ActionTypes.SET_CURRENT_USERS_ADMIN, payload })
