export interface TTerms {
  terms: string,
}

export interface TTermsMessage {
  message: string
}

export interface TTermsState {
  loading: boolean
  data: TTerms | null
  errors?: string | undefined
  confirmed?: boolean
}

export enum ActionTypes {
  GET_TERMS_R = '@@terms/GET_TERMS_R',
  GET_TERMS_S = '@@terms/GET_TERMS_S',
  GET_TERMS_E = '@@terms/GET_TERMS_E',
  
  POST_TERMS_R = '@@terms/POST_TERMS_R',
  POST_TERMS_S = '@@terms/POST_TERMS_S',
  POST_TERMS_E = '@@terms/POST_TERMS_E',
  
  SET_TERMS_FALSE = '@@terms/SET_TERMS_FALSE',
  SET_TERMS_TRUE = '@@terms/SET_TERMS_TRUE'
}
