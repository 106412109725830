import { TGalleryItemInfo, TGalleryTenderInfo } from '../../store/gallery/types'
import { tenderSocketType } from '../../hooks/Sockets/useTender'
import { ChangeItemByIdR } from '../../store/gallery/actions'

// sold = 0,
// buyNow = 1,
// bid = 2,
// deleteBid = 3,
// undoBuyNow = 4,

export const parseTenderItems = (
  state: TGalleryItemInfo[] | null,
  payload: tenderSocketType
): TGalleryItemInfo[] | null => {
  const currentItem = state?.find((item) => item.item_id === payload.item_id)

  if (!currentItem) return state

  // currentItem.product.asking_price = payload.price
  // currentItem.product.asking_price_per_carat = payload.price_per_carat
  // currentItem.product.rap_list_price_discount = payload.percent_of_rap

  switch (payload.type_of_event) {
    case 0:
      currentItem.is_sold = true
      if (currentItem.bid_status_id === 1) currentItem.bid_status_id = 7
      else currentItem.bid_status_id = 0
      break
    case 1:
      currentItem.is_sold = true
      currentItem.bid_status_id = 8
      break
    case 2:
      currentItem.bid_status_id = 1
      currentItem.bid = payload.price
      break
    case 3:
      currentItem.bid_status_id = 0
      currentItem.bid = 0
      break
    case 4:
      currentItem.is_sold = false
      if (payload.price) {
        currentItem.bid_status_id = 1
        currentItem.bid = payload.price
      } else {
        currentItem.bid_status_id = 0
        currentItem.bid = 0
      }
      break
    default:
      return state
  }

  return state
}

export const parseItemInfo = (state: TGalleryItemInfo | null, payload: tenderSocketType): TGalleryItemInfo | null => {
  const currentItem = state?.item_id === payload.item_id ? state : null

  if (!currentItem) return state

  // currentItem.product.asking_price = payload.price
  // currentItem.product.asking_price_per_carat = payload.price_per_carat
  // currentItem.product.rap_list_price_discount = payload.percent_of_rap

  switch (payload.type_of_event) {
    case 0:
      currentItem.is_sold = true
      if (currentItem.bid_status_id === 1) currentItem.bid_status_id = 7
      else currentItem.bid_status_id = 0
      break
    case 1:
      currentItem.is_sold = true
      currentItem.bid_status_id = 8
      break
    case 2:
      currentItem.bid_status_id = 1
      currentItem.bid = payload.price
      break
    case 3:
      currentItem.bid_status_id = 0
      currentItem.bid = 0
      break
    case 4:
      currentItem.is_sold = false
      if (payload.price) {
        currentItem.bid_status_id = 1
        currentItem.bid = payload.price
      } else {
        currentItem.bid_status_id = 0
        currentItem.bid = 0
      }
      break
    default:
      return state
  }

  return state
}

export const parseTenderItems2 = (
  state: TGalleryItemInfo[] | null,
  payload: ChangeItemByIdR
): TGalleryItemInfo[] | null => {
  const currentItem = state?.find((item) => item.item_id === payload.idItem)
  if (!currentItem) return state

  if (payload.body?.bid) currentItem.bid = payload.body.bid

  return state
}

export const parseTenderInfo = (
  state: TGalleryTenderInfo | null,
  payload: tenderSocketType
): TGalleryTenderInfo | null => {
  if (!state) return state
  const { tender_info } = state
  if (payload.type_of_event === 0 || payload.type_of_event === 1) {
    tender_info.sold += 1
  }
  if (payload.type_of_event === 4) {
    tender_info.sold -= 1
  }
  return state
}

export const parseTenderItemsForPersRecommend = (
  state: TGalleryItemInfo[] | null,
  payload: tenderSocketType
): TGalleryItemInfo[] | null => {
  const currentItem = state?.find((item) => item.item_id === payload.item_id)

  if (!currentItem) return state

  switch (payload.type_of_event) {
    case 0:
      break
    case 1:
      break
    case 2:
      if (currentItem.product.count_of_bids) currentItem.product.count_of_bids += 1
      else currentItem.product.count_of_bids = 1
      break
    case 3:
      if (currentItem.product.count_of_bids) currentItem.product.count_of_bids -= 1
      else currentItem.product.count_of_bids = 0
      break
    case 4:
      break
    default:
      return state
  }

  return state
}
