import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { IconLogo } from '../../svg/IconLogo'
import { validateButton } from '../../utils/userUtils/FormStyles'
import { ROUTES } from '../../utils/routes'
import { PostForgotPass, TypePostForgotPassR } from '../../store/auth/actions'
import { onEmailChange } from '../../utils/userUtils/EventHandlers'
import { Spinner } from '../../svg/Spinner'
import { AppStore } from '../../store/applicationState'

export const ForgotPass = () => {
  const [userEmail, setUserEmail] = useState('')
  const [emailError, setEmailError] = useState(true)
  const [submitError, setSubmitError] = useState(false)
  
  const dispatch = useDispatch()
  const history = useHistory()
  const { signIn } = useSelector((store: AppStore) => store)
  
  const callBack = (success: boolean) => {
    if (success) {
      history.push(ROUTES.successReset)
    }
  }
  
  const forgotPassFunc = (data: TypePostForgotPassR) => {
    dispatch(
      PostForgotPass.request({
        ...data,
        callBack,
      }),
    )
  }
  
  const onSubmitForm = (event: any) => {
    event.preventDefault()
    
    if (emailError) {
      setSubmitError(true)
      return null
    }
    setEmailError(false)
    
    return forgotPassFunc({ email: userEmail })
  }
  
  return (
    <div
      className='page-content max-w-screen-xl mx-auto flex justify-center items-center text-sm lg:pt-9 smallMobile:pt-16 pt-20'
    >
      <div className='container mx-auto'>
        <div className='max-w-md mx-auto my-10'>
          <div className='text-center'>
            <h1 className='my-3 text-xl font-circa font-semibold text-gray-700 dark:text-gray-200'>
              <IconLogo />
              <p>Reset Password</p>
            </h1>
          </div>
          
          <div className='m-7'>
            <form
              noValidate
              onSubmit={(event) => onSubmitForm(event)}
              action=''
              className='caret-button-reports'
            >
              <div className='mb-3'>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor='email' className='form-label'>
                  Enter your email address
                </label>
                
                <div>
                  <input
                    value={userEmail}
                    onChange={(event) =>
                      onEmailChange(event, setUserEmail, setEmailError)}
                    type='email'
                    name='email'
                    id='email'
                    placeholder='E-mail'
                    className={`form-input ${validateButton(emailError, submitError)}`}
                  />
                  
                  <div className={`form-error-text mt-1 ${emailError && submitError ? 'block' : 'hidden'}`}>
                    Please enter valid email address.
                  </div>
                </div>
              </div>
              
              <div className='mb-6'>
                <Link
                  to={ROUTES.login}
                  className='return-to-login text-base text-gray-400 focus:outline-none focus:text-button-reports hover:text-button-reports'
                >
                  Return to login
                </Link>
              </div>
              
              <div className='mb-6 relative'>
                <button
                  type='submit'
                  className='form-button'
                  style={signIn.loading ? { color: 'transparent' } : {}}
                >
                  Reset password
                </button>
                {signIn.loading && (
                  <div
                    className='absolute'
                    style={{ top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}
                  >
                    <Spinner />
                  </div>
                )}
              </div>
              
              {/* <div> */}
              {/*  <Link to="/success-reset" className="text-base text-gray-400 focus:outline-none focus:text-button-reports hover:text-button-reports"> */}
              {/*    Go to next layout */}
              {/*  </Link> */}
              {/* </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
