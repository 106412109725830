/* eslint-disable react/require-default-props,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Sort } from '../../../svg/Sort'
import { getUniqueId } from '../../../utils/FileUtils'
import { LoaderPoints } from '../LoaderPoints'
import { TTendersAudit } from '../../../store/admin/tenders/audit/types'
import { TCompanies } from '../../../store/admin/companies/types'
import { GetCompanies } from '../../../store/admin/companies/actions'
import { AppStore } from '../../../store/applicationState'
import { CustomDropdown } from '../adminUsersComponents/CustomDropdown'
import { getSingleMapData, getSingleMapDataForce } from '../../../utils/reducerUtils'
import { GetAuditByTendersChild } from '../../../store/admin/tenders/audit/actions'

interface TableProps {
  thead: object
  tbody: { id: number; [x: string]: any }[] | null
  tbodyChild: Map<number, object[]> | null
  tableCellWidth?: number[]
  no_borders_style?: boolean
  callBack?: (sortBy: string) => void
  loading?: boolean
  childIndex: number[]
  setChildIndex: (value: ((prev: number[]) => any) | number[]) => void
}

export const ChildrenTable = (
  tbody: object[] | undefined,
  body: TTendersAudit | undefined,
  selectedCompany: number | undefined,
  availableCompanies: TCompanies[] | undefined,
  onCompanyChose: (idItem: number, companyId: number) => void,
  colSpan: number | undefined
) => {
  if (!tbody || !body || !availableCompanies) return null

  const { item_id } = body

  const customCompanyDropdown = () => {
    return (
      <CustomDropdown
        menuItemsWidth='w-54'
        title='Company'
        solidIcon={!!selectedCompany && selectedCompany !== -1}
        onClear={() => onCompanyChose(item_id, -1)}
      >
        {[
          {
            title: 'Companies',
            activeRadioBtnId: selectedCompany,
            radioBtns: availableCompanies.map((company) => ({
              id: company.id,
              title: company.company_title,
              callBack: () => onCompanyChose(item_id, company.id),
            })),
          },
        ]}
      </CustomDropdown>
    )
  }

  const THead = {
    email: 'Customer',
    company_title: customCompanyDropdown(),
    sum: 'Sum',
    created_at: 'Timestamp',
  }

  return (
    <tr>
      <td className='p-0 m-0' colSpan={colSpan || 10000}>
        <div className='p-4 bg-bluegray-50 shadow-inner w-full'>
          <div className='overflow-x-auto'>
            <table className='w-full border whitespace-nowrap'>
              <thead className='text-left bg-white'>
                <tr className='divide-x border-b border-bluegray-200'>
                  {Object.entries(THead).map(([key, value]) => (
                    <th key={key} scope='col' className='whitespace-nowrap'>
                      {value}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {
                  // @ts-ignore
                  // eslint-disable-next-line react/destructuring-assignment
                  tbody?.length ? tbody.map(({ className, ...restObj }) => (
                    <tr
                      key={`table-${getUniqueId()}`}
                      className={`divide-x border-b border-bluegray-200 ${className}`}
                    >
                      {Object.entries(restObj).map(([key, value]) => {
                            const data: any = typeof value === 'string' ? value.split('|||') : value
                            return (
                              <td key={`td${key}`}>
                                {data[0] || data}
                                <div className='text-sm text-bluegray-400'>{data[1]}</div>
                              </td>
                            )
                          })}
                    </tr>
                      ))
                    : tbody && (
                    <tr>
                      <td colSpan={10000} className='text-center'>
                        No available items
                      </td>
                    </tr>
                      )
                }
              </tbody>
            </table>
          </div>
        </div>
      </td>
    </tr>
  )
}

export const TableForAudit: React.FC<TableProps> = ({
  thead,
  tbody,
  tbodyChild,
  tableCellWidth,
  no_borders_style,
  callBack,
  loading,
  childIndex,
  setChildIndex,
}) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { companies, audit } = useSelector((store: AppStore) => store)

  const [itemWithKeyWasPressed, setItemWithKeyWasPressed] = useState<string>('')
  const [bodyObjById, setBodyObjById] = useState<Map<number, TTendersAudit>>(new Map<number, TTendersAudit>())
  const [selectedCompany, setSelectedCompany] = useState<Map<number, number>>(new Map<number, number>())
  const [availableCompanies, setAvailableCompanies] = useState<Map<number, TCompanies[]>>(
    new Map<number, TCompanies[]>()
  )

  const selectedKey = useRef<{ sort: 'down' | 'up'; sortBy: string } | null>(null)

  useEffect(() => {
    childIndex.forEach((idItem) => {
      if (!bodyObjById?.get(idItem)) {
        const data = audit.data?.find((item) => item.item_id === idItem)
        if (data) {
          setBodyObjById((prev) => getSingleMapData(prev, { idItem, data }))
          setAvailableCompanies((prev) =>
            getSingleMapData(prev, {
              idItem,
              data: companies.data?.companies?.filter((company) => data.companies_ids?.some((id) => id === company.id)),
            })
          )
        }
      }
    })
  }, [childIndex])

  useEffect(() => {
    setChildIndex([])
  }, [location.pathname])

  useEffect(() => {
    if (!companies.data) dispatch(GetCompanies.request())
  }, [])

  const onCompanyChose = (idItem: number, companyId: number) => {
    dispatch(
      GetAuditByTendersChild.request({ idItem, companyId: companyId !== -1 ? [companyId] : undefined, limit: 10000 })
    )
    setSelectedCompany((prev: any) =>
      getSingleMapDataForce(prev, {
        idItem,
        data: companyId,
      })
    )
  }

  return (
    <div className='overflow-x-auto relative' style={!no_borders_style ? { minHeight: '120vh' } : {}}>
      <table className={`w-full ${tableCellWidth && 'table-fixed'} border whitespace-nowrap mb-2`}>
        <thead className={`${no_borders_style ? 'bg-white capitalize' : 'bg-gray-50'} text-left`}>
          <tr className='divide-x border-b border-bluegray-200'>
            {Object.entries(thead).map(([key, value], index) => {
              const object = typeof value !== 'string' ? value : null
              let style: CSSProperties = tableCellWidth && tableCellWidth[index] ? { width: tableCellWidth[index] } : {}
              if (!object) style = { ...style, overflow: 'auto' }
              return (
                <th
                  key={`th${key}`}
                  scope='col'
                  style={style}
                  className={`${no_borders_style && 'border-transparent'} whitespace-nowrap`}
                >
                  {object || (
                    <button
                      type='button'
                      className={`disabled:opacity-100 ${selectedKey.current?.sortBy === key ? '!font-bold' : ''}`}
                      style={{ font: 'inherit' }}
                      onClick={() => {
                        setItemWithKeyWasPressed(key)
                        if (callBack) {
                          callBack(key)
                          selectedKey.current = {
                            sort: selectedKey.current?.sortBy===key&&selectedKey.current?.sort === 'down' ? 'up' : 'down',
                            sortBy: key,
                          }
                        }
                      }}
                      disabled={itemWithKeyWasPressed === key && loading}
                    >
                      {value}
                      {!no_borders_style &&
                        key !== 'action' &&
                        key !== 'roles_categories' &&
                        key !== 'message_title' &&
                        key !== 'preferred_delivery_method' && (
                          <Sort sort={selectedKey.current?.sortBy === key ? selectedKey.current?.sort : undefined} />
                        )}
                    </button>
                  )}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {tbody?.length !== 0 ? (
            tbody?.map(({ id, ...objProps }, index) => (
              <React.Fragment key={`React.Fragment${getUniqueId()}`}>
                <tr
                  className={`${
                    no_borders_style && index % 2 === 1 && 'bg-white'
                  } divide-x border-b border-bluegray-200`}
                >
                  {Object.entries(objProps).map(([key, value]) => {
                    const data = typeof value === 'string' ? value.split('|||') : value
                    return (
                      <td key={`td${key}`} className={`${no_borders_style && 'border-transparent'} relative`}>
                        <div
                          onClick={() =>
                            data.type === 'button' &&
                            setChildIndex((prev) =>
                              childIndex.some((ind) => ind === id) ? prev.filter((ind) => ind !== id) : [...prev, id]
                            )}
                          style={{ overflow: 'auto' }}
                        >
                          {data[0] || data}
                          <div className='text-sm text-bluegray-400'>{data[1]}</div>
                        </div>
                      </td>
                    )
                  })}
                </tr>
                {childIndex.some((ind) => ind === id) &&
                  ChildrenTable(
                    tbodyChild?.get(id),
                    bodyObjById.get(id),
                    selectedCompany.get(id),
                    availableCompanies.get(id),
                    onCompanyChose,
                    tableCellWidth?.length
                  )}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan={tableCellWidth?.length || 10000} height={250} className='text-center'>
                No available items
              </td>
            </tr>
          )}
          {loading && (
            <tr className='justify-center'>
              <td
                height={50}
                width={tableCellWidth?.reduce((sum, value) => sum + value)}
                colSpan={tableCellWidth?.length}
              >
                <div className='flex'>
                  <LoaderPoints className='m-auto' />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
