import { combineReducers } from 'redux'
import { SignReducer } from './auth/reducer'
import { UserReducer } from './user/reducer'
import { MessageMethodsReducer } from './messageMethods/reducer'
import { TermsReducer } from './terms/reducer'
import { AdminMapping } from './admin/settings/mapping/reducer'
import { AdminUsersReducer } from './admin/users/reducer'
import { CategoriesReducer } from './categories/reducer'
import { RolesReducer } from './admin/roles/reducer'
import { CompaniesReducer } from './admin/companies/reducer'
import { TendersReducer } from './admin/tenders/reducer'
import { BidsByItemReducer } from './admin/tenders/bidsByItem/reducer'
import { BidsByBiddenReducer } from './admin/tenders/bidsByBidden/reducer'
import { AllBidsByTenderReducer } from './admin/tenders/allBidsByTender/reducer'
import { TenderResultsReducer } from './tenderResults/reducer'
import { BuyNowPurchasesReducer } from './admin/tenders/buyNowPurchases/reducer'
import { FinalizeTendersReducer } from './admin/tenders/finalizeTenders/reducer'
import { DashboardReducer } from './dashboard/reducer'
import { CmsReducer } from './admin/settings/cms/reducer'
import { GalleryReducer } from './gallery/reducer'
import { ItemActionsReducer } from './gallery/tendersActions/reducer'
import { CurrentBidsReducer } from './gallery/tenderSummary/reducer'
import { AuditByTenderReducer } from './admin/tenders/audit/reducer'
import { GlobalReducer } from './globalState/reducer'
import { EmailLogsReducer } from './admin/settings/emailLogs/reducer'
import { UpcomingTendersReducer } from './admin/tenders/upcomingTenders/reducer'
import { CurrentTimeReducer } from './currentTime/reducer'
import { MediaManagementReducer } from './admin/settings/mediaManagement/reducer'
import { DiamondVideoReducer } from './diamondVideo/reducer'
import { WorthyStateReducer } from './worthyState/reducer'
import { AdminCompaniesNSReducer } from './admin/companiesNS/reducer'
import { AddressFormsReducer } from './addressFormsState/reducer'

const appReducer = combineReducers({
  signIn: SignReducer,
  userData: UserReducer,
  messageMethods: MessageMethodsReducer,
  terms: TermsReducer,
  settingMapping: AdminMapping,
  adminUsers: AdminUsersReducer,
  categories: CategoriesReducer,
  roles: RolesReducer,
  companies: CompaniesReducer,
  tenders: TendersReducer,
  bidsByItem: BidsByItemReducer,
  bidsByBidden: BidsByBiddenReducer,
  allBidsByTender: AllBidsByTenderReducer,
  tenderResults: TenderResultsReducer,
  buyNowPurchases: BuyNowPurchasesReducer,
  finalizeTenders: FinalizeTendersReducer,
  dashboard: DashboardReducer,
  cms: CmsReducer,
  gallery: GalleryReducer,
  itemActions: ItemActionsReducer,
  currentBids: CurrentBidsReducer,
  audit: AuditByTenderReducer,
  global: GlobalReducer,
  emailLogs: EmailLogsReducer,
  upcomingTenders: UpcomingTendersReducer,
  currentTime: CurrentTimeReducer,
  mediaManagement: MediaManagementReducer,
  diamondVideo: DiamondVideoReducer,
  worthyState: WorthyStateReducer,
  adminCompaniesNS: AdminCompaniesNSReducer,
  addressFormsState: AddressFormsReducer
})

const rootReducer = (state: any, action: any) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === '@@auth/DELETE_SIGN_OUT_S') return appReducer(undefined, action)
  return appReducer(state, action)
}


export default rootReducer
