import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { callApi } from '../../../../utils/callApi'
import { API_ROUTES } from '../../../../utils/apiRoutes'
import * as Actions from './actions'
import { ActionTypes, TSettingMappingDiamondsObject, TSettingMappingMessage, TSettingMappingOptions } from './types'
import { SnackBar } from '../../../../components/ui/SnackBar'

const { addSnackBar } = SnackBar()

function* getDiamondsShapeWorker(action: ReturnType<typeof Actions.GetDiamondsShape.request>) {
  const { callBack, limit, offset } = action.payload as Actions.TypeGetDiamondsShapeR
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.mappingDiamondsShapes}?Limit=${limit || 20}&Offset=${offset || 0}`,
    })) as TSettingMappingDiamondsObject[]
    
    if (offset !== 0) yield put(Actions.GetDiamondsShape.success(data))
    else yield put(Actions.GetDiamondsShapeWith0Offset.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetDiamondsShape.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getDiamondsColorWorker(action: ReturnType<typeof Actions.GetDiamondsColor.request>) {
  const { callBack, limit, offset } = action.payload as Actions.TypeGetDiamondsColorR
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.mappingDiamondsColors}?Limit=${limit || 20}&Offset=${offset || 0}`,
    })) as TSettingMappingDiamondsObject[]
    
    if (offset !== 0) yield put(Actions.GetDiamondsColor.success(data))
    else yield put(Actions.GetDiamondsColorWith0Offset.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetDiamondsColor.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getDiamondsClarityWorker(action: ReturnType<typeof Actions.GetDiamondsClarity.request>) {
  const { callBack, limit, offset } = action.payload as Actions.TypeGetDiamondsClarityR
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.mappingDiamondsClarity}?Limit=${limit || 20}&Offset=${offset || 0}`,
    })) as TSettingMappingDiamondsObject[]
    
    if (offset !== 0) yield put(Actions.GetDiamondsClarity.success(data))
    else yield put(Actions.GetDiamondsClarityWith0Offset.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetDiamondsClarity.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getDiamondsFluorescenceWorker(action: ReturnType<typeof Actions.GetDiamondsFluorescence.request>) {
  const { callBack, limit, offset } = action.payload as Actions.TypeGetDiamondsFluorescenceR
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.mappingDiamondsFluorescence}?Limit=${limit || 20}&Offset=${offset || 0}`,
    })) as TSettingMappingDiamondsObject[]
    
    if (offset !== 0) yield put(Actions.GetDiamondsFluorescence.success(data))
    else yield put(Actions.GetDiamondsFluorescenceWith0Offset.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetDiamondsFluorescence.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getDiamondsCutPolishSymmetryWorker(action: ReturnType<typeof Actions.GetDiamondsCutPolishSymmetry.request>) {
  const { callBack, limit, offset } = action.payload as Actions.TypeGetDiamondsCutPolishSymmetryR
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.mappingDiamondsCutPolishSymmetry}?Limit=${limit || 20}&Offset=${offset || 0}`,
    })) as TSettingMappingDiamondsObject[]
    
    if (offset !== 0) yield put(Actions.GetDiamondsCutPolishSymmetry.success(data))
    else yield put(Actions.GetDiamondsCutPolishSymmetryWith0Offset.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetDiamondsCutPolishSymmetry.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getOptionsByTypeWorker(action: ReturnType<typeof Actions.GetOptionsByType.request>) {
  const { callBack, type_of_option_id } = action.payload as Actions.TypeGetOptionsByTypeR
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.mappingOptionsByType}?type_of_option_id=${type_of_option_id}`,
    })) as TSettingMappingOptions[]
    
    yield put(Actions.GetOptionsByType.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetOptionsByType.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* putDiamondsDictionaryWorker(action: ReturnType<typeof Actions.PutDiamondsDictionary.request>) {
  const { callBack, options_group_id, option_id } = action.payload as Actions.TypePutDiamondsDictionaryR
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'put',
      path: `${API_ROUTES.putDiamondsDictionary}?options_group_id=${options_group_id}&option_id=${option_id}`,
    })) as TSettingMappingMessage
    
    yield put(Actions.PutDiamondsDictionary.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.PutDiamondsDictionary.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (success) addSnackBar('The selection has been saved', success)
    else addSnackBar(data?.message || '', success)
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.GET_DIAMONDS_SHAPE_R, getDiamondsShapeWorker)
  yield takeEvery(ActionTypes.GET_DIAMONDS_COLOR_R, getDiamondsColorWorker)
  yield takeEvery(ActionTypes.GET_DIAMONDS_CLARITY_R, getDiamondsClarityWorker)
  yield takeEvery(ActionTypes.GET_DIAMONDS_FLUORESCENCE_R, getDiamondsFluorescenceWorker)
  yield takeEvery(ActionTypes.GET_DIAMONDS_CUT_POLISH_SYMMETRY_R, getDiamondsCutPolishSymmetryWorker)
  yield takeEvery(ActionTypes.GET_OPTIONS_BY_TYPE_R, getOptionsByTypeWorker)
  yield takeEvery(ActionTypes.PUT_DIAMONDS_DICTIONARY_R, putDiamondsDictionaryWorker)
}

export default function* adminMappingSaga() {
  yield all([fork(watchRequest)])
}