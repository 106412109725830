import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { ENVIRONMENT, SCROLL, SCROLL_GALLERY, TOKEN } from '../utils/consts'
import { useSocket } from '../hooks/useSocket'
import { ROUTES } from '../utils/routes'
import { AppStore } from '../store/applicationState'
import { GetUser } from '../store/user/actions'
import { useSocketForPersRecommend } from '../hooks/useSocketForPersRecommend'

interface LoggedInWrapperProps {
  children: React.ReactNode
}

export const LoggedInWrapper: React.FC<LoggedInWrapperProps> = ({ children }) => {
  const paddingTop = sessionStorage.getItem(TOKEN.access) ? 'pt-20' : 'pt-10'

  const history = useHistory()
  const dispatch = useDispatch()
  const { userData } = useSelector((store: AppStore) => store)
  useSocket()
  useSocketForPersRecommend()

  useEffect(() => {
    let timer: any
    if (!userData.data) {
      timer = setTimeout(() => {
        dispatch(GetUser.request())
      }, 1000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [userData.data])

  useEffect(() => {
    if (userData.data?.email) {
      if (ENVIRONMENT && ['development', 'stage', 'production'].includes(ENVIRONMENT)) {
        // @ts-ignore
        window.dataLayer = window.dataLayer || []
        // @ts-ignore
        window.dataLayer.push({
          event: 'login',
          email: userData.data.email,
          customerId: userData.data.id,
          displayName: `${userData.data.last_name ? userData.data.last_name : ''} ${
            userData.data.first_name ? userData.data.first_name : ''
          }`,
        })
      }
      Sentry.setUser({ email: userData.data.email })
    }
    return () => {
      Sentry.configureScope((scope) => scope.setUser(null))
    }
  }, [userData.data?.email])

  useEffect(() => {
    let interval: any
    const onPopState = () => {
      let intervalCount = 0
      clearInterval(interval)
      interval = setInterval(() => {
        const scrollY = Number(sessionStorage.getItem(SCROLL))
        const scrollGaleeryY = Number(sessionStorage.getItem(SCROLL_GALLERY))
        intervalCount += 1 // clear in 10 sec
        if (window.location.pathname.search(ROUTES.tenders) !== -1) {
          if (
            !scrollGaleeryY ||
            Number.isNaN(scrollGaleeryY) ||
            window.scrollY === scrollGaleeryY ||
            intervalCount > 50
          ) {
            sessionStorage.removeItem(SCROLL_GALLERY)
            clearInterval(interval)
            return
          }
          window.scrollTo({ top: scrollGaleeryY })
        } else {
          if (!scrollY || Number.isNaN(scrollY) || window.scrollY === scrollY || intervalCount > 50) {
            sessionStorage.removeItem(SCROLL)
            clearInterval(interval)
            return
          }
          window.scrollTo({ top: scrollY })
        }
      }, 200)
    }

    const unListen = history.listen((loc, action) => {
      if (action === 'PUSH') {
        sessionStorage.setItem(SCROLL, `${window.scrollY}`)
        sessionStorage.setItem(SCROLL_GALLERY, `${window.scrollY}`)
        if (loc.pathname === ROUTES.userAccount || loc.pathname.search(ROUTES.tenders) !== -1)
          window.scrollTo({ top: 0 })
      }
    })
    window.addEventListener('popstate', onPopState)

    return () => {
      unListen()
      clearInterval(interval)
      window.removeEventListener('popstate', onPopState)
    }
  }, [])

  return (
    <>
      <Header />
      <main className={`${paddingTop} page-content`}>{children}</main>
      <Footer />
    </>
  )
}
