import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from '../../components/ui/adminComponents/Input'
import { Button } from '../../components/ui/adminComponents/Button'
import { Table } from '../../components/ui/adminComponents/Table'
import { AppStore } from '../../store/applicationState'
import {
  DeleteBuyNow,
  GetBuyNowPurchases,
  GetBuyNowPurchasesCsv,
  GetTenderStatsForBuyNowPurchases,
  MakeSalesOrdersAndInvoices,
  changeStatusSalesOrdersAndInvoicesBuyNow,
} from '../../store/admin/tenders/buyNowPurchases/actions'
import { getBuyNowPurchasedParsed } from '../../utils/tendersUtils/tenderDataHelper'
import { downloadFile } from '../../utils/FileUtils'
import { searchProps, useSearch } from '../../hooks/useSearch'
import { paginationProps, usePagination } from '../../hooks/usePagination'
import { PopUp } from '../../components/ui/PopUp'
import { TCsv } from '../../store/admin/tenders/buyNowPurchases/types'
import { useAddItemPopup } from '../../hooks/useAddItemPopup'
import { TenderStatusType } from '../../utils/tendersUtils/tenderStatusHelper'
import { LoaderPoints } from '../../components/ui/LoaderPoints'
import { Checkbox } from '../../components/ui/Checkbox'

const buyNowPurchasesThead = {
  shipping_owner: 'Own',
  stock_number: 'Stock #',
  title: 'Item',
  email: 'Customer',
  company_title: 'Company',
  asking_price: 'BUY NOW Price',
  transaction_date: 'Transaction Date',
}

const tableCellWidth = [155, 155, 250, 270, 150, 195, 200, 120, 110]

interface BuyNowPurchasesProps {
  currentTenderId: number
  tenderStatus: TenderStatusType | null
}

export const BuyNowPurchases: React.FC<BuyNowPurchasesProps> = ({ currentTenderId, tenderStatus }) => {
  const limitAmount = 400
  const initialParams = {
    limit: limitAmount,
    offset: 0,
    search: undefined,
    sort: '',
    sortBy: '',
  }

  const dispatch = useDispatch()
  const { buyNowPurchases, tenders, userData } = useSelector((store: AppStore) => store)
  const { add } = useAddItemPopup()

  const [params, setParams] = useState<searchProps | paginationProps>(initialParams)
  const [dispatchTime, setDispatchTime] = useState(0)
  const [buyNowItemId, setBuyNowItemId] = useState<number>(-1)
  // const [invoiceItemId, setInvoiceItemId] = useState<number>(-1)
  const [childIndex, setChildIndex] = useState<number[]>([])
  // const [processedItems, setProcessedItems] = useState<number[]>([])
  const [isProcessing, setIsProcessing] = useState<boolean>(false)

  const [headIsChecked, setHeadIsChecked] = useState<boolean>(false)
  const [bodyAreChecked, setBodyAreChecked] = useState<Set<number>>(new Set())
  const [showPopup, setShowPopup] = useState<boolean>(false)

  const [tooltipVisible, setTooltipVisible] = useState<number | null>(null)

  const didMount = useRef(false)

  usePagination(buyNowPurchases.data, setParams, limitAmount)
  useSearch(limitAmount, params, setParams, setDispatchTime)

  useEffect(() => {
    dispatch(GetTenderStatsForBuyNowPurchases.request({ idTender: currentTenderId }))
  }, [currentTenderId])

  useEffect(() => {
    if (tenders.updateTenderNow) dispatch(GetTenderStatsForBuyNowPurchases.request({ idTender: currentTenderId }))
  }, [tenders.updateTenderNow])

  useEffect(() => {
    if (didMount.current) {
      setDispatchTime((prev) => prev + 1)
      setParams(initialParams)
      setHeadIsChecked(false)
      setBodyAreChecked(new Set())
      setShowPopup(false)
    }
    didMount.current = true
  }, [currentTenderId])

  useEffect(() => {
    dispatch(
      GetBuyNowPurchases.request({
        idTender: currentTenderId,
        offset: params.offset,
        limit: params.limit,
        search: params.search,
        sortBy: params.sortBy,
        sort: params.sort,
      })
    )
  }, [params.offset, params.limit, params.sortBy, params.sort, dispatchTime])

  useEffect(() => {
    if (tenders.updateTenderNow) {
      if (params.offset === 0) setDispatchTime((prev) => prev + 1)
      else setParams((prev) => ({ ...prev, offset: 0, limit: prev.limit + prev.offset }))
    }
  }, [tenders.updateTenderNow])

  useEffect(() => {
    if (!headIsChecked) setBodyAreChecked(new Set())
  }, [headIsChecked])

  useEffect(() => {
    if (buyNowPurchases.data && headIsChecked) {
      const activeItems = buyNowPurchases.data
        .filter((item) => item.ns_processing_status === '' || item.ns_processing_status === 'failed' )
        .map((item) => item.item_id)

      setBodyAreChecked(new Set(activeItems))
    }
  }, [headIsChecked, buyNowPurchases.data])

  /// /////////////////////////////////////////////////////////////////////////////////
  const callBack = (sortBy: string) => {
    if (sortBy === 'action') return

    let value: { sortBy: string; sort: 'ASC' | 'DESC' }
    if (sortBy !== params.sortBy || params.sort === 'DESC') value = { sortBy, sort: 'ASC' }
    else value = { sortBy, sort: 'DESC' }

    setParams((prev) => ({ ...prev, offset: 0, limit: limitAmount, sortBy: value.sortBy, sort: value.sort }))
  }

  const deleteBuyNow = (idItem: number) => {
    dispatch(
      DeleteBuyNow.request({
        idItem,
        callBack: (success) => {
          if (success) {
            if (params.offset === 0) setDispatchTime((prev) => prev + 1)
            else setParams((prev) => ({ ...prev, offset: 0, limit: prev.offset + limitAmount }))
            dispatch(GetTenderStatsForBuyNowPurchases.request({ idTender: currentTenderId }))
          }
        },
      })
    )
    setBuyNowItemId(-1)
  }

  const onBodyAreChecked = (itemId: number) => {
    setBodyAreChecked((prev) => {
      const updatedSet = new Set(prev)
      if (updatedSet.has(itemId)) {
        updatedSet.delete(itemId)
      } else {
        updatedSet.add(itemId)
      }
      return updatedSet
    })
  }

  const buyNowPurchasesTable = useMemo(() => {
    const tHead =
      userData.isExecutiveAdmin && tenderStatus === 'active'
        ? {
            ...buyNowPurchasesThead,
            action: 'Actions',
            invoiceCheckbox: (
              <>
                <div className='flex gap-1'>
                  <Checkbox
                    label=''
                    isChecked={headIsChecked}
                    setIsChecked={setHeadIsChecked}
                    customId='select-all-users'
                  />
                  Invoice
                </div>
              </>
            ),
          }
        : buyNowPurchasesThead

    return buyNowPurchases.data ? (
      <Table
        thead={tHead}
        tbody={getBuyNowPurchasedParsed(
          buyNowPurchases.data,
          setBuyNowItemId,
          tenderStatus === 'active',
          userData.isExecutiveAdmin,
          dispatch,
          add,
          tenderStatus,
          isProcessing,
          bodyAreChecked,
          onBodyAreChecked,
          setTooltipVisible,
          tooltipVisible
        )}
        callBack={callBack}
        childIndex={childIndex}
        setChildIndex={setChildIndex}
        tableCellWidth={userData.isExecutiveAdmin ? tableCellWidth : tableCellWidth.slice(0, tableCellWidth.length - 1)}
      />
    ) : null
  }, [buyNowPurchases.data, userData.isExecutiveAdmin, tenderStatus, isProcessing, bodyAreChecked, tooltipVisible])

  const downloadBuyNowPurchasesCsv = () => {
    dispatch(
      GetBuyNowPurchasesCsv.request({
        idTender: currentTenderId,
        callBack: (success, data: TCsv) => {
          if (success) downloadFile('text/csv', atob(data.file), data.file_name)
        },
      })
    )
  }

  const makeInvoice = () => {
    setIsProcessing(true)
    const idItems = Array.from(bodyAreChecked)
    dispatch(
      MakeSalesOrdersAndInvoices.request({
        idItems,
        callBack: (success) => {
          if (success) {
            dispatch(
              changeStatusSalesOrdersAndInvoicesBuyNow({
                ids: idItems,
                ns_processing_status: 'in_progress',
                error_msg: ''
              })
            )
          }
        },
      })
    )
    setHeadIsChecked(false)
    setBodyAreChecked(new Set())
    setIsProcessing(false)
    setShowPopup(false)
  }


  return (
    <>
      <div className='flex justify-between gap-2 lg:items-center mb-4 flex-col lg:flex-row'>
        <div className='flex w-full flex-col md:flex-row md:justify-between lg:justify-start lg:w-[40%] lg:gap-10 mb-6 lg:mb-0'>
          <div className='w-full md:w-1/3 lg:w-1/2 lg:mb-0 mb-6 md:mb-0'>
            <Input
              placeholder='Search...'
              value={params.search || ''}
              onChange={(e) => setParams((prev) => ({ ...prev, search: e.target.value }))}
            />
          </div>
          <div className='space-y-3 smallMobile:space-y-0 smallMobile:space-x-3 flex flex-col smallMobile:flex-row'>
            <Button text='Purchases CSV' variant='bidsCSV' onClick={downloadBuyNowPurchasesCsv} />
          </div>
        </div>
        <div className='border text-xs w-full lg:w-1/2 overflow-x-auto'>
          <div
            className='font-semibold text-bluegray-900 border-b border-bluegray-200 pl-3 flex'
            style={{ minWidth: '400px' }}
          >
            Summary
          </div>
          <div className='flex justify-between items-center divide-x w-full' style={{ minWidth: '400px' }}>
            <div className='px-3'>
              Sold:
              {` ${buyNowPurchases.tenderStats?.sold_count}`}
            </div>
            <div className='px-3'>
              % sold:
              {buyNowPurchases.tenderStats &&
                ` ${(
                  (buyNowPurchases.tenderStats.sold_count / (buyNowPurchases.tenderStats.total_count || 100)) *
                  100
                ).toFixed(1)}`}
            </div>
            <div className='px-3'>
              Total:
              {buyNowPurchases.tenderStats &&
                ` $${buyNowPurchases.tenderStats.total_buy_now / 100}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </div>
          </div>
        </div>
        <div className='lg:w-[125px]'>
          {bodyAreChecked.size > 0 && (
            <div className='flex justify-end pl-1'>
              <Button text='Generate invoice' variant='invoice' onClick={() => setShowPopup(true)} />
            </div>
          )}
        </div>
      </div>
      <div className='relative'>
        {buyNowPurchasesTable}
        {!!buyNowPurchasesTable && buyNowPurchases.loading && (
          <div className='absolute left-0 right-0 -bottom-6 flex justify-center'>
            <LoaderPoints className='m-auto' />
          </div>
        )}
      </div>
      {buyNowItemId !== -1 && (
        <PopUp
          onSubmit={() => deleteBuyNow(buyNowItemId)}
          onCancel={() => setBuyNowItemId(-1)}
          className='left-1/2 transform -translate-x-1/2'
        >
          Do you want to delete Buy Now? Are you sure?
        </PopUp>
      )}
      {showPopup && (
        <PopUp
          onSubmit={makeInvoice}
          onCancel={() => setShowPopup(false)}
          className='left-1/2 transform -translate-x-1/2'
        >
          Please confirm invoice creation for the selected items.
        </PopUp>
      )}
    </>
  )
}
