export const ByCountOfBids = [
  {
    title: 'Count of Bids High to Low',
    value: 'count_of_bids_high_to_low',
    sortBy: 'count_of_bids',
    sort: 'DESC',
  },
  {
    title: 'Count of Bids Low to High',
    value: 'count_of_bids_low_to_high',
    sortBy: 'count_of_bids',
    sort: 'ASC',
  },
]

export const DiamondOptions = [
  {
    title: 'Carat Weight High to Low',
    value: 'carat_weight_high_to_low',
    sortBy: 'weight',
    sort: 'DESC',
  },
  {
    title: 'Carat Weight Low to High',
    value: 'carat_weight_low_to_high',
    sortBy: 'weight',
    sort: 'ASC',
  },
  {
    title: 'Color High to Low',
    value: 'color_high_to_low',
    sortBy: 'color_mapping',
    sort: 'DESC',
  },
  {
    title: 'Color Low to High',
    value: 'color_low_to_high',
    sortBy: 'color_mapping',
    sort: 'ASC',
  },
  {
    title: 'Clarity High to Low',
    value: 'clarity_high_to_low',
    sortBy: 'clarity_mapping',
    sort: 'DESC',
  },
  {
    title: 'Clarity Low to High',
    value: 'clarity_low_to_high',
    sortBy: 'clarity_mapping',
    sort: 'ASC',
  },
  {
    title: 'Price High to Low',
    value: 'price_high_to_low',
    sortBy: 'asking_price',
    sort: 'DESC',
  },
  {
    title: 'Price Low to High',
    value: 'price_low_to_high',
    sortBy: 'asking_price',
    sort: 'ASC',
  },
]

export const WatchesOptions = [
  {
    title: 'Price High to Low',
    value: 'price_high_to_low',
    sortBy: 'asking_price',
    sort: 'DESC',
  },
  {
    title: 'Price Low to High',
    value: 'price_low_to_high',
    sortBy: 'weight',
    sort: 'ASC',
  },
  {
    title: 'Brand A-Z',
    value: 'brand_a_z',
    sortBy: 'brand',
    sort: 'ASC',
  },
  {
    title: 'Brand Z-A',
    value: 'brand_z_a',
    sortBy: 'brand',
    sort: 'DESC',
  },
]

export const JewelryOptions = [
  {
    title: 'Price High to Low',
    value: 'price_high_to_low',
    sortBy: 'asking_price',
    sort: 'DESC',
  },
  {
    title: 'Price Low to High',
    value: 'price_low_to_high',
    sortBy: 'asking_price',
    sort: 'ASC',
  },
  {
    title: 'Brand A-Z, Unbranded',
    value: 'brand_a_z',
    sortBy: 'brand',
    sort: 'ASC',
  },
  {
    title: 'Brand Unbranded, Z-A',
    value: 'brand_z_a',
    sortBy: 'brand',
    sort: 'DESC',
  },
]

export const ERROR_TEXT = {
  shouldBeNumber: 'Please enter your bid as a whole number',
  bidHigher: 'Your bid must be below the buy now price.',
  startPriceHigher: 'Please enter your bid higher or equal to Starting price',
  shouldBeBetween: 'The value must be between 0 and 99.99%',
  tryLater: 'please try later or contact with administrator',
  aboveZero: 'Your bid should be above zero',
}
