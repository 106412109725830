import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetCompanies } from '../store/admin/companies/actions'
import { GetCategories } from '../store/categories/actions'
import { AppStore } from '../store/applicationState'
import { searchProps } from './useSearch'
import { GetRoles } from '../store/admin/roles/actions'
import { addNewParams } from '../utils/helpers'

interface paramsProps {
  limit: number
  offset: number

  [x: string]: any
}

export interface filterForUsersProps {
  limitAmount: number
  params: paramsProps
  setCallback: (value: ((prevState: paramsProps) => paramsProps) | paramsProps) => void
  selectedRoles: number[]
  setSelectedRoles: (value: number[]) => void
  selectedFullAccessCategories: number[]
  setSelectedFullAccessCategories: (value: number[]) => void
  selectedViewOnlyCategories: number[]
  setSelectedViewOnlyCategories: (value: number[]) => void
  selectedCompanies: number[]
  setSelectedCompanies: (value: (prev: number[]) => number[]) => void
  setDispatchTime: (value: ((prevState: number) => number) | number) => void
  isRendered?: any
}

export const useFilterForUsers = ({
  limitAmount,
  params,
  setCallback,
  selectedRoles,
  // setSelectedRoles,
  selectedFullAccessCategories,
  // setSelectedFullAccessCategories,
  selectedViewOnlyCategories,
  // setSelectedViewOnlyCategories,
  selectedCompanies,
  setSelectedCompanies,
  setDispatchTime,
  isRendered,
}: filterForUsersProps) => {
  const dispatch = useDispatch()
  const { roles, categories, companies } = useSelector((store: AppStore) => store)

  const dataIsLoadedAtFirst = useRef<any>(null)

  useEffect(() => {
    if (!roles.data) dispatch(GetRoles.request())
    if (!companies.data) dispatch(GetCompanies.request())
    if (!categories.data) dispatch(GetCategories.request())
  }, [])

  useEffect(() => {
    if (/* roles.data &&  categories.data && */ companies.data) {
      // setSelectedRoles(roles.data.map(item => item.role_id))
      const defaultCompanies =
        companies.data && companies.data!.companies ? companies.data.companies.map((item) => item.id) : []
      setSelectedCompanies((prev) => (prev.length ? [...prev] : defaultCompanies))
      // setSelectedCategories(categories.data.map(item => item.category_id))
      dataIsLoadedAtFirst.current = true
    }
  }, [/* roles.data, categories.data, */ companies.data])

  useEffect(() => {
    if ((isRendered !== undefined && isRendered.current !== true) || dataIsLoadedAtFirst.current === null)
      return () => {}
    if (dataIsLoadedAtFirst.current) {
      dataIsLoadedAtFirst.current = false
      return () => {}
    }

    addNewParams('rolesId', selectedRoles.join('-'))
    addNewParams('fullAccessCategoriesId', selectedFullAccessCategories.join('-'))
    addNewParams('viewOnlyCategoriesId', selectedViewOnlyCategories.join('-'))
    addNewParams('companiesId', selectedCompanies.join('-'))

    const timerId = setTimeout(() => {
      if (params.limit === limitAmount && params.offset === 0) setDispatchTime((prev) => prev + 1)
      else setCallback((prev: searchProps) => ({ ...prev, offset: 0, limit: limitAmount }))
    }, 1000)

    return () => clearTimeout(timerId)
  }, [selectedRoles, selectedFullAccessCategories, selectedCompanies, selectedViewOnlyCategories])
}
