import React from 'react'

export const Submit = () => {
  return (
    <svg width='20' height='15' viewBox='0 0 15 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.49988 8.49956L1.58322 5.58289C1.50695 5.50569 1.41611 5.4444 1.31598 5.40257C1.21585 5.36073 1.10841 5.33919 0.999885 5.33919C0.891364 5.33919 0.783922 5.36073 0.683788 5.40257C0.583654 5.4444 0.49282 5.50569 0.416551 5.58289C0.339351 5.65916 0.278058 5.74999 0.236225 5.85013C0.194392 5.95026 0.172852 6.0577 0.172852 6.16622C0.172852 6.27474 0.194392 6.38219 0.236225 6.48232C0.278058 6.58245 0.339351 6.67329 0.416551 6.74956L3.90822 10.2412C4.23322 10.5662 4.75822 10.5662 5.08322 10.2412L13.9166 1.41623C13.9938 1.33996 14.055 1.24912 14.0969 1.14899C14.1387 1.04885 14.1603 0.941413 14.1603 0.832892C14.1603 0.724371 14.1387 0.61693 14.0969 0.516796C14.055 0.416662 13.9938 0.325828 13.9166 0.249559C13.8403 0.172359 13.7494 0.111066 13.6493 0.069233C13.5492 0.0274 13.4417 0.00585938 13.3332 0.00585938C13.2247 0.00585938 13.1173 0.0274 13.0171 0.069233C12.917 0.111066 12.8262 0.172359 12.7499 0.249559L4.49988 8.49956Z'
        fill='white'
      />
    </svg>
  )
}