import { Actions } from '../../../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../../../types/actions'


/// ///////////////////////////////////////////////////////////////////////
const GetBidsByBiddenActions = new Actions('GET_BIDS_BY_BIDDEN', ActionTypes)
const GetBidsByBiddenWith0OffsetActions = new Actions('GET_BIDS_BY_BIDDEN_WITH_O_OFFSET', ActionTypes)

export interface TypeGetBidsByBiddenR extends TDefRequest {
  idTender: number
  offset?: number
  limit?: number
  search?: string
  sortBy?: string
  sort?: string
}

export const GetBidsByBidden = {
  request: (payload: TypeGetBidsByBiddenR) => GetBidsByBiddenActions.request(payload),
  success: (payload: any) => GetBidsByBiddenActions.success(payload),
  error: (payload: string) => GetBidsByBiddenActions.error(payload),
}
export const GetBidsByBiddenWith0Offset = {
  success: (payload: any) => GetBidsByBiddenWith0OffsetActions.success(payload),
}


/// ///////////////////////////////////////////////////////////////////////
const GetBidsByBiddenChildActions = new Actions('GET_BIDS_BY_BIDDEN_CHILD', ActionTypes)

export interface TypeGetBidsByBiddenChildR extends TDefRequest {
  idTender: number
  idUser: number
}

export const GetBidsByBiddenChild = {
  request: (payload: TypeGetBidsByBiddenChildR) => GetBidsByBiddenChildActions.request(payload),
  success: (payload: any) => GetBidsByBiddenChildActions.success(payload),
  error: (payload: string) => GetBidsByBiddenChildActions.error(payload),
}
