/* eslint-disable react/require-default-props,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,react/destructuring-assignment,react/jsx-one-expression-per-line */
import React, { CSSProperties, useEffect, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { Sort } from '../../../svg/Sort'
import { getUniqueId } from '../../../utils/FileUtils'

interface TableProps {
  thead: object
  tbody: { id?: number; [x: string]: any }[] | null
  theadChild?: object
  tbodyChild?: Map<number, object[]> | null
  tableCellWidth?: number[]
  no_borders_style?: boolean
  callBack?: (sortBy: string) => void
  childIndex: number[]
  setChildIndex: (value: ((prev: number[]) => any) | number[]) => void
  styledReserveNotMet?: boolean
  onDeleteAll?: (id: number) => void
  defaultSort?: { sort: 'down' | 'up'; sortBy: string }
}

const ChildrenTable = (
  thead: object,
  tbody: object[] | undefined,
  colSpan: number | undefined,
  id: number,
  onDelete?: (id: number) => void
) => {
  if (Object.keys(thead).length === 0) return <></>
  return (
    <tr>
      <td className='p-0 m-0' colSpan={colSpan || 10000}>
        <div className='p-4 bg-bluegray-50 shadow-inner w-full'>
          {!!onDelete && (
            <div className='bg-gray-200 p-2 flex justify-end'>
              <button
                type='button'
                onClick={() => onDelete(id)}
                disabled={tbody?.length === 0}
                className='delete-bid ml-auto text-red-500 font-semibold hover:text-red-900'
              >
                Delete all ({tbody?.length}) main medias
              </button>
            </div>
          )}
          <div className='overflow-x-auto'>
            <table className='w-full border whitespace-nowrap'>
              <thead className='text-left bg-white'>
                <tr className='divide-x border-b border-bluegray-200'>
                  {Object.entries(thead).map(([key, value]) => (
                    <th key={key} scope='col' className='whitespace-nowrap'>
                      {value}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {
                  // @ts-ignore
                  // eslint-disable-next-line react/destructuring-assignment
                  tbody?.length ? tbody.map(({ className, ...restObj }) => (
                    <tr
                      key={`table-${getUniqueId()}`}
                      className={`divide-x border-b border-bluegray-200 ${className}`}
                    >
                      {Object.entries(restObj).map(([key, value]) => {
                            const data: any = typeof value === 'string' ? value.split('|||') : value
                            return (
                              <td colSpan={key === 'reserve_price' ? 4 : 1} key={`td${key}`}>
                                {data[0] || data}
                                <div className='text-sm text-bluegray-400'>{data[1]}</div>
                              </td>
                            )
                          })}
                    </tr>
                      ))
                    : tbody && (
                    <tr>
                      <td colSpan={10000} className='text-center'>
                        No available items
                      </td>
                    </tr>
                      )
                }
              </tbody>
            </table>
          </div>
        </div>
      </td>
    </tr>
  )
}

export const Table: React.FC<TableProps> = ({
  thead,
  tbody,
  theadChild,
  tbodyChild,
  tableCellWidth,
  no_borders_style,
  callBack,
  childIndex,
  setChildIndex,
  styledReserveNotMet,
  onDeleteAll,
  defaultSort,
}) => {
  const location = useLocation()

  const selectedKey = useRef<{ sort: 'down' | 'up'; sortBy: string } | null>(defaultSort || null)

  useEffect(() => {
    setChildIndex([])
  }, [location.pathname])

  const tHead = useMemo(() => {
    return (
      <thead className={`${no_borders_style ? 'bg-white capitalize' : 'bg-gray-50'} text-left`}>
        <tr className='divide-x border-b border-bluegray-200'>
          {Object.entries(thead).map(([key, value], index) => {
            const object = typeof value !== 'string' ? value : null
            let style: CSSProperties = tableCellWidth && tableCellWidth[index] ? { width: tableCellWidth[index] } : {}
            if (!object) style = { ...style, overflow: 'auto' }
            return (
              <th
                key={`th${key}`}
                scope='col'
                style={style}
                className={`${no_borders_style && 'border-transparent'} whitespace-nowrap`}
              >
                {object || (
                  <button
                    type='button'
                    className={`disabled:opacity-100 ${selectedKey.current?.sortBy === key ? '!font-bold' : ''}`}
                    style={{ font: 'inherit' }}
                    onClick={() => {
                      if (callBack) {
                        callBack(key)
                        selectedKey.current = {
                          sort: selectedKey.current?.sortBy===key&&selectedKey.current?.sort === 'down' ? 'up' : 'down',
                          sortBy: key,
                        }
                      }
                    }}
                  >
                    {value}
                    {!no_borders_style &&
                      !!callBack &&
                      key !== 'action' &&
                      key !== 'checkbox' &&
                      key !== 'action2' &&
                      key !== 'roles_categories' &&
                      key !== 'message_title' &&
                      key !== 'preferred_delivery_method' &&
                      key !== 'email_recipient' &&
                      key !== 'email_subject' &&
                      key !== 'email_event' &&
                      key !== 'email_tender_id' &&
                      key !== 'email_timestamp' &&
                      key !== 'image' && (
                        <Sort sort={selectedKey.current?.sortBy === key ? selectedKey.current?.sort : undefined} />
                      )}
                  </button>
                )}
              </th>
            )
          })}
        </tr>
      </thead>
    )
  }, [thead, callBack])

  const tBody = useMemo(() => {
    return (
      <tbody>
        {tbody?.length !== 0 ? (
          tbody?.map(({ id, ...objProps }, index) => (
            <React.Fragment key={`React.Fragment${getUniqueId()}`}>
              <tr
                className={`${
                  no_borders_style && index % 2 === 1 && 'bg-white'
                } divide-x border-b border-bluegray-200 ${
                  styledReserveNotMet && objProps.wining_bid === 'Reserve Not Met' && 'bg-amber-50'
                }`}
              >
                {Object.entries(objProps).map(([key, value]) => {
                  const data = typeof value === 'string' ? value.split('|||') : value
                  return (
                    <td key={`td${key}`} className={`${no_borders_style && 'border-transparent'} relative`}>
                      <div
                        onClick={() =>
                          data.type === 'button' &&
                          setChildIndex((prev) =>
                            childIndex.some((ind) => ind === id) ? prev.filter((ind) => ind !== id) : [...prev, id]
                          )}
                        style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                      >
                        {data[0] || data}
                        <div className='text-sm text-bluegray-400'>{data[1]}</div>
                      </div>
                    </td>
                  )
                })}
              </tr>
              {theadChild &&
                tbodyChild &&
                id &&
                childIndex.some((ind) => ind === id) &&
                ChildrenTable(theadChild, tbodyChild.get(id), tableCellWidth?.length, id, onDeleteAll)}
            </React.Fragment>
          ))
        ) : (
          <tr>
            <td colSpan={tableCellWidth?.length || 10000} height={250} className='text-center'>
              No available items
            </td>
          </tr>
        )}
      </tbody>
    )
  }, [tbody, theadChild, tbodyChild, childIndex])

  return (
    <div className='overflow-x-auto relative' style={!no_borders_style ? { minHeight: '120vh' } : {}}>
      <table className={`w-full ${tableCellWidth && 'table-fixed'} border whitespace-nowrap mb-2`}>
        {tHead}
        {tBody}
      </table>
    </div>
  )
}
