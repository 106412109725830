import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from '../../../components/ui/adminComponents/Input'
import { UpdateWinnerByItemId } from '../../../store/admin/tenders/finalizeTenders/actions'
import { GetBidsByItemForTenderClose } from '../../../store/admin/tenders/bidsByItem/actions'
import { getBidsByItemChildCLoseParsed, getBidsByItemCLoseParsed } from '../../../utils/tendersUtils/tenderDataHelper'
import { TableTenderClose } from '../../../components/ui/adminComponents/TableTenderClose'
import { AppStore } from '../../../store/applicationState'
import { ReservePriceData } from '../CloseTender'
import { TBidsByItem } from '../../../store/admin/tenders/bidsByItem/types'
import { useAddItemPopup } from '../../../hooks/useAddItemPopup'
import { searchProps, useSearch } from '../../../hooks/useSearch'
import { paginationProps } from '../../../hooks/usePagination'
import { TenderStatusType } from '../../../utils/tendersUtils/tenderStatusHelper'

interface BidsByItemOnlyWinnersProps {
  currentTenderId: number
  getChildForBidsByItem: (
    idItem: number,
    salesTarget: number,
    perCarat: number | undefined,
    listPrice: number,
    resolved: boolean,
    open?: boolean,
    scroll?: boolean
  ) => void
  childIndex: number[]
  setChildIndex: (value: ((prevState: number[]) => number[]) | number[]) => void
  reservePriceData: Map<number, ReservePriceData> | null
  setReadyToComplete: (value: ((prevState: boolean) => boolean) | boolean) => void
  setRefsForItems: (value: ((prevState: Map<number, any>) => Map<number, any>) | Map<number, any>) => void
  tenderStatus: TenderStatusType | null
}

const closeTenderThead = {
  shipping_owner: 'Own',
  stock_number: 'Stock #',
  title: 'Item',
  count_of_bids: '# Bids',
  high_bid: 'High Bid',
  consignor_payout_amount: 'Consignor payout amount',
  sales_target: 'Reserve',
  asking_price: 'BUY NOW',
  cost: 'Cost',
  action: 'Action',
}
const closeTenderTheadChild = {
  customer: 'Customer',
  company: 'Company',
  bid: 'Bid',
  bid_date: 'Bid Date ',
  action: 'Action',
}

export const BidsByItemOnlyWinners: React.FC<BidsByItemOnlyWinnersProps> = ({
  currentTenderId,
  getChildForBidsByItem,
  childIndex,
  setChildIndex,
  reservePriceData,
  setReadyToComplete,
  setRefsForItems,
  tenderStatus,
}) => {
  const limitAmount = 400
  const initialParams = {
    limit: limitAmount,
    offset: 0,
    search: undefined,
    sort: 'DESC',
    sortBy: 'high_bid',
  }

  const dispatch = useDispatch()
  const { bidsByItem } = useSelector((store: AppStore) => store)
  const { add } = useAddItemPopup()

  const [checkedValueChildrenTable, setCheckedValueChildrenTable] = useState<Map<number, string>>(
    new Map<number, string>()
  )
  const [lastSubmittedId, setLastSubmittedId] = useState(-1)
  const [params, setParams] = useState<searchProps | paginationProps>(initialParams)
  const [dispatchTime, setDispatchTime] = useState(0)

  const didMount = useRef(false)

  useSearch(limitAmount, params, setParams, setDispatchTime)

  useEffect(() => {
    if (didMount.current) {
      setDispatchTime((prev) => prev + 1)
      setParams(initialParams)
    }
    didMount.current = true
  }, [currentTenderId])

  useEffect(() => {
    dispatch(
      GetBidsByItemForTenderClose.request({
        tenderId: currentTenderId,
        search: params.search,
        sortBy: params.sortBy,
        sort: params.sort,
      })
    )
  }, [params.offset, params.limit, params.sortBy, params.sort, dispatchTime])

  useEffect(() => {
    if (bidsByItem.dataOnlyWinners) {
      setReadyToComplete(bidsByItem.dataOnlyWinners.every((item) => item.resolved))
    }
  }, [bidsByItem.dataOnlyWinners])

  useEffect(() => {
    if (bidsByItem.dataOnlyWinners) {
      let canGo = lastSubmittedId === -1
      const openChild = (data: TBidsByItem[]) => {
        for (let i = 0; i < data.length; i += 1) {
          const item = data[i]
          if (item.item_id === lastSubmittedId && !canGo) {
            canGo = true
          } else if (!data[i].resolved && canGo) {
            getChildForBidsByItem(
              item.item_id,
              data[i].sales_target,
              data[i].sales_target_per_carat,
              data[i].list_price,
              data[i].resolved,
              true,
              true
            )
            return true
          }
        }
        return false
      }
      const itemIsFound = openChild(bidsByItem.dataOnlyWinners)
      if (!itemIsFound) openChild(bidsByItem.dataOnlyWinners)
    }
  }, [bidsByItem.dataOnlyWinners])

  /// /////////////////////////////////////////////////////////////////////////////////////////////
  const callBack = (sortBy: string) => {
    if (sortBy === 'action') return

    let value: { sortBy: string; sort: 'ASC' | 'DESC' }
    if (sortBy !== params.sortBy || params.sort === 'DESC') value = { sortBy, sort: 'ASC' }
    else value = { sortBy, sort: 'DESC' }

    setParams((prev) => ({ ...prev, offset: 0, limit: limitAmount, sortBy: value.sortBy, sort: value.sort }))
    setChildIndex([])
  }

  const updateWinner = (idItem: number, idBid: number) =>
    dispatch(
      UpdateWinnerByItemId.request({
        idItem,
        idBid,
        callBack: (success) => {
          if (success) {
            setLastSubmittedId(idItem)
            setChildIndex((prev) => prev.filter((ind) => ind !== idItem))
            setDispatchTime((prev) => prev + 1)
          }
        },
      })
    )

  const bidsByItemCloseValues = useMemo(() => {
    return bidsByItem.dataOnlyWinners
      ? getBidsByItemCLoseParsed(
          bidsByItem.dataOnlyWinners,
          childIndex,
          getChildForBidsByItem,
          dispatch,
          add,
          tenderStatus
        )
      : null
  }, [bidsByItem.dataOnlyWinners, childIndex])

  const bidsByItemCloseValuesChild = useMemo(() => {
    if (!bidsByItem.onlyWinnersChildData || !reservePriceData) return null
    const result = new Map<number, object[]>()
    bidsByItem.onlyWinnersChildData.forEach((value, key) => {
      result.set(
        key,
        getBidsByItemChildCLoseParsed(
          value,
          checkedValueChildrenTable,
          setCheckedValueChildrenTable,
          key,
          reservePriceData,
          updateWinner
        )
      )
    })
    return result
  }, [bidsByItem.onlyWinnersChildData?.entries(), checkedValueChildrenTable, reservePriceData])

  const bidsByItemCloseTable = useMemo(() => {
    return bidsByItemCloseValues ? (
      <TableTenderClose
        thead={closeTenderThead}
        tbody={bidsByItemCloseValues}
        theadChild={closeTenderTheadChild}
        tbodyChild={bidsByItemCloseValuesChild}
        childIndex={childIndex}
        callBack={callBack}
        setRefsForItems={setRefsForItems}
      />
    ) : null
  }, [bidsByItemCloseValues, bidsByItemCloseValuesChild, childIndex])

  return (
    <>
      <div className='flex justify-between lg:items-center mb-4 flex-col lg:flex-row'>
        <div className='w-full md:w-1/3 lg:w-1/4 mb-6 lg:mb-0'>
          <Input
            placeholder='Search...'
            value={params.search || ''}
            onChange={(e) => setParams((prev) => ({ ...prev, search: e.target.value }))}
          />
        </div>
      </div>
      {bidsByItemCloseTable}
    </>
  )
}
