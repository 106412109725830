export const ROUTES = {
  home: '/',
  dashboard: '/dashboard',
  userAccount: '/user-account',
  terms: '/terms',
  adminPage: '/admin',
  adminPageTender: '/admin/tender',
  adminPageUpcomingTenders: '/admin/tender/upcoming-tenders',
  adminPageTendersSchedule: '/admin/tender/tenders-schedule',
  adminPageUsers: '/admin/users',
  adminPageUsersCustomers: '/admin/users/customers',
  adminPageUsersCompanies: '/admin/users/companies',
  adminPageUsersPersonalRecommendations: '/admin/users/personal-recommendations',
  adminPageSettings: '/admin/settings',
  adminPageSettingsMappingDiamonds: `/admin/settings/mappings/diamonds`,
  adminPageSettingsCsm: `/admin/settings/cms`,
  adminPageSettingsEmailLogs: `/admin/settings/email-logs`,
  adminPageSettingsMediaManagement: '/admin/settings/media-management',
  tenderResults: '/tender-results/tenders',
  tenders: '/tenders',
  c: '/c',

  login: '/login',
  forgotPass: '/forgot-password',
  successReset: '/success-reset',
  errorReset: '/error-reset',
  resetPass: '/reset-password',
  newPass: '/set-new-password',
  diamondVideo: '/diamond-video',
}
