import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { callApi } from '../../../../utils/callApi'
import { API_ROUTES } from '../../../../utils/apiRoutes'
import * as Actions from './actions'
import { ActionTypes, TImagesMetaData, TTenderGalleryList } from './types'
import { SnackBar } from '../../../../components/ui/SnackBar'

const { addSnackBar } = SnackBar()

function* getTenderImagesStatusWorker(action: ReturnType<typeof Actions.GetTenderImagesStatus.request>) {
  const { callBack, idTender } = action.payload as Actions.TypeGetTenderImagesStatusR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminTenders}/${idTender}/gallery-check-exists-images`,
    })) as string[]

    yield put(Actions.GetTenderImagesStatus.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetTenderImagesStatus.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (!success) addSnackBar(data?.message || '', success)
  }
}

function* sendImagesMetadataWorker(action: ReturnType<typeof Actions.SendImagesMetadata.request>) {
  const { callBack, imagesData } = action.payload as Actions.TypeSendImagesMetadataR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'post',
      path: API_ROUTES.adminPostImagesMetaData,
      data: imagesData,
    })) as TImagesMetaData[]

    yield put(Actions.SendImagesMetadata.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.SendImagesMetadata.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    const successMessage = 'Images loaded.'
    const errorMessage = data?.message || ''
    const snackBarMessage = success ? successMessage : errorMessage
    addSnackBar(snackBarMessage, success)
  }
}

function* getFilestackApiKeyWorker(action: ReturnType<typeof Actions.GetFilestackApiKey.request>) {
  const { callBack } = action.payload as Actions.TypeGetFilestackApiKeyR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: API_ROUTES.adminFilestackApiKey,
    })) as string

    yield put(Actions.GetFilestackApiKey.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetFilestackApiKey.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (!success) addSnackBar(data?.message || '', success)
  }
}

function* deleteAllImagesWorker(action: ReturnType<typeof Actions.DeleteAllImages.request>) {
  const { callBack, idTender, idItem } = action.payload as Actions.TypeDeleteAllImagesR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'delete',
      path: `${API_ROUTES.adminImageManagementDeleteAll}/${idTender}/items/${idItem}`,
    })) as string

    yield put(Actions.DeleteAllImages.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.DeleteAllImages.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (!success) addSnackBar(data?.message || '', success)
  }
}

function* deleteOneImageWorker(action: ReturnType<typeof Actions.DeleteOneImage.request>) {
  const { callBack, idTender, idImage } = action.payload as Actions.TypeDeleteOneImageR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'delete',
      path: `${API_ROUTES.adminImageManagementDeleteOne}/${idTender}/gallery/${idImage}`,
    })) as string

    yield put(Actions.DeleteOneImage.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.DeleteOneImage.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (!success) addSnackBar(data?.message || '', success)
  }
}

function* getTenderGalleryListWorker(action: ReturnType<typeof Actions.GetTenderGalleryList.request>) {
  const { callBack, idTender } = action.payload as Actions.TypeGetTenderGalleryListR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminTenders}/${idTender}/gallery/list`,
    })) as TTenderGalleryList[]

    yield put(Actions.GetTenderGalleryList.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetTenderGalleryList.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (!success) addSnackBar(data?.message || '', success)
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.GET_TENDER_IMAGES_STATUS_R, getTenderImagesStatusWorker)
  yield takeEvery(ActionTypes.SEND_IMAGES_METADATA_R, sendImagesMetadataWorker)
  yield takeEvery(ActionTypes.GET_FILESTACK_API_KEY_R, getFilestackApiKeyWorker)
  yield takeEvery(ActionTypes.DELETE_ALL_IMAGES_R, deleteAllImagesWorker)
  yield takeEvery(ActionTypes.DELETE_ONE_IMAGE_R, deleteOneImageWorker)
  yield takeEvery(ActionTypes.GET_TENDER_GALLERY_LIST_R, getTenderGalleryListWorker)
}

export default function* mediaManagementSaga() {
  yield all([fork(watchRequest)])
}
