/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import { TAllBidsByTender } from '../../store/admin/tenders/allBidsByTender/types'
import { TBidsByItem, TBidsByItemChild } from '../../store/admin/tenders/bidsByItem/types'
import { Arrow } from '../../svg/Arrow'
import { TBidsByBidden, TBidsByBiddenChild } from '../../store/admin/tenders/bidsByBidden/types'
import { TBuyNowPurchases } from '../../store/admin/tenders/buyNowPurchases/types'
import { Button } from '../../components/ui/adminComponents/Button'
import { RadioBtn } from '../../components/ui/RadioBtn'
import { ExclamationMark } from '../../svg/ExclamationMark'
import { getDate, getObjWithOutKeys } from '../helpers'
import { ReservePriceData } from '../../containers/TendersContent/CloseTender'
import { GetItemInfo } from '../../store/gallery/actions'
import { ItemPopupProps } from '../../components/ui/galleryUi/ItemPopup'
import { TTendersAudit, TTendersAuditChild } from '../../store/admin/tenders/audit/types'
import { GetAllBidsByTenderWith0Offset } from '../../store/admin/tenders/allBidsByTender/actions'
import {
  GetBidsByItemForTenderClose,
  GetBidsByItemWith0Offset,
  GetTenderStatsForBidsByItem,
} from '../../store/admin/tenders/bidsByItem/actions'
import { GetBidsByBiddenWith0Offset } from '../../store/admin/tenders/bidsByBidden/actions'
import {
  GetBuyNowPurchasesWith0Offset,
  GetTenderStatsForBuyNowPurchases,
} from '../../store/admin/tenders/buyNowPurchases/actions'
import { GetAuditByTenderWith0Offset } from '../../store/admin/tenders/audit/actions'
import { TTenderGalleryList, TTenderGalleryResponse } from '../../store/admin/settings/mediaManagement/types'
import { openLinkInNewTab } from '../userUtils/EventHandlers'
import { IconFilePdf } from '../../svg/IconFilePdf'
import { BidStatus } from '../../types/actions'
import { Checkbox } from '../../components/ui/Checkbox'
import { IconInfo } from '../../svg/IconInfo'

const getBidFieldByValues = (
  bid: number,
  weight: number | undefined,
  list_price: number | undefined,
  type_of_bid?: BidStatus
) => {
  const bidInDollars = Math.round(bid / 100)
  let icon = ''
  if (type_of_bid) icon = type_of_bid === 'bid' ? '$$$' : '%%%'
  if (bidInDollars) {
    if (weight) {
      const bidPerCarat = Math.round(bid / weight / 100)
      if (list_price) {
        const discount = Math.round((bid / weight / (list_price / 100.0) - 100.0) * 100) / 100
        return `$${bidPerCarat}/ct (${discount}%) ${icon}|||$${bidInDollars} total`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        )
      }
      return `$${bidPerCarat}/ct ${icon}|||$${bidInDollars} total`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    return `$${bidInDollars} ${icon}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return `-----`
}

const getReserveFieldByValues = (
  sales_target: number,
  sales_target_per_carat: number | undefined,
  list_price: number
) => {
  const salesTargetInDollars = Math.round(sales_target / 100)
  if (salesTargetInDollars) {
    if (sales_target_per_carat) {
      const salesTargetPerCaratInDollars = Math.round(sales_target_per_carat / 100)
      if (list_price > 0) {
        const discount = Math.round((sales_target_per_carat / (list_price / 100.0) - 100.0) * 100) / 100
        return `$${salesTargetPerCaratInDollars}/ct (${discount}%)|||$${salesTargetInDollars} total`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        )
      }
      return `$${salesTargetPerCaratInDollars}/ct|||$${salesTargetInDollars} total`.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ','
      )
    }
    return `$${salesTargetInDollars}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return `-----`
}

const getBuyNowFieldByValues = (
  asking_price: number | undefined,
  asking_price_discount: number | undefined,
  asking_price_per_carat: number | undefined
) => {
  if (asking_price) {
    if (asking_price_per_carat) {
      if (asking_price_discount) {
        return `$${Math.round(asking_price_per_carat / 100)}/ct (${asking_price_discount}%)|||$${Math.round(
          asking_price / 100
        )} total`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return `$${Math.round(asking_price_per_carat / 100)}/ct|||$${Math.round(asking_price / 100)} total`.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ','
      )
    }
    return `$${Math.round(asking_price / 100)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return `-----`
}

const getTitleBtn = (
  title: string,
  itemId: number,
  dispatch: any,
  add: (value: ItemPopupProps) => void,
  tenderStatus: any
) => {
  const openItemPopup = () => {
    dispatch(
      GetItemInfo.request({
        itemId,
        callBack: (success, data) => {
          if (success && data) {
            const finalized = tenderStatus === 'toBeClosed' || tenderStatus === 'completed'
            add({ item: data, isCatalog: false, finalized })
          }
        },
      })
    )
  }
  return (
    <div>
      <button type='button' className='text-left' onClick={openItemPopup}>
        {title}
      </button>
    </div>
  )
}

const getClassNameForAudit = (bid_status_id: number, active: boolean, is_deleted: boolean) => {
  if ([1, 2, 3, 4, 5, 6].includes(bid_status_id) && active) return 'bg-blue-200'
  if ([1, 2, 3, 4, 5, 6].includes(bid_status_id) && !active && is_deleted) return 'bg-rose-50'
  if ([1, 2, 3, 4, 5, 6].includes(bid_status_id) && !active && !is_deleted) return 'bg-blue-100'
  if ([7].includes(bid_status_id) && active) return 'bg-amber-100'
  if ([7].includes(bid_status_id) && !active && !is_deleted) return 'bg-neutral-200'
  if ([8].includes(bid_status_id) && active) return 'bg-emerald-200'
  if ([8].includes(bid_status_id) && !active) return 'bg-rose-200'
  return ''
}

export const getAllBidsParsed = (
  data: TAllBidsByTender[],
  isExecutiveAdmin: boolean,
  dispatch: any,
  add: (value: ItemPopupProps) => void,
  tenderStatus: any
) => {
  return data.map((item) => {
    return {
      shipping_owner: item.shipping_owner.includes('CIRCA')
        ? item.shipping_owner
        : item.consignor_name || item.shipping_owner,
      stock_number: item.stock_number,
      title: getTitleBtn(item.title, item.item_id, dispatch, add, tenderStatus),
      created_at: getDate(item.created_at),
      email: [item.company_title, item.email],
      company_title: item.company_title,
      bid: isExecutiveAdmin ? getBidFieldByValues(item.bid, item.weight, item.list_price) : 'Hidden',
      sales_target: getReserveFieldByValues(item.sales_target, item.sales_target_per_carat, item.list_price),
      asking_price: getBuyNowFieldByValues(item.asking_price, item.asking_price_discount, item.asking_price_per_carat),
    }
  })
}

export const getBidsByItemParsed = (
  data: TBidsByItem[],
  childIndex: number[],
  callBack: (idItem: number) => void,
  isExecutiveAdmin: boolean,
  dispatch: any,
  add: (value: ItemPopupProps) => void,
  tenderStatus: any,
  setShowRecommendationItem: (value: TBidsByItem) => void
) => {
  return data.map((item) => {
    const isOpened = childIndex.some((index) => index === item.item_id)
    const action = (
      <button type='button' onClick={() => callBack(isOpened ? -1 : item.item_id)}>
        Show Bids
        <div className={`inline-block transform transition-transform ${isOpened && 'rotate-180'}`}>
          <Arrow />
        </div>
      </button>
    )
    const action2 = (
      <div>
        <button
          type='button'
          className='text-sm py-1 2smallest:w-full smallMobile:px-2 whitespace-nowrap items-center flex justify-center bg-lightblue-50 text-lightblue-700 hover:bg-lightblue-100 border border-lightblue-200 rounded-md'
          onClick={() => setShowRecommendationItem(item)}
        >
          Recommend
        </button>
      </div>
    )

    const res = {
      id: item.item_id,
      shipping_owner: item.shipping_owner.includes('CIRCA')
        ? item.shipping_owner
        : item.consignor_name || item.shipping_owner,
      stock_number: item.stock_number,
      title: getTitleBtn(item.title, item.item_id, dispatch, add, tenderStatus),
      count_of_bids: item.count_of_bids,
      high_bid: isExecutiveAdmin ? getBidFieldByValues(item.high_bid, item.weight, item.list_price) : 'Hidden',
      sales_target: getReserveFieldByValues(item.sales_target, item.sales_target_per_carat, item.list_price),
      asking_price: getBuyNowFieldByValues(item.asking_price, item.asking_discount, item.asking_price_per_carat),
      wining_bid:
        !item.winning_bid && item.resolved
          ? 'Reserve Not Met'
          : getBidFieldByValues(item.winning_bid, item.weight, item.list_price),
      company_title: item.company_title,
      action,
      action2,
    }
    return tenderStatus === 'active' ? res : getObjWithOutKeys(res, ['action2'])
  })
}

export const getBidsByItemChildParsed = (data: TBidsByItemChild[], isExecutiveAdmin: boolean, isCompleted: boolean) => {
  return data.map((item) => {
    return {
      customer: item.email,
      company: item.company_title,
      bid: isExecutiveAdmin ? getBidFieldByValues(item.bid, item.weight, item.list_price) : 'Hidden',
      bid_date: getDate(item.created_at),
      className: item.bid_status_id === 2 && isCompleted ? 'bg-green-50' : '',
    }
  })
}

export const getBidsByItemCLoseParsed = (
  data: TBidsByItem[],
  childIndex: number[],
  callBack: (
    idItem: number,
    salesTarget: number,
    perCarat: number | undefined,
    listPrice: number,
    resolved: boolean
  ) => void,
  dispatch: any,
  add: (value: ItemPopupProps) => void,
  tenderStatus: any
) => {
  return data.map((item) => {
    const isOpened = childIndex.some((id) => id === item.item_id)
    const action = (
      <button
        type='button'
        onClick={() =>
          callBack(
            item.item_id,
            isOpened ? -1 : item.sales_target,
            item.sales_target_per_carat,
            item.list_price,
            item.resolved
          )
        }
      >
        Show Bids
        <div className={`inline-block transform transition-transform ${isOpened && 'rotate-180'}`}>
          <Arrow />
        </div>
      </button>
    )
    return {
      id: item.item_id,
      shipping_owner: item.shipping_owner.includes('CIRCA')
        ? item.shipping_owner
        : item.consignor_name || item.shipping_owner,
      stock_number: item.stock_number,
      title: getTitleBtn(item.title, item.item_id, dispatch, add, tenderStatus),
      count_of_bids: item.count_of_bids,
      high_bid: getBidFieldByValues(item.high_bid, item.weight, item.list_price),
      consignor_payout_amount: `$${Math.round(item.consignor_payout_amount / 100)}`.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ','
      ),
      sales_target: getReserveFieldByValues(item.sales_target, item.sales_target_per_carat, item.list_price),
      asking_price: getBuyNowFieldByValues(item.asking_price, item.asking_discount, item.asking_price_per_carat),
      cost:
        item.shipping_owner.includes('CIRCA') && item.cost ? `$${item.cost.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : '',
      action,
    }
  })
}

const getMapData = (state: Map<number, string>, payload: { idItem: number; data: string }) => {
  if (payload.data) {
    if (state) return state.set(payload.idItem, payload.data)
    return new Map<number, any>([[payload.idItem, payload.data]])
  }
  return state
}

export const getBidsByItemChildCLoseParsed = (
  data: TBidsByItemChild[],
  checkedValue: Map<number, string>,
  setCheckedValue: (value: ((prevState: Map<number, string>) => Map<number, string>) | Map<number, string>) => void,
  idItem: number,
  reserveData: Map<number, ReservePriceData> | null,
  updateWinner: (idItem: number, idBid: number) => void
) => {
  let reserveIsDone: boolean = false
  const result = []
  const winnerExist: boolean = data.findIndex((item) => item.bid_status_id === 2) !== -1
  const currentReserveData = reserveData?.get(idItem)
  if (!currentReserveData) return [{}]
  const addReserveNotMet = () => {
    const isChecked =
      checkedValue.get(idItem) === `reserveNotMet${idItem}` ||
      (currentReserveData.resolved && !winnerExist && checkedValue.get(idItem) === undefined)
    const action = (
      <RadioBtn
        text='Reserve Not Met'
        color='text-orange-400'
        value={`reserveNotMet${idItem}`}
        name={`closeTender${idItem}`}
        checked={isChecked}
        onChange={(e: any) => {
          setCheckedValue((prev) => getMapData(prev, { idItem, data: e.target.value }))
          updateWinner(idItem, -1)
        }}
      />
    )
    const reserveField = currentReserveData.salesTarget
      ? getReserveFieldByValues(
          currentReserveData.salesTarget,
          currentReserveData.perCarat,
          currentReserveData.listPrice
        ).split('|||')
      : 'No Reserve Price is set. Set anyway?'
    const reserve_price = (
      <div className='inline-flex'>
        <div className='flex items-center'>
          <ExclamationMark />
          <span className='mx-2'>Reserve Price:</span>
        </div>
        <div>
          {typeof reserveField === 'string' ? reserveField : reserveField[0]}
          <div className='text-sm text-bluegray-400'>{typeof reserveField === 'string' ? '' : reserveField[1]}</div>
        </div>
      </div>
    )
    result.push({
      reserve_price,
      action,
      className: isChecked ? 'bg-amber-50' : '',
    })
  }

  const getBgClassName = (bid: number) => {
    if (!(data.filter((item) => item.bid === bid).length > 1)) return '' // check if exist dubbing
    // check if is winner
    if (data.some((item) => item.bid > bid)) return 'bg-gray-100'
    return 'bg-green-100'
  }

  for (let i = 0; i < data.length; i += 1) {
    if (reserveIsDone || data[i].bid >= currentReserveData.salesTarget) {
      const isChecked =
        `winner${data[i].bid_id}${idItem}` === checkedValue.get(idItem) ||
        (currentReserveData.resolved && data[i].bid_status_id === 2 && checkedValue?.get(idItem) === undefined)
      const action = (
        <RadioBtn
          text={isChecked ? 'Winner' : 'Select as Winner'}
          value={`winner${data[i].bid_id}${idItem}`}
          checked={isChecked}
          name={`closeTender${idItem}`}
          onChange={(e: any) => {
            setCheckedValue((prev) => getMapData(prev, { idItem, data: e.target.value }))
            updateWinner(idItem, data[i].bid_id)
          }}
        />
      )
      result.push({
        customer: data[i].email,
        company: data[i].company_title,
        bid: getBidFieldByValues(data[i].bid, data[i].weight, data[i].list_price, data[i].type_of_bid),
        bid_date: getDate(data[i].created_at),
        action,
        className: isChecked ? 'bg-green-50' : getBgClassName(data[i].bid),
      })
    } else {
      reserveIsDone = true
      i -= 1
      addReserveNotMet()
    }
  }
  if (!reserveIsDone) addReserveNotMet()
  return result
}

export const getBidsByBiddenParsed = (
  data: TBidsByBidden[],
  childIndex: number[],
  callBack: (idUser: number) => void,
  idTender: number
) => {
  return data.map((item) => {
    const isOpened = childIndex.some((index) => index === +`${idTender}${item.user_id}`)
    const action = (
      <button type='button' className='flex' onClick={() => callBack(isOpened ? -1 : item.user_id)}>
        View
        <div className={`inline-block transform transition-transform ${isOpened && 'rotate-180'}`}>
          <Arrow />
        </div>
      </button>
    )
    return {
      id: +`${idTender}${item.user_id}`,
      email: item.email,
      company_title: item.company_title,
      count_of_bids: item.count_bids,
      last_bid: getDate(item.last_bid),
      action,
    }
  })
}

export const getBidsByBiddenChildParsed = (
  data: TBidsByBiddenChild[],
  isExecutiveAdmin: boolean,
  dispatch: any,
  add: (value: ItemPopupProps) => void,
  tenderStatus: any
) => {
  return data.map((item) => {
    return {
      shipping_owner: item.shipping_owner.includes('CIRCA')
        ? item.shipping_owner
        : item.consignor_name || item.shipping_owner,
      stock: item.stock_number,
      bid_date: getDate(item.created_at),
      item: getTitleBtn(item.title, item.item_id, dispatch, add, tenderStatus),
      bid: isExecutiveAdmin ? getBidFieldByValues(item.bid, item.weight, item.list_price) : 'Hidden',
      reserve: getReserveFieldByValues(item.sales_target, item.sales_target_per_carat, item.list_price),
      buy_now: getBuyNowFieldByValues(item.asking_price, item.asking_discount, item.asking_price_per_carat),
    }
  })
}

// ////////////////////////////////////////////////////////

export const getBuyNowPurchasedParsed = (
  data: TBuyNowPurchases[],
  setBuyNowItemId: (idItem: number) => void,
  isActive: boolean,
  isExecutiveAdmin: boolean,
  dispatch: any,
  add: (value: ItemPopupProps) => void,
  tenderStatus: any,
  isProcessing: boolean,
  bodyAreChecked: Set<number>,
  onBodyAreChecked: (itemId: number) => void,
  setTooltipVisible: (value: number | null) => void,
  tooltipVisible: number | null

) => {
  return data.map((item) => {
    const nsProcessingStatus = item.ns_processing_status
    const nsMassageError = item?.ns_err_msg

    let action = null
    if (isActive) {
      if (nsProcessingStatus === '') {
        action = (
          <div className='flex flex-col gap-2'>
            <Button variant='undo' text='Undo' disabled={isProcessing} onClick={() => setBuyNowItemId(item.item_id)} />
          </div>
        )
      } else if (nsProcessingStatus === 'in_progress') {
        action = (
          <div className='flex flex-col gap-2'>
            <Button variant='undo' text='Undo' disabled />
          </div>
        )
      } else if (nsProcessingStatus === 'completed' || nsProcessingStatus === 'failed') {
        action = (
          <div className='flex flex-col gap-2'>
            <div className='empty-cell' style={{ minWidth: 20, minHeight: 20 }} />
          </div>
        )
      }
    }

    let checkbox = null
    if (isActive) {
      if (nsProcessingStatus === '') {
        checkbox = (
          <Checkbox
            classname='mx-auto'
            label=''
            isChecked={bodyAreChecked.has(item.item_id)}
            setIsChecked={() => onBodyAreChecked(item.item_id)}
          />
        )
      } else if (nsProcessingStatus === 'in_progress') {
        checkbox = (
          <Checkbox
            classname='mx-auto'
            label=''
            isChecked={bodyAreChecked.has(item.item_id)}
            setIsChecked={() => {}}
            disabled
            labelClassname='px-0'
          />
        )
      } else if (nsProcessingStatus === 'completed') {
        checkbox = (
          <div className='flex items-center gap-2'>
            <div className='' style={{ minWidth: 20, minHeight: 20 }} />
            <span style={{ color: 'green' }}>Done!</span>
          </div>
        )
      } else if (nsProcessingStatus === 'failed') {
        checkbox = (
          <div className='flex items-center gap-2 relative'>
            <Checkbox
              classname=''
              label=''
              isChecked={bodyAreChecked.has(item.item_id)}
              setIsChecked={() => onBodyAreChecked(item.item_id)}
              labelClassname='!px-0'
            />
            <span style={{ color: 'red' }}>Failed</span>
            {nsMassageError && (
              <div
                onMouseEnter={() => setTooltipVisible(item.item_id)}
                onMouseLeave={() => setTooltipVisible(null)}
                className="relative"
              >
                <IconInfo className='w-[17px] !h-[17px] mt-1' />
                {tooltipVisible === item.item_id && (
                <>
                  <div className="tooltip">
                    {nsMassageError}
                  </div>
                </>
                )}
              </div>
            )}
          </div>
        )
      
      }
    }

    const result = {
      shipping_owner: item.shipping_owner.includes('CIRCA')
        ? item.shipping_owner
        : item.consignor_name || item.shipping_owner,
      stock_number: item.stock_number,
      title: getTitleBtn(item.title, item.item_id, dispatch, add, tenderStatus),
      email: [item.company_title, item.email],
      company_title: item.company_title,
      asking_price: getBuyNowFieldByValues(item.asking_price, item.asking_discount, item.asking_price_per_carat),
      transaction_date: getDate(item.transaction_date),
    }

    if (isExecutiveAdmin) {
      if (action) {
        // @ts-ignore
        result.action = action
      }
      if (checkbox) {
        // @ts-ignore
        result.invoice = checkbox
      }
    }
    return result
  })
}

export const getOnlyWinnersParsed = (
  data: TBidsByItem[],
  callBack: (
    idItem: number,
    salesTarget: number,
    perCarat: number | undefined,
    listPrice: number,
    resolved: boolean
  ) => void,
  dispatch: any,
  add: (value: ItemPopupProps) => void,
  tenderStatus: any
) => {
  return data.map((item) => {
    const action = (
      <button
        type='button'
        onClick={() => callBack(item.item_id, item.sales_target, item.sales_target_per_carat, item.list_price, true)}
      >
        Edit
      </button>
    )
    return {
      item: getTitleBtn(item.title, item.item_id, dispatch, add, tenderStatus),
      customer: item.winning_bid ? [item.company_title, item.email] : 'Reserve not met',
      bid: getBidFieldByValues(item.winning_bid, item.weight, item.list_price),
      action,
    }
  })
}

export const getAuditByTendersParsed = (
  data: TTendersAudit[],
  childIndex: number[],
  callBack: (idItem: number) => void,
  dispatch: any,
  add: (value: ItemPopupProps) => void,
  tenderStatus: any
) => {
  return data.map((item) => {
    const isOpened = childIndex.some((index) => index === item.item_id)
    const action = (
      <button type='button' onClick={() => callBack(isOpened ? -1 : item.item_id)}>
        View
        <div className={`inline-block transform transition-transform ${isOpened && 'rotate-180'}`}>
          <Arrow />
        </div>
      </button>
    )
    return {
      id: item.item_id,
      stock_number: item.stock_number,
      title: getTitleBtn(item.title, item.item_id, dispatch, add, tenderStatus),
      count_of_actions: item.count_of_actions,
      action,
    }
  })
}

export const getAuditByTendersChildParsed = (data: TTendersAuditChild[], itemData: TTendersAudit | undefined) => {
  return data.map((item) => {
    return {
      email: item.update_by_email ? `${item.email}|||Deleted by ${item.update_by_email}` : item.email,
      company_title: item.company_title,
      sum: getBidFieldByValues(item.bid, itemData?.weight, itemData?.list_price),
      created_at: getDate(item.created_at),
      className: getClassNameForAudit(item.bid_status_id, item.active, item.is_deleted),
    }
  })
}

export const getTenderItemsForDeleteParsed = (data: TTenderGalleryList[], childIndex: number[]) => {
  return data.map((item) => {
    const isOpened = childIndex.some((id) => id === item.item_id)
    const action = (
      <button type='button'>
        Go to media
        <div className={`inline-block transform transition-transform ${isOpened && 'rotate-180'}`}>
          <Arrow />
        </div>
      </button>
    )
    const getItem = () => {
      if (item.gallery_response) {
        switch (item.gallery_response[0].file_type_id) {
          case 1:
          case 4:
          case 5:
          case 6:
            return (
              <img
                src={item.gallery_response[0].url}
                alt='img'
                width={80}
                className='m-auto cursor-pointer'
                onClick={() => openLinkInNewTab(item.gallery_response![0].url)}
              />
            )

          case 3:
            return (
              <div className='flex justify-center text-4xl'>
                <IconFilePdf />
              </div>
            )
          default:
            return (
              <video
                src={item.gallery_response[0].url}
                width={80}
                autoPlay
                playsInline
                loop
                muted
                className='m-auto cursor-pointer'
                onClick={() => openLinkInNewTab(item.gallery_response![0].url)}
              />
            )
        }
      }
      return 'No media'
    }

    return {
      id: item.item_id,
      image: getItem(),
      sku: item.sku,
      brand: item.title,
      media: item.gallery_response?.length || 0,
      action,
    }
  })
}

export const getTenderItemsForDeleteChildParsed = (data: TTenderGalleryResponse[], onDelete: (id: number) => void) => {
  return data.map((item) => {
    const action = (
      <button
        type='button'
        onClick={() => onDelete(item.image_id)}
        className='delete-bid text-red-500 font-semibold hover:text-red-900'
      >
        Delete
      </button>
    )
    const handle = item.url.split('/')

    const getPreview = () => {
      switch (item.file_type_id) {
        case 1:
        case 4:
        case 5:
        case 6:
          return (
            <img
              src={item.url}
              alt='img'
              width={60}
              className='m-auto cursor-pointer'
              onClick={() => openLinkInNewTab(item.url)}
            />
          )
        case 3:
          return (
            <div className='flex justify-center text-4xl'>
              <IconFilePdf />
            </div>
          )

        default:
          return (
            <video
              src={item.url}
              width={60}
              autoPlay
              playsInline
              loop
              muted
              className='m-auto cursor-pointer'
              onClick={() => openLinkInNewTab(item.url)}
            />
          )
      }
    }
    return {
      image_preview: getPreview(),
      image_name: item.file_name_from_load,
      handle: handle[handle.length - 1],
      action,
    }
  })
}

export const clearDataListener = (url: string, dispatch: any) => {
  if (!url.includes('/all-bids')) dispatch(GetAllBidsByTenderWith0Offset.success(null))

  if (!url.includes('/bids-by-item')) {
    dispatch(GetBidsByItemWith0Offset.success(null))
    dispatch(GetTenderStatsForBidsByItem.success(null))
  }

  if (!url.includes('/bids-by-bidder')) dispatch(GetBidsByBiddenWith0Offset.success(null))

  if (!url.includes('/buy-now-purchases')) {
    dispatch(GetBuyNowPurchasesWith0Offset.success(null))
    dispatch(GetTenderStatsForBuyNowPurchases.success(null))
  }

  if (!url.includes('/close-tender')) dispatch(GetBidsByItemForTenderClose.success(null))

  if (!url.includes('/audit`')) dispatch(GetAuditByTenderWith0Offset.success(null))
}
