import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
// import queryString from 'query-string'
import { UpcomingTendersSwitch } from '../components/ui/upcomingTendersUi/UpcomingTendersSwitch'
import { GetUpcomingTenders } from '../store/admin/tenders/upcomingTenders/actions'
import { AppStore } from '../store/applicationState'
import { IconBack } from '../svg/IconBack'
// import { Sort } from '../svg/Sort'
import { getDate } from '../utils/helpers'
import { ROUTES } from '../utils/routes'

const tableCellWidth = [100, 150, 200, 150, 170]

const tHead = {
  id: 'Tender ID',
  sales_cycle: 'Tender SalesCycle',
  viewStatus: 'Show/Not Show',
  updated_at: 'TimeStamp',
  updated_by: 'User Sign',
}

export const UpcomingTenders = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [dispatchTime, setDispatchTime] = useState(0)
  const emptyField = '-----'

  // const {
  //   search,
  //   sort,
  //   sortBy,
  // } = queryString.parse(location.search)

  // const initialParams = {
  //   offset: 0,
  //   search: (typeof search === 'string' && search) || undefined,
  //   sort: (typeof sort === 'string' && sort) || '',
  //   sortBy: (typeof sortBy === 'string' && sortBy) || '',
  // }

  // const [params, setParams] = useState(initialParams)
  const { upcomingTenders } = useSelector((store: AppStore) => store)

  useEffect(() => {
    dispatch(GetUpcomingTenders.request())
  }, [dispatchTime])

  const dayNow = new Date()

  // const callBack = (sortByValue: string) => {
  //   let value: { sortBy: string, sort: 'ASC' | 'DESC' }
  //   if (sortByValue !== params.sortBy || params.sort === 'DESC') value = { sortBy: sortByValue, sort: 'ASC' }
  //   else value = { sortBy: sortByValue, sort: 'DESC' }

  //   addNewParams('sort', value.sort)
  //   addNewParams('sortBy', value.sortBy)

  //   setParams(prev => ({ ...prev, sortBy: value.sortBy, sort: value.sort }))
  // }

  return (
    <div>
      <button
        type='button'
        className='py-1.5 smallMobile:py-3 w-max flex flex-row items-center h-16'
        onClick={() => {
          // @ts-ignore
          if (location.state?.fromTenders) history.goBack()
          else history.push(`${ROUTES.adminPageTender}`)
        }}
      >
        <IconBack />

        <div className='ml-2 text-menuLink opacity-50 hover:opacity-80'>Back to tenders</div>
      </button>
      <div className='overflow-x-auto relative pb-16'>
        <table className={`upcoming-tenders w-full ${tableCellWidth && 'table-fixed'} border whitespace-nowrap mb-2`}>
          <thead className='bg-gray-50 text-left'>
            <tr className='divide-x border-b border-bluegray-200'>
              {Object.entries(tHead).map(([key, value], index) => {
                return (
                  <th
                    key={`th${key}`}
                    scope='col'
                    style={{ width: tableCellWidth[index] }}
                    className='whitespace-nowrap'
                  >
                    {value}
                    {/* {key === 'sales_cycle' ? (
                      <button
                        type='button'
                        className='disabled:opacity-100'
                        style={{ font: 'inherit' }}
                        disabled
                        onClick={() => {
                          if (callBack) callBack(key)
                        }}
                      >
                        {value}
                        <Sort />
                      </button>
                    ) : value} */}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody className=''>
            {upcomingTenders.data &&
              upcomingTenders.data.map((tender) => {
                return (
                  <tr className='tender divide-x border-b border-bluegray-200' key={`company-${tender.id}`}>
                    <td className='id overflow-auto'>{tender.id || emptyField}</td>
                    <td className='sales-cycle overflow-auto'>{tender.sales_cycle || emptyField}</td>
                    <td
                      className={`relative ${
                        tender.status === 'hidden' && new Date(tender.start_at) < dayNow
                          ? 'pointer-events-none bg-gray-900 bg-opacity-10'
                          : ''
                      }`}
                    >
                      <UpcomingTendersSwitch id={tender.id} status={tender.status} setDispatchTime={setDispatchTime} />
                    </td>
                    <td className='timestamp overflow-auto'>{getDate(tender.updated_at)}</td>
                    <td className='user-sign overflow-auto'>{tender.user?.email || emptyField}</td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
