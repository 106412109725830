import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as Actions from './actions'
import { callApi } from '../../../../utils/callApi'
import { API_ROUTES } from '../../../../utils/apiRoutes'
import { ActionTypes, TTendersAudit } from './types'
import { SnackBar } from '../../../../components/ui/SnackBar'

const { addSnackBar } = SnackBar()

function* getAuditByTenderWorker(action: ReturnType<typeof Actions.GetAuditByTender.request>) {
  const {
    callBack,
    idTender,
    offset,
    limit,
    search,
    sortBy,
    sort,
  } = action.payload as Actions.TypeGetAuditByTenderR
  
  let success = true
  let data = null
  
  let query = `?Offset=${offset || 0}&Limit=${limit || 20}`
  query += search ? `&Search=${search}` : ''
  query += sortBy ? `&SortBy=${sortBy}` : ''
  query += sort ? `&Sort=${sort}` : ''
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminTenders}/${idTender}/items/audit${query}`,
    })) as TTendersAudit[]
    
    if (offset !== 0) yield put(Actions.GetAuditByTender.success(data))
    else yield put(Actions.GetAuditByTenderWith0Offset.success(data))
    
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetAuditByTender.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getAuditByTenderChildWorker(action: ReturnType<typeof Actions.GetAuditByTendersChild.request>) {
  const {
    callBack,
    idItem,
    offset,
    limit,
    sort,
    companyId,
  } = action.payload as Actions.TypeGetAuditByTendersChildR
  
  let success = true
  let data = null
  
  let query = `?Offset=${offset || 0}&Limit=${limit || 20}`
  query += sort ? `&Sort=${sort}` : ''
  query += companyId ? companyId.map(id => `&CompanyId=${id}`).join('') : ''
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminTenders}/items/${idItem}/audit${query}`,
    })) as TTendersAudit[]
    
    if (offset !== 0) yield put(Actions.GetAuditByTendersChild.success({ data, idItem }))
    else yield put(Actions.GetAuditByTendersChildWith0Offset.success({ data, idItem }))
    
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetAuditByTendersChild.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (!success) addSnackBar(data?.message || '', success)
  }
}


function* watchRequest() {
  yield takeEvery(ActionTypes.GET_AUDIT_BY_TENDER_R, getAuditByTenderWorker)
  yield takeEvery(ActionTypes.GET_AUDIT_BY_TENDER_CHILD_R, getAuditByTenderChildWorker)
}

export default function* auditByTenderSaga() {
  yield all([fork(watchRequest)])
}
