/* eslint-disable react/require-default-props */
import React from 'react'
import { DropDownSimple } from './DropDownSimple'
import {
  TListType,
  TSettingMappingDiamondsObject,
  TSettingMappingOptions,
} from '../../../store/admin/settings/mapping/types'
import { Loader } from '../Loader'
import { ExclamationMark } from '../../../svg/ExclamationMark'

interface SettingTableProps {
  tbody: TSettingMappingDiamondsObject[] | null
  list: TSettingMappingOptions | null
  callBack?: (option: TListType, item: TSettingMappingDiamondsObject) => void
  loading?: boolean
}

export const SettingTable: React.FC<SettingTableProps> = ({ tbody, list, callBack, loading }) => {
  return (
    <div style={{ minHeight: '30rem' }} className='relative overflow-y-scroll'>
      <table className='w-full border whitespace-nowrap'>
        <thead className='bg-gray-50 text-left'>
          <tr className='divide-x border-b border-bluegray-200'>
            <th scope='col' className='whitespace-nowrap w-2/3'>
              GIA Diamond
              {' '}
              {list?.parent_group_name}
            </th>
            <th scope='col' className='whitespace-nowrap w-1/3'>
              Tender Grouping
            </th>
          </tr>
        </thead>
        <tbody>
          {tbody?.map((obj, index) => (
            <tr key={`tr${obj.option_id}`} className='divide-x border-b border-bluegray-200'>
              <td className='w-2/3 py-2'>
                {obj.option_key}
                {!obj.options_group_id && ' '}
                {!obj.options_group_id && <ExclamationMark />}
              </td>
              <td className='w-1/3 py-2'>
                <DropDownSimple
                  list={list?.child_options || null}
                  optionTitle={obj.option_title}
                  goUp={tbody?.length >= 5 && tbody?.length <= index + 5}
                  callBack={(item) => callBack && callBack(item, tbody[index])}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {loading && !tbody && <Loader />}
    </div>
  )
}
