import React from 'react'

export const IconFileAlt = () => {
  return (
    <svg
      className="svg-inline--fa fa-file-alt"
      aria-hidden="true"
      focusable="false"
      data-prefix="fal"
      data-icon="file-alt"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384 512"
      data-fa-i2svg=""
    >
      <path fill="currentColor" d="M365.3 125.3l-106.5-106.5C246.7 6.742 230.5 0 213.5 0L64-.0001c-35.35 0-64 28.65-64 64l.0065 384c0 35.35 28.65 64 64 64H320c35.35 0 64-28.65 64-64v-277.5C384 153.5 377.3 137.3 365.3 125.3zM224 34.08c4.477 1.566 8.666 3.846 12.12 7.299l106.5 106.5C346.1 151.3 348.4 155.5 349.9 160H240C231.2 160 224 152.8 224 144V34.08zM352 448c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h128v112C192 170.5 213.5 192 240 192H352V448zM96 272C96 280.8 103.2 288 112 288h160C280.8 288 288 280.8 288 272S280.8 256 272 256h-160C103.2 256 96 263.2 96 272zM272 320h-160C103.2 320 96 327.2 96 336S103.2 352 112 352h160c8.838 0 16-7.164 16-16S280.8 320 272 320zM272 384h-160C103.2 384 96 391.2 96 400S103.2 416 112 416h160c8.838 0 16-7.164 16-16S280.8 384 272 384z" />
    </svg>
  )
}
