import { Reducer } from 'redux'
import { ActionTypes, TItemState } from './types'

export const initialState: TItemState = {
  loading: false,
  data: null,
}

const reducer: Reducer<TItemState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_ITEM_BID_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.SET_ITEM_BID_S:
      return { ...state, loading: false }
    case ActionTypes.SET_ITEM_BID_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.SET_ITEM_BUY_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.SET_ITEM_BUY_S:
      return { ...state, loading: false }
    case ActionTypes.SET_ITEM_BUY_E:
      return { ...state, loading: false, errors: action.payload }
    
    default:
      return state
  }
}

export { reducer as ItemActionsReducer }