import React from 'react'

export const FilterSolid = ({ className = '' }) => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-prefix='fad'
      data-icon='filter'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      className={`svg-inline--fa fa-filter fa-w-16 fa-3x ${className}`}
    >
      <g className='fa-group'>
        <path
          fill='currentColor'
          d='M505 41L320 225.93V488c0 19.51-22 30.71-37.76 19.66l-80-56A24 24 0 0 1 192 432V226L7 41C-8 25.87 2.69 0 24 0h464c21.33 0 32 25.9 17 41z'
          className='fa-secondary'
        />
        <path fill='currentColor' d='' className='fa-primary' />
      </g>
    </svg>
  )
}