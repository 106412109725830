/* eslint-disable react/require-default-props */
import React, { CSSProperties } from 'react'

interface Props {
  style?: CSSProperties
}

export const IconCopy: React.FC<Props> = ({ style }) => {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='456pt'
      height='426pt'
      viewBox='0 0 456 426'
      style={{ ...style }}
      preserveAspectRatio='xMidYMid meet'
    >
      <g transform='translate(0,426) scale(0.1,-0.1)' fill='#000000' stroke='none'>
        <path
          d='M1905 3621 c-72 -18 -140 -73 -178 -146 -18 -35 -21 -61 -25 -212
l-4 -171 -25 -7 c-13 -3 -131 -5 -262 -4 -272 1 -282 -1 -358 -84 -76 -83 -73
-34 -73 -1083 0 -911 0 -935 20 -987 24 -64 78 -121 140 -151 44 -21 53 -21
740 -21 684 0 696 0 740 21 58 27 108 77 134 134 17 38 21 72 26 210 l5 165
260 5 c292 6 301 8 377 87 77 80 73 23 76 917 2 711 1 805 -14 855 -15 52 -29
69 -202 245 -127 128 -201 195 -232 210 -44 21 -56 21 -580 23 -294 1 -548 -2
-565 -6z m875 -398 c5 -204 7 -224 26 -250 42 -58 68 -63 300 -63 211 0 212 0
218 -22 10 -37 6 -1289 -4 -1333 -5 -22 -21 -50 -35 -63 l-26 -22 -660 0 -661
0 -29 29 -29 29 0 932 0 932 29 29 30 29 418 -2 418 -3 5 -222z m367 55 c90
-90 163 -168 163 -174 0 -7 -10 -14 -22 -18 -30 -8 -257 -8 -297 0 l-31 7 0
173 c0 132 3 174 12 174 7 0 86 -73 175 -162z m-1445 -1080 c3 -802 -3 -738
81 -826 76 -78 98 -82 482 -82 l335 0 0 -151 0 -151 -29 -29 -29 -29 -662 0
-662 0 -29 29 -29 29 0 932 0 932 29 29 29 29 241 0 240 0 3 -712z'
        />
      </g>
    </svg>
  )
}
