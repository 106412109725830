import React, { useState, useEffect } from 'react'
import Select, { createFilter } from 'react-select'
import { TCustomerAddress } from '../../../../store/admin/companies/types'
import { Edit } from '../../../../svg/Edit'
import { IconDelete } from '../../../../svg/IconDelete'
import { InputChecked } from '../../../../svg/InputChecked'
import { InputNotChecked } from '../../../../svg/InputNotChecked'
import { IconSearch } from '../../../../svg/IconSearch'
import { API_ROUTES } from '../../../../utils/apiRoutes'
import { callApi } from '../../../../utils/callApi'
import { SnackBar } from '../../SnackBar'

const validateAddress = (addItem: TCustomerAddress) => {
  if (addItem.country === '_unitedStates') {
    return addItem.addr1 && addItem.city && addItem.zip && addItem.country
  }
  return addItem.country
}

const validateButton = (inputError: boolean, formError: boolean, isMandatory: boolean) => {
  if (inputError && formError) {
    return 'form-input-invalid !border-red-400 focus:border-red-400 focus-within:border-red-400'
  }
  if (!inputError && formError && !isMandatory) {
    return 'border-gray-300 focus:border-button-reports focus-within:border-button-reports'
  }
  if (!inputError && formError && isMandatory) {
    return 'form-input-valid border-green-700 focus:border-green-700 focus-within:border-green-700'
  }
  return 'border-gray-300 focus:border-button-reports focus-within:border-button-reports'
}


interface ItemAddressProps {
  addressItem: TCustomerAddress
  index: number
  onInputValueChange: (address: TCustomerAddress, index: number) => void
  country: { value: string; label: string; states?: { value: string; label: string }[] }[]
  onCountryInputChange: (inputValue: string) => void
  onDeleteAddress: () => void
  toggleForm: (index: number, isExpanded: boolean) => void
  isExpandedForm: boolean
  companyNSName: string
}

export const ItemAddress: React.FC<ItemAddressProps> = ({
  addressItem,
  country,
  index,
  onInputValueChange,
  onCountryInputChange,
  onDeleteAddress,
  toggleForm,
  isExpandedForm,
  companyNSName
}) => {
  const [localAddressItem, setLocalAddressItem] = useState<TCustomerAddress>(addressItem)
  const [zipInput, setZipInput] = useState(localAddressItem.zip || '')
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const [formError, setFormError] = useState<boolean>(false)
  const [errors, setErrors] = useState({
    addr1: false,
    city: false,
    zip: false,
    country: false,
  })

  const isUnitedStates = localAddressItem.country === '_unitedStates'
  const { addSnackBar } = SnackBar()

  const getZipCodeInfo  = () => {
    callApi({
      method: 'get',
      path: `${API_ROUTES.adminGetUSZips}?Zip=${zipInput}`,
    })
      .then((res) => {
        const { city, state_name } = res
        setLocalAddressItem(prevState => ({
          ...prevState,
          city,
          state: state_name
        }))
      })
      .catch(({ data }) => {
        const errorMessage = data?.message
        addSnackBar(errorMessage, false)
      })
  }
  
  const handleSearchClick = () => {
    getZipCodeInfo()
  }
  
  useEffect(() => {
    setLocalAddressItem(addressItem)
    setErrors({
      addr1: false,
      city: false,
      zip: false,
      country: false,
    })
  }, [addressItem])

  useEffect(() => {
    if (!addressItem.addressee && companyNSName) {
      setLocalAddressItem((prevState) => ({
        ...prevState,
        addressee: companyNSName,
      }))
    }
  }, [])

  const filterConfig = {
    matchFrom: 'start' as const,
  }

  const handleToggleExpand = () => {
    toggleForm(index, !isExpandedForm)
  }

  const styles = {
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: '200px',
    }),
    control: (provided: any) => ({
      ...provided,
      color: 'red',
      border: `${errors.country ? '1px solid rgba(248, 113, 113, 1)' : ''}`,
      boxShadow: 'none',
      '&:hover': {
        borderColor: `${errors.country ? 'rgba(248, 113, 113, 1)' : ''}`,
      },
    }),
  }

  const selectedCountry = country.find((option) => option.value === localAddressItem.country)
  const stateOptions = selectedCountry?.states || []

  const formatAddressTitle = (address: TCustomerAddress) => {
    const countryOption = country.find((option) => option.value === address.country)
    const countryLabel = countryOption ? countryOption.label : ''
    return (
      address.country &&
      `${address.addressee} ${countryLabel} ${address.addr1} ${address.addr2 ? `${address.addr2} ` : ''}${
        address.city
      } ${address.state} ${address.zip}`
    )
  }

  const resetErrors = () => {
    setFormError(false)
    setErrors({
      addr1: false,
      city: false,
      zip: false,
      country: false,
    })
  }

  const handleSave = () => {
    if (validateAddress(localAddressItem)) {
      onInputValueChange(localAddressItem, index)
      toggleForm(index, false)
      resetErrors()
    } else {
      const newErrors = {
        addr1: !localAddressItem.addr1 && isUnitedStates,
        city: !localAddressItem.city && isUnitedStates,
        zip: !localAddressItem.zip && isUnitedStates,
        country: !localAddressItem.country,
        state: !localAddressItem.state && isUnitedStates,
      }
      setErrors(newErrors)
      setFormError(true)
    }
  }

  const onCloseEdit = () => {
    setLocalAddressItem(addressItem)

    const isEmpty =
      !addressItem.addr1 &&
      !addressItem.addr2 &&
      !addressItem.addr_phone &&
      !addressItem.addressee &&
      !addressItem.attention &&
      !addressItem.city &&
      !addressItem.country &&
      !addressItem.state &&
      !addressItem.zip

    if (isEmpty) {
      toggleForm(index, !isExpandedForm)
      onDeleteAddress()
    } else {
      toggleForm(index, false)
    }
    resetErrors()
  }

  const handleInputChange = (value: string | boolean, name: string) => {
    if (name === 'zip') {
      setZipInput(value as string)
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false,
      }))
    }
    setLocalAddressItem({
      ...localAddressItem,
      [name]: value,
    })
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }))
    setFormError(false)
    

    if (name === 'country'){
      setErrors({
        addr1: false,
        city: false,
        zip: false,
        country: false,
      })
      setFormError(false)
    }
  } 
    
  return (
    <div className='mb-1 p-3'>
      {isExpandedForm ? (
        <>
          <div className={`collapsible-content ${isExpandedForm ? 'expanded' : ''}`}>
            <div
              className={`grid grid-cols-6 ${
                isUnitedStates ? 'grid-rows-7' : 'grid-rows-6'
              } 2smMobile:grid-rows-5 desktop:grid-cols-8 desktop:grid-rows-3 pb-2`}
            >
              <div className='col-span-6 desktop:col-span-4 p-1.5'>
                <label htmlFor={`address1-${index}`} className='form-label'>
                  Address 1{isUnitedStates && '*'}
                </label>
                <div>
                  <input
                    value={localAddressItem.addr1 || ''}
                    onChange={(event) => handleInputChange(event.target.value, 'addr1')}
                    type='text'
                    name='addr1'
                    id={`address1-${index}`}
                    placeholder='Enter address'
                    className={`form-input-address ${validateButton(errors.addr1, formError, isUnitedStates)}`}
                  />
                </div>
                <div className={`static form-error-text ${errors.addr1 ? 'block' : 'hidden'}`}>
                  Please enter Address 1.
                </div>
              </div>

              <div className='col-span-6 row-start-2 2smMobile:row-start-2 desktop:col-span-4 desktop:col-start-5 desktop:row-start-1 mb-1.5 p-1.5 '>
                <label htmlFor={`address2-${index}`} className='form-label'>
                  Address 2
                </label>
                <div>
                  <input
                    value={localAddressItem.addr2 || ''}
                    onChange={(event) => handleInputChange(event.target.value, 'addr2')}
                    type='text'
                    name='addr2'
                    id={`address2-${index}`}
                    placeholder='Enter address'
                    className={`form-input-address focus:border-button-reports`}
                  />
                </div>
              </div>

              <div
                className={`col-span-6 row-start-3 2smMobile:row-start-3 desktop:row-start-2 mb-1.5 p-1.5 2smMobile:col-span-3 desktop:col-span-3`}
              >
                <label htmlFor={`country-${index}`} className='form-label'>
                  Country *
                </label>
                <Select
                  options={country}
                  styles={styles}
                  placeholder='Select country'
                  value={country.find((option) => option.value === localAddressItem.country)}
                  className={`relative`}
                  onChange={(selectedOption) => {
                    if (selectedOption) handleInputChange(selectedOption.value, 'country')
                  }}
                  onInputChange={onCountryInputChange}
                  menuPlacement='bottom'
                  filterOption={createFilter(filterConfig)}
                />
                <div className={`static form-error-text ${errors.country ? 'block' : 'hidden'}`}>
                  Please select a country.
                </div>
              </div>

              <div
                className={`col-span-6 row-start-4 2smMobile:row-start-3 desktop:col-span-3 desktop:row-start-2 mb-1.5 p-1.5 desktop:col-start-4`}
              >
                <label htmlFor={`zip-${index}`} className='form-label'>
                  Zip{isUnitedStates && '*'}
                </label>
                <div className='relative flex items-center'>
                  <input
                    value={localAddressItem.zip || ''}
                    onChange={(event) => handleInputChange(event.target.value, 'zip')}
                    type='text'
                    name='zip'
                    id={`zip-${index}`}
                    placeholder='Enter zip'
                    className={`form-input-address !bg-none ${validateButton(errors.zip, formError, isUnitedStates)}`}
                  />
                  {isUnitedStates && (
                  <>
                    <button
                      type='button'
                      className='absolute right-0 top-0 h-[95%] w-[38px] border-l border-gray-300 flex items-center justify-center rounded-r-[5px] cursor-pointer'
                      data-tooltip-id="search-tooltip"
                      onMouseEnter={() => setTooltipVisible(true)}
                      onMouseLeave={() => setTooltipVisible(false)}
                      onClick={handleSearchClick}
                    >
                      <IconSearch className='opacity-70 hover:opacity-90 ' />
                    </button>
                    {tooltipVisible && (
                      <div className="absolute text-center bottom-full left-0 lg:left-[40px] desktop:left-[-46px] transform mb-2 desktop:min-w-[315px] bg-gray-600 text-white text-sm p-2 rounded shadow-lg z-10">
                        Lookup for the city and state based on the ZIP code
                      </div>
                    )}
                  </>
                  )}
                </div>
                <div className={`static form-error-text ${errors.zip ? 'block' : 'hidden'}`}>Please enter Zip.</div>
              </div>

              <div className={`col-span-6 row-start-5 2smMobile:col-span-3 2smMobile:row-start-4 desktop:col-span-2 desktop:col-start-7 desktop:row-start-2 mb-[8px] p-1.5 ${isUnitedStates ? 'row-start-6' : '2smMobile:col-start-1 2smMobile:col-span-6'}`}>
                <label htmlFor={`city-${index}`} className='form-label'>
                  City{isUnitedStates && '*'}
                </label>
                <div>
                  <input
                    value={localAddressItem.city || ''}
                    onChange={(event) => handleInputChange(event.target.value, 'city')}
                    type='text'
                    name='city'
                    id={`city-${index}`}
                    placeholder='Enter city'
                    className={`form-input-address ${validateButton(errors.city, formError, isUnitedStates)}`}
                  />
                </div>
                <div className={`static form-error-text ${errors.city ? 'block' : 'hidden'}`}>
                  Please select a city.
                </div>
              </div>

              {isUnitedStates && (
                <div className='col-span-6 row-start-5 2smMobile:col-span-3 2smMobile:row-start-4 desktop:col-start-1 desktop:row-start-3 mb-1.5 p-1.5'>
                  <label htmlFor={`state-${index}`} className='form-label'>
                    State
                  </label>
                  <div>
                    <Select
                      value={stateOptions.find((option) => option.value === localAddressItem.state)}
                      onChange={(selectedOption) => {
                        if (selectedOption) handleInputChange(selectedOption.value, 'state')
                      }}
                      options={stateOptions}
                      styles={styles}
                      placeholder='Select State'
                      className='border-gray-300 focus:border-button-reports'
                      menuPlacement='bottom'
                      id={`state-${index}`}
                    />
                  </div>
                </div>
              )}

              <div
                className={`col-span-6 2smMobile:col-span-6 2smMobile:row-start-5 desktop:col-span-5  desktop:row-start-3 mb-1.5 p-1.5 ${
                  isUnitedStates ? 'desktop:col-start-4 row-start-7' : ' desktop:col-start-1 desktop:col-span-8 row-start-6'
                }`}
              >
                <label htmlFor={`addressee-${index}`} className='form-label'>
                  Addressee
                </label>
                <div>
                  <input
                    value={localAddressItem.addressee || ''}
                    type='text'
                    name='addressee'
                    id={`addressee-${index}`}
                    placeholder='Enter addressee'
                    className={`form-input-address focus:border-button-reports relative}`}
                    onChange={(event) => handleInputChange(event.target.value, 'addressee')}
                  />
                </div>
              </div>

            </div>

            <div className='flex items-end flex-col justify-between desktop:items-center desktop:flex-row'>
              <div className='flex gap-2'>
                <div className='mb-1 py-3 px-2 self-end'>
                  <label
                    htmlFor={`defaultShipping-${index}`}
                    className='w-max-content text-sm text-gray-600 flex items-center cursor-pointer'
                  >
                    <input
                      type='checkbox'
                      id={`defaultShipping-${index}`}
                      className='hidden'
                      checked={localAddressItem.default_shipping}
                      onChange={() => handleInputChange(!localAddressItem.default_shipping, 'default_shipping')}
                    />
                    {localAddressItem.default_shipping ? <InputChecked /> : <InputNotChecked />}
                    <span className='ml-3 select-none'>Default for shipping</span>
                  </label>
                </div>
                <div className='mb-1 py-3 px-2 self-end'>
                  <label
                    htmlFor={`defaultBilling-${index}`}
                    className='w-max-content text-sm text-gray-600 flex items-center cursor-pointer'
                  >
                    <input
                      type='checkbox'
                      id={`defaultBilling-${index}`}
                      className='hidden'
                      checked={localAddressItem.default_billing}
                      onChange={() => handleInputChange(!localAddressItem.default_billing, 'default_billing')}
                    />
                    {localAddressItem.default_billing ? <InputChecked /> : <InputNotChecked />}
                    <span className='ml-3 select-none'>Default for billing</span>
                  </label>
                </div>
              </div>
            </div>
            <div className='flex justify-end'>
              <div className='flex flex-row gap-3'>
                <button
                  onClick={onCloseEdit}
                  className='text-white bg-red-500 hover:bg-red-600 px-5 py-2 rounded self-end mb-2.5 min-w-[85px]'
                  type='button'
                >
                  Cancel
                </button>
                <button
                  onClick={handleSave}
                  className='text-white bg-green-700 hover:bg-green-800 px-5 py-2 rounded self-end mb-2.5 min-w-[85px]'
                  type='button'
                >
                  Save
                </button>
              </div>
            </div>
          </div>

        </>
      ) : (
        <>
          <div
            className={`px-4 py-2 gap-2.5 bg-white flex items-center cursor-pointer ${
              isExpandedForm ? 'border-t border-l border-r border-gray-300' : 'border border-gray-300'
            }`}
          >
            <div className='flex-grow'>
              <div className='text-lg font-bold'>{formatAddressTitle(localAddressItem)} </div>
              <div className='flex gap-3'>
                {localAddressItem.default_billing && <div>Default billing</div>}
                {localAddressItem.default_shipping && <div>Default shipping</div>}
              </div>
            </div>
            {!isExpandedForm && (
              <div className='flex gap-3'>
                <button onClick={handleToggleExpand} className='p-1 ml-auto' type='button'>
                  <Edit />
                </button>
                <button onClick={onDeleteAddress} className='p-1 ml-2' type='button'>
                  <IconDelete />
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}
