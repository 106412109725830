import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import React from 'react'
import { validateButton } from '../../../utils/userUtils/FormStyles'
import { onNumberChange } from '../../../utils/userUtils/EventHandlers'

interface FormPhoneInputProps {
  userTell: string;
  setUserTell: (value: string) => void;
  userTellError: boolean;
  setTellError: (value: boolean) => void;
  submitError: boolean;
}

export const FormPhoneInput: React.FC<FormPhoneInputProps> = ({
  userTell,
  setUserTell,
  userTellError,
  setTellError,
  submitError,
}) => {
  return (
    <div>
      <PhoneInput
        country='us'
        value={userTell}
        enableSearch
        placeholder='Enter phone number'
        onChange={(event: any) => {
          onNumberChange(event, userTell, setUserTell, setTellError)
        }}
        containerClass={`w-full h-12 py-1 placeholder-gray-300 border rounded-md focus:outline-none bg-white ${validateButton(userTellError, submitError)}`}
        inputStyle={{
          width: '100%',
          border: 'none',
          paddingTop: '4px',
        }}
        buttonStyle={{
          border: 'none',
          borderRight: '1px solid #cacaca',
          borderRadius: '6px 0 0 6px',
        }}
      />
      
      <div className={`form-error-text mt-1 ${userTellError && submitError ? 'block' : 'hidden'}`}>
        Please enter a valid phone number.
      </div>
    </div>
  )
}
