import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { callApi } from '../../utils/callApi'
import { API_ROUTES } from '../../utils/apiRoutes'
import * as Actions from './actions'
import { ActionTypes, TUser, TUserMessage } from './types'
import { TypeSetUserData, TypeSetUserPassword } from './actions'
import { SnackBar } from '../../components/ui/SnackBar'

const { addSnackBar } = SnackBar()

function* getUserWorker(action: ReturnType<typeof Actions.GetUser.request>) {
  // @ts-ignore
  const callBack = action.payload?.callBack
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: API_ROUTES.myAccount,
    })) as TUser
    
    yield put(Actions.GetUser.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetUser.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* setUserDataWorker(action: ReturnType<typeof Actions.SetUserData.request>) {
  const { callBack } = action.payload as TypeSetUserData
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'put',
      path: API_ROUTES.setMyAccountData,
      data: action.payload,
    })) as TUserMessage
    
    yield put(Actions.SetUserData.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.SetUserData.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    const successMessage = 'Your profile has been updated.'
    const errorMessage = data?.message || ''
    const snackBarMessage = success ? successMessage : errorMessage
    addSnackBar(snackBarMessage, success)
  }
}

function* setUserPasswordWorker(action: ReturnType<typeof Actions.SetUserPassword.request>) {
  const { callBack } = action.payload as TypeSetUserPassword
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'put',
      path: API_ROUTES.setUserPassword,
      data: action.payload,
    })) as TUserMessage
    
    yield put(Actions.SetUserPassword.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.SetUserPassword.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    const successMessage = 'Your password has been updated.'
    const errorMessage = data?.message || ''
    const snackBarMessage = success ? successMessage : errorMessage
    addSnackBar(snackBarMessage, success)
  }
}


function* watchRequest() {
  yield takeEvery(ActionTypes.GET_USER_R, getUserWorker)
  yield takeEvery(ActionTypes.SET_USER_DATA_R, setUserDataWorker)
  yield takeEvery(ActionTypes.SET_USER_PASSWORD_R, setUserPasswordWorker)
  
}

export default function* userSaga() {
  yield all([fork(watchRequest)])
}