import React from 'react'

interface IconFilterProps {
  // eslint-disable-next-line react/require-default-props
  styled?: string
}

export const IconFilter: React.FC<IconFilterProps> = ({ styled }) => {
  return (
    <svg
      className={`svg-inline--fa fa-filter ${styled}`}
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="filter"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      data-fa-i2svg=""
    >
      <path fill="currentColor" d="M504.6 84.19L320 306.8v149.2c0 19.52-21.97 30.7-37.75 19.66l-80-55.98C195.8 415.2 192 407.8 192 400V306.8L7.375 84.19C-9.965 63.28 5.213 32 32.7 32h446.6C506.8 32 521.1 63.28 504.6 84.19z" />
    </svg>
  )
}
