import { Reducer } from 'redux'
import { ActionTypes, TCmsState } from './types'

export const initialState: TCmsState = {
  loading: false,
  data: null,
  currentTemplateMessages: {
    id: null,
    data: null,
  },
  currentUsersId: null,
}

const reducer: Reducer<TCmsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_CSM_TEMPLATES_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_ALL_CSM_TEMPLATES_S:
      return { ...state, loading: false, errors: undefined, data: action.payload }
    case ActionTypes.GET_ALL_CSM_TEMPLATES_E:
      return { ...state, loading: false, errors: action.payload }
    
    case ActionTypes.GET_ALL_CSM_MESSAGES_BY_ID_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_ALL_CSM_MESSAGES_BY_ID_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        currentTemplateMessages: { id: action.payload.id, data: action.payload.data },
      }
    case ActionTypes.GET_ALL_CSM_MESSAGES_BY_ID_E:
      return { ...state, loading: false, errors: action.payload }
    
    case ActionTypes.EDIT_TEMPLATE_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.EDIT_TEMPLATE_S:
      return { ...state, loading: false, errors: undefined }
    case ActionTypes.EDIT_TEMPLATE_E:
      return { ...state, loading: false, errors: action.payload }
    
    case ActionTypes.EDIT_MESSAGE_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.EDIT_MESSAGE_S:
      return { ...state, loading: false, errors: undefined }
    case ActionTypes.EDIT_MESSAGE_E:
      return { ...state, loading: false, errors: action.payload }
    
    case ActionTypes.SET_CURRENT_USERS_ADMIN:
      return { ...state, currentUsersId: action.payload }
    
    default:
      return state
  }
}

export { reducer as CmsReducer }