/* eslint-disable react/require-default-props */
import React, { CSSProperties } from 'react'
import { ViewLikeAUser } from '../svg/ViewLikeAUser'
import { recMode, viewAs } from '../utils/consts'
import { IconStar } from '../svg/IconStar'

interface ViewAsCustomerProps {
  style?: CSSProperties
  className?: string
}

export const ViewAsCustomer: React.FC<ViewAsCustomerProps> = ({ style, className = '' }) => {
  return viewAs ? (
    <div
      id='viewAsCustomerContainer'
      style={style}
      className={`flex justify-center items-center gap-x-2 py-1 ${
        recMode ? 'bg-yellow-700' : 'bg-green-700'
      } text-gray-100 ${className}`}
    >
      {recMode ? <IconStar isActive /> : <ViewLikeAUser white />}
      {recMode ? (
        <span>&quot;Personal Recommendations&quot; mode</span>
      ) : (
        <span>&quot;View as Customer&quot; mode</span>
      )}
    </div>
  ) : null
}
