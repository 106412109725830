import React from 'react'

interface IconTimesProps {
  // eslint-disable-next-line react/require-default-props
  styled?: string
}

export const IconTimes: React.FC<IconTimesProps> = ({ styled }) => {
  return (
    <svg
      className={`svg-inline--fa fa-times-circle ${styled}`}
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="times-circle"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      data-fa-i2svg=""
    >
      <path fill="currentColor" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256C397.4 512 512 397.4 512 256S397.4 0 256 0zM336.1 303c9.375 9.375 9.375 24.56 0 33.94c-9.381 9.381-24.56 9.373-33.94 0L256 289.9l-47.03 47.03c-9.381 9.381-24.56 9.373-33.94 0c-9.375-9.375-9.375-24.56 0-33.94l47.03-47.03L175 208.1c-9.375-9.375-9.375-24.56 0-33.94s24.56-9.375 33.94 0L256 222.1l47.03-47.03c9.375-9.375 24.56-9.375 33.94 0s9.375 24.56 0 33.94l-47.03 47.03L336.1 303z" />
    </svg>
  )
}
