import { Reducer } from 'redux'
import { ActionTypes, TGalleryItemInfo, TGalleryState } from './types'
import {
  parseItemInfo,
  parseTenderInfo,
  parseTenderItems,
  parseTenderItems2,
  parseTenderItemsForPersRecommend,
} from '../../utils/socketUtils/parseTenderItems'
import { changeImageSizeForGallery, changeImageSizeForGalleryArray } from '../../utils/imgUrlParse/imgUrlParser'
import { tenderDataPricesHelper } from '../../utils/tendersUtils/tenderDataPricesHelper'
import { itemDataPricesHelper } from '../../utils/tendersUtils/itemDataPricesHelper'
import { wishlistHelper, wishlistPopupHelper } from '../../utils/tendersUtils/wishlistHelper'
import { recommendationHelper, recommendationPopupHelper } from '../../utils/tendersUtils/recomedationHelper'

export const initialState: TGalleryState = {
  loading: false,
  data: {
    itemInfo: null,
    tenderInfo: null,
    tenderItems: null,
    atLeastOneRecommended: false,
    loadTenderPrices: 0,
    showPrices: 0,
    pricesAreLoaded: false,
  },
}

const reducer: Reducer<TGalleryState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ITEM_INFO_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_ITEM_INFO_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        data: { ...state.data, itemInfo: changeImageSizeForGallery(action.payload) },
      }
    case ActionTypes.GET_ITEM_INFO_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.GET_ITEM_PRICES_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_ITEM_PRICES_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        data: { ...state.data, itemInfo: itemDataPricesHelper(state.data.itemInfo, action.payload) },
      }
    case ActionTypes.GET_ITEM_PRICES_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.GET_TENDER_INFO_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_TENDER_INFO_S:
      return { ...state, loading: false, errors: undefined, data: { ...state.data, tenderInfo: action.payload } }
    case ActionTypes.GET_TENDER_INFO_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.GET_TENDER_ITEMS_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_TENDER_ITEMS_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        data: {
          ...state.data,
          tenderItems: changeImageSizeForGalleryArray(action.payload),
          atLeastOneRecommended: action.payload?.some((item: TGalleryItemInfo) => item.in_recommendations),
        },
      }
    case ActionTypes.GET_TENDER_ITEMS_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.GET_TENDER_ITEMS_PRICES_R:
      return {
        ...state,
        loading: true,
        errors: undefined,
        data: {
          ...state.data,
          pricesAreLoaded: false,
        },
      }
    case ActionTypes.GET_TENDER_ITEMS_PRICES_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        data: {
          ...state.data,
          pricesAreLoaded: true,
          tenderItems: tenderDataPricesHelper(state.data.tenderItems, action.payload),
          atLeastOneRecommended: tenderDataPricesHelper(state.data.tenderItems, action.payload)?.some(
            (item: TGalleryItemInfo) => item.in_recommendations
          ),
        },
      }
    case ActionTypes.GET_TENDER_ITEMS_PRICES_E:
      return { ...state, loading: false, errors: 'Error load prices' }

    case ActionTypes.WS_CHANGE_TENDER_ITEMS:
      return {
        ...state,
        loading: false,
        errors: undefined,
        data: {
          ...state.data,
          itemInfo: parseItemInfo(state.data.itemInfo, action.payload),
          tenderItems: parseTenderItems(state.data.tenderItems, action.payload),
          tenderInfo: parseTenderInfo(state.data.tenderInfo, action.payload),
          atLeastOneRecommended: state.data.tenderItems?.some((item: TGalleryItemInfo) => item.in_recommendations),
        },
      }

    case ActionTypes.WS_CHANGE_TENDER_ITEMS_FOR_PERS_RECOMMEND:
      return {
        ...state,
        loading: false,
        errors: undefined,
        data: {
          ...state.data,
          tenderItems: parseTenderItemsForPersRecommend(state.data.tenderItems, action.payload),
        },
      }

    case ActionTypes.CHANGE_ITEMS_BY_ID:
      return {
        ...state,
        loading: false,
        errors: undefined,
        data: {
          ...state.data,
          tenderItems: parseTenderItems2(state.data.tenderItems, action.payload),
          atLeastOneRecommended: parseTenderItems2(state.data.tenderItems, action.payload)?.some(
            (item: TGalleryItemInfo) => item.in_recommendations
          ),
        },
      }
    case ActionTypes.LOAD_TENDER_PRICES:
      return {
        ...state,
        loading: false,
        errors: undefined,
        data: { ...state.data, loadTenderPrices: state.data.loadTenderPrices + 1 },
      }
    case ActionTypes.SHOW_PRICES:
      return {
        ...state,
        loading: false,
        errors: undefined,
        data: { ...state.data, showPrices: state.data.showPrices + 1 },
      }

    case ActionTypes.SET_ITEM_WISHLIST_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.SET_ITEM_WISHLIST_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        data: {
          ...state.data,
          tenderItems: wishlistHelper(state.data.tenderItems, action.payload),
          itemInfo: wishlistPopupHelper(state.data.itemInfo, action.payload),
          atLeastOneRecommended: wishlistHelper(state.data.tenderItems, action.payload)?.some(
            (item: TGalleryItemInfo) => item.in_recommendations
          ),
        },
      }
    case ActionTypes.SET_ITEM_WISHLIST_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.SET_ITEM_RECOMMENDATION_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.SET_ITEM_RECOMMENDATION_S:
      return {
        ...state,
        loading: false,
        errors: undefined,
        data: {
          ...state.data,
          tenderItems: recommendationHelper(state.data.tenderItems, action.payload),
          itemInfo: recommendationPopupHelper(state.data.itemInfo, action.payload),
          atLeastOneRecommended: recommendationHelper(state.data.tenderItems, action.payload)?.some(
            (item: TGalleryItemInfo) => item.in_recommendations
          ),
        },
      }
    case ActionTypes.SET_ITEM_RECOMMENDATION_E:
      return { ...state, loading: false, errors: action.payload }

    default:
      return state
  }
}

export { reducer as GalleryReducer }
