import React from 'react'
import { TGalleryItemInfo } from '../../../../store/gallery/types'

interface JewelryAdditionalDetailsProps {
  item: TGalleryItemInfo;
}

export const JewelryAdditionalDetails: React.FC<JewelryAdditionalDetailsProps> = ({ item }) => {
  const detailItem = item.product.product_details

  const convertString = (str: string) => {
    if (str[0] === '.') return `0${str}`

    return str
  }

  const itemDetails = [
    { value: item.product.sku, title: 'SKU' },
    { value: detailItem.type, title: 'Type' },
    { value: detailItem.metal_type, title: 'Metal' },
    { value: detailItem.ring_size, title: 'Ring Size' },
    { value: detailItem.bracelet_length, title: 'Bracelet Length' },
    { value: detailItem.necklace_length, title: 'Necklace Length' },
    { value: detailItem.weight_grams, title: 'Item Weight grams' },
  ]

  const diamondDetails = [
    { value: detailItem.approximate_total_diamond_carat_weight
      ? `${convertString(detailItem.approximate_total_diamond_carat_weight)} ct`
      : '', title: 'Approximate Total Diamond Weight' },
    { value: detailItem.shape, title: 'Diamond Shape' },
    { value: detailItem.approximate_diamond_color, title: 'Diamond Color Range' },
    { value: detailItem.approximate_diamond_clarity, title: 'Diamond Clarity' },
    { value: detailItem.diamond_count, title: 'Number of Diamonds' },
  ]

  const getGemstoneType = () => {
    if (detailItem.precious_color && detailItem.precious_color_2) {
      return `${detailItem.precious_color}, ${detailItem.precious_color_2}`
    }

    return detailItem.precious_color || detailItem.precious_color_2
  }

  const gemstoneDetails = [
    { value: getGemstoneType(), title: 'Gemstone Type' },
    { value: detailItem.colored_stone_condition, title: 'Gemstone Quality' },
    { value: `${detailItem.approximate_total_colored_stone_carat_weight
      ? `${convertString(detailItem.approximate_total_colored_stone_carat_weight)} ct`
      : ''}`, title: 'Approximate Total Gemstone Weight' },
    { value: detailItem.colored_stone_notes, title: 'Gemstone Notes' },
  ]

  return (
    <div className="">
      {itemDetails.some(detail => !!detail.value) ? (
        <div className="item-details-section mb-6">
          <div className="font-semibold text-base mb-3">
            Item Details
          </div>

          {itemDetails.map(field => {
            if (field.value) {
              return (
                <div className="item-details additionalDetaillsField" key={`${field.title}-itemDetails`}>
                  <div className="font-semibold">
                    {`${field.title}:`}
                  </div>
          
                  <div className="text-bluegray-600">
                    {field.value}
                  </div>
                </div>
              )
            }

            return null
          })}
        </div>

      ) : null}

      {diamondDetails.some(detail => !!detail.value) ? (
        <div className="diamond-details-section mb-6">
          <div className="font-semibold text-base mb-3">
            Diamond Details
          </div>

          {diamondDetails.map(field => {
            if (field.value) {
              return (
                <div className="diamond-details additionalDetaillsField" key={`${field.title}-diamondDetails`}>
                  <div className="font-semibold">
                    {`${field.title}:`}
                  </div>
          
                  <div className="text-bluegray-600">
                    {field.value}
                  </div>
                </div>
              )
            }

            return null
          })}
        </div>
      ) : null}

      {gemstoneDetails.some(detail => !!detail.value) ? (
        <div className="gemstone-details-section mb-6">
          <div className="font-semibold text-base mb-3">
            Gemstone Details
          </div>

          {gemstoneDetails.map(field => {
            if (field.value) {
              return (
                <div className="gemstone-details additionalDetaillsField" key={`${field.title}-gemstoneDetails`}>
                  <div className="font-semibold">
                    {`${field.title}:`}
                  </div>
          
                  <div className="text-bluegray-600">
                    {field.value}
                  </div>
                </div>
              )
            }

            return null
          })}
        </div>
      ) : null}
    </div>
    
  )
}
