import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Information, AcquisitionHistory, TenderSchedule } from '../containers/dashboard'
import { AppStore } from '../store/applicationState'
import { GetUser } from '../store/user/actions'
import { TenderTypes } from '../types/actions'

export const Dashboard = () => {
  const { userData } = useSelector((store: AppStore) => store)
  const dispatch = useDispatch()

  const [activeTenders, setActiveTenders] = useState<TenderTypes>('diamond')
  const [userCategories, setUserCategories] = useState<string[]>([])
  const [activeSearch, setActiveSearch] = useState(false)

  useEffect(() => {
    dispatch(GetUser.request())
  }, [])

  useEffect(() => {
    const allUserCategories = userData.fullAccessCategories.concat(userData.viewOnlyCategories)
    const categories: string[] = []
    if (allUserCategories.includes(1) || allUserCategories.includes(4)) {
      categories.push('diamond')
    }
    if (allUserCategories.includes(3) || allUserCategories.includes(6)) {
      categories.push('watches')
      if (!categories.includes('diamond')) setActiveTenders('watches')
    }
    if (allUserCategories.includes(2) || allUserCategories.includes(5)) {
      categories.push('jewelry')
      if (!categories.includes('diamond') && !categories.includes('watches')) setActiveTenders('jewelry')
    }

    setUserCategories([...categories])
  }, [userData.fullAccessCategories, userData.viewOnlyCategories])

  return (
    <div className='px-6 md:px-4 desktop:px-6 pb-16 text-sm'>
      <div className='w-full lg:w-12/12 mt-1 mx-auto max-w-7xl'>
        <h1 className='font-circa text-xl py-5'>
          {userData.data?.first_name ? `Welcome, ${userData.data?.first_name}` : ''}
        </h1>
        <div className='content flex-col flex md:flex-row md:space-x-4 bigScreen:space-x-16 md:space-y-0 space-y-16'>
          <div className='w-full md:w-4/12 lg:w-3/12 desktop:w-4/12 space-y-6 whitespace-nowrap'>
            <TenderSchedule userCategories={userCategories} />

            <Information />
          </div>

          <div className='w-full dashboard-tenders md:w-8/12 lg:w-9/12 desktop:w-8/12 bg-white shadow p-8 md:p-3 bigScreen:p-8 md:space-y-16 h-full'>
            {/* <AcquisitionPerformance
              userCategories={userCategories}
              activeTenders={activeTenders}
              setActiveTenders={setActiveTenders}
              setActiveSearch={setActiveSearch}
            /> */}
            <AcquisitionHistory
              userCategories={userCategories}
              userCompany={userData.data?.company_name}
              activeTenders={activeTenders}
              setActiveTenders={setActiveTenders}
              activeSearch={activeSearch}
              setActiveSearch={setActiveSearch}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
