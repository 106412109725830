export interface TMessageMethods {
  message_method_id: number,
  method_name: string,
}

export interface TMessageMethodsMessage {
  message: string
}

export interface TMessageMethodsState {
  loading: boolean
  data: TMessageMethods[] | null
  errors?: string | undefined
}

export enum ActionTypes {
  GET_MESSAGE_METHODS_R = '@@MESSAGE_METHODS/GET_MESSAGE_METHODS_R',
  GET_MESSAGE_METHODS_S = '@@MESSAGE_METHODS/GET_MESSAGE_METHODS_S',
  GET_MESSAGE_METHODS_E = '@@MESSAGE_METHODS/GET_MESSAGE_METHODS_E',
  
}
