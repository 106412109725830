import React from 'react'

export const IconArrowCircle = ({ className = ''}) => {
  return (
    // <svg
    //   className={`svg-inline--fa fa-circle-arrow-left ${className}`}
    //   aria-hidden="true"
    //   focusable="false"
    //   data-prefix="far"
    //   data-icon="circle-arrow-left"
    //   role="img"
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 512 512"
    //   data-fa-i2svg=""
    // >
    //   <path
    //     fill="currentColor"
    //     d="M368 232H197.1l67.21-62.41c9.719-9.062 10.25-24.22 1.25-33.94c-9.031-9.688-24.22-10.25-33.94-1.25l-112 104C114.8 242.1 112 249.3 112 256s2.781 13.03 7.656 17.59l112 104c9.719 9 24.91 8.438 33.94-1.25c9-9.719 8.469-24.88-1.25-33.94L197.1 280H368c13.25 0 24-10.75 24-24S381.3 232 368 232zM256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464z"
    //   />
    // </svg>
    <svg className={`svg-inline--fa fa-circle-arrow-left ${className}`} aria-hidden="true" focusable="false" data-prefix="fal" data-icon="circle-arrow-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M368 240H182.6l68.69-68.69c6.25-6.25 6.25-16.38 0-22.62s-16.38-6.25-22.62 0l-96 96C129.6 247.8 128 251.9 128 256s1.562 8.188 4.688 11.31l96 96c6.25 6.25 16.38 6.25 22.62 0s6.25-16.38 0-22.62L182.6 272H368C376.8 272 384 264.8 384 256S376.8 240 368 240zM256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 480c-123.5 0-224-100.5-224-224s100.5-224 224-224s224 100.5 224 224S379.5 480 256 480z" /></svg>
  )
}
