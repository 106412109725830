import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Container } from '../wrappers/Container'
import { AppStore } from '../store/applicationState'
import { ROUTES } from '../utils/routes'

interface HomeProps {}

export const Home: React.FC<HomeProps> = () => {
  const history = useHistory()
  const { userData } = useSelector((store: AppStore) => store)
  
  useEffect(() => {
    const timer = setTimeout(() => {
      if (userData.isAssociateAdmin || userData.isExecutiveAdmin) history.replace(ROUTES.adminPageTender)
      else history.replace(ROUTES.dashboard)
    }, 200)
    
    return () => {clearTimeout(timer)}
  }, [userData])
  
  return (
    <Container>
      {/* <h2>Home page</h2> */}
    </Container>
  )
}
