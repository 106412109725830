import { Actions } from '../../utils/Action'
import { ActionTypes } from './types'
import { TDefRequest } from '../../types/actions'


/// ///////////////////////////////////////////////////////////////////////
const GetCategoriesActions = new Actions('GET_CATEGORIES', ActionTypes)

export interface TypeGetCategoriesR extends TDefRequest {}

export const GetCategories = {
  request: (payload?: TypeGetCategoriesR) => GetCategoriesActions.request(payload || {}),
  success: (payload: any) => GetCategoriesActions.success(payload),
  error: (payload: string) => GetCategoriesActions.error(payload),
}

/// ///////////////////////////////////////////////////////////////////////
