import { useCallback } from 'react'
import { deleteAuthCookie } from '../../utils/cookiesUtils'
// import { useDispatch } from 'react-redux'
// import { useHistory } from 'react-router-dom'
// import { SetSignout } from '../../store/auth/actions'

export const useSignout = () => {
  // const dispatch = useDispatch()
  // const history = useHistory()
  
  const signoutListener = useCallback(() => {
    localStorage.clear()
    sessionStorage.clear()
    deleteAuthCookie()
    // dispatch(SetSignout())
    const isWorthy = window.location.href.includes('/worthy')
    window.location.href = isWorthy ? '/worthy/login#endofsession' : '/login#endofsession'
  }, [])
  
  return {
    signoutListener,
  }
}