import { Reducer } from 'redux'
import { ActionTypes, TFinalizeTendersState } from './types'

export const initialState: TFinalizeTendersState = {
  data: null,
  loading: false,
}

const reducer: Reducer<TFinalizeTendersState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_TENDER_FINALIZED_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.SET_TENDER_FINALIZED_S:
      return { ...state, loading: false }
    case ActionTypes.SET_TENDER_FINALIZED_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.UPDATE_WINNER_BY_ITEM_ID_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.UPDATE_WINNER_BY_ITEM_ID_S:
      return { ...state, loading: false }
    case ActionTypes.UPDATE_WINNER_BY_ITEM_ID_E:
      return { ...state, loading: false, errors: action.payload }

    case ActionTypes.GET_TENDER_STATISTIC_R:
      return { ...state, loading: true, errors: undefined }
    case ActionTypes.GET_TENDER_STATISTIC_S:
      return { ...state, data: action.payload, loading: false }
    case ActionTypes.GET_TENDER_STATISTIC_E:
      return { ...state, loading: false, errors: action.payload }

    default:
      return state
  }
}

export { reducer as FinalizeTendersReducer }
