export interface TTenderStatistic {
  count_of_reserve_not_met_consign_items: number
  count_of_reserve_not_met_items_with_owner_circa: number
  count_of_sold_consign_items: number
  count_of_sold_items_with_circa_owner: number
}

export interface TFinalizeTendersMessage {
  message: string
}

export interface TFinalizeTendersState {
  data: TTenderStatistic | null
  loading: boolean
  errors?: string | undefined
}

export enum ActionTypes {
  SET_TENDER_FINALIZED_R = '@@TENDERS/SET_TENDER_FINALIZED_R',
  SET_TENDER_FINALIZED_S = '@@TENDERS/SET_TENDER_FINALIZED_S',
  SET_TENDER_FINALIZED_E = '@@TENDERS/SET_TENDER_FINALIZED_E',

  UPDATE_WINNER_BY_ITEM_ID_R = '@@TENDERS/UPDATE_WINNER_BY_ITEM_ID_R',
  UPDATE_WINNER_BY_ITEM_ID_S = '@@TENDERS/UPDATE_WINNER_BY_ITEM_ID_S',
  UPDATE_WINNER_BY_ITEM_ID_E = '@@TENDERS/UPDATE_WINNER_BY_ITEM_ID_E',

  GET_TENDER_STATISTIC_R = '@@TENDERS/GET_TENDER_STATISTIC_R',
  GET_TENDER_STATISTIC_S = '@@TENDERS/GET_TENDER_STATISTIC_S',
  GET_TENDER_STATISTIC_E = '@@TENDERS/GET_TENDER_STATISTIC_E',
}
