/* eslint-disable react/require-default-props */
/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from 'react'
// eslint-disable-next-line no-use-before-define
import { Menu, Transition } from '@headlessui/react'
// eslint-disable-next-line no-use-before-define
import { ChevronDownIcon } from '@heroicons/react/solid'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

interface DropdownProps {
  title: string
  children: {
    title: string,
    callBack: () => void
  }[]
  noStyle?: boolean
}

export const Dropdown: React.FC<DropdownProps> = ({ children, title, noStyle }) => {
  return (
    <Menu as='div' className='relative inline-block text-left w-full'>
      <Menu.Button
        style={noStyle ? { border: 'none', boxShadow: 'none', padding: 0, background: 'inherit', font: 'inherit' } : {}}
        className='border w-full border-gray-100 bg-gray-50 whitespace-nowrap text-gray-700 rounded-md smallMobile:px-4 py-2 select-none hover:bg-gray-100 focus:outline-none toggle-filter text-sm'
      >
        <div className='mx-auto inline-flex'>
          {title}
          <ChevronDownIcon
            style={noStyle ? { marginLeft: 0 } : {}}
            className='-mr-1 ml-2 h-5 w-5'
            aria-hidden='true'
          />
        </div>
      </Menu.Button>
      
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items
          className='origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50'
        >
          {children.map(item => (
            <div className='py-1' key={item.title}>
              <Menu.Item>
                {({ active }) => (
                  <button
                    type='submit'
                    onClick={item.callBack}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full text-left px-4 py-2 text-sm',
                    )}
                  >
                    {item.title}
                  </button>
                )}
              </Menu.Item>
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
