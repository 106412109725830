/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/react-in-jsx-scope */
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from '../store'
import { SCROLL_GALLERY } from '../utils/consts'
import { deleteSearchParams } from '../utils/helpers'
import { ErrorPopup } from '../components/ui/galleryUi/ErrorPopup'

export const useErrorPopup = () => {
  const add = (text = '') => {
    const div = document.createElement('div')

    div.id = 'errorPopup'
    ReactDOM.render(
      <Provider store={store}>
        <ErrorPopup text={text} />
      </Provider>,
      div
    )
    document.body.appendChild(div)
    document.body.style.overflow = 'hidden'
    document.body.style.height = '100%'
    sessionStorage.setItem(SCROLL_GALLERY, `${window.scrollY}`)

    return () => {
      document.body.removeChild(div)
    }
  }

  const remove = () => {
    const popUp = document.getElementById('errorPopup')
    const itemIdUrl = new URLSearchParams(window.location.search).get('item')
    if (popUp) document.body.removeChild(popUp)
    if (itemIdUrl) deleteSearchParams('item')

    document.body.style.overflow = 'auto'
    document.body.style.height = 'auto'
  }

  return {
    add,
    remove,
  }
}
