import { Reducer } from 'redux'
import { ActionTypes, TWorthyState } from './types'

export const initialState: TWorthyState = {
  isWorthy: false,
}

const reducer: Reducer<TWorthyState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_IS_WORTHY:
      return { ...state, isWorthy: true }

    default:
      return state
  }
}

export { reducer as WorthyStateReducer }
