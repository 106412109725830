/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ItemPopup, ItemPopupProps } from '../components/ui/galleryUi/ItemPopup'
import store from '../store'
import { SCROLL_GALLERY } from '../utils/consts'
import { deleteSearchParams } from '../utils/helpers'

export const useAddItemPopup = () => {
  const removeZoom = () => {
    const magnifyarea = document.getElementsByClassName('magnifyarea')[0]
    if (magnifyarea) magnifyarea.remove()
    const cursorshade = document.getElementsByClassName('cursorshade')[0]
    if (cursorshade) cursorshade.remove()
    const zoomstatus = document.getElementsByClassName('zoomstatus')[0]
    if (zoomstatus) zoomstatus.remove()
    const zoomtracker = document.getElementsByClassName('zoomtracker')[0]
    if (zoomtracker) zoomtracker.remove()

    const xzoom_preview = document.getElementsByClassName('xzoom-preview')[0]
    if (xzoom_preview) xzoom_preview.remove()
    const xzoom_source = document.getElementsByClassName('xzoom-source')[0]
    if (xzoom_source) xzoom_source.remove()
  }

  const add = useCallback((props: ItemPopupProps) => {
    const div = document.createElement('div')

    div.id = 'itemPopup'
    ReactDOM.render(
      <Provider store={store}>
        <ItemPopup {...props} />
      </Provider>,
      div
    )
    document.body.appendChild(div)
    document.body.style.overflow = 'hidden'
    document.body.style.height = '100%'
    sessionStorage.setItem(SCROLL_GALLERY, `${window.scrollY}`)

    return () => {
      document.body.removeChild(div)
      removeZoom()
    }
  }, [])

  const remove = useCallback(() => {
    const popUp = document.getElementById('itemPopup')
    const itemIdUrl = new URLSearchParams(window.location.search).get('item')
    if (popUp) document.body.removeChild(popUp)
    if (itemIdUrl) deleteSearchParams('item')

    document.body.style.overflow = 'auto'
    document.body.style.height = 'auto'
    removeZoom()
  }, [])

  return {
    add,
    remove,
  }
}
