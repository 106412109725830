import React from 'react'

interface IconLogoProps {}

export const IconLogo: React.FC<IconLogoProps> = () => {
  return (
    <svg
      id="svg2"
      version="1.1"
      xmlSpace="preserve"
      width={92}
      height={32}
      viewBox="0 0 200 34.102905"
      xmlns="http://www.w3.org/2000/svg"
      className="svg-inline--fa fa-circalogo fa-3x"
      style={{ height: '32px' }}
    >
      <metadata id="metadata8">
        {/* <rdf:rdf>
          <cc:work rdf:about>
            <dc:format>image/svg+xml</dc:format>
            <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
          </cc:work>
        </rdf:rdf> */}
      </metadata>
      <defs id="defs6" />
      {/* <sodipodi:namedview id="namedview4" pagecolor="#ffffff" bordercolor="#666666" borderopacity={1} objecttolerance={10} gridtolerance={10} guidetolerance={10} inkscape:pageopacity={0} inkscape:pageshadow={2} inkscape:window-width={2378} inkscape:window-height={1327} inkscape:pagecheckerboard={0} showgrid="false" width="20px" inkscape:zoom={1} inkscape:cx="3.5" inkscape:cy="7.5" inkscape:window-x={0} inkscape:window-y={25} inkscape:window-maximized={0} inkscape:current-layer="g10" fit-margin-top={0} fit-margin-left={0} fit-margin-right={0} fit-margin-bottom={0} /> */}
      <g id="g10" transform="matrix(1.3333333,0,0,-1.3333333,-0.28738459,44.562813)">
        <g id="g120" transform="matrix(10.295888,0,0,10.295888,-2.0036215,-310.68817)">
          <path
            id="path14"
            d="m 2.6031612,32.661824 c -0.02008,0.03681 -0.040082,0.07016 -0.063513,0.103504 -0.053278,0.07327 -0.1332664,0.146658 -0.2098973,0.193422 -0.1101346,0.06997 -0.3469442,0.170043 -0.6804713,0.170043 -0.093282,0 -0.4534739,-0.0067 -0.73018994,-0.196727 -0.0232554,-0.02008 -0.0766843,-0.05668 -0.13344076,-0.120025 -0.0899264,-0.09683 -0.21668265,-0.283529 -0.21668265,-0.617128 0,-0.09659 0.009964,-0.363471 0.18681695,-0.593494 0.2100255,-0.273366 0.556768,-0.336654 0.6568626,-0.35343 0.1134075,-0.01652 0.2333284,-0.01652 0.270105,-0.01652 0.1067055,0 0.5635825,-0.0036 0.8437276,0.303229 0.056603,0.06024 0.090029,0.110057 0.1299609,0.180208 l 0.353399,-0.07346 C 2.9331551,31.504704 2.8898977,31.444719 2.8432567,31.387912 2.4597572,30.937898 1.779416,30.937898 1.6427206,30.937898 c -0.4669171,0 -0.74028211,0.129882 -0.9069409,0.236762 -0.060112,0.04003 -0.10677773,0.07663 -0.16020531,0.123401 -0.0331692,0.0333 -0.10654934,0.106753 -0.17326973,0.213251 -0.0834449,0.126832 -0.18676619,0.3303 -0.18676619,0.680472 0,0.113186 0.006608,0.423529 0.20676949,0.700245 0.0332968,0.05003 0.0767097,0.10327 0.16999093,0.186766 0.0833688,0.06995 0.1800056,0.136669 0.2734144,0.18344 0.30348571,0.153269 0.68034181,0.159873 0.77027271,0.159873 0.1101801,0 0.5469251,-0.01 0.8871412,-0.216684 0.046563,-0.02999 0.143404,-0.09333 0.2433684,-0.196649 0.1066275,-0.11344 0.1634841,-0.216814 0.1934741,-0.27019 l -0.3568085,-0.07676"
            style={{
              fill: '#231f20',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: '0.00650683',
            }}
          />
          <path
            id="path16"
            d="M 4.2337598,33.352212 V 30.997883 H 3.8935502 v 2.354329 h 0.3402096"
            style={{
              fill: '#231f20',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: '0.00650683',
            }}
          />
          <path
            id="path18"
            d="m 5.7043554,32.218423 h 1.163701 c 0.1899994,0 0.4135091,0.0067 0.5434505,0.173348 0.033315,0.04008 0.086736,0.120045 0.086736,0.256714 0,0.04346 -0.00325,0.133364 -0.059928,0.230153 C 7.3382398,33.055416 7.1814903,33.068755 6.998258,33.07206 H 5.7043554 Z m 1.270348,1.133789 c 0.2002802,-0.0034 0.2736122,-0.01009 0.373492,-0.03665 0.3168826,-0.0835 0.4968615,-0.33017 0.4968615,-0.653735 0,-0.126701 -0.026548,-0.456617 -0.3800639,-0.603482 -0.060123,-0.02338 -0.1234346,-0.04334 -0.2566944,-0.06342 L 8.0786521,30.997883 H 7.6416534 L 6.8446318,31.941629 H 5.7075828 V 30.997883 H 5.3708023 v 2.354329 h 1.6039011"
            style={{
              fill: '#231f20',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: '0.00650683',
            }}
          />
          <path
            id="path20"
            d="m 11.133024,32.661824 c -0.02004,0.03681 -0.03989,0.07016 -0.06325,0.103504 -0.05316,0.07327 -0.133456,0.146658 -0.210236,0.193422 -0.110031,0.06997 -0.346944,0.170043 -0.680159,0.170043 -0.09344,0 -0.4537221,-0.0067 -0.7303925,-0.196727 -0.023229,-0.02008 -0.076585,-0.05668 -0.1333249,-0.120025 -0.089794,-0.09683 -0.2168076,-0.283529 -0.2168076,-0.617128 0,-0.09659 0.00996,-0.363471 0.186746,-0.593494 0.2100405,-0.273366 0.5568545,-0.336654 0.6571248,-0.35343 0.1130892,-0.01652 0.2333352,-0.01652 0.2699692,-0.01652 0.106582,0 0.563491,-0.0036 0.843675,0.303229 0.05661,0.06024 0.09012,0.110057 0.129942,0.180208 l 0.353646,-0.07346 c -0.07678,-0.136745 -0.120116,-0.19673 -0.166705,-0.253537 -0.383708,-0.450014 -1.063736,-0.450014 -1.20051,-0.450014 -0.466996,0 -0.740413,0.129882 -0.9071179,0.236762 -0.060123,0.04003 -0.106647,0.07663 -0.160003,0.123401 -0.033445,0.0333 -0.1066469,0.106753 -0.1734721,0.213251 -0.083353,0.126832 -0.1866809,0.3303 -0.1866809,0.680472 0,0.113186 0.00657,0.423529 0.2068521,0.700245 0.033185,0.05003 0.07652,0.10327 0.1698283,0.186766 0.083353,0.06995 0.1801741,0.136669 0.2733519,0.18344 0.3036087,0.153269 0.6804196,0.159873 0.7706696,0.159873 0.10977,0 0.546704,-0.01 0.886816,-0.216684 0.04672,-0.02999 0.143475,-0.09333 0.243485,-0.196649 0.106647,-0.11344 0.163452,-0.216814 0.193449,-0.27019 l -0.3569,-0.07676"
            style={{
              fill: '#231f20',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: '0.00650683',
            }}
          />
          <path
            id="path22"
            d="m 12.803718,31.848217 h 1.163876 l -0.497056,0.993742 c -0.04334,0.09011 -0.05323,0.113486 -0.07997,0.210151 -0.02336,-0.0701 -0.03338,-0.103452 -0.08993,-0.223496 z m 0.773727,1.503995 1.207017,-2.354329 h -0.386831 l -0.286691,0.58015 h -1.447119 l -0.286886,-0.58015 h -0.376941 l 1.220551,2.354329 h 0.3569"
            style={{
              fill: '#231f20',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: '0.00650683',
            }}
          />
        </g>
      </g>
    </svg>
  )
}
