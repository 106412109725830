import React from 'react'

export const DiamondSolid = () => {
  return (
    <svg
      className='gem-placeholder svg-inline--fa fa-gem'
      aria-hidden='true'
      focusable='false'
      data-prefix='fad'
      data-icon='gem'
      role='img'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      data-fa-i2svg=''
    >
      <g className='fa-duotone-group'>
        <path
          className='fa-secondary'
          fill='currentColor'
          style={{ opacity: 0.4 }}
          d='M256 192l144-128h-288L256 192zM222.3 224L112 64L0 224H222.3zM512 224l-112-160l-110.3 160H512z'
        />
        <path
          className='fa-primary'
          fill='currentColor'
          d='M512 224l-244.3 250.9C264.6 478.2 260.4 480 256 480s-8.636-1.828-11.67-5.062L0 224H512z'
        />
      </g>
    </svg>
  )
}