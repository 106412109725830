import React from 'react'

export const IconEdit = () => {
  return (
    <svg
      className="svg-inline--fa fa-edit"
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="edit"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      data-fa-i2svg=""
    >
      <g className="fa-duotone-group">
        <path className="fa-secondary opacity-40" fill="currentColor" d="M497.9 42.19l-28.13-28.14c-18.75-18.75-49.14-18.75-67.88 0l-38.62 38.63l96.01 96.01l38.62-38.63C516.7 91.33 516.7 60.94 497.9 42.19zM383.1 448H63.1V128h156.1l64-64H63.1C28.65 64 0 92.65 0 128v320c0 35.35 28.65 64 63.1 64h319.1c35.34 0 63.1-28.65 63.1-64l-.0039-220.1l-63.1 63.99V448z" />
        <path className="fa-primary" fill="currentColor" d="M153.8 262.2l209.5-209.5l96.01 96.01l-209.5 209.4c-3.35 3.35-7.616 5.633-12.26 6.562l-95.23 19.04c-8.396 1.679-15.8-5.723-14.12-14.12l19.04-95.22C148.2 269.8 150.5 265.5 153.8 262.2z" />
      </g>
    </svg>
  )
}
