import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import * as Actions from './actions'
import { callApi } from '../../utils/callApi'
import { API_ROUTES } from '../../utils/apiRoutes'
import { ActionTypes, TTerms } from './types'
import { SnackBar } from '../../components/ui/SnackBar'
import { refresh } from '../../utils/checkToken'

const { addSnackBar } = SnackBar()

function* getTermsWorker(action: ReturnType<typeof Actions.GetTerms.request>) {
// @ts-ignore
  const callBack = action.payload?.callBack
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'get',
      path: API_ROUTES.getTerms,
    })) as TTerms
    
    yield put(Actions.GetTerms.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetTerms.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* postTermsWorker(action: ReturnType<typeof Actions.PostTerms.request>) {
// @ts-ignore
  const callBack = action.payload?.callBack
  let success = true
  let data = null
  
  try {
    data = (yield call(callApi, {
      method: 'post',
      path: API_ROUTES.postTerms,
    })) as TTerms
    yield call(refresh)
    yield put(Actions.PostTerms.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.PostTerms.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (!success) addSnackBar(data?.message || '', success)
  }
}


function* watchRequest() {
  yield takeEvery(ActionTypes.GET_TERMS_R, getTermsWorker)
  yield takeEvery(ActionTypes.POST_TERMS_R, postTermsWorker)
  
}

export default function* termsSaga() {
  yield all([fork(watchRequest)])
}