import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { callApi } from '../../../../utils/callApi'
import { API_ROUTES } from '../../../../utils/apiRoutes'
import * as Actions from './actions'
import { ActionTypes, TCmsTemplates, TMessageForTemplates } from './types'
import { SnackBar } from '../../../../components/ui/SnackBar'
import { TAdminUsersMessage } from '../../users/types'

const { addSnackBar } = SnackBar()

function* getAllTemplatesWorker(action: ReturnType<typeof Actions.GetAllCsmTemplates.request>) {
  const { callBack, search, sortBy, sort } = action.payload as Actions.TypeGetAllCsmTemplatesR
  let success = true
  let data = null

  let query = search || sort || sortBy ? '?' : ''
  query += search ? `Search=${search}` : ''
  query += sortBy ? `&SortBy=${sortBy}` : ''
  query += sort ? `&Sort=${sort}` : ''

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminCms}/templates${query}`,
    })) as TCmsTemplates[]

    yield put(Actions.GetAllCsmTemplates.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetAllCsmTemplates.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getAllCsmMessagesByIdWorker(action: ReturnType<typeof Actions.GetAllCsmMessagesById.request>) {
  const { callBack, idTemplate } = action.payload as Actions.TypeGetAllCsmMessagesByIdR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.adminCms}/templates/${idTemplate}/messages`,
    })) as TMessageForTemplates[]

    yield put(Actions.GetAllCsmMessagesById.success({ id: idTemplate, data }))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetAllCsmMessagesById.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* editTemplateWorker(action: ReturnType<typeof Actions.EditTemplate.request>) {
  const { callBack, idTemplate, body } = action.payload as Actions.TypeEditTemplateR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'put',
      path: `${API_ROUTES.adminCms}/templates/${idTemplate}`,
      data: body,
    })) as {}

    yield put(Actions.EditTemplate.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.EditTemplate.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    // const successMessage = 'The template has been updated'
    // const errorMessage = data?.message || ''
    // const snackBarMessage = success ? successMessage : errorMessage
    // addSnackBar(snackBarMessage || '', success)
  }
}

function* editMessageWorker(action: ReturnType<typeof Actions.EditMessage.request>) {
  const { callBack, idMessage, body } = action.payload as Actions.TypeEditMessageR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'put',
      path: `${API_ROUTES.adminCms}/messages/${idMessage}`,
      data: body,
    })) as {}

    yield put(Actions.EditMessage.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.EditMessage.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* sendMessageToUsersWorker(action: ReturnType<typeof Actions.SendMessageToUsers.request>) {
  const { callBack, body } = action.payload as Actions.TypeSendMessageToUsersR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'post',
      path: `${API_ROUTES.adminCms}/send-messages`,
      data: body,
    })) as TAdminUsersMessage

    yield put(Actions.SendMessageToUsers.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.SendMessageToUsers.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (success) addSnackBar(data?.message || 'Message has been sent', success)
    else addSnackBar(data?.message || '', success)
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.GET_ALL_CSM_TEMPLATES_R, getAllTemplatesWorker)
  yield takeEvery(ActionTypes.GET_ALL_CSM_MESSAGES_BY_ID_R, getAllCsmMessagesByIdWorker)
  yield takeEvery(ActionTypes.EDIT_TEMPLATE_R, editTemplateWorker)
  yield takeEvery(ActionTypes.EDIT_MESSAGE_R, editMessageWorker)
  yield takeEvery(ActionTypes.SEND_MESSAGE_TO_USERS_R, sendMessageToUsersWorker)
}

export default function* cmsSaga() {
  yield all([fork(watchRequest)])
}
