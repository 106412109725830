import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { ungzip } from 'pako'
import { callApi } from '../../utils/callApi'
import { API_ROUTES } from '../../utils/apiRoutes'
import * as Actions from './actions'
import { ActionTypes, TGalleryItemInfo, TGalleryTenderInfo } from './types'
import { TItemMessage } from './tendersActions/types'
import { SnackBar } from '../../components/ui/SnackBar'
import { useErrorPopup } from '../../hooks/useErrorPopup'

const { addSnackBar } = SnackBar()
const { add } = useErrorPopup()

function* getGetItemInfoWorker(action: ReturnType<typeof Actions.GetItemInfo.request>) {
  const { callBack, itemId } = action.payload as Actions.TypeGetItemInfoR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.tenders}/items/${itemId}`,
      isGzip: true,
    })) as TGalleryItemInfo
    const result = ungzip(data as any, { to: 'string' })
    data = JSON.parse(result)

    yield put(Actions.GetItemInfo.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetItemInfo.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getGetItemPricesWorker(action: ReturnType<typeof Actions.GetItemPrices.request>) {
  const { callBack, itemId } = action.payload as Actions.TypeGetItemInfoR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.tenders}/items/${itemId}`,
      isGzip: true,
    })) as TGalleryItemInfo
    const result = ungzip(data as any, { to: 'string' })
    data = JSON.parse(result)

    yield put(Actions.GetItemPrices.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetItemPrices.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getTenderInfoWorker(action: ReturnType<typeof Actions.GetTenderInfo.request>) {
  const { callBack, idTender } = action.payload as Actions.TypeGetTenderInfoR
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.tenders}/${idTender}/gallery`,
    })) as TGalleryTenderInfo

    yield put(Actions.GetTenderInfo.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetTenderInfo.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getTenderItemsWorker(action: ReturnType<typeof Actions.GetTenderItems.request>) {
  const {
    callBack,
    idTender,
    offset,
    limit,
    search,
    sortBy,
    sort,
    showMyActivity,
    minWeight,
    maxWeight,
    favorites,
    recommendations,
    available,
    certified,
    lab,
    hasBox,
    hasPapers,
    brands,
    filters,
    adminInfo,
  } = action.payload as Actions.TypeGetTenderItemsR

  let success = true
  let data = null

  let query = `?Offset=${offset || 0}&Limit=${limit || 10}`
  query += search ? `&Search=${search}` : ''
  query += sortBy ? `&SortBy=${sortBy}` : ''
  query += sort ? `&Sort=${sort}` : ''

  query += showMyActivity ? `&ShowMyActivity=${showMyActivity}` : ''
  query += minWeight ? `&MinWeight=${minWeight}` : ''
  query += maxWeight ? `&MaxWeight=${maxWeight}` : ''
  query += favorites ? `&Favorites=${favorites}` : ''
  query += recommendations ? `&Recommendations=${recommendations}` : ''
  query += available ? `&Available=${available}` : ''
  query += certified ? `&Certified=${certified}` : ''
  query += lab ? `&Lab=${lab}` : ''
  query += hasBox ? `&HasBox=${hasBox}` : ''
  query += hasPapers ? `&HasPapers=${hasPapers}` : ''
  query += brands ? `&Brands=${brands}` : ''
  query += filters ? `&Filters=${filters}` : ''
  query += adminInfo ? `&AdminInfo=${adminInfo}` : ''

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.tenders}/${idTender}/gallery/list${query}`,
      isGzip: true,
    })) as TGalleryItemInfo[]
    const result = ungzip(data as any, { to: 'string' })
    data = JSON.parse(result)

    yield put(Actions.GetTenderItems.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetTenderItems.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* getTenderItemsPricesWorker(action: ReturnType<typeof Actions.GetTenderItemsPrices.request>) {
  const {
    callBack,
    idTender,
    offset,
    limit,
    search,
    sortBy,
    sort,
    showMyActivity,
    minWeight,
    maxWeight,
    favorites,
    available,
    certified,
    lab,
    hasBox,
    hasPapers,
    brands,
    filters,
    adminInfo,
  } = action.payload as Actions.TypeGetTenderItemsPricesR

  let success = true
  let data = null

  let query = `?Offset=${offset || 0}&Limit=${limit || 10}`
  query += search ? `&Search=${search}` : ''
  query += sortBy ? `&SortBy=${sortBy}` : ''
  query += sort ? `&Sort=${sort}` : ''

  query += showMyActivity ? `&ShowMyActivity=${showMyActivity}` : ''
  query += minWeight ? `&MinWeight=${minWeight}` : ''
  query += maxWeight ? `&MaxWeight=${maxWeight}` : ''
  query += favorites ? `&Favorites=${favorites}` : ''
  query += available ? `&Available=${available}` : ''
  query += certified ? `&Certified=${certified}` : ''
  query += lab ? `&Lab=${lab}` : ''
  query += hasBox ? `&HasBox=${hasBox}` : ''
  query += hasPapers ? `&HasPapers=${hasPapers}` : ''
  query += brands ? `&Brands=${brands}` : ''
  query += filters ? `&Filters=${filters}` : ''
  query += adminInfo ? `&AdminInfo=${adminInfo}` : ''

  try {
    data = (yield call(callApi, {
      method: 'get',
      path: `${API_ROUTES.tenders}/${idTender}/gallery/list${query}`,
      isGzip: true,
    })) as TGalleryItemInfo[]
    const result = ungzip(data as any, { to: 'string' })
    data = JSON.parse(result)

    yield put(Actions.GetTenderItemsPrices.success(data))
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.GetTenderItemsPrices.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
  }
}

function* setItemWishlistWorker(action: ReturnType<typeof Actions.SetItemWishlist.request>) {
  const { callBack, idTender, idItem, status } = action.payload as Actions.TypeSetItemWishlist
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: status ? 'post' : 'delete',
      path: `${API_ROUTES.tenders}/${idTender}/items/${idItem}/wishlist`,
    })) as TItemMessage
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.SetItemWishlist.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (success) yield put(Actions.SetItemWishlist.success({ idItem, status }))
    if (!success) {
      if (data?.message !== 'do not have access') addSnackBar(data?.message || '', success)
      else add('access to bid')
    }
  }
}

function* setItemRecommendationWorker(action: ReturnType<typeof Actions.SetItemRecommendation.request>) {
  const { callBack, idItem, companyId, status } = action.payload as Actions.TypeSetItemRecommendation
  let success = true
  let data = null

  try {
    data = (yield call(callApi, {
      method: status ? 'post' : 'delete',
      path: `${API_ROUTES.adminRecommendation}`,
      data: {
        item_id: idItem,
        company_ids: companyId,
      },
    })) as TItemMessage
  } catch (e) {
    success = false
    // @ts-ignore
    data = e?.data || e
    yield put(Actions.SetItemRecommendation.error(`${data?.message || data}`))
  } finally {
    if (callBack) callBack(success, data)
    if (success) yield put(Actions.SetItemRecommendation.success({ idItem, status }))
    if (!success) addSnackBar(data?.message || '', success)
  }
}

function* watchRequest() {
  yield takeEvery(ActionTypes.GET_ITEM_INFO_R, getGetItemInfoWorker)
  yield takeEvery(ActionTypes.GET_TENDER_INFO_R, getTenderInfoWorker)
  yield takeEvery(ActionTypes.GET_TENDER_ITEMS_R, getTenderItemsWorker)
  yield takeEvery(ActionTypes.GET_TENDER_ITEMS_PRICES_R, getTenderItemsPricesWorker)
  yield takeEvery(ActionTypes.GET_ITEM_PRICES_R, getGetItemPricesWorker)
  yield takeEvery(ActionTypes.SET_ITEM_WISHLIST_R, setItemWishlistWorker)
  yield takeEvery(ActionTypes.SET_ITEM_RECOMMENDATION_R, setItemRecommendationWorker)
}

export default function* gallerySaga() {
  yield all([fork(watchRequest)])
}
