import { applyMiddleware, compose, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
// import { persistReducer, persistStore } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import reducer from './rootReducer'
import sagas from './rootSaga'

// const persistConfig = {
//   key: 'root',
//   storage,
// }

// const persistedReducer = persistReducer(persistConfig, reducer)

const sagaMiddleware = createSagaMiddleware()

const createStoreWithMiddleware = compose()(createStore)

const composeEnhancers = composeWithDevTools({})

const store = createStoreWithMiddleware(
  // persistedReducer,
  reducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(sagas)

// export const persistor = persistStore(store)
export default store
