import React from 'react'

export const CalenderDay = () => {
  return (
    <svg
      className="calendar-icon svg-inline--fa fa-calendar-day fa-w-14"
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      data-icon="calendar-day"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      data-fa-i2svg=""
    >
      <g className="fa-group">
        <path
          className="fa-secondary text-bluegray-300"
          fill="currentColor"
          d="M0 192v272a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V192zm192 176a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16v-96a16 16 0 0 1 16-16h96a16 16 0 0 1 16 16zm112-240h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16zm-192 0h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16z"
        />
        <path
          className="fa-primary"
          fill="currentColor"
          d="M448 112v80H0v-80a48 48 0 0 1 48-48h48v48a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V64h128v48a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V64h48a48 48 0 0 1 48 48z"
        />
      </g>
    </svg>
  )
}
