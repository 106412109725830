import { useEffect, useState } from "react"

export const useClickOutside = (ref: any) => {
  const [clickOutside, setClickOutside] = useState<boolean>(false)

  useEffect(() => {
    const onClick = (e: any) => {
      const path = e.path || (e.composedPath && e.composedPath())

      if (path?.find((element: any): boolean => element === ref.current)) {
        setClickOutside(false)
      } else {
        setClickOutside(true)
      }
    }

    document.addEventListener("mousedown", onClick, true)
    document.addEventListener("touchstart", onClick, true)

    return () => {
      document.body.removeEventListener("mousedown", onClick, true)
      document.body.removeEventListener("touchstart", onClick, true)
    }
  }, [ref])

  return {
    clickOutside,
  }
}
