export interface TSettingMappingDiamondsObject {
  option_id: number
  option_key: string
  option_title: string
  options_group_id: number
  type_of_option_id: number
}

export interface TSettingMappingDiamonds {
  shapes: TSettingMappingDiamondsObject[] | null
  colors: TSettingMappingDiamondsObject[] | null
  clarity: TSettingMappingDiamondsObject[] | null
  fluorescence: TSettingMappingDiamondsObject[] | null
  cut_polish_symmetry: TSettingMappingDiamondsObject[] | null
}

export interface TListType {
  options_group_id: number
  option_name: string
}

export interface TSettingMappingOptions {
  child_options: {
    options_group_id: number
    option_name: string
  }[]
  parent_group_name: string
  parent_id: number
}

export interface TSettingMapping {
  options: TSettingMappingOptions[] | []
  diamonds: TSettingMappingDiamonds
}

export interface TSettingMappingMessage {
  message: string
}

export interface TSettingMappingState {
  loadingDiamonds: boolean
  loadingOptions: boolean
  data: TSettingMapping
  errors?: string | undefined
}

export enum ActionTypes {
  GET_DIAMONDS_SHAPE_R = '@@ADMIN_SETTING_MAPPING/GET_DIAMONDS_SHAPE_R',
  GET_DIAMONDS_SHAPE_S = '@@ADMIN_SETTING_MAPPING/GET_DIAMONDS_SHAPE_S',
  GET_DIAMONDS_SHAPE_WITH_0_OFFSET_S = '@@ADMIN_SETTING_MAPPING/GET_DIAMONDS_SHAPE_WITH_0_OFFSET_S',
  GET_DIAMONDS_SHAPE_E = '@@ADMIN_SETTING_MAPPING/GET_DIAMONDS_SHAPE_E',

  GET_DIAMONDS_COLOR_R = '@@ADMIN_SETTING_MAPPING/GET_DIAMONDS_COLOR_R',
  GET_DIAMONDS_COLOR_WITH_0_OFFSET_S = '@@ADMIN_SETTING_MAPPING/GET_DIAMONDS_COLOR_WITH_0_OFFSET_S',
  GET_DIAMONDS_COLOR_S = '@@ADMIN_SETTING_MAPPING/GET_DIAMONDS_COLOR_S',
  GET_DIAMONDS_COLOR_E = '@@ADMIN_SETTING_MAPPING/GET_DIAMONDS_COLOR_E',

  GET_DIAMONDS_CLARITY_R = '@@ADMIN_SETTING_MAPPING/GET_DIAMONDS_CLARITY_R',
  GET_DIAMONDS_CLARITY_S = '@@ADMIN_SETTING_MAPPING/GET_DIAMONDS_CLARITY_S',
  GET_DIAMONDS_CLARITY_WITH_0_OFFSET_S = '@@ADMIN_SETTING_MAPPING/GET_DIAMONDS_CLARITY_WITH_0_OFFSET_S',
  GET_DIAMONDS_CLARITY_E = '@@ADMIN_SETTING_MAPPING/GET_DIAMONDS_CLARITY_E',

  GET_DIAMONDS_FLUORESCENCE_R = '@@ADMIN_SETTING_MAPPING/GET_DIAMONDS_FLUORESCENCE_R',
  GET_DIAMONDS_FLUORESCENCE_S = '@@ADMIN_SETTING_MAPPING/GET_DIAMONDS_FLUORESCENCE_S',
  GET_DIAMONDS_FLUORESCENCE_WITH_0_OFFSET_S = '@@ADMIN_SETTING_MAPPING/GET_DIAMONDS_FLUORESCENCE_WITH_0_OFFSET_S',
  GET_DIAMONDS_FLUORESCENCE_E = '@@ADMIN_SETTING_MAPPING/GET_DIAMONDS_FLUORESCENCE_E',

  GET_DIAMONDS_CUT_POLISH_SYMMETRY_R = '@@ADMIN_SETTING_MAPPING/GET_DIAMONDS_CUT_POLISH_SYMMETRY_R',
  GET_DIAMONDS_CUT_POLISH_SYMMETRY_S = '@@ADMIN_SETTING_MAPPING/GET_DIAMONDS_CUT_POLISH_SYMMETRY_S',
  GET_DIAMONDS_CUT_POLISH_SYMMETRY_WITH_0_OFFSET_S = '@@ADMIN_SETTING_MAPPING/GET_DIAMONDS_CUT_POLISH_SYMMETRY_WITH_0_OFFSET_S',
  GET_DIAMONDS_CUT_POLISH_SYMMETRY_E = '@@ADMIN_SETTING_MAPPING/GET_DIAMONDS_CUT_POLISH_SYMMETRY_E',

  GET_OPTIONS_BY_TYPE_R = '@@ADMIN_SETTING_MAPPING/GET_OPTIONS_BY_TYPE_R',
  GET_OPTIONS_BY_TYPE_S = '@@ADMIN_SETTING_MAPPING/GET_OPTIONS_BY_TYPE_S',
  GET_OPTIONS_BY_TYPE_E = '@@ADMIN_SETTING_MAPPING/GET_OPTIONS_BY_TYPE_E',

  PUT_DIAMONDS_DICTIONARY_R = '@@ADMIN_SETTING_MAPPING/PUT_DIAMONDS_DICTIONARY_R',
  PUT_DIAMONDS_DICTIONARY_S = '@@ADMIN_SETTING_MAPPING/PUT_DIAMONDS_DICTIONARY_S',
  PUT_DIAMONDS_DICTIONARY_E = '@@ADMIN_SETTING_MAPPING/PUT_DIAMONDS_DICTIONARY_E',
}
