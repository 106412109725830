import { EditMessage, EditTemplate } from '../../store/admin/settings/cms/actions'

export const saveMessages = (current: any, email: boolean, emailState: any, /* whatsApp: boolean, whatsAppState: any, */ sms: boolean, smsState: any, dispatch: any, setEditStatus: any) => {
  dispatch(EditMessage.request({
    idMessage: current.email.message_id, body: {
      active: email,
      subject: emailState.subject,
      body: emailState.body,
      footer: emailState.footer,
    },
    callBack(success, data?) {
      setEditStatus((prev: any) => {
        return {
          ...prev,
          emailMessage: success ? 'The email message has been updated' : data.message,
          emailError: !success,
        }
      })
    },
  }))
  // dispatch(EditMessage.request({
  //   idMessage: current.whatsApp.message_id, body: {
  //     active: whatsApp,
  //     subject: whatsAppState.subject,
  //     body: whatsAppState.body,
  //     footer: whatsAppState.footer,
  //   },
  // }))
  dispatch(EditMessage.request({
    idMessage: current.sms.message_id, body: {
      active: sms,
      subject: smsState.subject,
      body: smsState.body,
      footer: smsState.footer,
    },
    callBack(success, data?) {
      setEditStatus((prev: any) => {
        return {
          ...prev,
          smsMessage: success ? 'The sms message has been updated' : data.message,
          smsError: !success,
        }
      })
    },
  }))
}

export const saveTemplate = (schedule: number, idTemplate: number, active: boolean, preferred: boolean, dispatch: any, setEditStatus: any) => {
  dispatch(EditTemplate.request({
    idTemplate, body: {
      active,
      preferred_delivery_method: preferred,
      schedule,
    },
    // callBack: ((success) => success && dispatch(GetAllCsmTemplates.request())),
    callBack(success, data?) {
      setEditStatus((prev: any) => {
        return {
          ...prev,
          templateMessage: success ? 'The template has been updated' : data.message,
          tempalteError: !success,
        }
      })
    },
  }))
}