import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { GetBidsByItemForTenderCloseChild } from '../../store/admin/tenders/bidsByItem/actions'
import { BidsByItemOnlyWinners } from './CloseTender/BidsByItemOnlyWinners'
import { AllBidsByTenderOnlyWinners } from './CloseTender/AllBidsByTenderOnlyWinners'
import { TenderStatusType } from '../../utils/tendersUtils/tenderStatusHelper'

interface CloseTenderProps {
  currentTenderId: number
  tenderStatus: TenderStatusType | null
}

export interface ReservePriceData {
  salesTarget: number
  perCarat: number | undefined
  listPrice: number
  resolved: boolean
}

export const CloseTender: React.FC<CloseTenderProps> = ({ currentTenderId, tenderStatus }) => {
  const dispatch = useDispatch()

  const [refsForItems, setRefsForItems] = useState<Map<number, any>>(new Map<number, any>())

  const [reservePriceData, setReservePriceData] = useState<Map<number, ReservePriceData> | null>(null)
  const [childIndex, setChildIndex] = useState<number[]>([])
  const [readyToComplete, setReadyToComplete] = useState<boolean>(false)

  const getChildForBidsByItem = (
    idItem: number,
    salesTarget: number,
    perCarat: number | undefined,
    listPrice: number,
    resolved: boolean,
    open?: boolean,
    scroll?: boolean
  ) => {
    const getMapData = (state: Map<number, any> | null, payload: { idItem: number; data: ReservePriceData }) => {
      if (payload.data) {
        if (state) return state.set(payload.idItem, payload.data)
        return new Map<number, any>([[payload.idItem, payload.data]])
      }
      return state
    }
    if (salesTarget !== -1) {
      setReservePriceData((prev) => getMapData(prev, { idItem, data: { listPrice, perCarat, salesTarget, resolved } }))
      dispatch(GetBidsByItemForTenderCloseChild.request({ idItem }))
    }
    if (open) setChildIndex((prev) => (prev.some((ind) => ind === idItem) ? prev : [...prev, idItem]))
    else
      setChildIndex((prev) =>
        prev.some((ind) => ind === idItem) ? prev.filter((ind) => ind !== idItem) : [...prev, idItem]
      )

    if (scroll) {
      window.scrollTo({
        top: refsForItems.get(idItem).getBoundingClientRect().top + window.scrollY - 50,
        behavior: 'smooth',
      })
    }
  }

  return (
    <>
      <BidsByItemOnlyWinners
        currentTenderId={currentTenderId}
        getChildForBidsByItem={getChildForBidsByItem}
        childIndex={childIndex}
        setChildIndex={setChildIndex}
        reservePriceData={reservePriceData}
        setReadyToComplete={setReadyToComplete}
        setRefsForItems={setRefsForItems}
        tenderStatus={tenderStatus}
      />
      {readyToComplete && (
        <AllBidsByTenderOnlyWinners
          currentTenderId={currentTenderId}
          getChildForBidsByItem={getChildForBidsByItem}
          tenderStatus={tenderStatus}
        />
      )}
      {/* {readyToComplete && <TenderStatistic idTender={currentTenderId} />} */}
    </>
  )
}
