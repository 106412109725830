import React from 'react'

export const Auction = ({ className = '' }) => {
  return (
    <svg width='25' height='25' className={className} viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 19.9995V21.9995H0V19.9995H12ZM12.586 0.685547L20.364 8.46355L18.95 9.87955L17.89 9.52555L15.413 11.9995L21.07 17.6565L19.656 19.0705L14 13.4135L11.596 15.8175L11.879 16.9495L10.464 18.3635L2.686 10.5855L4.101 9.17155L5.231 9.45355L11.525 3.16055L11.172 2.10055L12.586 0.685547Z'
        fill='#114B6D'
      />
    </svg>
  )
}