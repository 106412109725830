/* eslint-disable react/require-default-props,react/jsx-props-no-spreading */
import React from 'react'
import { Csv } from '../../../svg/Csv'
import { Submit } from '../../../svg/Submit'
import { PlusSign } from '../../../svg/PlusSign'
import { FileCool } from '../../../svg/FileCool'

interface ButtonProps {
  variant:
    | 'tenderActive'
    | 'tenderCatalog'
    | 'bidsCSV'
    | 'finalize'
    | 'message'
    | 'white'
    | 'undo'
    | 'whiteSmall'
    | 'save'
    | 'addFile'
    | 'goTo'
    | 'sendToNS'
    | 'edit'
    | 'generateInvoices'
    | 'invoice'
  text: string
  onClick?: () => void
  image?: any
  reference?: any
  disabled?: boolean
  className?: string

  [key: string]: any
}

export const Button: React.FC<ButtonProps> = ({
  variant,
  text,
  onClick,
  image,
  reference,
  disabled,
  className,
  ...props
}) => {
  let styles = className ? `${className} ` : ''
  let icon = null

  switch (variant) {
    case 'tenderActive':
      styles +=
        'tender rounded-md select-none whitespace-nowrap focus:outline-none text-green-700 bg-green-50 border-green-200 p-2 border'
      break
    case 'tenderCatalog':
      styles +=
        'tender border border-gray-100 bg-gray-50 text-gray-700 whitespace-nowrap rounded-md px-4 py-2 select-none hover:bg-gray-100 focus:outline-none border border-bluegray-200'
      break
    case 'bidsCSV':
      icon = <Csv />
      styles +=
        'border border-teal-200 bg-teal-100 text-teal-900 rounded-md px-4 py-2 select-none hover:bg-teal-50 focus:outline-none focus:border-transparent text-xs'
      break
    case 'generateInvoices':
      styles +=
      'border border-orange-200 bg-orange-100 text-orange-900 rounded-md px-4 py-2 select-none hover:bg-orange-50 focus:outline-none focus:border-transparent text-xs'
      break
    case 'message':
      styles +=
        'text-sm py-2 2smallest:w-full smallMobile:px-3 whitespace-nowrap items-center flex justify-center gap-x-0 smallMobile:gap-x-3 bg-lightblue-50 text-lightblue-700 hover:bg-lightblue-100 border border-lightblue-200 rounded-md'
      icon = <PlusSign />
      break
    case 'finalize':
      styles +=
        'border border-green-500 bg-green-500 text-white rounded-md px-4 py-2 select-none hover:bg-green-600 focus:outline-none text-base'
      break
    case 'white':
      styles +=
        'border border-gray-100 bg-gray-50 whitespace-nowrap text-gray-700 rounded-md px-4 py-2 select-none hover:bg-gray-100 focus:outline-none toggle-filter text-sm w-full smallMobile:w-auto'
      break
    case 'whiteSmall':
      styles +=
        'border border-gray-100 bg-gray-50 whitespace-nowrap text-gray-700 rounded-md px-2 py-1 select-none hover:bg-gray-100 transform hover:scale-110 transition-all focus:outline-none toggle-filter text-xs'
      break
    case 'undo':
      styles += 'border border-red-300 rounded-md bg-red-100 py-0.5 px-5 font-semibold'
      break
    case 'invoice':
      styles += 'min-h-[38px] border border-teal-200 bg-teal-100 text-teal-900 rounded-md px-4 py-2 select-none hover:bg-teal-50 focus:outline-none focus:border-transparent text-xs'
      break
    case 'save':
      styles = 'flex bg-green-700 w-full h-min p-2 text-white font-semibold rounded justify-around items-center'
      icon = (
        <>
          <div className='w-px h-4 bg-white' />
          <Submit />
        </>
      )
      break
    case 'addFile':
      styles +=
        'flex items-center addFileClass whitespace-nowrap border border-green-600 text-green-600 hover:text-white bg-white hover:bg-green-700 py-1.5 px-6 rounded'
      icon = <FileCool />
      break
    case 'goTo':
      styles +=
        'font-semibold border-lightblue-900 text-lightblue-900 border rounded bg-white py-2 px-4 w-full whitespace-nowrap'
      break
    case 'sendToNS':
      styles += 'border border-yellow-300 rounded-md bg-yellow-100 py-0.5 px-3 font-semibold text-yellow-800'
      break
    case 'edit':
      styles += 'border border-yellow-300 rounded-md bg-yellow-100 py-0.5 px-3 font-semibold text-yellow-800'
      break
    default:
  }
  if (image !== undefined) icon = image

  return (
    <button
      style={variant === 'save' ? { height: 'fit-content' } : {}}
      type='button'
      ref={reference}
      disabled={disabled}
      className={styles}
      onClick={onClick}
      {...props}
    >
      {icon && variant !== 'save' && <span className='mr-0.5'>{icon}</span>}
      <span className='text'>{text}</span>
      {variant === 'save' && icon}
    </button>
  )
}
