export interface TItemMessage {
  message: string
}

export interface TItemState {
  loading: boolean
  data: string | null
  errors?: string | undefined
}

export enum ActionTypes {
  SET_ITEM_BID_R = '@@itemActions/SET_ITEM_BID_R',
  SET_ITEM_BID_S = '@@itemActions/SET_ITEM_BID_S',
  SET_ITEM_BID_E = '@@itemActions/SET_ITEM_BID_E',

  DELETE_ITEM_BID_R = '@@itemActions/DELETE_ITEM_BID_R',
  DELETE_ITEM_BID_S = '@@itemActions/DELETE_ITEM_BID_S',
  DELETE_ITEM_BID_E = '@@itemActions/DELETE_ITEM_BID_E',
  
  SET_ITEM_BUY_R = '@@itemActions/SET_ITEM_BUY_R',
  SET_ITEM_BUY_S = '@@itemActions/SET_ITEM_BUY_S',
  SET_ITEM_BUY_E = '@@itemActions/SET_ITEM_BUY_E',
}
